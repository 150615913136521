const ShippingsEndPoints =  {
    GET_SHIPPING_TYPE: `/api/shippingType`,
    GET_SERVICE_TYPE: `/api/costOfProductType/all`,
    GET_UNIT_MEASURE: `/api/unitMeasure`,
    GET_SHIPPING_DETAILS_BY_FILTER_PARAM: `/api/invoice/shipping/`,
    GET_ORDER_WITH_GUIDE: `/api/invoice/shipping/all/shippingWithOutTravelAssigned`,
    CREATE_ORDER_TYPE: '/api/shippingType/',
    UPDATE_ORDER_TYPE: '/api/shippingType/:id'
}

export default ShippingsEndPoints