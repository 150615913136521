const AuthenticationEndPoints =  {
    LOGIN: `/api/auth/login`,
    LOGOUT: `/api/auth/logout`,
    VALIDATE_EMAIL: `/api/auth/validateEmail`,
    GENERATE_CODE: `/api/auth/createCode`,
    VALIDATE_CODE: `/api/auth/validateCode`,
    CHANGE_PASSWORD: `/api/auth/changePassword`,
    VALIDATE_TOKEN_SESSION: `/api/auth/validateSessionToken`
}

export default AuthenticationEndPoints