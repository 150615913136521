// Components
import App from './pages/app/App';
import reportWebVitals from './reportWebVitals';

// Libraries
import React from 'react';
import ReactDOM from 'react-dom/client';

// Providers
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next';

// Store
import store from './storage/store/configure-store.prod.store'

// Styles
import './index.scss';
import 'foundation-sites/dist/css/foundation.min.css';

// Translations
import string from './shared/translations/I18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={string} >
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
