// Constants - Endpoints
import VehicleEndPoints from "./constants/vehicle.end-points"

// Infrastructure
import Http from "./infrastructure/http.infrastructure"

// Services
import { getTokenService } from "./authentication.services"

export async function getTypeFuelService() {
    try {
        const { data: responseGetTypeFuel, error } = await Http.get(VehicleEndPoints.GET_TYPE_FUEL)
        if (responseGetTypeFuel && responseGetTypeFuel.status) {
            const result = responseGetTypeFuel.data.map((typeFuel) => ({
                label: typeFuel?.name,
                value: typeFuel?.id
            }))
            return result
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTypeBodyworkService() {
    try {
        const { data: responseGetTypeBodywork, error } = await Http.get(VehicleEndPoints.GET_TYPE_BODYWORK)
        if (responseGetTypeBodywork && responseGetTypeBodywork.status) {
            const result = responseGetTypeBodywork.data.map((typeBodywork) => ({
                label: typeBodywork?.name,
                value: typeBodywork?.id
            }))
            return result
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTypeVehicleService() {
    try {
        const { data: responseGetTypeVehicle, error } = await Http.get(VehicleEndPoints.GET_TYPE_VEHICLE)
        if (responseGetTypeVehicle && responseGetTypeVehicle.status) {
            const result = responseGetTypeVehicle.data.map((typeVehicle) => ({
                label: typeVehicle?.name,
                value: typeVehicle?.id
            }))
            return result
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getVehicleForByPlateCodeOrNumberService (valueFilter) {
    try {
        let token = getTokenService()
        const { data: responseGetFilterVehicle, error } = await Http.get(VehicleEndPoints.GET_FILTER_VEHICLE_BY_PLATE_CODE_OR_NUMBER, valueFilter, token)
        if (responseGetFilterVehicle && responseGetFilterVehicle.status) {
            const responseGetFilter = responseGetFilterVehicle?.data?.map((vehicle) => ({
                ...vehicle,
                value: `${vehicle?.vehicle?.internalNumber} - ${vehicle?.travel?.date} - ${vehicle?.travel?.time}`
            }))
            return responseGetFilter
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getFilterTravelService(valueFilter) {
    try {
        const { data: responseGetFilterTravel, error } = await Http.get(VehicleEndPoints.GET_VEHICLE_FILTER,{valueFilter})
        if (responseGetFilterTravel && responseGetFilterTravel.status) {
            const result = responseGetFilterTravel.data.map((vehicle) => ({
                ...vehicle,
                value: `${vehicle?.internalNumber} - ${vehicle?.plate}`
            }))
            return result
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createOwnerService(body) {
    try {
        const { data: responseCreateVehicleOwner, error } = await Http.post(VehicleEndPoints.CREATE_OWNER, body, null)
        if (responseCreateVehicleOwner && responseCreateVehicleOwner.status) {
            return responseCreateVehicleOwner.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createVehicleService(body) {
    try {
        const rerecordingMotor = body?.rerecordingMotor?.isTrue;
        const rerecordingSerialNumber = body?.rerecordingSerialNumber?.isTrue;
        const rerecordingChassis = body?.rerecordingChassis?.isTrue;
        const isMaintenance = body?.isMaintenance?.isTrue;


        const modifiedBody = {
            idOwner: body?.owner,
            mark: body?.mark,
            code: body?.code,
            plate: body?.plate,
            model: body?.model,
            motorNumber: body?.motorNumber,
            rerecordingMotor,
            idTypeFuel: body?.typeFuel?.value,
            internalNumber: body?.internalNumber,
            chassisNumber: body?.chassisNumber,
            rerecordingChassis,
            serialNumber: body?.serialNumber,
            rerecordingSerialNumber,
            SOATExpiration: body?.SOATExpiration,
            idTypeVehicle: body?.typeVehicle?.value,
            idTypeBodywork: body?.typeBodywork?.value,
            municipality: body?.municipalityResidence?.value,
            idTemplateVehicle: body?.templateVehicle?.id,
            number: body?.number,
            isMaintenance,
            codeBodyWork: body?.codeBodyWork,
            mechanicalTechnicianExpiration: body?.mechanicalTechnicianExpiration,
            operatingCardExpiration: body?.operatingCardExpiration,
            civilInsuranceExpiration: body?.civilInsuranceExpiration

        };

        const { data: responseCreateVehicle, error } = await Http.post(VehicleEndPoints.CREATE_VEHICLE, modifiedBody, null)
        if (responseCreateVehicle && responseCreateVehicle.status) {
            return responseCreateVehicle.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateInfoVehicleService(id, body) {
    try {
        const urlWithParams = VehicleEndPoints.UPDATE_BY_ID_VEHICLE.replace(":id", id)
        const { data: responseUpdateInfoVehicle, error } = await Http.patch(urlWithParams, body, null)
        if (responseUpdateInfoVehicle && responseUpdateInfoVehicle.status) {
            return responseUpdateInfoVehicle.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateInfoOwnerService(body) {
    try {
        let token = getTokenService()
        const { data: responseUpdateInfoOwner, error } = await Http.put(VehicleEndPoints.UPDATE_OWNER, body, token)
        if (responseUpdateInfoOwner && responseUpdateInfoOwner.status) {
            return responseUpdateInfoOwner.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getObtainVehicleByServiceNumberService (valueFilter,isTransferPassages) {
    try {
        let token = getTokenService()
        const { data: getResponseFilterVehicle, error } = await Http.get(VehicleEndPoints.GET_FILTER_AVAILABLE_VEHICLE_TO_TRANSFER_MANIFEST_TRAVEL, valueFilter, token)
        if (getResponseFilterVehicle && getResponseFilterVehicle.status) {
            let responseGetFilter = null
            if (isTransferPassages) {
                responseGetFilter = getResponseFilterVehicle?.data.map((vehicle) => ({
                    ...vehicle,
                    value: `${vehicle?.vehicle?.internalNumber}-${vehicle?.travel?.date}-${vehicle?.travel?.time} (${vehicle?.vehicle?.seatsSold}/${vehicle?.vehicle?.totalSeats})`
                }))
            }else{
                responseGetFilter = getResponseFilterVehicle?.data.map((vehicle) => ({
                    ...vehicle,
                    value: `${vehicle?.vehicle?.internalNumber}-${vehicle?.travel?.date}-${vehicle?.travel?.time}`
                }))
            }
            return responseGetFilter
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getVehicleInformationByIdService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = VehicleEndPoints.GET_VEHICLE_BY_ID.replace(":id", id)
        const { data: getResponseVehicleInformation, error } = await Http.get(urlWithParams, null, token)
        if (getResponseVehicleInformation && getResponseVehicleInformation.status) {
            const infoVehicle = getResponseVehicleInformation.data
            const modifiedTypeVehicle = {
                ...infoVehicle,
                idRegisterVehicle: infoVehicle?.id,
                typeVehicle:{
                    value: infoVehicle?.typeVehicle?.id,
                    label: infoVehicle?.typeVehicle?.name
                },
                typeFuel:{
                    value: infoVehicle?.typeFuel?.id,
                    label: infoVehicle?.typeFuel?.name
                },
                typeBodywork:{
                    value: infoVehicle?.typeBodywork?.id,
                    label: infoVehicle?.typeBodywork?.name
                },
                isMaintenance: {
                    isTrue: infoVehicle?.isMaintenance === true,
                    isFalse: infoVehicle?.isMaintenance === false
                },
                rerecordingMotor: {
                    isTrue: infoVehicle?.rerecordingMotor === true,
                    isFalse: infoVehicle?.rerecordingMotor === false
                },
                rerecordingChassis: {
                    isTrue: infoVehicle?.rerecordingChassis === true,
                    isFalse: infoVehicle?.rerecordingChassis === false
                },
                rerecordingSerialNumber: {
                    isTrue: infoVehicle?.rerecordingSerialNumber === true,
                    isFalse: infoVehicle?.rerecordingSerialNumber === false
                },
                ...infoVehicle?.owner && {
                    ...infoVehicle?.owner,
                    idOwner: infoVehicle?.owner?.id,
                    departmentResidence: {
                        value: infoVehicle.owner?.departmentResidence?.id,
                        label: infoVehicle.owner?.departmentResidence?.name
                    },
                    municipalityResidence: {
                        value: infoVehicle.owner?.municipalityResidence?.id,
                        label: infoVehicle.owner?.municipalityResidence?.name
                    },
                    documentType: {
                        value: infoVehicle.owner?.documentType?.id,
                        label: infoVehicle.owner?.documentType?.name
                    },
                    indicativeNumber: {
                        value: infoVehicle.owner?.indicativeNumber?.id,
                        label: infoVehicle.owner?.indicativeNumber?.number
                    },
                    indicativeNumberWhatsapp: {
                        value: infoVehicle.owner?.indicativeNumberWhatsapp?.id,
                        label: infoVehicle.owner?.indicativeNumberWhatsapp?.number
                    }
                }
            };

            delete modifiedTypeVehicle?.id;
            delete modifiedTypeVehicle?.owner;

            return modifiedTypeVehicle;
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllListOfVehiclesInformationService(valueFilter = "", filterType, page) {
    try {
        let token = getTokenService()
        const { data: responseGetAllListOfVehiclesInformation, error } = await Http.get(VehicleEndPoints.GET_FILTER_USERS, {valueFilter: valueFilter, filterType, page}, token)
        if (responseGetAllListOfVehiclesInformation && responseGetAllListOfVehiclesInformation.status) {
            return responseGetAllListOfVehiclesInformation?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllListOfVehiclesInformationCounterService(valueFilter = "", filterType) {
    try {
        let token = getTokenService()
        const { data: responseGetAllListOfVehiclesInformationCounter, error } = await Http.get(VehicleEndPoints.GET_VEHICLE_ALL_PAGINATION_COUNTER, {valueFilter: valueFilter, filterType}, token)
        if (responseGetAllListOfVehiclesInformationCounter && responseGetAllListOfVehiclesInformationCounter.status) {
            return responseGetAllListOfVehiclesInformationCounter?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getFilterDriverAvailableToVehicleService(valueFilter) {
    try {
        let token = getTokenService()
        const { data: getResponseDriverAvailable, error } = await Http.get(VehicleEndPoints.GET_FILTER_DRIVER_AVAILABLE_ASSIGNED_TO_VEHICLE, valueFilter, token)
        if (getResponseDriverAvailable && getResponseDriverAvailable.status) {
            const responseGetFilter = getResponseDriverAvailable?.data.map((driver) => ({
                ...driver,
                value: `${driver?.name} ${driver?.lastName}`
            }))
            return responseGetFilter
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async  function getFilterVehicleAvailableToVehicleService(valueFilter) {
    try {
        let token = getTokenService()
        const { data: getResponseVehicleAvailable, error } = await Http.get(VehicleEndPoints.GET_FILTER_VEHICLE_AVAILABLE_ASSIGNED_TO_DRIVER, valueFilter, token)
        if (getResponseVehicleAvailable && getResponseVehicleAvailable.status) {
            const responseGetFilter = getResponseVehicleAvailable?.data.map((vehicle) => ({
                ...vehicle,
                value: `${vehicle?.vehicle?.plate} - ${vehicle?.vehicle?.internalNumber}`
            }))
            return responseGetFilter
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }

}