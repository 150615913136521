//Libraries
import { combineReducers } from 'redux';

//Reducers
import AuthenticationReducer from '../reducers/authentication/authentication.reducer';
import AppReducer from "../reducers/app/app.reducer";
import SidesNavReducer from '../reducers/sides-nav/sides-nav.reducer';
import SalesReducer from '../reducers/sales/sales.reducer';
import DriversReducer from '../reducers/drivers/drivers.reducer';
import VehiclesReducer from '../reducers/vehicles/vehicles.reducer'
import BoardingSheetReducer from '../reducers/boarding-sheet/boarding-sheet.reducer';
import UserSettingsReducer from '../reducers/user-settings/user-settings.reducer';
import SettingsReducer from '../reducers/settings/settings.reducer';

const rootReducer = combineReducers({
	AuthenticationReducer,
	AppReducer,
	SidesNavReducer,
	SalesReducer,
	DriversReducer,
	VehiclesReducer,
	BoardingSheetReducer,
	UserSettingsReducer,
	SettingsReducer
});

export default rootReducer;
