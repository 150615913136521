// Actions
import { setDataSalesStateReducer } from '../../../../../../storage/reducers/sales/sales.action';

// Assets
import { Assets } from '../../../../../../assets';

// Libraries
import { Tooltip } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

// Services
import { changeStateToStandByService } from '../../../../../../services/sales.services';

// Styles
import './seat-map-render.component.scss'

const SeatMapRender = (props) => {

    const {
        // Actions
        setDataSalesStateReducer,
        //Variables
        isPreview,
        seatMap,
        assignedSeats,
        innerWidth,
        removeSeat = () => {},
        isSocketConnected,
        disabled
    } = props;

    useEffect(() => {}, [isSocketConnected])

    const INITIAL_STATE = {
        isTooltip: null
    }

    const [state, setState] = useState(INITIAL_STATE);
    const { isTooltip } = state;

    const handleMouseEnter = (index) => {
      setState({...state, isTooltip: index });
    };
    
    const handleMouseLeave = () => {
      setState({...state, isTooltip: null});
    };

    const getStatus = ({state,id}) => {
        if (assignedSeats.some(obj => obj.id === id)) {
            return t("sales.passages.assignedSeats.preselected")
        }else{
            const status = {
              0: t("sales.passages.assignedSeats.available"),
              1: t("sales.passages.assignedSeats.notAvailable"),
              2: t("sales.passages.assignedSeats.reserved"),
            };
            return status[state] || "";
        }
    }

    const selectedSeat = async(seat) => {
        try {
            await changeStateToStandByService([seat?.id]);
        } catch {
          // TODO: Implement error alert with code error.
        }
    }

    const handleTooltipVisibleChange = (visible) => {
        if (!visible) {
            setState({ ...state, isTooltip: null });
        }
    };

    const handleSeatClick = (seat) => {
        selectedSeat(seat)
        setDataSalesStateReducer("passageSales",'assignedSeats', [...assignedSeats, seat])
        setDataSalesStateReducer("passageSales",'seatSelected', undefined)
        handleMouseLeave()
    }

    const handleRemoveSeat = (seat) => {
        removeSeat(seat)
        handleMouseLeave()
    }
    return (
        <div className='grid-x seat-map-render__container'>
            {seatMap?.map((row, n) =>
                <div key={n} className={`grid-y ${n===0? 'seat-map-render__row--driver' :'seat-map-render__row'} flex-column-reverse`}>
                    {row.map((seat, m) => (
                        seat
                            ? <Tooltip
                                onOpenChange={(e) => handleTooltipVisibleChange(e)}
                                open={isTooltip === `${n}${m}`}
                                key={`${n}${m}`}
                                title={() => (
                                    <div className='grid-y seat-map-render__tooltip'>
                                        <div className='grid-x seat-map-render__tooltip__header'>
                                            <span className='grid-y seat-map-render__tooltip__name'>{seat?.name}</span>
                                            <span className='grid-y seat-map-render__tooltip__status'>{getStatus(seat)}</span>
                                        </div>
                                        <div className='grid-x align-middle seat-map-render__tooltip__body'>
                                            <div className='grid-x seat-map-render__tooltip__description'>
                                                <span className='grid-y small-10 medium-12 seat-map-render__tooltip__description__text'>
                                                {t("sales.passages.assignedSeats.cost")} ${seat.price.toLocaleString('es-ES', { style: 'decimal' })}
                                                </span>
                                                {innerWidth < 639
                                                    ? <button className='small-2'>
                                                        <img
                                                            src={Assets.SharedIcons.icon_info}
                                                            alt='icon_info'
                                                        />
                                                    </button>
                                                    : null
                                                }
                                            </div>
                                            <button
                                                disabled={isPreview || (disabled && !assignedSeats.some(s => s.id === seat.id))}
                                                className='seat-map-render__tooltip__body__button'
                                                onClick={() =>{
                                                    assignedSeats.some(s => s.id === seat.id) ?
                                                        handleRemoveSeat(seat)
                                                    :
                                                        handleSeatClick(seat) 
                                                }}
                                            >
                                                <span>{ assignedSeats.some(s => s.id === seat.id) ? "Liberar" : t("sales.passages.assignedSeats.reserve")}</span>
                                            </button>
                                        </div>
                                    </div>
                                )}
                                trigger='click'
                                placement="bottom"
                                color={'white'}
                                overlayInnerStyle={{ color: 'red' }}
                                zIndex={1}
                            >
                                {assignedSeats.some(s => s.id === seat.id)
                                    ? <button
                                        className={n === 0
                                            ? 'seat-map-render__row__driver-seat'
                                            : `seat-map-render__row__seat`
                                        }
                                        onClick={() => handleMouseEnter(`${n}${m}`)}
                                    >
                                        <img
                                            src={Assets.SalesIcons.icon_preselected_seat}
                                            alt="icon_preselected_seat"
                                        />
                                    </button>
                                    :
                                    <button
                                        key={`${n}${m}`}
                                        disabled={assignedSeats?.some(s => s.id === seat.id) || seat?.state !== 0}
                                        onClick={() => handleMouseEnter(`${n}${m}`)}
                                        className={n === 0
                                            ? 'seat-map-render__row__driver-seat'
                                            : `seat-map-render__row__seat`
                                        }
                                        style={{display: "flex"}}
                                    >
                                        {seat?.state === 2 && 
                                            <img
                                                src={Assets.SalesIcons.icon_reserved_seat}
                                                alt="icon_reserved_seat"
                                            />
                                        }
                                        { seat?.state === 0 &&
                                            <img
                                                src={Assets.SalesIcons.icon_available_seat}
                                                alt="icon_available_seat"
                                            />
                                        }
                                        { seat?.state === 1 &&
                                            <img
                                                src={Assets.SalesIcons.icon_seat_not_available}
                                                alt="icon_seat_not_available"
                                            />
                                        }
                                    </button>
                                }
                            </Tooltip>
                            : <div
                                key={`${n}${m}`}
                                className={n === 0
                                    ? 'seat-map-render__row__driver-seat'
                                    : `seat-map-render__row__seat`
                                }
                            >
                                {(n === 0 && m === 0)
                                    ? (<img
                                        src={Assets.SalesIcons.icon_driver_seat}
                                        alt="icon_available_seat"
                                    />)
                                    : null
                                }
                            </div>
                    ))}
                </div>)
            }
        </div>
    )
}

const mapStateToProps = ({ AppReducer, SalesReducer }) => {
    const { windowSize: { innerWidth } } = AppReducer;
    const { isSocketConnected } = SalesReducer;

    return {
        innerWidth,
        isSocketConnected
    };
};

const mapStateToPropsActions = {
    setDataSalesStateReducer
};

export default connect(mapStateToProps,mapStateToPropsActions)(SeatMapRender);