//Libraries
import { Steps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from "dayjs";

//Styles
import "./order-guide.component.scss"

const OrderGuideComponent = (props) => {

  const { dataInvoice }= props
  const [t] = useTranslation("translation")
  
    return (
        <>
            <div className="order-guide__content grid-x">
                <div className="order-guide__content__field grid-y small-6">
                  <span className="order-guide__content__field__label">
                    {t("sales.passages.pdfPassages.invoiceNumber")}
                  </span>
                  <span>{`${dataInvoice?.invoice?.codeSale}-${dataInvoice?.invoice?.codePrefix}-${dataInvoice?.invoice?.number}`}</span>
                </div>
                <div className="order-guide__content__field grid-y small-6">
                  <span className="order-guide__content__field__label">
                    {t("sales.passages.pdfPassages.dateIssued")}
                  </span>
                  <span>{dayjs(dataInvoice?.invoiceDetails?.dateOfEntry).format('DD/MM/YYYY')}</span>
                </div>
                <div className="order-guide__content__field grid-y small-12">
                  <span className="order-guide__content__field__label">
                    {t("sales.passages.pdfPassages.client")}
                  </span>
                  <span>{`${dataInvoice?.clientSends?.name} ${dataInvoice?.clientSends?.lastName}`}</span>
                </div>
                <div className="order-guide__content__field grid-y small-6">
                  <span className="order-guide__content__field__label">
                    {t("sales.passages.pdfPassages.document")}
                  </span>
                  <span>{`${dataInvoice?.clientSends?.documentType?.name} ${dataInvoice?.clientSends?.numberDocument}`}</span>
                </div>
                <div className="order-guide__content__field grid-y small-6">
                  <span className="order-guide__content__field__label">
                    {t("sales.passages.pdfPassages.exitDate")}
                  </span>
                  <span>{dayjs(dataInvoice?.invoiceDetails?.dateOfEntry).format('DD/MM/YYYY')}</span>
                </div>
                <div className="order-guide__content__field grid-y small-12">
                  <span className="order-guide__content__field__label">
                    {t("sales.shippings.orderGuide.addressee")}
                  </span>
                  <span>{`${dataInvoice?.clientReceives?.name} ${dataInvoice?.clientReceives?.lastName}`}</span>
                </div>
                <div className="order-guide__content__field grid-y small-12">
                  <span className="order-guide__content__field__label">
                    {t("sales.shippings.orderGuide.recipientAddress")}
                  </span>
                  <span>{dataInvoice?.clientReceives?.address}</span>
                </div>
                <div className="order-guide__content__field grid-y small-12">
                  <span className="order-guide__content__field__label">
                    {t("sales.shippings.orderGuide.recipientPhoneNumber")}
                  </span>
                  <span>{dataInvoice?.clientReceives?.numberPhone}</span>
                </div>
            </div>
            <div className='grid-x order-guide__box align-center'>
                <label className='order-guide__content__field__label'>{t("sales.shippings.orderGuide.guideStatus")}</label>
                <Steps
                    direction="vertical"
                    current={2}
                    className="order-guide__steps"
                    items={dataInvoice?.shipmentTracking.map(item => ({
                      title: <span className='order-guide__steps__title'>{item?.name}</span>,
                      description: <span className='order-guide__steps__description'>{`${dayjs(item?.date)?.format('DD/MM/YYYY')} - ${item?.time}`}</span>,
                      icon: <div
                          className="grid-x align-center-middle order-guide__steps__circle__content"
                        >
                            <div className='order-guide__steps__circle'></div>
                        </div>
                    }))}
                />
            </div>
        </>
      )

}

export default OrderGuideComponent