// Model
import SeatModel from "../model/seat.model";

export function mapperToSeatModel(seatDTO) {
    return new SeatModel(
        seatDTO.id, 
        seatDTO.row,
        seatDTO.column,
        seatDTO.price,
        seatDTO.state,
        seatDTO.name,
    )
}