const DriversEndPoints =  {
    GET_ALL_DRIVERS: `/api/driver`,
    GET_DRIVER_BY_ID: `/api/driver/:id`,
    GET_AUTHORIZED_CATEGORIES: `/api/licenseCategory/`,
    GET_ALL_COUNTRIES: `/api/country/`,
    GET_ALL_BLOOD_TYPES: `/api/bloodType/`,
    GET_DRIVER_AVAILABLE_WITH_VEHICLE: `/api/driver/getDriverAvailableWithVehicle`,
    CREATE_DRIVER: `/api/driver`,
    ADD_DRIVER_TO_VEHICLE:`/api/driver/assignVehicle`,
    UPDATE_BY_ID_DRIVER: `/api/driver/:id`,
    GET_FILTER_DRIVERS: `/api/vehicle/filter/one/vehicleAvailableAssignedToDriver`,
    ASSIGN_DRIVER_AVAILABLE: `/api/driver/assignVehicleAvailable`,
    FILTER_ONE_SEARCH_VEHICLE: `/api/vehicle/filter/one/searchVehicle`,
    GET_ALL_DRIVERS_PAGINATION: `/api/driver/all/pagination`,
    UNLINKED_DRIVER: `/api/driver/unassignVehicle/:id`,
}

export default DriversEndPoints