import * as driversTypes from './drivers.types';

export const setStateDriverReducer = (prop, value) => (dispatch) => {
	dispatch({ type: driversTypes.SET_DRIVERS, payload: { prop, value } });
};

export const resetDataDriverReducer = () => (dispatch) => {
	dispatch({ type: driversTypes.RESET_DATA_DRIVERS });
};

export const setDataDriverReducer = (key, prop, value) => (dispatch) => {
    dispatch({ type: driversTypes.SET_DATA_DRIVER, payload: { key, prop, value } });
};

export const setDataVehiclesKeyStateReducer = (value) => (dispatch) => {
    dispatch({ type: driversTypes.SET_DATA_DRIVER_KEY_STATE, payload: { value } });
};

export const resetDataNewDriverReducer = () => (dispatch) => {
    dispatch ({ type: driversTypes.RESET_DATA_NEW_USER });
}