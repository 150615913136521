// Model
import DocumentTypeModel from "../model/document-type.model";
import DocumentTypeDTO from "./document-type.dto";

export function mapperToDocumentTypeModel(documentTypeDTO) {
    return new DocumentTypeModel(documentTypeDTO.id, documentTypeDTO.name)
}

export function extractToDocumentTypeDTO({label,value}){
    return new DocumentTypeDTO(value,label) 
}