//Actions
import { setStateAppReducer } from '../../../../storage/reducers/app/app.actions';
import { setStateSidesNavReducer } from '../../../../storage/reducers/sides-nav/sides-nav.actions'
import { resetDataSaleLogoutReducer } from '../../../../storage/reducers/sales/sales.action' 

//Assets
import { Assets } from '../../../../assets'

//Components-Shared
import ErrorToastComponent from '../../toast/error-toast/error-toast.component';

//Constants
import SalesConst from '../../../../core/constants/sales.const';
import AlertConst from '../../../../core/constants/alerts.const';

//Libraries
import { IconButton, Toolbar} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Services
import { clearKeysAuthenticationService } from '../../../../services/authentication.services';

//Styles
import "./navbar-main.component.scss"

const NavbarMainComponent = (props) => {
  const [t] = useTranslation("translation")
  let history = useNavigate();
  const {
    //Actions
    setStateSidesNavReducer, 
    resetDataSaleLogoutReducer,
    setStateAppReducer,
    //Variables
    isDrawer,
    salesHistory,
    isErrorPrintTicket
  } = props


  const INITIAL_STATE = {
    isHoverSearch: false,
    isHoverMyProfile: false,
    //TODO: Commented by new requirements
    // isHoverNotification: false,
    isHoverExitSession: false,
    isHoverSideMenu: false
  }

  const [state, setState] = useState(INITIAL_STATE);

  const { 
    isHoverSearch, 
    isHoverMyProfile, 
    //TODO: Commented by new requirements
    // isHoverNotification, 
    isHoverExitSession, 
    isHoverSideMenu,
  } = state;

  useEffect(() => {
    if (isErrorPrintTicket) {
      ErrorToastComponent({
        title: 'Error al imprimir',
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      });
      setStateAppReducer('isErrorPrintTicket', false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorPrintTicket]);

  const hoverImg = (label, value) => {
    setState({
      ...state,
      [label] : value
    })
  }

  const handleDrawer = () =>{
    setStateSidesNavReducer("isDrawer", !isDrawer)
  }

  const handlerLogout = () => { 
    resetDataSaleLogoutReducer();
    clearKeysAuthenticationService();
    history("/login", { replace: true })
   }

  const handleSearch = () => {
    if (salesHistory !== SalesConst.PASSAGES_SALES ) {
      window.open(
        `/tracking?type=${salesHistory.toLowerCase()}`,
        '_blank', 'noopener,noreferrer'
      )
    }
  }

  return (
    <div className="navbar-main__content">
      <Toolbar className="navbar-main__navbar">
        <IconButton
          aria-label="open drawer"
          onClick={() => handleDrawer()}
          edge="start"
          className="navbar-main__side-menu__close"
        >
          <img
            src={Assets.SharedIcons.icon_logo}
            alt="icon_logo"
          />
          {isDrawer && <label className='navbar-main__title'>{t("navbarMain.title")}</label>}
          <img
            src={
              !isDrawer
                ? Assets.SharedIcons.icon_side_menu_close
                : (
                  isHoverSideMenu
                    ? Assets.SharedIcons.icon_side_menu_hover
                    : Assets.SharedIcons.icon_side_menu
                )
            }
            alt="icon_side_menu"
            className="navbar-main__side-menu"
            onMouseOver={() => {hoverImg("isHoverSideMenu",true)}}
            onMouseOut={() => {hoverImg("isHoverSideMenu",false)}}
          />
        </IconButton>
        <div className="grid-x align-middle">
          {/* TODO: inactive module due to new development */}
            { false && salesHistory === SalesConst.ASSIGNMENTS_SALES &&
              <button
                onClick={()=> handleSearch()}
              >
                <img
                  src={isHoverSearch ? Assets.SharedIcons.icon_search_hover : Assets.SharedIcons.icon_search}
                  alt="icon_search"
                  className="navbar-main__navbar--search navbar-main__navbar__icons__hidden"
                  onMouseOver={() => {hoverImg("isHoverSearch",true)}}
                  onMouseOut={() => {hoverImg("isHoverSearch",false)}}
                />
                <span className="navbar-main__text navbar-main__navbar__icons__hidden">
                  {t("navbarMain.track")}
                </span>
              </button>
            }
          <div className="navbar-main__navbar__right">
            <img
              src={isHoverMyProfile ? Assets.SharedIcons.icon_my_profile_hover : Assets.SharedIcons.icon_my_profile}
              alt="icon_my_profile"
              className="navbar-main__navbar--profile"
              onMouseOver={() => {hoverImg("isHoverMyProfile",true)}}
              onMouseOut={() => {hoverImg("isHoverMyProfile",false)}}
            />
            <span className="navbar-main__text navbar-main__navbar__icons__hidden">
              {t("navbarMain.myProfile")}
            </span>
          </div>
          {/* TODO: Commented by new requirements */}
          {/* <img
            src={isHoverNotification ? Assets.SharedIcons.icon_notifications_hover : Assets.SharedIcons.icon_notifications}
            alt="icon_notifications"
            className="navbar-main__navbar--notification"
            onMouseOver={() => { hoverImg("isHoverNotification", true) }}
            onMouseOut={() => { hoverImg("isHoverNotification", false) }}
          /> */}
          <button
            onClick={() => { handlerLogout() }}
          >
            <img
              src={isHoverExitSession ? Assets.SharedIcons.icon_exit_session_hover : Assets.SharedIcons.icon_exit_session}
              alt="icon_exit_session"
              onMouseOver={() => { hoverImg("isHoverExitSession", true) }}
              onMouseOut={() => { hoverImg("isHoverExitSession", false) }}
            />
          </button>
        </div>
      </Toolbar>
    </div>
  );
}
const mapStateToProps = ({ SidesNavReducer, SalesReducer, AppReducer }) => {
  const { isDrawer } = SidesNavReducer;
  const { salesHistory } = SalesReducer;
  const { isErrorPrintTicket } = AppReducer;

  return {
    isDrawer,
    salesHistory,
    isErrorPrintTicket
  };
};

const mapStateToPropsActions = {
  setStateSidesNavReducer,
  resetDataSaleLogoutReducer,
  setStateAppReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(NavbarMainComponent);