//Libraries
import { Route, Routes } from "react-router-dom";

//Styles
import "./sales.router.scss"

//Views - Pages
import SalesPage from "../../pages/sales/sales/sales.page"
import PassagesPage from "../../pages/sales/passages/passages.page"
import TurnPage from "../../pages/sales/turn/turn.page"
import ShippingsPage from "../../pages/sales/shippings/shippings.page";
import SummaryPage from "../../pages/sales/summary/summary.page";

const SalesRoutes = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<SalesPage />} />
        <Route path="/passages" element={<PassagesPage />} />
        <Route path="/turn" element={<TurnPage/>} />
        <Route path="/shippings" element={<ShippingsPage/>} />
        <Route path="/summary" element={<SummaryPage/>} />
      </Routes>
    </>
  );
};

export default SalesRoutes
