import { setStateSettingsReducer} from '../../../storage/reducers/settings/settings.action'
// Libraries
import { connect } from 'react-redux';
import { Tabs } from 'antd';

// Pages
import InvoicingResolutionPage from '../invoicing-resolution/invoicing-resolution.page';
import UsersPermissionsPage from '../users-permissions/users-permissions.page';
import CreationShipmentsPage from '../creation-shipments/creation-shipments.page';
import { ClientManagementPage } from '../client-management/client-management.page';
//TODO: Commented by new requirement
// import RouteCreationPage from '../route-creation/route-creation.page';
import PricePage from '../price/price.page';
import ConstantsFilePage from '../constants-file/constants-file.page';
import { InvoiceManagementPage } from '../invoice-management/invoice-management.page';

// Styles
import './settings.page.scss';

const SettingsPage = (props) => {

    const {
        selectedTab,
        setStateSettingsReducer
    } = props;

    const items = [
        {
            key: '1',
            label: "Usuarios y permisos",
            children: <UsersPermissionsPage/>,
        },
        {
            key: '2',
            label: "Tarifas",
            children: <PricePage/>,
        },
        {
            key: '3',
            label: "Encomiendas",
            children: <CreationShipmentsPage/>,
        },
        //TODO: Commented by new requirement
        // {
        //     key: '4',
        //     label: "Creación de rutas",
        //     children: <RouteCreationPage/>,
        // },
        {
            key: '5',
            label: "Resolución de facturación",
            children: <InvoicingResolutionPage/>,
        },
        {
            key: '6',
            label: "Constantes",
            children: <ConstantsFilePage/>,
        },
        {
            key: '7',
            label: "Gestión del cliente",
            children: <ClientManagementPage/>,
        },
        {
            key: '8',
            label: "Gestión facturas",
            children: <InvoiceManagementPage/>,
        }
    ]

    return (
        <>
            <div className='settings__breadcrumbs'>
                <span>Configuraciones</span>
            </div>
            <div>
                <Tabs 
                    className='settings__tabs'
                    activeKey={selectedTab} 
                    onChange={(activeKey) => setStateSettingsReducer('selectedTab', activeKey )} 
                    items={items}
                />
            </div>
        </>
    )
}
const mapStateToProps = ({ SettingsReducer }) => {
    const { selectedTab } = SettingsReducer;
  
    return {
        selectedTab
    };
  };
  
  const mapStateToPropsActions = {
    setStateSettingsReducer
  };

export default connect(mapStateToProps, mapStateToPropsActions)(SettingsPage);