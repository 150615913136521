import * as ExcelJS from 'exceljs';

export function excelFileGeneratorUtils(infoHeader, item, fileTitle, columnWidths) {

    const itemList = item;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${fileTitle}`);

    const headerStyle = {
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF85AF26' } },
        font: { bold: true, color: { argb: 'FFFFFFFF' } },
        border: {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
        }
    };

    const dataCellStyle = {
        border: {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
        }
    };

    const headerRow = worksheet.addRow(infoHeader);
    headerRow.eachCell(cell => {
        cell.style = headerStyle;
    });

    itemList.forEach(item => {
        const row = [];
        for (const key in item) {
            if (item.hasOwnProperty(key)) {
                row.push(item[key]);
            }
        }

        const newRow = worksheet.addRow(row);
        newRow.eachCell(cell => {
            cell.style = dataCellStyle;
        });
    });

    const columns = worksheet.columns;
    for (let i = 0; i < columnWidths.length; i++) {
        columns[i].width = columnWidths[i];
    }

    worksheet.eachRow(row => {
        row.height = 30;
    });

    worksheet.eachRow(row => {
        row.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    
    // Generate the Excel file
    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Archivo_${fileTitle}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};