export const SalesErrorsConst = {
  passages: {
    TICK007: "Asientos no disponibles",
    INVO009: "No hay resoluciones activas para la creación de Pasajes",
    INVO010: "Actualmente, todos los prefijos están en uso",
    VEHI013: "El vehículo no existe",
    TRVL046: "La hora del viaje del vehículo no está disponible",
    TRVL047: "Parámetro de búsqueda requerido (TRVL047)",
  },
  shippings: {
    INVO009: "No hay resoluciones activas para la creación de encomienda",
    INVO010: "Actualmente, todos los prefijos están en uso",
    AUTH011: "El token es invalido",
    USRL004: "El usuario es incorrecto",
    USRL005: "Falta el token de autenticación",
    USRL006: "El usuario no existe",
    VEHI016: "Se requiere el numero interno del vehiculo",
    VEHI033: "La fecha es obligatoria",
    VEHI034: "La fecha no es valida",
    VEHI035: "La hora no es valida"
  },
  moneyTransfer: {
    INVO009: "No hay resoluciones activas para la creación de giro",
    INVO010: "Actualmente, todos los prefijos están en uso",
  },
  invoice: {
    INVO005: "error al intentar visualizar la factura generada.",
    INVO014: "La factura ya está cancelada.",
    INVO015: "La factura ya es electrónica.",
    INVO017: "Factura no tiene tipo de servicio asociado.",
    TRVL054: "La factura ya fue planillada en un viaje, no puede cancelar dicha factura."
  },
  default: "Ocurrió un error",
};
