//Libraries
import React from "react";
import { BlobProvider } from "@react-pdf/renderer";
import Pdf from "@mikecousins/react-pdf";

//Styles
import "./preview-pdf.component.scss"

const PreviewPdfComponent = (props) => {
  
  const { scaleNumber = 0.6, children } = props;

  return (
    <div className="preview-pdf__content">
      <BlobProvider document={children}>
      {({url,loading})=> !loading && url &&
          <Pdf
            file={url}
            page={1}
            scale={scaleNumber}
          />
      }
      </BlobProvider>
    </div>
  );
};

export default PreviewPdfComponent;
