//Actions
import { resetDataVehicleReducer, setDataVehiclesKeyStateReducer, setStateVehicleReducer } from '../../../storage/reducers/vehicles/vehicles.action';

//Assets
import { Assets } from '../../../assets';

//Components
import VehicleDataComponent from './components/vehicle-data/vehicle-data.component';
import VehicleStepperComponent from './components/vehicle-stepper/vehicle-stepper.component';
import OwnerInformationComponent from './components/owner-information/owner-information.component';
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';
import SuccessToastComponent from '../../../shared/components/toast/success-toast/success-toast.component';
import InternalVehicleIdentificationComponent from "./components/internal-vehicle-identification/internal-vehicle-identification.component";

//Constants
import AlertConst from '../../../core/constants/alerts.const';
import { SettingsErrorsConst } from '../../../core/constants/errors/alerts-app/seettings.errors.const';

//Libraries
import dayjs from "dayjs";
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Services
import { createOwnerService, createVehicleService, getVehicleInformationByIdService, updateInfoOwnerService, updateInfoVehicleService } from '../../../services/vehicle.services';
//Styles
import "./add-vehicle.page.scss";

const steps = [
    { label: "Datos del vehículo" },
    { label: "Identificación interna del vehículo" },
    { label: "Datos del propietario" },
];

const AddVehiclesPage = (props) => {

    const {
        //Variables
        isError,
        addVehicle,
        newVehicleInformation,
        //Actions
        setStateVehicleReducer,
        resetDataVehicleReducer,
        setDataVehiclesKeyStateReducer
    } = props;

    let history = useNavigate();
    let params = useLocation(); 

    const INITIAL_STATE = {
        isDataVehicle: true,
        locationStepperState: 0
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { locationStepperState,
            isDataVehicle,
         } = state;

    useEffect(() => {
        return () => {
            resetDataVehicleReducer('addVehicle', {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (params?.state?.typeAction === 'VIEW_INFO_VEHICLE') && setStateVehicleReducer('isDisabled', true);
        if (params.state?.typeAction === 'EDIT_INFO_VEHICLE' || params.state?.typeAction === 'VIEW_INFO_VEHICLE') {
            getInfoVehicle(params.state?.id);
        } else {
            setState((prevState) => ({
                ...prevState,
                isDataVehicle: false
            }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.state?.typeAction]);

    const getInfoVehicle = async (id) => {
        try {
            const infoVehicle = await getVehicleInformationByIdService(id);
            if (infoVehicle) {
                setDataVehiclesKeyStateReducer('addVehicle', infoVehicle);
                setDataVehiclesKeyStateReducer('newVehicleInformation', infoVehicle);
                setState((prevState) => ({
                    ...prevState,
                    isDataVehicle: false
                }))
            } else {
                setState((prevState) => ({
                    ...prevState,
                    isDataVehicle: false
                }))
            }
        } catch (error) {

        }
    };

    const handleNext = () => {
        if (steps.length > locationStepperState) {
            setState({
                ...state,
                locationStepperState: locationStepperState + 1,
            });
        };
    };

    const handleBack = () => {
        setState({
            ...state, 
            locationStepperState: locationStepperState - 1,
        });
    };

    const onClose = () => {
        resetDataVehicleReducer('addVehicle', {});
        history("/vehicles", { replace: true });
    };

    const addNewVehicle = async () => {
        const createOwnerBody = {
            indicativeNumberWhatsapp: addVehicle?.indicativeNumberWhatsapp?.value,
            numberPhoneWhatsapp: addVehicle?.numberPhoneWhatsapp,
            email: addVehicle?.email,
            address: addVehicle?.address,
            municipality: addVehicle?.municipalityResidence?.value,
            documentType: addVehicle?.documentType?.value,
            numberDocument: addVehicle?.numberDocument,
            name: addVehicle?.name,
            lastName: addVehicle?.lastName,
            indicativePhone: addVehicle?.indicativeNumber?.value,
            numberPhone: addVehicle?.numberPhone
        };

        createOwnerService(createOwnerBody)
            .then((ownerId) => {
                const addVehicleModified = {
                    ...addVehicle,
                    SOATExpiration: dayjs(addVehicle?.SOATExpiration).format("YYYY-MM-DD"),
                    mechanicalTechnicianExpiration: dayjs(addVehicle?.mechanicalTechnicianExpiration).format("YYYY-MM-DD"),
                    operatingCardExpiration: dayjs(addVehicle?.operatingCardExpiration).format("YYYY-MM-DD"),
                    civilInsuranceExpiration: dayjs(addVehicle?.civilInsuranceExpiration).format("YYYY-MM-DD"),
                    owner: ownerId
                };
                createVehicleService(addVehicleModified)
                    .then(() => {
                        onClose();
                        SuccessToastComponent({
                            html:
                                `<span>${"Vehiculo creado exitosamente"}
                          <a class="create-user__text-alert"</a>
                        </span>`,
                            position: AlertConst.TOP_END_POSITION_TEXT,
                            timer: 1500
                        });
                    })
                    .catch((err) => {
                        ErrorToastComponent({
                            title: SettingsErrorsConst.newUsers[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                            position: AlertConst.TOP_END_POSITION_TEXT,
                            timer: 1500
                        });
                    });
            })
            .catch((error) => {
                // TODO: Implement error alert with code error.
            });
    };

    const updateInfoVehicle = async () => {
        try {
            let ownerData = {
                idOwner: newVehicleInformation?.idOwner,
                indicativeNumberWhatsapp: newVehicleInformation?.indicativeNumberWhatsapp?.value,
                numberPhoneWhatsapp: newVehicleInformation?.numberPhoneWhatsapp,
                email: newVehicleInformation?.email,
                address: newVehicleInformation?.address,
                municipality: newVehicleInformation?.municipalityResidence?.value,
                documentType: newVehicleInformation?.documentType?.value,
                numberDocument: newVehicleInformation?.numberDocument,
                name: newVehicleInformation?.name,
                lastName: newVehicleInformation?.lastName,
                indicativePhone: newVehicleInformation?.indicativeNumber?.value,
                numberPhone: newVehicleInformation?.numberPhone,
            }
            let createOwnerBody = {
                mark: addVehicle?.mark,
                code: addVehicle?.code,
                plate: addVehicle?.plate,
                mileage: addVehicle?.mileage,
                model: addVehicle?.model,
                motorNumber: addVehicle?.motorNumber,
                idTypeFuel: addVehicle?.typeFuel?.value,
                internalNumber: addVehicle?.internalNumber,
                chassisNumber: addVehicle?.chassisNumber,
                serialNumber: addVehicle?.serialNumber,
                idTypeVehicle: addVehicle?.typeVehicle?.value,
                idTypeBodywork: addVehicle?.typeBodywork?.value,
                municipality: addVehicle?.municipalityResidence?.value,
                idTemplateVehicle: addVehicle?.templateVehicle?.id,
                number: addVehicle?.number,
                codeBodyWork: addVehicle?.codeBodyWork,
                SOATExpiration: dayjs(addVehicle?.SOATExpiration).format("YYYY-MM-DD"),
                mechanicalTechnicianExpiration: dayjs(addVehicle?.mechanicalTechnicianExpiration).format("YYYY-MM-DD"),
                operatingCardExpiration: dayjs(addVehicle?.operatingCardExpiration).format("YYYY-MM-DD"),
                civilInsuranceExpiration: dayjs(addVehicle?.civilInsuranceExpiration).format("YYYY-MM-DD"),
                isMaintenance: !addVehicle?.isMaintenance.isTrue,
                rerecordingChassis: addVehicle?.rerecordingChassis.isTrue,
                rerecordingMotor: addVehicle?.rerecordingMotor.isTrue,
                rerecordingSerialNumber: addVehicle?.rerecordingSerialNumber.isTrue,
            };

            let OwnerNewData = {
                idOwner: addVehicle?.idOwner,
                indicativeNumberWhatsapp: addVehicle?.indicativeNumberWhatsapp?.value,
                numberPhoneWhatsapp: addVehicle?.numberPhoneWhatsapp,
                email: addVehicle?.email,
                address: addVehicle?.address,
                municipality: addVehicle?.municipalityResidence?.value,
                documentType: addVehicle?.documentType?.value,
                numberDocument: addVehicle?.numberDocument,
                name: addVehicle?.name,
                lastName: addVehicle?.lastName,
                indicativePhone: addVehicle?.indicativeNumber?.value,
                numberPhone: addVehicle?.numberPhone
            }

            let NewDataVehicle = {
                mark: newVehicleInformation?.mark,
                code: newVehicleInformation?.code,
                plate: newVehicleInformation?.plate,
                mileage: newVehicleInformation?.mileage,
                model: newVehicleInformation?.model,
                motorNumber: newVehicleInformation?.motorNumber,
                idTypeFuel: newVehicleInformation?.typeFuel?.value,
                internalNumber: newVehicleInformation?.internalNumber,
                chassisNumber: newVehicleInformation?.chassisNumber,
                serialNumber: newVehicleInformation?.serialNumber,
                idTypeVehicle: newVehicleInformation?.typeVehicle?.value,
                idTypeBodywork: newVehicleInformation?.typeBodywork?.value,
                municipality: newVehicleInformation?.municipalityResidence?.value,
                idTemplateVehicle: newVehicleInformation?.templateVehicle?.id,
                number: newVehicleInformation?.number,
                codeBodyWork: newVehicleInformation?.codeBodyWork,
                SOATExpiration: dayjs(newVehicleInformation?.SOATExpiration).format("YYYY-MM-DD"),
                mechanicalTechnicianExpiration: dayjs(newVehicleInformation?.mechanicalTechnicianExpiration).format("YYYY-MM-DD"),
                operatingCardExpiration: dayjs(newVehicleInformation?.operatingCardExpiration).format("YYYY-MM-DD"),
                civilInsuranceExpiration: dayjs(newVehicleInformation?.civilInsuranceExpiration).format("YYYY-MM-DD"),
                isMaintenance: !newVehicleInformation?.isMaintenance.isTrue,
                rerecordingChassis: newVehicleInformation?.rerecordingChassis.isTrue,
                rerecordingMotor: newVehicleInformation?.rerecordingMotor.isTrue,
                rerecordingSerialNumber: newVehicleInformation?.rerecordingSerialNumber.isTrue,
            }

            let modifiedFields = compareObjects(createOwnerBody, NewDataVehicle);
            let ownerModifiedFields = compareObjects(ownerData, OwnerNewData);

            let vehicleUpdated = false;
            let ownerUpdated = false;

            if (Object.keys(ownerModifiedFields).length > 0) {
                let newOwnerId = null;
                if (ownerData.numberDocument !== OwnerNewData.numberDocument) {
                    newOwnerId = await createOwnerService(ownerData);
                    modifiedFields.idOwner = newOwnerId;
                    await updateInfoVehicleService(params.state?.id, modifiedFields);
                } else {
                    await updateInfoOwnerService({ ...ownerModifiedFields, id: newVehicleInformation?.idOwner });
                }
                if (Object.keys(modifiedFields).length > 0) {
                    if (newOwnerId) {
                        modifiedFields.idOwner = newOwnerId;
                    }
                    await updateInfoVehicleService(params.state?.id, modifiedFields);
                }
                ownerUpdated = true;

            } else if (Object.keys(modifiedFields).length > 0) {
                await updateInfoVehicleService(params.state?.id, modifiedFields);
                vehicleUpdated = true;
            }
            if (vehicleUpdated || ownerUpdated) {
                onClose();
                SuccessToastComponent({
                    html:
                        `<span>${"Vehiculo actualizado exitosamente"}
                  <a class="create-user__text-alert"</a>
                </span>`,
                    position: AlertConst.TOP_END_POSITION_TEXT,
                    timer: 1500
                });
            }

        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.newUsers[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
        }
    }

    const compareObjects = (objectOld, objectNew) => {
        const differences = {};

        const compareProperties = (key, valueOld, valueNew) => {
            if (typeof valueOld === 'object' && typeof valueNew === 'object') {
                const subDifferences = compareObjects(valueOld, valueNew);
                if (Object.keys(subDifferences).length > 0) {
                    differences[key] = subDifferences;
                }
            } else if (valueOld !== valueNew) {
                differences[key] = valueNew;
            }
        };

        const missingProperties = (objOld, objNew) => {
            const missing = {};
            for (const prop in objNew) {
                if (objNew.hasOwnProperty(prop)) {
                    if (!objOld.hasOwnProperty(prop)) {
                        missing[prop] = objNew[prop];
                    } else if (typeof objNew[prop] === 'object' && typeof objOld[prop] === 'object') {
                        const subDifferences = compareObjects(objOld[prop], objNew[prop]);
                        if (Object.keys(subDifferences).length > 0) {
                            missing[prop] = subDifferences;
                        }
                    } else if (objOld[prop] !== objNew[prop]) {
                        missing[prop] = objNew[prop];
                    }
                }
            }
            return missing;
        };

        for (const key in objectOld) {
            if (objectOld.hasOwnProperty(key) && objectNew.hasOwnProperty(key)) {
                compareProperties(key, objectOld[key], objectNew[key]);
            }
        }

        const missingInObjectOld = missingProperties(objectOld, objectNew);
        const missingInObjectNew = missingProperties(objectNew, objectOld);

        Object.assign(differences, missingInObjectOld, missingInObjectNew);

        return differences;
    }
 
    return (
        <>
            <div className="add-vehicle__container">
                <span className="add-vehicle__subtitle">Vehículo</span>
                <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
                <span className="add-vehicle__subtitle">
                    Agregar un vehículo
                </span>
            </div>
            <div className='add-vehicle__content'>
                <div className='grid-x small-12 add-vehicle__content-items'>
                    <div className='small-6 add-vehicle__content-items__item'>
                        <img
                            alt="icon_vehicles_card"
                            src={Assets.SharedIcons.icon_vehicles_card}
                            className='add-vehicle__content-items__icon'
                        />
                        <span className="add-vehicle__content-items__title">Agregar un vehículo</span>
                    </div>
                    <div className='grid-x small-6 justify-content-end add-vehicle__content-items__item'>
                        <button
                            onClick={() => onClose()}
                            className='add-vehicle__content-items__button-close'
                        >
                            <span className='add-vehicle__content-items__close-text'>
                                Cancelar
                            </span>
                            <img
                                alt="icon_square_close"
                                src={Assets.SharedIcons.icon_square_close}
                                className='add-vehicle__content-items__icon'
                            />
                        </button>
                    </div>
                </div>
                <div className='add-vehicle__box'>
                    <VehicleStepperComponent
                        dataStepper={steps}
                        locationStepper={locationStepperState}
                        onStepper={(e) => {
                            setState({
                                ...state,
                                locationStepperState: e,
                            })
                        }}
                    />
                    {locationStepperState === 0 && !isDataVehicle && <VehicleDataComponent />}
                    {locationStepperState === 1 && !isDataVehicle && <InternalVehicleIdentificationComponent />}
                    {locationStepperState === 2 && !isDataVehicle && <OwnerInformationComponent />}
                    <div className={`grid-x small-12 add-vehicle__content-button ${locationStepperState === 0 ? 'justify-content-end' : 'justify-content-between'}`}>
                        {(locationStepperState !== 0) &&
                            (<button
                                onClick={() => handleBack()}
                                className="add-vehicle__button__return"
                            >
                                <img
                                    src={Assets.SharedIcons.icon_back}
                                    alt="icon_back"
                                    className='add-vehicle__button__icon'
                                />
                                <span>Regresar</span>
                            </button>)
                        }
                        <div className='grid-x'>
                            {(locationStepperState === 2)
                                ?
                                    (<button
                                        disabled={isError}
                                        onClick={() => {params.state?.typeAction === 'EDIT_INFO_VEHICLE' ? updateInfoVehicle() : params.state?.typeAction === 'VIEW_INFO_VEHICLE' ? onClose() : addNewVehicle() }}
                                        className={`${params.state?.typeAction === 'VIEW_INFO_VEHICLE' ? "add-vehicle__button__save" : ((params.state?.typeAction === 'EDIT_INFO_VEHICLE' || params.state?.typeAction === undefined) && !isError ? "add-vehicle__button__save" : "add-vehicle__button__save-disabled")}`}

                                    >
                                    <span>
                                        {params.state?.typeAction === 'EDIT_INFO_VEHICLE'
                                            ? 'Actualizar'
                                            : params.state?.typeAction === 'VIEW_INFO_VEHICLE'
                                                ? 'Finalizar'
                                                : 'Crear Vehiculo'
                                        }
                                    </span>
                                    </button>)
                                :
                                    <button
                                        disabled={isError}
                                        onClick={() => handleNext()}
                                        className="add-vehicle__button__next"
                                    >
                                        <span>Siguiente</span>
                                        <img
                                            alt="icon_next"
                                            src={Assets.SharedIcons.icon_next}
                                            className='add-vehicle__button__icon'
                                        />
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = ({ VehiclesReducer }) => {
    const { isError, addVehicle,newVehicleInformation } = VehiclesReducer;
    return {
        isError,
        addVehicle,
        newVehicleInformation,
    };
};

const mapStateToPropsActions = {
    setStateVehicleReducer,
    resetDataVehicleReducer,
    setDataVehiclesKeyStateReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(AddVehiclesPage);