import * as sidesNavTypes from './sides-nav.types';

const INITIAL_STATE = {
	isDrawer: false,
	isSale: false
};


const SidesNavReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case sidesNavTypes.SET_STATE_SIDES_NAV:
			return {
				...state,
				[action.payload.prop]: action.payload.value
			};

		default:
			return state;
	}

};

export default SidesNavReducer;