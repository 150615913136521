//Assets
import { Assets } from '../../../../assets';

//Constants
import { BoardingSheetErrorsConst } from '../../../../core/constants/errors/alerts-app/boarding-sheet.errors.const';
import AlertConst from '../../../../core/constants/alerts.const';

// Components
import ErrorToastComponent from '../../../../shared/components/toast/error-toast/error-toast.component';

//Libraries
import React, { useState } from 'react'
import { AutoComplete, Input } from 'antd';
import dayjs from 'dayjs';

//Services
import { getObtainVehicleByServiceNumberService } from '../../../../services/vehicle.services';

//Styles
import "./modal-transfer.component.scss"

const ModalTransferComponent = (props) => {

    const { 
        itemSelected,
        daySelected,
        setVehicleSelected = () => {},
        observation = () => {}
    } = props;

    const INITIAL_STATE = {
        optionsVehicleSearch: []
    }

    const [state, setState] = useState(INITIAL_STATE)

    const { optionsVehicleSearch } = state

    const filterVehicle = async(filter) => {
        try {
            const listVehicles = await getObtainVehicleByServiceNumberService({
                valueFilter:filter,
                date: dayjs(daySelected).format('YYYY-MM-DD'),
                time: dayjs(daySelected).format('HH:mm:ss'),
                idTravel: itemSelected?.travel?.id
            })

            if (listVehicles.length === 0) {
                ErrorToastComponent({
                    title: BoardingSheetErrorsConst.localError.resultEmpty || BoardingSheetErrorsConst.default,
                    position: AlertConst.TOP_END_POSITION_TEXT,
                    timer: 1500
                })
            }

            setState((prevState) => ({
                ...prevState,
                optionsVehicleSearch: listVehicles
            }))
        } catch {
            // TODO: Implement error alert with code error.
        }
    }

  return (
    <>
        <span className='grid-x text-center modal-transfer__title'>
            Vas a transferir la planilla del vehículo con el código {itemSelected?.vehicle?.plate}
        </span>
        <div className='grid-x modal-transfer__box'>
            <span className='small-6 modal-transfer__subtitle'>Pasajes vendidos</span>
            <span className='small-6 text-right modal-transfer__text'>{itemSelected?.travel?.ticketsSales}/{itemSelected?.travel?.totalSeat}</span>
            <span className='small-6 modal-transfer__subtitle'>Encomiendas</span>
            <span className='small-6 text-right modal-transfer__text'>{itemSelected?.travel?.totalShipping}</span>
        </div>
        <span className='modal-transfer__subtitle'>Busca el Vehículo Disponible</span>
        <AutoComplete
            className="modal-transfer__input"
            onSearch={(e) => {
                const trimmedValue = e.trim();
                if (trimmedValue) {
                    filterVehicle(trimmedValue);
                } else { 
                    setState({ ...state, optionsVehicleSearch: [] });
                }
            }}
            options={optionsVehicleSearch}
            onSelect={(value,options)=> setVehicleSelected(options)}
            onChange={(e) => !e.trim() && setVehicleSelected(null)}
        >
            <Input
                allowClear={{
                    clearIcon: (
                    <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                    />
                    )
                }}
                className="modal-transfer__input__content"
                placeholder="Escribe el Nro. del Vehículo"
                prefix={
                    <img
                    className="modal-transfer__input__search"
                    src={Assets.SharedIcons.icon_search}
                    alt="icon_search"
                    />
                }
            />
        </AutoComplete>
        <span className='modal-transfer__comments'>¿Deseas agregar una observación?</span>
        <Input.TextArea 
            onChange={(e)=>  observation(e?.target?.value)}
            rows={3} 
            placeholder="Escribe aquí tu comentario u observación"
            className='modal-transfer__text-area'
        />
    </>
  )
}

export default ModalTransferComponent