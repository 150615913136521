export const ShiftsErrorsConst = {
  passages: {
    TRVL046: "Ya existe el vehículo en ese turno",
  },
  route: {
    ROLE003: "El rol de usuario no se encuentra en el sistema",
    ROUT006: "El rol asociado al usuario no tiene permisos para realizar esta operación",
    SELL007: "El usuario no tiene sede asociada"
  },
  default: "Ocurrió un error",
};