const SettingsEndPoints =  {
    GET_HEADQUARTERS: `/api/headquarter`,
    GET_ROLE_ALL: `/api/role/all/systemUserRoles`,
    GET_MODULES: `/api/module`,
    CREATE_RESOLUTION: `/api/resolution`,
    GET_ALL_RESOLUTION: `/api/resolution`,
    GET_RESOLUTION_BY_ID: `/api/resolution/:id`,
    UPDATE_RESOLUTION: `/api/resolution/:id`,
    GET_ALL_SERVICE: `/api/serviceType/all`,
    GET_ALL_NUMBER_CONSTANT: `/api/calculationSystemConstant/all`,
    GET_ALL_STRING_CONSTANT: '/api/stringSystemConstant/all',
    UPDATE_NUMBER_CONSTANT: '/api/calculationSystemConstant/:code',
    UPDATE_STRING_CONSTANT: '/api/stringSystemConstant/:code',
    GET_FILTER_USERS: `/api/users/all/pagination`,
    GET_USER_BY_ID: `/api/users/:id`,
    UPDATE_USER_BY_ID: `/api/users/:id`,
    CREATE_NEW_USER: `/api/users`,
    GET_ALL_MONEY_TRANSFER_RANGE: `/api/moneyTransferRange`,
    CREATE_MONEY_TRANSFER_RANGE: `/api/moneyTransferRange`,
    GET_LIST_OF_MUNICIPALITIES: `/api/municipality`,
    UPDATE_BY_ID_MONEY_TRANSFER_RANGE: `/api/moneyTransferRange/:id`,
    DELETE_BY_ID_MONEY_TRANSFER_RANGE: `/api/moneyTransferRange/:id`,
    GET_LIST_PRICE_PASSAGES: `/api/route/all/setting`,
    UPDATE_BY_ID_PRICE_PASSAGES: `/api/route/:id`,
    GET_USER_ALL_PAGINATION_COUNTER: `/api/users/all/pagination/counter`,
    GET_FILTER_INVOICES: `/api/invoice/filterInvoice/:filter`,
    UPDATE_INVOICES: `/api/invoice/changePaymentMethodInvoice`
}

export default SettingsEndPoints