// Libraries
import * as Yup from 'yup';

// Shared
import string from '../../shared/translations/I18n';


/*
* Startup of the login module validators
*/
export const validateLoginSchema = Yup.object().shape({
    user: Yup.string()
    .required(string.t("authentication.validations.required")),
    password: Yup.string()
    .required(string.t("authentication.validations.required"))
});
/*
* End of the login module validators
*/


/*
* Startup of the register module validators
*/

/*
* End of the register module validators
*/


/* 
* Startup of the password recovery module validators
*/
export const validateEmailSchema = Yup.object().shape({
    email: Yup.string()
        .email(string.t("authentication.validations.invalidEmail"))
        .required(string.t("authentication.validations.required"))
});

export const validateFormatPassword = (value) => {
    const errors = {};

    if (value.length < 6 || value.length > 10) {
        errors.eightCharacters = true;
    }
    if (!/\d/.test(value)) {
        errors.number = true;
    }
    if (!/^(?=.*[A-Z])/.test(value)) {
        errors.uppercaseLetter = true;
    }
    if (!/^(?=.*[!@#$%^&*_])/.test(value)) {
        errors.specialCharacter = true;
    }
    if (!/^(?=.*[a-z])/.test(value)) {
        errors.lowercaseLetter = true;
    }

    return errors;
};

/* 
* End of the password recovery module validators
*/