//Actions
import { setDataDriverReducer, setStateDriverReducer } from "../../../../../storage/reducers/drivers/drivers.action";

//Assets
import { Assets } from "../../../../../assets";

// Constants
import AppConst from "../../../../../core/constants/app.const";

//Libraries
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { DatePicker, Input, Select } from "antd";
import { useFormik } from "formik";
import dayjs from "dayjs";

//Styles
import './driver-personal-data.component.scss';

// Services
import { 
  getAllBloodTypesService, 
  //TODO: Commented by new requirement
  // getAllCountriesService 
} from "../../../../../services/drivers.services";
import { getAllDepartmentService, getAllDocumentTypeService, getAllIndicativeNumberService, getAllMunicipalityService } from "../../../../../services/shared.services";

//Utils
import { driverPersonalDataSchema } from "../../../../../utils/form-validations/drivers.validators.utils";

const DriverPersonalDataComponent = (props) => {

  const {
    //Variables
    isDisabled,
    driverFrom,
    //Actions
    setDataDriverReducer,
    setStateDriverReducer
  } = props;

  const { values, errors, touched, handleBlur, setValues, setFieldTouched, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      lastName: '',
      documentType: undefined,
      documentNumber: '',
      date: '',
      //TODO: Commented by new requirement
      // countryOfBirth: undefined,
      departmentOfBirth: undefined,
      municipalityOfBirth: undefined,
      bloodType: undefined,
      telephoneCode: undefined,
      telephone: '',
      contactEmail: '',
      departmentResidence: undefined,
      municipalityResidence: undefined,
      contactAddress: '',
      state: false
    },
    validationSchema: driverPersonalDataSchema,
    onSubmit: () => { },
  });

  const INITIAL_STATE = {
    optionsTypeDocument: [],
    optionsDepartment: [],
    optionsMunicipality: [],
    optionsNumberList: [],
    //TODO: Commented by new requirement
    // optionCountriesList: [],
    optionBloodTypeList: [],
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    optionsTypeDocument, 
    optionsDepartment, 
    optionsMunicipality,
    optionsNumberList, 
    //TODO: Commented by new requirement
    // optionCountriesList, 
    optionBloodTypeList
  } = state;

  useEffect(() => {
    setFieldsByReducer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverFrom])

  useEffect(() => {
    dataSelectorsForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStateDriverReducer('isError', Object.keys(errors).length !== 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const setFieldsByReducer = () => {
    const {
      name, lastName, documentType, documentNumber, date,
      //TODO: Commented by new requirement
      // countryOfBirth, 
      departmentOfBirth, municipalityOfBirth, bloodType, telephoneCode, telephone, contactEmail, departmentResidence,
      municipalityResidence, contactAddress, state
    } = driverFrom;

    setValues({
      name, lastName, documentType, documentNumber, date,
      //TODO: Commented by new requirement
      // countryOfBirth, 
      departmentOfBirth, municipalityOfBirth, bloodType, telephoneCode, telephone, contactEmail, departmentResidence,
      municipalityResidence, contactAddress, state
    })
  }
  
  const dataSelectorsForm = async () => {
    try {
      const results = await Promise.allSettled([
        getAllDocumentTypeService(),
        getAllDepartmentService(),
        getAllIndicativeNumberService(),
        //TODO: Commented by new requirement
        // getAllCountriesService(),
        getAllBloodTypesService(),

      ]);
      let documentTypeList = [];
      let departmentList = [];
      let indicativeNumberList = [];
      //TODO: Commented by new requirement
      // let countriesList = [];
      let bloodTypesList = [];

      for (let index = 0; index < results.length; index++) {
        const elementResponse = results[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              documentTypeList = elementResponse?.value?.map((documentType) => ({
                label: documentType?.name,
                value: documentType?.id
              }));
              break;

            case 1:
              departmentList = elementResponse?.value?.map((department) => ({
                label: department?.name,
                value: department?.id
              }));
              break;

            case 2:
              indicativeNumberList = elementResponse?.value?.map((indicativeNumber) => ({
                label: indicativeNumber?.number,
                value: indicativeNumber?.id
              }));
              setFieldValue("telephoneCode", indicativeNumberList[0]);

              break;
            //TODO: Commented by new requirement
            // case 3:
            //   countriesList = elementResponse?.value?.map((country) => ({
            //     label: country?.name,
            //     value: country?.id
            //   }));
            //   break;

            case 3:
              bloodTypesList = elementResponse?.value?.map((bloodType) => ({
                label: bloodType?.name,
                value: bloodType?.id
              }));
              break;
              
            default:
              break;
          }
        }
      }
      setState({
        ...state,
        optionsTypeDocument: documentTypeList,
        optionsDepartment: departmentList,
        optionsNumberList: indicativeNumberList,
        //TODO: Commented by new requirement
        // optionCountriesList: countriesList,
        optionBloodTypeList: bloodTypesList
      });
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  const onChange = async (data, target) => {
    const value = data && data.target ? data.target.value || '' : data;
    const driverPersonalData = { ...values, [target]: value };
    if (target === 'departmentResidence' || target === 'departmentOfBirth') {
      driverPersonalData[target] = value;
      if (target === 'departmentResidence') {
        driverPersonalData.municipalityResidence = undefined;
      } else if (target === 'departmentOfBirth') {
        driverPersonalData.municipalityOfBirth = undefined;
        setDataDriverReducer('driverFrom', 'municipalityOfBirth', undefined);
      }
      const municipalityList = await getAllMunicipality(value?.value);
      setState({
        ...state,
        optionsMunicipality: municipalityList
      })
      setFieldTouched("municipalityResidence", true);
    };
    setValues(driverPersonalData);
    setDataDriverReducer('driverFrom', target, value)
  };

  const getAllMunicipality = async (idDepartment) => {
    try {
      const res = await getAllMunicipalityService(idDepartment);
      return res.map((municipality) => ({ label: municipality.name, value: municipality.id }));
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  return (
    <div className='grid-x'>
      <div className="small-12 medium-6 driver-personal-data__content">
        <label className=" grid-x driver-personal-data__label">
          Nombre del conductor
          <div className="driver-personal-data__label__icon" />
        </label>
        <Input
          value={values.name}
          disabled={isDisabled}
          onChange={(value) => onChange(value, 'name')}
          onBlur={(value) => handleBlur('name')(value)}
          status={errors.name && touched.name ? "error" : ""}
          className="driver-personal-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={'Escribe el nombre del conductor'}
        />
        <span className="grid-x driver-personal-data__errors">{touched.name ? errors.name : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content driver-personal-data__content__right">
        <label className=" grid-x driver-personal-data__label driver-personal-data__label--mod">
          Apellidos del conductor
          <div className="driver-personal-data__label__icon" />
        </label>
        <Input
          value={values.lastName}
          disabled={isDisabled}
          onChange={(value) => onChange(value, 'lastName')}
          onBlur={(value) => handleBlur('lastName')(value)}
          status={errors.lastName && touched.lastName ? "error" : ""}
          className="driver-personal-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={"Escribe el apellido del conductor"}
        />
        <span className="grid-x driver-personal-data__errors">{touched.lastName ? errors.lastName : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content">
        <label className="grid-x driver-personal-data__label">
          Tipo de Documento
          <div className="driver-personal-data__label__icon" />
        </label>
        <Select
          value={values.documentType}
          className="driver-personal-data__select"
          disabled={isDisabled}
          onChange={(e, event) => onChange(event, 'documentType')}
          onBlur={(value) => handleBlur('documentType')(value)}
          status={errors.documentType && touched.documentType ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsTypeDocument}
          placeholder={'Selecciona el tipo de documento'}
        />
        <span className="grid-x driver-personal-data__errors">{touched.documentType ? errors.documentType : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content driver-personal-data__content__right">
        <label className=" grid-x driver-personal-data__label driver-personal-data__label--mod">
          Número de Documento
          <div className="driver-personal-data__label__icon" />
        </label>
        <Input
          value={values.documentNumber}
          disabled={isDisabled}
          onChange={(value) => onChange(value, 'documentNumber')}
          onBlur={(value) => handleBlur('documentNumber')(value)}
          status={errors.documentNumber && touched.documentNumber ? "error" : ""}
          className="driver-personal-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={'Escribe el número del documento'}
        />
        <span className="grid-x driver-personal-data__errors">{touched.documentNumber ? errors.documentNumber : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content">
        <label className="grid-x driver-personal-data__label">
          Fecha de nacimiento
          <div className="driver-personal-data__label__icon" />
        </label>
        <DatePicker
          className="driver-personal-data__date"
          disabled={isDisabled}
          disabledDate={(current) => {
            return( current && current > dayjs().subtract(18, 'year'))}
          }
          value={values.date ? dayjs(values.date) : null}
          onChange={(date) => onChange(date,"date")}
          status={errors.date && touched.date ? 'error' : ''}
          onBlur={() => handleBlur('date')}
          format={"DD/MM/YYYY"}
          allowClear={{
            clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
          }}
          popupClassName="driver-personal-data__date__picker"
          placeholder="DD/MM/AAAA"
          suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
          superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
          superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
          nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
          prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
        />
        <span className="grid-x driver-personal-data__errors">{touched.date ? errors.date : null}</span>
      </div>
      {/* TODO: Commented by new requirement */}
      {/* <div className="small-12 medium-6 driver-personal-data__content driver-personal-data__content__right">
        <label className=" grid-x driver-personal-data__label driver-personal-data__label--mod">
          Pais de nacimiento
          <div className="driver-personal-data__label__icon" />
        </label>
        <Select
          value={values.countryOfBirth}
          className="driver-personal-data__select"
          disabled={isDisabled}
          onChange={(e, event) => onChange(event, 'countryOfBirth')}
          onBlur={(value) => handleBlur('countryOfBirth')(value)}
          status={errors.countryOfBirth && touched.countryOfBirth ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionCountriesList}
          placeholder={'Seleccione pais de nacimiento'}
        />
        <span className="grid-x driver-personal-data__errors">{touched.countryOfBirth ? errors.countryOfBirth : null}</span>
      </div> */}
      <div className="small-12 medium-6 driver-personal-data__content driver-personal-data__content__right">
        <label className=" grid-x driver-personal-data__label driver-personal-data__label--mod">
          Dpto. de Nacimiento
          <div className="driver-personal-data__label__icon" />
        </label>
        <Select
          value={values.departmentOfBirth}
          className="driver-personal-data__select"
          disabled={isDisabled}
          onChange={(e, event) => onChange(event, 'departmentOfBirth')}
          onBlur={(value) => handleBlur('departmentOfBirth')(value)}
          status={errors.departmentOfBirth && touched.departmentOfBirth ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsDepartment}
          placeholder={'Seleccione departamento de nacimiento'}
        />
        <span className="grid-x driver-personal-data__errors">{touched.departmentOfBirth ? errors.departmentOfBirth : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content">
        <label className=" grid-x driver-personal-data__label">
          Municipio de nacimiento
          <div className="driver-personal-data__label__icon" />
        </label>
        <Select
          value={values.municipalityOfBirth}
          className="driver-personal-data__select"
          onChange={(e, event) => onChange(event, 'municipalityOfBirth')}
          onBlur={(value) => handleBlur('municipalityOfBirth')(value)}
          disabled={(isDisabled || !values?.departmentOfBirth)}
          status={errors.municipalityOfBirth && touched.municipalityOfBirth ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsMunicipality}
          placeholder={'Seleccione municipio de nacimiento'}
        />
        <span className="grid-x driver-personal-data__errors">{touched.municipalityOfBirth ? errors.municipalityOfBirth : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content driver-personal-data__content__right">
        <label className=" grid-x driver-personal-data__label driver-personal-data__label--mod">
          Tipo de sangre
          <div className="driver-personal-data__label__icon" />
        </label>
        <Select
          disabled={isDisabled}
          value={values.bloodType}
          options={optionBloodTypeList}
          className="driver-personal-data__select"
          placeholder={'Escriba el tipo de sangre'}
          onBlur={(value) => handleBlur('bloodType')(value)}
          onChange={(e, event) => onChange(event, 'bloodType')}
          status={errors.bloodType && touched.bloodType ? "error" : ""}
          suffixIcon={<img alt="icon_down_arrow" src={Assets.SharedIcons.icon_down_arrow}/>}
        />
        <span className="grid-x driver-personal-data__errors">{touched.bloodType ? errors.bloodType : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content">
        <label className=" grid-x driver-personal-data__label">
          Teléfono de contacto
          <div className="driver-personal-data__label__icon" />
        </label>
        <div className="grid-x driver-personal-data__label--mod justify-content-between">
          <Select
            disabled={isDisabled}
            className="driver-personal-data__select indicative-number"
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsNumberList}
            value={values.telephoneCode}
            onChange={(e, event) => onChange(event, 'telephoneCode')}
            onBlur={(value) => handleBlur('telephoneCode')(value)}
            status={errors.telephoneCode && touched.telephoneCode ? "error" : ""}
          />
          <Input
            value={values.telephone}
            disabled={isDisabled}
            onChange={(value) => onChange(value, 'telephone')}
            onBlur={(value) => handleBlur('telephone')(value)}
            status={errors.telephone && touched.telephone ? "error" : ""}
            className="driver-personal-data__input__content phone-number"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={'Número de contacto'}
          />
          <span className="grid-x client-data__errors">{touched.telephone ? errors.telephone : null}</span>
        </div>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content driver-personal-data__content__right">
        <label className=" grid-x driver-personal-data__label driver-personal-data__label--mod">
          {'Correo de Contacto (Opcional)'}
        </label>
        <Input
          value={values.contactEmail}
          disabled={isDisabled}
          onChange={(value) => onChange(value, 'contactEmail')}
          onBlur={(value) => handleBlur('contactEmail')(value)}
          status={errors.contactEmail && touched.contactEmail ? "error" : ""}
          className="driver-personal-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={'Escribe el correo electrónico'}
        />
        <span className="grid-x driver-personal-data__errors">{touched.contactEmail ? errors.contactEmail : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content">
        <label className=" grid-x driver-personal-data__label">
          {'Dpto. de Residencia (Opcional)'}
        </label>
        <Select
          value={values.departmentResidence?.value ? values.departmentResidence : undefined}
          className="driver-personal-data__select"
          disabled={isDisabled}
          options={optionsDepartment}
          placeholder={'Seleccione departamento'}
          onChange={(e, event) => onChange(event, 'departmentResidence')}
          onBlur={(value) => handleBlur('departmentResidence')(value)}
          status={errors.departmentResidence && touched.departmentResidence ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
        />
        <span className="grid-x driver-personal-data__errors">{touched.departmentResidence ? errors.departmentResidence : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content driver-personal-data__content__right">
        <label className="grid-x driver-personal-data__label driver-personal-data__label--mod">
          {'Municipio de Residencia (Opcional)'}
        </label>
        <Select
          value={values.municipalityResidence?.value ? values.municipalityResidence : undefined}
          className="driver-personal-data__select"
          onChange={(e, event) => onChange(event, 'municipalityResidence')}
          onBlur={(value) => handleBlur('municipalityResidence')(value)}
          disabled={(isDisabled || !values?.departmentResidence)}
          status={errors.municipalityResidence && touched.municipalityResidence ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsMunicipality}
          placeholder={'Seleccione municipio'}
        />
        <span className="grid-x driver-personal-data__errors">{touched.municipalityResidence ? errors.municipalityResidence : null}</span>
      </div>
      <div className="small-12 medium-6 driver-personal-data__content">
        <label className=" grid-x driver-personal-data__label">
          {'Dirección de Contacto (Opcional)'}
        </label>
        <Input
          value={values.contactAddress}
          disabled={isDisabled}
          onChange={(value) => onChange(value, 'contactAddress')}
          onBlur={(value) => handleBlur('contactAddress')(value)}
          status={errors.contactAddress && touched.contactAddress ? "error" : ""}
          className="driver-personal-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={"Escribe una dirección de contacto"}
        />
        <span className="grid-x driver-personal-data__errors">{touched.contactAddress ? errors.contactAddress : null}</span>
      </div>
    </div>
  )
};

const mapStateToProps = ({ DriversReducer }) => {
  const { driverFrom, isDisabled } = DriversReducer;
  return {
    driverFrom,
    isDisabled
  };
};

const mapStateToPropsActions = {
  setDataDriverReducer,
  setStateDriverReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(DriverPersonalDataComponent);