// Libraries
import * as Yup from 'yup';

/*
* Startup of the validators of the vehicles module
*/
export const vehicleDataSchema = Yup.object().shape({
    typeVehicle: Yup.mixed()
        .required('Requerido'),
    mark: Yup.string()
        .required('Requerido'),
    model: Yup.string()
        .required('Requerido'),
    plate: Yup.string()
        .required('Requerido'),
    typeFuel: Yup.mixed()
        .required('Requerido'),
    internalNumber: Yup.string()
        .required('Requerido'),
    templateVehicle: Yup.mixed()
        .required('Requerido, seleccione una plantilla'),
    codeBodyWork: Yup.string()
        .required('Requerido'),
    typeBodywork: Yup.mixed()
        .required('Requerido'),
    code: Yup.string()
        .required('Requerido'),
    number: Yup.string()
        .required('Requerido'),
    operatingCardExpiration: Yup.string()
        .nullable()
        .required('Requerido'),
    civilInsuranceExpiration: Yup.string()
        .nullable()
        .required('Requerido'),
});

export const internalVehicleIdentificationSchema = Yup.object().shape({
    motorNumber: Yup.string()
        .required('Requerido'),
    chassisNumber: Yup.string()
        .required('Requerido'),
    serialNumber: Yup.string()
        .required('Requerido'),
    SOATExpiration: Yup.string()
        .nullable()
        .required('Requerido'),
    mechanicalTechnicianExpiration: Yup.string()
        .nullable()
        .required('Requerido'),
})

export const ownerInformationSchema = Yup.object().shape({
    documentType: Yup.mixed()
        .required('Requerido'),
    numberDocument: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .min(6, 'Minimo 6 digitos')
        .max(10, 'Maximo 10 digitos')
        .required('Requerido'),
    name: Yup.string()
        .required('Requerido'),
    lastName: Yup.string()
        .required('Requerido'),
    indicativeNumber: Yup.mixed()
        .required('Requerido'),
    numberPhone: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .length(10, 'Numero no valido')
        .required('Requerido'),
    indicativeNumberWhatsapp: Yup.mixed()
        .required('Requerido'),
    numberPhoneWhatsapp: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .length(10, 'Numero no valido')
        .required('Requerido'),
    departmentResidence: Yup.mixed()
        .required('Requerido'),
    municipalityResidence: Yup.mixed()
        .required('Requerido'),
    address: Yup.mixed()
        .required('Requerido'),
    email: Yup.string()
        .email('Email no valido')
        .required('Requerido'),
})
/*
* End of the vehicles module validators
*/