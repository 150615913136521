//Actions
import { setDataVehiclesStateReducer, setStateVehicleReducer } from '../../../../../storage/reducers/vehicles/vehicles.action';

//Assets
import { Assets } from '../../../../../assets';

//TODO: Commented on new requirement not to attach photo documents
//Const
// import AlertConst from '../../../../../core/constants/alerts.const';

//Components
// import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';

//Libraries
import dayjs from "dayjs";
import { useFormik } from "formik";
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Checkbox, DatePicker, Input } from "antd";

//Styles
import './internal-vehicle-identification.component.scss';

//Utils
import { internalVehicleIdentificationSchema } from '../../../../../utils/form-validations/vehicles.validators.utils';


const InternalVehicleIdentificationComponent = (props) => {

  const {
    //Actions
    setStateVehicleReducer,
    setDataVehiclesStateReducer,
    //Variables
    addVehicle,
    isDisabled,
  } = props;

  const { values, errors, touched, handleBlur, setValues } = useFormik({
    initialValues: {
      motorNumber: '',
      rerecordingMotor: {
        isTrue: false,
        isFalse: true
      },
      chassisNumber: '',
      rerecordingChassis: {
        isTrue: false,
        isFalse: true
      },
      serialNumber: '',
      rerecordingSerialNumber: {
        isTrue: false,
        isFalse: true
      },
      SOATExpiration: '',
      mechanicalTechnicianExpiration: '',
      //TODO: Commented on new requirement not to attach photo documents
      // soatPhoto: null,
      // mechanicalTechnicianPhoto: null,
    },
    validationSchema: internalVehicleIdentificationSchema,
    onSubmit: () => { },
  });

  //TODO: Commented on new requirement not to attach photo documents
  // const stateProperties = {
  //   soatPhoto: 'selectImgSoat',
  //   mechanicalTechnicianPhoto: 'selectImgMechanicalTechnician',
  // };

  // const INITIAL_STATE = {
  //   selectImgSoat: "",
  //   selectImgMechanicalTechnician: "",
  // };

  // const [state, setState] = useState(INITIAL_STATE);
  // const { selectImgSoat, selectImgMechanicalTechnician } = state;

  useEffect(() => {
    setFieldsByReducer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStateVehicleReducer('isError', Object.keys(errors).length !== 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const setFieldsByReducer = () => {
    const {
      motorNumber, rerecordingMotor, chassisNumber,
      rerecordingChassis, serialNumber, rerecordingSerialNumber,
      SOATExpiration, mechanicalTechnicianExpiration
    } = addVehicle;
    setValues({
      motorNumber, rerecordingMotor, chassisNumber,
      rerecordingChassis, serialNumber, rerecordingSerialNumber,
      SOATExpiration, mechanicalTechnicianExpiration
    })
  }

  const onChange = async (data, target) => {
    //TODO: Commented on new requirement not to attach photo documents
    // if (target === 'soatPhoto' || target === 'mechanicalTechnicianPhoto') {
    //   const { target: { value: fileImg, files } } = data;
    //   const fileValue = files[0];
    //   const newStateProperty = stateProperties[target];
    //   if (fileValue?.type === "image/jpg" || fileValue?.type === "image/jpeg" || fileValue?.type === "image/png") {
    //     if (fileValue?.size <= 4194304) {
    //       setFieldValue(target, fileValue);
    //       setState((prevState) => ({
    //         ...prevState,
    //         [newStateProperty]: fileImg,
    //       }));
    //     } else {
    //       setFieldValue(target, "")
    //       setState((prevState) => ({
    //         ...prevState,
    //         [newStateProperty]: "",
    //       }));
    //       ErrorToastComponent({
    //         title: 'Lo siento, has superado el límite de tamaño de la imagen. Por favor, selecciona una imagen más pequeña.',
    //         position: AlertConst.TOP_END_POSITION_TEXT,
    //         timer: 1500
    //       })
    //     }
    //   } else {
    //     setState((prevState) => ({
    //       ...prevState,
    //       [newStateProperty]: "",
    //     }));
    //     if (fileValue) {
    //       ErrorToastComponent({
    //         title: 'Lo siento, el tipo de archivo no es compatible',
    //         position: AlertConst.TOP_END_POSITION_TEXT,
    //         timer: 1500
    //       })
    //     }
    //   }
    // } else {
      const value = data && data.target ? data.target.value || '' : data;
      const internalVehicleIdentificationData = { ...values, [target]: value };
      setValues(internalVehicleIdentificationData);
      setDataVehiclesStateReducer('addVehicle', target, value)
    // }
  };

  const onChangeCheckbox = (value, target, option) => {
    setValues((prevValues) => ({
      ...prevValues,
      [option]: {
        ...prevValues[option],
        [target]: value,
        [(target === 'isTrue') ? 'isFalse' : 'isTrue']: !value,
      },
    }));
    setDataVehiclesStateReducer(
      'addVehicle',
      option,
      {
        [target]: value,
        [(target === 'isTrue') ? 'isFalse' : 'isTrue']: !value,
      }
    )
  };

  return (
    <div className='grid-x internal-vehicle-identification__content__button'>
      <div className='grid-y small-12 internal-vehicle-identification__content internal-vehicle-identification__content-titles'>
        <span className='internal-vehicle-identification__title'>Identidad interna del vehículo</span>
        <span className='internal-vehicle-identification__subtitle'>Nos ayudará a conocer el estado actualizado del vehículo y sus principales características.</span>
      </div>
      <div className='small-12 medium-4 internal-vehicle-identification__content'>
        <label className=" grid-x internal-vehicle-identification__label">
          Número de motor
          <div className="internal-vehicle-identification__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          value={values.motorNumber}
          placeholder={'Escribe el número interno'}
          onChange={(value) => onChange(value, 'motorNumber')}
          onBlur={(value) => handleBlur('motorNumber')(value)}
          className="internal-vehicle-identification__input__content"
          status={errors.motorNumber && touched.motorNumber ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className="grid-x internal-vehicle-identification__errors">{touched.motorNumber ? errors.motorNumber : null}</span>
      </div>
      <div className='grid-x small-12 medium-2 internal-vehicle-identification__content'>
        <span className='grid-x small-10 justify-content-center internal-vehicle-identification__label'>Regrabado</span>
        <div className='grid-x internal-vehicle-identification__content-check'>
          <div className="internal-vehicle-identification__content-check__check">
            Sí
            <Checkbox
              disabled={isDisabled}
              checked={values.rerecordingMotor.isTrue}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'isTrue', 'rerecordingMotor')}
            />
          </div>
          <div className="internal-vehicle-identification__content-check__check">
            No
            <Checkbox
              disabled={isDisabled}
              checked={values.rerecordingMotor.isFalse}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'isFalse', 'rerecordingMotor')}
            />
          </div>
        </div>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle internal-vehicle-identification__content'>
        <label className="grid-x internal-vehicle-identification__label internal-vehicle-identification__label--mod">
          Número de chasis
          <div className="internal-vehicle-identification__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          value={values.chassisNumber}
          placeholder={'Escribe el número interno'}
          onChange={(value) => onChange(value, 'chassisNumber')}
          onBlur={(value) => handleBlur('chassisNumber')(value)}
          className="internal-vehicle-identification__input__content"
          status={errors.chassisNumber && touched.chassisNumber ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className="grid-x internal-vehicle-identification__errors">{touched.chassisNumber ? errors.chassisNumber : null}</span>
      </div>
      <div className='grid-x small-12 medium-2 internal-vehicle-identification__content'>
        <span className='grid-x small-10 justify-content-center internal-vehicle-identification__label'>Regrabado</span>
        <div className='grid-x internal-vehicle-identification__content-check'>
          <div className="internal-vehicle-identification__content-check__check">
            Sí
            <Checkbox
              disabled={isDisabled}
              checked={values.rerecordingChassis.isTrue}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'isTrue', 'rerecordingChassis')}
            />
          </div>
          <div className="internal-vehicle-identification__content-check__check">
            No
            <Checkbox
              disabled={isDisabled}
              checked={values.rerecordingChassis.isFalse}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'isFalse', 'rerecordingChassis')}
            />
          </div>
        </div>
      </div>
      <div className='small-12 medium-3 internal-vehicle-identification__content'>
        <label className=" grid-x internal-vehicle-identification__label">
          Número de serie
          <div className="internal-vehicle-identification__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          value={values.serialNumber}
          placeholder={'Escribe el número interno'}
          onChange={(value) => onChange(value, 'serialNumber')}
          onBlur={(value) => handleBlur('serialNumber')(value)}
          className="internal-vehicle-identification__input__content"
          status={errors.serialNumber && touched.serialNumber ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className="grid-x internal-vehicle-identification__errors">{touched.serialNumber ? errors.serialNumber : null}</span>
      </div>
      <div className='grid-x small-12 medium-2 internal-vehicle-identification__content'>
        <span className='grid-x small-10 justify-content-center internal-vehicle-identification__label'>Regrabado</span>
        <div className='grid-x internal-vehicle-identification__content-check'>
          <div className="internal-vehicle-identification__content-check__check">
            Sí
            <Checkbox
              disabled={isDisabled}
              checked={values.rerecordingSerialNumber.isTrue}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'isTrue', 'rerecordingSerialNumber')}
            />
          </div>
          <div className="internal-vehicle-identification__content-check__check">
            No
            <Checkbox
              disabled={isDisabled}
              checked={values.rerecordingSerialNumber.isFalse}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'isFalse', 'rerecordingSerialNumber')}
            />
          </div>
        </div>
      </div>
      <div className='grid-x small-12 medium-7'>
        <div className="small-6 internal-vehicle-identification__content">
          <label className=" grid-x internal-vehicle-identification__label">
            Vencimiento del SOAT
            <div className="internal-vehicle-identification__label__icon" />
          </label>
          <DatePicker
            disabled={isDisabled}
            format={"DD/MM/YYYY"}
            placeholder="DD/MM/AAAA"
            className="internal-vehicle-identification__date"
            onBlur={() => handleBlur('SOATExpiration')}
            popupClassName="internal-vehicle-identification__date__picker"
            value={ dayjs(values.SOATExpiration).isValid()? dayjs(values.SOATExpiration) : null}
            onChange={(date) => onChange(date, 'SOATExpiration')}
            status={errors.SOATExpiration && touched.SOATExpiration ? "error" : ""}
            allowClear={{
              clearIcon: (<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
            }}
            suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
            superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
            superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
            nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
            prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
          />
          <span className="grid-x internal-vehicle-identification__errors">{touched.SOATExpiration ? errors.SOATExpiration : ""}</span>
        </div>
        <div className="small-6 internal-vehicle-identification__content internal-vehicle-identification__content__right">
          <label className="grid-x internal-vehicle-identification__label internal-vehicle-identification__label--mod">
            Vencimiento de Técnico mecánica
            <div className="internal-vehicle-identification__label__icon" />
          </label>
          <DatePicker
            disabled={isDisabled}
            format={"DD/MM/YYYY"}
            placeholder="DD/MM/AAAA"
            className="internal-vehicle-identification__date"
            onBlur={() => handleBlur('mechanicalTechnicianExpiration')}
            popupClassName="internal-vehicle-identification__date__picker"
            value={ dayjs(values.mechanicalTechnicianExpiration).isValid()? dayjs(values.mechanicalTechnicianExpiration) : null}
            onChange={(date) => onChange(date, 'mechanicalTechnicianExpiration')}
            status={errors.mechanicalTechnicianExpiration && touched.mechanicalTechnicianExpiration ? "error" : ""}
            allowClear={{
              clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
            }}
            suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
            superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
            superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
            nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
            prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
          />
          <span className="grid-x internal-vehicle-identification__errors">{touched.mechanicalTechnicianExpiration ? errors.mechanicalTechnicianExpiration : ""}</span>
        </div>
      </div>
      {/* TODO: Commented on new requirement not to attach photo documents */}
      {/* <div className='grid-x small-12 medium-6 internal-vehicle-identification__content'>
        <label className=" grid-x internal-vehicle-identification__label internal-vehicle-identification__label--mod">
          Adjuntar Seguro de accidentes de tránsito (SOAT)
          <div className="internal-vehicle-identification__label__icon" />
        </label>
        <div className='internal-vehicle-identification__upload-photo'>
          <img
            alt="icon_id_card"
            className={values.soatPhoto ? 'internal-vehicle-identification__upload-photo__photo-uploaded' : ''}
            src={values.soatPhoto ? ((typeof values.soatPhoto === "string") ? values.soatPhoto : URL.createObjectURL(values.soatPhoto)) : Assets.SharedIcons.icon_id_card}
          />
          <div className='grid-y align-center-middle'>
            <input
              type="file"
              id="filePhotoSoat"
              disabled={isDisabled}
              value={selectImgSoat}
              accept="image/jpg, image/jpeg, image/png"
              className='internal-vehicle-identification__hidden'
              onChange={(event) => onChange(event, 'soatPhoto')}
            />
            <label className={`internal-vehicle-identification__upload-photo__charge-photo-label ${isDisabled ? "internal-vehicle-identification__upload-photo__disabled" : ""}`} htmlFor="filePhotoSoat">
              <span className="internal-vehicle-identification__upload-photo__charge-photo-label__text">Subir fotos</span>
              <img
                src={Assets.SharedIcons.icon_up_arrow_white}
                alt="icon_down_arrow"
              />
            </label>
            <span className='internal-vehicle-identification__upload-photo__text'>Anexa fotografía del vehículo en donde se observe la placa. Los tipos de formatos permisos son: JPG , JPEG o PNG.</span>
          </div>
        </div>
      </div>
      <div className='small-12 medium-6 internal-vehicle-identification__content__right'>
        <label className=" grid-x internal-vehicle-identification__label internal-vehicle-identification__label--mod">
          Adjuntar Revisión tecnico mecánica
          <div className="internal-vehicle-identification__label__icon" />
        </label>
        <div className='internal-vehicle-identification__upload-photo'>
          <img
            alt="icon_id_card"
            className={values.mechanicalTechnicianPhoto ? 'internal-vehicle-identification__upload-photo__photo-uploaded' : ''}
            src={values.mechanicalTechnicianPhoto ? ((typeof values.mechanicalTechnicianPhoto === "string") ? values.mechanicalTechnicianPhoto : URL.createObjectURL(values.mechanicalTechnicianPhoto)) : Assets.SharedIcons.icon_id_card}
          />
          <div className='grid-y align-center-middle'>
            <input
              type="file"
              disabled={isDisabled}
              id="filePhotoMechanicalTechnician"
              value={selectImgMechanicalTechnician}
              accept="image/jpg, image/jpeg, image/png"
              className='internal-vehicle-identification__hidden'
              onChange={(event) => onChange(event, 'mechanicalTechnicianPhoto')}
            />
            <label className={`internal-vehicle-identification__upload-photo__charge-photo-label ${isDisabled ? "internal-vehicle-identification__upload-photo__disabled" : ""}`} htmlFor="filePhotoMechanicalTechnician">
              <span className="internal-vehicle-identification__upload-photo__charge-photo-label__text">Subir fotos</span>
              <img
                src={Assets.SharedIcons.icon_up_arrow_white}
                alt="icon_down_arrow"
              />
            </label>
            <span className='internal-vehicle-identification__upload-photo__text'>Anexa fotografía del vehículo en donde se observe la placa. Los tipos de formatos permisos son: JPG , JPEG o PNG.</span>
          </div>
        </div>
      </div> */}
    </div>
  )
};

const mapStateToProps = ({ VehiclesReducer }) => {
  const { addVehicle, isDisabled } = VehiclesReducer;
  return {
    addVehicle,
    isDisabled
  };
};

const mapStateToPropsActions = {
  setStateVehicleReducer,
  setDataVehiclesStateReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(InternalVehicleIdentificationComponent);