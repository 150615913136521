//Actions
import { setDataVehiclesKeyStateReducer, setDataVehiclesStateReducer, setStateVehicleReducer } from '../../../../../storage/reducers/vehicles/vehicles.action';

//Assets
import { Assets } from '../../../../../assets';

//Components
//TODO: Commented on new requirement not to attach photo documents
// import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';

//Constants
import AppConst from '../../../../../core/constants/app.const';
//TODO: Commented on new requirement not to attach photo documents
// import AlertConst from '../../../../../core/constants/alerts.const';

//Libraries
import { useFormik } from 'formik';
import { Input, Select } from 'antd';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

//Services
import {
  getAllDepartmentService, getAllDocumentTypeService,
  getAllIndicativeNumberService, getAllMunicipalityService
} from '../../../../../services/shared.services';

//Styles
import './owner-information.component.scss';

//Utils
import { ownerInformationSchema } from '../../../../../utils/form-validations/vehicles.validators.utils';

const OwnerInformationComponent = (props) => {

  const {
    //Actions
    setStateVehicleReducer,
    setDataVehiclesStateReducer,
    setDataVehiclesKeyStateReducer,
    //Variables
    addVehicle,
    isDisabled,
    newVehicleInformation
  } = props;

  let params = useLocation(); 

  const { values, errors, touched, handleBlur, setValues, setFieldTouched } = useFormik({
    initialValues: {
      documentType: undefined,
      numberDocument:	'',
      name: '',
      lastName: '',
      indicativeNumber: undefined,
      numberPhone: '',
      indicativeNumberWhatsapp: undefined,
      numberPhoneWhatsapp: '',
      departmentResidence: undefined,
      municipalityResidence: undefined,
      address: '',
      email: ''
      //TODO: Commented on new requirement not to attach photo documents
      // identityCardPhoto: null
    },
    validationSchema: ownerInformationSchema,
    onSubmit: () => { },
  });

  const INITIAL_STATE = {
    //TODO: Commented on new requirement not to attach photo documents
    // selectImgIdentityCard: "",
    optionsTypeDocument: [],
    optionsDepartment: [],
    optionsMunicipality: [],
    optionsNumberList: [],
  };

  const [state, setState] = useState(INITIAL_STATE);
  const { 
    //TODO: Commented on new requirement not to attach photo documents
    // selectImgIdentityCard,
    optionsTypeDocument, 
    optionsDepartment, 
    optionsMunicipality, 
    optionsNumberList 
  } = state;

  useEffect(() => {
    dataSelectorsForm();
    setFieldsByReducer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStateVehicleReducer('isError', Object.keys(errors).length !== 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const setFieldsByReducer = () => {
    const {
      documentType, numberDocument, name,
      lastName, indicativeNumber, numberPhone,
      indicativeNumberWhatsapp, numberPhoneWhatsapp, departmentResidence,
      municipalityResidence, address, email
    } = params?.state?.typeAction === 'EDIT_INFO_VEHICLE' ? newVehicleInformation : addVehicle;
    setValues({
      documentType, numberDocument, name,
      lastName, indicativeNumber, numberPhone,
      indicativeNumberWhatsapp, numberPhoneWhatsapp, departmentResidence,
      municipalityResidence, address, email
    })
  };

  const dataSelectorsForm = async () => {
    try {
      const results = await Promise.allSettled([
        getAllDocumentTypeService(),
        getAllDepartmentService(),
        getAllIndicativeNumberService()
      ]);
      let documentTypeList = [];
      let departmentList = [];
      let indicativeNumberList = [];

      for (let index = 0; index < results.length; index++) {
        const elementResponse = results[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              documentTypeList = elementResponse?.value?.map((documentType) => ({
                label: documentType?.name,
                value: documentType?.id
              }));
              break;
            case 1:
              departmentList = elementResponse?.value?.map((department) => ({
                label: department?.name,
                value: department?.id
              }));
              break;
            case 2:
              indicativeNumberList = elementResponse?.value?.map((indicativeNumber) => ({
                label: indicativeNumber?.number,
                value: indicativeNumber?.id
              }));
              let setCodes = {};
              setCodes = {
                indicativeNumber: addVehicle?.indicativeNumber ? addVehicle.indicativeNumber : indicativeNumberList[0],
                indicativeNumberWhatsapp: addVehicle?.indicativeNumberWhatsapp ? addVehicle.indicativeNumberWhatsapp : indicativeNumberList[0],
              };
              setValues(prevValues => ({
                ...prevValues,
                ...setCodes
              }));
              setDataVehiclesKeyStateReducer('addVehicle', setCodes);

              break;
            default:
              break;
          }
        }
      }
      setState({
        ...state,
        optionsTypeDocument: documentTypeList,
        optionsDepartment: departmentList,
        optionsNumberList: indicativeNumberList,
      });
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  const onChange = async (data, target) => {
    // TODO: Commented on new requirement not to attach photo documents
    // if (target === 'identityCardPhoto') {
    //   const { target: { value: fileImg, files } } = data;
    //   const fileValue = files[0];
    //   if (fileValue?.type === "image/jpg" || fileValue?.type === "image/jpeg" || fileValue?.type === "image/png") {
    //     if (fileValue?.size <= 4194304) {
    //       setFieldValue(target, fileValue)
    //       setState({
    //         ...state,
    //         selectImgIdentityCard: fileImg
    //       })
    //     } else {
    //       setFieldValue(target, "")
    //       setState({
    //         ...state,
    //         selectImgIdentityCard: ""
    //       })
    //       ErrorToastComponent({
    //         title: 'Lo siento, has superado el límite de tamaño de la imagen. Por favor, selecciona una imagen más pequeña.',
    //         position: AlertConst.TOP_END_POSITION_TEXT,
    //         timer: 1500
    //       })
    //     }
    //   } else {
    //     setState({
    //       ...state,
    //       selectImgIdentityCard: ""
    //     })
    //     if (fileValue) {
    //       ErrorToastComponent({
    //         title: 'Lo siento, el tipo de archivo no es compatible',
    //         position: AlertConst.TOP_END_POSITION_TEXT,
    //         timer: 1500
    //       })
    //     }
    //   }
    // } else {
      const value = data && data.target ? data.target.value || '' : data;
      const ownerInformationData = { ...values, [target]: value };
      if (target === 'departmentResidence') {
        ownerInformationData.departmentResidence = value;
        ownerInformationData.municipalityResidence = undefined;
        const municipalityList = await getAllMunicipality(value?.value);
        setState({
          ...state,
          optionsMunicipality: municipalityList
        })
        setFieldTouched("municipalityResidence", true);
      };
      setValues(ownerInformationData);
      if (params?.state?.typeAction === 'EDIT_INFO_VEHICLE') {
        setDataVehiclesStateReducer('newVehicleInformation', target, value);
      } else {
        setDataVehiclesStateReducer('addVehicle', target, value);
      }
    // }
  };

  const getAllMunicipality = async (idDepartment) => {
    try {
      const res = await getAllMunicipalityService(idDepartment);
      return res.map((municipality) => ({ label: municipality.name, value: municipality.id }));
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  const setPhoneWhatsApp = (value) => {
    setValues(prevValues => ({
      ...prevValues,
      ...value
    }));

    setDataVehiclesKeyStateReducer('addVehicle', value);
  };
  // municipalityResidence, address, email

  return (
    <div className='grid-x owner-information__content__button'>
      <div className='small-12 medium-4 owner-information__content'>
        <label className=" grid-x owner-information__label">
          Tipo de Documento
          <div className="owner-information__label__icon" />
        </label>
        <Select
          disabled={isDisabled}
          value={values.documentType}
          options={optionsTypeDocument}
          className="owner-information__select"
          placeholder={'Selecciona el tipo de documento'}
          onBlur={(value) => handleBlur('documentType')(value)}
          onChange={(e, event) => onChange(event, 'documentType')}
          status={errors.documentType && touched.documentType ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
        />
        <span className='grid-x owner-information__errors'>{touched.documentType ? errors.documentType : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle owner-information__content'>
        <label className='grid-x owner-information__label owner-information__label--mod'>
          Número de Documento
          <div className='owner-information__label__icon' />
        </label>
        <Input
          disabled={isDisabled}
          value={values.numberDocument}
          placeholder={'Número de Documento'}
          className='owner-information__input__content'
          onChange={(value) => onChange(value, 'numberDocument')}
          onBlur={(value) => handleBlur('numberDocument')(value)}
          status={errors.numberDocument && touched.numberDocument ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className='grid-x owner-information__errors'>{touched.numberDocument ? errors.numberDocument : null}</span>
      </div>
      <div className='small-12 medium-4 owner-information__content owner-information__content__right'>
        <label className='grid-x owner-information__label owner-information__label--mod'>
          Nombres del Propietario
          <div className='owner-information__label__icon' />
        </label>
        <Input
          value={values.name}
          disabled={isDisabled}
          onChange={(value) => onChange(value, 'name')}
          onBlur={(value) => handleBlur('name')(value)}
          className='owner-information__input__content'
          placeholder={'Escribe el nombre del propietario'}
          status={errors.name && touched.name ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className='grid-x owner-information__errors'>{touched.name ? errors.name : null}</span>
      </div>
      <div className='small-12 medium-4 owner-information__content'>
        <label className=" grid-x owner-information__label">
          Apellidos del Propietario
          <div className="owner-information__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          value={values.lastName}
          className='owner-information__input__content'
          onChange={(value) => onChange(value, 'lastName')}
          onBlur={(value) => handleBlur('lastName')(value)}
          placeholder={'Escribe el apellido del propietario'}
          status={errors.lastName && touched.lastName ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className='grid-x owner-information__errors'>{touched.lastName ? errors.lastName : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle owner-information__content'>
        <label className='grid-x owner-information__label owner-information__label--mod'>
          Teléfono de contacto
          <div className='owner-information__label__icon' />
        </label>
        <div className='grid-x justify-content-between owner-information__label--mod'>
          <Select
            disabled={isDisabled}
            options={optionsNumberList}
            value={values.indicativeNumber}
            className='owner-information__select indicative-number'
            onChange={(e, event) => onChange(event, 'indicativeNumber')}
            onBlur={(value) => { handleBlur('indicativeNumber')(value) }}
            status={(errors.indicativeNumber && touched.indicativeNumber) ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
          />
          <Input
            disabled={isDisabled}
            value={values.numberPhone}
            placeholder={'Número de contacto'}
            onChange={(value) => onChange(value, 'numberPhone')}
            onBlur={(value) => handleBlur('numberPhone')(value)}
            className='owner-information__input__content phone-number'
            status={(errors.numberPhone && touched.numberPhone) ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
          />
          <span className='grid-x owner-information__errors'>
            {(touched.numberPhone || touched.indicativeNumber) ? errors.numberPhone ? errors.numberPhone : errors.indicativeNumber : null}
          </span>
        </div>
      </div>
      <div className='small-12 medium-4 owner-information__content owner-information__content__right'>
        <div className='grid-x justify-content-between owner-information__label--mod'>
          <label className='grid-x owner-information__label'>
            Whatsapp
            <div className='owner-information__label__icon' />
          </label>
          <div className='owner-information__option-copy'>
            <label className='owner-information__option-copy__text'>
              Copiar número telefónico
            </label>
            <button
              disabled={isDisabled}
              onClick={() => {
                setPhoneWhatsApp({
                  indicativeNumberWhatsapp: values.indicativeNumber,
                  numberPhoneWhatsapp: values.numberPhone
                })
              }}
            >
              <img
                src={Assets.SalesIcons.icon_copy_disabled}
                alt="icon_copy_disabled"
                className='owner-information__option-copy__icon'
              />
            </button>
          </div>
        </div>
        <div className='grid-x justify-content-between owner-information__label--mod'>
          <Select
            disabled={isDisabled}
            options={optionsNumberList}
            value={values.indicativeNumberWhatsapp}
            className='owner-information__select indicative-number'
            onBlur={(value) => handleBlur('indicativeNumberWhatsapp')(value)}
            onChange={(e, event) => onChange(event, 'indicativeNumberWhatsapp')}
            status={(errors.indicativeNumberWhatsapp && touched.indicativeNumberWhatsapp) ? "error" : ""}
            suffixIcon={
              <img
                alt="icon_down_arrow"
                src={Assets.SharedIcons.icon_down_arrow}
              />
            }
          />
          <Input
            disabled={isDisabled}
            value={values.numberPhoneWhatsapp}
            placeholder={'Número de WhatsApp'}
            className='owner-information__input__content phone-number'
            onChange={(value) => onChange(value, 'numberPhoneWhatsapp')}
            onBlur={(value) => handleBlur('numberPhoneWhatsapp')(value)}
            status={(errors.numberPhoneWhatsapp && touched.numberPhoneWhatsapp) ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
          />
          <span className='grid-x owner-information__errors'>
            {(touched.numberPhoneWhatsapp || touched.indicativeNumberWhatsapp) ? errors.numberPhoneWhatsapp ? errors.numberPhoneWhatsapp : errors.indicativeNumberWhatsapp : null}
          </span>
        </div>
      </div>
      <div className='small-12 medium-4 owner-information__content'>
        <label className="grid-x owner-information__label">
          Departamento de Residencia
          <div className="owner-information__label__icon" />
        </label>
        <Select
          disabled={isDisabled}
          options={optionsDepartment}
          value={values.departmentResidence}
          className="owner-information__select"
          placeholder={'Seleccione su departamento'}
          onChange={(e, event) => onChange(event, 'departmentResidence')}
          onBlur={(value) => handleBlur('departmentResidence')(value)}
          status={errors.departmentResidence && touched.departmentResidence ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
        />
        <span className='grid-x owner-information__errors'>{touched.departmentResidence ? errors.departmentResidence : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle owner-information__content'>
        <label className='grid-x owner-information__label owner-information__label--mod'>
          Municipio de Residencia
          <div className="owner-information__label__icon" />
        </label>
        <Select
          options={optionsMunicipality}
          value={values.municipalityResidence}
          className="owner-information__select"
          placeholder={'Seleccione su Municipio'}
          disabled={!values.departmentResidence || isDisabled}
          status={errors.municipalityResidence && touched.municipalityResidence ? "error" : ""}
          onChange={(e, event) => onChange(event, 'municipalityResidence')}
          onBlur={(value) => handleBlur('municipalityResidence')(value)}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
        />
        <span className='grid-x owner-information__errors'>{touched.municipalityResidence ? errors.municipalityResidence : null}</span>
      </div>
      <div className='small-12 medium-4 owner-information__content owner-information__content__right'>
        <label className='grid-x owner-information__label owner-information__label--mod'>
          Dirección de Contacto
          <div className="owner-information__label__icon" />
        </label>
        <Input
          value={values.address}
          disabled={isDisabled}
          className='owner-information__input__content'
          onChange={(value) => onChange(value, 'address')}
          onBlur={(value) => handleBlur('address')(value)}
          placeholder={'Escribe una dirección de contacto'}
          status={errors.address && touched.address ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className='grid-x owner-information__errors'>{touched.address ? errors.address : null}</span>
      </div>
      <div className='small-12 medium-4 owner-information__content'>
        <label className=" grid-x owner-information__label">
          Correo de Contacto
          <div className="owner-information__label__icon" />
        </label>
        <Input
          value={values.email}
          disabled={isDisabled}
          placeholder={'Escribe el correo electrónico'}
          className='owner-information__input__content'
          onChange={(value) => onChange(value, 'email')}
          onBlur={(value) => handleBlur('email')(value)}
          status={errors.email && touched.email ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className='grid-x owner-information__errors'>{touched.email ? errors.email : null}</span>
      </div>
      {/* TODO: Commented on new requirement not to attach photo documents */}
      {/* <div className='grid-x small-12 medium-4 align-center-middle owner-information__content'>
        <label className='grid-x owner-information__label owner-information__label--mod'>
          Adjuntar documento de identidad
          <div className='owner-information__label__icon' />
        </label>
        <div className='owner-information__upload-photo'>
          <img
            alt="icon_id_card"
            className={values.identityCardPhoto ? 'owner-information__upload-photo__photo-uploaded' : ''}
            src={values.identityCardPhoto ? ((typeof values.identityCardPhoto === "string") ? values.identityCardPhoto : URL.createObjectURL(values.identityCardPhoto)) : Assets.SharedIcons.icon_id_card}
          />
          <div className='grid-y align-center-middle'>
            <input
              type="file"
              disabled={isDisabled}
              id="filePhotoIdentifyCard"
              value={selectImgIdentityCard}
              accept="image/jpg, image/jpeg, image/png"
              className='owner-information__hidden'
              onChange={(event) => onChange(event, 'identityCardPhoto')}
            />
            <label className={`owner-information__upload-photo__charge-photo-label ${isDisabled ? "owner-information__upload-photo__charge-photo-label__disabled" : ""}`} htmlFor="filePhotoIdentifyCard">
              <span className='owner-information__upload-photo__charge-photo-label__text'>Adjuntar documento</span>
              <img
                src={Assets.SharedIcons.icon_up_arrow_white}
                alt="icon_down_arrow"
              />
            </label>
            <span className='owner-information__upload-photo__text'>Adjunta el documento de identidad por ambos lados. Los tipos de formatos permisos son: JPG , JPEG o PNG.</span>
          </div>
        </div>
      </div> */}
    </div>
  )
};

const mapStateToProps = ({ VehiclesReducer }) => {
  const { addVehicle, isDisabled, newVehicleInformation } = VehiclesReducer;
  return {
    addVehicle,
    isDisabled,
    newVehicleInformation
  };
};

const mapStateToPropsActions = {
  setStateVehicleReducer,
  setDataVehiclesStateReducer,
  setDataVehiclesKeyStateReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(OwnerInformationComponent);