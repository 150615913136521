/**
* Secret Key to create (#Wiedii-Lions@2022) SHA256
*/
const LocalStorageConst = {
    TOKEN_SESSION: "4379CBCFE061E632AD3C178F1881412AD97EF2D567B5D55EC58C5FCDF5139002",
    ROL_USER: "3B1734E3B6FDB20D433DC2D30E2F7F881B9C4F4715E3ECC9518E3AEB2CE4720C",
    PASSAGE_SALES: "ea50dc0ca09865a31257ee675260240388c9e59e18fec9492c6218f3a12931bd",
    USER:"e738f5ab9142c2ab2c00a08b55a60ed200080b49f08abe8ecf5b8f4719261d43"
}

export default LocalStorageConst