// Nav-bars
import icon_side_menu from "./nav-bars/icon_side_menu.svg"
import icon_my_profile from "./nav-bars/icon_my_profile.svg"
import icon_exit_session from "./nav-bars/icon_exit_session.svg"
import icon_notifications from "./nav-bars/icon_notifications.svg"
import icon_search_hover from "./nav-bars/icon_search_hover.svg"
import icon_my_profile_hover from "./nav-bars/icon_my_profile_hover.svg"
import icon_notifications_hover from "./nav-bars/icon_notifications_hover.svg"
import icon_exit_session_hover from "./nav-bars/icon_exit_session_hover.svg"
import icon_side_menu_hover from './nav-bars/icon_side_menu_hover.svg'
import icon_side_menu_close from './nav-bars/icon_side_menu_close.svg'

// Shared-icons
import icon_logo_and_name from "./icons/icon_logo_and_name.svg"
import icon_logo from "./icons/icon_cotranscat.svg"
import icon_search from "./icons/icon_search.svg"
import icon_down_arrow from "./icons/icon_down_arrow.svg"
import icon_back from "./icons/icon_back.svg"
import icon_next from "./icons/icon_next.svg"
import icon_up_arrow from "./icons/icon_up_arrow.svg"
import icon_logo_vertical from "./icons/icon_logo_vertical.svg"
import icon_square_close from "./icons/icon_square_close.svg"
import icon_down_arrow_disabled from "./icons/icon_down_arrow_disabled.svg"
import icon_up_arrow_disabled from "./icons/icon_up_arrow_disabled.svg"
import icon_close from "./icons/icon_close.svg"
import icon_info from "./icons/icon_info.svg"
import icon_logo_pdf from "./icons/icon_logo_pdf.png"
import icon_share from "./icons/icon_share.svg"
import icon_download from "./icons/icon_download.svg"
import icon_download_color_citron from "./icons/icon_download_color_citron.svg"
import icon_view_details from "./icons/icon_view_details.svg"
import icon_print_ticket from "./icons/icon_print_ticket.svg"
import icon_drivers_card from "./icons/icon_drivers_card.svg"
import icon_drivers_card_hover from "./icons/icon_drivers_card_hover.svg"
import icon_add from "./icons/icon_add.svg"
import icon_add_hover from "./icons/icon_add_hover.svg"
import icon_pencil from "./icons/icon_pencil.svg"
import icon_format from "./icons/icon_format.svg"
import icon_send_ticket_white from "./icons/icon_send_ticket_white.svg"
import icon_format_white from "./icons/icon_format_white.svg"
import icon_download_inactive from "./icons/icon_download_inactive.svg"
import icon_view_details_inactive from "./icons/icon_view_details_inactive.svg"
import icon_print_ticket_inactive from "./icons/icon_print_ticket_inactive.svg"
import icon_id_card from "./icons/icon_id_card.svg"
import icon_up_arrow_white from "./icons/icon_up_arrow_white.svg"
import icon_up_arrow_gray from "./icons/icon_up_arrow_gray.svg"
import icon_transfer from "./icons/icon_transfer.svg"
import icon_generate from "./icons/icon_generate.svg"
import icon_vehicles_card from "./icons/icon_vehicles_card.svg"
import icon_vehicles_card_hover from "./icons/icon_vehicles_card_hover.svg"
import icon_cotranscat_disabled from "./icons/icon_cotranscat_disabled.svg"
import icon_open_link from "./icons/icon_open_link.svg"
import icon_attached_files from "./icons/icon_attached_files.svg"
import icon_attached_files_disabled from "./icons/icon_attached_files_disabled.svg"
import icon_wastebasket from "./icons/icon_wastebasket.svg"
import icon_wastebasket_disabled from "./icons/icon_wastebasket_disabled.svg"
import icon_associate_driver from "./icons/icon_associate_driver.svg"
import icon_search_inactive from "./icons/icon_search_inactive.svg"
import icon_user_pdf from "./icons/icon_user_pdf.png"
import icon_camera from "./icons/icon_camera.svg"
import icon_delete from "./icons/icon_delete.svg"
import icon_edit_pencil from "./icons/icon_edit_pencil.svg"
import icon_download_document from "./icons/icon_download_document.svg"
import icon_download_spreadsheet from "./icons/icon_download_spreadsheet.svg"
import icon_download_active from "./icons/icon_download_active.svg"
import icon_shipping from "./icons/icon_shipping.svg"
import icon_location from "./icons/icon_location.svg"
import icon_next_disabled from "./icons/icon_next_disabled.svg"
import icon_add_cross from "./icons/icon_add_cross.svg"
import icon_hour from "./icons/icon_hour.svg"

// Sides-nav
import icon_user_profile from "./sides-nav/icon_user_profile.svg"
import icon_sales from "./sides-nav/icon_sales.svg"
import icon_sales_active from "./sides-nav/icon_sales_active.svg"
import icon_spreadsheet from "./sides-nav/icon_spreadsheet.svg"
import icon_spreadsheet_active from "./sides-nav/icon_spreadsheet_active.svg"
import icon_box from "./sides-nav/icon_box.svg"
import icon_box_active from "./sides-nav/icon_box_active.svg"
import icon_drivers from "./sides-nav/icon_drivers.svg"
import icon_drivers_active from "./sides-nav/icon_drivers_active.svg"
import icon_shifts from "./sides-nav/icon_shifts.svg"
import icon_shifts_active from "./sides-nav/icon_shifts_active.svg"
import icon_vehicles from "./sides-nav/icon_vehicles.svg"
import icon_vehicles_active from "./sides-nav/icon_vehicles_active.svg"
import icon_settings from "./sides-nav/icon_settings.svg"
import icon_settings_hover from "./sides-nav/icon_settings_hover.svg"

export const SharedIcons = {
    icon_logo_and_name,
    icon_logo,
    icon_side_menu,
    icon_search,
    icon_my_profile,
    icon_exit_session,
    icon_notifications,
    icon_user_profile,
    icon_sales,
    icon_sales_active,
    icon_spreadsheet,
    icon_spreadsheet_active,
    icon_box,
    icon_box_active,
    icon_drivers,
    icon_drivers_active,
    icon_shifts,
    icon_shifts_active,
    icon_vehicles,
    icon_vehicles_active,
    icon_settings,
    icon_settings_hover,
    icon_down_arrow,
    icon_back,
    icon_next,
    icon_search_hover,
    icon_my_profile_hover,
    icon_notifications_hover,
    icon_exit_session_hover,
    icon_side_menu_hover,
    icon_side_menu_close,
    icon_up_arrow,
    icon_logo_vertical,
    icon_square_close,
    icon_down_arrow_disabled,
    icon_up_arrow_disabled,
    icon_close,
    icon_info,
    icon_logo_pdf,
    icon_share,
    icon_download,
    icon_view_details,
    icon_print_ticket,
    icon_drivers_card,
    icon_drivers_card_hover,
    icon_add,
    icon_add_hover,
    icon_pencil,
    icon_format,
    icon_send_ticket_white,
    icon_format_white,
    icon_download_inactive,
    icon_view_details_inactive,
    icon_print_ticket_inactive,
    icon_id_card,
    icon_up_arrow_white,
    icon_transfer,
    icon_generate,
    icon_vehicles_card,
    icon_vehicles_card_hover,
    icon_cotranscat_disabled,
    icon_open_link,
    icon_attached_files,
    icon_wastebasket,
    icon_associate_driver,
    icon_search_inactive,
    icon_wastebasket_disabled,
    icon_attached_files_disabled,
    icon_user_pdf,
    icon_camera,
    icon_delete,
    icon_up_arrow_gray,
    icon_download_color_citron,
    icon_edit_pencil,
    icon_download_document,
    icon_download_spreadsheet, 
    icon_download_active,
    icon_shipping,
    icon_location,
    icon_next_disabled,
    icon_add_cross,
    icon_hour
}