//Assets
import { Assets } from '../../../assets'

//Components
import PdfDetailsComponent from '../../boarding-sheet/components/pdf-details/pdf-details.component'

//Libraries
import { BlobProvider } from '@react-pdf/renderer'
import { Link } from 'react-router-dom'

//Styles
import "./my-spreadsheets.page.scss"

const MySpreadsheetsPage = () => {

    const dataSpreadsheet = [
      {
        id: 1,
        name: 'TI36141',
        route:  'Cucuta - Tibú',
      },
      {
        id: 2,
        name: 'TI36141',
        route:  'Cucuta - Tibú',
      },
      {
        id: 3,
        name: 'TI36141',
        route:  'Cucuta - Tibú',
      },
      {
        id: 4,
        name: 'TI36141',
        route:  'Cucuta - Tibú',
      },
      {
        id: 5,
        name: 'TI36141',
        route:  'Cucuta - Tibú',
      },
      {
        id: 6,
        name: 'TI36141',
        route:  'Cucuta - Tibú',
      }
    ]

    const printPdf = async (url) => {
      window.open(url)
    };

  return (
    <>
      <div>
        <div className='grid-x align-center-middle my-spreadsheets__header'>
            <img
              src={Assets.SharedIcons.icon_spreadsheet_active}
              alt='icon_spreadsheet_active'
            />
            <span className='my-spreadsheets__title'>Mis planillas</span>
        </div>
      </div>
        <div className='my-spreadsheets__body__content my-spreadsheets__body__box'>
          { dataSpreadsheet.map((item, index) => {
            return (
              <div 
                key={index}
                className='my-spreadsheets__target'
              >
                <div className='my-spreadsheets__target__content'>
                  <span className='my-spreadsheets__target__name'>{"Planilla No. "}</span>
                  <span className='my-spreadsheets__target__name my-spreadsheets__target__name--bold'>{item.name}</span>
                </div>
                <div className='my-spreadsheets__target__content'>
                  <span className='my-spreadsheets__target__route'>{"Ruta: "}</span>
                  <span className='my-spreadsheets__target__route my-spreadsheets__target__route--bold'>{item.route}</span>
                </div>
                <div className='my-spreadsheets__target__content my-spreadsheets__target__content__button'>
                  <button className='my-spreadsheets__target__button'>
                    <span className='my-spreadsheets__target__button__text'>Iniciar viaje</span>
                  </button>
                </div>
                <div className='grid-x my-spreadsheets__target__button__content'>
                  <div className="small-6 grid-x align-center-middle my-spreadsheets__target__button__separator-right">
                  <BlobProvider document={<PdfDetailsComponent />}>
                    {({ url, loading, error }) => {
                      return (
                          <button
                            onClick={() => printPdf(url)}
                            disabled={loading || error}
                            className='grid-y align-center-middle'
                          >
                            <img
                              src={Assets.SharedIcons.icon_attached_files}
                              alt='icon_attached_files'
                              style={{ height: "30px", width: "30px" }}
                            />
                            <span className='my-spreadsheets__target__button__text my-spreadsheets__target__button__text--black'>
                              Ver planilla
                            </span>
                          </button>
                      );
                    }}
                  </BlobProvider>
                  </div>
                  <div className="small-6 grid-y align-center-middle my-spreadsheets__target__button__separator-left">
                    <Link to="./myOrders" className='grid-y align-center-middle' >
                      <img
                        src={Assets.SalesIcons.icon_orders}
                        alt='icon_orders'
                        style={{ height: "30px", width: "30px" }}
                      />
                      <span className='my-spreadsheets__target__button__text my-spreadsheets__target__button__text--black'>
                        Encomiendas
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
    </>
  )
}

export default MySpreadsheetsPage