const SharedEndPoints =  {
    GET_DOCUMENT_TYPE: `/api/documentType`,
    GET_DEPARTMENT: `/api/department`,
    GET_MUNICIPALITY: `/api/municipality`,
    GET_INDICATIVE_NUMBER: `/api/indicativeNumber`,
    GET_PAYMENT_METHOD: `/api/paymentMethod`,
    GET_FILTER_USERS: `/api/users/filterUsers`,
    CREATE_CLIENT: `/api/client`,
    UPDATE_CLIENT: `/api/client`,
    CREATE_INVOICE: `/api/invoice/travel`,
    CREATE_INVOICE_OBSERVATION: `/api/observation`,
    GET_OBSERVATIONS_BY_INVOICE: `/api/observation/:id`,
    GET_INVOICE: `/api/invoice/travel/:idInvoice`,
    GET_SITE: `/api/site`
}

export default SharedEndPoints