// Libraries
import * as Yup from 'yup';

export const createResolutionSchema = Yup.object().shape({
    serviceType: Yup.mixed()
        .required('Requerido'),
    prefixTNS: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Solo se permiten letras')
        .length(2, 'Debe tener 2 caracteres')
        .required('Requerido'),
    prefixDIAN: Yup.string()
        .matches(/^[a-zA-Z]+$/, 'Solo se permiten letras')
        .length(3, 'Debe tener 3 caracteres')
        .required('Requerido'),
    initialRange: Yup.number()
        .typeError('Debe ser un número')
        .required('Requerido')
        .positive('Debe ser un número positivo')
        .test('is-less-than-final', 'Debe ser menor que el Rango Final', function (value) {
          const finalRange = this.parent.finalRange;
          if (value && finalRange) {
            return value < finalRange;
          }
          return false;
        }),
    currentConsecutive: Yup.string()
        .matches(/^[0-9]+$/, 'Solo se permiten números')
        .required('Requerido'),
    finalRange: Yup.number()
        .typeError('Debe ser un número')
        .required('Requerido')
        .positive('Debe ser un número positivo')
        .test('is-greater-than-the-initial', 'Debe ser mayor que el Rango Inicial', function (value) {
          const initialRange = this.parent.initialRange;
          if (value && initialRange) {
            return value > initialRange;
          }
          return false;
        }),
    number: Yup.string()
        .matches(/^[0-9]+$/, 'Solo se permiten números')
        .length(15, 'Debe tener 15 dígitos')
        .required('Requerido'),
    date: Yup.string()
        .required('Requerido')
        .nullable(),
    codeBank: Yup.string()
        .matches(/^[0-9]+$/, 'Solo se permiten números')
        .min(2, 'Mínimo 2 dígitos')
        .required('Requerido'),
    route: Yup.mixed()
        .required('Requerido'),
})

export const createUserSchema = Yup.object().shape({

    nameCreateUser: Yup.string()
        .required('Requerido'),
    lastNameCreateUser: Yup.string()
        .required('Requerido'),
    typeDocumentCreateUser: Yup.mixed()
        .required('Requerido'), 
    numberDocumentCreateUser: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .min(6, 'Minimo 6 digitos')
        .max(10, 'Maximo 10 digitos')
        .required('Requerido'),
    dateOfBirthCreateUser: Yup.string()
        .required('Requerido'),
    indicativeCreateUser: Yup.mixed()
        .required('Requerido'),
    telephoneCreateUser: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .length(10, 'Numero no valido')
        .required('Requerido'),
    emailCreateUser: Yup.string()
        .email('Email no valido')
        .required('Requerido'),
    campusCreateUser: Yup.mixed()
        .required('Requerido'),
    newPasswordCreateUser: Yup.mixed()
        .when("isEditingFormik", {
            is: value => typeof value === 'boolean' && value,
            then: Yup.string().notRequired(),
            otherwise: Yup.string().required('Requerido')
        }),
    confirmNewPasswordCreateUser: Yup.mixed()
        .when("newPasswordCreateUser", {
            is: value => value,
            then: Yup.string()
                .oneOf([Yup.ref('newPasswordCreateUser'), null], 'Las contraseñas deben coincidir')
                .required('Requerido'),
            otherwise: Yup.string().notRequired()
        })
})

export const optionPermitsSchema = Yup.object().shape({
    typeUser: Yup.mixed()
        .required('Requerido'),
    optionsPermits: Yup.array()
    .test("permits", "Debe seleccionar al menos una opción", function(value) {
        if (value.some((item) => item.checked === true)) 
            return true;
        return false;
    })
})

export const modalAdjustmentPriceSchema = Yup.object().shape({
    moneyAdjustmentPrice: Yup.string()
        .required('Requerido'),
    percentageCostAdjustmentPrice: Yup.string()
        .matches(/^\d{1,2}$/, 'Debe ser un número de 1 a 2 dígitos')
        .required('Requerido'),
    moneyTransferAdjustmentPrice: Yup.string()
        .required('Requerido'),
    ivaAdjustmentPrice: Yup.string()
        .matches(/^\d{1,2}$/, 'Debe ser un número de 1 a 2 dígitos')
        .required('Requerido'),
    costPercentageAddRangePrice: Yup.string()
        .matches(/^\d{1,2}$/, 'Debe ser un número de 1 a 2 dígitos')
        .required('Requerido'),
    costMoneyTransferAddRangePrice: Yup.string()
        .required('Requerido'),
    ivaPercentageAddRangePrice: Yup.string()
        .matches(/^\d{1,2}$/, 'Debe ser un número de 1 a 2 dígitos')
        .required('Requerido'),
    initialRangeAddRangePrice: Yup.string()
        .required('Requerido'),
    finalRangeAddRangePrice: Yup.string()
        .when("isOpenRangeAddRangePrice", {
            is: 1,
            then: Yup.string(),
            otherwise: Yup.string().required('Requerido')
        })
});

export const managementDataClientSchema = Yup.object().shape({
    documentType: Yup.mixed()
        .required('Requerido'),
    documentNumber: Yup.string().when('documentType', {
        is: (documentType) => documentType && documentType.label === 'NIT',
        then: Yup.string()
            .matches(/^\d+-?\d*$/, 'Solo se permite números y un guion entre el número')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
        otherwise: Yup.string()
            .matches(/^[0-9]+$/, 'Solo se permiten números')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
    }),
    name: Yup.string()
        .required('Requerido'),
    lastName: Yup.string()
        .required('Requerido'),
    telephone: Yup.string()
        .min(10, 'Mínimo 10 dígitos')
        .max(12, 'Máximo 12 dígitos')
        .required('Requerido'),
    contactAddress: Yup.string(),
    contactEmail: Yup.string()
        .email('Email no valido'),
    municipalityResidence: Yup.mixed()
        .when('departmentResidence', {
            is: value => typeof value === 'object' && value !== null && Object.keys(value).length > 0,
            then: Yup.mixed().required('Requerido')
        })
})
