import { t } from "i18next"

// TAPS
//TODO: commented tap for delivery
export const passageTaps = [
    t("sales.salesFooter.salesTable.passage.invoiced"),
    // t("sales.salesFooter.salesTable.passage.postdated")
]

export const assignmentTaps = [
    t("sales.salesFooter.salesTable.assignment.cash"),
    t("sales.salesFooter.salesTable.assignment.cashOnDelivery"), 
    t("sales.salesFooter.salesTable.assignment.toDeliver"),
    t("sales.salesFooter.salesTable.turn.claimed"),
    "Entregados en sede"
    ]

export const turnTaps = [
    t("sales.salesFooter.salesTable.turn.sentTo"),
    t("sales.salesFooter.salesTable.turn.claimed"),
    t("sales.salesFooter.salesTable.turn.toDeliver")
]

// HEADERS

export const turnHeaders = {
    guide: t("sales.salesFooter.salesTable.turn.guide"),
    clientName: t("sales.salesFooter.salesTable.turn.clientName"),
    document: t("sales.salesFooter.salesTable.turn.document"),
    addressee: t("sales.salesFooter.salesTable.turn.addressee"),
    documentAddressee: t("sales.salesFooter.salesTable.turn.document"),
    amountDraft: t("sales.salesFooter.salesTable.turn.amountDraft"),
    dateSale: t("sales.salesFooter.salesTable.turn.dateSale"),
    destination: t("sales.salesFooter.salesTable.turn.destination"),
    seller: t("sales.salesFooter.salesTable.turn.seller")
}