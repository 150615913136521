//Components
import NavbarMainComponent from '../shared/components/nav-bars/navbar-main/navbar-main.component';
import SidebarMainComponent from '../shared/components/side-navs/sidebar-main/sidebar-main.component';

// Libraries
import { Outlet } from 'react-router-dom'
//TODO : It is required to move the language settings for the ant desing and day js components to the file containing the whole application.
import { ConfigProvider } from 'antd';
import esEs from 'antd/locale/es_ES';
import 'dayjs/locale/es'
import dayjs from 'dayjs';

import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.locale('es');
dayjs.extend(updateLocale);
dayjs.updateLocale('es', {
  weekStart: 0
})


const InnerContent = () => {
  return (
    <div>
      <ConfigProvider locale={esEs}>
        <div className="flex-container">
          <NavbarMainComponent/>
          <SidebarMainComponent/>
          <main
            className="sales-router__content"
          >
            <Outlet />
          </main>
        </div>
      </ConfigProvider>
    </div>
  )
}

export default InnerContent;
