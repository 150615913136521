//Assets
import { Assets } from "../../assets";

//Const - core
import SalesConst from "../../core/constants/sales.const";

//Libraries
import { Input, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useSearchParams} from "react-router-dom";
import { useTranslation } from "react-i18next";

//Styles
import "./tracking.page.scss";

//Utils
import { statusOrders, statusTurns } from "../../utils/data/tracking.page.utils";
import { trackingSchema } from "../../utils/form-validations/tracking.validators.utils";

const TrackingPage = () => {

  let history = useNavigate();
  const [t] = useTranslation("translation")
  const [searchParams] = useSearchParams();

  useEffect(() => {
      if(searchParams?.get('type') !== SalesConst.ASSIGNMENTS_SALES.toLowerCase() && searchParams?.get('type') !== SalesConst.TURNS_SALES.toLowerCase()){
          history("/", { replace: true,})
      }

      if (searchParams?.get('type') === SalesConst.ASSIGNMENTS_SALES.toLowerCase()) {
        let dataNew = statusOrders?.map((data)=>{
          return (
              {
                title:<span className='tracking__steps__title'>{data?.title}</span>,
                description:<span className='tracking__steps__description'>{data?.description}</span>,
                icon: 
                    <div
                      className="grid-x align-center-middle tracking__steps__circle__content"
                    >
                        <div className='tracking__steps__circle'></div>
                    </div>
              }
          )
        })
        setState({
          ...state,
          typeTracking: 1,
          dataSteps: dataNew
        })
      }

      if (searchParams?.get('type') === SalesConst.TURNS_SALES.toLowerCase()) {
        let dataNew = statusTurns?.map((data)=>{
          return (
              {
                title:<span className='tracking__steps__title'>{data?.title}</span>,
                description:<span className='tracking__steps__description'>{data?.description}</span>,
                icon: 
                    <div
                      className="grid-x align-center-middle tracking__steps__circle__content"
                    >
                        <div className='tracking__steps__circle'></div>
                    </div>
              }
          )
        })
        setState({
          ...state,
          typeTracking: 2,
          dataSteps: dataNew
        })
      }
      
      setFieldValue("numberSearch", searchParams?.get('number') || "")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const INITIAL_STATE = {
    isSearch: false,
    typeTracking: null,
    dataSteps: [],
    numSteps: 3
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { isSearch, typeTracking, dataSteps, numSteps } = state

  const handleSearch = () =>{
    setState({
      ...state,
      isSearch: true
    })
  }

  const  {values, errors, setFieldValue} = useFormik({
    initialValues: {
      numberSearch: ''
    },
    validationSchema: trackingSchema,
  });

  return (
    <div className="tracking__container grid-x align-center align-bottom">
      <div className="tracking__content">
        <div className="flex-container align-center-middle tracking__title__content">
          <img src={Assets.SalesIcons.icon_orders} alt="icon_orders" />
          <label className="tracking__title">
            {
              `${t("tracking.trackYour")}
              ${
                typeTracking === 1 ?
                  t("tracking.assignment")
                :
                typeTracking === 2 &&
                  t("tracking.turn")
              }`
            }
          </label>
        </div>
        <div className="grid-x">
          <div className="small-12 medium-6 tracking__content-input">
            <label className=" grid-x tracking__label">
              {
                `${t("tracking.enterYour")}
                ${
                  typeTracking === 1 ?
                    t("tracking.guide")
                  :
                  typeTracking === 2 &&
                    t("tracking.invoice")
                }`
              }
            </label>
            <Input
              value={values.numberSearch}
              onChange={({target:{value}})=> setFieldValue("numberSearch",value)}
              className="tracking__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
            />
            <span className="grid-x tracking__errors">
              {t("tracking.error")}
            </span>
          </div>
          <div className="small-12 medium-6 grid-x align-middle">
            <button 
              className="tracking__button"
              onClick={()=> handleSearch()}
              disabled={errors?.numberSearch}
            >
            {
              `${t("tracking.track")}
              ${
                typeTracking === 1 ?
                  t("tracking.assignment")?.charAt(0)?.toUpperCase() + t("tracking.assignment")?.slice(1)
                :
                typeTracking === 2 &&
                  t("tracking.turn")?.charAt(0)?.toUpperCase() + t("tracking.turn")?.slice(1)
              }`
            }
            </button>
          </div>
        </div>
        <div className="tracking__box">
          <label className="text-center tracking__sub-title">
            {
              `${t("tracking.status")}
              ${
                typeTracking === 1 ?
                  t("tracking.assignment")
                :
                typeTracking === 2 &&
                  t("tracking.turn")
              }`
            }
          </label>
          { isSearch ?
            <>
              <div className="grid-x tracking__box__data">
                <div className="small-2 grid-x align-center-middle">
                  <img
                    src={typeTracking === 1 ? Assets.SalesIcons.icon_orders : Assets.SalesIcons.icon_turns}
                    alt="icon_orders"
                    className="tracking__icon"
                  />
                </div>
                <div className="small-10">
                  <Steps
                      direction="vertical"
                      current={numSteps}
                      className="tracking__steps small-10"
                      items={dataSteps}
                  />
                </div>
              </div>
              {numSteps === (dataSteps?.length - 1) &&
                <div className="grid-x align-center-middle">
                  <img
                    src={typeTracking === 1 ? Assets.SalesIcons.icon_orders : Assets.SalesIcons.icon_turns}
                    alt="icon_orders"
                    width={25}
                  />
                  <span className="tracking__steps__description">
                  {
                      `${t("tracking.delivery")}
                      ${
                        typeTracking === 1 ?
                          t("tracking.assignment")
                        :
                        typeTracking === 2 &&
                          t("tracking.turn")
                      }`
                    }
                  </span>
                </div>
              }
            </>
            :
              <div className="grid-x align-center-middle tracking__box__no-data">
              <div className="grid-y align-middle">
                <img
                  src={typeTracking === 1 ? Assets.SalesIcons.icon_orders : Assets.SalesIcons.icon_turns}
                  alt="icon_orders"
                  height={39}
                  width={52}
                />
                <span className="tracking__text">
                {
                  `${t("tracking.statusYour")}
                  ${
                    typeTracking === 1 ?
                      t("tracking.assignment")
                    :
                    typeTracking === 2 &&
                      t("tracking.turn")
                  }`
                }
                </span>
              </div>
            </div>
          }
        </div>
        <div className="grid-x align-center-middle tracking__footer">
          <img
            src={Assets.SharedIcons.icon_logo_and_name}
            alt="icon_logo_and_name"
            height={37}
            width={200}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackingPage;