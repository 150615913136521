//Assets
import { Assets } from '../../assets';

// Constants
import { ShiftsErrorsConst } from '../../core/constants/errors/alerts-app/shifts.errors.const';
import AlertConst from '../../core/constants/alerts.const';

// Components
import ErrorToastComponent from '../../shared/components/toast/error-toast/error-toast.component';

//Libraries
import React, {  useRef, useState, useEffect } from 'react'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { AutoComplete, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';

//Services
import { getFilterTravelService } from '../../services/vehicle.services';
import { createTravelService, deleteVehicleService, getAllTravelByRangeDateService, updateTravelService } from '../../services/shifts.services';
import { getAllRouteByHeadquarterDepartService } from '../../services/sales.services';

//Styles
import "./shifts.page.scss"

//Utils
import { shiftsData } from '../../utils/data/shifts.utils';
import ModalMainComponent from '../../shared/components/modal/modal-main/modal-main.component';

const ShiftsPage = () => {
  
  const [items, setItems] = useState(shiftsData);

  const travelByRangeDate = async(routeParams) => {
    try {
      let getAllRoutes = route
      if (!routeParams) {
        if (!route) {
          const resGetAllRoutes = await getRoute()
          if (resGetAllRoutes?.length > 0) {
            const routesMap = resGetAllRoutes.map((route) => ({ value: route.id, label: route.name }));
            getAllRoutes = routesMap[0]
            setState((prevState) => ({
              ...prevState,
              route: routesMap[0],
              optionRoute: routesMap
            }))
          }
        }
      };
      const initialDate = dayjs(showDate[0]).format('YYYY-MM-DD')
      const finalDate = dayjs(showDate[showDate.length - 1]).format('YYYY-MM-DD')
      const responseTravelByRangeDate = await getAllTravelByRangeDateService( initialDate, finalDate, getAllRoutes?.value)
      setItems(responseTravelByRangeDate)
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const formatHour = `${dayjs().format('h')}:00 ${dayjs().format('A')}`
  const formatDay = dayjs().format('dddd')
  const currentHour = items?.find((item) => item?.id === formatHour);
  const currentDateTime = currentHour?.nestedDroppables?.find((item) => item?.day === formatDay);
  
  const [state, setState] = useState({
    weekSelected: dayjs(),
    duplicateCounter: '0',
    selectedCell: formatHour,
    selectedCellItem: currentDateTime?.id,
    selectedCellDay: formatDay,
    filterVehicleList: [],
    route: undefined,
    selectFilter: null,
    isViewModalDeletedShifts: false,
    optionRoute: []
  });

  const { 
    weekSelected, duplicateCounter, selectedCell, 
    selectedCellItem, selectedCellDay, filterVehicleList,
    route, selectFilter, isViewModalDeletedShifts, optionRoute
  } = state;

  const getRoute = async() => {
    try {
      const routes = await getAllRouteByHeadquarterDepartService();
      return routes
    } catch (err){
      ErrorToastComponent({
        title: ShiftsErrorsConst.route[err.code] || `${ShiftsErrorsConst.default} (${err.code || 'UDFND'})`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      }) 
    }
  }
  
  useEffect(() => {
    //TODO: must be adjusted to have more than one trip at the same time and day
    travelByRangeDate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekSelected])
  
  const containerRef = useRef(null);
  const activeHourRef = useRef(null);

  useEffect(() => {
    if (activeHourRef.current) {
      activeHourRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center', 
        inline: 'start',
      });
    }
  }, [selectedCell]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceDroppableId = result?.source?.droppableId;
    const destinationDroppableId = result?.destination?.droppableId;

    const removeAccents = (day) => {
      return day.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    
    const isDayDroppable = (droppableId) => {
      const normalizedDroppableId = removeAccents(droppableId);
      const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
      return days.some(day => {
        const normalizedDay = removeAccents(day);
        return normalizedDroppableId.includes(normalizedDay);
      });
    };
   
    if (isDayDroppable(sourceDroppableId) && isDayDroppable(destinationDroppableId)){
      const sourceDroppable = items.find((item) =>
        item?.nestedDroppables.some((nestedDroppable) => nestedDroppable?.id === sourceDroppableId)
      );
      const destinationDroppable = items.find((item) =>
        item?.nestedDroppables.some((nestedDroppable) => nestedDroppable?.id === destinationDroppableId)
      );
      const sourceNestedDroppable = sourceDroppable.nestedDroppables.find(
        (nestedDroppable) => nestedDroppable?.id === sourceDroppableId
      );
      const destinationNestedDroppable = destinationDroppable.nestedDroppables.find(
        (nestedDroppable) => nestedDroppable?.id === destinationDroppableId
      );
      const sourceIndex = result?.source?.index;
      const destinationIndex = result?.destination?.index;

      const isItemDuplicated = destinationNestedDroppable?.id !== sourceNestedDroppable?.id && destinationNestedDroppable?.items.some(
        (item) => item?.idDriverVehicle === sourceNestedDroppable?.items[sourceIndex]?.idDriverVehicle
      );
      if(sourceNestedDroppable?.day !== destinationNestedDroppable?.day && !isItemDuplicated){
        const movedItem = sourceNestedDroppable?.items[sourceIndex];
        const copyItem = {...movedItem, id: `${duplicateCounter}1`}
        setState((prevState) => ({
          ...prevState,
          duplicateCounter: `${duplicateCounter}1`
        }));
        duplicateTravel(destinationNestedDroppable,copyItem, destinationDroppable?.id)
        destinationNestedDroppable?.items.splice(destinationIndex, 0, copyItem);
      } else if(sourceNestedDroppable?.day === destinationNestedDroppable?.day && !isItemDuplicated){
        const [movedItem] = sourceNestedDroppable?.items.splice(sourceIndex, 1);
        updateTravel(destinationNestedDroppable,movedItem,destinationDroppable?.id)
        destinationNestedDroppable?.items.splice(destinationIndex, 0, movedItem);
      }

      setState((prevState) => ({
        ...prevState,
        selectedCell: destinationDroppable.id,
        selectedCellItem: destinationDroppableId,
        selectedCellDay: destinationNestedDroppable.day
        ,
      }));
    }

    setItems((prevItems) => [...prevItems]);
  };

  const duplicateTravel = async(travel, item, id) => {
    try {
      const dateSearch = showDate.find(date => date.format('dddd').toLowerCase() === travel?.day?.toLowerCase());
      await createTravelService({
        idVehicle: item?.idDriverVehicle,
        date: dayjs(dateSearch).format('YYYY-MM-DD'),
        time: dayjs(id, 'h:mm A').format('HH:mm:ss'),
        route: route?.value,
        isShiftManual: true
      })
    } catch (err) {
      ErrorToastComponent({
        title: ShiftsErrorsConst.passages[err.code] || ShiftsErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const updateTravel = async(travel, item, id) => {
    try {
      const dateSearch = showDate.find(date => date.format('dddd').toLowerCase() === travel?.day?.toLowerCase());
      const data = {
        idDriverVehicle: item?.idDriverVehicle,
        date: dayjs(dateSearch).format('YYYY-MM-DD'),
        time: dayjs(id, 'h:mm A').format('HH:mm:ss'),
      }
      await updateTravelService(data,item?.idTravel)
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const handleWeek = (value) => {
    setState({
      ...state,
      weekSelected: value
    })
  }

  const previousWeek = () => {
    const week = weekSelected.subtract(1, 'week')
    setState({
      ...state,
      weekSelected: week
    })
  }

  const nextWeek = () => {
    const week = weekSelected.add(1, 'week')
    setState({
      ...state,
      weekSelected: week
    })
  }

  const showDate = Array.from({ length: 7 }, (_, index) =>
    weekSelected.startOf('week').add(index, 'day')
  );

  const handleClickCell = (cellId, droppableId) => {

    const itemsHours = items.find(
      (item) => item.id === droppableId
    );
    const itemsDays = itemsHours?.nestedDroppables.find(
      (item) => item.id === cellId
    );

    setState((prevState) => ({
      ...prevState,
      selectedCell: droppableId,
      selectedCellItem: cellId,
      selectedCellDay: itemsDays.day,
    }));
  };

  const handleSearch = async(value) => {
    try {
      const filterVehicleList = await getFilterTravelService(value);
      setState({
        ...state,
        filterVehicleList,
        selectFilter: {
          value
        }
      })
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const handleCreate = async () => {
    try {
      const dateSearch = showDate.find(date => date.format('dddd').toLowerCase() === selectedCellDay.toLowerCase());
      await createTravelService({
        idVehicle: selectFilter?.id,
        date: dayjs(dateSearch).format("YYYY-MM-DD"),
        time: dayjs(selectedCell, 'h:mm A').format('HH:mm:ss'),
        route: route?.value,
        isShiftManual: true
      });
      setState({
        ...state,
        selectFilter: null,
        filterVehicleList: []
      })
    } catch (err) {
      ErrorToastComponent({
        title: ShiftsErrorsConst.passages[err.code] || ShiftsErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
    travelByRangeDate();
  }

  const deleteOption = async (id) => {
    try {
      await deleteVehicleService(id);
      const initialDate = dayjs(showDate[0]).format('YYYY-MM-DD')
      const finalDate = dayjs(showDate[showDate.length - 1]).format('YYYY-MM-DD')
      const responseTravelByRangeDate = await getAllTravelByRangeDateService( initialDate, finalDate, route?.value );
      setItems(responseTravelByRangeDate)

      } catch (err) {
        ErrorToastComponent({
          title: ShiftsErrorsConst.passages[err.code] || `${ShiftsErrorsConst.default} (${err.code || 'UDFND'})`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 1500
        })
      }
  }

  const modalClose = () => {
    setState((prevState) => ({
      ...prevState,
      isViewModalDeletedShifts: false
    }))
  }

  const onChange = (data) => {
    setState((prevState) => ({
      ...prevState,
      selectFilter: data
    }));
  };

  const selectVehicle = (options) => {
    setState((prevState) => ({
      ...prevState,
      selectFilter: options
    }));
    onChange(options)
  };
  
  return (
    <>
      <div className="shifts__breadcrumbs">
        <span>Turnos</span>
      </div>
      <div className="shifts__container">
        <div>
          <img src={Assets.ShiftsIcons.icon_shifts} alt="icon_shifts" />
          <span className='shifts__title'>Turnero manual</span>
          <Select
            value={route?.label}
            onChange={(e, value) => {
              setState(prevState => ({
                ...prevState,
                route: value
              }))
              travelByRangeDate(value)
            }}
            options={optionRoute}
            optionLabelProp="label"
            menuItemSelectedIcon={() => <></>}
            className="shifts__select"
            removeIcon={() => {
              return (
                <img
                  src={Assets.SharedIcons.icon_close} alt="icon_square_close" />
              )
            }}
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
          />
        </div>
        <div className="shifts__box shifts__box__header grid-x">
          <div className="small-6">
            <span className='shifts__box__week'>Semana del:</span>
            <div className='grid-x justify-content-between shifts__select-week'>
              <button>
                <img
                  src={Assets.SalesIcons.icon_back}
                  alt='icon_back'
                  onClick={() => previousWeek()}
                />
              </button>
                <DatePicker
                  defaultValue={dayjs()}
                  format={(value)=>{
                    return `${dayjs(value)
                      ?.startOf("week")
                      ?.format("DD/MM/YY")} ~ ${dayjs(value)
                      ?.endOf("week")
                      ?.format("DD/MM/YY")}`;
                  }
                  }
                  picker="week"
                  onChange={(date)=>handleWeek(date)}
                  value={weekSelected}
                  allowClear={false}
                  variant="borderless"
                  suffixIcon={null}
                  placeholder=""
                  className='shifts__select-week__date'
                  superNextIcon={
                    <img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />
                  }
                  superPrevIcon={
                    <img src={Assets.SalesIcons.icon_back} alt="icon_back" />
                  }
                  nextIcon={
                    <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
                  }
                  prevIcon={
                    <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
                  }
                  popupClassName="shifts__select-week__date__picker"
                  
                />
              <button>
                <img
                  src={Assets.SalesIcons.icon_forward}
                  alt='icon_forward'
                  onClick={()=> nextWeek()}
                />
              </button>
            </div>
          </div>
          <div className="small-6">
            <span className='shifts__label'>Agregar vehículo por código, placa</span>
            <div className='grid-x justify-content-between'>
            <AutoComplete
              options={filterVehicleList}
              onSelect={(value,options)=>selectVehicle(options)}
              onSearch={(e)=>{
                const trimValue = e.trimStart();
                if (trimValue) {
                  handleSearch(trimValue);
                } else {
                  setState({...state, filterVehicleList: [], selectFilter: null})
                }
              }}
            >
              <Input
                value={selectFilter?.value}
                onChange={(event) => onChange({value: event.target.value})}
                allowClear={{
                    clearIcon: (
                    <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                    />
                    )
                }}
                className="shifts__input__content shifts__input__content--mod"
                placeholder="Escribe el Nro. del Vehículo"
                prefix={
                    <img
                    className="shifts__input__search"
                    src={Assets.SharedIcons.icon_search}
                    alt="icon_search"
                    />
                }
              />
            </AutoComplete>
              <button 
              disabled = {!selectFilter?.id}
              onClick={() => handleCreate() }
              className='shifts__input__button'
              >
                Agregar vehículo
              </button>
            </div>
          </div>
        </div>
        <div className="shifts__box shifts__calendar">
        <div className='grid-x'>
          <div className='shifts__calendar__days__hour'></div>
            {
              showDate.map((item, index) => {
                return (
                  <div
                    className={`align-center-middle text-center grid-y shifts__calendar__days ${item?.format('dddd') === selectedCellDay ? 'shifts__calendar__days__active' : ''}`}
                    key={index}
                  >
                    <span 
                      className={`shifts__calendar__hour ${item?.format('dddd') === selectedCellDay ? 'shifts__calendar__hour__active' : ''}`}>
                        {item?.format('dddd')}
                    </span>
                    <span 
                      className={`shifts__calendar__hour--mod ${item?.format('dddd') === selectedCellDay ? 'shifts__calendar__hour__active' : ''}`}>
                        {item?.format('DD/MMMM')}
                    </span>
                  </div>
                )
              })
            }
        </div>
        <div className='grid-x'>
          <div className='shifts__calendar__days__hour shifts__calendar__days__hour--mod'>
            <span className="shifts__calendar__hour">Hora</span>
          </div>
          <div className='shifts__calendar__days shifts__calendar__days--mod text-center grid-x align-center-middle'>
            <span className="shifts__calendar__hour--exit">Salida</span>
          </div>
          <div className='shifts__calendar__days shifts__calendar__days--mod text-center grid-x align-center-middle'>
            <span className="shifts__calendar__hour--exit">Salida</span>
          </div>
          <div className='shifts__calendar__days shifts__calendar__days--mod text-center grid-x align-center-middle'>
            <span className="shifts__calendar__hour--exit">Salida</span>
          </div>
          <div className='shifts__calendar__days shifts__calendar__days--mod text-center grid-x align-center-middle'>
            <span className="shifts__calendar__hour--exit">Salida</span>
          </div>
          <div className='shifts__calendar__days shifts__calendar__days--mod text-center grid-x align-center-middle'>
            <span className="shifts__calendar__hour--exit">Salida</span>
          </div>
          <div className='shifts__calendar__days shifts__calendar__days--mod text-center grid-x align-center-middle'>
            <span className="shifts__calendar__hour--exit">Salida</span>
          </div>
          <div className='shifts__calendar__days shifts__calendar__days--mod text-center grid-x align-center-middle'>
            <span className="shifts__calendar__hour--exit">Salida</span>
          </div>
        </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <div className='shifts__calendar__container' ref={containerRef}>
              {items.map((droppable) => (
                <div
                  key={droppable.id}
                  className='shifts__calendar__droppable'
                >
                  <div 
                    className={`shifts__calendar__hour__content ${selectedCell === droppable.id ? 'shifts__calendar__hour__content__active' : ''}`}
                    ref={selectedCell === droppable.id ? activeHourRef : null}
                    >
                    <span 
                      className={`shifts__calendar__hour ${selectedCell === droppable.id ? 'shifts__calendar__hour__active' : ''}`}>
                      {droppable.id}
                    </span>
                  </div>
                  {droppable.nestedDroppables.map((nestedDroppable) => (
                    <Droppable
                      key={nestedDroppable.id}
                      droppableId={nestedDroppable.id}
                      day={nestedDroppable.day}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`shifts__calendar__droppable__content ${selectedCellItem === nestedDroppable.id ? 'shifts__calendar__droppable__content__active' : ''}`}
                          onClick={() => handleClickCell(nestedDroppable.id, droppable.id)}
                        >
                          {nestedDroppable?.items?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                              icon_next
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className='grid-x shifts__calendar__vehicle justify-content-between'
                                >
                                  <div className="shifts__calendar__vehicle__icon-collapse">
                                    <img src={Assets.ShiftsIcons.icon_collapse} alt="icon_collapse" />
                                  </div>
                                  <div>
                                    <div className='shifts__calendar__vehicle__title'>{item.content}</div>
                                    <div>{item.name}</div>
                                  </div>
                                  <div>
                                    <button
                                      onClick={() =>
                                        (item.isTravelWithAssociations)
                                          ? setState((prevState) => ({ ...prevState, isViewModalDeletedShifts: true}))
                                          : deleteOption(item.idTravel)
                                      }
                                    >
                                      <img src={Assets.SharedIcons.icon_close} alt="icon_close" />
                                    </button>
                                    
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
              ))}
            </div>
          </DragDropContext>
        </div>
      </div>

      <ModalMainComponent
        close
        width={380}
        open={isViewModalDeletedShifts}
        onClose={() => modalClose()}
        titleIcon={<img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name' />}
        footer={[
          <button
            className='shifts__modal__button'
            onClick={() => modalClose()}

          >
            <span>{"Aceptar"}</span>
          </button>
        ]}
      >
        <div className="grid-x align-center-middle">
          <p className="shifts__modal-preview__title">
            Confirmación de Eliminación de Vehículo
          </p>
          <div className="shifts__modal-preview__info">
            <p>
              El vehículo actualmente cuenta con reservas de pasajes y encomiendas.
            </p>
          </div>
          <div className="shifts__modal-preview__text">
            <p>
              Por favor, acceda al módulo de planillas para llevar a cabo la transferencia de datos correspondiente.
            </p>
          </div>
        </div>
      </ModalMainComponent>
    </>
  );
}

export default ShiftsPage