import * as boardingSheetTypes from './boarding-sheet.types';

const INITIAL_STATE = {
	optionsShipping: [],
    optionsVehicleSearch: []
};

const BoardingSheetReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case boardingSheetTypes.SET_BOARDING_SHEET:
			return {
				...state,
				[action.payload.prop]: action.payload.value
			}
		default:
			return state;
	}

};

export default BoardingSheetReducer;
