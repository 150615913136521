// Actions
import { resetDataSaleReducer, setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from "../../../storage/reducers/sales/sales.action";

//Assets
import { Assets } from "../../../assets";

//Components
import ClientDataComponents from "./components/client-data/client-data.components";
import TripDataComponent from "./components/trip-data/trip-data.component";
import PassengerDataComponent from "./components/passenger-data/passenger-data.component";
import BillingDataComponent from "./components/billing-data/billing-data.component";
import SeatAssignmentComponent from "./components/seat-assignment/seat-assignment.component";
import DrawerComponent from "../components/drawer/drawer.component";
import ModalSaveComponent from "../components/modal-save/modal-save.component";
import ModalInvoiceComponent from "../components/modal-invoice/modal-invoice.component";
import StepperComponent from "../components/stepper/stepper.component";
import NavigationBodyComponent from "../components/navigation-body/navigation-body.component";
import BoardingTicketsComponent from "./components/boarding-tickets/boarding-tickets.component";
import PreviewPdfComponent from "../components/preview-pdf/preview-pdf.component";
import PdfPassagesComponent from "./components/pdf-passages/pdf-passages.component";

//Components - Shared
import ModalMainComponent from "../../../shared/components/modal/modal-main/modal-main.component";
import SuccessToastComponent from "../../../shared/components/toast/success-toast/success-toast.component";
import ErrorToastComponent from "../../../shared/components/toast/error-toast/error-toast.component";

//Constants
import AlertConst from "../../../core/constants/alerts.const";
import { SalesErrorsConst } from "../../../core/constants/errors/alerts-app/sales.errors.const";

// Connect WebSocket
import { PrintConnectionSocket, ConnectionPassageSocket } from "../../../socket";

//Libraries
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import { Spin } from 'antd';
import printJS from 'print-js'

//Services
import { createClientService, createInvoicePassengerService, getInvoiceService, updateClientService } from "../../../services/shared.services";
import { clearPassageSalesService, getPassageSalesService, setPassageSalesService } from "../../../services/sales.services";

//Styles
import "./passages.page.scss";

const PassagesPage = (props) => {
  const {
    // Actions
    setStateSaleReducer,
    resetDataSaleReducer,
    setDataSaleReducer,
    setDataSalesStateReducer,
    // Variables
    isShowDrawer,
    passageSales,
    innerWidth
  } = props;

  const steps = [
    { label: "Datos del Cliente" },
    { label: "Datos del Viaje" },
    { label: "Datos de Pasajeros" },
    { label: "Datos de Facturación" },
  ];

  const INITIAL_STATE = {
    locationStepperState: 0,
    isModalInvoice: false,
    isModalSave: false,
    isOpenModal: false,
    isPrint: false,
    isDataPassageSales: true,
    invoiceData: null,
    isTypePrint: 1,
    selectedTickets: [],
    isLoadingModalSpin: false
  };

  const [state, setState] = useState(INITIAL_STATE);
  const { 
    locationStepperState, isModalInvoice, isModalSave, 
    isOpenModal, isPrint, isDataPassageSales,
    invoiceData,isTypePrint, selectedTickets,
    isLoadingModalSpin
  } = state;

  const nextButtonRef = useRef();
  const invoiceButtonRef = useRef();
  let history = useNavigate();
  let { state: isPreview } = useLocation();

  useEffect(() => {
    if (!isPreview) {
      const { connectSocket, disconnectSocket } = ConnectionPassageSocket();
      connectSocket();
      return () => {
        disconnectSocket();
      };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const directSale = () => {
    resetDataSaleReducer();
    clearPassageSalesService()
    history("/sales", { replace: true });
  };

  const [t] = useTranslation("translation")

  useEffect(() => {
    const passageSalesLocal = getPassageSalesService()
    if (passageSalesLocal) {
      setDataSaleReducer("passageSales",passageSalesLocal)
      setState((prevState)=>({
        ...prevState,
        isDataPassageSales: false
      }))
    }else {
      setState((prevState)=>({
        ...prevState,
        isDataPassageSales: passageSalesLocal
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInvoice = () =>{
    if (isModalInvoice) {
      setState((prevState)=>({
        ...prevState,
        isPrint: true,
        selectedTickets: []
      }))
      setStateSaleReducer('isShowDrawer', true)
    } else {
      setState({
        ...state,
        isModalSave: false,
        isOpenModal:true
      })
    }
    
  }

  const handleSave = () =>{
    if (isModalInvoice) {
      setState((prevState)=>({
        ...prevState,
        isPrint: false
      }))
      setStateSaleReducer('isShowDrawer', true)
    } else {
      setState({
        ...state,
        isModalSave: true,
        isOpenModal:true
      })
    }
  }

  const handleAcceptInvoice = async () => {
    try {
      if (isModalSave) {
        setPassageSalesService(passageSales);
        SuccessToastComponent({
          html: 
          `<span>
            ${t("sales.salesFooter.congratulations")}
          </span>`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 1000
        });
        setState((prevState) => ({
          ...prevState,
          isOpenModal: false,
          isLoadingModalSpin: false
        }));
      } else {
        await createInvoice(passageSales);
        const keysToCompare = [
          "documentType", 
          "documentNumber",
          "name",
          "lastName",
          "telephone",
          "telephoneCode",
          "municipalityResidence"
        ];
  
        const compareData = keysToCompare.every(key => {
          const passageSalesValue = passageSales[key];
          const originalClientDataValue = passageSales?.originalClientData[key];
        
          return deepEqual(passageSalesValue, originalClientDataValue);
        });
  
        if (compareData === false) {
          try {
            await updateClientService(passageSales);
            setDataSalesStateReducer('passageSales', "originalClientData", passageSales);
          } catch {
            // TODO: Implement error alert with code error.
          }
        }
        setState((prevState) => ({
          ...prevState,
          isLoadingModalSpin: false
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoadingModalSpin: false
      }));
    }
  };

  const createInvoice = async(data) =>{
    const newData = data?.assignedSeats?.map(object => {
      const { passenger } = object;
      const { 
        name: passengerName, telephone: numberPhone, lastName: passengerLastName, 
        telephoneCode: { value: idIndicativeNumber }
      } = passenger;
      const { id: idSeat } = object;
      return { passengerName, numberPhone, idSeat, passengerLastName, idIndicativeNumber };
    });
    const dataInvoice = {
      id: data?.id,
      idPaymentMethod: data?.typePayment?.value,
      price: parseInt(data?.assignedSeats[0]?.price),
      idSite: data?.location?.value,
      tickets : newData,
      isElectronic: passageSales.isElectronic
    }
    passageSales?.extraValue && (dataInvoice.extraValue = passageSales?.extraValue)
    try {
      const idInvoice = await createInvoicePassengerService(dataInvoice);
      await setStateSaleReducer("idInvoice", idInvoice)
      clearPassageSalesService()
      SuccessToastComponent({
        html: 
        `<span>${t("sales.passages.successfully")}
        <a class="passages__text-alert" href="/sales">${t("sales.passages.salesHistory")}</a>
        </span>`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 5000
      })
      setStateSaleReducer('isDisabled', true)
      setState({
        ...state,
        locationStepperState: locationStepperState + 1,
        isModalInvoice: true,
        isOpenModal:false
      })
      getInvoice(idInvoice);
    } catch (err){
      ErrorToastComponent({
        title: SalesErrorsConst.passages[err.code] || SalesErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const getInvoice = async (idInvoice) => {
    try {
      const dataInvoice = await getInvoiceService(idInvoice);
      setState((prevState) => ({
        ...prevState,
        invoiceData: dataInvoice,
        isPrint: true,
        selectedTickets: []
      }));

      if (innerWidth < 639) {
        window.toFlutter.postMessage(JSON.stringify(dataInvoice));
      } else {
        const { connectSocket, printTicketSocket } = PrintConnectionSocket();
        connectSocket();
        printTicketSocket({ typeTicket: 'printPassage', data: dataInvoice });
      }

      setStateSaleReducer('isShowDrawer', true);
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  const handleNext = () =>{
    setStateSaleReducer('isShowDrawer', false)
    if (steps.length > locationStepperState) {
      locationStepperState === 0 && validateClient()
      setState({
        ...state,
        locationStepperState: locationStepperState + 1,
      });
    }
    if (steps.length === locationStepperState) {
      setState({
        ...state,
        isOpenModal:false
      })
      clearPassageSalesService()
      resetDataSaleReducer()
      history("/sales", { replace: true });
    }
  }

  const validateClient = async () =>{
    if (passageSales?.id) {
      const keysToCompare = [
        "id",
        "documentType",
        "documentNumber",
        "name",
        "lastName",
        "telephone",
        "telephoneCode",
        "whatsapp",
        "whatsappCode",
        "departmentResidence",
        "municipalityResidence",
        "contactAddress",
        "contactEmail" 
      ];

      const compareData = keysToCompare.every(key => {
        const passageSalesValue = passageSales[key];
        const originalClientDataValue = passageSales?.originalClientData[key];
      
        return deepEqual(passageSalesValue, originalClientDataValue);
      });

      if (compareData === false) {
        try {
          await updateClientService(passageSales);
          setDataSalesStateReducer('passageSales', "originalClientData", passageSales)
        } catch {
          // TODO: Implement error alert with code error.
        }
      }
    }else{
      try {
        const idClient = await createClientService(passageSales);
        let dataClient = passageSales
        dataClient.id = idClient
        dataClient.originalClientData = {...dataClient}
        setDataSaleReducer("passageSales",dataClient)
      } catch {
        // TODO: Implement error alert with code error.
      }
      
    }
  }

  const deepEqual = (value1, value2) => {
    if (typeof value1 === "object" && typeof value2 === "object") {
      const keys1 = Object.keys(value1);
      const keys2 = Object.keys(value2);
  
      if (keys1.length !== keys2.length) {
        return false;
      }
  
      for (const key of keys1) {
        if (!deepEqual(value1[key], value2[key])) {
          return false;
        }
      }
  
      return true;
    }
  
    return value1 === value2;
  }

  const handleCloseModal = () =>{
    setState({
      ...state,
      isOpenModal: false
    })
  }

  const handleBack = () =>{
    setStateSaleReducer("isShowDrawer", false);
    if (locationStepperState > 0) {
      setState({
        ...state,
        locationStepperState: locationStepperState - 1,
      });
    }
  }

  const closeDrawer = () => {
    setStateSaleReducer("isShowDrawer", false);
    setState({
      ...state,
      isPrint: false,
      selectedTickets: []
    })
  }

  const handlePrint = (ticket) =>{
    const pdfSize = innerWidth < 639 ? undefined : isTypePrint
    pdfDocumentPrint(pdfSize,ticket)

    //TODO: modal print
    // if (innerWidth < 639) {
    //   pdfDocumentPrint(undefined,ticket)
    // }else{
    //   setState((prevState)=>({
    //     ...prevState,
    //     isModalPrint: true
    //   }))
    // }
  }

  //TODO: modal print
  // const onCloseModalPrint = () =>{
  //   setState((prevState)=>({
  //     ...prevState,
  //     isModalPrint: false,
  //     isTypePrint: 0
  //   }))
  // }
  
  const printPdf = async(url) => {
    if (innerWidth > 639) {
      printJS({
        printable: url,
        type: "pdf",
        showModal: true,
      })
    } else {
      window.open(url)
    }
  };

  const pdfDocumentPrint = (size, ticket) => {
    const ticketsData = ticket?.length ? ticket : selectedTickets;
    const invoiceDataCopy = {
      ...invoiceData,
      tickets: ticketsData.length ? ticketsData : invoiceData?.tickets
    };
    if (size) {
      const { connectSocket, printTicketSocket } = PrintConnectionSocket();
      connectSocket();
      printTicketSocket({typeTicket: 'printPassage', data: invoiceDataCopy});
    } else {
      const pdfBlob = pdf(<PdfPassagesComponent dataInvoice={invoiceDataCopy} />)
      pdfBlob.toBlob().then((blob) => {
        printPdf(URL.createObjectURL(blob))
      });
      window.toFlutter.postMessage(JSON.stringify(invoiceDataCopy));
    }
  };

  const handlePrintTicket = (ticket) =>{
    setState({ ...state, selectedTickets: ticket })
    handlePrint(ticket)
  }

  return (
    <>
      <div className="passages__content">
        <span className="passages__subtitle">{t("sales.passages.sales")}</span>
        <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
        <span className="passages__subtitle">
          {t("sales.passages.passages")}
        </span>
      </div>
      <div className={`grid-x ${isShowDrawer ? "passages__container--mod" : "passages__container"}`}>
        <div className={`${isShowDrawer?"passages__container__steps--mod":"passages__container__steps"} grid-x`}>
        <StepperComponent
          title={t("sales.passages.ticketSales")}
          name={t("sales.passages.passages")}
          icon={Assets.SalesIcons.icon_passages}
          dataStepper={steps}
          locationStepper={locationStepperState}
          onClose={()=>directSale()}
          onStepper={(e)=>{
            setState({
              ...state,
              locationStepperState: e,
            })
          }}
        />
        <NavigationBodyComponent
          nextButtonRef={nextButtonRef}
          invoiceButtonRef={invoiceButtonRef}
          isSave={isModalInvoice}
          isPreview={isPreview}
          locationStepper={locationStepperState}
          onBack={()=>handleBack()}
          onInvoice={()=>handleInvoice()}
          onSave={()=>handleSave()}
          onNext={()=>handleNext()}
          check={locationStepperState === 2}
          textSave={isModalInvoice? t("sales.passages.seePassages") : t("sales.passages.save")}
          textNext={locationStepperState >= 3 ? t("sales.passages.end") : t("sales.passages.next")}
          textInvoice={isModalInvoice ? t("sales.passages.seeInvoice") : t("sales.passages.invoice")}
          numStepper={steps.length}
        >
          {locationStepperState === 0 && !isDataPassageSales && <ClientDataComponents nextButtonRef={nextButtonRef}/>}
          {locationStepperState === 1 && !isDataPassageSales && <TripDataComponent nextButtonRef={nextButtonRef} isPreview={isPreview} />}
          {locationStepperState === 2 && !isDataPassageSales && <PassengerDataComponent nextButtonRef={nextButtonRef}/>}
          {locationStepperState >= 3 && !isDataPassageSales && <BillingDataComponent invoiceButtonRef={invoiceButtonRef}/>}
        </NavigationBodyComponent>
      </div>
        <div className={isShowDrawer ? "passages__drawer-modal--active" : "passages__drawer-modal"}>
          {locationStepperState >= 3 || invoiceData
            ? 
            <>
              <DrawerComponent
                accept = {()=> handlePrint()}
                pdfDocument = { isPrint && <PdfPassagesComponent dataInvoice={invoiceData} />}
                onClose = {()=> closeDrawer()}
                title={isPrint ? t("sales.passages.pdfPassages.salesInvoice.title") : t("sales.passages.pdfPassages.title")}
                buttonPrimaryText={t("sales.passages.pdfPassages.button")}
                // TODO: Submit button for selected ticket
                // buttonSecondaryText={t("sales.passages.pdfPassages.link")}
                onModalPrint={()=>handlePrint()}
                disabledPrint={(!isPrint && !selectedTickets.length)}
                typePrint={isTypePrint}
                // onTypePrint={(type)=> setState({ ...state, isTypePrint: type })}
              >
                {isPrint ?
                  <PreviewPdfComponent scaleNumber={1}>
                    <PdfPassagesComponent dataInvoice={invoiceData} />
                  </PreviewPdfComponent>
                  :
                  <BoardingTicketsComponent 
                    dataInvoice={invoiceData} 
                    ticketsSelected={(ticket)=> handlePrintTicket(ticket)}
                    ticketsChecked={(ticket)=> setState({...state, selectedTickets: ticket})}
                    selectedTickets={selectedTickets}
                  />
                }
              </DrawerComponent>
            </>
            : <SeatAssignmentComponent />
          }
        </div>
      </div>
        <>
          <ModalMainComponent
            width={300}
            open={isOpenModal}
            onClose={()=>handleCloseModal()}
          footer={[
            <button key="accept" className="passages__modal__button"
              onClick={() => {
                setState((prevState) => ({ ...prevState, isLoadingModalSpin: true }));
                handleAcceptInvoice();
              }}
              disabled={isLoadingModalSpin}
            >
              <span className="passages__modal__button__label">
                
                {isLoadingModalSpin ? <Spin size='default' className='passages__modal__spin' /> : (isModalSave ? t("sales.passages.accept") : t("sales.passages.invoiceNow"))}
              </span>
            </button>,
            <div className={`passages__modal__close ${isModalSave ? "passages__hidden" : ""}`} key="cancel">
              <button
                className="passages__modal__close__label"
                onClick={() =>
                  handleCloseModal()
                }
              >
                {t("sales.passages.cancel")}
              </button>
            </div>
          ]}
          >
            {isModalSave ? 
              <ModalSaveComponent />
             : 
             <ModalInvoiceComponent />
            }
          </ModalMainComponent>
          {/* TODO: Modal print */}
            {/* <ModalMainComponent
              open={isModalPrint}
              onClose={()=> onCloseModalPrint()}
              width={300}
              close
              footer={[
                <button 
                    key="accept" 
                    className="sales-table__modal-electronic__button"
                    onClick={()=>pdfDocumentPrint(isTypePrint)}
                  >
                    <span className="sales-table__modal-electronic__button__label">
                      Aceptar
                    </span>
                  </button>,
              ]}
              titleIcon={
                <div className="grid-x align-center-middle">
                  <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
                </div>
              }
            >
              <p className="passages__subtitle text-center" style={{ marginTop: "20px" }}>
                Imprimir Factura
              </p>
              <p className="sales-table__modal-electronic__info text-center" style={{ width: "100%" }}>
                Selecciona el formato de impresión de factura de venta
              </p>
              <div className="grid-x">
                <Select
                  value={isTypePrint}
                  onChange={(e)=> setState({
                    ...state,
                    isTypePrint: e
                  })}
                  placeholder={"Selecciona el tipo de impresión"}
                  className="small-12"
                  options={[
                    {
                      label: "Impresión de 80 mm",
                      value: 0
                    },
                    {
                      label: "Impresión formato carta",
                      value: 1
                    }
                  ]}
                  suffixIcon={
                    <img
                      src={Assets.SharedIcons.icon_down_arrow}
                      alt="icon_down_arrow"
                    />
                  }
                />
              </div>
            </ModalMainComponent> */}
        </>
    </>
  );
};

const mapStateToProps = ({ SalesReducer, AppReducer }) => {
  const { isShowDrawer, passageSales } = SalesReducer;
  const { windowSize: { innerWidth } } = AppReducer;
  return {
    isShowDrawer,
    passageSales,
    innerWidth
  };
};


const mapStateToPropsActions = {
  resetDataSaleReducer,
  setStateSaleReducer,
  setDataSaleReducer,
  setDataSalesStateReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(PassagesPage);
