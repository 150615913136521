const SalesConst = {
    PASSAGES_SALES: 'PASSAGES_SALES',
    ASSIGNMENTS_SALES: 'ASSIGNMENTS_SALES',
    TURNS_SALES: 'TURNS_SALES',

    // Shippings
    DECLARED_VALUE: 'PDSV',
    COST_PER_KILO:'CPKS',
    MINIUM_VALUE: 'MSV',
    MINIUM_DECLARED_VALUE: 'MDVS',
    PAYMENT_METHODS: {
        CASH: 1,
        UPON_DELIVERY: 3,
        CREDIT_CARD: 2,
    },

    //turns
    TURNS_TYPE:{
        MONEY_TRANSFER_SENDS: 1,
        MONEY_TRANSFER_RECEIPTS: 0,
    },
}

export default SalesConst;