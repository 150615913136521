//Assets
import { Assets } from '../../assets';

//Components
import PreviewPdfComponent from '../sales/components/preview-pdf/preview-pdf.component';
import PdfCashComponent from './components/pdf-cash/pdf-cash.component';

//Libraries
import { useEffect, useState } from 'react';
import { DatePicker, Select } from "antd";
import { connect } from "react-redux";
import dayjs from 'dayjs';
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import printJS from 'print-js'

//Services
import { getCashClosingService, getUserDataService } from '../../services/cash-closing.services';

//Styles
import "./cash-closing.page.scss"

const CashClosingPage = (props) => {

    const {
        // Variables
        innerWidth  
    } = props;

    const INITIAL_STATE = {
        isPrint: false,
        optionsUser: [],
        date: null,
        userSelected: null,
        dataCashClosing: null,
    };
    
    const [state, setState] = useState(INITIAL_STATE);
    const { isPrint, optionsUser, date, userSelected, dataCashClosing } = state;

    useEffect(() => {
      getUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUserData = async() => {
        try {
            const userData = await getUserDataService();
            setState({
                ...state,
                optionsUser: userData
            })
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }

    const generateClosure = async() => {
        try {
            const dataCashClosing = await getCashClosingService({
                date: dayjs(date).format('YYYY-MM-DD'),
                idSeller: userSelected.value
            })
            const dataCashClosingModified = {
                ...dataCashClosing,
                date: dayjs().format('DD/MM/YYYY'),
                time: dayjs().format('HH:mm:a'),
                userSelected: userSelected.label
                
            }
            
            setState({
                ...state,
                dataCashClosing: dataCashClosingModified,
                isPrint: true
            })
        } catch (error) {
            // TODO: Implement error alert with code error.
            
        }
    }
    const closeClosure = () => {
        setState({
          ...state,
          isPrint: false,
          userSelected: null,
          date: null

        })
       
    }
    

    const printPdf = async(url) => {
        if (innerWidth > 639) {
          printJS({
            printable: url,
            type: "pdf",
            showModal: true,
          })
        } else {
          window.open(url)
        }
      };

      const pdfDocumentPrint = () =>{
        const pdfBlob = pdf(<PdfCashComponent data={dataCashClosing}/>);
        pdfBlob.toBlob().then((blob) => {
          printPdf(URL.createObjectURL(blob))
        });
      }

    return (
        <>
            <div className="cash-closing__breadcrumbs">
                <span>Turnos</span>
            </div>
            <div className="cash-closing__container">
                <div>
                    <img src={Assets.SharedIcons.icon_box_active} alt="icon_box_active" />
                    <span className='cash-closing__title'> Cierre de caja </span>
                </div>
                <div className="cash-closing__box cash-closing__box__header grid-x">
                    <div className="small-4">
                        <span className="cash-closing__box__week">
                            Usuario
                        </span>
                        <Select
                            className="cash-closing__select"
                            value={userSelected}
                            suffixIcon={
                                <img
                                    src={Assets.SharedIcons.icon_down_arrow}
                                    alt="icon_down_arrow"
                                />
                            }
                            placeholder={'Selecciona un Usuario'}
                            options={optionsUser}
                            onChange={(e, event)=> setState({
                                    ...state,
                                    userSelected: event
                                })}
                        />
                    </div>
                    <div className="small-8">
                        <span className='cash-closing__label'>Selecciona la fecha del viaje</span>
                        <div className='grid-x justify-content-between'>
                            <div className='small-8'>
                                <DatePicker
                                    onChange={(dateSelected)=>{
                                        setState({
                                            ...state,
                                            date: dateSelected
                                        })
                                    }}
                                    className="cash-closing__date small-8"
                                    format={"DD/MM/YYYY"}
                                    value={date ? dayjs(date, 'DD:MM:YYYY') : null}
                                    allowClear={{
                                        clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
                                    }}
                                    popupClassName="cash-closing__date__picker"
                                    placeholder="DD/MM/AAAA"
                                    suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
                                    superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
                                    superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
                                    nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
                                    prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}

                                />
                            </div>
                            <button 
                                className='cash-closing__button small-4'
                                onClick={()=>generateClosure()}
                                disabled={!userSelected || !date}
                            >
                                Generar cierre
                                <img width={30} src={Assets.SharedIcons.icon_send_ticket_white} alt="icon_send_ticket_white" />
                            </button>
                        </div>
                    </div>
                </div>
                {isPrint && (
                    <div className="cash-closing__box cash-closing__container-print">
                        <div className="cash-closing__container-print__print-header">
                            <button onClick={()=>closeClosure()} >
                                <img
                                    alt="icon_square_close"
                                    src={Assets.SharedIcons.icon_square_close}
                                />
                                <span className='cash-closing__container-print__close'>Cerrar</span>
                            </button>
                            <div className='cash-closing__container-print__title'>
                                Cierre de caja
                            </div>
                            <div>
                                <PDFDownloadLink document={<PdfCashComponent data={dataCashClosing} />} fileName={`document${Date.now()}`} >
                                    <button >
                                    <img
                                        alt="icon_download_document"
                                        src={Assets.SharedIcons.icon_download_document}
                                    />
                                    </button>
                                </PDFDownloadLink>
                                <button onClick={()=>pdfDocumentPrint()}>
                                    <img
                                        alt="icon_print"
                                        src={Assets.SalesIcons.icon_print}
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="cash-closing__container-print__print-content">
                            <div className="cash-closing__container-print__print-sheet">
                                <PreviewPdfComponent scaleNumber={1.5}>
                                    <PdfCashComponent data={dataCashClosing} />
                                </PreviewPdfComponent>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    );
}

const mapStateToProps = ({ AppReducer }) => {
    const { windowSize: { innerWidth } } = AppReducer;
    return {
      innerWidth
    };
  };

  export default connect(mapStateToProps, null) (CashClosingPage)