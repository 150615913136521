//Assets
import { Assets } from '../../../assets'

//Components
import ModalMainComponent from '../../../shared/components/modal/modal-main/modal-main.component';
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';
import SuccessToastComponent from '../../../shared/components/toast/success-toast/success-toast.component';

//Constants
import { SettingsErrorsConst } from '../../../core/constants/errors/alerts-app/seettings.errors.const';
import AlertConst from '../../../core/constants/alerts.const';

//Libraries
import React, { useEffect, useState } from 'react'
import { Checkbox, Input, Tooltip } from 'antd';
import { useFormik } from 'formik';

//Services
import { createMoneyTransferRangeService, deleteByIdMoneyTransferRangeService, getAllMoneyTransferRangeService, getListPricePassagesService, updateByIdMoneyTransferRangeService, updateByIdPricePassagesService } from '../../../services/settings.services';

//Styles
import "./price.page.scss";

//Utils
import { modalAdjustmentPriceSchema } from '../../../utils/form-validations/settings.validators.utils';

const PricePage = (props) => {

    const{
        //Variables
        isDisabled
    } = props;

    const INITIAL_STATE ={
        openModalPassagesPrice: false,
        openModalTurnsPrice: false,
        openModalAddRange: false,
        modalPassages: undefined,
        modalTurns: undefined,
        dataTurns: [],
        dataPassages: []
    }

    const [state, setState] = useState(INITIAL_STATE)
    const {
        openModalPassagesPrice,
        openModalTurnsPrice,
        openModalAddRange,
        modalPassages,
        modalTurns,
        dataTurns,
        dataPassages
    } = state

    const  {values, errors, handleBlur, touched, setFieldValue, setFieldError, setValues} = useFormik({
        initialValues: {
            moneyAdjustmentPrice: "",
            percentageCostAdjustmentPrice: "",
            moneyTransferAdjustmentPrice: "",
            ivaAdjustmentPrice: "",
            costPercentageAddRangePrice: "",
            costMoneyTransferAddRangePrice: "",
            ivaPercentageAddRangePrice: "",
            initialRangeAddRangePrice: "",
            finalRangeAddRangePrice: "",
            isOpenRangeAddRangePrice: 0
        },
        validationSchema: modalAdjustmentPriceSchema,
    });

    const pricePassagesModal = (item) =>{
        setState({
            ...state,
            openModalPassagesPrice: true,
            modalPassages: item
        })
        setFieldValue("moneyAdjustmentPrice", item.price)
    }

    const priceTurnsModal = (item) =>{
        setState({
            ...state,
            openModalTurnsPrice: true,
            modalTurns: item
        })
        setFieldValue("percentageCostAdjustmentPrice", item.costPercentage * 100)
        setFieldValue("moneyTransferAdjustmentPrice", item.costMoneyTransfer)
        setFieldValue("ivaAdjustmentPrice", item.ivaPercentage * 100)
    }

    const priceAddRangeModal = () =>{
        setState({
            ...state,
            openModalAddRange: true
        })
    }

    const modalClose = () => {
        setState((prevState) => ({
        ...prevState,
        openModalPassagesPrice: false,
        openModalTurnsPrice: false,
        openModalAddRange: false,
        }))
    }

    useEffect(() => {
        getRanges();
        getRoutesPassages();
    }, [])

    const getRanges = async () => {
        try {
            const ranges = await getAllMoneyTransferRangeService();
            setState((prevState) => ({
                ...prevState,
                dataTurns: ranges
            }))
        } catch (error) {
            //Implement error alert with code error
        }
    }
    const clearDataModalAddRange = () => {
        setValues (dataTurns);
    }

    const deleteRange = async (id) => {
        try {
            await deleteByIdMoneyTransferRangeService(id)
            getRanges();
        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.moneyTraRanges[err.code] || SettingsErrorsConst.default,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }
    }

    const handlerOnChangeCost = (key, value) => { 
        if (value === '') {
            setFieldValue(key, undefined);
        } else {
            let numericValue= Number(value.replace(/,/g,""))
            if(!isNaN(numericValue)) {
                setFieldValue(key, numericValue)
            }
        }
     }

    const handleChangeCheckbox = (event) => {
        const value = event.target.checked ? 1 : 0;

        if (value === 1) {
            setFieldValue("finalRangeAddRangePrice", "");
        }
        setFieldValue("isOpenRangeAddRangePrice", value);
    }

    const handleCreate = async () => {

        let newInitialRange = values.initialRangeAddRangePrice;
        let newFinalRange = values.finalRangeAddRangePrice;

        const rangeInitial = dataTurns.some((item) =>{
            return newInitialRange >= item.initialRange && newInitialRange <= item.finalRange
        });

        const rangeFinal = dataTurns.some((item) =>{
            return newFinalRange >= item.initialRange && newFinalRange <= item.finalRange && newFinalRange > newInitialRange
        })
        if (rangeInitial) {
            setFieldError("initialRangeAddRangePrice", "El rango inicial esta dentro de un rango existente");
        }
        if (rangeFinal) {
            setFieldError("finalRangeAddRangePrice", "El rango final esta dentro de un rango existente");
        }
        if (newFinalRange < newInitialRange) {
            setFieldError("finalRangeAddRangePrice", "El rango final no puede ser menor que el rango inicial");
        }
        if (newInitialRange === newFinalRange) {
            setFieldError("finalRangeAddRangePrice", "El rango final no puede ser igual que el rango inicial");
        }
        if (!rangeInitial && !rangeFinal) {
            try {
                let data = {
                    costPercentage: values?.costPercentageAddRangePrice / 100,
                    ivaPercentage: values?.ivaPercentageAddRangePrice / 100,
                    costMoneyTransfer: values?.costMoneyTransferAddRangePrice,
                    initialRange: values?.initialRangeAddRangePrice,
                    isOpenRange: values?.isOpenRangeAddRangePrice ? 1 : 0,
                };

                if (data.isOpenRange === 0)
                    data.finalRange = values?.finalRangeAddRangePrice;
                await createMoneyTransferRangeService(data);

                getRanges();
                modalClose();
                clearDataModalAddRange();
                SuccessToastComponent({
                    html:
                        `<span>${"Rango creado satisfactoriamente"}
                  <a class="create-user__text-alert"</a>
                </span>`,
                    position: AlertConst.TOP_END_POSITION_TEXT,
                    timer: 1500
                });
            } catch (err) {
                ErrorToastComponent({
                    title: SettingsErrorsConst.moneyTraRanges[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                    position: AlertConst.TOP_END_POSITION_TEXT,
                    timer: 1500
                })
            }
        }
    }

    const handleCostAdjustment = async () => {
        try {
            let data = {
                costPercentage: values?.percentageCostAdjustmentPrice / 100,
                ivaPercentage: values?.ivaAdjustmentPrice / 100,
                costMoneyTransfer: values?.moneyTransferAdjustmentPrice
            }
            await updateByIdMoneyTransferRangeService(modalTurns.id, data)
            getRanges();
            modalClose();
        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.moneyTraRanges[err.code] || SettingsErrorsConst.default,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }
    }
    
    const getRoutesPassages = async () => {
        try {
            const routesPassages = await getListPricePassagesService()
            setState((prevState) => ({
                ...prevState,
                dataPassages: routesPassages
            }))
        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.routesPassages[err.code] || SettingsErrorsConst.default,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }
    }

    const handleCostAdjustmentPassages = async () => {
        try {
            let dataPricePassages = {
                price: values?.moneyAdjustmentPrice
            }
            await updateByIdPricePassagesService(modalPassages.id, dataPricePassages)
            getRoutesPassages();
            modalClose();
        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.routesPassages[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }
    }

    return (
        <div className='grid-x justify-content-between price__container__header'>
            <div className='grid-y price__container__left'>
                <div className=' '>
                    <img
                        src={Assets.SalesIcons.icon_passages}
                        alt="icon_generate"
                        width={35}
                        height={35}   
                    />
                    <span className='price__title'>Pasajes</span>
                </div>
                <div className='price__box price__box--mod grid-x '>
                    <div className='grid-x small-12 price__table__line'>
                        <span className='price__subtitle small-4'>Ruta</span>
                        <span className='price__subtitle small-4'>Valor por unidad</span>
                        <span className='price__subtitle small-4'>Acciones</span>
                    </div>
                    {dataPassages.map((item, index) => (
                        <div className='grid-x small-12 price__table__line' key={index}>
                            <div className='price__separator small-4 text-center'>{item.name}</div>
                            <div className='price__separator small-4 text-center'>{item.price.toLocaleString()}</div>
                            <div className='price__separator small-4 text-center'>
                                <button 
                                    className="price__button__actions"
                                    onClick={() =>pricePassagesModal(item)}
                                >
                                    <span>Ajustar Costo</span>
                                    <img
                                        src={Assets.SharedIcons.icon_edit_pencil}
                                        alt="icon_generate"
                                    />
                                </button>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <div className='grid-y price__container__right'>
                <div className=''>
                <div>
                    <img
                    src={Assets.SalesIcons.icon_turns}
                    alt="icon_generate"
                    width={35}
                    height={35}   
                    />  
                    <span className='price__title'>Giros</span>
                </div>
                </div>
                <div className='price__box price__box--mod grid-x  '>
                    <div className='grid-x small-12 price__table__line '>
                        <span className='price__subtitle small-6'>Rango de giro</span>
                        <span className='price__subtitle small-6'>Acciones</span>
                    </div>
                {dataTurns.map((item, index) => (
                    <div className='grid-x small-12 price__table__line' key={index}>
                        <div className='price__separator price__separator--mod small-7 text-left'>
                            <Tooltip 
                                color={'white'}
                                placement="bottomLeft"
                                overlayStyle={{ maxWidth: '100%' }}
                                title={() => (
                                    <div className='grid-y price__tooltip'>
                                        <div className='grid-x price__tooltip__header'>
                                            <span className='grid-y price__tooltip__name'>Formula del valor de giro</span>
                                        </div>
                                        <div className='grid-x align-middle price__tooltip__body'>
                                            <span className='grid-x price__tooltip__description__text'>Valor a enviar del giro</span>
                                            <span className='price__tooltip__description__text--mod'>x</span>
                                            <span className='grid-x price__tooltip__description__text'>{item.costPercentage * 100}%</span>
                                            <span className='price__tooltip__description__text--mod'>+</span>
                                            <span className='grid-x price__tooltip__description__text'>{item.costMoneyTransfer.toLocaleString()}</span>
                                            <span className='price__tooltip__description__text--mod'>=</span>
                                            <span className='grid-x price__tooltip__description__text'>Costo del giro</span>
                                        </div>
                                        <div className='grid-x align-middle price__tooltip__body'>
                                            <span className='grid-x price__tooltip__description__text'>Costo del giro</span>
                                            <span className='price__tooltip__description__text--mod'>x</span>
                                            <span className='grid-x price__tooltip__description__text'>{item.ivaPercentage * 100}%</span>
                                            <span className='price__tooltip__description__text--mod'>=</span>
                                            <span className='grid-x price__tooltip__description__text'>Costo Total</span>
                                        </div>
                                    </div>
                                )}
                            >
                                <button 
                                >
                                    <img
                                        src={Assets.SharedIcons.icon_info}
                                        alt='icon_info'
                                    />
                                </button>
                            </Tooltip>
                            <span>
                                $ {item.initialRange ? item.initialRange.toLocaleString() : "" } -
                                $ {item.finalRange ? item.finalRange.toLocaleString() : "∞"}
                            </span>
                        </div>
                        <div className='price__separator small-4 text-center'>
                            <button 
                                className="price__button__actions"
                                onClick={() => priceTurnsModal(item)}
                            >
                                <span>Ajustar Costo</span>
                                <img
                                    src={Assets.SharedIcons.icon_edit_pencil}
                                    alt="icon_generate"
                                />
                            </button>
                        </div>
                        <div className='price__separator small-1 text-center'>
                            <button
                                onClick={() => deleteRange(item.id)}
                            >
                                <img
                                    src ={Assets.SharedIcons.icon_delete}
                                    alt='icon_delete'
                                />
                            </button>
                        </div>
                    </div>
                ))}
                </div>
                
                <div>
                <button 
                    className="price__button"
                    onClick={() => priceAddRangeModal()}
                >
                    <span className='price__button__text'>Agregar Rango</span>
                    <img
                        src={Assets.SharedIcons.icon_add_cross}
                        alt="icon_add_cross"
                    />
                </button>
                </div>
            </div>

            {openModalAddRange && 
                <ModalMainComponent
                width={325}
                open={openModalAddRange}
                onClose={modalClose}
                titleIcon={
                    <div className='grid-x align-center'>
                        <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
                    </div>
                }
                footer={[
                    <div key="accept" className='grid-x align-center'>
                        <button
                            className='price__modal__button'
                            onClick={()=> handleCreate()}
                            disabled={isDisabled 
                                || !values?.costPercentageAddRangePrice 
                                || !values?.costMoneyTransferAddRangePrice 
                                || !values?.ivaPercentageAddRangePrice 
                                || !values?.initialRangeAddRangePrice 
                                || (values.isOpenRangeAddRangePrice === 0 && !values?.finalRangeAddRangePrice)
                            }
                        >
                        <span>Agregar rango</span>
                        </button>
                    </div>,
                    <div key="cancel" className='grid-x align-center'>
                        <button
                            className='price__modal__cancel'
                            onClick={() => modalClose()}
                        >
                        <span >Salir</span>
                        </button>
                    </div>
                ]}
                >
                    <div className='grid-x align-center-middle'>
                        <span className='price__modal__title'>Agregar Rango</span>
                    </div>
                    <div className='grid-x align-center-middle'>
                        <span className='text-center price__modal__text'>Aquí puedes agregar el rango de giros para los valores de consignaciones</span>
                    </div>
                    <div className='small-8 grid-x align-middle price__modal__title'>
                        <span className="price__info">Porcentaje del costo del Giro</span>
                        <Input
                            className="price__input__content price__input__content--mod align-left"
                            value={values.costPercentageAddRangePrice?.toLocaleString() }
                            status={errors?.costPercentageAddRangePrice && touched?.costPercentageAddRangePrice ? "error" : "" }
                            onBlur={(value) =>handleBlur("costPercentageAddRangePrice")(value)}
                            onChange={(e) => {handlerOnChangeCost("costPercentageAddRangePrice", e.target.value)}}
                            allowClear={{
                                clearIcon: (
                                    <img
                                        width={28}
                                        src={Assets.SharedIcons.icon_square_close}
                                        alt="icon_logo_vertical"
                                    />
                                )
                            }}
                            placeholder="Actualiza el porcentaje del costo del giro"
                        />
                        <span className='price__modal__text--mod align-right'>%</span>
                        <span className="grid-x price__errors">{touched?.costPercentageAddRangePrice? errors?.costPercentageAddRangePrice : null}</span>
                    </div>
                    <div className='small-8 grid-x align-middle price__modal__title'>
                        <span className="price__info--mod">Costo del Giro</span>
                        <Input
                            prefix="$"
                            className="price__input__content price__input__content--mod align-left"
                            value={values.costMoneyTransferAddRangePrice?.toLocaleString() }
                            status={errors?.costMoneyTransferAddRangePrice && touched?.costMoneyTransferAddRangePrice ? "error" : "" }
                            onBlur={(value) =>handleBlur("costMoneyTransferAddRangePrice")(value)}
                            onChange={(e) => {handlerOnChangeCost("costMoneyTransferAddRangePrice", e.target.value)}}
                            allowClear={{
                                clearIcon: (
                                    <img
                                        width={28}
                                        src={Assets.SharedIcons.icon_square_close}
                                        alt="icon_logo_vertical"
                                    />
                                )
                            }}
                            placeholder="Actualiza el monto del giro"
                        />
                        <span className='price__modal__text--mod align-right'>COP</span>
                        <span className="grid-x price__errors">{touched?.costMoneyTransferAddRangePrice? errors?.costMoneyTransferAddRangePrice : null}</span>
                    </div>
                    <div className='small-8 grid-x align-middle price__modal__title'>
                        <span className="price__info--mod">Porcentaje del IVA</span>
                        <Input
                            className="price__input__content price__input__content--mod align-left"
                            value={values.ivaPercentageAddRangePrice?.toLocaleString() }
                            status={errors?.ivaPercentageAddRangePrice && touched?.ivaPercentageAddRangePrice ? "error" : "" }
                            onBlur={(value) =>handleBlur("ivaPercentageAddRangePrice")(value)}
                            onChange={(e) => {handlerOnChangeCost("ivaPercentageAddRangePrice", e.target.value)}}
                            allowClear={{
                                clearIcon: (
                                    <img
                                        width={28}
                                        src={Assets.SharedIcons.icon_square_close}
                                        alt="icon_logo_vertical"
                                    />
                                )
                            }}
                            placeholder="Actualiza el porcentaje del IVA"
                        />
                        <span className='price__modal__text--mod align-right'>%</span>
                        <span className="grid-x price__errors">{touched?.ivaPercentageAddRangePrice? errors?.ivaPercentageAddRangePrice : null}</span>
                    </div>
                    <div className='small-8 grid-x align-middle price__modal__title'>
                        <span className="price__info--mod ">Rango Inicial</span>
                        <span className="price__info--mod-right">Rango abierto</span>
                        <div className='price__content-check__check'>
                            <Checkbox 
                                checked = {values.isOpenRangeAddRangePrice}
                                onChange={handleChangeCheckbox}
                            />
                        </div>
                        <Input
                            prefix="$"
                            className="price__input__content"
                            value={values.initialRangeAddRangePrice?.toLocaleString() }
                            status={errors?.initialRangeAddRangePrice && touched?.initialRangeAddRangePrice ? "error" : "" }
                            onBlur={(value) =>handleBlur("initialRangeAddRangePrice")(value)}
                            onChange={(e) => {handlerOnChangeCost("initialRangeAddRangePrice", e.target.value)}}
                            allowClear={{
                                clearIcon: (
                                    <img
                                        width={28}
                                        src={Assets.SharedIcons.icon_square_close}
                                        alt="icon_logo_vertical"
                                    />
                                )
                            }}
                            placeholder="Agrega el rango inicial"
                        />
                        <span className="grid-x price__errors">{touched?.initialRangeAddRangePrice? errors?.initialRangeAddRangePrice : null}</span>
                    </div>
                    <div className='small-8 grid-x align-middle price__modal__title'>
                        <span className="price__info--mod">Rango Final</span>
                        <Input
                            prefix="$"
                            className="price__input__content"
                            value={values.finalRangeAddRangePrice ? values.finalRangeAddRangePrice.toLocaleString() : ""}
                            status={errors?.finalRangeAddRangePrice && touched?.finalRangeAddRangePrice ? "error" : "" }
                            onBlur={(value) =>handleBlur("finalRangeAddRangePrice")(value)}
                            onChange={(e) => {handlerOnChangeCost("finalRangeAddRangePrice", e.target.value)}}
                            disabled={values.isOpenRangeAddRangePrice === 1}
                            allowClear={{
                                clearIcon: (
                                    <img
                                        width={28}
                                        src={Assets.SharedIcons.icon_square_close}
                                        alt="icon_logo_vertical"
                                    />
                                )
                            }}
                            placeholder="Agrega el rango final"
                        />
                        <span className="grid-x price__errors">{touched?.finalRangeAddRangePrice? errors?.finalRangeAddRangePrice : null}</span>
                    </div>
                </ModalMainComponent>
            }

            {openModalPassagesPrice && 
                <ModalMainComponent
                    width={320}
                    open={openModalPassagesPrice}
                    onClose={modalClose}
                    titleIcon={
                        <div className='grid-x align-center'>
                            <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
                        </div>
                    }
                    footer={[
                        <div key="accept" className='grid-x align-center'>
                            <button
                                className='price__modal__button'
                                onClick={()=> handleCostAdjustmentPassages()}
                                disabled={(isDisabled || !values?.moneyAdjustmentPrice)}
                            >
                            <span>Actualizar Costo</span>
                            </button>
                        </div>,
                        <div key="cancel" className='grid-x align-center'>
                            <button
                                className='price__modal__cancel'
                                onClick={() => modalClose()}
                            >
                            <span >Salir</span>
                            </button>
                        </div>
                    ]}
                >
                    <div className='grid-x align-center-middle'>
                        <span className='price__modal__title'>Pasajes {modalPassages?.name}</span>
                    </div>
                    <div className='grid-x align-center-middle'>
                        <span className='text-center price__modal__text'>Aquí puedes actualizar el costo por unidad del pasaje de la ruta {modalPassages?.name}</span>
                    </div>
                    <div className='small-8 grid-x align-middle price__modal__title'>
                        <span className="price__info">Costo por unidad del pasaje</span>
                        <Input
                            prefix="$"
                            className="price__input__content price__input__content--mod align-left"
                            value={values.moneyAdjustmentPrice?.toLocaleString() }
                            status={errors?.moneyAdjustmentPrice && touched?.moneyAdjustmentPrice ? "error" : "" }
                            onBlur={(value) =>handleBlur("moneyAdjustmentPrice")(value)}
                            onChange={(e) => {handlerOnChangeCost("moneyAdjustmentPrice", e.target.value)}}
                            allowClear={{
                                clearIcon: (
                                    <img
                                        width={28}
                                        src={Assets.SharedIcons.icon_square_close}
                                        alt="icon_logo_vertical"
                                    />
                                )
                            }}
                            placeholder="Actualiza el valor del pasaje"
                        />
                        <span className='price__modal__text--mod align-right'>COP</span>
                        <span className="grid-x price__errors">{touched?.moneyAdjustmentPrice? errors?.moneyAdjustmentPrice : null}</span>
                    </div>
                </ModalMainComponent> 
            }
            {openModalTurnsPrice && 
                <ModalMainComponent
                width={325}
                open={openModalTurnsPrice}
                onClose={modalClose}
                titleIcon={
                    <div className='grid-x align-center'>
                        <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
                    </div>
                }
                footer={[
                    <div key="accept" className='grid-x align-center'>
                        <button
                            className='price__modal__button'
                            onClick={()=> handleCostAdjustment()}
                            disabled={(isDisabled || !values?.percentageCostAdjustmentPrice || !values?.moneyTransferAdjustmentPrice || !values?.ivaAdjustmentPrice)}
                        >
                        <span>Actualizar Costo</span>
                        </button>
                    </div>,
                    <div key="cancel" className='grid-x align-center'>
                        <button
                            className='price__modal__cancel'
                            onClick={() => modalClose()}
                        >
                        <span >Salir</span>
                        </button>
                    </div>
                ]}
            >
                <div className='grid-x align-center-middle'>
                    <span className='price__modal__title'>
                        Giro de 
                        $ {modalTurns.initialRange ? modalTurns?.initialRange.toLocaleString() : ""} - 
                        $ {modalTurns.finalRange ? modalTurns?.finalRange?.toLocaleString() : "∞"}
                    </span>
                </div>
                <div className='grid-x align-center-middle'>
                    <span className='text-center price__modal__text'>Aquí puedes actualizar el costo de la consignacion de un giro</span>
                </div>
                <div className='small-8 grid-x align-middle price__modal__title'>
                    <span className="price__info">Porcentaje del costo del Giro</span>
                    <Input
                        className="price__input__content price__input__content--mod align-left"
                        value={values.percentageCostAdjustmentPrice?.toLocaleString() }
                        status={errors?.percentageCostAdjustmentPrice && touched?.percentageCostAdjustmentPrice ? "error" : "" }
                        onBlur={(value) =>handleBlur("percentageCostAdjustmentPrice")(value)}
                        onChange={(e) => {handlerOnChangeCost("percentageCostAdjustmentPrice", e.target.value)}}
                        allowClear={{
                            clearIcon: (
                                <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                />
                            )
                        }}
                        placeholder="Actualiza el porcentaje del costo del giro"
                    />
                    <span className='price__modal__text--mod align-right'>%</span>
                    <span className="grid-x price__errors">{touched?.percentageCostAdjustmentPrice? errors?.percentageCostAdjustmentPrice : null}</span>
                </div>
                <div className='small-8 grid-x align-middle price__modal__title'>
                    <span className="price__info--mod">Costo del Giro</span>
                    <Input
                        prefix="$"
                        className="price__input__content price__input__content--mod align-left"
                        value={values.moneyTransferAdjustmentPrice?.toLocaleString() }
                        status={errors?.moneyTransferAdjustmentPrice && touched?.moneyTransferAdjustmentPrice ? "error" : "" }
                        onBlur={(value) =>handleBlur("moneyTransferAdjustmentPrice")(value)}
                        onChange={(e) => {handlerOnChangeCost("moneyTransferAdjustmentPrice", e.target.value)}}
                        allowClear={{
                            clearIcon: (
                                <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                />
                            )
                        }}
                        placeholder="Actualiza el monto del giro"
                    />
                    <span className='price__modal__text--mod align-right'>COP</span>
                    <span className="grid-x price__errors">{touched?.moneyTransferAdjustmentPrice? errors?.moneyTransferAdjustmentPrice : null}</span>
                </div>
                <div className='small-8 grid-x align-middle price__modal__title'>
                    <span className="price__info--mod">Porcentaje del IVA</span>
                    <Input
                        className="price__input__content price__input__content--mod align-left"
                        value={values.ivaAdjustmentPrice?.toLocaleString() }
                        status={errors?.ivaAdjustmentPrice && touched?.ivaAdjustmentPrice ? "error" : "" }
                        onBlur={(value) =>handleBlur("ivaAdjustmentPrice")(value)}
                        onChange={(e) => {handlerOnChangeCost("ivaAdjustmentPrice", e.target.value)}}
                        allowClear={{
                            clearIcon: (
                                <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                />
                            )
                        }}
                        placeholder="Actualiza el porcentaje del IVA"
                    />
                    <span className='price__modal__text--mod align-right'>%</span>
                    <span className="grid-x price__errors">{touched?.ivaAdjustmentPrice? errors?.ivaAdjustmentPrice : null}</span>
                </div>
            </ModalMainComponent> 
            }
        </div>
    )
}


export default PricePage


