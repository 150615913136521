//Actions
import { setDataSaleReducer, setStateSaleReducer } from "../../../../../storage/reducers/sales/sales.action";

//Assets
import { Assets } from "../../../../../assets";

//Components
import TableNoSalesComponent from "../table-no-sales/table-no-sales.component";
import ModalElectronicInvoiceComponent from "../modal-electronic-invoice/modal-electronic-invoice.component";
import PdfPassagesComponent from "../../../passages/components/pdf-passages/pdf-passages.component";
import PdfShippingsComponent from "../../../shippings/components/pdf-shippings/pdf-shippings.component";
import PdfTurnComponent from "../../../turn/components/pdf-turn/pdf-turn.component";

//Components - Shared
import ModalMainComponent from "../../../../../shared/components/modal/modal-main/modal-main.component";
import ModalServiceDeliveryComponent from "../modal-service-delivery/modal-service-delivery.component";
import ErrorToastComponent from "../../../../../shared/components/toast/error-toast/error-toast.component";
import SuccessToastComponent from "../../../../../shared/components/toast/success-toast/success-toast.component";

//Constants
import { SalesErrorsConst } from "../../../../../core/constants/errors/alerts-app/sales.errors.const";
import AlertConst from "../../../../../core/constants/alerts.const";

//Core - Const
import SalesConst from "../../../../../core/constants/sales.const";

// Connect WebSocket
import { PrintConnectionSocket } from "../../../../../socket";

//Libraries
import { Box, Pagination, PaginationItem, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiAddBoxLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { AutoComplete, Checkbox, DatePicker, Input, Select, Spin } from "antd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { pdf } from "@react-pdf/renderer";
import { useFormik } from "formik";

// Services
import { cancelledInvoiceService, createElectronicInvoiceService, getInvoiceShippingService, getInvoiceTurnService, getPassagesService, updateToAssignedTravelService } from "../../../../../services/sales.services";
import { getAllDocumentTypeService, getInvoiceService, updateClientService } from "../../../../../services/shared.services";
import { getVehicleForByPlateCodeOrNumberService } from "../../../../../services/vehicle.services";

//Styles
import "./sales-table.component.scss";

// Data
import { assignmentTaps, passageTaps, turnHeaders, turnTaps } from "../../data";

//Utils
import { assignmentHeadersTable, passageHeadersTable } from "../../../../../utils/data/sales.utils";
import { saleTableSchema } from "../../../../../utils/form-validations/sales.validators.utils";
import { createFilterObject } from "../../../../../utils/sales.utils";

const filterOptions = [
  {
    label: "fecha de venta",
    value: 0,
  },
  {
    label: "Monto vendido",
    value: 1,
  },
  {
    label: "Vendedor",
    value: 2,
  },
  {
    label: "Destino",
    value: 3,
  },
  {
    label: "Documento",
    value: 4,
  },
  {
    label: "Nombre del Cliente",
    value: 5,
  },
  {
    label: "Metodo de pago",
    value: 6,
  },
];

const SalesTableComponent = (props) => {
  const {
    //Actions
    setStateSaleReducer,
    setDataSaleReducer,
    //Variables
    salesHistory,
    data,
    innerWidth,
    count,
    onPage = ()=> null,
    onTabShippingsDelivered,
    onTabShippingsCollected,
    isLoadingTable
  } = props

  const [t] = useTranslation("translation")
  const INITIAL_STATE = {
    countTabs: 0,
    dataUser: undefined,
    headers: undefined,
    taps:[],
    selectSale: null,
    valueFilterInput: "",
    pageState: 1,
    initialDateRange: null,
    endDateRange: null,
    checkedItem: undefined,
    openModal: {
      stateModal: false,
      type: 0
    },
    isCreateInvoiceElectronic: true,
    isModalPrint: false,
    idInvoice: undefined,
    isTypePrint: 0,
    linkingMode: {
      selected: null,
      open: false
    },
    optionsVehicleSearch: [],
    vehicleSelected: null,  
    modalFact: null,
    isModalOpen: false,
    documentTypes: []
  }
  const [state, setState] = useState(INITIAL_STATE)
  const { 
    dataUser, selectSale,
    taps, headers, valueFilterInput, initialDateRange, endDateRange,
    checkedItem, openModal, isCreateInvoiceElectronic,
    isModalPrint, idInvoice, isTypePrint,
    linkingMode, optionsVehicleSearch, vehicleSelected,
    isModalOpen,modalFact, documentTypes, countTabs
  } = state
  let history = useNavigate();

  const [prevSalesHistory, setPrevSalesHistory] = useState(salesHistory);

  const {values, errors, setFieldValue, touched, handleBlur} = useFormik({
    initialValues: {
      numberDocumentDelivered: '',
      nameDelivered: '',
      lastNameDelivered: '',
    },
    validationSchema: saleTableSchema
  })

  useEffect(() => {
    function setHeader() {
      let headers;
      let taps;
      switch (salesHistory) {
        case SalesConst.PASSAGES_SALES:
          headers = passageHeadersTable;
          taps = passageTaps;
          break;
        case SalesConst.ASSIGNMENTS_SALES:
          headers = assignmentHeadersTable;
          taps = assignmentTaps;
          break;
        case SalesConst.TURNS_SALES:
          headers = turnHeaders;
          taps = turnTaps;
          break;
      
        default:
          headers = passageHeadersTable;
          taps = passageTaps;
          break;
      }
      setState((prevState) => ({
        ...prevState,
        countTabs: salesHistory !== prevSalesHistory ? 0 : prevState.countTabs,
        dataUser: data? data : [],
        selectSale: null,
        valueFilterInput: "",
        initialDateRange: null,
        endDateRange: null,
        selectedDocumentType: modalFact?.typeDocument,
        checkedItem: undefined,
        headers,
        taps
      }))
      setStateSaleReducer("isLoadingTable", false)
      if(salesHistory !== prevSalesHistory){
        setPrevSalesHistory(salesHistory);
      }
    }
    setHeader()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesHistory, data, modalFact, setStateSaleReducer])

  useEffect(() => {
    async function fetchDocumentTypes() {
      const types = await getAllDocumentTypeService();
      const mappedTypes = types.map((documentType) => ({
        label: documentType?.name,
        value: documentType?.id
      }));
      setState((prevState) => ({
        ...prevState,
        documentTypes: mappedTypes,
      }))
    }
    fetchDocumentTypes();
  }, []);

  const onChange = (data, target) => {
    if (target === "rangePicker") {
      setState((prevState) => ({
        ...prevState,
        initialDateRange: data[0],
        endDateRange: data[1]
      }))
    } else {
      const value = data && data.target ? data.target.value || '' : data;
      setState((prevState) => ({
        ...prevState,
        [target]: value
      }))
    }
  };

  const handleData = (page) => {
    setState((prevState) => ({ 
      ...prevState, 
      pageState: page
    }))
    // TODO: It has been adjusted so that when creating or deleting an order the error that it does not have typePaymentMethod is not thrown.
    // onPage(createFilterObject(valueFilterInput, selectSale, initialDateRange, endDateRange))
  }

  const handleSummary = async(idElement,data) =>{
    if(salesHistory === SalesConst.PASSAGES_SALES){
      try {
        const { passagesResponse , idInvoice} = await getPassagesService(idElement)

        setDataSaleReducer("passageSales",{...passagesResponse})
        setStateSaleReducer("isDisabled", true);
        setStateSaleReducer("idInvoice", idInvoice);
        history("passages", { replace: true, state: { isPreview: true }  });
      } catch {
        // TODO: Implement error alert with code error.
      }
    }else{
      history("summary", { 
        replace: true, 
        state:{
          id: idElement,
          modalFact: data,
          documentTypes: documentTypes
        } 
      })
    }
  }

  const handlerCancelledInvoice = async(id, isElectronic) => {
    try {
      await cancelledInvoiceService({
        idInvoice: id,
        date: dayjs().format('YYYY-MM-DD'),
        time: dayjs().format('HH:mm:ss'),
      })
      setState((prevState) => ({
        ...prevState,
        checkedItem: undefined,
        openModal: {
          stateModal: false,
          type: 0
        },
        isCreateInvoiceElectronic: true
      }))
      let messages = ""
       if(isElectronic) {
          messages="Cancelación satisfactoria, por favor notificarla en Clarisa"
       } else {
        messages="Cancelación de factura realizada"
       }
      SuccessToastComponent({
        html: 
          `<span>${messages}
              <a class="create-user__text-alert"</a>
          </span>`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 8000
      })
      handleData(1)
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        checkedItem: undefined,
        openModal: {
          stateModal: false,
          type: 0
        },
        isCreateInvoiceElectronic: true
      }))
      ErrorToastComponent({
        title: SalesErrorsConst.invoice[err.code] || `${SalesErrorsConst.default} (${err.code || 'UDFND'})`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 4000
      })
    }
  }

  const handlerCreateElectronicInvoice = async(id) => {
    try {
      await updateClientService({contactEmail: checkedItem?.email, id: checkedItem?.idClient})
      await createElectronicInvoiceService(id)
      setState((prevState) => ({
        ...prevState,
        checkedItem: undefined,
        openModal: {
          stateModal: false,
          type: 0
        },
        isCreateInvoiceElectronic: true
      }))

      SuccessToastComponent({
        html: 
          `<span>Creación de factura electronica realizada
              <a class="create-user__text-alert"</a>
          </span>`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 5000
      })
      handleData(1)
    } catch (err) {
      ErrorToastComponent({
        title: SalesErrorsConst.invoice[err.code] || `${SalesErrorsConst.default} (${err.code || 'UDFND'})`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const handleTabSelection = (indexTab, callback) => {
    let objectFilter = createFilterObject(valueFilterInput, selectSale, initialDateRange, endDateRange);

    if(salesHistory === SalesConst.ASSIGNMENTS_SALES){
      if(indexTab === 0){
        objectFilter.typePaymentMethod = 1;
      }else if(indexTab === 1){
        objectFilter.typePaymentMethod = 3;
      }else if (indexTab === 2) {
        onTabShippingsDelivered({ ...objectFilter, isDelivered: 0 });
        return;
      }else if (indexTab === 3) {
        onTabShippingsDelivered({ ...objectFilter, isDelivered: 1 });
        return;
      }else if (indexTab === 4) {
        onTabShippingsCollected({ ...objectFilter });
        return;
      }
    }else if(salesHistory === SalesConst.TURNS_SALES){
      if(indexTab === 0){
        objectFilter.isReportLocal = 1;
        objectFilter.isGetClaimed =0;
      }else if(indexTab === 1){
        objectFilter.isReportLocal = 1;
        objectFilter.isGetClaimed =1;
      }else if (indexTab === 2) {
        objectFilter.isReportLocal = 0;
      }
    }
    callback(objectFilter);
  }

  const handlerSearchFilterSaleTable = (indexTab) => {
    handleTabSelection(indexTab, onPage);
  }

  const handleTabSales = async (indexTab) => {
    setStateSaleReducer('countTabs', indexTab);
    handleTabSelection(indexTab, onPage);
  }

  const handlerClearFilterParams = () => {
    setState((prevState) => ({ 
      ...prevState, 
      selectSale: null,
      valueFilterInput: "",
      initialDateRange: null,
      endDateRange: null
    }))
    onPage({page: 0})
  }

  const handlerChangeInputFilter = (data, target) => {
    const value = data && data.target ? data.target.value || '' : data;
       setState((prevState) => ({ 
      ...prevState, 
      [target]: value
    }))
  }

  const closeModal = () => {
    setState({
      ...state, 
      openModal: {
        ...openModal,
        stateModal:false
      },
      isCreateInvoiceElectronic: openModal.type ? isCreateInvoiceElectronic : true
    })
  }

  const acceptModal = (type) => {
    switch (type) {
      case 0:
        handlerCreateElectronicInvoice(checkedItem.id).then(() => {
          handleTabSales(countTabs);
        });
        break;
      case 1:
          handlerCancelledInvoice(checkedItem.id, checkedItem.isElectronic).then(() => {
            handleTabSales(countTabs);
          });
        break;
      default:
        break;
    }
  }

  const changeEmail = (email) => {
    const validation = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
    if (validation.test(email)) {
      if (isCreateInvoiceElectronic) {
        setState((prevState)=>({
          ...prevState,
          isCreateInvoiceElectronic: false,
          checkedItem: {
            ...checkedItem,
            email: email
          }
        }))
      }else{
        setState((prevState)=>({
          ...prevState,
          checkedItem: {
            ...checkedItem,
            email: email
          }
        }))
      }
    }else{
      setState((prevState)=>({
        ...prevState,
        isCreateInvoiceElectronic: true,
        checkedItem: {
          ...checkedItem,
          email: email
        }
      }))
    }
  }

  const pdfDownload = async(idInvoice) => {
    try {
      const dataInvoice = await servicesTypeSalesServices(idInvoice);
      const tabClaimedShippings = salesHistory === SalesConst.ASSIGNMENTS_SALES && countTabs === 3;
      const tabClaimedTurns = salesHistory === SalesConst.TURNS_SALES && countTabs === 1;
      const typeSalesComponent = {
        [SalesConst.PASSAGES_SALES]: <PdfPassagesComponent dataInvoice={dataInvoice}/>,
        [SalesConst.ASSIGNMENTS_SALES]: <PdfShippingsComponent dataInvoice={dataInvoice} tabClaimedShippings={tabClaimedShippings}/>,
        [SalesConst.TURNS_SALES]: <PdfTurnComponent dataInvoice={dataInvoice} tabClaimedTurns={tabClaimedTurns}/>,
      }
      const blob = await pdf(typeSalesComponent[salesHistory]).toBlob();
  
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = `document${Date.now()}.pdf`;
      link.click();
  
      URL.revokeObjectURL(url);
      window.toFlutter.postMessage(JSON.stringify(dataInvoice));
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const handlePrint = async (idInvoice) => {
    if (innerWidth < 639) {
      pdfDocumentPrint(undefined, idInvoice)
    } else {
      try {
        const { connectSocket, printTicketSocket } = PrintConnectionSocket();
        connectSocket();
        const typeSalesComponent = {
          [SalesConst.PASSAGES_SALES]: 'printPassage',
          [SalesConst.ASSIGNMENTS_SALES]: 'printAssignment',
          [SalesConst.TURNS_SALES]: 'printTurns',
        }
        const dataInvoice = await servicesTypeSalesServices(idInvoice);
        printTicketSocket({ typeTicket: typeSalesComponent[salesHistory], data: dataInvoice });
      } catch (error) {
        // TODO: Implement error alert with code error.
      }
      // TODO: print type modal.
      // setState((prevState)=>({
      //   ...prevState,
      //   isModalPrint: true,
      //   idInvoice: idInvoice
      // }))
    }
  }

  const pdfDocumentPrint = async (size, idInvoice, isDelivery = false) => {
    try {
      if (innerWidth < 639) {
        const dataInvoice = await servicesTypeSalesServices(idInvoice);
        const typeSalesComponent = {
          [SalesConst.PASSAGES_SALES]: size ? <PdfPassagesComponent dataInvoice={dataInvoice} size={size} /> : <PdfPassagesComponent dataInvoice={dataInvoice} />,
          [SalesConst.ASSIGNMENTS_SALES]: size ? <PdfShippingsComponent dataInvoice={dataInvoice} size={size} isDelivery={isDelivery} /> : <PdfShippingsComponent dataInvoice={dataInvoice} isDelivery={isDelivery} />,
          [SalesConst.TURNS_SALES]: size ? <PdfTurnComponent dataInvoice={dataInvoice} size={size} isDelivery={isDelivery} /> : <PdfTurnComponent dataInvoice={dataInvoice} isDelivery={isDelivery} />,
        }
        const pdfBlob = pdf(typeSalesComponent[salesHistory]);
        pdfBlob.toBlob().then((blob) => {
          printPdf(URL.createObjectURL(blob))
        });
        window.toFlutter.postMessage(JSON.stringify(dataInvoice));
      } else {
        const { connectSocket, printTicketSocket } = PrintConnectionSocket();
        connectSocket();
        const typeSalesComponent = {
          [SalesConst.PASSAGES_SALES]: 'printPassage',
          [SalesConst.ASSIGNMENTS_SALES]: 'printAssignment',
          [SalesConst.TURNS_SALES]: 'printTurns',
        }
        const dataInvoice = await servicesTypeSalesServices(idInvoice);
        printTicketSocket({ typeTicket: typeSalesComponent[salesHistory], data: dataInvoice });
      }
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const servicesTypeSalesServices = async(idInvoice) => {
    switch (salesHistory) {
      case SalesConst.PASSAGES_SALES:
        try {
          return await getInvoiceService(idInvoice);
        } catch (error) {
          // TODO: Implement error alert with code error.
        }
        break;
      case SalesConst.ASSIGNMENTS_SALES:
        try {
          return await getInvoiceShippingService(idInvoice);
        } catch (error) {
          // TODO: Implement error alert with code error.
        }
        break;
      case SalesConst.TURNS_SALES:
        try {
          return await getInvoiceTurnService(idInvoice);
        }
        catch (error) {
          // TODO: Implement error alert with code error.
        }
        break;
      default:
        break;
    }
  }

  const printPdf = async(url) => {
    window.open(url)
  };

  const onCloseModalPrint = () =>{
    setState((prevState)=>({
      ...prevState,
      isModalPrint: false,
      idInvoice: undefined,
      isTypePrint: 0
    }))
  }

  const handleLinking = (item) =>{
    setState((prevState)=>({
      ...prevState,
      linkingMode: {
        selected: item,
        open: true
      }
    }))
  }

  const closeModalLinking = () =>{
    setState((prevState)=>({
      ...prevState,
      linkingMode: {
        ...prevState.linkingMode,
        open: false
      },
      vehicleSelected: null
    }))
  }

  const filterVehicle = async(filter) => {
    try {
        const listVehicles = await getVehicleForByPlateCodeOrNumberService({
            valueFilter:filter,
            date: dayjs().format('YYYY-MM-DD'),
            time: dayjs().format('HH:mm:ss')
        })

        if (listVehicles.length === 0) {
          ErrorToastComponent({
            title: "No se encontraron vehiculos disponibles en el turnero manual",
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
          })
        }
        setState((prevState) => ({
            ...prevState,
            optionsVehicleSearch: listVehicles
        }))
    } catch (err) {
      ErrorToastComponent({
        title: SalesErrorsConst.shippings[err.code] || SalesErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const handleLinkVehicle = async() =>{
    try {
      await updateToAssignedTravelService(
        linkingMode?.selected?.id,
        vehicleSelected?.travel?.id
      )
      setState((prevState)=>({
        ...prevState,
        linkingMode: {
          ...prevState.linkingMode,
          open: false
        }
      }))
      handleTabSales(countTabs)
      handlerClearFilterParams()
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const onCloseModal = () =>{
    setState((prevState)=>({
      ...prevState,
      isModalOpen: false,
    }))
  }

  const getFilteredOptions = (selectSale) => {
    if (selectSale === SalesConst.PASSAGES_SALES) {
      return filterOptions;
    } else {
      return filterOptions.filter(option => option.label !== 'Metodo de pago');
    }
  };
  
  return (
    <>
      <div className="sales-table__filters">
        <div className="grid-x sales-table__select__container">
          <Select
            value={selectSale}
            onChange={(e, event) => {
              setState({
                ...state,
                selectSale: event
              })
            }}
            placeholder={t("sales.salesFooter.selectBy")}
            className="sales-table__select"
            options={getFilteredOptions(salesHistory)}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
          />
          {(selectSale || innerWidth < 639) && (
            <button className="grid-x align-center-middle sales-table__clear"
              onClick={() => { handlerClearFilterParams()}}
            >
              <img
                src={Assets.SalesIcons.icon_clear}
                alt="icon_clear"
              />
            </button>
          )}
        </div>
        <div className="grid-x sales-table__input__container">
          <Input
            disabled={selectSale === null}
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            onChange={(value) => handlerChangeInputFilter(value, 'valueFilterInput')}
            value={valueFilterInput}
            className="sales-table__input__content"
            placeholder={t("sales.salesFooter.whatSearch")}
            prefix={
              <img
                className="sales-table__input__search"
                src={Assets.SharedIcons.icon_search}
                alt="icon_search"
              />
            }
          />
        </div>
        <div className={selectSale?.value !== 0 ? "sales-table__date__container--mod" : "sales-table__date__container"}>
          {selectSale?.value === 0 && (
            (innerWidth > 639)
              ? (
                <DatePicker.RangePicker
                  format={"DD/MM/YYYY"}
                  value={
                    initialDateRange && endDateRange ? [dayjs(initialDateRange, 'DD:MM:YYYY'), dayjs(endDateRange, 'DD:MM:YYYY')] : null}
                  onChange={(e, date) => onChange(date, 'rangePicker')}
                  className="sales-table__range-picker"
                  separator={<img src={Assets.SalesIcons.icon_right_arrow} alt="icon_right_arrow" />}
                  suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar"/>}
                  allowClear={{
                    clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar"/>)
                  }}
                  popupClassName="sales-table__range-picker__modal"
                  superNextIcon={
                    <img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />
                  }
                  superPrevIcon={
                    <img src={Assets.SalesIcons.icon_back} alt="icon_back" />
                  }
                  nextIcon={
                    <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
                  }
                  prevIcon={
                    <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
                  }
                />
              )
              : <>
                <DatePicker
                  className="sales-table__date"
                  format={"DD/MM/YYYY"}
                  placeholder="DD/MM/AAAA"
                  value={initialDateRange ? dayjs(initialDateRange, 'DD:MM:YYYY') : null}
                  onChange={(e, date) => onChange(date, 'initialDateRange')}
                  allowClear={{
                    clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
                  }}
                  popupClassName="sales-table__date__picker"
                  suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
                  superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
                  superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
                  nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
                  prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
                />
                <DatePicker
                  className="sales-table__date"
                  format={"DD/MM/YYYY"}
                  placeholder="DD/MM/AAAA"
                  value={endDateRange ? dayjs(endDateRange, 'DD:MM:YYYY') : null}
                  onChange={(e, date) => onChange(date, 'endDateRange')}
                  allowClear={{
                    clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
                  }}
                  popupClassName="sales-table__date__picker"
                  suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
                  superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
                  superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
                  nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
                  prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
                />
              </>
          )
          }
        </div>
        <div className="sales-table__button__container">
          <button 
            className="sales-table__button"
            onClick={() => {
              handlerSearchFilterSaleTable(countTabs)
            }}
          >
            <span>{t("sales.salesFooter.search")}</span>
          </button>
        </div>
      </div>
      <div className="sales-table__content-pagination">
        { checkedItem &&
          <div className="sales-table__invoice-button__box sales-table__hide">
            <div className="sales-table__invoice-button__container">
              <div className="sales-table__invoice-button__content">
                  <button 
                    className={`sales-table__invoice-button ${checkedItem.isElectronic ? "sales-table__invoice-button--mod": ""}`}
                    onClick={() => {
                      setState((prevState)=>({
                        ...prevState,
                        checkedItem: checkedItem,
                        openModal:{
                          stateModal: true,
                          type: 0
                        }
                      }))
                      changeEmail(checkedItem?.email)
                    }}
                  >
                    <span className="sales-table__invoice-button__text">Convertir en factura electrónica</span>
                    <img
                      src={Assets.SalesIcons.icon_invoice}
                      alt="icon_invoice"
                    />
                  </button>
                <button 
                  className="sales-table__invoice-button"
                  onClick={() => {
                    setState({
                      ...state,
                      checkedItem: checkedItem,
                      openModal:{
                        stateModal: true,
                        type: 1
                      }
                    })
                  }}
                >
                  <span className="sales-table__invoice-button__text">Cancelar factura</span>
                  <img
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_square_close"
                  />
                </button>
              </div>
            </div>
          </div>
        }
        <Box className="sales-table__container">
          <Box>
            <Tabs
              value={countTabs}
              onChange={(e, event) => onChange(event, "countTabs")}
              aria-label="basic tabs example"
              className="sales-table__tabs__content"
            >
              {
                taps?.map((tap, index) => <Tab key={index} className="sales-table__tabs" label={tap} onClick={()=>handleTabSales(index)}/>)
              }            
            </Tabs>
          </Box>
          {isLoadingTable ? (
            <Spin
              size = 'large'
              tip = "Cargando los datos..."
              delay={200}
              className='sales-table__spin'
            >
              <span></span>
            </Spin>
          ) : (
            <div className="sales-table__content">
              {dataUser?.map((fact, index) => {
                return (
                  <div
                    key={index}
                    className={
                      `grid-x small-12 sales-table__content-data 
                      ${index === dataUser?.length - 1
                        ? "sales-table__content-data--mod"
                        : ""
                      }`
                    }
                  >
                    {Object.keys(fact).map((key, i) => {
                      if (key !== 'id' && key !== 'isCancelled'&& key !== 'isElectronic' && key !== 'email' && key !== 'idClient' && key !== 'paymentMethod' && key !== 'dataClientReceivesr' && key !== 'documentClientReceives' && key !=='typeDocument' && key !=='amountMoney') {
                      return (
                        <div key={i} className={`${((i === 2 && salesHistory === SalesConst.PASSAGES_SALES)|| (i === 4 && salesHistory === SalesConst.PASSAGES_SALES) || i === 5 || i === 3) ? "medium-2 sales-table__text__content" : "medium-1 sales-table__text__content"}`} >
                          <span
                            className={
                              "sales-table__text sales-table__title " +
                              (index === 0 ? "" : "sales-table__none")
                            }
                            style={innerWidth > 639 ? { textAlign: "center" } : {}}
                          >
                            {headers ? headers[key] : null}
                          </span>
                          <div className="sales-table__data-content">
                            { (key === "factura" || key === "guide") &&
                              <Checkbox
                              disabled={fact?.isCancelled || (salesHistory === SalesConst.ASSIGNMENTS_SALES && (countTabs === 2 || countTabs === 3)) || (salesHistory === SalesConst.TURNS_SALES && countTabs === 2)}
                                className="sales-table__checkbox sales-table__hide"
                                checked={checkedItem?.id === fact?.id && (countTabs !== 2 || countTabs !== 3)}
                                onChange={() => {
                                  setState({
                                    ...state,
                                    checkedItem: fact?.id !== checkedItem?.id ? fact : undefined
                                  })
                                }}
                              />
                            }
                            {
                              key === "vehicle" ? (
                                !fact[key] && (countTabs === 0 || countTabs === 1) ? (
                                  <button
                                    onClick={()=> handleLinking(fact)}
                                    className="grid-x justify-content-between sales-table__button__secondary align-middle"
                                    style={{ padding: "4px", margin: "1.5% 0" }}
                                    disabled={fact?.isCancelled}
                                  >
                                    <span className="small-10 sales-table__button__secondary__text">
                                      Vincular vehículo
                                    </span>
                                    <img
                                      src={Assets.SharedIcons.icon_associate_driver}
                                      alt="icon_associate_driver"
                                    />
                                  </button>
                                ) : 
                                fact[key] && (countTabs === 0 || countTabs === 1) ? (
                                  <button
                                    onClick={()=> !(salesHistory === SalesConst.PASSAGES_SALES  && innerWidth < 639) && handleSummary(fact['id'],fact)}
                                    className={`sales-table__text ${fact?.isCancelled && (headers[key] === "Costo de Venta" || headers[key]=== "Monto del giro") ? "sales-table__text--cancel" : (fact?.isCancelled ? "sales-table__text--mod" : "")}`}
                                  >
                                    {fact?.isCancelled && (headers[key] === "Costo de Venta" || headers[key]=== "Monto del giro") ? "Cancelado" : fact[key]}
                                    { key === "vehicle" &&
                                      <img
                                        src={Assets.SharedIcons.icon_associate_driver}
                                        alt="icon_associate_driver"
                                        style={{ marginLeft: "5px" }}
                                      />
                                    }
                                    {((fact?.isElectronic && key === "factura") || (fact?.isElectronic && key === "guide")) &&
                                      <img
                                        src={Assets.SalesIcons.icon_invoice}
                                        alt="icon_invoice"
                                      />
                                    }
                                  </button>
                                ) : 
                                fact[key] && countTabs === 2 ? (
                                  <button
                                    onClick={()=> !(salesHistory === SalesConst.PASSAGES_SALES  && innerWidth < 639) && handleSummary(fact['id'],fact)}
                                    className={`sales-table__text ${fact?.isCancelled && (headers[key] === "Costo de Venta" || headers[key]=== "Monto del giro") ? "sales-table__text--cancel" : (fact?.isCancelled ? "sales-table__text--mod" : "")}`}
                                  >
                                    {fact?.isCancelled && (headers[key] === "Costo de Venta" || headers[key]=== "Monto del giro") ? "Cancelado" : fact[key]}
                                    { key === "vehicle" &&
                                      <img
                                        src={Assets.SharedIcons.icon_associate_driver}
                                        alt="icon_associate_driver"
                                        style={{ marginLeft: "5px" }}
                                      />
                                    }
                                    {((fact?.isElectronic && key === "factura") || (fact?.isElectronic && key === "guide")) &&
                                      <img
                                        src={Assets.SalesIcons.icon_invoice}
                                        alt="icon_invoice"
                                      />
                                    }
                                  </button>
                                ) : null
                              ) : (
                                <button
                                  onClick={()=> !(salesHistory === SalesConst.PASSAGES_SALES  && innerWidth < 639) && handleSummary(fact['id'],fact)}
                                  className={`sales-table__text ${fact?.isCancelled && (headers[key] === "Costo de Venta" || headers[key]=== "Monto del giro") ? "sales-table__text--cancel" : (fact?.isCancelled ? "sales-table__text--mod" : "")}`}
                                >
                                  {fact?.isCancelled && (headers[key] === "Costo de Venta" || headers[key]=== "Monto del giro") ? "Cancelado" : fact[key]}
                                  {((fact?.isElectronic && key === "factura") || (fact?.isElectronic && key === "guide")) &&
                                    <img
                                      src={Assets.SalesIcons.icon_invoice}
                                      alt="icon_invoice"
                                    />
                                  }
                                </button>
                              )
                            }
                          </div>
                        </div>
                      )} else {
                        return null
                      }
                    })}
                    <div className="medium-1 small-12 sales-table__text__content sales-table__text__content--mod">
                      <div
                        className={
                          "sales-table__text sales-table__title sales-table__title--mod " +
                          (index === 0 ? "" : "sales-table__hidden")
                        }
                      >
                        { !fact?.isElectronic && !fact?.isCancelled &&
                          <button 
                            className="sales-table__view-more"
                            onClick={() => {
                              const validation = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
                              const isCreateInvoiceElectronicState = !validation.test(fact?.email)
                              setState({
                                ...state,
                                checkedItem: fact,
                                openModal:{
                                  stateModal: true,
                                  type: 0
                                },
                                isCreateInvoiceElectronic: isCreateInvoiceElectronicState,
                              })
                            }}
                          >
                            <img
                              src={Assets.SalesIcons.icon_invoice}
                              alt="icon_invoice"
                            />
                          </button>
                        }
                        {!fact?.isCancelled &&
                          <button
                            onClick={() => {
                              setState({
                                ...state,
                                checkedItem: fact?.id !== checkedItem?.id ? fact : undefined,
                                openModal:{
                                  stateModal: true,
                                  type: 1
                                }
                              })
                            }}
                          >
                            <RiAddBoxLine className="sales-table__icons__content sales-table__icons__content--mod" />
                          </button>
                        }
                      </div>
                      <div className="sales-table__icons">
                        {/* TODO: Commented by new parameter */}
                        {/* <button
                          onClick={() => handleSummary(fact['id'],fact)}
                        >
                          <img
                            src={Assets.SharedIcons.icon_view_details_inactive}
                            alt="icon_view_details_inactive"
                          />
                        </button> */}
                        {(salesHistory === SalesConst.TURNS_SALES || salesHistory === SalesConst.ASSIGNMENTS_SALES ) && countTabs === 2 ? (
                        <div className="sales-table__icons__modal">
                          <button
                            disabled={fact?.isCancelled}
                            onClick={() => {
                                      
                              setState((prevState)=>({
                                ...prevState,
                                isModalOpen: true,
                                modalFact: fact,
                                // deliveryData: {
                                //   ...prevState.deliveryData,
                                //   numberDocumentUser: fact.documentClientReceives.numberDocument,
                                //   nameUser: fact.dataClientReceivesr.name,
                                //   lastNameUser: fact.dataClientReceivesr.lastName,
                                //   idDocumentType: fact.typeDocument.value
                                // }
                              }))
                            }}
                          >
                            <img
                              src={fact?.isCancelled ? Assets.SharedIcons.icon_add : Assets.SharedIcons.icon_add_hover}
                              alt="icon_add_hover"
                            />
                          </button>
                          
                        </div>
                      ): null}
                        <div
                          className="sales-table__icons__link"
                        >
                          <button
                            disabled={fact?.isCancelled}
                            onClick={() => {
                              pdfDownload(fact?.id)
                            }}
                          >
                            <img
                              src={fact?.isCancelled ? Assets.SalesIcons.icon_shared_disabled : Assets.SalesIcons.icon_shared}
                              alt="icon_shared_disabled"
                            />
                          </button>
                        </div>
                        <button
                          disabled={fact?.isCancelled}
                          onClick={() => handlePrint(fact?.id)}
                          style={{ visibility: fact?.isCancelled ? "hidden" : "visible" }}
                        >
                          <img
                            src={Assets.SalesIcons.icon_print}
                            alt="icon_print"
                          />
                        </button>
                      </div>
                    </div> 
                  </div>
                );
              })}
              {dataUser?.length === 0 &&
                <TableNoSalesComponent headers={headers} />
              }
            </div>
          )}
        </Box>
        {isLoadingTable ? null : (
          <div className="grid-x justify-content-between sales-table__content__navigation">
            <span className="sales-table__info">
              {`${data?.length || 0}  ${t("sales.salesFooter.salesTable.from")} ${count} ${t("sales.salesFooter.salesTable.results")}`}
            </span>
            <div className="grid-x sales-table__pagination__content">
              <Pagination
                disabled={count === 0  ? true : false}
                onChange={(e, event) => handleData(event)}
                count={Math.ceil(count / 50) || 1}
                siblingCount={0}
                boundaryCount={0}
                showFirstButton
                showLastButton
                shape="rounded"
                className="sales-table__pagination"
                renderItem={(item) => (
                  <PaginationItem
                    className="sales-table__pagination__item"
                    slots={{
                      previous: () => {
                        return (
                          <img
                            src={Assets.SharedIcons.icon_back}
                            alt="icon_back"
                          />
                        )
                      },
                      next: () => {
                        return (
                          <img
                            src={Assets.SharedIcons.icon_next}
                            alt="icon_forward"
                          />
                        )
                      },
                      first: () => {
                        return (
                          <img
                            src={Assets.SalesIcons.icon_back}
                            alt="icon_back"
                          />
                        )
                      },
                      last: () => {
                        return (
                          <img
                            src={Assets.SalesIcons.icon_forward}
                            alt="icon_back"
                          />
                        )
                      },
                    }}
                    {...item}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
      { openModal?.stateModal &&
        <ModalMainComponent
          width={openModal.type ? 320 : 400}
          open={openModal?.stateModal}
          onClose={()=> closeModal()}
          titleIcon={
            <div className="grid-x align-center-middle">
              <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
            </div>
          }
          footer={[
            <button 
              key="accept" 
              className="sales-table__modal-electronic__button"
              onClick={()=> acceptModal(openModal.type)}
              disabled={!openModal.type && isCreateInvoiceElectronic ? true : false}
            >
              <span className="sales-table__modal-electronic__button__label">
                {openModal.type ? "Sí, cancelar factura" : "Crear factura electrónica"}
              </span>
            </button>,
            <div className="sales-table__modal-electronic__close" key="cancel">
              <button
                className="sales-table__modal-electronic__close__label"
                onClick={()=> closeModal()}
              >
                {openModal.type ? "Salir" : "Cancelar"}
              </button>
            </div>
          ]}
        >
          <>
            { openModal.type ?
              <div className="grid-x align-center-middle">
                <p className="sales-table__modal-electronic__title">
                  Estás a punto de cancelar una factura
                </p>
                <p className="sales-table__modal-electronic__info">
                  Una vez realizada la cancelación de esta factura todos los valores de la misma quedarán en $0.
                </p>
                <p className="sales-table__modal-electronic__text">
                  ¿Estas seguro que deseas cancelar esta factura?
                </p>
              </div>
              :
              <ModalElectronicInvoiceComponent
                email={checkedItem?.email}
                changeEmail={(email)=>changeEmail(email)}
                isCreateInvoiceElectronic={isCreateInvoiceElectronic}
              />
            }
          </>
        </ModalMainComponent>
      }
      <ModalMainComponent
        open={isModalPrint}
        onClose={()=> onCloseModalPrint()}
        width={300}
        close
        footer={[
          <button 
              key="accept" 
              className="sales-table__modal-electronic__button"
              onClick={()=>pdfDocumentPrint(isTypePrint, idInvoice)}
            >
              <span className="sales-table__modal-electronic__button__label">
                Aceptar
              </span>
            </button>,
        ]}
        titleIcon={
          <div className="grid-x align-center-middle">
            <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
          </div>
        }
      >
        <p className="passages__subtitle text-center" style={{ marginTop: "20px" }}>
          Imprimir Factura
        </p>
        <p className="sales-table__modal-electronic__info text-center" style={{ width: "100%" }}>
          Selecciona el formato de impresión de factura de venta
        </p>
        <div className="grid-x">
          <Select
            value={isTypePrint}
            onChange={(e)=> setState({
              ...state,
              isTypePrint: e
            })}
            placeholder={"Selecciona el tipo de impresión"}
            className="small-12"
            options={[
              {
                label: "Impresión de 80 mm",
                value: 0
              },
              {
                label: "Impresión formato carta",
                value: 1
              }
            ]}      
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
          />
        </div>
      </ModalMainComponent>
      <ModalMainComponent
        open={linkingMode?.open}
        onClose={()=> closeModalLinking()}
        close
        footer={[
          <button 
            disabled={!vehicleSelected}
            key="accept" 
            className="sales-table__modal-electronic__button"
            onClick={()=> handleLinkVehicle()}
          >
            <span className="sales-table__modal-electronic__button__label">
              Vincular vehículo
            </span>
          </button>,
        ]}
        titleIcon={
          <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
        }
      >
        <p className="sales-table__linking__label text-center">
          Vas a vincular a la encomienda con el número de guía {linkingMode?.selected?.guide} un vehículo
        </p>
        <div className="grid-x sales-table__linking__content">
          <label 
            className="grid-x ordering-data__label align-left"
            style={{ width: "100%" }}
          >
            Busca vehículos Disponible
          </label>
          <AutoComplete
            style={{ width: "100%" }}
            onSearch={(e) => {
                const trimmedValue = e.trim();
                if (trimmedValue) {
                    filterVehicle(trimmedValue);
                } else { 
                  setState({ ...state, optionsVehicleSearch: [], vehicleSelected: null });
                }
            }}
            options={optionsVehicleSearch}
            onSelect={(value,options)=> setState({...state,vehicleSelected:options})}
            onChange={() => setState({...state, vehicleSelected: null})}
          >
            <Input
              allowClear={{
                clearIcon: (
                <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                />
                )
              }}
              className="modal-transfer__input__content"
              placeholder="Buscar vehículo"
              prefix={
                <img
                  className="modal-transfer__input__search"
                  src={Assets.SharedIcons.icon_search}
                  alt="icon_search"
                />
              }
            />
          </AutoComplete>
        </div>
      </ModalMainComponent>
      {isModalOpen && modalFact && (
        <ModalServiceDeliveryComponent
          isModalOpen={isModalOpen}
          onCloseModal={onCloseModal}
          modalFact={modalFact}
          documentTypes={documentTypes}
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          countTabs={countTabs}
          salesHistory={salesHistory}
          onTabShippingsDelivered={onTabShippingsDelivered}
          initialDateRange={initialDateRange}
          endDateRange={endDateRange}
          valueFilterInput={valueFilterInput}
          selectSale={selectSale}
          onPage={onPage}
          pdfDocumentPrint={pdfDocumentPrint}
          origin="sales-table"
          setDataStatusMod={(newData) => setState((prevState) => ({
            ...prevState,
            isDeliveryButton: newData?.delivery ? true : false
          }))}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ AppReducer, SalesReducer}) => {
  const { windowSize: { innerWidth } } = AppReducer;
  const { salesHistory, isLoadingTable} = SalesReducer;

  return {
    innerWidth,
    salesHistory,
    isLoadingTable
  };
};

const mapStateToPropsActions = {
  setStateSaleReducer,
  setDataSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(SalesTableComponent);
