import * as authenticationTypes from './authentication.types';

const INITIAL_STATE = {
	userLogged: undefined,
	isLogged: false,
	isError: false,
	error: undefined,
	isCheckingSession: false,
	isValidSession: false,
	uidUserRecoveryPassword: ""
};

/**
 * AuthenticationReducer - This reducer stores the information related to the user authentication module, so we will find the information of a user logged into the project, it also contains the errors occurred in the authentication process.
 *
 * @param {[object]} userLogged User logged information.
 * @param {[boolean]} isLogged  Flag to identify if there is a user logged.
 * @param {[boolean]} isError Flag to identify if there is an error in the user authentication process. 
 * @param {[object]} error Contains the information of the error occurred in the authentication process.
 * @param {[object]} isCheckingSession Flag containing the confirming boolean value if the session token was checked with API.
 * @param {[object]} isValidSession Flag containing the confirming boolean value if the API session token is valid and/or correct.
 * @param {[object]} uidUserRecoveryPassword contains the uid of the user identified for password change.
 *
 */

const AuthenticationReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {
		case authenticationTypes.LOGIN:
			return {
				...state,
				isLogged: true,
				userLogged: action.payload
			};

		case authenticationTypes.LOGOUT:
			return {
				...state,
				isLogged: false,
				userLogged: undefined
			};

		case authenticationTypes.SET_ERROR_LOGIN:
			return {
				...state,
				isError: true,
				error: action.payload
			};

		case authenticationTypes.SET_STATE_AUTHENTICATION:

			return {
				...state,
				[action.payload.prop]: action.payload.value
			};


		case authenticationTypes.SET_PROCESS_VALIDATE_SESSION:
			return {
				...state,
				isCheckingSession: action.payload.isCheckingSession,
				isValidSession: action.payload.isValidSession
			};
		case authenticationTypes.RESET_STATE:

			return {
				...state,
				INITIAL_STATE
			};

		default:
			return state;
	}

};

export default AuthenticationReducer;