// Assets
import { Assets } from '../../../../../assets'

//Actions
import { setDataNewUserStateReducer, setStateNewUserReducer } from '../../../../../storage/reducers/user-settings/user-settings.action'

// Libraries
import { Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { connect } from 'react-redux'

//Services
import { getModulesService } from '../../../../../services/settings.services'

// Styles
import "./permits-user.component.scss"

// Utils
import { optionPermitsSchema } from '../../../../../utils/form-validations/settings.validators.utils'
import { getRoleService } from '../../../../../services/settings.services'


const PermitsUserComponent = (props) => {

    const {
        setStateNewUserReducer,
        setDataNewUserStateReducer,
        permitsNewUser
    } = props

    const INITIAL_STATE = {
        rolesUser: [],
        isChecking: false,
        checkingYes: false,
    }

    const [state, setState] = useState(INITIAL_STATE)
    const { 
        rolesUser,
        isChecking,
        checkingYes
    } = state


    const {values, setValues, errors, touched, handleBlur, setTouched} = useFormik({
        initialValues:{
            typeUser: undefined,
            optionsPermits : []
        },
        validationSchema: optionPermitsSchema
      })
      useEffect(() => {
          getModulesUser();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
    
    const getModulesUser = async () => {
        try{
            const modules = await getModulesService();
            
        let touchesValues = {
            typeUser: permitsNewUser.typeUser ? true : false,
            optionsPermitsNewUser: permitsNewUser.optionsPermitsNewUser ? true : false,
        }
        let valuesFormik = {
            ...values,
            typeUser: permitsNewUser.typeUser,
            optionsPermits: modules
        }
        
        if (permitsNewUser.optionsPermitsNewUser.length) {
            const newPermits = updateCheckedProperty(valuesFormik.optionsPermits, permitsNewUser.optionsPermitsNewUser)
            valuesFormik.optionsPermits = newPermits;
        }
        setTouched({...touched,...touchesValues});
        setValues(valuesFormik);
        async function fetchAndSetRoles() {
            const roles = await getRoleService();
            const mappedRoles = roles.map((role) => ({
              label: role?.name,
              value: role?.id
            }));
            setState((prevState) => ({
              ...prevState,
              rolesUser: mappedRoles,
              isChecking: valuesFormik.optionsPermits.some((item) => item.label === "Planillas" && item.checked)
            }))
          }
          fetchAndSetRoles();
        }catch(error){
            //TODO: Implement alert with error code.
        }
    }

    const mergeObjects = (modules, modulesChecked) => {
        return { ...modules, ...modulesChecked };
    }

    const updateCheckedProperty = (modules, modulesChecked) => {
        return modules.map(module => {
            const matchingObject = modulesChecked.find(obj => obj.id === module.id);
            return matchingObject ? 
            mergeObjects(module, { checked: matchingObject.checked }) : module; });
        }

    useEffect(() => {
        setStateNewUserReducer("isError", !!Object.keys(errors).length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [errors]);

    const onChange = (data, target) => {
        const value = data && data.target ? data.target.value || '' : data;
        if (target === "typeUser") {
            const permitUserData = { ...values, [target]: value };
            setValues(permitUserData);
            setDataNewUserStateReducer("permitsNewUser", target, value);
        } else {
            setDataNewUserStateReducer("permitsNewUser", target, value);
        }
    }

    const handleChecked = (checked, index) => {
        const options = [...values.optionsPermits]
        options[index].checked = checked

        if (options[index].label === "Planillas") {
            setState((prevState) => ({
                ...prevState,
                isChecking: checked
            }))
        }

        setDataNewUserStateReducer("permitsNewUser", "optionsPermitsNewUser", options);
        setValues({...values, optionsPermits: options});

    }

  return (
    <div style={{paddingLeft: "30px", paddingRight: "30px", height: "82%"}}>
        <div className='grid-x'>
            <label>Tipo de usuario</label>
            <Select
            style={{ width: "250px", marginLeft: "20px" }}
            //   disabled={isDisabled}
            value={values.typeUser}
            className="client-data__select"
            onChange={(e, event) => onChange(event, "typeUser")}
            onBlur={(value) => handleBlur("typeUser")(value)}
            status={errors.typeUser && touched.typeUser ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={rolesUser}
            placeholder="Selecciona el tipo de usuario"
          />
          <span className="grid-x data-user__errors">{touched?.typeUser ? errors?.typeUser : null}</span>
        </div>
        <div className='permits-user__list__content'>
            {values?.optionsPermits?.map((item, index) => {
                if (
                    (values.typeUser?.label !== "Administrador" && values.typeUser?.label !=="Jefe de operaciones") ||
                    (item.label !== "Pasajes" && item.label !== "Encomienda" && item.label !== "Giros")
                ){
                    return(
                        <div key={index} className='grid-x permits-user__list'>
                            <Switch 
                                checked={item.checked} 
                                onChange={(checked) => handleChecked(checked,index)} 
                                className='permits-user__switch'
                                size='small'
                            />
                            <div className='grid-y small-10' style={{marginLeft: "10px"}}>
                                <label className='permits-user__subtitle'>{item.label}</label>
                                <span className='permits-user__text'>{item.description}</span>

                                {item.label === "Planillas" && item.checked && isChecking && (
                                    <div className='grid-x permits-user__content-boarding-sheet'>
                                        <Switch
                                            checked={permitsNewUser.checkingYes}
                                            onChange={(value) => onChange(value, "checkingYes")}
                                            className='permits-user__switch'
                                            size='small'
                                        />

                                        <label className='permits-user__subtitle permits-user__subtitle--mod'>Gestión contable de planillas</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                }
                return null;
            })}
        </div>
    </div>
  )
}

const mapStateToProps = ({UserSettingsReducer}) => {
    const { permitsNewUser} = UserSettingsReducer;
    return{
        permitsNewUser
    };
  };
  
  const mapStateToPropsActions = {
    setDataNewUserStateReducer,
    setStateNewUserReducer
  };
  
  export default connect (mapStateToProps, mapStateToPropsActions)(PermitsUserComponent);
