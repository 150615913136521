//Actions
import { setDataDriverReducer, setStateDriverReducer } from '../../../../../storage/reducers/drivers/drivers.action'

//Assets
import { Assets } from '../../../../../assets';

//components
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';

// Constants
import AlertConst from '../../../../../core/constants/alerts.const';

//Libraries
import dayjs from "dayjs";
import { useFormik } from "formik";
import { connect } from "react-redux";
import React, { useEffect, useState } from 'react';
import { DatePicker, Input, Select } from "antd";

//Services
import { getAuthorizedCategoriesService } from '../../../../../services/drivers.services';

//Styles
import "./driver-information-from.component.scss";

//Utils
import { driverInformationFromSchema } from '../../../../../utils/form-validations/drivers.validators.utils';

const DriverInformationFromComponent = (props) => {

  const {
    //Variables
    isDisabled,
    driverFrom,
    //Actions,
    setDataDriverReducer,
    setStateDriverReducer
  } = props;

  const { values, errors, touched, handleBlur, setValues, setFieldValue } = useFormik({
    initialValues: {
      driverLicenseNumber: '',
      dateOfIssue: '',
      dateExpiration: '',
      nameOfEntity: '',
      authorizedCategory: undefined,
      drivingRestrictions: '',
      photoLicense: null,
    },
    validationSchema: driverInformationFromSchema,
    onSubmit: () => { },
  });

  const INITIAL_STATE = {
    selectImg: "",
    optionsCategoriesList: []
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { optionsCategoriesList } = state;

  useEffect(() => {
    setFieldsByReducer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverFrom]);

  useEffect(() => {
    categorySelector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStateDriverReducer('isError', Object.keys(errors).length !== 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const setFieldsByReducer = () => {
    const {
      driverLicenseNumber, dateOfIssue, dateExpiration,
      nameOfEntity, authorizedCategory, drivingRestrictions
    } = driverFrom;

    setValues ({
      driverLicenseNumber, dateOfIssue, dateExpiration, nameOfEntity,
      authorizedCategory, drivingRestrictions
    })
  }

  const categorySelector = async () => {
    try {
      const optionsCategoriesList = await getAuthorizedCategoriesService();
      setState({ ...state, optionsCategoriesList });
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const onChange = async (data, target) => {
    if (target === 'photoLicense') {
      const { target: { value: fileImg, files } } = data;
      const fileValue = files[0];
      if (fileValue?.type === "image/jpg" || fileValue?.type === "image/jpeg" || fileValue?.type === "image/png") {
        if (fileValue?.size <= 4194304) {
          setFieldValue(target, fileValue)
          setState({
            ...state,
            selectImg: fileImg
          })
        } else {
          setFieldValue(target, "")
          setState({
            ...state,
            selectImg: ""
          })
          ErrorToastComponent({
            title: 'Lo siento, has superado el límite de tamaño de la imagen. Por favor, selecciona una imagen más pequeña.',
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
          })
        }
      } else {
        setState({
          ...state,
          selectImg: ""
        })
        if (fileValue) {
          ErrorToastComponent({
            title: 'Lo siento, el tipo de archivo no es compatible',
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
          })
        }
      }
    } else {
      const value = data && data.target ? data.target.value || '' : data;
      const driverPersonalData = { ...values, [target]: value };
      setValues(driverPersonalData);
      setDataDriverReducer('driverFrom', target, value);
    }
  };

  return (
    <div className='grid-x driver-information-from__container'>
      <div className='small-6'>
        <div className="small-12 driver-information-from__content">
          <label className=" grid-x driver-information-from__label">
            Número de Licencia de conducción
            <div className="driver-information-from__label__icon" />
          </label>
          <Input
            disabled={isDisabled}
            value={values.driverLicenseNumber}
            onChange={(value) => onChange(value, 'driverLicenseNumber')}
            onBlur={(value) => handleBlur('driverLicenseNumber')(value)}
            status={errors.driverLicenseNumber && touched.driverLicenseNumber ? "error" : ""}
            className="driver-information-from__input__content"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={'Escribe el número del documento'}
          />
          <span className="grid-x driver-information-from__errors">{touched.driverLicenseNumber ? errors.driverLicenseNumber : null}</span>
        </div>
        <div className="small-12 driver-information-from__content">
          <label className=" grid-x driver-information-from__label">
            Fecha de caducación
            <div className="driver-information-from__label__icon" />
          </label>
          <DatePicker
            disabled={isDisabled}
            className="driver-information-from__date"
            value={values.dateExpiration ? dayjs(values.dateExpiration) : null}
            onChange={(date) => onChange(date, 'dateExpiration')}
            status={errors.dateExpiration && touched.dateExpiration ? 'error' : ''}
            onBlur={() => handleBlur('dateExpiration')}
            format={"DD/MM/YYYY"}
            allowClear={{
              clearIcon: (<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
            }}
            popupClassName="driver-information-from__date__picker"
            placeholder="DD/MM/AAAA"
            suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
            superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
            superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
            nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
            prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
          />
          <span className="grid-x driver-information-from__errors">{touched.dateExpiration ? errors.dateExpiration : null}</span>
        </div>
        <div className="small-12 driver-information-from__content">
          <label className=" grid-x driver-information-from__label">
            Categorias autorizadas
            <div className="driver-information-from__label__icon" />
          </label>
          <Select
            disabled={isDisabled}
            value={values.authorizedCategory}
            className="driver-information-from__select"
            onChange={(e, event) => onChange(event, 'authorizedCategory')}
            onBlur={(value) => handleBlur('authorizedCategory')(value)}
            status={errors.authorizedCategory && touched.authorizedCategory ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsCategoriesList}
            placeholder={'Selecciona la categoria'}
          />
          <span className="grid-x driver-information-from__errors">{touched.authorizedCategory ? errors.authorizedCategory : null}</span>
        </div>

        {/* TODO: 283580 implement license attachment */}

        {/* <div className="small-12 driver-information-from__content">
          <label className="grid-x driver-information-from__label">
            Adjuntar foto de licencia
            <div className="driver-information-from__label__icon" />
          </label>
          <div className='driver-information-from__upload-photo'>
            <img
              alt="icon_id_card"
              className={values.photoLicense ? 'driver-information-from__upload-photo__photo-uploaded' : ''}
              src={values.photoLicense ? ((typeof values.photoLicense === "string") ? values.photoLicense : URL.createObjectURL(values.photoLicense)) : Assets.SharedIcons.icon_id_card}
            />
            <div className='grid-y align-center-middle'>
              <input
                type="file"
                id="fileImg"
                value={selectImg}
                disabled={isDisabled}
                accept="image/jpg, image/jpeg, image/png"
                className='driver-information-from__hidden'
                onChange={(event) => onChange(event, 'photoLicense')}
              />
              <label className={`driver-information-from__upload-photo__charge-photo-label ${isDisabled  ? 'driver-information-from__disabled' : ''}`} htmlFor="fileImg">
                <span className="driver-information-from__upload-photo__charge-photo-label__text">Subir foto</span>
                <img
                  src={Assets.SharedIcons.icon_up_arrow_white}
                  alt="icon_down_arrow"
                />
              </label>
              <span className='driver-information-from__upload-photo__text'>Seleccione uno de los siguientes tipos de archivo: JPG , JPEG o PNG</span>
            </div>
          </div>
        </div> */}
      </div>
      <div className='small-6'>
        <div className="small-12 driver-information-from__content driver-information-from__content__right">
          <label className="grid-x driver-information-from__label driver-information-from__label--mod">
            Fecha de expedición
            <div className="driver-information-from__label__icon" />
          </label>
          <DatePicker
            disabled={isDisabled}
            className="driver-information-from__date"
            value={values.dateOfIssue ? dayjs(values.dateOfIssue) : null}
            onChange={(date) => onChange(date, 'dateOfIssue')}
            status={errors.dateOfIssue && touched.dateOfIssue ? 'error' : ''}
            onBlur={() => handleBlur('dateOfIssue')}
            format={"DD/MM/YYYY"}
            allowClear={{
              clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
            }}
            popupClassName="driver-information-from__date__picker"
            placeholder="DD/MM/AAAA"
            suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
            superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
            superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
            nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
            prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
          />
          <span className="grid-x driver-information-from__errors">{touched.dateOfIssue ? errors.dateOfIssue : null}</span>
        </div>
        <div className="small-12 driver-information-from__content driver-information-from__content__right">
          <label className="grid-x driver-information-from__label driver-information-from__label--mod">
            Organismo de transito
            <div className="driver-information-from__label__icon" />
          </label>
          <Input
            disabled={isDisabled}
            value={values.nameOfEntity}
            onChange={(value) => onChange(value, 'nameOfEntity')}
            onBlur={(value) => handleBlur('nameOfEntity')(value)}
            status={errors.nameOfEntity && touched.nameOfEntity ? "error" : ""}
            className="driver-information-from__input__content"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={"Escribe el nombre de la entidad"}
          />
          <span className="grid-x driver-information-from__errors">{touched.nameOfEntity ? errors.nameOfEntity : null}</span>
        </div>
        <div className="small-12 driver-information-from__content driver-information-from__content__right">
          <label className="grid-x driver-information-from__label driver-information-from__label--mod">
            Restricciones al conducir
          </label>
          <Input.TextArea
            rows={12}
            disabled={isDisabled}
            value={values.drivingRestrictions}
            placeholder={'Descripción del campo'}
            className='driver-information-from__text-area'
            onChange={(value) => onChange(value, 'drivingRestrictions')}
          />
          <span className="grid-x driver-information-from__errors">{touched.drivingRestrictions ? errors.drivingRestrictions : null}</span>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = ({ DriversReducer }) => {
  const { driverFrom, isDisabled } = DriversReducer;
  return {
    isDisabled,
    driverFrom
  };
};

const mapStateToPropsActions = {
  setDataDriverReducer,
  setStateDriverReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(DriverInformationFromComponent);