// Actions
import { setDataSalesStateReducer, setStateSaleReducer } from "../../../../../storage/reducers/sales/sales.action";

//Assets
import { Assets } from "../../../../../assets";

// components
import PassengerSeat from "./components/passenger-seat.component";

//Libraries
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

//Services
import { getPassageSalesService, setPassageSalesService } from "../../../../../services/sales.services";

//Styles
import "./passenger-data.component.scss";

const PassengerDataComponent = (props) => {
  const {
    // Actions
    setDataSalesStateReducer,
    setStateSaleReducer,
    // Variables
    innerWidth,
    assignedSeats,
    isDisabled,
    passageSales,
    nextButtonRef,
    ...dataClient
  } = props

  const [t] = useTranslation("translation")

  const INITIAL_STATE = {
    isTooltip: false
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { isTooltip } = state;


  const handleMouseEnter = () => {
    setState({...state, isTooltip: true });
  };
  
  const handleMouseLeave = () => {
    setState({...state, isTooltip: false});
  };

  const assignedToClient = () => {
    handleMouseLeave();
    setDataSalesStateReducer(
      "passageSales",
      'assignedSeats',
      assignedSeats.map(assignedSeat => ({
        ...assignedSeat,
        passenger: {
          ...dataClient
        }
      }))
    )
    setErrorIndexes([]);
    setStateSaleReducer('isError', false)
  };

  const [errorIndexes, setErrorIndexes] = useState([])
  
  const anErrorExists = (index, errors) => {
    const newErrorIndexes = (!!Object.keys(errors).length)
      ? [...errorIndexes, index]
      : errorIndexes.filter((i) => i !== index)
    setErrorIndexes(newErrorIndexes);
    setStateSaleReducer('isError', !!newErrorIndexes.length)
  };

  useEffect(() => {
    if (!isDisabled) {
      const passagesData = getPassageSalesService()
        const {
          id,documentType,documentNumber,name,
          lastName,telephone,telephoneCode,whatsapp,
          whatsappCode,departmentResidence,municipalityResidence,
          contactAddress,contactEmail,date,hours,
          destination,numberOfSeats,vehicle,seatSelected,
          assignedSeats,originalClientData
        } = passageSales
        const newDataPassages = {
          id,documentType,documentNumber,name,
          lastName,telephone,telephoneCode,whatsapp,
          whatsappCode,departmentResidence,municipalityResidence,
          contactAddress,contactEmail,date,hours,
          destination,numberOfSeats,vehicle,seatSelected,
          assignedSeats,originalClientData
        }
        const passagesSalesData = {...passagesData, ...newDataPassages}
        setPassageSalesService(passagesSalesData);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passageSales])

  return (
    <div>
      <div className="grid-x">
        <div className="small-10 medium-6">
          <label className="passenger-data__title">{t("sales.passages.passengerData.assignsPassengers")}</label>
          <span className="passenger-data__text">
            {t("sales.passages.passengerData.selectTickets")}
          </span>
        </div>
        <div className="small-2 medium-6 passenger-data__copy">
          <span className="passenger-data__text__subtitle">
            {t("sales.passages.passengerData.assignCustomer")}
          </span>
          {innerWidth < 639
            ? <Tooltip
              open={isTooltip}
              placement="bottomRight"
              title={() => (
                <div className='passenger-data__tooltip'>
                  <div className='passenger-data__tooltip__header grid-x'>
                    <img
                      className='passenger-data__tooltip__icon'
                      src={Assets.SalesIcons.icon_copy_disabled}
                      alt='icon_copy_disabled'
                    />
                    <span className='passenger-data__tooltip__title'>
                      {t("sales.passages.passengerData.tooltip.titleMobile")}
                    </span>
                  </div>
                  <div className='passenger-data__tooltip__body'>
                    <span className='passenger-data__tooltip__text'>
                    {t("sales.passages.passengerData.tooltip.description")}
                    </span>
                    <button
                      disabled = {isDisabled}
                      className='passenger-data__tooltip__button'
                      onClick={() => assignedToClient()}
                    >
                      <span>{t("sales.passages.passengerData.tooltip.button")}</span>
                    </button>
                  </div>
                </div>)}
              color='white'
              zIndex={1}
            >
              <button 
                disabled={isDisabled}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
              >
                <img src={isDisabled ? Assets.SalesIcons.icon_copy_disabled : Assets.SalesIcons.icon_copy} alt="icon_copy" />
              </button>
            </Tooltip>
            : 
            <>
              <button
                autoFocus
                disabled={isDisabled} 
                onClick={assignedToClient}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    assignedToClient();
                    setTimeout(() => {
                      nextButtonRef.current.focus();
                    }, 100);
                  }
                }}
              >
                <img src={isDisabled ? Assets.SalesIcons.icon_copy_disabled : Assets.SalesIcons.icon_copy} alt="icon_copy" />
              </button>
            </>
          }
        </div>
        {assignedSeats.map((seat, index) =>
          <PassengerSeat
            anErrorExists={(errors) => anErrorExists(index, errors)}
            seat={seat}
            key={index}
          />)}
      </div>
    </div>
  );
};

const mapStateToProps = ({ SalesReducer, AppReducer }) => {
  const {
    passageSales: {
      name,
      lastName,
      telephone,
      telephoneCode,
      assignedSeats,
    },
    passageSales,
    isDisabled
  } = SalesReducer;

  const {
    windowSize: { innerWidth },
  } = AppReducer;
  return {
    innerWidth,
    name,
    lastName,
    telephone,
    telephoneCode,
    assignedSeats,
    isDisabled,
    passageSales
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setStateSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(PassengerDataComponent);
