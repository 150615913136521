import SalesConst from "../../../core/constants/sales.const";
import * as salesTypes from "./sales.types";

const INITIAL_STATE = {
	isError: false,
	isDisabled: false,
	isShowDrawer: false,
	idInvoice: undefined,
	salesHistory: SalesConst.PASSAGES_SALES,
	isSocketConnected: false,
	countTabs: 0,
	isLoadingTable: false,
	passageSales: {
		id: '',
		documentType: undefined,
		documentNumber: '',
		name: '',
		lastName: '',
		telephone: '',
		telephoneCode: undefined,
		whatsapp: '',
		whatsappCode: undefined,
		departmentResidence: undefined,
		municipalityResidence: undefined,
		contactAddress: '',
		contactEmail: '',
		date: '',
		hours: null,
		destination: undefined,
		location: undefined,
		departmentResidenceRecipients: undefined,
		numberOfSeats: 1,
		vehicle: undefined,
		assignedSeats: [] ,
		seatSelected: undefined,
		typePayment: undefined,
		originalClientData: undefined,
		isElectronic: false,
		extraValue: 0,
	},
	turnSales:{
		idCustomer: '',
		documentTypeCustomer: undefined,
		documentNumberCustomer: '',
		nameCustomer: '',
		lastNameCustomer: '',
		telephoneCustomer: '',
		telephoneCodeCustomer: undefined,
		whatsappCustomer: '',
		whatsappCodeCustomer: undefined,
		departmentResidenceCustomer: undefined,
		municipalityResidenceCustomer: undefined,
		contactAddressCustomer: '',
		contactEmailCustomer: '',
		idRecipients: '',
		documentTypeRecipients: undefined,
		documentNumberRecipients: '',
		nameRecipients: '',
		lastNameRecipients: '',
		telephoneRecipients: '',
		whatsappRecipients: '',
		departmentResidenceRecipients: undefined,
		municipalityResidenceRecipients: undefined,
		contactAddressRecipients: '',
		contactEmailRecipients: '',
		dinner: 0,
      	dinnerIva: 0,
      	dinnerCost: 0,
      	dinnerTotal: 0,
		isCalculate: false,
		originalClientDataCustomer: undefined,
		originalClientDataRecipients: undefined,
		isElectronic: false
	},
	shippingsSales: {
		paymentMethod: undefined,
		typePackage: undefined,
		contentPackage: "",
		declaredValue: 0,
		domicile: false,
		serviceType: undefined,
		weightAmount:0,
		suggestedCost: 0,
		isCalculate: true,
		costOrder: 0,
		quantity: 0,
		cargoManifest: 0,
		insuranceCost: 0,
		domicileCost: 0,
		domicileDescription: "",
		totalOrder: 0,
		idCustomer: '',
		documentTypeCustomer: undefined,
      	documentNumberCustomer: '',
      	nameCustomer: '',
      	lastNameCustomer: '',
      	telephoneCustomer: '',
		telephoneCodeCustomer: '',
      	whatsappCustomer: '',
      	whatsappCodeCustomer: '',
      	departmentResidenceCustomer: undefined,
      	municipalityResidenceCustomer: undefined,
      	contactAddressCustomer: '',
      	contactEmailCustomer: '',
		idRecipients: '',
		documentTypeRecipients: undefined,
		documentNumberRecipients: '',
		nameRecipients: '',
		lastNameRecipients: '',
		telephoneRecipients: '',
		telephoneCodeRecipients: '',
		whatsappRecipients: '',
		whatsappCodeRecipients: '',
		departmentResidenceRecipients: undefined,
		municipalityResidenceRecipients: undefined,
		contactAddressRecipients: '',
		contactEmailRecipients: '',
		originalClientDataCustomer: undefined,
		originalClientDataRecipients: undefined,
		isElectronic: false,
		vehicleSelected: undefined
	},
};

const SalesReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case salesTypes.SET_SALES:
			return{
				...state,
				[action.payload.prop]: action.payload.value
			}

		case salesTypes.SET_DATA_SALES_STATE:
			return {
				...state,
				[action.payload.key]:{
					...state[action.payload.key],
					[action.payload.prop]: action.payload.value
				}
			};
		case salesTypes.SET_DATA_SALES:
			return {
				...state,
				[action.payload.key]:{
					...state[action.payload.key],
					...action.payload.value
				}
			};
		case salesTypes.RESET_DATA_SALES:
			return {
				...INITIAL_STATE,
				salesHistory: state.salesHistory,
			};
		case salesTypes.RESET_DATA_SALES_LOGOUT:
			return {
				...INITIAL_STATE
			};
		case salesTypes.SET_SEATS_TO_AVAILABLE:
			const arrayPassages = [...state?.passageSales?.assignedSeats]
			const arraySeats = [...state?.passageSales?.vehicle?.vehicle?.seatMap]
			const idSearch = action?.payload?.seats[0];
			const arrayFiltered = arrayPassages.filter(passage => passage.id !== idSearch);
			for (const seat of arraySeats) {
				if (Array.isArray(seat)) {
				  for (const seatItem of seat) {
					if (seatItem && seatItem.id === idSearch) {
					  seatItem.state = 0;
					}
				  }
				}
			}

			return {
				...state,
				passageSales: {
					...state.passageSales,
					assignedSeats: arrayFiltered,
					vehicle: {
						...state.passageSales.vehicle,
						vehicle: {
							...state.passageSales.vehicle.vehicle,
							seatMap: arraySeats
						}
					}
				}
			};
			
		case salesTypes.SET_SEATS_CHANGED_TO_SELECTED:
			const arrayPassagesCheck = [...state?.passageSales?.assignedSeats]
			const arrayPassagesAssigned = [...state?.passageSales?.vehicle?.vehicle?.seatMap]
			const idChange = action?.payload?.seats[0];
			const arrayFilter = arrayPassagesCheck.filter(passage => passage.id === idChange);
			
			if (arrayFilter.length === 0){
				for (const seat of arrayPassagesAssigned) {
					if (Array.isArray(seat)) {
					  for (const seatItem of seat) {
						if (seatItem && seatItem.id === idChange) {
						  seatItem.state = 2;
						}
					  }
					}
				}
			}
			return {
				...state,
				isSocketConnected: !state.isSocketConnected,
				passageSales: {
					...state.passageSales,
					vehicle: {
						...state.passageSales.vehicle,
						vehicle: {
							...state.passageSales.vehicle.vehicle,
							seatMap: arrayPassagesAssigned
						}
					}
				}
			};

		default:
			return state;
	}

};

export default SalesReducer;