//Assets
import { Assets } from "../../../../../assets";

//Libraries
import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';

//Styles
import { PdfDriversStyles } from "./pdf-drivers.component.styles";

const PdfDriversComponent = ({ info }) => {

    return (
        <Document>
            <Page size="A4" style={PdfDriversStyles.page}>
                <View style={PdfDriversStyles.section}>
                    <View style={PdfDriversStyles.content}>
                        <Image
                            src={Assets.SharedIcons.icon_logo_pdf}
                            style={PdfDriversStyles.logo}
                        />
                        <View style={PdfDriversStyles.content_info}>
                            <Text style={PdfDriversStyles.title}>Información del Conductor</Text>
                            <Image
                                resizeMode="contain"
                                src={Assets.SharedIcons.icon_user_pdf}
                                style={PdfDriversStyles.icon_profile}
                            />
                            <Text style={[PdfDriversStyles.subtitle, {marginTop: '40px'}]}>Nombre del conductor</Text>
                            <Text style={PdfDriversStyles.description}>
                                {info?.fullName}
                            </Text>
                            <Text style={PdfDriversStyles.subtitle}>Número de contacto</Text>
                            <Text style={PdfDriversStyles.description}>
                                {info?.phone}
                            </Text>
                            <Text style={PdfDriversStyles.subtitle}>Licencia de conducción</Text>
                            <Text style={PdfDriversStyles.description}>
                                {info?.driversLicense}
                            </Text>
                            <Text style={PdfDriversStyles.subtitle}>Nro. del vehículo</Text>
                            <Text style={PdfDriversStyles.description}>
                                {info?.vehicleNumber?.plate ? info.vehicleNumber.plate : 'Vehículo no vinculado'}
                            </Text>
                            <Text style={PdfDriversStyles.subtitle}>Estado</Text>
                            <Text style={PdfDriversStyles.description}>
                                {info?.state ? 'Disponible' : 'No disponible'}
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default PdfDriversComponent