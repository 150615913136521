import { StyleSheet } from "@react-pdf/renderer";

export const PdfPassagesStyles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
      width:"100%"
    },
    section: {
      width: "100%",
      display: "flex",
      alignItems: "center"
    },
    logo:{
        width: "152px",
        height: "32px",
        marginTop: "10px"
    },
    subTitle:{
        fontSize: "10px",
        fontWeight: "normal",
        width:"152px",
        paddingTop: "5px",
        textAlign:"center",
    },
    titleMunicipality:{
        fontSize: "10px",
        width:"220px",
        marginTop: "5px",
        marginBottom: "5px",
        textAlign: "center",
        fontFamily: "Helvetica-Bold",
    },
    titleNit:{
        fontSize: "10px",
        width:"220px",
        marginTop: "5px",
        textAlign: "center",
        fontFamily: "Helvetica-Bold",
    },
    content:{
        width: "100%",
        height: "100%"
    },
    box:{
        width: "100%",
        paddingHorizontal: "25px",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    boxSection:{
        width: "50%"
    },
    boxSections:{
        width: "52%"
    },
    boxSect:{
        width: "48%"
    },
    boxSec:{
        width: "12%"
    },
    title:{
        fontSize: "12px",
        marginTop: "3px",
        fontFamily: "Helvetica-Bold"
    },
    data:{
        fontSize: "12px",
    },
    boxUnit:{
        width: "100%",
    },
    line:{
        height: "1px",
        width:"100%",
        backgroundColor: "black"
    },
    lineSeparator:{
        marginTop: "8px"
    },
    titleSecondary:{
        fontSize: "10px"
    },
    dataRight:{
        textAlign: "right",
        marginTop: "3px"
    },
    dataLeft:{
        marginTop: "5px"
    },
    dataSeparator:{
        paddingRight: "10px"
    },
    dataUser:{
        flexWrap: "wrap",
        flexDirection: "row",
    },
    passenger:{
        width: "70%"
    },
    passengerText:{
        paddingLeft: "15px"
    },
    booth:{
        width: "30%",
        display: "flex",
        justifyContent: "space-between"
    },
    passengerLabel:{
        fontSize: "12px",
    },
    dataText:{
        fontSize: "12px",
    },
    dataContent:{
        marginTop: "5px"
    },
    lineBottom:{
        marginBottom: "5px"
    },
    footer:{
        marginTop: "8px",
        fontSize: "10px",
        textAlign: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingHorizontal: "0px",
        paddingBottom: "10px",
        marginBottom: "2px",
    },
    centerText:{
        fontSize: "10px"
    },
    containerCenterText:{
        marginTop: "10px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    bottom:{
        marginBottom: "25px",
    },

});