import { SharedIcons } from "./shared"
import { SalesIcons } from "./sales/index"
import { ShiftsIcons } from "./shifts/index"
import { VehiclesIcons } from "./vehicles/index"

export const Assets ={
    SharedIcons,
    SalesIcons,
    ShiftsIcons,
    VehiclesIcons
}