// Constants
import CoreAppEndPoints from "../services/constants/core.end-points";

// Models
import SocketConnection from "./models/socket.model";

export const ConnectionPassageSocket = () => {
    const url = `${CoreAppEndPoints.WEB_SOCKET_DOMAIN}/passage`;
    const socketConnection = new SocketConnection();

    const connectSocket = () => {
        socketConnection.connect(url);
    };

    const disconnectSocket = () => {
        socketConnection.disconnect();
    };

    return {
        connectSocket,
        disconnectSocket
    };
};

export const PrintConnectionSocket = () => {
    const url = `${CoreAppEndPoints.WEB_SOCKET_PRINT}`;
    const socketConnection = new SocketConnection();

    const connectSocket = () => {
        socketConnection.connect(url);
    };

    const disconnectSocket = () => {
        socketConnection.disconnect();
    };

    const printTicketSocket = (data) => {
        socketConnection.printTickets(data);
    };

    return {
        connectSocket,
        disconnectSocket,
        printTicketSocket
    };
};