import * as vehiclesTypes from './vehicles.types';

export const setStateVehicleReducer = (prop, value) => (dispatch) => {
    dispatch({ type: vehiclesTypes.SET_VEHICLES, payload: { prop, value } });
};

export const setDataVehiclesStateReducer = (key, prop, value) => (dispatch) => {
    dispatch({ type: vehiclesTypes.SET_DATA_VEHICLES_STATE, payload: { key, prop, value } });
};

export const setDataVehiclesKeyStateReducer = (prop, value) => (dispatch) => {
    dispatch({ type: vehiclesTypes.SET_DATA_VEHICLES_KEY_STATE, payload: { prop, value } });
};

export const resetDataVehicleReducer = () => (dispatch) => {
    dispatch({ type: vehiclesTypes.RESET_DATA_VEHICLES });
};