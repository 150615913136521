export const passageHeadersTable = {
    factura: "Factura Nro",
    name: "Nombre del Cliente",
    documento: "Nro. Documento",
    costo: "Costo de Venta",
    fecha: "Fecha de venta",
    pasajes: "# Pasajes",
    destino: "Destino",
    vendedor: "Vendedor",
}

export const assignmentHeadersTable = {
    guide: "Nro de Guía",
    clientName: "Cliente",
    document: "Nro. Documento",
    addressee: "Destinatario",
    documentAddressee: "Nro. Documento",
    costSales: "Costo de Venta",
    dateSale: "Fecha de venta",
    destination: "Destino",
    vehicle: "Nro. del vehículo",
}