//Actions
import { setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from '../../../../../storage/reducers/sales/sales.action';

//Assets
import { Assets } from '../../../../../assets';

//Constants
import AppConst from '../../../../../core/constants/app.const';

//Libraries
import { AutoComplete, Input, Select } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

//Services
import { getAllDocumentTypeService, getAllIndicativeNumberService, getDepartmentByNameService, getFilterUsersService, getMunicipalityBySiteService } from '../../../../../services/shared.services';

//Styles
import "./recipients-information.component.scss"

//Utils
import { shippingsRecipientsDataSchema } from '../../../../../utils/form-validations/sales.validators.utils';

const RecipientsInformationComponent = (props) => {

  const {
    // Actions
    setDataSalesStateReducer,
    setStateSaleReducer,
    setDataSaleReducer,
    // Variables
    isDisabled,
    nextButtonRef,
    ...recipientsData
  } = props

  const [t] = useTranslation("translation");

  useEffect(() => {
    setValues(recipientsData);
    dataSelectorsForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const INITIAL_STATE = {
    optionsTypeDocument: [],
    optionsDepartment: [],
    optionsMunicipality: [],
    optionsNumberList: [],
    optionsUserSearch: [],
    isDropdownOpen: false
  }

  const [state, setState] = useState(INITIAL_STATE)

  const { 
    optionsTypeDocument,
    optionsDepartment, 
    optionsMunicipality, 
    optionsNumberList,
    optionsUserSearch,
    isDropdownOpen
  } = state

  const documentTypeRecipientsRef = useRef();
  const documentNumberRecipientsRef = useRef();
  const nameRecipientsRef = useRef();
  const lastNameRecipientsRef = useRef();
  const telephoneCodeRecipientsRef = useRef();
  const telephoneRecipientsRef = useRef();
  const whatsAppRecipientsRef = useRef();
  const departmentResidenceRecipientsRef = useRef();
  const municipalityResidenceRecipientsRef = useRef();
  const defaultRef = useRef();  

  const inputRefs = [
    documentTypeRecipientsRef, 
    documentNumberRecipientsRef, 
    nameRecipientsRef, 
    lastNameRecipientsRef, 
    telephoneCodeRecipientsRef, 
    telephoneRecipientsRef, 
    whatsAppRecipientsRef, 
    departmentResidenceRecipientsRef, 
    municipalityResidenceRecipientsRef, 
    defaultRef
  ];

  const dataSelectorsForm = async () => {
    try {
      const results = await Promise.allSettled([
        getAllDocumentTypeService(),
        getDepartmentByNameService(),
        getAllIndicativeNumberService()
      ]);
  
      let documentTypeList = [];
      let departmentList = [];
      let indicativeNumberList = [];
      let municipalityList = [];
      let setDepartmentAndCodes = {};

      for (let index = 0; index < results.length; index++) {
        const elementResponse = results[index];
  
        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              documentTypeList = elementResponse.value.map((documentType) => ({
                label: documentType.name,
                value: documentType.id
              }));
              break;
            case 1:
              departmentList = [{
                label: elementResponse.value.name,
                value: elementResponse.value.id
              }];
                setDepartmentAndCodes.departmentResidenceRecipients = departmentList[0]
              break;
            case 2:
              indicativeNumberList = elementResponse.value.map((indicativeNumber) => ({
                label: indicativeNumber.number,
                value: indicativeNumber.id
              }));

              if (!recipientsData?.telephoneCodeRecipients || !recipientsData?.whatsappCodeRecipients) {
                setDepartmentAndCodes.telephoneCodeRecipients = !recipientsData?.telephoneCodeRecipients ? indicativeNumberList[0] : recipientsData.telephoneCodeRecipients
                setDepartmentAndCodes.whatsappCodeRecipients = !recipientsData?.whatsappCodeRecipients ? indicativeNumberList[0] : recipientsData.whatsappCodeRecipients
              };
              break;
            default:
              break;
          }
        }
      }
      if(Object.keys(setDepartmentAndCodes).length > 0) {
        municipalityList = await getAllMunicipality(setDepartmentAndCodes?.departmentResidenceRecipients?.value);
      }
      if (municipalityList && municipalityList.length > 0){
        if (!recipientsData?.municipalityResidenceRecipients) {
        setDepartmentAndCodes.municipalityResidenceRecipients = municipalityList[0]
        }
      }

      setState({
        ...state,
        optionsTypeDocument: documentTypeList,
        optionsDepartment: departmentList,
        optionsNumberList: indicativeNumberList,
        optionsMunicipality: municipalityList
      });

      setDataSaleReducer("shippingsSales",setDepartmentAndCodes)
      setValues({
        ...recipientsData,
        ...setDepartmentAndCodes
        
      })
      
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  const  {values, errors, touched, handleBlur, setValues, handleSubmit, setFieldTouched} = useFormik({
    initialValues: {
      idRecipients: '',
      documentTypeRecipients: undefined,
      documentNumberRecipients: '',
      nameRecipients: '',
      lastNameRecipients: '',
      telephoneRecipients: '',
      telephoneCodeRecipients: undefined,
      whatsappRecipients: '',
      whatsappCodeRecipients: undefined,
      departmentResidenceRecipients: undefined,
      municipalityResidenceRecipients: undefined,
      contactAddressRecipients: '',
      contactEmailRecipients: ''
    },
    validationSchema: shippingsRecipientsDataSchema,
    onSubmit: () => {},
  });
  
  useEffect(() => {
    setStateSaleReducer('isError', !!Object.keys(errors).length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const onChange = async (data, target) => {
    const value = data && data.target ? data.target.value || '' : data;
    const passengerData = { ...values, [target]: value };

    if (target === 'departmentResidenceRecipients') {
      passengerData.departmentResidenceRecipients = value;
      passengerData.municipalityResidenceRecipients = undefined;
      const municipalityList = await getAllMunicipality(value?.value);
      setState({
        ...state,
        optionsMunicipality: municipalityList
      })
    }
      
    setValues(passengerData)
    setDataSalesStateReducer("shippingsSales",target, value)
  };

  const getAllMunicipality = async (idDepartment) => {
    try {
      const res = await getMunicipalityBySiteService(idDepartment);
      return res.map((municipality) => ({ 
        label: municipality.name, 
        value: municipality.id 
      }));
      
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const setPhoneWhatsApp = (value) => {
    setValues(prevValues => ({
      ...prevValues,
      ...value
    }));
    setDataSaleReducer("shippingsSales",value)
  }


  const filterUser = async (data) => {
    try {
      const res = await getFilterUsersService(data);
      setState((prevState) => ({
        ...prevState,
        optionsUserSearch: res
      }));
    } catch (error) {
      // TODO: Implement error alert with error code
    }
  }; 

  const selectFilter = async (options) =>{
    setState({...state,optionsUserSearch: []})
    const {
      id,
      //TODO:The autocomplete of the residence department of the receiving client is removed so as not to affect the destination department
      // department,
      documentType,
      indicativeNumber,
      indicativeNumberWhatsapp,
      lastName,
      name,
      documentNumber,
      numberPhone,
      numberPhoneWhatsapp,
      contactAddress,
      contactEmail
    } = options;

    const telephoneCodeRecipients = indicativeNumber?.number && indicativeNumber?.id
      ? { ...indicativeNumber, label: indicativeNumber.number, value: indicativeNumber.id }
      : optionsNumberList[0];

    const whatsappCodeRecipients = indicativeNumberWhatsapp?.number && indicativeNumberWhatsapp?.id
      ? { ...indicativeNumberWhatsapp, label: indicativeNumberWhatsapp.number, value: indicativeNumberWhatsapp.id }
      : optionsNumberList[0];

    const documentTypeCodeRecipients = documentType
    ? { label: documentType.name, value: documentType.id }
    : undefined;

    //TODO:The autocomplete of the residence department of the receiving client is removed so as not to affect the destination department
    // const departmentResidenceRecipients = department
    // ? { label: department.name, value: department.id }
    // : undefined;

    // if(departmentResidenceRecipients){
    //   getAllMunicipality(departmentResidenceRecipients?.value)
    //   .then((res)=>{
    //     setState({
    //       ...state,
    //       optionsMunicipality: res
    //     })
    //   })
    //   .catch(() => {
    //     // TODO: Implement error alert with code error.
    //   })
    // }
    
    const value = {
      ...values,
      idRecipients: id,
      //TODO:The autocomplete of the residence department of the receiving client is removed so as not to affect the destination department
      // departmentResidenceRecipients,
      documentTypeRecipients: documentTypeCodeRecipients,
      telephoneCodeRecipients,
      whatsappCodeRecipients,
      lastNameRecipients : lastName,
      nameRecipients : name,
      documentNumberRecipients : documentNumber,
      telephoneRecipients: numberPhone,
      contactAddressRecipients : contactAddress,
      contactEmailRecipients : contactEmail,
      whatsappRecipients: numberPhoneWhatsapp
    }

    value.originalClientDataRecipients = {...value}

    try {
      await setValues(value);
      setDataSaleReducer("shippingsSales", value);
      handleSubmit();
    } catch {
      // TODO: Implement error alert with code error.
    }
  }
  useEffect(() => {
    if (values.whatsappRecipients) {
      setFieldTouched("whatsappRecipients", true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.whatsappRecipients])

  const handleSetPhoneWhatsApp = () => {
    setPhoneWhatsApp({
      whatsappRecipients: values.telephoneRecipients,
      whatsappCodeRecipients: values.telephoneCodeRecipients
    })
  };
      
  const onHandlerOnSearch = (e) => {
    const trimmedValue = e.trim();
    if (trimmedValue) {
      filterUser(trimmedValue);
    } else {
      setState((prevState) => ({
        ...prevState,
        optionsUserSearch: []
      }));
    }
  };

  const debouncedHandleSearch = useMemo(
    () => debounce(onHandlerOnSearch, 390),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="grid-x">
      <div className="medium-6 recipients-information__content flex-container align-middle">
        <label className="recipients-information__title">
          {t("sales.passages.clientData.enterData")}
        </label>
      </div>
      <div className="medium-6 recipients-information__content recipients-information__content__right recipients-information__hidden">
      <AutoComplete
          autoFocus
          options={optionsUserSearch}
          disabled={isDisabled}
          className="recipients-information__label--mod"
          onSelect={(value,options)=>selectFilter(options)}
          onSearch={ debouncedHandleSearch }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              const inputValue = e.target.value;
              const selectedOption = optionsUserSearch.find(option => option.documentNumber === inputValue);
              if (selectedOption) selectFilter(selectedOption);
              inputRefs[0].current.focus();
            }
          }}
        >
        <Input
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          className="recipients-information__input__content"
          placeholder={t("sales.passages.clientData.searchName")}
          prefix={
            <img
              className="recipients-information__input__search"
              src={Assets.SharedIcons.icon_search}
              alt="icon_search"
            />
          }
        />
        </AutoComplete>
      </div>
      <div className="small-12 medium-6 recipients-information__content">
        <label className="grid-x recipients-information__label">
          {t("sales.passages.clientData.typeDocument")}
          <div className="recipients-information__label__icon" />
        </label>
        <Select
          ref={inputRefs[0]}
          disabled={isDisabled}
          value={values.documentTypeRecipients}
          className="recipients-information__select"
          onChange={(e, event) => onChange(event, 'documentTypeRecipients')}
          onBlur={(value) => handleBlur('documentTypeRecipients')(value)}
          status={errors.documentTypeRecipients && touched.documentTypeRecipients ?"error":""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsTypeDocument}
          placeholder={t("sales.passages.clientData.selectTypeDocument")}
          onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
          onSelect={(value) => {
            if (isDropdownOpen) {
              inputRefs[1].current.focus();
            }
          }}
        />
        <span className="grid-x recipients-information__errors">{touched.documentTypeRecipients? errors.documentTypeRecipients : null}</span>
      </div>
      <div className="small-12 medium-6 recipients-information__content recipients-information__content__right">
        <label className=" grid-x recipients-information__label recipients-information__label--mod">
          {t("sales.passages.clientData.documentNumber")} 
          <div className="recipients-information__label__icon" />
        </label>
        <Input
          ref={inputRefs[1]}
          disabled={isDisabled}
          value={values.documentNumberRecipients}
          onChange={(value) => onChange(value, 'documentNumberRecipients')}
          onBlur={(value) => handleBlur('documentNumberRecipients')(value)}
          status={errors.documentNumberRecipients && touched.documentNumberRecipients ?"error" : ""}
          className="recipients-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.documentNumberPlaceholder")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[2].current.focus();
            }
          }}
        />
        <span className="grid-x recipients-information__errors">{touched.documentNumberRecipients? errors.documentNumberRecipients : null}</span>
      </div>
      <div className="small-12 medium-6 recipients-information__content">
        <label className=" grid-x recipients-information__label">
          {t("sales.turnPage.recipientData.nameRecipient")}
          <div className="recipients-information__label__icon" />
        </label>
        <Input
          ref={inputRefs[2]}
          disabled={isDisabled}
          value={values.nameRecipients}
          onChange={(value) => onChange(value, 'nameRecipients')}
          onBlur={(value) => handleBlur('nameRecipients')(value)}
          status={errors.nameRecipients && touched.nameRecipients ? "error" : ""}
          className="recipients-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterName")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[3].current.focus();
            }
          }}
        />
        <span className="grid-x recipients-information__errors">{touched.nameRecipients? errors.nameRecipients : null}</span>
      </div>
      <div className="small-12 medium-6 recipients-information__content recipients-information__content__right">
        <label className=" grid-x recipients-information__label recipients-information__label--mod">
          {t("sales.turnPage.recipientData.lastNameRecipient")}
          <div className="recipients-information__label__icon" />
        </label>
        <Input
          ref={inputRefs[3]}
          disabled={isDisabled}
          value={values.lastNameRecipients}
          onChange={(value) => onChange(value, 'lastNameRecipients')}
          onBlur={(value) => handleBlur('lastNameRecipients')(value)}
          status={errors.lastNameRecipients && touched.lastNameRecipients ? "error" : ""}
          className="recipients-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterLastName")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[4].current.focus();
            }
          }}
        />
        <span className="grid-x recipients-information__errors">{touched.lastNameRecipients? errors.lastNameRecipients : null}</span>
      </div>
      <div className="small-12 medium-6 recipients-information__content">
        <label className=" grid-x recipients-information__label">
          {t("sales.passages.clientData.contactTelephone")} 
          <div className="recipients-information__label__icon" />
        </label>
        <div className="grid-x recipients-information__label--mod justify-content-between">
          <Select
            ref={inputRefs[4]}
            disabled={isDisabled}
            value={values.telephoneCodeRecipients}
            onChange={(e,value) => onChange(value, 'telephoneCodeRecipients')}
            onBlur={(value) => handleBlur('telephoneCodeRecipients')(value)}
            status={errors.telephoneCodeRecipients && touched.telephoneCodeRecipients ? "error" : ""}
            className="recipients-information__select indicative-number"
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsNumberList}
            onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
            onSelect={(value) => {
              if (isDropdownOpen) {
                inputRefs[5].current.focus();
              }
            }}
          />
          <Input
            ref={inputRefs[5]}
            disabled={isDisabled}
            value={values.telephoneRecipients}
            onChange={(value) => onChange(value, 'telephoneRecipients')}
            onBlur={(value) => handleBlur('telephoneRecipients')(value)}
            status={errors.telephoneRecipients && touched.telephoneRecipients ? "error" : ""}
            className="recipients-information__input__content phone-number"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={t("sales.passages.clientData.enterDigits")} 
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSetPhoneWhatsApp();
                inputRefs[6].current.focus();
              }
            }}
          />
        </div>
        <span className="grid-x recipients-information__errors">{touched.telephoneRecipients? errors.telephoneRecipients : null}</span>
      </div>
      <div className="small-12 medium-6 recipients-information__content recipients-information__content__right">
        <div className="grid-x justify-content-between recipients-information__label--mod">
          <label className=" grid-x recipients-information__label">
            {t("sales.passages.clientData.whatsapp")}  
          </label>
        </div>
        <div className="grid-x recipients-information__label--mod justify-content-between">
          <Select
            disabled={isDisabled}
            value={values.whatsappCodeRecipients}
            onChange={(e,value) => onChange(value, 'whatsappCodeRecipients')}
            onBlur={(value) => handleBlur('whatsappCodeRecipients')(value)}
            className="recipients-information__select indicative-number"
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsNumberList}
            onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
          />
          <Input
            ref={inputRefs[6]}
            disabled={isDisabled}
            value={values.whatsappRecipients}
            onChange={(value) => onChange(value, 'whatsappRecipients')}
            onBlur={(value) => handleBlur('whatsappRecipients')(value)}
            className="recipients-information__input__content phone-number"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={t("sales.passages.clientData.digitsWhatsApp")}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                inputRefs[7].current.focus();
              }
            }}
          />
        </div>
      </div>
      <div className="small-12 medium-6 recipients-information__content">
        <label className=" grid-x recipients-information__label">
          {t("sales.turnPage.recipientData.destinationDepartment")}
          <div className="recipients-information__label__icon" />
        </label>
        <Select
          ref={inputRefs[7]}
          disabled={isDisabled}
          value={values.departmentResidenceRecipients}
          onChange={(e, event) => onChange(event, 'departmentResidenceRecipients')}
          className="recipients-information__select"
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsDepartment}
          placeholder={t("sales.passages.clientData.selectDepartment")}
          onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
          onSelect={(value) => {
            if (isDropdownOpen) {
              setTimeout(() => {
                inputRefs[8].current.focus();
              }, 100);
            }
          }}
        />
      </div>
      <div className="small-12 medium-6 recipients-information__content recipients-information__content__right">
        <label className="grid-x recipients-information__label recipients-information__label--mod">
          {t("sales.turnPage.recipientData.destinationMunicipality")}
          <div className="recipients-information__label__icon" />
        </label>
        <Select
          ref={inputRefs[8]}
          value={values.municipalityResidenceRecipients}
          onChange={(e, event) => onChange(event, 'municipalityResidenceRecipients')}
          className="recipients-information__select"
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          disabled={!values?.departmentResidenceRecipients || isDisabled}
          options={optionsMunicipality}
          placeholder={t("sales.passages.clientData.selectMunicipality")}
          onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
          onSelect={(value) => {
            setTimeout(() => {
              if (isDropdownOpen) {
                nextButtonRef.current.focus();
              }
            }, 100);
          }}
        />
      </div>
      <div className="small-12 medium-6 recipients-information__content">
        <label className=" recipients-information__label">
          {t("sales.turnPage.recipientData.destinationAddress")}
        </label>
        <Input
          disabled={isDisabled}
          value={values.contactAddressRecipients}
          onChange={(value) => onChange(value, 'contactAddressRecipients')}
          onBlur={(value) => handleBlur('contactAddressRecipients')(value)}
          status={errors.contactAddressRecipients && touched.contactAddressRecipients ? "error" : ""}
          className="recipients-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.writeContact")}
        />
        <span className="grid-x recipients-information__errors">{touched.contactAddressRecipients? errors.contactAddressRecipients : null}</span>
      </div>
      <div className="small-12 medium-6 recipients-information__content recipients-information__content__right">
        <label className="recipients-information__label recipients-information__label--mod">
          {t("sales.passages.clientData.contactEmail")}
        </label>
        <Input
          disabled={isDisabled}
          value={values.contactEmailRecipients}
          onChange={(value) => onChange(value, 'contactEmailRecipients')}
          onBlur={(value) => handleBlur('contactEmailRecipients')(value)}
          status={errors.contactEmailRecipients && touched.contactEmailRecipients ? "error" : ""}
          className="recipients-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterEmail")}
        />
        <span className="grid-x recipients-information__errors">{touched.contactEmailRecipients? errors.contactEmailRecipients : null}</span>
      </div>
    </div>
  );
}

const mapStateToProps = ({ SalesReducer }) => {
  const {
    shippingsSales: {
      idRecipients,
      documentTypeRecipients,
      documentNumberRecipients,
      nameRecipients,
      lastNameRecipients,
      telephoneRecipients,
      telephoneCodeRecipients,
      whatsappRecipients,
      whatsappCodeRecipients,
      departmentResidenceRecipients,
      municipalityResidenceRecipients,
      contactAddressRecipients,
      contactEmailRecipients,
    },
    isDisabled
  } = SalesReducer;
  return {
    idRecipients,
    documentTypeRecipients,
    documentNumberRecipients,
    nameRecipients,
    lastNameRecipients,
    telephoneRecipients,
    telephoneCodeRecipients,
    whatsappRecipients,
    whatsappCodeRecipients,
    departmentResidenceRecipients,
    municipalityResidenceRecipients,
    contactAddressRecipients,
    contactEmailRecipients,
    isDisabled
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setStateSaleReducer,
  setDataSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(RecipientsInformationComponent);