// Assets
import { Assets } from "../../../assets";

// Actions
import {
  setUserLoginAuthenticationReducer,
  setErrorLoginAuthenticationReducer,
  setProcessValidateSessionAuthenticationReducer
} from "./../../../storage/reducers/authentication/authentication.actions";

// Constants
import AlertConst from "../../../core/constants/alerts.const";

// Libraries
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Input } from 'antd';

// Models
import CredentialModel from "../../../models/credential/model/credential.model";

// Services
import { loginService, setTokenService, setUserRolesService, setUserService } from "./../../../services/authentication.services";

// Styles
import "./login.page.scss";

// Utils
import ErrorToastComponent from "../../../shared/components/toast/error-toast/error-toast.component";
import { validateLoginSchema } from "../../../utils/form-validations/authentications.validators.utils";

const LoginPage = (props) => {

  const {
    setUserLoginAuthenticationReducer,
    setErrorLoginAuthenticationReducer,
    setProcessValidateSessionAuthenticationReducer
  } = props;

  const navigate = useNavigate()

  const [t] = useTranslation("translation")

  const { handleSubmit, handleChange, values, errors, touched, setTouched } = useFormik({
    initialValues: {
      user: "",
      password: "",
    },
    validationSchema: validateLoginSchema,
    onSubmit: (values) => {
      loginService(new CredentialModel(values.user, values.password))
        .then((res) => {
          const { authentication, user, role } = res
          const modules = user.modules
          if (modules && modules.length > 0) {
            setUserService(user)
            setTokenService(authentication?.token);
            setUserRolesService(role);
            setUserLoginAuthenticationReducer(res);

            navigate(`/${modules[0].path}`, { replace: true })
          } else {
            ErrorToastComponent({
              title: `El usuario ${user.name} no tiene módulos asignados.`,
              position: AlertConst.TOP_END_POSITION_TEXT,
              timer: 1500
            })
          }
        })
        .catch((err) => {
          ErrorToastComponent({
            title: `${err.code} ${err.message}`,
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
          })
          setErrorLoginAuthenticationReducer(err);
        });
    },
  });

  useEffect(() => {
    setProcessValidateSessionAuthenticationReducer(false, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form className="grid-y login-page__container" onSubmit={handleSubmit}>
      <div className="grid-x align-center-middle small-4">
        <img
          src={Assets.SharedIcons.icon_logo_vertical}
          alt="icon_logo_vertical"
        />
      </div>
      <div className="grid-y small-8 login-page__form-content">
        <span className="login-page__form-content__title">
          {t("authentication.login.mainTitle")}
        </span>
        <div className="grid-y small-8 justify-content-around">
          <div>
            <div className="grid-y login-page__form-content__field ">
              <label className="grid-x login-page__form-content__field__title">
                {t("authentication.login.user")} <div className="login-page__form-content__field__title__icon" />
              </label>
              <Input
                value={values.user}
                allowClear={{
                  clearIcon: <img
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                }}
                type="none"
                status={touched.user && errors.user ? "error" : ""}
                onFocus={() => setTouched({ ...touched, user: true })}
                onChange={(value) => handleChange('user')(value)}
                placeholder={t("authentication.login.user")}
                className={`login-page__form-content__field__input ${!!values.user.length ? 'login-page__form-content__field__input--mod' : ''}`}
              />
            </div>
            <div className="grid-y login-page__form-content__field ">
              <label className=" grid-x login-page__form-content__field__title">
                {t("authentication.login.password")} <div className="login-page__form-content__field__title__icon" />
              </label>
              <Input.Password
                value={values.password}
                allowClear={{
                  clearIcon: <img
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                }}
                iconRender={() => null}
                type="none"
                status={touched.password && errors.password ? "error" : ""}
                onFocus={() => setTouched({ ...touched, password: true })}
                onChange={(value) => handleChange('password')(value)}
                placeholder={t("authentication.login.password")}
                className={`login-page__form-content__field__input ${!!values.password.length ? 'login-page__form-content__field__input--mod' : ''}`}
              />
            </div>
          </div>
          <div className="grid-x">
            <button
              type="submit"
              className="login-page__button"
            >
              {t("authentication.login.loginButton")}
            </button>
          </div>
        </div>
        <div className="grid-y small-2 align-middle justify-content-evenly">
          <div className="grid-x align-center-middle login-page__recovery-password">
            <span className=" login-page__recovery-password__text">
              {t("authentication.login.forgotPassword")}
            </span>
            <Link className="login-page__recovery-password__link" to="/passwordRecovery">
              {t("authentication.login.clickHere")}
            </Link>
          </div>
          <span className="login-page__recovery-password__link">
            {t("authentication.login.help")}
          </span>

        </div>
      </div>
    </form>
  );
};

const mapStateToPropsActions = {
  setUserLoginAuthenticationReducer,
  setErrorLoginAuthenticationReducer,
  setProcessValidateSessionAuthenticationReducer
};

export default connect(null, mapStateToPropsActions)(LoginPage);
