import icon_review from "./icon_review.svg"
import icon_overhaul from "./icon_overhaul.svg"
import icon_happy_face_green from "./icon_happy_face_green.svg"
import icon_sad_face_black from "./icon_sad_face_black.svg"
import icon_vehicle_button from "./icon_vehicle_button.svg"

export const VehiclesIcons = {
    icon_review,
    icon_overhaul,
    icon_happy_face_green,
    icon_sad_face_black,
    icon_vehicle_button
}