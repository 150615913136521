class SeatDTO {

    constructor(id, row, column, price, state, name) {
        this.id = id;
        this.row = row;
        this.column = column;
        this.price = price;
        this.state = state;
        this.name = name;
    }
}

export default SeatDTO