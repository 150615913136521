//Actions
import { setDataVehiclesKeyStateReducer, setStateDriverReducer } from "../../../storage/reducers/drivers/drivers.action";

//Assets
import { Assets } from "../../../assets";

//Libraries
import { connect } from "react-redux";
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

//Components
import DriverInformationComponent from './components/driver-information/driver-information.component';
import DriverFormComponent from "./components/driver-form/driver-form.component";

//Service
import { getDriverByIdService } from "../../../services/drivers.services";

//Styles
import "./create-drivers.page.scss";

const CreateDriversPage = (props) => {

    const {
        //Actions
        setStateDriverReducer,
        setDataVehiclesKeyStateReducer
    } = props;

    let params = useLocation();

    useEffect(() => {
        if (params?.state?.typeAction) {
            (params?.state?.typeAction === 'VIEW_INFO_DRIVER') && setStateDriverReducer('isDisabled', true);
            getDriverById();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDriverById = async () => {
        try {
            const driverUser = await getDriverByIdService(params?.state?.idDriver);
            setDataVehiclesKeyStateReducer({
                driverFrom: {
                    name:driverUser?.name,
                    lastName:driverUser?.lastName,
                    documentType:driverUser?.documentType || undefined,
                    documentNumber:driverUser?.numberDocument,
                    date:driverUser?.dateOfBirth,
                    countryOfBirth:driverUser?.countryBirth || undefined,
                    departmentOfBirth:driverUser?.departmentBirth || undefined,
                    municipalityOfBirth:driverUser?.municipalityBirth || undefined,
                    bloodType:driverUser?.bloodType || undefined,
                    telephoneCode:driverUser?.indicativePhone || undefined,
                    telephone:driverUser?.numberPhone,
                    contactEmail:driverUser?.email || '',
                    departmentResidence:driverUser?.departmentResidence || undefined,
                    municipalityResidence:driverUser?.municipalityResidence || undefined,
                    contactAddress:driverUser?.address || '',
                    driverLicenseNumber:driverUser?.licenseNumber,
                    dateOfIssue:driverUser?.dateOfLicenseIssuance,
                    dateExpiration:driverUser?.dateExpirationLicense,
                    nameOfEntity:driverUser?.transitAgency,
                    authorizedCategory:driverUser?.licenseCategory || undefined,
                    drivingRestrictions:driverUser?.restriction,
                    state:driverUser?.state,
                    vehicle: {
                        id:driverUser?.vehicle?.id,
                        vehicleType:driverUser?.vehicle?.typeVehicle,
                        plate:driverUser?.vehicle?.plate,
                        internalNumber:driverUser?.vehicle?.internalNumber,
                        mileage:driverUser?.vehicle?.mileage,
                        vehicleCode:driverUser?.vehicle?.code,
                        vehicleMake:driverUser?.vehicle?.mark,
                        vehicleStatus:driverUser?.vehicle?.isMaintenance,
                        numberOfSeats:driverUser?.vehicle?.numberSeats,
                        fuelType:driverUser?.vehicle?.typeFuel,
                        bodywork:driverUser?.vehicle?.typeBodywork
                    }
                },
                originData: {
                    name:driverUser?.name,
                    lastName:driverUser?.lastName,
                    documentType:driverUser?.documentType || undefined,
                    documentNumber:driverUser?.numberDocument,
                    date:driverUser?.dateOfBirth,
                    countryOfBirth:driverUser?.countryBirth || undefined,
                    departmentOfBirth:driverUser?.departmentBirth || undefined,
                    municipalityOfBirth:driverUser?.municipalityBirth || undefined,
                    bloodType:driverUser?.bloodType || undefined,
                    telephoneCode:driverUser?.indicativePhone || undefined,
                    telephone:driverUser?.numberPhone,
                    contactEmail:driverUser?.email || '',
                    departmentResidence:driverUser?.departmentResidence || undefined,
                    municipalityResidence:driverUser?.municipalityResidence || undefined,
                    contactAddress:driverUser?.address || '',
                    driverLicenseNumber:driverUser?.licenseNumber,
                    dateOfIssue:driverUser?.dateOfLicenseIssuance,
                    dateExpiration:driverUser?.dateExpirationLicense,
                    nameOfEntity:driverUser?.transitAgency,
                    authorizedCategory:driverUser?.licenseCategory || undefined,
                    drivingRestrictions:driverUser?.restriction,
                    state:driverUser?.state,
                    vehicle: {
                        id:driverUser?.vehicle?.id,
                        vehicleType:driverUser?.vehicle?.typeVehicle,
                        plate:driverUser?.vehicle?.plate,
                        internalNumber:driverUser?.vehicle?.internalNumber,
                        mileage:driverUser?.vehicle?.mileage,
                        vehicleCode:driverUser?.vehicle?.code,
                        vehicleMake:driverUser?.vehicle?.mark,
                        vehicleStatus:driverUser?.vehicle?.isMaintenance,
                        numberOfSeats:driverUser?.vehicle?.numberSeats,
                        fuelType:driverUser?.vehicle?.typeFuel,
                        bodywork:driverUser?.vehicle?.typeBodywork
                    }
                },
            })
            setStateDriverReducer('driverUserRedux', driverUser);

            if(driverUser?.vehicle?.idDriverVehicle){
                setStateDriverReducer('isUnlinkVehicle', false);
                setStateDriverReducer('idDriverVehicleSelected', driverUser?.vehicle?.idDriverVehicle);
            }else{
                setStateDriverReducer("isUnlinkVehicle", true)
            }
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    return (
        <>
            <div className="create-drivers__content">
                <span className="create-drivers__subtitle">Conductores</span>
                <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
                <span className="create-drivers__subtitle">
                    Datos del conductor
                </span>
            </div>
            <div className='grid-x create-drivers__container'>
                <div className='grid-x create-drivers__container__drivers-data'>
                    <DriverInformationComponent />
                </div>
                <div className='grid-x create-drivers__container__drivers-form'>
                    <DriverFormComponent />
                </div>
            </div>
        </>
    )
};

const mapStateToPropsActions = {
    setStateDriverReducer,
    setDataVehiclesKeyStateReducer
};

export default connect(null, mapStateToPropsActions)(CreateDriversPage);