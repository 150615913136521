//Assets
import { Assets } from '../../../assets'

//Components
// import PdfShippingsComponent from '../shippings/components/pdf-shippings/pdf-shippings.component'
// import PdfTurnComponent from '../turn/components/pdf-turn/pdf-turn.component'

//Components - Shared
import ModalMainComponent from "../../../shared/components/modal/modal-main/modal-main.component"
import SuccessToastComponent from '../../../shared/components/toast/success-toast/success-toast.component'

//Const
import SalesConst from '../../../core/constants/sales.const'
import AlertConst from '../../../core/constants/alerts.const'

// Connect WebSocket
import { PrintConnectionSocket } from '../../../socket'

//Libraries
import { useFormik } from 'formik'
import { Select, Steps } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
// import { pdf } from '@react-pdf/renderer'
import { connect } from 'react-redux'
import ModalServiceDeliveryComponent from '../sales/components/modal-service-delivery/modal-service-delivery.component'

// Services
import { getShippingDetailsByFilterParamService } from '../../../services/shippings.services'
import { getInvoiceShippingService, getInvoiceTurnPreviewService, getInvoiceTurnService } from '../../../services/sales.services'

//Styles
import "./summary.page.scss"

//Utils
import { saleTableSchema } from '../../../utils/form-validations/sales.validators.utils'

const SummaryPage = (props) => {

  const { 
    salesHistory,
    countTabs
   } = props;

    const [t] = useTranslation("translation")
    let history = useNavigate();
    let { state: dataRoute } = useLocation();

    const INITIAL_STATE = {
      dataStatusMod : [],
      isOpenModal: false,
      detailsInvoice: null,
      isModalPrint: false,
      typePrint: 0,
      isModalOpen: false,
      isDeliveryButton: false
    }
    const [state, setState] = useState(INITIAL_STATE)
    const { 
      dataStatusMod, isOpenModal, detailsInvoice,
      isModalPrint, typePrint,isModalOpen, isDeliveryButton
    } = state

    const {values, errors, setFieldValue, touched, handleBlur} = useFormik({
      initialValues: {
        numberDocumentDelivered: '',
        nameDelivered: '',
        lastNameDelivered: '',
      },
      validationSchema: saleTableSchema
    })

    useEffect(() => {
      getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async() =>{

      switch (salesHistory) {
        case SalesConst.ASSIGNMENTS_SALES:
          try {
            const resAssignment = await getShippingDetailsByFilterParamService(dataRoute?.id)
            let dataNew = resAssignment?.dataStatus

            setState({
              ...state,
              dataStatusMod: dataNew,
              detailsInvoice: resAssignment
            })

          } catch (error) {
            // TODO: Implement error alert with code error.
            handleClose()
          }
          break;

        case SalesConst.TURNS_SALES:
          try {
            const resTurn = await getInvoiceTurnPreviewService(dataRoute?.id);
            let dataSteps = resTurn?.dataStatus

            setState({
              ...state,
              detailsInvoice: resTurn,
              dataStatusMod: dataSteps
            })
          } catch (error) {
            // TODO: Implement error alert with code error.
            handleClose()
          }
          break;
      
        default:
          handleClose()
          break;
      }
    }

    //TODO: Implement network sharing
    // const handleShare = () =>{
    //     setState({
    //         ...state,
    //         isOpenModal: true
    //     })
    // }

    const handleCloseModal = () =>{
        setState({
            ...state,
            isOpenModal: false
        })
    }

    const handleClose = () =>{
      history("/sales", { replace: true })
    }

    const handleShareModal = () =>{
      handleCloseModal()
      SuccessToastComponent({
        html: 
        `<span>
          ${salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.alertShippings") : t("sales.summary.alertTurn")}
        </span>`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 5000
      })
    }

  const pdfDocumentPrint = async (size, isDelivery = false) => {
    try {
      const { connectSocket, printTicketSocket } = PrintConnectionSocket();
      connectSocket();
      const typeSalesComponent = {
        [SalesConst.ASSIGNMENTS_SALES]: 'printAssignment',
        [SalesConst.TURNS_SALES]: 'printTurns',
      }
      const dataInvoice = await servicesTypeSalesServices(dataRoute?.id);
      printTicketSocket({typeTicket: typeSalesComponent[salesHistory], data: dataInvoice});
      // TODO: socket process. CODE 01
      // const typeSalesComponent = {
      //   [SalesConst.ASSIGNMENTS_SALES]: size ? <PdfShippingsComponent dataInvoice={dataInvoice} size isDelivery={isDelivery} /> : <PdfShippingsComponent dataInvoice={dataInvoice} isDelivery={isDelivery} />,
      //   [SalesConst.TURNS_SALES]: size ? <PdfTurnComponent dataInvoice={dataInvoice} size isDelivery={isDelivery} /> : <PdfTurnComponent dataInvoice={dataInvoice} isDelivery={isDelivery} />,
      // }
      // const pdfBlob = pdf(typeSalesComponent[salesHistory]);
      // pdfBlob.toBlob().then((blob) => {
      //   printPdf(URL.createObjectURL(blob))
      // });
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const servicesTypeSalesServices = async(idInvoice) => {
    switch (salesHistory) {
      case SalesConst.ASSIGNMENTS_SALES:
        try {
          return await getInvoiceShippingService(idInvoice);
        } catch (error) {
          // TODO: Implement error alert with code error.
        }
        break;

      case SalesConst.TURNS_SALES:
        try {
          return await getInvoiceTurnService(idInvoice);
        } catch (error) {
          // TODO: Implement error alert with code error.
        }
        break;
      default:
        break;
    }
  }
  // TODO: socket process. CODE 01
  // const printPdf = async(url) => {
  //   window.open(url)
  // };

  const onCloseModalPrint = () =>{
    setState((prevState)=>({
      ...prevState,
      isModalPrint: false,
      typePrint: 0
    }))
  }

  const onCloseModal = () => {
    setState(prevState => ({
      ...prevState,
      isModalOpen: false 
    }));
  };

  return (
    <>
      <div className="summary__content">
        <span className="summary__subtitle">
          {salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.historyOrder") : t("sales.summary.historyTransfers")}
        </span>
        <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
        <span className="summary__subtitle">
          {`${salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.guide") : t("sales.summary.invoice")} ${detailsInvoice?.dataSale?.num}`}
        </span>
      </div>
      <div className="summary__container">
        <div className="grid-x align-middle justify-content-between summary__container__header">
          <label className="summary__label ">{t("sales.summary.summarySale")}</label>
          <button onClick={()=> handleClose()}>
            <span className="summary__label">{t("sales.summary.cancel")}</span>
            <img src={Assets.SharedIcons.icon_square_close} alt="icon_close" />
          </button>
        </div>
        <div className="grid-x summary__box">
          <>
            <div className="grid-x small-12 justify-content-between summary__text__content align-middle">
                <label className="summary__title">
                  {`${salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.guide") : t("sales.summary.invoice")} ${detailsInvoice?.dataSale?.num}`}
                </label>
                <button 
                  className='grid-x align-center-middle'
                  // TODO: socket process. CODE 01
                  // onClick={()=> setState((prevState)=>({ ...prevState, isModalPrint: true }))}
                  onClick={() => pdfDocumentPrint()}
                >
                  <span className="summary__subtitle">{t("sales.summary.printInvoice")}</span>
                  <img
                    src={Assets.SalesIcons.icon_print_ticket_active}
                    alt="icon_print_ticket_active"
                    height={27}
                    width={27}
                  />
                </button>
            </div>
            <div className="grid-x small-12">
                <div className="grid-x small-7 summary__text__content">
                  <label className="small-3 summary__label">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.category") : t("sales.summary.amountDrawing")}
                  </label>
                  <span className="summary__text">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? detailsInvoice?.dataSale?.category : `$ ${detailsInvoice?.dataSale?.amountDrawing?.toLocaleString()}`}
                  </span>
                </div>
                <div className="flex-container justify-content-between small-5 summary__text__content">
                  <label className="summary__label">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.costOrder") : t("sales.summary.costDraft")}
                  </label>
                  <span className="summary__text">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? `$ ${detailsInvoice?.dataSale?.costOrder?.toLocaleString()}` : `$ ${detailsInvoice?.dataSale?.costDraft?.toLocaleString()}`}
                  </span>
                </div>
            </div>
            <div className="grid-x small-12">
                <div className="grid-x small-7 summary__text__content">
                  <label className="summary__label small-3">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.content") : t("sales.summary.costDraft")}
                  </label>
                  <span className="summary__text">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? detailsInvoice?.dataSale?.content : `$ ${detailsInvoice?.dataSale?.costDraft?.toLocaleString()}`}
                  </span>
                </div>
                <div className="flex-container justify-content-between small-5 summary__text__content">
                  <label className="summary__label">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.costInsurance") : t("sales.summary.costOfTheTurnaround")}
                  </label>
                  <span className="summary__text">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? `$ ${detailsInvoice?.dataSale?.costInsurance?.toLocaleString()}` : `$ ${detailsInvoice?.dataSale?.totalCost?.toLocaleString()}`}
                  </span>
                </div>
            </div>
            <div className="grid-x small-12">
                <div className="grid-x small-7 summary__text__content">
                  <label className="summary__label small-3">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.declaredValue") : "IVA 19%"}
                  </label>
                  <span className="summary__text">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? `$ ${detailsInvoice?.dataSale?.declaredValue?.toLocaleString()}` : `$ ${detailsInvoice?.dataSale?.iva?.toLocaleString()}`}
                  </span>
                </div>
                <div className="flex-container justify-content-between small-5 summary__text__content">
                  <label className="summary__label">{salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.totalCost") : t("sales.summary.amountReceived")}</label>
                  <span className="summary__text">
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES ? `$ ${detailsInvoice?.dataSale?.costTotal?.toLocaleString()}` : `$ ${detailsInvoice?.dataSale?.amountReceived?.toLocaleString()}`}
                  </span>
                </div>
            </div>
            { salesHistory === SalesConst.ASSIGNMENTS_SALES &&
              <>
                <div className="grid-x small-12">
                  <div className="grid-x small-7 summary__text__content">
                    <label className="summary__label small-3">{t("sales.summary.delivery")}</label>
                    <span className="summary__text">
                      {detailsInvoice?.dataSale?.delivery}
                    </span>
                  </div>
                  <div className="flex-container justify-content-between small-5 summary__text__content">
                    <label className="summary__label">Porte</label>
                    <span className="summary__text">
                      ${detailsInvoice?.dataSale?.porteService?.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="grid-x small-12">
                  <div className="grid-x small-7 summary__text__content">
                  <label className="summary__label small-3">{salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.destinationPlace") : t("sales.summary.amountReceived")}</label>
                    <span className="summary__text">
                      {salesHistory === SalesConst.ASSIGNMENTS_SALES ? detailsInvoice?.dataRecipient?.residentSiteArrive : null}
                    </span>
                  </div>
                  <div className="flex-container justify-content-between small-5 summary__text__content">
                    <label className="summary__label">Manifiesto de carga</label>
                    <span className="summary__text">
                      ${detailsInvoice?.dataSale?.manifestOfFreight?.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div className="grid-x small-12">
                  {detailsInvoice?.dataSale?.delivery === "Domicilio" &&
                    <div className="grid-x small-7 summary__text__content">
                      <label className="summary__label--mod small-3">Descripción domicilio</label>
                      <span className="summary__text" style={{ whiteSpace: "normal"}}>
                        {salesHistory === SalesConst.ASSIGNMENTS_SALES && detailsInvoice?.dataSale?.description}
                      </span>
                    </div>
                  }
                  {detailsInvoice?.dataSale?.delivery === "Domicilio" &&
                    <div className="flex-container justify-content-between small-5 summary__text__content">
                      <label className="summary__label">Costo del domicilio</label>
                      <span className="summary__text">
                        {salesHistory === SalesConst.ASSIGNMENTS_SALES ? `$ ${detailsInvoice?.dataSale?.costDelivery?.toLocaleString()}` : `$ ${detailsInvoice?.dataSale?.amountReceived?.toLocaleString()}`}
                      </span>
                    </div>
                  }
                </div> 
              </>
            }
          </>
        </div>
        <div className="grid-x">
          <div className="small-6">
            <div className="summary__box summary__box__left">
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">{t("sales.summary.client")}</label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataClient?.clientName}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">
                    {t("sales.summary.typeDocument")}
                </label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataClient?.documentType}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">{t("sales.summary.number")}</label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataClient?.identificationNumber}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">{t("sales.summary.telephone")}</label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataClient?.phone}
                </span>
              </div>
              { salesHistory !== SalesConst.ASSIGNMENTS_SALES &&
                <div className="grid-x small-12 summary__text__content">
                  <label className="small-6 summary__label">Whatsapp</label>
                  <span className="small-6 summary__text">
                    {detailsInvoice?.dataClient?.phoneWhatsapp}
                  </span>
                </div>
              }
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">
                    {t("sales.summary.residenceAddress")}
                </label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataClient?.address}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content--mod">
                <label className="small-6 summary__label">
                    {t("sales.summary.municipalityResidence")}
                </label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataClient?.residentMunicipality}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">{t("sales.summary.recipient")}</label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataRecipient?.clientName}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">
                    {t("sales.summary.typeDocument")}
                </label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataRecipient?.documentType}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">{t("sales.summary.number")}</label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataRecipient?.identificationNumber}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">{t("sales.summary.telephone")}</label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataRecipient?.phone}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">
                    {t("sales.summary.residenceAddress")}
                </label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataRecipient?.address}
                </span>
              </div>
              <div className="grid-x small-12 summary__text__content">
                <label className="small-6 summary__label">
                    {t("sales.summary.municipalityResidence")}
                </label>
                <span className="small-6 summary__text">
                  {detailsInvoice?.dataRecipient?.residentMunicipality}
                </span>
              </div>
            </div>
          </div>
          <div className="small-6">
            <div className="summary__box summary__box__right">
              <div className="grid-x justify-content-between summary__text__content--mod">
                <label className="summary__label">
                    { salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.orderStatus") : "Estado del Giro" }
                </label>
                {/* TODO: Implement network sharing */}
                {/* <button onClick={()=> handleShare()} className="grid-x align-center-middle">
                  <label className="summary__label">{t("sales.summary.share")}</label>
                  <img src={Assets.SharedIcons.icon_share} alt="icon_share" />
                </button> */}
                { countTabs === 2 && !isDeliveryButton && (
                  <button 
                    onClick={() => {    
                      setState((prevState)=>({
                        ...prevState,
                        isModalOpen: true,
                      }))
                    }} 
                    className="grid-x align-center-middle"
                  >
                    <label className="summary__label--mod2">{salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.deliverPackage") : t("sales.summary.deliverMoney")}</label>
                    <img 
                      src={Assets.SharedIcons.icon_add_hover} 
                      alt="icon_add_hover" 
                      width={20}
                      height={20}
                    />
                  </button>
                 )}
              </div>
              <div className='grid-x align-center'>
                <div className='small-2 grid-y justify-content-end'>
                    <img
                        src={salesHistory === SalesConst.ASSIGNMENTS_SALES ? Assets.SalesIcons.icon_orders : Assets.SalesIcons.icon_turns}
                        alt='icon_orders'
                        height={50}
                        width={50}
                    />
                </div>
                <Steps
                    direction="vertical"
                    current={2}
                    className="order-guide__steps small-10"
                    items={dataStatusMod?.map((data) => {
                      return {
                          title: <span className='order-guide__steps__title'>{data?.title}</span>,
                          description: <span className='order-guide__steps__description'>{data?.description}</span>,
                          icon:
                              <div
                                  className="grid-x align-center-middle order-guide__steps__circle__content"
                              >
                                  <div className='order-guide__steps__circle'></div>
                              </div>
                      }
                  })}
                />
              </div>
              {/* TODO: commented upon new request */}
              {/* <div className='grid-x align-center'>
                <button className='grid-x align-middle'>
                    <img
                        src={salesHistory === SalesConst.ASSIGNMENTS_SALES ? Assets.SalesIcons.icon_orders : Assets.SalesIcons.icon_turns}
                        alt='icon_orders'
                        height={24}
                        width={24}
                    />
                    <label className='summary__text'>{t("sales.summary.proofReceipt")}</label>
                </button>
              </div> */}
              <div className='grid-x align-center'>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalMainComponent
        open={isOpenModal}
        footer={null}
        close={true}
        titleIcon={<img className='summary__text__content'  src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>}
        width={320}
        onClose={()=>handleCloseModal()}
      >
        <>
            <span className='grid-x summary__modal__text text-center summary__text__content--mod'>{`${t("sales.summary.shareDescription")} ${ salesHistory === SalesConst.ASSIGNMENTS_SALES ? t("sales.summary.order") : t("sales.summary.turn")}`}</span>
            <div className='grid-x justify-content-between summary__text__content'>
                <label className='summary__label'>Nro. de guía</label>
                <span className='summary__text'>{detailsInvoice?.dataSale?.num}</span>
            </div>
            <div className='grid-x justify-content-between summary__text__content'>
                <label className='summary__label'>{t("sales.summary.client")}</label>
                <span className='summary__text'>{detailsInvoice?.dataClient?.clientName}</span>
            </div>
            <div className='grid-x justify-content-between summary__text__content--mod'>
                <label className='summary__label'>Whatsapp</label>
                <span className='summary__text'>{detailsInvoice?.dataClient?.phoneWhatsapp || detailsInvoice?.dataClient?.phone}</span>
            </div>
            <button className='summary__modal__button' onClick={()=> handleShareModal()}>
                <span>{t("sales.summary.shareLink")}</span>
            </button>
        </>
      </ModalMainComponent>

      <ModalMainComponent
        open={isModalPrint}
        onClose={()=> onCloseModalPrint()}
        width={300}
        close
        footer={[
          <button 
              key="accept" 
              className="sales-table__modal-electronic__button"
              onClick={()=>pdfDocumentPrint(typePrint)}
            >
              <span className="sales-table__modal-electronic__button__label">
                Aceptar
              </span>
            </button>,
        ]}
        titleIcon={
          <div className="grid-x align-center-middle">
            <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
          </div>
        }
      >
        <p className="passages__subtitle text-center" style={{ marginTop: "20px" }}>
          Imprimir Factura
        </p>
        <p className="sales-table__modal-electronic__info text-center" style={{ width: "100%" }}>
          Selecciona el formato de impresión de factura de venta
        </p>
        <div className="grid-x">
          <Select
            value={typePrint}
            onChange={(e)=> setState({
              ...state,
              typePrint: e
            })}
            placeholder={"Selecciona el tipo de impresión"}
            className="small-12"
            options={[
              {
                label: "Impresión de 80 mm",
                value: 0
              },
              {
                label: "Impresión formato carta",
                value: 1
              }
            ]}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
          />
        </div>
      </ModalMainComponent>

      <ModalServiceDeliveryComponent
        isModalOpen={isModalOpen}
        onCloseModal={onCloseModal}
        salesHistory={salesHistory}
        modalFact={dataRoute.modalFact}
        documentTypes={dataRoute.documentTypes}
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        pdfDocumentPrint={pdfDocumentPrint}
        setDataStatusMod={(newData) => setState((prevState) => ({
          ...prevState,
          dataStatusMod: [...prevState.dataStatusMod, newData ],
          isDeliveryButton: newData?.delivery ? true : false
        }))}
      />
    </>
  );
}

const mapStateToProps = ({ SalesReducer }) => {
  const { salesHistory, countTabs } = SalesReducer

  return {
    salesHistory,
    countTabs
  };
};


export default connect(mapStateToProps, null)(SummaryPage)