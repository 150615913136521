import * as vehiclesTypes from './vehicles.types';

const INITIAL_STATE = {
	isError: false,
	isDisabled: false,
	addVehicle: {
		//VehicleData
		idRegisterVehicle: '',
		typeVehicle: undefined,
		mark: '',
		model: '',
		plate: '',
		mileage: '',
		typeFuel: undefined,
		internalNumber: '',
		templateVehicle: undefined,
		codeBodyWork: '',
		typeBodywork: undefined,
		code: '',
		number: '',
		isMaintenance: {
			isTrue: true,
			isFalse: false
		},
		operatingCardExpiration: '',
		civilInsuranceExpiration: '',
		//InternalVehicleIdentification
		motorNumber: '',
		rerecordingMotor: {
			isTrue: false,
			isFalse: true
		},
		chassisNumber: '',
		rerecordingChassis: {
		  isTrue: false,
		  isFalse: true
		},
		serialNumber: '',
		rerecordingSerialNumber: {
		  isTrue: false,
		  isFalse: true
		},
		SOATExpiration: '',
		mechanicalTechnicianExpiration: '',		
		//OwnerInformation.
		idOwner: '',
		documentType: undefined,
		numberDocument:	'',
		name: '',
		lastName: '',
		indicativeNumber: undefined,
		numberPhone: '',
		indicativeNumberWhatsapp: undefined,
		numberPhoneWhatsapp: '',
		departmentResidence: undefined,
		municipalityResidence: undefined,
		address: '',
		email: ''
	},

	newVehicleInformation: {
		//VehicleData
		idRegisterVehicle: '',
		typeVehicle: undefined,
		mark: '',
		model: '',
		plate: '',
		mileage: '',
		typeFuel: undefined,
		internalNumber: '',
		templateVehicle: undefined,
		codeBodyWork: '',
		typeBodywork: undefined,
		code: '',
		number: '',
		isMaintenance: {
			isTrue: true,
			isFalse: false
		},
		operatingCardExpiration: '',
		civilInsuranceExpiration: '',
		//InternalVehicleIdentification
		motorNumber: '',
		rerecordingMotor: {
			isTrue: false,
			isFalse: true
		},
		chassisNumber: '',
		rerecordingChassis: {
		  isTrue: false,
		  isFalse: true
		},
		serialNumber: '',
		rerecordingSerialNumber: {
		  isTrue: false,
		  isFalse: true
		},
		SOATExpiration: '',
		mechanicalTechnicianExpiration: '',		
		//OwnerInformation.
		idOwner: '',
		documentType: undefined,
		numberDocument:	'',
		name: '',
		lastName: '',
		indicativeNumber: undefined,
		numberPhone: '',
		indicativeNumberWhatsapp: undefined,
		numberPhoneWhatsapp: '',
		departmentResidence: undefined,
		municipalityResidence: undefined,
		address: '',
		email: ''
	},
};

const VehiclesReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {
		case vehiclesTypes.SET_VEHICLES:
			return {
				...state,
				[action.payload.prop]: action.payload.value
			}
		case vehiclesTypes.SET_DATA_VEHICLES_STATE:
			return {
				...state,
				[action.payload.key]: {
					...state[action.payload.key],
					[action.payload.prop]: action.payload.value
				}
			};
		case vehiclesTypes.SET_DATA_VEHICLES_KEY_STATE:
			return {
				...state,
				[action.payload.prop]: {
					...state[action.payload.prop],
					...action.payload.value
				}
			};
		case vehiclesTypes.RESET_DATA_VEHICLES:
			return {
				...INITIAL_STATE
			};

		default:
			return state;
	}

};

export default VehiclesReducer;