const SalesEndPoints =  {
    GET_ROUTE: `/api/route`,
    GET_ROUTE_BY_HEADQUARTER_DEPART: `/api/route/all/byHeadquarterDepart`,
    GET_VEHICLES_AVAILABLE_TO_TRAVEL: `/api/travel/vehiclesAvailableToTravel`,
    GET_SEAT: `/api/seat`,
    GET_DRIVER_VEHICLE_TRAVEL: `/api/travel/getDriverVehicleTravel`,
    CHANGE_STATE_TO_STAND_BY: `/api/seat/changeStateToStandBy`,
    CHANGE_STATE_TO_AVAILABLE: `/api/seat/changeStateToAvailable`,
    GET_INVOICE_PASSAGES:`/api/invoice/travel/all/`,
    CREATE_INVOICE_SHIPPING:`/api/invoice/shipping`,
    GET_INVOICE_SHIPPING:`/api/invoice/shipping/all`,
    CREATE_INVOICE_TURN:`/api/invoice/moneyTransfer`,
    GET_INVOICE_TURN:`/api/invoice/moneyTransfer/`,
    GET_INVOICE_TURNS:`/api/invoice/moneyTransfer/all`,
    CANCELLED_INVOICE:`/api/invoice/cancelation`,
    CREATE_ELECTRONIC_INVOICE:`/api/invoice/electronic/create`,
    ASSOCIATE_VEHICLE_TO_TRAVEL:`/api/travel/byVehicle`,
    GET_INVOICE_PASSAGES_BY_ID: `/api/invoice/travel/:id`,
    GET_ALL_VEHICLES_AVAILABLE_TRAVEL: `/api/vehicle/all/findAllVehiclesAvailableTravelOptional`,
    UPDATE_SHIPPING: `/api/shipping`,
    GET_CONSTANT: `/api/calculationSystemConstant/:code`,
    GET_INVOICE_SHIPPING_TOBEDELIVERED:`/api/invoice/shipping/all/toBeDelivered`,
    GET_INVOICE_SHIPPING_COLLECTED:`/api/invoice/shipping/all/collected`,
    POST_DELIVERY_SHIPPING_INVOICE:`/api/shippingDelivered`,
    POST_DELIVERY_TURN_INVOICE: `/api/moneyTransferDelivered`
}

export default SalesEndPoints