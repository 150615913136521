const AppConst = {
    APP_KEY: "",
    FULFILLED: "fulfilled",
    ROLE_ADMIN: "ADMIN",
    ROLE_USER: "USER",
    TYPE_ROUTES:{
        SALES: "SLS",
        SPREADSHEET: "MNTR",
        CASH: "CSHCL",
        DRIVERS: "DRVR",
        SHIFTS: "MNSH",
        VEHICLES: "VHCL",
        SETTINGS: "CFGR"
    }
}

export default AppConst