// Libraries
import * as Yup from 'yup';

/*
* Startup of the validators of the driver module
*/
export const driverPersonalDataSchema = Yup.object().shape({
    name: Yup.string()
        .required('Requerido'),
    lastName: Yup.string()
        .required('Requerido'),
    documentType: Yup.mixed()
        .required('Requerido'),
    documentNumber: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .min(6, 'Minimo 6 digitos')
        .max(10, 'Maximo 10 digitos')
        .required('Requerido'),
    date: Yup.string()
        .required('Requerido'),
    //TODO: Commented by new requirement
    // countryOfBirth: Yup.mixed()
    //     .required('Requerido'),
    departmentOfBirth: Yup.mixed()
        .required('Requerido'),
    municipalityOfBirth: Yup.mixed()
        .required('Requerido'),
    bloodType: Yup.mixed()
        .required('Requerido'),
    telephoneCode: Yup.mixed()
        .required('Requerido'),
    telephone: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .length(10, 'Numero no valido')
        .required('Requerido'),
    contactEmail: Yup.string()
        .email('Email no valido')
        .nullable(),
    municipalityResidence: Yup.mixed()
        .when('departmentResidence', {
            is: value => typeof value === 'object' && value !== null && Object.keys(value).length > 0,
            then: Yup.mixed()
        })
        .nullable(),
    contactAddress: Yup.string()
        .nullable(),
})

export const driverInformationFromSchema = Yup.object().shape({
    driverLicenseNumber: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .min(6, 'Minimo 6 digitos')
        .max(10, 'Maximo 10 digitos')
        .required('Requerido'),
    dateOfIssue: Yup.string()
        .required('Requerido'),
    dateExpiration: Yup.string()
        .required('Requerido'),
    nameOfEntity: Yup.string()
        .required('Requerido'),
    authorizedCategory: Yup.mixed()
        .required('Requerido'),
})
/*
* End of the driver module validators
*/