//Actions
import { setStateBoardingSheetReducer } from '../../../../storage/reducers/boarding-sheet/boardin-sheet.action';

// Assets
import { Assets } from '../../../../assets';

// Components
import ErrorToastComponent from '../../../../shared/components/toast/error-toast/error-toast.component';

//Constants
import { BoardingSheetErrorsConst } from '../../../../core/constants/errors/alerts-app/boarding-sheet.errors.const';
import AlertConst from '../../../../core/constants/alerts.const';

// Helpers
import { passagesModalPreviewSchema } from '../../../../utils/form-validations/sales.validators.utils';

//Libraries
import { Select, AutoComplete, Input, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { useFormik } from "formik";
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react'

//Styles
import "./modal-preview.component.scss";

//Services
import { getOptionsShippingService, getPassagesListingService } from '../../../../services/boarding-sheet.services';
import {getObtainVehicleByServiceNumberService} from '../../../../services/vehicle.services';

const ModalPreviewComponent = (props) => {

        
        const {
            //Actions
            setStateBoardingSheetReducer,
            //funtions
            setVehicleSelected = () => {},
            setDisabled = () => {},
            //Constants
            itemSelected,
            daySelected,
            isTransferPassages
        } = props;
        

        useEffect(() => {
            if (isTransferPassages) {
                getPassagesList(itemSelected?.travel?.id)
            } else {
                getOptionsShipping(itemSelected?.travel?.id)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps    
        },[])

        const INITIAL_STATE = {
           optionsShippingState: [],
           optionsVehicleSearchState: [],
           optionsPassages: [],
           selectedPassages: [],
        }

        const [state, setState] = useState(INITIAL_STATE)
        const { 
            optionsShippingState, optionsVehicleSearchState, optionsPassages,
            selectedPassages
        } = state;

        const  {values, errors, handleBlur, touched, setFieldValue} = useFormik({
            initialValues: {
              optionsShipping: [],
              optionsVehicleSearch: {},
            },
            initialErrors:{
                optionsShipping: "",
                optionsVehicleSearch:""
            },
            validationSchema: passagesModalPreviewSchema,
        });

        useEffect(() => {
            setDisabled( Object.keys(errors).length > 0)
            // eslint-disable-next-line react-hooks/exhaustive-deps    
         },[errors])
         
        const getPassagesList = async(id) => {
            try {
                const passagesList = await getPassagesListingService(id)
                setState((prevState) => ({
                    ...prevState,
                    optionsPassages: passagesList,
                    selectedPassages: passagesList.filter(object => object?.checked === true) || []
                }))
                onChange(passagesList.filter(object => object?.checked === true), 'optionsShipping')
            } catch (error) {
                ErrorToastComponent()
            }
        }

        const getOptionsShipping= async(item) => {
            try {
              const optionsShipping = await getOptionsShippingService(item);
              setState((prevState) => ({
                ...prevState,
                optionsShippingState: optionsShipping
              }))
            } catch (error) {
            }
        }

        const filterVehicle = async(valueFilter) => {
            try {
                const responseGetFilter = await getObtainVehicleByServiceNumberService({
                    valueFilter: valueFilter,
                    date: dayjs(daySelected).format('YYYY-MM-DD'),
                    time: dayjs(daySelected).format('HH:mm:ss'),
                    idTravel: itemSelected?.travel?.id
                },isTransferPassages)


                if (responseGetFilter.length === 0) {
                    ErrorToastComponent({
                        title: BoardingSheetErrorsConst.localError.resultEmpty || BoardingSheetErrorsConst.default,
                        position: AlertConst.TOP_END_POSITION_TEXT,
                        timer: 1500
                    })
                }
                setState((prevState) => ({
                    ...prevState,
                    optionsVehicleSearchState: responseGetFilter
                }))
                
            } catch {
                // TODO: Implement error alert with code error.
            }
        }

    const onChange = (data, target) => {
        const value = data && data.target ? data.target.value || '' : data;
        setFieldValue(target, value);
        if (isTransferPassages && target === "optionsVehicleSearch") {
            setState((prevState) => ({
                ...prevState,
                selectedPassages: [],
                optionsPassages: optionsPassages.map(object => ({...object, checked: false}))
            }))
        }
        setStateBoardingSheetReducer(target, value)
    }

    const SelectVehicle = (options) => {
        setVehicleSelected(options)
        onChange(options, 'optionsVehicleSearch')
    }


    const handlePassages = async (value) => {
        try {
            let options = [...optionsPassages];
            let checks = [];

            options.forEach((option) => {
                if (value.some((select) => select.id === option.id)) {
                    option.checked = true;
                    checks.push(option);
                } else {
                    option.checked = false;
                }
            });

            setState((prevState) => ({
                ...prevState,
                selectedPassages: checks
            }))
            onChange(checks, 'optionsShipping')
        } catch (err) {
            ErrorToastComponent({
                title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }
    };

    const totalAmount = (array) => {
        return array.reduce((add, object) => {
            return add + object.amount;
        }, 0);
    } 

    return (
        <div>
            <div className='grid-x small-12'>
                <div className='grid-x small-6'>
                    <span className='small-12 text-left modal-preview__subtitle'>Código del vehículo</span>
                    <span className='small-12 text-left modal-preview__subtitle'>Nro. del vehículo</span>
                    <span className='small-12 text-left modal-preview__subtitle'>Pasajes vendidos</span>
                    <span className='small-12 text-left modal-preview__subtitle'>Encomiendas</span>
                </div>
                <div className='grid-x small-6'>
                    <span className='small-12 text-right modal-preview__text'>{itemSelected?.vehicle?.plate}</span>
                    <span className='small-12 text-right modal-preview__text'>{itemSelected?.vehicle?.internalNumber}</span>
                        <span className='small-12 text-right modal-preview__text'>{itemSelected?.travel?.ticketsSales}/{itemSelected?.travel?.totalSeat}</span>
                    <span className='small-12 text-right modal-preview__text'>{itemSelected?.travel?.totalShipping}</span>
                </div>
            </div>

            {!isTransferPassages &&
                <>
                    <label className='modal-preview__subtitle text-left'>Encomiendas Vinculadas </label>
                    <Select
                        className="modal-preview__select"
                        mode="multiple"
                        suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
                        value={values.optionsShipping}
                        onChange={(e, event) => onChange(event, 'optionsShipping')}
                        onBlur={(value) => handleBlur('optionsShipping')(value)}
                        status={errors.optionsShipping && touched.optionsShipping ? "error" : ""}
                        options={optionsShippingState}
                        menuItemSelectedIcon={() => <></>}
                        showSearch={true}
                        removeIcon={() => {
                            return (
                                <img
                                    src={Assets.SharedIcons.icon_close} alt="icon_square_close" />
                            )
                        }}
                    />
                </>
            }
            <label className='modal-preview__subtitle text-left'>Vehiculo que recibe </label>
            <AutoComplete
            className="modal-preview__input"
            onSearch={(e) => {
                const trimmedValue = e.trim();
                if (trimmedValue) {
                    filterVehicle(trimmedValue);
                } else { 
                    setState({ ...state, optionsVehicleSearchState: [] });
                }
            }}
            options={optionsVehicleSearchState}
            onSelect={(value,options)=>SelectVehicle(options)}
            >
            <Input
                value= {values.optionsVehicleSearch?.value}
                onChange={(event) => onChange({value:event.target.value}, 'optionsVehicleSearch')}
                onBlur={(value) => handleBlur('optionsVehicleSearch')(value)}
                status={errors.optionsVehicleSearch && touched.optionsVehicleSearch ?"error":""}
                allowClear={{
                    clearIcon: (
                    <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                    />
                    )
                }}
                className="modal-preview__input__content"
                placeholder="Escribe el Nro. del Vehículo"
                prefix={
                    <img
                    className="modal-transfer__input__search"
                    src={Assets.SharedIcons.icon_search}
                    alt="icon_search"
                    />
                }
            />
            </AutoComplete>
            {isTransferPassages &&
                <div className='small-12 grid-x' style={{ marginTop: '10px' }}>
                    <div className='small-6'>
                        <label className='modal-temporary-payroll__subtitle'>Vincular pasajes</label>
                    </div>
                    <Select
                        disabled={!values?.optionsVehicleSearch?.value}
                        mode="multiple"
                        optionLabelProp="label"
                        placeholder="Facturas de pasajes"
                        value={selectedPassages}
                        onChange={(e, select) => handlePassages(select)}
                        className="modal-temporary-payroll__select"
                        suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
                        menuItemSelectedIcon={() => <></>}
                        showSearch={true}
                        removeIcon={() => {
                            return (
                                <img
                                    src={Assets.SharedIcons.icon_close} alt="icon_square_close" />
                            )
                        }}

                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {optionsPassages?.map((item, index) => {
                            return (
                                <Select.Option
                                    key={index}
                                    id={item?.id}
                                    label={item?.label}
                                    value={item?.id}
                                    disabled={(item.amount + totalAmount(selectedPassages) + (values?.optionsVehicleSearch?.vehicle?.seatsSold || 0)) > (values?.optionsVehicleSearch?.vehicle ? values?.optionsVehicleSearch?.vehicle?.totalSeats : itemSelected?.travel?.totalSeat) && !item?.checked}
                                >
                                    <div className="grid-x justify-content-between">
                                        <div>
                                            <Checkbox
                                                checked={item?.checked}
                                                className="trip-data__check"
                                                disabled={(item.amount + totalAmount(selectedPassages) + (values?.optionsVehicleSearch?.vehicle?.seatsSold || 0)) > (values?.optionsVehicleSearch?.vehicle ? values?.optionsVehicleSearch?.vehicle?.totalSeats : itemSelected?.travel?.totalSeat) && !item?.checked}
                                            />
                                            {item?.label}
                                        </div>
                                    </div>
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
            }
        </div>
    )
}

const mapStateToPropsActions = {
    setStateBoardingSheetReducer
};

export default connect(null, mapStateToPropsActions)(ModalPreviewComponent);