//Assets
import { Assets } from "../../../../../assets";

//Libraries
import React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

//Styles
import { PdfTurnStyles } from "./pdf-turn.component.styles";

const PdfTurnComponent = (props) => {

  const { dataInvoice, size, isDelivery, tabClaimedTurns } = props
  const [t] = useTranslation("translation");

  const splitCufe = (cufe, size) => {
    const parts = [];
    for (let i = 0; i < cufe.length; i += size) {
      parts.push(cufe.substring(i, i + size));
    }
    return parts.join('\n');
  };

  return (
    <Document style={PdfTurnStyles.content}>
      <Page size={size ? "A4" : { width: 297 }} style={PdfTurnStyles.page}>
        <View style={PdfTurnStyles.section}>
          <Image
            src={Assets.SharedIcons.icon_logo_pdf}
            style={PdfTurnStyles.logo}
          />
          <Text
            style={PdfTurnStyles.titleNit}
          >
            {"Cooperativa de Transportadores del Catatumbo\nNit: 800.175.577-0\nRegimen común"}
          </Text>
          <Text
            style={PdfTurnStyles.titleNit}
          >
            {"Factura electrónica de venta"}
          </Text>
          <Text style={PdfTurnStyles.subTitle}>
            {t("sales.turnPage.pdfTurn.header")} No. {dataInvoice?.resolution?.number} DE {dayjs(dataInvoice?.resolution?.date).format('DD/MM/YYYY')} DEL {dataInvoice?.codePrefix} {dataInvoice?.resolution?.initialRange} AL {dataInvoice?.codePrefix} {dataInvoice?.resolution?.finalRange}
          </Text>
          <View style={PdfTurnStyles.box}>
            <View style={PdfTurnStyles.boxSection}>
              <Text style={PdfTurnStyles.title}>
                {t("sales.turnPage.pdfTurn.guide")}
              </Text>
              <Text style={PdfTurnStyles.data}>{`${dataInvoice?.codeSale}-${dataInvoice?.codePrefix}-${dataInvoice?.number}`}</Text>
            </View>
            <View style={PdfTurnStyles.boxSection}>
              <Text style={PdfTurnStyles.title}>
                {t("sales.turnPage.pdfTurn.dateIssued")}
              </Text>
              <Text style={PdfTurnStyles.data}>{dayjs(dataInvoice?.date).format('DD/MM/YYYY')} {dataInvoice?.hour}</Text>
            </View>
            <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
              {t("sales.turnPage.pdfTurn.client")}
            </Text>
            <Text style={[PdfTurnStyles.data, PdfTurnStyles.boxUnit]}>
              {`${dataInvoice?.invoiceClient?.name} ${dataInvoice?.invoiceClient?.lastName}`}
            </Text>
            <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
              {t("sales.turnPage.pdfTurn.seller")}
            </Text>
            <Text style={[PdfTurnStyles.data, PdfTurnStyles.boxUnit]}>
              {`${dataInvoice?.seller?.name} ${dataInvoice?.seller?.lastName}`}
            </Text>
            <View style={PdfTurnStyles.boxSection}>
              <Text style={PdfTurnStyles.title}>
                {t("sales.turnPage.pdfTurn.document")}
              </Text>
              <Text style={PdfTurnStyles.data}>{`${dataInvoice?.invoiceClient?.userDocumentType} ${dataInvoice?.invoiceClient?.numberDocument}`}</Text>
            </View>
            <View style={PdfTurnStyles.boxSection}>
              <Text style={PdfTurnStyles.title}>
                {t("sales.turnPage.pdfTurn.departure")}
              </Text>
              <Text style={PdfTurnStyles.data}>{dayjs(dataInvoice?.date).format('DD/MM/YYYY')}</Text>
            </View>
            <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
              {t("sales.turnPage.pdfTurn.addressee")}
            </Text>
            <Text style={[PdfTurnStyles.data, PdfTurnStyles.boxUnit]}>
              {`${dataInvoice?.invoiceMoneyTransfer?.moneyTransferClient?.name} ${dataInvoice?.invoiceMoneyTransfer?.moneyTransferClient?.lastName}`}
            </Text>
            <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
              {t("sales.turnPage.pdfTurn.recipientAddress")}
            </Text>
            <Text style={[PdfTurnStyles.data, PdfTurnStyles.boxUnit]}>
              {dataInvoice?.invoiceMoneyTransfer?.moneyTransferClient?.address}
            </Text>
            <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
              {t("sales.turnPage.pdfTurn.recipientPhoneNumber")}
            </Text>
            <Text style={[PdfTurnStyles.data, PdfTurnStyles.boxUnit]}>
              {dataInvoice?.invoiceMoneyTransfer?.moneyTransferClient?.numberPhone}
            </Text>
          </View>
          <View style={[PdfTurnStyles.line, PdfTurnStyles.lineSeparator]} />
          <View style={[PdfTurnStyles.box, { marginBottom: "10px", paddingBottom: "10px"}]}>
            <Text
              style={[
                PdfTurnStyles.title,
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.boxSection,
              ]}
            >
              {t("sales.turnPage.pdfTurn.salesConcept")}
            </Text>
            <Text
              style={[
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.boxSection,
                PdfTurnStyles.dataRight,
              ]}
            >
              {t("sales.turnPage.pdfTurn.dispatchingOfGiro")}
            </Text>
            <Text
              style={[
                PdfTurnStyles.boxSection,
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.dataLeft,
              ]}
            >
              {t("sales.turnPage.pdfTurn.amountOfDraft")}
            </Text>
            <Text
              style={[
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.boxSection,
                PdfTurnStyles.dataRight,
              ]}
            >
              ${(dataInvoice?.invoiceMoneyTransfer?.amountMoney)?.toLocaleString()}
            </Text>
            <Text
              style={[
                PdfTurnStyles.boxSection,
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.dataLeft,
              ]}
            >
              {t("sales.turnPage.pdfTurn.costOfTheTurnaround")}
            </Text>
            <Text
              style={[
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.boxSection,
                PdfTurnStyles.dataRight,
              ]}
            >
              ${(dataInvoice?.invoiceMoneyTransfer?.cost)?.toLocaleString()}
            </Text>
            <Text
              style={[
                PdfTurnStyles.boxSection,
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.dataLeft,
              ]}
            >
              {t("sales.turnPage.pdfTurn.vat")} 19%
            </Text>
            <Text
              style={[
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.boxSection,
                PdfTurnStyles.dataRight,
              ]}
            >
              ${(dataInvoice?.invoiceMoneyTransfer?.iva)?.toLocaleString()}
            </Text>
            <View style={[PdfTurnStyles.line, PdfTurnStyles.lineSeparator]} />
            <Text
              style={[
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.boxSection,
                PdfTurnStyles.dataLeft,
              ]}
            >
              {t("sales.turnPage.pdfTurn.totalCost")}
            </Text>
            <Text
              style={[
                PdfTurnStyles.title,
                PdfTurnStyles.titleSecondary,
                PdfTurnStyles.boxSection,
                PdfTurnStyles.dataRight
              ]}
            >
              ${(dataInvoice?.price)?.toLocaleString()}
            </Text>
            <View style={[PdfTurnStyles.line, PdfTurnStyles.lineSeparator]} />
            <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
              {t("sales.turnPage.turnoverData.cufe")}
            </Text>
            <Text style={[PdfTurnStyles.data, PdfTurnStyles.boxUnit]}>
              {splitCufe(dataInvoice?.dataElectronic?.cufe, 37)}
            </Text>
            <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
              {t("sales.turnPage.turnoverData.date")}
            </Text>
            <Text style={[PdfTurnStyles.data, PdfTurnStyles.boxUnit]}>
              {dataInvoice?.dataElectronic?.dateValidation}
            </Text>
            <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
              {t("sales.turnPage.turnoverData.provider")}
            </Text>
            <Text style={[PdfTurnStyles.data, PdfTurnStyles.boxUnit]}>
              {dataInvoice?.dataElectronic?.provider?.name} {dataInvoice?.dataElectronic?.provider?.nit}
            </Text>
            {(isDelivery || tabClaimedTurns) &&
              <>
                <View style={[PdfTurnStyles.line, PdfTurnStyles.lineSeparator]} />
                <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
                  {isDelivery ? "Cliente que recibe" : "Cliente que reclama"}
                </Text>
                <Text
                  style={[
                    PdfTurnStyles.boxSection,
                    PdfTurnStyles.titleSecondary,
                    PdfTurnStyles.dataLeft,
                  ]}
                >
                  Nombre
                </Text>
                <Text
                  style={[
                    PdfTurnStyles.titleSecondary,
                    PdfTurnStyles.boxSection,
                    PdfTurnStyles.dataRight,
                  ]}
                >
                  {isDelivery ? dataInvoice?.invoiceClient?.name : dataInvoice?.delivered?.nameUser}
                </Text>
                <Text
                  style={[
                    PdfTurnStyles.boxSection,
                    PdfTurnStyles.titleSecondary,
                    PdfTurnStyles.dataLeft,
                  ]}
                >
                  Apellido
                </Text>
                <Text
                  style={[
                    PdfTurnStyles.titleSecondary,
                    PdfTurnStyles.boxSection,
                    PdfTurnStyles.dataRight,
                  ]}
                >
                  {isDelivery ? dataInvoice?.invoiceClient?.lastName : dataInvoice?.delivered?.lastNameUser}
                </Text>
                <Text
                  style={[
                    PdfTurnStyles.boxSection,
                    PdfTurnStyles.titleSecondary,
                    PdfTurnStyles.dataLeft,
                  ]}
                >
                  Documento
                </Text>
                <Text
                  style={[
                    PdfTurnStyles.titleSecondary,
                    PdfTurnStyles.boxSection,
                    PdfTurnStyles.dataRight,
                  ]}
                >
                  {isDelivery ? dataInvoice?.invoiceClient?.numberDocument : dataInvoice?.delivered?.numberDocumentUser}
                </Text>
                {!tabClaimedTurns && (
                  <>
                    <Text style={[PdfTurnStyles.title, PdfTurnStyles.boxUnit]}>
                      Firma
                    </Text>
                    <View style={[PdfTurnStyles.signatureLine]} />
                  </>
                )}
              </>
            }
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfTurnComponent;
