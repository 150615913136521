import dayjs from "dayjs"
import BoardingSheetEndPoints from "./constants/boarding-sheet.end-points"
import Http from "./infrastructure/http.infrastructure"
import { getTokenService } from "./authentication.services";

export async function getManifestTravelByDateService(date) {

    try {
        let token = getTokenService();
        const { data: responseGetManifestTravel, error } = await Http.get(BoardingSheetEndPoints.GET_MANIFEST, { date }, token)
        if (responseGetManifestTravel && responseGetManifestTravel.status) {

            const manifestTravel = Object.values(responseGetManifestTravel?.data.reduce((acc, object) => {
              const hora = dayjs(object.travel.time, 'HH:mm:00').format('HH:mm:00');
              
              if (!acc[hora]) {
                acc[hora] = {
                  id: hora,
                  items: [],
                };
              }
            
              acc[hora].items.push(object);
            
              return acc;
            }, {}));

            return manifestTravel
            
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};


export async function getAllManifestTravelService(valueFilter,offset,initialDate, finalDate) {
    let data = {
        valueFilter,
        offset
    }
    if(initialDate && finalDate){
        data = {
            ...data,
            initialDate,
            finalDate
        }
    }
    try {
        let token = getTokenService();
        const { data: responseGetManifestTravel, error } = await Http.get(BoardingSheetEndPoints.GET_ALL_MANIFEST, data, token)
        if (responseGetManifestTravel && responseGetManifestTravel.status) {
            return responseGetManifestTravel.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllManifestTravelCountService(valueFilter, initialDate, finalDate) {
    let data = {
        valueFilter
    }
    if(initialDate && finalDate){
        data = {
            ...data,
            initialDate,
            finalDate
        }
    }
    try {
        let token = getTokenService();
        const { data: responseGetManifestTravelCount, error } = await Http.get(BoardingSheetEndPoints.GET_ALL_MANIFEST_COUNT, data, token)
        if (responseGetManifestTravelCount && responseGetManifestTravelCount.status) {
            return responseGetManifestTravelCount.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function postManifestTravelByRangeDateService(initialDate, finalDate, isValidateSynchronization) {
    try {
        let token = getTokenService()
        const { data: responsePostManifestTravel, error } = await Http.post(BoardingSheetEndPoints.POST_MANIFEST_TRAVEL_BY_RANGE_DATE, {initialDate, finalDate, isValidateSynchronization}, token)
        if (responsePostManifestTravel && responsePostManifestTravel.status) {
            return responsePostManifestTravel.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateAssignTravelAnotherVehicleService(id, data) {
    try {
        const urlWithParams = BoardingSheetEndPoints.UPDATE_ASSIGN_TRAVEL_ANOTHER_VEHICLE.replace(":id", id)
        const { data: responseUpdateManifestTravelCount, error } = await Http.put(urlWithParams, data)
        if (responseUpdateManifestTravelCount && responseUpdateManifestTravelCount.status) {
            return responseUpdateManifestTravelCount.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createManifestNumberService(id, data) {
    try {
        let token = getTokenService()
        const urlWithParams = BoardingSheetEndPoints.CREATE_MANIFEST_NUMBER.replace(":id", id)
        const { data: createManifestNumber, error } = await Http.put(urlWithParams, data, token)
        if (createManifestNumber && createManifestNumber.status) {
            return createManifestNumber.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getOrderWithGuideService(items, idTravel) {
    try {
        let token = getTokenService();
        let data = {
            shippings: items,
            idTravel : idTravel
        }
        const { data: getOrderWithGuide, error } = await Http.patch(BoardingSheetEndPoints.GET_ORDER_WITH_GUIDE, data, token)
        if (getOrderWithGuide && getOrderWithGuide.status) {
            
            return getOrderWithGuide.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }

}; 

export async function getManifestByIdService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = BoardingSheetEndPoints.GET_MANIFEST_BY_ID.replace(":id", id)
        const { data: getManifestById, error } = await Http.get(urlWithParams, null, token)
        if (getManifestById && getManifestById.status) {
            return getManifestById.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getHeadquarterAssociatedService() {
    try {
        let token = getTokenService()
        const { data: getHeadquarterAssociated, error } = await Http.get(BoardingSheetEndPoints.GET_HEADQUARTER_ASSOCIATED, null, token)
        if (getHeadquarterAssociated && getHeadquarterAssociated.status) {
            return getHeadquarterAssociated.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getOptionsShippingService(idTravel) {
    try {
        let token = getTokenService()

        const { data: getOptionsShipping, error } = await Http.get(BoardingSheetEndPoints.GET_SHIPPING_ASSOCIATED_BY_TRAVEL, {idTravel}, token)
        if (getOptionsShipping && getOptionsShipping.status) {
            const optionsShipping = getOptionsShipping.data.map((item) => ({
                ...item,
                label: `${item?.invoice.codeSale}-${item?.invoice.prefix}-${item?.invoice.number}`,
                value: item?.id,
                checked: false
            }))
            return optionsShipping
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDataAllPdfService(data) {
    try {
        let token = getTokenService()
        const paramsQuery = data.map(idTravel => `manifests=${idTravel}`).join('&');
        let urlWithParams = `${BoardingSheetEndPoints.GET_DATA_ALL_PDF}?${paramsQuery}`

        const { data: getDataAllPdf, error } = await Http.get(urlWithParams, null, token)
        if (getDataAllPdf && getDataAllPdf.status) {
            return getDataAllPdf.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getPassagesListService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = BoardingSheetEndPoints.GET_PASSAGES.replace(":id", id)

        const { data: getPassagesList, error } = await Http.get(urlWithParams, null, token)
        if (getPassagesList && getPassagesList.status) {
            return getPassagesList.data.map((item) => ({
                ...item,
                label: `${item?.codeSale}-${item?.number} ${item?.vehicle ? " - " + item?.vehicle : ""} (${item.amount})`,
                value: item?.id,
                checked: item?.isSelected
            }))
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getShippingsListService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = BoardingSheetEndPoints.GET_SHIPPINGS.replace(":id", id)

        const { data: getShippingsList, error } = await Http.get(urlWithParams, null, token)
        if (getShippingsList && getShippingsList.status) {
            return getShippingsList.data.map((item) => ({
                ...item,
                label: `${item?.codeSale}-${item?.number} ${item?.vehicle ? " - " + item?.vehicle : ""}`,
                value: item?.id,
                checked: item?.isSelected
            }))
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getTemporaryPayrollService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = BoardingSheetEndPoints.GET_TEMPORARY.replace(":id", id)

        const { data: getTemporaryPayroll, error } = await Http.get(urlWithParams, null, token)

        if (getTemporaryPayroll && getTemporaryPayroll.status) {
            return getTemporaryPayroll.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function patchPassagesService(id,idInvoice) {
    try {
        let token = getTokenService()

        const { data: patchPassages, error } = await Http.patch(BoardingSheetEndPoints.PATCH_PASSAGES, {id,idInvoice}, token)
        if (patchPassages && patchPassages.status) {
            return patchPassages.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function patchShippingService(id,idInvoice) {
    try {
        let token = getTokenService()

        const { data: patchShipping, error } = await Http.patch(BoardingSheetEndPoints.PATCH_SHIPPING, {id,idInvoice}, token)
        if (patchShipping && patchShipping.status) {
            return patchShipping.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateTemporaryPayrollService(id, data) {
    try {
        let token = getTokenService()
        const urlWithParams = BoardingSheetEndPoints.UPDATE_TEMPORARY.replace(":id", id)

        const { data: updateTemporaryPayroll, error } = await Http.put(urlWithParams, data, token)

        if (updateTemporaryPayroll && updateTemporaryPayroll.status) {
            return updateTemporaryPayroll.data
        } else {
            throw error
        }

    } catch (err) {
        throw err
    }
};

export async function getDetailsTemporaryPayrollService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = BoardingSheetEndPoints.GET_DETAILS_TEMPORARY.replace(":id", id)

        const { data: detailsTemporaryPayroll, error } = await Http.get(urlWithParams, null, token)

        if (detailsTemporaryPayroll && detailsTemporaryPayroll.status) {
            return detailsTemporaryPayroll.data
        } else {
            throw error
        }

    } catch (err) {
        throw err
    }
};

export async function deletePayrollService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = BoardingSheetEndPoints.PATCH_DELETE.replace(":id", id)

        const { data: detailsTemporaryPayroll, error } = await Http.patch(urlWithParams, null, token)

        if (detailsTemporaryPayroll && detailsTemporaryPayroll.status) {
            return detailsTemporaryPayroll.data
        } else {
            throw error
        }

    } catch (err) {
        throw err
    }
};

export async function getPayrollProcessToZeroService(data) {
    try {
        let token = getTokenService()
        const { data: getHeadquarterAssociated, error } = await Http.post(BoardingSheetEndPoints.GET_PAYROLL_PROCESS_TO_ZERO, data, token)
        if (getHeadquarterAssociated && getHeadquarterAssociated.status) {
            return {
                suggestedInsuranceValue: Number(getHeadquarterAssociated?.data?.totalManifestPrice),
            }
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getPassagesListingService(id) {
    try {
        let token = getTokenService()

        const { data: getPassagesList, error } = await Http.get(BoardingSheetEndPoints.GET_PASSAGES_LIST, {idTravel:id}, token)
        if (getPassagesList && getPassagesList.status) {
            return getPassagesList.data.map((item) => ({
                ...item,
                label: `${item?.codeSale}-${item?.number} ${item?.vehicle ? " - " + item?.vehicle : ""} (${item.amount})`,
                value: item?.id,
                checked: item?.isSelected
            }))
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function patchPassagesListingService(id, passages) {
    try {
        let token = getTokenService()

        const { data: getPassagesList, error } = await Http.patch(BoardingSheetEndPoints.PATCH_TRANSFER_PASSAGES, {idTravel:id, tickets:passages}, token)
        if (getPassagesList && getPassagesList.status) {
            return getPassagesList.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};