//Assets
import { Assets } from '../../../assets'

//Actions
import { setStateUserSettingsReducer, resetDataNewUserReducer } from '../../../storage/reducers/user-settings/user-settings.action'

//Libraries
import React, { useEffect, useState } from 'react'
import { Input, Select } from 'antd'
import { Pagination, PaginationItem } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

//Services
import { getAllUsersService, getUserAllPaginationCounterService, getUserByIdService } from '../../../services/settings.services';

//Styles
import './users-permissions.page.scss'

const UsersPermissionsPage = (props) => {

    const {
        setStateUserSettingsReducer, 
        resetDataNewUserReducer
    } = props;

    let history = useNavigate();

    const titles = [
        "Foto","Nickname","Nombre del usuario","Nro. Documento",
        "Número de contacto","Tipo de usuario","Estado","Acciones"
    ]

    const INITIAL_STATE = {
        listNewUser: [],
        valueFilter: "",
        selectFilterTypes: null,
        userCount: 0,
        pageState: 1,
        itemTotalList: 7,
        itemByPage: 0
    }

    const [state, setState] = useState(INITIAL_STATE)
    const { 
        listNewUser,
        valueFilter,
        selectFilterTypes,
        userCount,
        pageState,
        itemTotalList,
        itemByPage
     } = state
     
    const redirectEditButton = async (id) => {
        try {
            const response = await getUserByIdService(id);
            setStateUserSettingsReducer(response)
            history('createUser',{ state : { idCreateUser: id, isEditing: true} })   
        } catch (error) {
            // Implement error alert with code error.
        }
    }

    const redirectCreateButton = (id) => {
        resetDataNewUserReducer()
        history('createUser', { state : {isEditing: false } })
    }

    useEffect(() => {
        getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState])
    
    const getUsers = async (valueFilter, sortOrderType) => {
        try {
            const users = await getAllUsersService(valueFilter, sortOrderType, pageState);
            const count = await getUserAllPaginationCounterService(valueFilter, sortOrderType);
            let itemCalculated = 0;

            if(itemTotalList * pageState <= count) {
                itemCalculated = itemTotalList * pageState;
            }else{
                itemCalculated = count;
            }
            
            setState(prevState => ({
                ...prevState,
                listNewUser: users,
                userCount: count,
                itemByPage: itemCalculated
            }));
        } catch (error) {
            // Implement error alert with code error.
        }
    }
    
    const handleData = (page) => {
        setState(prevState => ({
            ...prevState,
            pageState: page
        }))
     }

    const handleSelectFilter = async (event) => {

        const filterSelect = event && event.target ? event.target.value || '' : event;
        
        await getUsers(valueFilter, filterSelect?.value);
        setState(prevState => ({
            ...prevState,
            selectFilterTypes: filterSelect
        }))

    }

    const handlerChangeFilter = async (event) => {
        
        const valueFilter = event && event.target ? event.target.value || '' : event;
        
        await getUsers(valueFilter, selectFilterTypes?.value);

        setState(prevState => ({
            ...prevState,
            valueFilter: valueFilter
        }));
    }

    const handleClearFilterParams = () => {
        setState(prevState => ({
            ...prevState,
            valueFilter: "",
            selectFilterTypes: null
        }))
    }

    const dataListFilter = [
        {
            label: "Alfabeticamente A-Z",
            value: "nameAscendent"
        },
        {
            label: "Mas reciente registrados",
            value: "dateRegistrationAscendent"
        },
        {
            label: "Mas antiguo registrado",
            value: "dateRegistrationDescendent"
        },
        {
            label: "Dispobibles",
            value: "usersActive"
        },
        {
            label: "Inactivos",
            value: "usersInactive"
        }
    ]

  return (
    <div>
        <div className='grid-x align-center-middle'>
            <button 
                className='users-permissions__button'
                onClick={() => {
                    redirectCreateButton()
                }}
            >
                <span className='users-permissions__button__text'>
                    Crear usuario
                </span>
                <img
                    src={Assets.SharedIcons.icon_add}
                    alt="icon_add"
                    className='users-permissions__button__icon'
                />
            </button>
        </div>
        <div className='grid-x' style={{ marginTop: "30px" }}>
            <div className='grid-x medium-6 justify-content-between align-middle'>
                <span>Listado de usuarios</span>
                <Select
                    value={selectFilterTypes}
                    className="users-permissions__select"
                    onChange={(e, event) => {handleSelectFilter(event)}}
                    suffixIcon={
                    <img
                        src={Assets.SharedIcons.icon_down_arrow}
                        alt="icon_down_arrow"
                    />
                    }
                    options={dataListFilter}
                    placeholder="Ordenar por"
                />
            </div>
            <div className='grid-x medium-6 grid-container align-middle justify-content-around'>
                <span>Buscar</span>
                <Input
                    style={{ width: "80%" }}
                    placeholder="Buscar usuario por nombre o número de documento"
                    value={valueFilter}
                    onChange={(e) => {
                        handlerChangeFilter(e);
                        if (e.target.value === ""){
                            handleClearFilterParams()
                        }
                    }}
                    className="users-permissions__input__content"
                    allowClear={{
                    clearIcon: (
                        <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                        />
                    ),
                    }}
                />
            </div>
        </div>
        <div className='users-permissions__table'>
            <div className='grid-x'>
                {titles.map((title, index) => {
                    return (
                        <div
                            key={index}
                            style={{ width: `calc(100% / ${titles.length})` }}
                            className='text-center users-permissions__table__line'
                        >
                            <span className='users-permissions__table__title'>{title}</span>
                        </div>
                    )
                })}
            </div>
            <div>

                {listNewUser.map((item, index) => {
                    const status = {
                        1: "Activo",
                        0: "Inactivo",
                    }

                    return(
                        <div
                            key={index}
                            className={`${index === listNewUser?.length - 1 ? 'grid-x users-permissions__table__padding' : 'grid-x users-permissions__table__line users-permissions__table__padding '}`}
                        >
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span 
                                    className='users-permissions__table__text'
                                >
                                    <img 
                                        src={item.img ? item.img :  Assets.SharedIcons.icon_user_profile}
                                        alt="img"
                                        width={40}
                                        height={40}
                                    />
                                </span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='users-permissions__table__text'>{item?.nickName}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='users-permissions__table__text'>{item?.name}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='users-permissions__table__text'>{item?.numberDocument}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='users-permissions__table__text'>{item?.numberPhone}</span>
                            </div>
                            {/* <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='users-permissions__table__text'>{dayjs(item?.resolution?.dateOfIssuance).format('DD/MM/YYYY')}</span>
                            </div> */}
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='users-permissions__table__text'>{item?.role?.name}</span>
                            </div>
                            {/* TODO: comentent this line by new requeriment */}
                            {/* <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='users-permissions__table__text'>{item?.role?.name}</span>
                            </div> */}
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span 
                                    className={`users-permissions__table__text ${item?.state ? "users-permissions__table__text__green" : "users-permissions__table__text__red"}`}
                                >
                                    {status[item?.state]}
                                </span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <button
                                    className="users-permissions__button--mod"
                                    onClick={() => {
                                        redirectEditButton(item?.id)
                                    }}
                                >
                                    <span>Editar perfil</span>
                                    <img
                                        src={Assets.SharedIcons.icon_edit_pencil}
                                        alt="icon_generate"
                                    />
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        <div className="grid-x justify-content-between users-permissions__content__navigation">
            <span className="users-permissions__info">
              {`${ itemByPage || 0}  de ${userCount} resultados`}
            </span>
            <div className="grid-x users-permissions__pagination__content">
              <Pagination
                disabled={userCount === 0  ? true : false}
                onChange={(e, event) => handleData(event)}
                count={Math.ceil(userCount / itemTotalList) || 1}
                siblingCount={0}
                boundaryCount={0}
                showFirstButton
                showLastButton
                shape="rounded"
                className="users-permissions__pagination"
                renderItem={(item) => (
                  <PaginationItem
                    className="users-permissions__pagination__item"
                    slots={{
                      previous: () => {
                        return (
                          <img
                            src={Assets.SharedIcons.icon_back}
                            alt="icon_back"
                          />
                        )
                      },
                      next: () => {
                        return (
                          <img
                            src={Assets.SharedIcons.icon_next}
                            alt="icon_forward"
                          />
                        )
                      },
                      first: () => {
                        return (
                          <img
                            src={Assets.SalesIcons.icon_back}
                            alt="icon_back"
                          />
                        )
                      },
                      last: () => {
                        return (
                          <img
                            src={Assets.SalesIcons.icon_forward}
                            alt="icon_back"
                          />
                        )
                      },
                    }}
                    {...item}
                  />
                )}
              />
            </div>
          </div>
    </div>
  )
}
const mapStateToProps = ({ UserSettingsReducer }) => {
    const { newUserSettings, originData, permitsNewUser } = UserSettingsReducer;
    return{
        newUserSettings,
        originData,
        permitsNewUser
    };
  };
const mapStateToPropsActions = {
    setStateUserSettingsReducer,
    resetDataNewUserReducer
  };

export default connect(mapStateToProps, mapStateToPropsActions)(UsersPermissionsPage)