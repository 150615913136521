//Assets
import { Assets } from "../../../../assets";

// Constants
import AlertConst from "../../../../core/constants/alerts.const";
import AlertErrorsConst from "../../../../core/constants/errors/alerts-app/alerts-error.const";

// Libraries
import Swal from "sweetalert2";

// Models - DA0
import { createErrorInternalApp } from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.dao";

//Styles
import "./success-toast.component.scss"

/**
* Success toast component
* 
* @param props - Contains configuration properties
* @param {string} [props.html = ''] - Text toast alert.
* @param {number} [props.timer = 1500] - Numeric value of the time (milliseconds) in which the alert will be displayed.
* @param {string} [props.position = position] - Text value of the popup window position.
* @param {boolean} [props.timerProgressBar = true] - boolean value show a progress bar on the pop-up window.
*
* @returns {Object} Returns a SweetAlert2 popup, success toast alert
*/

const SuccessToastComponent = (props) => {

  if (!props) {
    throw createErrorInternalApp(AlertErrorsConst.paramsRequiredBuildToasts)
  }

  let {
    html = "",
    timer = 1500,
    position = AlertConst.CENTER_POSITION_TEXT,
    timerProgressBar = true
  } = props

  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: false,
    timer,
    timerProgressBar,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  return Toast.fire({
    iconHtml: `<img src=${Assets.SharedIcons.icon_info} />`,
    iconColor: "transparent",
    html,
    width: "500px",
    customClass:{
      htmlContainer: "success-toast__text",
      timerProgressBar: "success-toast__progress"
    },
  })
}

export default SuccessToastComponent