import React, { useEffect, useState } from "react";
import { Input, Select, Spin } from "antd";
import { Assets } from "../../../../../assets";
import ModalMainComponent from "../../../../../shared/components/modal/modal-main/modal-main.component";
import SalesConst from "../../../../../core/constants/sales.const";
import { postDeliveryShippingInvoice, postDeliveryTurnInvoice } from "../../../../../services/sales.services";
import "./modal-service-delivery.scss";
import { getUserService } from "../../../../../services/authentication.services";
import { createFilterObject } from "../../../../../utils/sales.utils";

const ModalServiceDeliveryComponent = (props) => {
    const {
        isModalOpen, 
        modalFact, 
        documentTypes, 
        errors, 
        touched, 
        handleBlur, 
        setFieldValue,
        countTabs,
        salesHistory,
        onTabShippingsDelivered,
        onCloseModal,
        initialDateRange,
        endDateRange,
        valueFilterInput,
        selectSale,
        onPage,
        origin,
        setDataStatusMod,
        pdfDocumentPrint
    } = props;

    const INITIAL_STATE = {
        deliveryData:{
          idDocumentType: null,
          numberDocumentUser: "",
          nameUser: "",
          lastNameUser: "",
          idInvoice: "",
        },
        data: {
            guide: "",
            paymentMethod: "",
            costSales: "",
            sellerName: "",
            sellerLastName: "",
        },
        isLoadingSpin: false
      }

    const [state, setState] = useState(INITIAL_STATE)
    const { 
        deliveryData,
        data,
        isLoadingSpin
    } = state

    const handleModalDelivered = async () => {
        try {
            let currentDate = new Date();
            let date = currentDate.toISOString().split('T')[0]; 
            let time = currentDate.toTimeString().split(' ')[0];
            let response = null;
            let dataToSend = {
                idDocumentType: deliveryData.idDocumentType,
                numberDocumentUser: deliveryData.numberDocumentUser,
                nameUser: deliveryData.nameUser,
                lastNameUser: deliveryData.lastNameUser,
                idInvoice: deliveryData.idInvoice,
                date,
                time
            };

            if(salesHistory === SalesConst.ASSIGNMENTS_SALES){
                response = await postDeliveryShippingInvoice(dataToSend);
            }else if (salesHistory === SalesConst.TURNS_SALES){
                response = await postDeliveryTurnInvoice(dataToSend);
            }
            
            setDataStatusMod(response);  

            if(origin === "sales-table"){
                let objectFilter = createFilterObject(valueFilterInput, selectSale, initialDateRange, endDateRange); 
                if (salesHistory === SalesConst.ASSIGNMENTS_SALES) {
                    onTabShippingsDelivered({...objectFilter, isDelivered: 0});
                } else if (salesHistory === SalesConst.TURNS_SALES) {
                    onPage({...objectFilter, isReportLocal: 0});
                }
            }
            
            pdfDocumentPrint(undefined, deliveryData.idInvoice, true );
            onCloseModal();
        } catch (err) {
            // TODO: Implement error alert with code error.
        } finally{
            setState(prevState => ({
                ...prevState,
                isLoadingSpin: false
            }));
        }
    }

    useEffect(() => {
        deliveryInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const  deliveryInformation = () =>{
        
        const dataUser = getUserService()
        const dataForAssignmentsSales ={
            guide: modalFact?.guide,
            paymentMethod: modalFact?.paymentMethod,
            costSales: modalFact?.costSales,
            sellerName: dataUser?.name,
            sellerLastName: dataUser?.lastName
        }

        const dataForTurnsSales ={
            guide: modalFact?.guide,
            amountMoney: modalFact?.amountMoney,
            sellerName: dataUser?.name,
            sellerLastName: dataUser?.lastName,
        }

        setState(prevState => ({
            ...prevState,
            data: salesHistory === SalesConst.ASSIGNMENTS_SALES ? dataForAssignmentsSales : dataForTurnsSales,
            deliveryData: {
                ...prevState.deliveryData,
                nameUser: modalFact?.dataClientReceivesr?.name,
                lastNameUser: modalFact?.dataClientReceivesr?.lastName,
                numberDocumentUser: modalFact?.documentClientReceives?.numberDocument,
                idDocumentType: modalFact?.typeDocument?.value,
                idInvoice: modalFact?.id
            }
        }));
    }

    return (
        <>
            <ModalMainComponent
                width={320}
                open={isModalOpen}
                onClose={onCloseModal}
                titleIcon={
                    <div className="grid-x align-center-middle">
                        <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
                    </div>
                }
                footer={[
                    <button 
                        key="accept" 
                        className="modal-service-delivery__modal-electronic__button"
                        disabled={isLoadingSpin || !state.deliveryData?.numberDocumentUser || !state.deliveryData?.nameUser || !state.deliveryData?.lastNameUser}
                        onClick={() => {
                            setState(prevState => ({
                                ...prevState,
                                isLoadingSpin: true
                            }))
                            handleModalDelivered()
                        }}
                    >
                        {isLoadingSpin
                            ? <Spin size='default' className='boarding-sheet__button__transfer__spin' />
                            : <span className="modal-service-delivery__modal-electronic__button__label">Entregar</span>
                        }
                    </button>
                ]}
            >
                <div >
                    <div className='modal-service-delivery__subtitle small-2'>
                        <span>Número de factura:</span>
                        <span className='modal-service-delivery__value'>{data.guide}</span>
                    </div>
                    {salesHistory === SalesConst.ASSIGNMENTS_SALES && countTabs === 2 && (
                        <div className='modal-service-delivery__subtitle small-2'>
                            <span>Tipo de pago:</span>
                            <span className='modal-service-delivery__value'>{data.paymentMethod}</span>
                        </div>
                    )}
                    <div className='modal-service-delivery__subtitle small-2'>
                        <span>{salesHistory === SalesConst.ASSIGNMENTS_SALES ? "Costo de la encomienda:" : "Monto del giro:"}</span>
                        <span className='modal-service-delivery__value'>{salesHistory === SalesConst.ASSIGNMENTS_SALES ? data.costSales : data.amountMoney}</span>
                    </div>

                    <div className='modal-service-delivery__subtitle small-2'>
                        <span>Nombre del vendedor:</span>
                        <span className='modal-service-delivery__value'>{data?.sellerName}</span>
                    </div>

                    <div className='modal-service-delivery__subtitle small-2'>
                        <span>Apellido del vendedor:</span>
                        <span className='modal-service-delivery__value'>{data?.sellerLastName}</span>
                    </div>
                </div>
                <div>
                    <label className='modal-service-delivery__subtitle small-2'>Tipo de documento de quien entrega:</label>
                    <Select 
                        className="modal-service-delivery__select modal-service-delivery__select--mod"
                        value={deliveryData.idDocumentType || ""}
                        options={documentTypes}
                        onChange={selectedOption => {
                            setState(prevState => {
                                const updatedState = {
                                    ...prevState,
                                    deliveryData: {
                                        ...prevState.deliveryData,
                                        idDocumentType: selectedOption
                                    }
                                };
                                return updatedState;
                            });
                        }}
                    />
                </div>
                <div>
                    <label className='modal-service-delivery__subtitle small-2'>Número de documento de quien recibe:</label>
                    <Input 
                        className="modal-service-delivery__input__content"
                        type="text" 
                        value={deliveryData.numberDocumentUser || ""}
                        status={errors?.numberDocumentDelivered && touched?.numberDocumentDelivered ?"error":""}
                        onBlur={(value) => handleBlur('numberDocumentDelivered')(value)}
                        onChange={(e) => {
                            setFieldValue('numberDocumentDelivered', e.target.value);
                            setState(prevState => {
                                const updatedState = {
                                    ...prevState,
                                    deliveryData: {
                                        ...prevState.deliveryData,
                                        numberDocumentUser: e.target.value
                                    }
                                };
                                return updatedState;
                            });
                        }}
                        allowClear={{
                            clearIcon: (
                                <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                />
                            )
                        }}
                    /> 
                    <span className="grid-x modal-service-delivery__errors">{touched?.numberDocumentDelivered? errors?.numberDocumentDelivered : null}</span>
                </div>
                <div>
                    <label className='modal-service-delivery__subtitle small-2'>Nombre de quien recibe:</label>
                    <Input 
                        className="modal-service-delivery__input__content"
                        type="text" 
                        value={deliveryData.nameUser || ""}
                        status={errors?.nameDelivered && touched?.nameDelivered ?"error":""}
                        onBlur={(value) => handleBlur('nameDelivered')(value)} 
                        onChange={(e) => {
                            setFieldValue('nameDelivered', e.target.value);
                            setState(prevState => {
                                const updatedState = {
                                    ...prevState,
                                    deliveryData: {
                                        ...prevState.deliveryData,
                                        nameUser: e.target.value
                                    }
                                };
                                return updatedState;
                            });
                        }}
                        allowClear={{
                            clearIcon: (
                                <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                />
                            )
                        }}
                    />
                    <span className="grid-x modal-service-delivery__errors">{touched?.nameDelivered? errors?.nameDelivered : null}</span>
                </div>
                <div>
                    <label className='modal-service-delivery__subtitle small-2'>Apellido de quien recibe:</label>
                    <Input 
                        className="modal-service-delivery__input__content"
                        type="text" 
                        value={deliveryData.lastNameUser || ""} 
                        status={errors?.lastNameDelivered && touched?.lastNameDelivered ?"error":""}
                        onBlur={(value) => handleBlur('lastNameDelivered')(value)} 
                        onChange={(e) => {
                            setFieldValue('lastNameDelivered', e.target.value);
                            setState(prevState => {
                                const updatedState = {
                                    ...prevState,
                                    deliveryData: {
                                        ...prevState.deliveryData,
                                        lastNameUser: e.target.value
                                    }
                                };
                                return updatedState;
                            });
                        }}
                        allowClear={{
                            clearIcon: (
                                <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                />
                            )
                        }}
                    />
                    <span className="grid-x modal-service-delivery__errors">{touched?.lastNameDelivered? errors?.lastNameDelivered : null}</span>
                </div>
            </ModalMainComponent>
        </>
    );
};

export default ModalServiceDeliveryComponent;