//Actions
import { setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from '../../../../../storage/reducers/sales/sales.action';

//Assets
import { Assets } from '../../../../../assets';

//Constants
import AppConst from '../../../../../core/constants/app.const';

//Libraries
import { AutoComplete, Input, Select } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

//Services
import { 
  getAllDepartmentService, getAllDocumentTypeService, getAllIndicativeNumberService, 
  getAllMunicipalityService, getFilterUsersService
} from '../../../../../services/shared.services';

//Styles
import "./customer-information.component.scss"

//Utils
import { shippingsCustomerDataSchema } from '../../../../../utils/form-validations/sales.validators.utils';

const CustomerInformationComponent = (props) => {

  const {
    // Actions
    setDataSalesStateReducer,
    setStateSaleReducer,
    setDataSaleReducer,
    // Variables
    isDisabled,
    isElectronic,
    nextButtonRef,
    ...CustomerInformation
  } = props

  const [t] = useTranslation("translation");
  
  const documentTypeCustomerRef = useRef();
  const documentNumberCustomerRef = useRef();
  const nameCustomerRef = useRef();
  const lastNameCustomerRef = useRef();
  const telephoneCodeCustomerRef = useRef();
  const telephoneCustomerRef = useRef();
  const whatsAppCustomerRef = useRef();
  const departmentResidenceCustomerRef = useRef();
  const municipalityResidenceCustomerRef = useRef();
  const defaultRef = useRef();  

  const inputRefs = [
    documentTypeCustomerRef, 
    documentNumberCustomerRef, 
    nameCustomerRef,
    lastNameCustomerRef, 
    telephoneCodeCustomerRef, 
    telephoneCustomerRef, 
    whatsAppCustomerRef, 
    departmentResidenceCustomerRef, 
    municipalityResidenceCustomerRef, 
    defaultRef
  ];

    const INITIAL_STATE = {
    optionsTypeDocument: [],
    optionsDepartment: [],
    optionsMunicipality: [],
    optionsNumberList: [],
    optionsUserSearch: [],
    isDropdownOpen: false
  }

  const [state, setState] = useState(INITIAL_STATE)

  const { 
    optionsTypeDocument,
    optionsDepartment, 
    optionsMunicipality, 
    optionsNumberList,
    optionsUserSearch,
    isDropdownOpen
  } = state
      
  useEffect(() => {
    setValues(CustomerInformation);
    dataSelectorsForm()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataSelectorsForm = async () => {
    try {
      const results = await Promise.allSettled([
        getAllDocumentTypeService(),
        getAllDepartmentService(),
        getAllIndicativeNumberService()
      ]);
  
      let documentTypeList = [];
      let departmentList = [];
      let indicativeNumberList = [];
      let municipalityList = [];

      for (let index = 0; index < results.length; index++) {
        const elementResponse = results[index];
  
        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              documentTypeList = elementResponse.value.map((documentType) => ({
                label: documentType.name,
                value: documentType.id
              }));
              break;
            case 1:
              departmentList = elementResponse.value.map((department) => ({
                label: department.name,
                value: department.id
              }));
              break;
            case 2:
              indicativeNumberList = elementResponse.value.map((indicativeNumber) => ({
                label: indicativeNumber.number,
                value: indicativeNumber.id
              }));

              if (!CustomerInformation?.telephoneCodeCustomer || !CustomerInformation?.whatsappCodeCustomer) {
                let setCodes = {};
                setCodes = {
                  telephoneCodeCustomer: !CustomerInformation?.telephoneCodeCustomer ? indicativeNumberList[0] : CustomerInformation.telephoneCodeCustomer,
                  whatsappCodeCustomer: !CustomerInformation?.whatsappCodeCustomer ? indicativeNumberList[0] : CustomerInformation.whatsappCodeCustomer
                };
                setDataSaleReducer("shippingsSales",setCodes)
                setValues({
                  ...CustomerInformation,
                  ...setCodes
                });
              }

              break;
            default:
              break;
          }
        }
      }
  
      if (CustomerInformation?.departmentResidenceCustomer?.value) {
        municipalityList = await getAllMunicipality(CustomerInformation?.departmentResidenceCustomer?.value);
      }

      setState({
        ...state,
        optionsTypeDocument: documentTypeList,
        optionsDepartment: departmentList,
        optionsNumberList: indicativeNumberList,
        optionsMunicipality: municipalityList
      });
    } catch {
      // TODO: Implement error alert with code error.
    }
  }; 

  
  const { values, errors, touched, handleBlur, setValues, handleSubmit, setFieldTouched } = useFormik({
    initialValues: {
      idCustomer: '',
      documentTypeCustomer: undefined,
      documentNumberCustomer: '',
      nameCustomer: '',
      lastNameCustomer: '',
      telephoneCustomer: '',
      telephoneCodeCustomer: undefined,
      whatsappCustomer: '',
      whatsappCodeCustomer: undefined,
      departmentResidenceCustomer: undefined,
      municipalityResidenceCustomer: undefined,
      contactAddressCustomer: '',
      contactEmailCustomer: ''
    },
    validationSchema: shippingsCustomerDataSchema,
    onSubmit: () => {},
  });
  
  useEffect(() => {
    setStateSaleReducer('isError', !!Object.keys(errors).length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const onChange = async (data, target) => {
    const value = data && data.target ? data.target.value || '' : data;
    const passengerData = { ...values, [target]: value };

    if (target === 'departmentResidenceCustomer') {
      const previousValueDepartment = values.departmentResidenceCustomer;
      passengerData.departmentResidenceCustomer = value;
      const municipalityList = await getAllMunicipality(value?.value);
      setState({
        ...state,
        optionsMunicipality: municipalityList
      })
      setFieldTouched("municipalityResidenceCustomer", true);

      if (previousValueDepartment !== value) {
        passengerData.municipalityResidenceCustomer = undefined;
      }
    }

    if (target === "contactEmailCustomer" && isElectronic) {
      setValues(passengerData);
      setDataSaleReducer("shippingsSales",{ contactEmailCustomer : value, isElectronic: false})
    }else{
      setValues(passengerData)
      setDataSalesStateReducer("shippingsSales",target, value)
    }
  };
  
  const getAllMunicipality = async (idDepartment) => {
    try {
      const res = await getAllMunicipalityService(idDepartment);
      return res.map((municipality) => ({ label: municipality.name, value: municipality.id }));
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const setPhoneWhatsApp = (value) => {
    setValues(prevValues => ({
      ...prevValues,
      ...value
    }));
    setDataSaleReducer("shippingsSales",value)
  }

  const selectFilter = async (options) =>{
    setState({...state,optionsUserSearch: []})
    const {
      id,
      department,
      documentType,
      indicativeNumber,
      indicativeNumberWhatsapp,
      lastName,
      municipality,
      name,
      documentNumber,
      numberPhone,
      numberPhoneWhatsapp,
      contactAddress,
      contactEmail
    } = options;

    const telephoneCodeCustomer = indicativeNumber?.number && indicativeNumber?.id
      ? { ...indicativeNumber, label: indicativeNumber.number, value: indicativeNumber.id }
      : optionsNumberList[0];

    const whatsappCodeCustomer = indicativeNumberWhatsapp?.number && indicativeNumberWhatsapp?.id
      ? { ...indicativeNumberWhatsapp, label: indicativeNumberWhatsapp.number, value: indicativeNumberWhatsapp.id }
      : optionsNumberList[0];

    const documentTypeCodeCustomer = documentType
    ? { label: documentType.name, value: documentType.id }
    : undefined;

    const municipalityResidenceCustomer = municipality
    ? { label: municipality.name, value: municipality.id }
    : undefined;

    const departmentResidenceCustomer = department
    ? { label: department.name, value: department.id }
    : undefined;

    if(departmentResidenceCustomer){
      getAllMunicipality(departmentResidenceCustomer?.value)
      .then((res)=>{
        setState({
          ...state,
          optionsMunicipality: res
        })
      })
      .catch(() => {
        // TODO: Implement error alert with code error.
      })
    }
    
    const value = {
      ...values,
      idCustomer: id,
      departmentResidenceCustomer,
      documentTypeCustomer: documentTypeCodeCustomer,
      telephoneCodeCustomer,
      whatsappCodeCustomer,
      municipalityResidenceCustomer,
      lastNameCustomer : lastName,
      nameCustomer : name,
      documentNumberCustomer : documentNumber,
      telephoneCustomer: numberPhone,
      contactAddressCustomer : contactAddress,
      contactEmailCustomer : contactEmail,
      whatsappCustomer: numberPhoneWhatsapp
    }

    value.originalClientDataCustomer = {...value}

    try {
      await setValues(value);
      setDataSaleReducer("shippingsSales", value);
      handleSubmit();
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const filterUser = async (data) => {
    try {
      const res = await getFilterUsersService(data);
      setState((prevState) => ({
        ...prevState,
        optionsUserSearch: res,
      }));
    } catch (error) {
      // TODO: Implement error alert with error code
    }
  };

  useEffect(() => {
    if (values.whatsappCustomer) {
      setFieldTouched("whatsappCustomer", true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.whatsappCustomer])

  const handleSetPhoneWhatsApp = () => {
    setPhoneWhatsApp({
      whatsappCustomer: values.telephoneCustomer,
      whatsappCodeCustomer: values.telephoneCodeCustomer
    });
  }
      
  const onHandlerOnSearch = (e) => {
    const trimmedValue = e.trim();
    if (trimmedValue) {
      filterUser(trimmedValue);
    } else {
      setState((prevState) => ({
        ...prevState,
        optionsUserSearch: []
      }));
    }
  };

  const debouncedHandleSearch = useMemo(
    () => debounce(onHandlerOnSearch, 390),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="grid-x">
      <div className="medium-6 customer-information__content flex-container align-middle">
        <label className="customer-information__title">
          {t("sales.passages.clientData.enterData")}
        </label>
      </div>
      <div className="medium-6 customer-information__content customer-information__content__right customer-information__hidden">
        <AutoComplete
          autoFocus
          options={optionsUserSearch}
          disabled={isDisabled}
          className="customer-information__label--mod"
          onSelect={(value,options)=>selectFilter(options)}
          onSearch={ debouncedHandleSearch }
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              const inputValue = e.target.value;
              const selectedOption = optionsUserSearch.find(option => option.documentNumber === inputValue);
              if (selectedOption) selectFilter(selectedOption);
              inputRefs[0].current.focus();
            }
          }}
        >
        <Input
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          className="customer-information__input__content"
          placeholder={t("sales.passages.clientData.searchName")}
          prefix={
            <img
              className="customer-information__input__search"
              src={Assets.SharedIcons.icon_search}
              alt="icon_search"
            />
          }
        />
        </AutoComplete>
      </div>
      <div className="small-12 medium-6 customer-information__content">
        <label className="grid-x customer-information__label">
          {t("sales.passages.clientData.typeDocument")}
          <div className="customer-information__label__icon" />
        </label>
        <Select
          ref={inputRefs[0]}
          disabled={isDisabled}
          value={values.documentTypeCustomer}
          className="customer-information__select"
          onChange={(e, event) => onChange(event, 'documentTypeCustomer')}
          onBlur={(value) => handleBlur('documentTypeCustomer')(value)}
          status={errors.documentTypeCustomer && touched.documentTypeCustomer ?"error":""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsTypeDocument}
          placeholder={t("sales.passages.clientData.selectTypeDocument")}
          onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
          onSelect={(value) => {
            if (isDropdownOpen) {
              inputRefs[1].current.focus();
            }
          }}
        />
        <span className="grid-x customer-information__errors">{touched.documentTypeCustomer? errors.documentTypeCustomer : null}</span>
      </div>
      <div className="small-12 medium-6 customer-information__content customer-information__content__right">
        <label className=" grid-x customer-information__label customer-information__label--mod">
          {t("sales.passages.clientData.documentNumber")} 
          <div className="customer-information__label__icon" />
        </label>
        <Input
          ref={inputRefs[1]}
          disabled={isDisabled}
          value={values.documentNumberCustomer}
          onChange={(value) => onChange(value, 'documentNumberCustomer')}
          onBlur={(value) => handleBlur('documentNumberCustomer')(value)}
          status={errors.documentNumberCustomer && touched.documentNumberCustomer ?"error" : ""}
          className="customer-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.documentNumberPlaceholder")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[2].current.focus();
            }
          }}
        />
        <span className="grid-x customer-information__errors">{touched.documentNumberCustomer ? errors.documentNumberCustomer : null}</span>
      </div>
      <div className="small-12 medium-6 customer-information__content">
        <label className=" grid-x customer-information__label">
          {t("sales.passages.clientData.clientName")} 
          <div className="customer-information__label__icon" />
        </label>
        <Input
          ref={inputRefs[2]}
          disabled={isDisabled}
          value={values.nameCustomer}
          onChange={(value) => onChange(value, 'nameCustomer')}
          onBlur={(value) => handleBlur('nameCustomer')(value)}
          status={errors.nameCustomer && touched.nameCustomer ? "error" : ""}
          className="customer-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterName")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[3].current.focus();
            }
          }}
        />
        <span className="grid-x customer-information__errors">{touched.nameCustomer ? errors.nameCustomer : null}</span>
      </div>
      <div className="small-12 medium-6 customer-information__content customer-information__content__right">
        <label className=" grid-x customer-information__label customer-information__label--mod">
          {t("sales.passages.clientData.clientLastName")} 
          <div className="customer-information__label__icon" />
        </label>
        <Input
          ref={inputRefs[3]}
          disabled={isDisabled}
          value={values.lastNameCustomer}
          onChange={(value) => onChange(value, 'lastNameCustomer')}
          onBlur={(value) => handleBlur('lastNameCustomer')(value)}
          status={errors.lastNameCustomer && touched.lastNameCustomer ? "error" : ""}
          className="customer-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterLastName")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[4].current.focus();
            }
          }}
        />
        <span className="grid-x customer-information__errors">{touched.lastNameCustomer? errors.lastNameCustomer : null}</span>
      </div>
      <div className="small-12 medium-6 customer-information__content">
        <label className=" grid-x customer-information__label">
          {t("sales.passages.clientData.contactTelephone")} 
          <div className="customer-information__label__icon" />
        </label>
        <div className="grid-x customer-information__label--mod justify-content-between">
          <Select
            ref={inputRefs[4]}
            disabled={isDisabled}
            value={values.telephoneCodeCustomer}
            onChange={(e,value) => onChange(value, 'telephoneCodeCustomer')}
            onBlur={(value) => handleBlur('telephoneCodeCustomer')(value)}
            status={errors.telephoneCodeCustomer && touched.telephoneCodeCustomer ? "error" : ""}
            className="customer-information__select indicative-number"
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsNumberList}
            onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
            onSelect={(value) => {
              if (isDropdownOpen) {
                inputRefs[5].current.focus();
              }
            }}
          />
          <Input
            ref={inputRefs[5]}
            disabled={isDisabled}
            value={values.telephoneCustomer}
            onChange={(value) => onChange(value, 'telephoneCustomer')}
            onBlur={(value) => handleBlur('telephoneCustomer')(value)}
            status={errors.telephoneCustomer && touched.telephoneCustomer ? "error" : ""}
            className="customer-information__input__content phone-number"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={t("sales.passages.clientData.enterDigits")} 
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSetPhoneWhatsApp();
                inputRefs[6].current.focus();
              }
            }}
          />
        </div>
        <span className="grid-x customer-information__errors">{touched.telephoneCustomer? errors.telephoneCustomer : null}</span>
      </div>
      <div className="small-12 medium-6 customer-information__content customer-information__content__right">
        <div className="grid-x justify-content-between customer-information__label--mod">
          <label className=" grid-x customer-information__label">
            {t("sales.passages.clientData.whatsapp")}  
          </label>
        </div>
        <div className="grid-x customer-information__label--mod justify-content-between">
          <Select
            disabled={isDisabled}
            value={values.whatsappCodeCustomer}
            onChange={(e,value) => onChange(value, 'whatsappCodeCustomer')}
            onBlur={(value) => handleBlur('whatsappCodeCustomer')(value)}
              className="customer-information__select indicative-number"
              suffixIcon={
                <img
                  src={Assets.SharedIcons.icon_down_arrow}
                  alt="icon_down_arrow"
                />
              }
              options={optionsNumberList}
              onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
            />
          <Input
            ref={inputRefs[6]}
            disabled={isDisabled}
            value={values.whatsappCustomer}
            onChange={(value) => onChange(value, 'whatsappCustomer')}
            onBlur={(value) => handleBlur('whatsappCustomer')(value)}
            className="customer-information__input__content phone-number"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={t("sales.passages.clientData.digitsWhatsApp")}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                inputRefs[7].current.focus();
              }
            }}
          />
        </div>
      </div>
      <div className="small-12 medium-6 customer-information__content">
        <label className="customer-information__label customer-information__label--mod">
          {t("sales.passages.clientData.residenceDepartment")}
        </label>
        <Select
          ref={inputRefs[7]}
          disabled={isDisabled}
          value={values.departmentResidenceCustomer}
          status={errors.departmentResidenceCustomer && touched.departmentResidenceCustomer ? "error" : ""}
          onBlur={(value) => handleBlur('departmentResidenceCustomer')(value)}
          onChange={(e, event) => onChange(event, 'departmentResidenceCustomer')}
          className="customer-information__select"
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsDepartment}
          placeholder={t("sales.passages.clientData.selectDepartment")}
          onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
          onSelect={(value) => {
            if (isDropdownOpen) {
              setTimeout(() => {
                inputRefs[8].current.focus();
              }, 100);
            }
          }}
        />
      </div>
      <div className="small-12 medium-6 customer-information__content customer-information__content__right">
        <label className="customer-information__label customer-information__label--mod">
          {t("sales.passages.clientData.municipalityResidence")}
        </label>
        <Select
          ref={inputRefs[8]}
          value={values.municipalityResidenceCustomer}
          status={errors.municipalityResidenceCustomer && touched.municipalityResidenceCustomer ? "error" : ""}
          onBlur={(value) => handleBlur('municipalityResidenceCustomer')(value)}
          onChange={(e, event) => onChange(event, 'municipalityResidenceCustomer')}
          className="customer-information__select"
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          disabled={!values?.departmentResidenceCustomer || isDisabled}
          options={optionsMunicipality}
          placeholder={t("sales.passages.clientData.selectMunicipality")}
          onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
          onSelect={(value) => {
            setTimeout(() => {
              if (isDropdownOpen) {
                nextButtonRef.current.focus();
              }
            }, 100);
          }}
        />
        <span className="grid-x customer-information__errors">{touched.municipalityResidenceCustomer? errors.municipalityResidenceCustomer : null}</span>
      </div>
      <div className="small-12 medium-6 customer-information__content">
        <label className=" customer-information__label">
          {t("sales.passages.clientData.contactAddress")}
        </label>
        <Input
          disabled={isDisabled}
          value={values.contactAddressCustomer}
          onChange={(value) => onChange(value, 'contactAddressCustomer')}
          onBlur={(value) => handleBlur('contactAddressCustomer')(value)}
          status={errors.contactAddressCustomer && touched.contactAddressCustomer ? "error" : ""}
          className="customer-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.writeContact")}
        />
        <span className="grid-x customer-information__errors">{touched.contactAddressCustomer ? errors.contactAddressCustomer : null}</span>
      </div>
      <div className="small-12 medium-6 customer-information__content customer-information__content__right">
        <label className="customer-information__label customer-information__label--mod">
          {t("sales.passages.clientData.contactEmail")}
        </label>
        <Input
          disabled={isDisabled}
          value={values.contactEmailCustomer}
          onChange={(value) => onChange(value, 'contactEmailCustomer')}
          onBlur={(value) => handleBlur('contactEmailCustomer')(value)}
          status={errors.contactEmailCustomer && touched.contactEmailCustomer ? "error" : ""}
          className="customer-information__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterEmail")}
        />
        <span className="grid-x customer-information__errors">{touched.contactEmailCustomer? errors.contactEmailCustomer : null}</span>
      </div>
    </div>
  );
}


const mapStateToProps = ({ SalesReducer }) => {
  const {
    shippingsSales: {
      idCustomer,
      documentTypeCustomer,
      documentNumberCustomer,
      nameCustomer,
      lastNameCustomer,
      telephoneCustomer,
      telephoneCodeCustomer,
      whatsappCustomer,
      whatsappCodeCustomer,
      departmentResidenceCustomer,
      municipalityResidenceCustomer,
      contactAddressCustomer,
      contactEmailCustomer,
      isElectronic
    },
    isDisabled
  } = SalesReducer;
  return {
    idCustomer,
    documentTypeCustomer,
    documentNumberCustomer,
    nameCustomer,
    lastNameCustomer,
    telephoneCustomer,
    telephoneCodeCustomer,
    whatsappCustomer,
    whatsappCodeCustomer,
    departmentResidenceCustomer,
    municipalityResidenceCustomer,
    contactAddressCustomer,
    contactEmailCustomer,
    isDisabled,
    isElectronic
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setStateSaleReducer,
  setDataSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(CustomerInformationComponent);