import { StyleSheet } from "@react-pdf/renderer";

export const PdfVehiclesStyles = StyleSheet.create({
    page: {
        width: "100%",
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
    },

    section: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    content: {
        width: '90%',
        height: '90%',
        borderWidth: '1px',
        alignItems: "center",
        borderColor: '#C7C7C7'
    },

    logo: {
        width: "152px",
        height: "32px",
        marginVertical: "30px"

    },

    content_info: {
        width: '100%',
        height: '100%',
        paddingTop: '20px',
        alignItems: "center",
        paddingHorizontal: '20px'
    },

    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginVertical: '40px',
        fontFamily: "Helvetica-Bold"
    },


    subtitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: "Helvetica-Bold"
    },

    description: {
        fontSize: "14px",
        color: "#2C2C2C",
        marginBottom: '10px'
    }
})