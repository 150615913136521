export const BoardingSheetErrorsConst = {
  passages: {
    TRVL003: "Licencia de conducción ha expirado",
    TRVL005: "SOAT del vehículo ha expirado",
    TRVL004: "revisión técnico-mecánica del vehículo ha expirado",
    TRVL030: "Conductor asociado al vehículo no fue encontrado (TRVL030)",
    TRVL026: "El viaje asignado tiene un horario más corto que el viaje actual, por favor coloque un viaje que salga a la misma hora o más tarde para hacer el intercambio.",
    TRVL055: "El seguro civil ha expirado",
    TRVL056: "La tarjeta de operación está caducada",
    TRVL057: "Ya existen planillas contabilizadas dentro del rango de fechas solicitado",
    TRVL020: "No se puede contabilizar planillas que contengan fechas del día operativo actual",
  },
  delete: {
    TRVL057: "La planilla ya fue contabilizada",
  },
  localError: {
    resultEmpty: "No se encontraron resultados",
  },
  default: "Ocurrió un error",
};