//Assets
import { Assets } from '../../../assets'

//Components
import InfoToastComponent from '../../../shared/components/toast/info-toast/info-toast.component'
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component'
import SuccessToastComponent from '../../../shared/components/toast/success-toast/success-toast.component'

//Constants
import AlertConst from '../../../core/constants/alerts.const'
import { SettingsErrorsConst } from '../../../core/constants/errors/alerts-app/seettings.errors.const'

//Libraries
import { Input, Select } from 'antd'
import React, { useState } from 'react'

//Services
import { getTextSearchInvoicesService, updateTypePaymentService } from '../../../services/settings.services'
import { getAllPaymentMethodService } from '../../../services/shared.services'

//Styles
import './invoice-management.page.scss'

export const InvoiceManagementPage = () => {

    const INITIAL_STATE = {
        textSearch: '',
        isClear: false,
        isForm: false,
        selectTypePayment: null,
        selectTypePaymentCopy: null,
        optionPaymentType: [],
        idInvoice: null
    }
    const [state, setState] = useState(INITIAL_STATE)
    const {
        textSearch,
        isClear,
        isForm,
        selectTypePayment,
        selectTypePaymentCopy,
        optionPaymentType,
        idInvoice
    } = state

    const onChange = (data, target) => {
        const value = data && data.target ? data.target.value : data;
        setState((prevState) => ({
            ...prevState,
            [target]: value,
        }));
    };

    const handleTextSearch = async (valueFilter) => {
        try {
            const respPaymentOptions = await getAllPaymentMethodService();
            const allPaymentOptions = respPaymentOptions;
    
            const optionPaymentType = allPaymentOptions
                .filter(item => ['Transferencia', 'Efectivo', 'Crédito'].includes(item.name))
                .map(({ name, id }) => ({ label: name, value: id }));
    
            const optionShippingType = allPaymentOptions
                .filter(item => ['Efectivo', 'Contraentrega'].includes(item.name))
                .map(({ name, id }) => ({ label: name, value: id }));
    
            const optionServiceType = allPaymentOptions
                .filter(item => ['Efectivo'].includes(item.name))
                .map(({ name, id }) => ({ label: name, value: id }));
    
            const resp = await getTextSearchInvoicesService(valueFilter);
    
            if (!resp || !resp.paymentMethod) {
                InfoToastComponent({
                    html: `<span>No se encontraron coincidencias para la búsqueda.</span>`,
                    position: AlertConst.TOP_END_POSITION_TEXT,
                    timer: 1500
                });
                return;
            }

            const paymentMethod = resp.paymentMethod;
            const serviceType = resp.serviceType;
    
            let selectedPaymentOptions;
    
            switch (serviceType) {
                case 1:
                    selectedPaymentOptions = optionServiceType;
                    break;
                case 2:
                    selectedPaymentOptions = optionPaymentType;
                    break;
                case 3:
                    selectedPaymentOptions = optionShippingType;
                    break;
                default:
                    selectedPaymentOptions = [];
            }
    
            const data = {
                label: paymentMethod.name,
                value: paymentMethod.id
            };
    
            setState((prevState) => ({
                ...prevState,
                isClear: true,
                isForm: true,
                selectTypePayment: data,
                selectTypePaymentCopy: data,
                idInvoice: resp.id,
                optionPaymentType: selectedPaymentOptions
            }));
        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.constants[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
        }
    };

    const handlerClearFilterParams = () => {
        setState((prevState) => ({
            ...prevState,
            textSearch: '',
            isClear: false,
            isForm: false,
            selectTypePayment: null,
            selectTypePaymentCopy: null
        }))
    };

    const saveTypePayment = async () => {
        try {
            const resp = await updateTypePaymentService({
                idInvoice: idInvoice,
                idPaymentMethod: selectTypePayment.value
            })

            let message = "La factura fue actualizada con exito."
            if (resp?.message) message = resp.message?.message || '** Esta factura ya ha sido sincronizada con Clarisa. Por favor, notifique a Clarisa sobre cualquier modificación.'
            SuccessToastComponent({
                html:`
                <span>
                    <span>${message}</span>
                </span>`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 5000
            });
            handlerClearFilterParams()
        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.invoice[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 3000
            });
        }
    };

    return (
        <div className='invoice-management__container'>
            <div className="grid-x invoice-management__input">
                <Input
                    allowClear={{
                        clearIcon: (
                            <img
                                width={28}
                                src={Assets.SharedIcons.icon_square_close}
                                alt="icon_logo_vertical"
                            />
                        )
                    }}
                    onChange={(value) => onChange(value, 'textSearch')}
                    value={textSearch}
                    className="invoice-management__input__content"
                    placeholder="Buscar Factura Ej. FV-ETC-00000001"
                    prefix={
                        <img
                            className="invoice-management__input__search"
                            src={Assets.SharedIcons.icon_search}
                            alt="icon_search"
                        />
                    }
                />
                <button
                    disabled={!textSearch}
                    className='invoice-management__button'
                    onClick={() => handleTextSearch(textSearch)}
                >
                    <span className='invoice-management__button__text'>Buscar</span>
                </button>
                {isClear && (
                    <button
                        className='invoice-management__clear'
                        onClick={() => handlerClearFilterParams()}
                    >
                        <img
                            src={Assets.SalesIcons.icon_clear}
                            alt="icon_clear"
                        />
                    </button>
                )}
            </div>

            {isForm && (
                <div className='grid-y align-justify invoice-management__payment_type'>
                    <div className='invoice-management__select__container'>
                        <Select
                            value={selectTypePayment}
                            onChange={(e, event) => {
                                setState((prevState) => ({
                                    ...prevState,
                                    selectTypePayment: event
                                }))
                            }}
                            placeholder="Seleccione el tipo de pago"
                            className="invoice-management__select"
                            options={optionPaymentType}
                            suffixIcon={
                                <img
                                    src={Assets.SharedIcons.icon_down_arrow}
                                    alt="icon_down_arrow"
                                />
                            }
                        />
                    </div>
                    <div className='grid-x align-right'>
                        <button
                            disabled={JSON.stringify(selectTypePayment) === JSON.stringify(selectTypePaymentCopy)}
                            className='invoice-management__button'
                            onClick={() => saveTypePayment()}
                        >
                            <span className='invoice-management__button__text'>Guardar</span>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}