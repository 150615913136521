// Actions
import { setStateAppReducer } from "../../storage/reducers/app/app.actions";
import { setSeatsToAvailableReducer, setSeatsChangedToSelectedReducer } from "../../storage/reducers/sales/sales.action";

// Libraries
import socketIOClient from 'socket.io-client'

// Store
import store from "./../../storage/store/configure-store.prod.store";
class SocketConnection {
    constructor() {
        this.url = null;
        this.socket = null;
    }

    connect(url) {
        this.url = url;
        this.socket = socketIOClient(url);

        this.socket.on('connect', () => {
            console.log(`Connected to ${this.url}`);
        });
        
        this.socket.on('disconnect', () => {
            console.log(`Disconnected from ${this.url}`);
            this.socket = null;
        });

        this.socket.on('error', (error) => {
            console.error(`Socket connection error on ${this.url}:`, error);
            this.disconnect();
        });

        this.socket.on('@@passage/SEATS_CHANGED_TO_SELECTED', (data) => {
            console.log(`Received message from server ${this.url} SEATS_CHANGED_TO_SELECTED:`, data);
            store.dispatch(setSeatsChangedToSelectedReducer(data));
        });
        
        this.socket.on('@@passage/SEATS_CHANGED_TO_AVAILABLE', (data) => {
            console.log(`Received message from server ${this.url} SEATS_CHANGED_TO_AVAILABLE:`, data);
            store.dispatch(setSeatsToAvailableReducer(data));
        });

        this.socket.on('statusConnection', (data) => {
            console.log('statusConnection ===>', data);
        });

        this.socket.on('isSatisfactoryImpression', (data) => {
            if (!data) {
                store.dispatch(setStateAppReducer('isErrorPrintTicket', true));
            }
            this.disconnect()
        });

        this.socket.on("connect_error", (err) => {
            this.disconnect()
        });
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            console.log(`Disconnected from ${this.url}`);
            this.socket = null;
        }
    }

    printTickets(data) {
        this.socket.emit('print', data);
    }
}

export default SocketConnection;