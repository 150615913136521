// Constants - Endpoints
import DriversEndPoints from "./constants/drivers.end-points";

// Infrastructure
import Http from "./infrastructure/http.infrastructure";

// Services
import { getTokenService } from "./authentication.services";

export async function getAllDriversService(offset) {
    try {
        const { data: responseGetAllDrivers, error } = await Http.get(DriversEndPoints.GET_ALL_DRIVERS, { offset })
        if (responseGetAllDrivers && responseGetAllDrivers.status) {
            return responseGetAllDrivers.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllDriversPaginationService(valueFilter = "", sortOrderType, page) {
    try {
        let token = getTokenService()
        const { data: responseGetAllDriversPagination, error } = await Http.get(DriversEndPoints.GET_ALL_DRIVERS_PAGINATION, { valueFilter: valueFilter, sortOrderType, page }, token)
        if (responseGetAllDriversPagination && responseGetAllDriversPagination.status) {
            return responseGetAllDriversPagination.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllDriversPaginationCounterService(valueFilter = "", sortOrderType) {
    try {
        let token = getTokenService();
        const { data: responseGetAllDriverPagination, error } = await Http.get(DriversEndPoints.GET_ALL_DRIVERS_PAGINATION, { valueFilter: valueFilter, sortOrderType }, token)
        if (responseGetAllDriverPagination && responseGetAllDriverPagination.status) {
            return responseGetAllDriverPagination.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}


export async function getDriverByIdService(id) {
    let token = getTokenService();
    try {
        const urlWithParams = DriversEndPoints.GET_DRIVER_BY_ID.replace(":id", id)
        const { data: responseGetDriverById, error } = await Http.get(urlWithParams, null, token)
        if (responseGetDriverById && responseGetDriverById.status) {
            const updatedResponse = {
                ...responseGetDriverById,
                data: {
                    ...responseGetDriverById?.data,
                    documentType: {
                        label: responseGetDriverById?.data?.documentType?.name,
                        value: responseGetDriverById?.data?.documentType?.id
                    },
                    countryBirth: {
                        label: responseGetDriverById?.data?.countryBirth?.name,
                        value: responseGetDriverById?.data?.countryBirth?.id
                    },
                    departmentBirth: {
                        label: responseGetDriverById?.data?.departmentBirth?.name,
                        value: responseGetDriverById?.data?.departmentBirth?.id
                    },
                    municipalityBirth: {
                        label: responseGetDriverById?.data?.municipalityBirth?.name,
                        value: responseGetDriverById?.data?.municipalityBirth?.id
                    },
                    bloodType: {
                        label: responseGetDriverById?.data?.bloodType?.name,
                        value: responseGetDriverById?.data?.bloodType?.id
                    },
                    indicativePhone: {
                        label: responseGetDriverById?.data?.indicativePhone?.number,
                        value: responseGetDriverById?.data?.indicativePhone?.id
                    },
                    departmentResidence: {
                        label: responseGetDriverById?.data?.departmentResidence?.name,
                        value: responseGetDriverById?.data?.departmentResidence?.id
                    },
                    municipalityResidence: {
                        label: responseGetDriverById?.data?.municipalityResidence?.name,
                        value: responseGetDriverById?.data?.municipalityResidence?.id
                    },
                    licenseCategory: {
                        label: responseGetDriverById?.data?.licenseCategory?.name,
                        value: responseGetDriverById?.data?.licenseCategory?.id
                    }
                }
            };
            return updatedResponse.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAuthorizedCategoriesService() {
    try {
        const { data: responseGetAuthorizedCategories, error } = await Http.get(DriversEndPoints.GET_AUTHORIZED_CATEGORIES)
        if (responseGetAuthorizedCategories && responseGetAuthorizedCategories.status) {
            const result = responseGetAuthorizedCategories.data.map((authorizedCategories) => ({
                label: authorizedCategories?.name,
                value: authorizedCategories?.id
            }))
            return result
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllCountriesService() {
    try {
        const { data: responseGetAllCountries, error } = await Http.get(DriversEndPoints.GET_ALL_COUNTRIES)
        if (responseGetAllCountries && responseGetAllCountries.status) {
            return responseGetAllCountries.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllBloodTypesService() {
    try {
        const { data: responseGetAllBloodTypes, error } = await Http.get(DriversEndPoints.GET_ALL_BLOOD_TYPES)
        if (responseGetAllBloodTypes && responseGetAllBloodTypes.status) {
            return responseGetAllBloodTypes.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getDriverAvailableWithVehicleService() {
    try {
        let token = getTokenService();
        const { data: getDriverAvailableWithVehicle, error } = await Http.get(DriversEndPoints.GET_DRIVER_AVAILABLE_WITH_VEHICLE, null, token)
        if (getDriverAvailableWithVehicle && getDriverAvailableWithVehicle.status) {
            const getDriverAvailable = getDriverAvailableWithVehicle.data.map((item) => ({
                ...item,
                label: `${item?.name} ${item?.lastName}`,
                value: item?.idDriverVehicle
            }))
            return getDriverAvailable
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function createDriverService(data) {
    
    try {
        let token = getTokenService();
        const { data: responseCreateDriver, error } = await Http.post(DriversEndPoints.CREATE_DRIVER, data, token)
        if (responseCreateDriver && responseCreateDriver.status) {
            return responseCreateDriver.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function updateByIdDriverService (id, data) {
    try {
        let token = getTokenService();
        const urlWithParams = DriversEndPoints.UPDATE_BY_ID_DRIVER.replace(":id", id)
        const { data: responseUpdateDriver, error } = await Http.patch(urlWithParams, data, token)
        if (responseUpdateDriver && responseUpdateDriver.status) {
            return responseUpdateDriver.data
        } else {
            throw error
        }
        
    } catch (err) {
        throw err
    }
}

export async function addDriverToVehicleService(body) {
    
    try {
        const { data: addDriverToVehicle, error } = await Http.post(DriversEndPoints.ASSIGN_DRIVER_AVAILABLE, body)
        if (addDriverToVehicle && addDriverToVehicle.status) {
            return addDriverToVehicle.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function assignDriverAvailableService(body) {
    try {
        const { data: assignDriverAvailable, error } = await Http.post(DriversEndPoints.ASSIGN_DRIVER_AVAILABLE, body)
        if (assignDriverAvailable && assignDriverAvailable.status) {
            return assignDriverAvailable.data
            
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getFilterVehicleAvailableService({ valueFilter }) {
    try {
        const { data: responseGetFilterVehicle, error } = await Http.get(DriversEndPoints.FILTER_ONE_SEARCH_VEHICLE, { valueFilter })
        if (responseGetFilterVehicle && responseGetFilterVehicle.status) {
            return responseGetFilterVehicle.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}
export async function getDriverFilterService(valueFilter = "", sortOrderType) {
    try {
        const { data: responseGetDriverFilter, error } = await Http.get(DriversEndPoints.GET_FILTER_DRIVERS, {valueFilter: valueFilter, sortOrderType})
        if (responseGetDriverFilter && responseGetDriverFilter.status) {
            return responseGetDriverFilter?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function unlinkedDriverService(id) {
    try {
        const urlWithParams = DriversEndPoints.UNLINKED_DRIVER.replace(":id", id)
        const { data: unlinkedDriver, error } = await Http.patch(urlWithParams)
        if (unlinkedDriver && unlinkedDriver.status) {
            return unlinkedDriver.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};