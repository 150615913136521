import * as salesTypes from './sales.types';

export const setStateSaleReducer = (prop,value) => (dispatch) => {
	dispatch({ type: salesTypes.SET_SALES, payload: { prop,value } });
};

export const setDataSalesStateReducer = (key, prop, value) => (dispatch) => {
	dispatch({ type: salesTypes.SET_DATA_SALES_STATE, payload: { key, prop, value } });
};

export const setDataSaleReducer = (key,value) => (dispatch) => {
	dispatch({ type: salesTypes.SET_DATA_SALES, payload: { key,value } });
};

export const resetDataSaleReducer = () => (dispatch) => {
	dispatch({ type: salesTypes.RESET_DATA_SALES });
};

export const resetDataSaleLogoutReducer = () => (dispatch) => {
	dispatch({ type: salesTypes.RESET_DATA_SALES_LOGOUT });
};

export const setSeatsToAvailableReducer = (value) => (dispatch) => {
	dispatch({ type: salesTypes.SET_SEATS_TO_AVAILABLE, payload: value });
};

export const setSeatsChangedToSelectedReducer = (value) => (dispatch) => {
	dispatch({ type: salesTypes.SET_SEATS_CHANGED_TO_SELECTED, payload: value });
};