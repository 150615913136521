//Assets
import { Assets } from '../../../../assets';

//Constants
import BoardingSheetConst from '../../../../core/constants/boarding-sheet.const';

//Libraries
import React, { useEffect, useState } from 'react'
import { Select, Input, Checkbox, DatePicker } from 'antd';

//Services
import { getDriverAvailableWithVehicleService } from '../../../../services/drivers.services';
import { getHeadquarterAssociatedService, getPayrollProcessToZeroService } from '../../../../services/boarding-sheet.services';
import {getOrderWithGuideService} from '../../../../services/shippings.services';

//Styles
import "./modal-generate.component.scss"
import dayjs from 'dayjs';

const ModalGenerateComponent = (props) => {

  const { 
    itemSelected, 
    driverSelected,
    setDriverSelected = () => {},
    setDataGeneratePayroll = () => {},
    selectedShipments,
    setSelectedShipments = () => {},
    setDaySelected = () => {},
    daySelected
  } = props;

  useEffect(() => {
    if (!itemSelected?.driver?.isDriverDefault) {
      setDriverSelected({
        label: `${itemSelected?.driver?.name || ''} ${itemSelected?.driver?.lastName || ''}`,
        value: itemSelected?.driver?.id
      })
    }
     getOptionsDriver()
     getHeadquarterAssociated()
     getOptionsGuide ()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getHeadquarterAssociated = async() => {
    try {
      const {name} = await getHeadquarterAssociatedService();
      setState((prevState) => ({
        ...prevState,
        headquarterAssociated: name
      }))
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const getOptionsDriver = async() => {
    try {
      const optionsDriver = await getDriverAvailableWithVehicleService();
      setState((prevState) => ({
        ...prevState,
        optionsDriver: optionsDriver
      }))
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const getOptionsGuide= async() => {
    try {
      const optionsGuide = await getOrderWithGuideService();
      setState((prevState) => ({
        ...prevState,
        optionsShipping: optionsGuide
      }))
    } catch (error) {
    }
  }

  const handleSelectAll = (e) => {
    let value = e.target.checked;
    let options = [...optionsShipping];
    let checks = [];

    options.forEach((option) => {
      if (value) {
        option.checked = true;
        checks.push(option);
      } else {
        option.checked = false;
      }
    });

    setSelectedShipments(checks);

  };

  const INITIAL_STATE = {
    optionsDriver: [],
    headquarterAssociated: "",
    optionsShipping: []
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { 
    optionsDriver, 
    headquarterAssociated, 
    optionsShipping
  } = state;

  const handleChange = (e,selected) => {
  let options = [...optionsShipping]
  let checks = []

  options.forEach((option) => {
      if (selected.some((select) => select.id === option.id)) {
        option.checked = true;
        checks.push(option)
      } else {
        option.checked = false;
      }
    });
    setSelectedShipments (selected)
  };

  const getPayrollProcessToZero = async (idTravel) => {
    const dataCurrentManifest = {
      id:idTravel,
      contributions: itemSelected.travel.contributions,
      cucutaContributions: itemSelected.travel.cucutaContributions,
      extraContributions: itemSelected.travel.extraContributions,
      loanPayment: itemSelected.travel.loanPayment,
      otherIncome: itemSelected.travel.otherIncome,
    }
    const { suggestedInsuranceValue } = await getPayrollProcessToZeroService(dataCurrentManifest);

    setDataGeneratePayroll({...itemSelected?.travel,
      socialSecurity: suggestedInsuranceValue,
      insuranceFund: suggestedInsuranceValue
    })
  }

  return (
    <>
      <span className='grid-x text-center modal-generate__title'>
        Vas a generar una planilla para el vehículo con la siguiente información:
      </span>
      <div className='grid-x'>
        <div className='small-12'>
          <label 
            className='modal-generate__subtitle'
            style={{ marginBottom: "10px" }}
          >
            Seleciona la fecha de generación
          </label>
          <div className='grid-x align-center-middle'>
            <DatePicker
              onChange={(dateSelected) => {
                setDaySelected(dateSelected)
              }}
              className="cash-closing__date small-8"
              format={"DD/MM/YYYY"}
              value={daySelected ? dayjs(daySelected, 'DD:MM:YYYY') : null}
              allowClear={false}
              style={{ marginBottom: "10px" }}
              popupClassName="cash-closing__date__picker"
              placeholder="DD/MM/AAAA"
              suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
              superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
              superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
              nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
              prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
            />
          </div>
        </div>
        <span className='small-6 modal-generate__subtitle'>Código del vehículo</span>
        <span className='small-6 text-right modal-generate__text'>{itemSelected?.vehicle?.plate}</span>
        <span className='small-6 modal-generate__subtitle'>Nro. del vehículo</span>
        <span className='small-6 text-right modal-generate__text'>{itemSelected?.vehicle?.internalNumber}</span>
        <span className='small-6 modal-generate__subtitle'>Pasajes vendidos</span>
        <span className='small-6 text-right modal-generate__text'>{itemSelected?.travel?.ticketsSales}/{itemSelected?.travel?.totalSeat}</span>
        <span className='small-6 modal-generate__subtitle'>Encomiendas</span>
        <span className='small-6 text-right modal-generate__text'>{itemSelected?.travel?.totalShipping}</span>

        <div className='small-12 grid-x '>
          <div className='small-6'>
            <label className='modal-generate__subtitle'>Planilla a cero</label>
          </div>
          <div className='small-6 text-right modal-generate__content-check__check'>
              <button
                onClick={() => getPayrollProcessToZero(itemSelected?.travel?.id)}
                className='modal-generate__modal__buttons'
              >
                <img
                  src={Assets.SharedIcons.icon_format}
                  alt="icon_format"
                />
              </button>
          </div>
        </div>

        <div className='small-12 grid-x '>
          <div className='small-6'>
            <label className='modal-generate__subtitle'>Vincular encomiendas no asociadas</label>
          </div>
          <div className='small-6 text-right modal-generate__content-check__check'>
            {optionsShipping.length !== 0 &&(
              <Checkbox
                onChange={(e) => handleSelectAll(e)}
              >Todas
              </Checkbox>
            )}
          </div>
          <Select
            mode="multiple"
            optionLabelProp="label"
            placeholder="Encomiendas no asociadas"
            value={selectedShipments}
            onChange={handleChange}

            // value={setState?.filter(object => object?.checked === true)?.map(object => object?.id)}

            className="modal-generate__select"
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
            menuItemSelectedIcon={() => <></>}
            showSearch={true}
            removeIcon={() => {
              return (
                <img
                  src={Assets.SharedIcons.icon_close} alt="icon_square_close" />
              )
            }}

            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {optionsShipping?.map((item, index) => {

              return (
                <Select.Option
                  key={index}
                  id={item?.id}
                  label={item?.label}
                  value={item?.id}
                // disabled={item?.state !== 0 || (values?.assignedSeats?.length === values?.numberOfSeats && !item?.checked)}
                >
                  <div className="grid-x justify-content-between">
                    <div>
                      <Checkbox
                        checked={item?.checked}
                        className="trip-data__check"
                      // disabled={item?.state !== 0 || (values?.assignedSeats?.length === values?.numberOfSeats && !item?.checked)}
                      />
                      {item?.label}
                    </div>
                  </div>
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className='small-12'>
          <p className='modal-generate__subtitle'>Conductor</p>
          <Select
            showSearch
            optionLabelProp="label"
            value={driverSelected}
            onChange={(i, value) => setDriverSelected(value)}
            className="modal-generate__select"
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
            options={optionsDriver}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            placeholder="Seleccione un conductor"
          />
        </div>
        <span className='small-6 modal-generate__subtitle'>Abonos Prest</span>
        <Input
          value={itemSelected?.travel?.loanPayment?.toLocaleString()}
          onChange={(e) => setDataGeneratePayroll({...itemSelected?.travel,loanPayment: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
          className="small-6 modal-generate__input__content"
          onBlur={() => !itemSelected?.travel?.loanPayment && setDataGeneratePayroll({...itemSelected?.travel,loanPayment: "0"})}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
        />
        { BoardingSheetConst.TIBU === headquarterAssociated &&
          <>
            <span className='small-6 modal-generate__subtitle'>Aportes</span>
            <Input
              value={itemSelected?.travel?.contributions?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected?.travel,contributions: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.travel?.contributions && setDataGeneratePayroll({...itemSelected?.travel,contributions: "0"})}
              className="small-6 modal-generate__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
          </>
        }
        { BoardingSheetConst.CUCUTA === headquarterAssociated &&
          <>
            <span className='small-6 modal-generate__subtitle'>Seg Social</span>
            <Input
              value={itemSelected?.travel?.socialSecurity?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected?.travel,socialSecurity: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.travel?.socialSecurity && setDataGeneratePayroll({...itemSelected?.travel,socialSecurity: "0"})}
              className="small-6 modal-generate__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
          </>
        }
        { BoardingSheetConst.TIBU === headquarterAssociated &&
          <>
            <span className='small-6 modal-generate__subtitle'>Fondo Seg</span>
            <Input
              value={itemSelected?.travel?.insuranceFund?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected?.travel,insuranceFund: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.travel?.insuranceFund && setDataGeneratePayroll({...itemSelected?.travel,insuranceFund: "0"})}
              className="small-6 modal-generate__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
          </>
        }
        { BoardingSheetConst.CUCUTA === headquarterAssociated && 
          <>
            <span className='small-6 modal-generate__subtitle'>Otros Ingre</span>
            <Input
              value={itemSelected?.travel?.otherIncome?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected?.travel,otherIncome: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.travel?.otherIncome && setDataGeneratePayroll({...itemSelected?.travel,otherIncome: "0"})}
              className="small-6 modal-generate__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
            <span className='small-6 modal-generate__subtitle'>Aportes C</span>
            <Input
              value={itemSelected?.travel?.cucutaContributions?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected?.travel,cucutaContributions: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.travel?.cucutaContributions && setDataGeneratePayroll({...itemSelected?.travel,cucutaContributions: "0"})}
              className="small-6 modal-generate__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
          </>
        }
        
        <span className='small-6 modal-generate__subtitle'>Aportes Ex</span>
        <Input
          value={itemSelected?.travel?.extraContributions?.toLocaleString()}
          onChange={(e) => setDataGeneratePayroll({...itemSelected?.travel,extraContributions: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
          onBlur={() => !itemSelected?.travel?.extraContributions && setDataGeneratePayroll({...itemSelected?.travel,extraContributions: "0"})}
          className="small-6 modal-generate__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
        />
      </div>
    </>
  )
}

export default ModalGenerateComponent