//Actions
import { setStateSidesNavReducer } from "../../../../storage/reducers/sides-nav/sides-nav.actions";

//Components
import SidebarInfoComponent from "./components/sidebar-info/sidebar-info.component";

//Libraries
import { Drawer, SwipeableDrawer } from "@mui/material";
import React from "react";
import { connect } from "react-redux";

//Styles
import "./sidebar-main.component.scss"

const SidebarMainComponent = (props) => {
    const {
        //Actions
        setStateSidesNavReducer,
        //Variables
        innerWidth,
        isDrawer,
      } = props;

  return (
    <>
      {innerWidth > 639 ? (
        <Drawer
          variant="permanent"
          open={isDrawer}
          className={isDrawer ? "sidebar-main__drawer" : "sidebar-main__drawer--close"}
        >
          <SidebarInfoComponent />
        </Drawer>
      ) : (
        <SwipeableDrawer
          anchor="left"
          open={isDrawer}
          onClose={() => setStateSidesNavReducer("isDrawer", false)}
          onOpen={() => setStateSidesNavReducer("isDrawer", true)}
          swipeAreaWidth={0}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
          className="grid-x sidebar-main__drawer__content"
          PaperProps={{
            className: "small-7 sidebar-main__drawer__content",
          }}
        >
          <SidebarInfoComponent />
        </SwipeableDrawer>
      )}
    </>
  );
};

const mapStateToProps = ({ AppReducer, SidesNavReducer }) => {
    const {windowSize: { innerWidth }} = AppReducer;
    const { isDrawer } = SidesNavReducer;
  
    return {
      innerWidth,
      isDrawer,
    };
};
  
const mapStateToPropsActions = {
  setStateSidesNavReducer,
};
  
export default connect(mapStateToProps, mapStateToPropsActions)(SidebarMainComponent);
