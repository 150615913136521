// Constants
import LocalStorageConst from "../core/constants/local-storage.const"

// Infrastructures
import Http from "./infrastructure/http.infrastructure"
import LocalStorage from "./infrastructure/local-storage.infrastructure"

// Infrastructures - constants
import AuthenticationEndPoints from "./constants/authentication.end-points"

// Models
import UserModel from "../models/user/model/user.model"
import AuthenticationModel from "../models/authentication/model/authentication.model"
import RoleModel from "../models/role/model/role.model"

/** 
* Begin Block API Services 
**/
export async function loginService(credentials) {

    try {
        const { data:responseLogin, error } = await Http.post(AuthenticationEndPoints.LOGIN,credentials)
        if (responseLogin && responseLogin.status) {
            const {token, user} = responseLogin.data
            const authenticationModel = new AuthenticationModel(token)
            const role = new RoleModel(user.role.id, user.role.name)
            let userData = new UserModel(
                user.id, user.name, user.lastName, user.documentType, 
                user.indicativeNumber,null, user.numberDocument, user.numberPhone, 
                null, null, null,user.email,null, user.modules 
            )
            userData.hasPostPayroll = user.hasPostPayroll
            return {
                authentication: authenticationModel,
                role: role,
                user: userData
            }
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function logoutService(token) {

    try {
        const {data: responseLogin, error} = await Http.post(AuthenticationEndPoints.LOGOUT, token)
        if (responseLogin && responseLogin.status) {
            const userLogged = responseLogin.data
            return new UserModel(userLogged.name, userLogged.last)
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getValidateEmailService(email) {
    try {
        const { data:responseValidateEmail, error} = await Http.get(AuthenticationEndPoints.VALIDATE_EMAIL, email)
        if (responseValidateEmail && responseValidateEmail.status) {
            return responseValidateEmail.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function generateCodeService(token) {

    try {
        const {data:responseGenerateCode, error} = await Http.post(AuthenticationEndPoints.GENERATE_CODE,null, token)
        if (responseGenerateCode && responseGenerateCode.status) {
            return responseGenerateCode.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function validateCodeService(code, token) {
    try {
        const {data:responseValidateCode, error} = await Http.post(AuthenticationEndPoints.VALIDATE_CODE, {code}, token)
        if (responseValidateCode && responseValidateCode.status) {
            return responseValidateCode.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function changePasswordService(password, passwordConfirm , token) {
    try {
        const {data: responseChangePassword, error} = await Http.patch(AuthenticationEndPoints.CHANGE_PASSWORD, {password, passwordConfirm}, token)
        if (responseChangePassword && responseChangePassword.status) {
            return responseChangePassword.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function validateSessionService(token) {
    try {
        const { data:responseValidateCode, error } = await Http.get(AuthenticationEndPoints.VALIDATE_TOKEN_SESSION,null, token)
        if (responseValidateCode && responseValidateCode.status) {
            return true
        } else {
            throw error
        }
    } catch (error) {
        throw error
    }
}
/** 
* End Block API Services 
**/

/** 
* Begin Block LocalStorage Services 
**/
export function setTokenService(token) {
    LocalStorage.set(LocalStorageConst.TOKEN_SESSION, token)
}

export function clearKeysAuthenticationService() {
    LocalStorage.remove(LocalStorageConst.TOKEN_SESSION)
    LocalStorage.remove(LocalStorageConst.ROL_USER)
    LocalStorage.remove(LocalStorageConst.USER)
    LocalStorage.remove(LocalStorageConst.PASSAGE_SALES)
}

export function getTokenService() {
    return LocalStorage.get(LocalStorageConst.TOKEN_SESSION, true)
}

export function getUserRolesService() {
    return LocalStorage.get(LocalStorageConst.ROL_USER, true)
}

export function setUserRolesService(roles) {
    LocalStorage.set(LocalStorageConst.ROL_USER, roles)
}

export function setUserService(user){
    LocalStorage.set(LocalStorageConst.USER, user)
}

export function getUserService(){
    return LocalStorage.get(LocalStorageConst.USER, true)
    
}
export function getUserModulesPermissionsService() {
    let modules = []
    const dataUserLogged = LocalStorage.get(LocalStorageConst.USER, true)
    if (dataUserLogged && dataUserLogged?.modules) {

        modules = dataUserLogged.modules.map((module) => ({...module}))
    }
    return modules
}

/** 
* End Block LocalStorage Services 
**/