// Actions
import { setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from "../../../../../storage/reducers/sales/sales.action";

//Assets
import { Assets } from "../../../../../assets";

//Components
import ModalObservationsComponent from "../modal-observations/modal-observations.component";
import ModalElectronicInvoiceComponent from "../../../sales/components/modal-electronic-invoice/modal-electronic-invoice.component";

//Components - Shared
import ModalMainComponent from "../../../../../shared/components/modal/modal-main/modal-main.component";

//Const
import AppConst from "../../../../../core/constants/app.const";

//Libraries
import React, { useState, useEffect } from "react";
import { Checkbox, DatePicker, Input, InputNumber, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { connect } from "react-redux";

//Services
import { getAllDocumentTypeService, getAllIndicativeNumberService, getAllMunicipalityService, getAllPaymentMethodService, updateClientService } from "../../../../../services/shared.services";

//Styles
import "./billing-data.component.scss";

//Utils
import { passagesBillingDataSchema } from "../../../../../utils/form-validations/sales.validators.utils";



const BillingDataComponent = (props) => {
  const {
    // Action
    setDataSalesStateReducer,
    setStateSaleReducer,
    setDataSaleReducer,
    // Variables
    documentType,
    documentNumber,
    name,
    lastName,
    telephone,
    telephoneCode,
    departmentResidence,
    municipalityResidence,
    contactAddress,
    date,
    hours,
    destination,
    assignedSeats,
    typePayment,
    isDisabled,
    vehicle,
    isElectronic,
    numberOfSeats,
    id,
    extraValue,
    invoiceButtonRef
  } = props;

  const INITIAL_STATE = {
    isEditBilling: true,
    isModalObservation: false,
    optionsPaymentMethod: [],
    optionsMunicipality: [],
    optionsTypeDocument: [],
    optionsNumberList: [],
    isShowField: {
      contactAddress: false,
      municipalityResidence: false
    },
    modalInvoiceElectronic: {
      isElectronic: true,
      isState: false
    },
    isDropdownOpen: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isEditBilling, 
    isModalObservation,
    optionsPaymentMethod, 
    optionsMunicipality, 
    optionsNumberList,
    optionsTypeDocument,
    isShowField,
    modalInvoiceElectronic,
    isDropdownOpen
  } = state;

  const [t] = useTranslation("translation");

  useEffect(() => {

    let isShow = {...isShowField}
    if (contactAddress) {
      isShow.contactAddress = true
    }
    if (municipalityResidence) {
      isShow.municipalityResidence = true
    }
    setState((prevState) => ({
      ...prevState,
      isShowField: isShow
    }));

    let touchesValues = { 
      documentType: documentType ? true : false ,
      documentNumber: documentNumber ? true : false ,
      name: name ? true : false ,
      lastName: lastName ? true : false ,
      telephone: telephone ? true : false ,
      telephoneCode: telephoneCode ? true : false ,
      municipalityResidence: municipalityResidence ? true : false ,
      contactAddress: contactAddress ? true : false ,
      typePayment: typePayment ? true : false,
      extraValue: extraValue ? true : false
    }
    setTouched({...touched,...touchesValues});
    setValues({
      documentType,
      documentNumber,
      name,
      lastName,
      telephone,
      telephoneCode,
      municipalityResidence,
      contactAddress,
      typePayment,
      unitCost: Math.floor(
        assignedSeats[0]?.price ? parseFloat(assignedSeats[0].price) : 0
      )
      .toLocaleString(),
      extraValue,
    })
    const promiseList = [getAllDocumentTypeService(), getAllIndicativeNumberService(), getAllPaymentMethodService()];
    if (departmentResidence?.value) {
      promiseList.push(getAllMunicipalityService(departmentResidence?.value))
    }
    dataSelectorsForm(promiseList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataSelectorsForm = async (promiseList) => {
    await Promise.allSettled(promiseList)
    .then((res)=>{
      let documentTypeList = [];
      let indicativeNumberList = [];
      let paymentMethodList = [];
      let municipalityList = [];

      for (let index = 0; index < res.length; index++) {
        const elementResponse = res[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              documentTypeList = elementResponse?.value.map((documentType) => ({ label: documentType.name, value: documentType.id }))
              break;;
            case 1:
              indicativeNumberList = elementResponse?.value.map((indicativeNumber) => ({ label: indicativeNumber.number, value: indicativeNumber.id }))
              if (!telephoneCode) setValues({telephoneCode:indicativeNumberList[0]})
              break;
            case 2:
              paymentMethodList = (elementResponse?.value || [])
                .filter(paymentMethod => paymentMethod.name !== "Contraentrega")
                .map(({ name, id }) => ({ label: name, value: id }));
              break;
            case 3:
              municipalityList = elementResponse?.value.map((municipality) => ({ label: municipality.name, value: municipality.id }))
              break;;
            default:
              break;
          }
        }
      }
      
      setState((prevState) => ({
        ...prevState,
        optionsTypeDocument: documentTypeList,
        optionsNumberList: indicativeNumberList,
        optionsPaymentMethod: paymentMethodList,
        optionsMunicipality: municipalityList
      }));

    })
    .catch(() => {
      // TODO: Implement error alert with code error.
    })
  } 

  const  {values, errors, touched, handleBlur, setValues, setTouched} = useFormik({
    initialValues: {
      documentType: undefined,
      documentNumber: '',
      name: '',
      lastName: '',
      telephone: '',
      telephoneCode: undefined,
      municipalityResidence: undefined,
      contactAddress: '',
      typePayment: undefined,
      unitCost: 0,
      extraValue: 0,
      contactEmail: ''
    },
    validationSchema: passagesBillingDataSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  
  const onChange = (data, target) => {
    let value = data && data.target ? data.target.value || '' : data;
    const passengerData = { ...values, [target]: value }
    if (target === "unitCost" || target === "extraValue") {
      value = value.replace(/,/g, "");
      value = value.replace(/[^0-9]/g, '')
      if (/^-?\d*\.?\d*$/.test(value) || value === '' || value === '-') {
        value = Number(value)
        setValues({...values, [target]: value})
        if (target === "unitCost") {
          const valueAssignedSeats = assignedSeats?.map(object => {
            return {...object, price : value}
          });
          const unitCostVehicle = vehicle?.vehicle?.seatMap?.map(subArray => Array.isArray(subArray) ? subArray.map(element => {
            if (typeof element === 'object' && element?.hasOwnProperty('price')) {
              element.price = value;
            }
            return element;
          }) : subArray);
          const newVehicle = {...vehicle}
          newVehicle.vehicle.seatMap = unitCostVehicle
          setDataSaleReducer("passageSales",{vehicle:newVehicle,assignedSeats:valueAssignedSeats})
        }else{
          setDataSalesStateReducer("passageSales",target, value)
        }
      }
    }else{
      if (target === "typePayment") {
        passengerData.extraValue = 0
        setValues(passengerData)
        setDataSaleReducer("passageSales", { [target]: value, extraValue: 0})
      }else{
        setValues(passengerData)
        setDataSalesStateReducer("passageSales",target, value)
      }
    }
  };

  const handledModal = () =>{
    setState({
      ...state,
      isModalObservation : true
    })
  }
  const handleCloseModal = () =>{
    setState({
      ...state,
      isModalObservation : false
    })
  }

  useEffect(() => {
    setStateSaleReducer('isError', !!Object.keys(errors).length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const checkInvoice = () => {
    if (values.contactEmail) {
      onChange(!isElectronic, "isElectronic")
    }else{
      if (!isElectronic) {
        setState({
          ...state,
          modalInvoiceElectronic: {
            ...modalInvoiceElectronic,
            isState: true
          }
        })
      }
    }
  }

  const closeModalInvoiceElectronic = () => {
    onChange("","contactEmail")
    setState({
      ...state,
      modalInvoiceElectronic: {
        isElectronic: true,
        isState: false
      }
    })
  }

  const changeEmail = (email) => {
    const validation = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
    onChange(email,"contactEmail")
    if (validation.test(email)) {
      setState({
        ...state,
        modalInvoiceElectronic: {
          ...modalInvoiceElectronic,
          isElectronic: false
        }
      })
    }
  }

  const acceptModalInvoiceElectronic = async() => {
    try {
      await updateClientService({contactEmail: values.contactEmail, id: id})
      setState({
        ...state,
        modalInvoiceElectronic: {
          isElectronic: true,
          isState: false
        }
      })
      setDataSalesStateReducer("passageSales","isElectronic", true)
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }
  
  return (
    <>
      <div className="grid-x">
        <div className="grid-x small-12 medium-12 justify-content-between">
          <label className="billing-data__title">
            {t("sales.passages.billingData.purchaseSummary")}
          </label>
          <div className="grid-x align-middle">
            <span className="billing-data__invoice-electronics">
              Crear esta factura como electrónica
            </span>
            <Checkbox
              checked={isElectronic}
              className="billing-data__checkbox"
              onChange={() => checkInvoice()}
              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.typeDocument")}</label>
          <Select
            disabled={isDisabled}
            value={values.documentType}
            className="billing-data__select"
            onChange={(e, event) => onChange(event, 'documentType')}
            onBlur={(value) => handleBlur('documentType')(value)}
            status={errors.documentType && touched.documentType ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsTypeDocument}
          />
        <span className="grid-x billing-data__errors">{touched.documentType? errors.documentType : null}</span>
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.documentNumber")}</label>
          <Input
            disabled={isDisabled}
            value={values.documentNumber}
            onChange={(value) => onChange(value, 'documentNumber')}
            onBlur={(value) => handleBlur('documentNumber')(value)}
            status={errors.documentNumber && touched.documentNumber ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={t("sales.passages.billingData.documentNumber")}
            className="billing-data__input"
          />
          <span className="grid-x billing-data__errors">{touched.documentNumber? errors.documentNumber : null}</span>
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.clientName")}</label>
          <Input
            disabled={isDisabled}
            value={values.name}
            onChange={(value) => onChange(value, 'name')}
            onBlur={(value) => handleBlur('name')(value)}
            status={errors.name && touched.name ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={t("sales.passages.billingData.clientName")}
            className="billing-data__input"
          />
        <span className="grid-x billing-data__errors">{touched.name? errors.name : null}</span>
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.clientLastName")}</label>
          <Input
            disabled={isDisabled}
            value={values.lastName}
            onChange={(value) => onChange(value, 'lastName')}
            onBlur={(value) => handleBlur('lastName')(value)}
            status={errors.lastName && touched.lastName ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={t("sales.passages.billingData.contactTelephone")}
            className="billing-data__input"
          />
          <span className="grid-x billing-data__errors">{touched.lastName? errors.lastName : null}</span>
        </div>
        <div className="small-12 medium-4">
          <label className="billing-data__label">{t("sales.passages.billingData.contactTelephone")}</label>
          <Select
            disabled={isDisabled}
            value={values.telephoneCode}
            onChange={(e,value) => onChange(value, 'telephoneCode')}
            onBlur={(value) => handleBlur('telephoneCode')(value)}
            status={errors.telephoneCode && touched.telephoneCode ? "error" : ""}
            className="billing-data__select billing-data__select--mod"
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsNumberList}
          />
          <Input
            disabled={isDisabled}
            value={values.telephone}
            onChange={(value) => onChange(value, 'telephone')}
            onBlur={(value) => handleBlur('telephone')(value)}
            status={errors.telephone && touched.telephone ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            className="billing-data__input billing-data__input--mod"
          />
          <span className="grid-x billing-data__errors">{touched.telephone? errors.telephone : null}</span>
        </div>
        { isShowField?.contactAddress &&
          <div className="small-12 medium-4">
          <label className="billing-data__label">{t("sales.passages.billingData.contactAddress")}</label>
          <Input
            disabled={isDisabled}
            value={values.contactAddress}
            onChange={(value) => onChange(value, 'contactAddress')}
            onBlur={(value) => handleBlur('contactAddress')(value)}
            status={errors.contactAddress && touched.contactAddress ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={t("sales.passages.billingData.contactAddress")}
            className="billing-data__input"
          />
          </div>
        }
        { isShowField?.municipalityResidence &&
          <div className="small-12 medium-4">
          <label className="billing-data__label">{t("sales.passages.billingData.municipalityResidence")}</label>
          <Select
          disabled={isDisabled}
            value={values.municipalityResidence}
            className="billing-data__select"
            onChange={(e, event) => onChange(event, 'municipalityResidence')}
            onBlur={(value) => handleBlur('municipalityResidence')(value)}
            status={errors.municipalityResidence && touched.municipalityResidence ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsMunicipality}
          />
          <span className="grid-x billing-data__errors">{touched.municipalityResidence? errors.municipalityResidence : null}</span>
          </div>
        }
      </div>
      <div className="billing-data__line" />
      <div className="grid-x">
        <label className="small-7 medium-12 billing-data__title billing-data__title--mod">
          {t("sales.passages.billingData.travelData")}
        </label>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.travelDate")}</label>
          <DatePicker
            className="billing-data__date"
            onChange={onChange}
            format={"DD/MM/YYYY"}
            allowClear={{
              clearIcon: (
                <img
                src={Assets.SalesIcons.icon_clear_calendar}
                alt="icon_clear_calendar"
                />
              )
            }}
            popupClassName="billing-data__date__picker"
            placeholder="DD/MM/AAAA"
            suffixIcon={
              <img
                src={isEditBilling ? Assets.SalesIcons.icon_calendar_disabled : Assets.SalesIcons.icon_calendar}
                alt="icon_calendar"
                style={{ height: "25px", width: "25px" }}
              />
            }
            superNextIcon={
              <img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />
            }
            superPrevIcon={
              <img src={Assets.SalesIcons.icon_back} alt="icon_back" />
            }
            nextIcon={
              <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
            }
            prevIcon={
              <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
            }
            defaultValue={dayjs(date, "DD/MM/YYYY")}
            disabled = {isEditBilling}
          />
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.travelTime")}</label>
          <TimePicker
            use12Hours
            onChange={onChange}
            className="billing-data__hour"
            suffixIcon={
              <img
                src={isEditBilling ? Assets.SalesIcons.icon_hour_disabled : Assets.SalesIcons.icon_hour}
                alt="icon_hour"
                className="billing-data__hour__icon"
              />
            }
            popupClassName="billing-data__hour__select"
            allowClear={false}
            defaultValue={dayjs(hours, "HH:mm")}
            disabled = {isEditBilling}
          />
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.route")}</label>      
            <Input
              className="billing-data__input__disabled"
              disabled
              value={destination?.label}
            />
        </div>
        <div className="grid-x small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.numberSeats")}</label>
          <div className={`grid-x billing-data__input-number__content ${isEditBilling ? "billing-data__input-number__content--disabled" : ""}`}>
          <InputNumber
            onChange={(e)=> setState({
              ...state,
              numberOfSeats : e
            })}
            value={numberOfSeats}
            className="billing-data__input-number"
            controls={false}
            variant="borderless"
            disabled = {isEditBilling}
          />
          <div className="grid-y billing-data__input-number__buttons">
            <button className="small-6"
              onClick={()=>{ 
                setState({
                  ...state,
                  numberOfSeats: numberOfSeats + 1 
                })
              }}
              disabled = {isEditBilling}
            >
              <img
                src={isEditBilling ? Assets.SharedIcons.icon_up_arrow_disabled : Assets.SharedIcons.icon_up_arrow}
                alt="icon_up_arrow"
                className="billing-data__input-number__buttons__icon"
              />
            </button>
            <button className="small-6"
              onClick={()=>{ 
                setState({
                  ...state,
                  numberOfSeats: numberOfSeats - 1 
                })
              }}
              disabled={numberOfSeats <= 0 || isEditBilling}
            >
              <img
                src={(numberOfSeats <= 0 || isEditBilling) ? Assets.SharedIcons.icon_down_arrow_disabled : Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
                className="billing-data__input-number__buttons__icon"
              />
            </button>
          </div>
        </div>
        </div>
      </div>
      <div className="billing-data__line" />
      <div className="grid-x">
        <label className="small-6 billing-data__title billing-data__title--mod">
          {t("sales.passages.billingData.purchaseData")}
        </label>
        <div className="small-6 grid-x billing-data__title--mod justify-content-end">
          <button
            className="grid-x"
            onClick={()=> handledModal()}
            disabled={!isDisabled}
          >
              <label className="billing-data__comments__label">{t("sales.passages.billingData.remarks")}</label>
              <img
                src={isDisabled ? Assets.SalesIcons.icon_comments : Assets.SalesIcons.icon_comments_disabled}
                alt="icon_comments"
                className="billing-data__comments"
              />
          </button>
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.unitCost")}</label>
          <Input
            disabled={isDisabled}
            value={values?.unitCost?.toLocaleString()}
            defaultValue={assignedSeats[0]?.price?.toLocaleString()}
            className="small-10 medium-11 billing-data__input"
            onChange={(value) => onChange(value, 'unitCost')}
            onBlur={(value) => handleBlur('unitCost')(value)}
            status={errors.unitCost && touched.unitCost ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
          />
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">{t("sales.passages.billingData.totalCost")}</label>
          <Input
            className="small-10 medium-11 billing-data__input__disabled"
            disabled
            value={`$ ${
                Math.floor(
                  assignedSeats
                  .map((seat) => parseFloat(seat.price))
                  .reduce((total, price) => total + price, 0)
                )
                .toLocaleString()
              }`
            }
          />
        </div>
        <div className="small-12 medium-3"> 
          <label className="billing-data__label">{t("sales.passages.billingData.selectPayment")}</label>
          <Select
            autoFocus
            disabled={isDisabled}
            value={values.typePayment}
            className="billing-data__select"
            onChange={(e, value) => onChange(value, 'typePayment')}
            onBlur={(value) => handleBlur('typePayment')(value)}
            status={errors.typePayment && touched.typePayment ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsPaymentMethod}
            onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
            onSelect={(value) => {
              setTimeout(() => {
                if (isDropdownOpen) {
                  invoiceButtonRef.current.focus();
                }
              }, 100);
            }}
          />
          <span className="grid-x billing-data__errors">{touched.typePayment? errors.typePayment : null}</span>
        </div>
        <div className="small-12 medium-3">
          <label className="billing-data__label">Valor Extra</label>
          <Input
            disabled={isDisabled || values?.typePayment?.label !== "Crédito"}
            value={values.extraValue?.toLocaleString()}
            className="small-10 medium-11 billing-data__input"
            onChange={(event) => onChange(event, 'extraValue')}
            onBlur={(value) => handleBlur('extraValue')(value)}
            status={errors.extraValue && touched.extraValue ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
          />
        </div>
      </div>
      <>
          <ModalMainComponent
            width={478}
            title={"Observaciones"}
            open={isModalObservation}
            onClose={()=> handleCloseModal()}
            footer={false}
            titleClass="billing-data__title-modal"
          >
            <ModalObservationsComponent onClose={()=> handleCloseModal()}/>
          </ModalMainComponent>
      </>
      { modalInvoiceElectronic?.isState &&
        <ModalMainComponent
          width={400}
          open={modalInvoiceElectronic?.isState}
          onClose={()=> closeModalInvoiceElectronic()}
          titleIcon={
            <div className="grid-x align-center-middle">
              <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
            </div>
          }
          footer={[
            <button 
              key="accept" 
              className="sales-table__modal-electronic__button"
              onClick={()=> acceptModalInvoiceElectronic()}
              disabled={modalInvoiceElectronic?.isElectronic}
            >
              <span className="sales-table__modal-electronic__button__label">
                Crear factura electrónica
              </span>
            </button>,
            <div className="sales-table__modal-electronic__close" key="cancel">
              <button
                className="sales-table__modal-electronic__close__label"
                onClick={()=> closeModalInvoiceElectronic()}
              >
                Cancelar
              </button>
            </div>
          ]}
        >
          <ModalElectronicInvoiceComponent
            email={values.contactEmail}
            changeEmail={(email)=>changeEmail(email)}
            isCreateInvoiceElectronic={modalInvoiceElectronic?.isElectronic}
          />
        </ModalMainComponent>
      }
    </>
  );
};

const mapStateToProps = ({ SalesReducer }) => {
  const {
    passageSales: {
      documentType,
      documentNumber,
      name,
      lastName,
      telephone,
      telephoneCode,
      departmentResidence,
      municipalityResidence,
      contactAddress,
      date,
      hours,
      destination,
      assignedSeats,
      typePayment,
      vehicle,
      isElectronic,
      contactEmail,
      numberOfSeats,
      id,
      extraValue
    },
    isDisabled
  } = SalesReducer;
  return {
    documentType,
    documentNumber,
    name,
    lastName,
    telephone,
    telephoneCode,
    departmentResidence,
    municipalityResidence,
    contactAddress,
    date,
    hours,
    destination,
    assignedSeats,
    typePayment,
    isDisabled,
    vehicle,
    isElectronic,
    contactEmail,
    numberOfSeats,
    id,
    extraValue
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setStateSaleReducer,
  setDataSaleReducer
}

export default connect(mapStateToProps, mapStateToPropsActions)(BillingDataComponent);
