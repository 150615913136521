//Actions
import { setDataNewUserStateReducer, setStateNewUserReducer } from "../../../../../storage/reducers/user-settings/user-settings.action";

//Assets
import { Assets } from "../../../../../assets";

//Libraries
import { DatePicker, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

//Services
import { getAllDocumentTypeService, getAllIndicativeNumberService } from "../../../../../services/shared.services";
import { getHeadquarterService } from "../../../../../services/settings.services";

//Styles
import "./data-user.component.scss"

//Utils
import { createUserSchema } from "../../../../../utils/form-validations/settings.validators.utils";
import { validateFormatPassword } from "../../../../../utils/form-validations/authentications.validators.utils";


const DataUserComponent = (props) => {

  const {
    setDataNewUserStateReducer,
    setStateNewUserReducer,
    ...newUserSettings
  } = props;

  let { state: routerState} = useLocation();

  const INITIAL_STATE = {
    formatErrors: {
      eightCharacters: true,
      number: true,
      specialCharacter: true,
      uppercaseLetter: true,
      lowercaseLetter: true
    },
    documentTypes: [],
    indicativeNumbers:[],
    headquarter: [],
    isFocusPasswordRules: false
  }

  const [state, setState] = useState(INITIAL_STATE);
  const { 
    formatErrors, documentTypes, indicativeNumbers, headquarter, isFocusPasswordRules
   } = state;

  const {values, setValues, errors, setFieldValue, touched, handleBlur, setTouched} = useFormik({
    initialValues:{
      nameCreateUser: '',
      lastNameCreateUser: '',
      typeDocumentCreateUser: undefined,
      numberDocumentCreateUser: '',
      dateOfBirthCreateUser: undefined,
      indicativeCreateUser: undefined,
      telephoneCreateUser: '',
      emailCreateUser: '',
      campusCreateUser: undefined,
      newPasswordCreateUser: '',
      confirmNewPasswordCreateUser: '',
      isEditingFormik: false,
      state: false
    },
    validationSchema: createUserSchema
  })

  useEffect(() => {
    let touchesValues = {
      nameCreateUser: newUserSettings.nameCreateUser ? true : false,
      lastNameCreateUser: newUserSettings.lastNameCreateUser ? true : false,
      typeDocumentCreateUser: newUserSettings.typeDocumentCreateUser ? true : false,
      numberDocumentCreateUser: newUserSettings.numberDocumentCreateUser ? true : false,
      dateOfBirthCreateUser: newUserSettings.dateOfBirthCreateUser ? true : false,
      indicativeCreateUser: newUserSettings.indicativeCreateUser ? true : false,
      telephoneCreateUser: newUserSettings.telephoneCreateUser ? true : false,
      emailCreateUser: newUserSettings.emailCreateUser ? true : false,
      campusCreateUser: newUserSettings.campusCreateUser ? true : false,
      state: newUserSettings.state ? true : false,
      newPasswordCreateUser: newUserSettings.newPasswordCreateUser ? true : false,
      confirmNewPasswordCreateUser: newUserSettings.confirmNewPasswordCreateUser ? true : false,
    }
    setTouched({...touched,...touchesValues});
    setValues({...newUserSettings, isEditingFormik:routerState?.isEditing });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStateNewUserReducer("isError", !!Object.keys(errors).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const onChange = async (data, target) => {
    const value = data && data.target ? data.target.value || '' : data;
    const newUserData = { ...values, [target]: value };

    if (target === "newPasswordCreateUser") {
      setFieldValue('newPasswordCreateUser', value);
      const errors = validateFormatPassword(value);
      setState((prevState) => ({ 
        ...prevState, 
        formatErrors: errors 
      }));
      setDataNewUserStateReducer("newUserSettings", target, value);
    }else{
      setValues(newUserData);
      setDataNewUserStateReducer("newUserSettings", target, value);
    }
  }

  useEffect(() => {
    async function fetchDocumentTypes() {
      const types = await getAllDocumentTypeService();
      const mappedTypes = types.map((documentType) => ({
        label: documentType?.name,
        value: documentType?.id
      }));
      const indicatives = await getAllIndicativeNumberService();
      const mappedIndicatives = indicatives.map((indicative) => ({
        label: indicative?.number,
        value: indicative?.id
      }));
      const headquarter = await getHeadquarterService();
      const mappedHeadquarter = headquarter.map((campus) => ({
        label: campus?.description,
        value: campus?.id,
      }))
      setState((prevState) => ({
        ...prevState,
        documentTypes: mappedTypes,
        indicativeNumbers: mappedIndicatives,
        headquarter: mappedHeadquarter
      }))
    }
    fetchDocumentTypes();
  }, []);

  return (
    <>
      <div
        className="grid-x"
        style={{ paddingLeft: "30px" }}
      >
        <div className="small-12 medium-6 data-user__content">
          <label className=" grid-x data-user__label">
            Nombre del usuario
          </label>
          <Input
            // disabled={isDisabled}
            value={values.nameCreateUser}
            onChange={(value) => onChange(value, 'nameCreateUser')}
            onBlur={(value) => handleBlur('nameCreateUser')(value)}
            status={errors?.nameCreateUser && touched?.nameCreateUser ? "error" : ""}
            className="data-user__input__content"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              ),
            }}
            placeholder="Escribe el nombre del usuario"
          />
          <span className="grid-x data-user__errors">{touched?.nameCreateUser? errors?.nameCreateUser : null}</span>
        </div>
        <div className="small-12 medium-6 data-user__content data-user__content__right">
          <label className=" grid-x data-user__label data-user__label--mod">
            Apellidos del usuario
          </label>
          <Input
              // disabled={isDisabled}
              value={values.lastNameCreateUser}
              onChange={(value) => onChange(value, "lastNameCreateUser")}
              onBlur={(value) => handleBlur("lastNameCreateUser")(value)}
              status={ errors?.lastNameCreateUser && touched?.lastNameCreateUser ? "error" : "" }
              className="data-user__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder="Escribe el apellido del usuario"
          />
          <span className="grid-x data-user__errors">{touched?.lastNameCreateUser ? errors?.lastNameCreateUser : null}</span>
        </div>
        <div className="small-12 medium-6 data-user__content">
          <label className="grid-x data-user__label">
            Tipo de Documento
          </label>
          <Select
              // disabled={isDisabled}
              className="data-user__select"
              value={values.typeDocumentCreateUser}
              options={documentTypes}
              onChange={(e, event) => onChange(event, "typeDocumentCreateUser")}
              onBlur={(value) => handleBlur("typeDocumentCreateUser")(value)}
              status={ errors?.typeDocumentCreateUser && touched?.typeDocumentCreateUser ? "error" : "" }
              suffixIcon={
                <img
                  src={Assets.SharedIcons.icon_down_arrow}
                  alt="icon_down_arrow"
                />
              }
              placeholder="Selecciona el tipo de documento"
          />
          <span className="grid-x data-user__errors">{touched?.typeDocumentCreateUser ? errors?.typeDocumentCreateUser : null}</span>
        </div>
        <div className="small-12 medium-6 data-user__content data-user__content__right">
          <label className=" grid-x data-user__label data-user__label--mod">
            Número de Documento
          </label>
          <Input
              // disabled={isDisabled}
              value={values.numberDocumentCreateUser}
              onChange={(value) => onChange(value, "numberDocumentCreateUser")}
              onBlur={(value) => handleBlur("numberDocumentCreateUser")(value)}
              status={errors?.numberDocumentCreateUser && touched?.numberDocumentCreateUser ? "error" : "" }
              className="data-user__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder="Escribe el número del documento"
          />
          <span className="grid-x data-user__errors">{touched?.numberDocumentCreateUser ? errors?.numberDocumentCreateUser : null}</span>
        </div>
        <div className="small-12 medium-6 data-user__content">
          <label className="grid-x data-user__label">
            Fecha de nacimiento
          </label>
          <DatePicker
            // disabled={isDisabled}
            style={{ width: "90%" }}
            className="data-user__date"
            disabledDate={(current) => {
              return( current && current > dayjs().subtract(18, 'year'))}
            }
            value={values.dateOfBirthCreateUser ? dayjs(values.dateOfBirthCreateUser, "DD:MM:YYYY") : null }
            onChange={(e, event) => onChange(event, "dateOfBirthCreateUser")}
            status={errors?.dateOfBirthCreateUser && touched?.dateOfBirthCreateUser ? "error" : ""}
            onBlur={() => handleBlur("dateOfBirthCreateUser")}
            format={"DD/MM/YYYY"}
            allowClear={{
              clearIcon:(
                <img
                  src={Assets.SalesIcons.icon_clear_calendar}
                  alt="icon_clear_calendar"
                  style={{ height: "25px", width: "25px" }}
                />
              )
            }}
            popupClassName="trip-data__date__picker"
            placeholder="DD/MM/AAAA"
            suffixIcon={
              <img
                src={Assets.SalesIcons.icon_calendar}
                alt="icon_calendar"
                style={{ height: "25px", width: "25px" }}
              />
            }
            superNextIcon={
              <img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />
            }
            superPrevIcon={
              <img src={Assets.SalesIcons.icon_back} alt="icon_back" />
            }
            nextIcon={
              <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
            }
            prevIcon={
              <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
            }
          />
          <span className="grid-x trip-data__errors">{touched?.dateOfBirthCreateUser ? errors?.dateOfBirthCreateUser : null}</span>
        </div>
        <div className="small-12 medium-6 data-user__content data-user__content__right">
          <label className=" grid-x data-user__label data-user__label--mod">
            Teléfono de contacto
          </label>
          <div className="grid-x data-user__label--mod justify-content-between">
            <Select
              //   disabled={isDisabled}
              className="data-user__select indicative-number"
              suffixIcon={
                <img
                  src={Assets.SharedIcons.icon_down_arrow}
                  alt="icon_down_arrow"
                />
              }
                options={indicativeNumbers}
                value={values.indicativeCreateUser}
                onChange={(e, event) => onChange(event, "indicativeCreateUser")}
                onBlur={(value) => handleBlur("indicativeCreateUser")(value)}
                status={errors?.indicativeCreateUser && touched?.indicativeCreateUser ? "error" : "" }
            />
            <Input
                // disabled={isDisabled}
                value={values.telephoneCreateUser}
                onChange={(value) => onChange(value, "telephoneCreateUser")}
                onBlur={(value) => handleBlur("telephoneCreateUser")(value)}
                status={ errors?.telephoneCreateUser && touched?.telephoneCreateUser ? "error" : "" }
                className="data-user__input__content phone-number"
                allowClear={{
                  clearIcon: (
                    <img
                      width={28}
                      src={Assets.SharedIcons.icon_square_close}
                      alt="icon_logo_vertical"
                    />
                  ),
                }}
                placeholder="Número de contacto"
            />
          </div>
          <span className="grid-x data-user__errors">{touched?.telephoneCreateUser ? errors?.telephoneCreateUser : null}</span>
        </div>
        <div className="small-12 medium-6 data-user__content">
          <label className="grid-x data-user__label">
            Correo de Contacto
          </label>
          <Input
              // disabled={isDisabled}
              value={values.emailCreateUser}
              onChange={(value) => onChange(value, "emailCreateUser")}
              onBlur={(value) => handleBlur("emailCreateUser")(value)}
              status={ errors?.emailCreateUser && touched?.emailCreateUser ? "error" : "" }
              className="data-user__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder="Escribe el correo electrónico"
          />
          <span className="grid-x data-user__errors">{touched?.emailCreateUser ? errors?.emailCreateUser : null}</span>
        </div>
        <div className="small-12 medium-6 data-user__content data-user__content__right">
          <label className=" grid-x data-user__label data-user__label--mod">
            Sede principal del usuario
          </label>
          <Select
            //   disabled={isDisabled}
              value={values.campusCreateUser}
              className="data-user__select"
              onChange={(e, event) => onChange(event, "campusCreateUser")}
              onBlur={(value) => handleBlur("campusCreateUser")(value)}
              status={errors?.campusCreateUser && touched?.campusCreateUser ? "error" : ""}
              suffixIcon={
                <img
                  src={Assets.SharedIcons.icon_down_arrow}
                  alt="icon_down_arrow"
                />
              }
              options={headquarter}
              placeholder="Selecciona la sede"
          />
          <span className="grid-x data-user__errors">{touched?.campusCreateUser ? errors?.campusCreateUser : null}</span>
        </div>
      </div>
      <div className="create-user__content-left__line"></div>
      <div
        className="grid-x"
        style={{ paddingLeft: "30px" }}
      >
        <div className="small-12 medium-6 data-user__content">
          <label className=" grid-x data-user__label">
            Crear nueva contraseña
          </label>
          <Input.Password
            // disabled={isDisabled}
            value={values.newPasswordCreateUser}
            onChange={(value) => onChange(value, "newPasswordCreateUser")}  
            onBlur={(value) => {
              handleBlur('newPasswordCreateUser')(value);
              setState((prevState) => ({ 
                ...prevState, 
                isFocusPasswordRules: false 
              }))
            }}
            status={errors?.newPasswordCreateUser && touched?.newPasswordCreateUser ? "error" : ""}
            className="data-user__input__content"
            onFocus={() => {
              setTouched({ ...touched, newPasswordCreateUser: true });
              setState((prevState) => ({ 
                ...prevState, 
                isFocusPasswordRules: true 
              }))
            }}
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              ),
            }}
            placeholder="Escribe aquí tu contraseña"
          />
          <span className="grid-x data-user__errors">{touched?.newPasswordCreateUser? errors?.newPasswordCreateUser : null}</span>
          <div className="grid-y small-5 data-user__form-content__field__description">
            {isFocusPasswordRules && touched.newPasswordCreateUser
              ? <>
                <span className={formatErrors.eightCharacters
                  ? "data-user__form-content__field__description__text"
                  : "data-user__form-content__field__description__text--mod"}>
                    Entre 6 y 10 caracteres</span>
                <span className={formatErrors.number
                  ? "data-user__form-content__field__description__text"
                  : "data-user__form-content__field__description__text--mod"}>
                    Debe tener en caracter numérico</span>
                <span className={formatErrors.uppercaseLetter
                  ? "data-user__form-content__field__description__text"
                  : "data-user__form-content__field__description__text--mod"}>
                    Debe tener al menos una mayúscula</span>
                <span className={formatErrors.lowercaseLetter
                  ? "data-user__form-content__field__description__text"
                  : "data-user__form-content__field__description__text--mod"}>
                    Debe tener al menos una minúscula</span>
                <span className={formatErrors.specialCharacter
                  ? "data-user__form-content__field__description__text"
                  : "data-user__form-content__field__description__text--mod"}>
                    Debe tener al menos un carácter especial</span>
              </>
              : null
            }
          </div>
        </div>
        <div className="small-12 medium-6 data-user__content data-user__content__right align-content-start">
          <label className=" grid-x data-user__label data-user__label--mod">
            Confirmar contraseña
          </label>
          <Input.Password
              // disabled={isDisabled}
              value={values.confirmNewPasswordCreateUser}
              onChange={(value) => onChange(value, "confirmNewPasswordCreateUser")}
              onBlur={(value) => handleBlur("confirmNewPasswordCreateUser")(value)}
              status={errors?.confirmNewPasswordCreateUser && touched?.confirmNewPasswordCreateUser ? "error" : "" }
              className="data-user__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder="Confirma tu contraseña"
          />
          <span className="grid-x data-user__errors">{touched?.confirmNewPasswordCreateUser ? errors?.confirmNewPasswordCreateUser : null}</span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ UserSettingsReducer }) => {
  const { 
    newUserSettings:{
      nameCreateUser,
      lastNameCreateUser,
      typeDocumentCreateUser,
      numberDocumentCreateUser,
      dateOfBirthCreateUser,
      indicativeCreateUser,
      telephoneCreateUser,
      emailCreateUser,
      campusCreateUser,
      newPasswordCreateUser,
      confirmNewPasswordCreateUser,
      state
    }
  } = UserSettingsReducer;
  return{
    nameCreateUser,
    lastNameCreateUser,
    typeDocumentCreateUser,
    numberDocumentCreateUser,
    dateOfBirthCreateUser,
    indicativeCreateUser,
    telephoneCreateUser,
    emailCreateUser,
    campusCreateUser,
    newPasswordCreateUser,
    confirmNewPasswordCreateUser,
    state
  };
};

const mapStateToPropsActions = {
  setDataNewUserStateReducer,
  setStateNewUserReducer
};

export default connect (mapStateToProps, mapStateToPropsActions)(DataUserComponent);
