//Assets
import { Assets } from '../../../../assets';

//Libraries
import React from 'react'
import { useTranslation } from 'react-i18next';

//Styles
import "./modal-invoice.component.scss"

const ModalInvoiceComponent = () => {

  const [t] = useTranslation("translation")
  
  return (
    <>
      <div className="grid-x align-center-middle">
        <img
          src={Assets.SharedIcons.icon_logo_and_name}
          alt="icon_logo_and_name"
          className="modal-invoice__icon"
        />
      </div>
      <label className="modal-invoice__title">
        {t("sales.passages.invoiceSale")}
      </label>
      <p className="modal-invoice__description">
        {t("sales.passages.invoiceDone")}
      </p>
      <p className="modal-invoice__description">
        {t("sales.passages.invoiceConsult")}
      </p>
      <p className="modal-invoice__description--mod">
        {t("sales.passages.invoiceWant")}
      </p>
    </>
  );
}

export default ModalInvoiceComponent