//Assets
import { Assets } from "../../../../../assets";

//Components
import NavigationBodyComponent from "../../../../sales/components/navigation-body/navigation-body.component";

//Components - Shared
import ErrorToastComponent from "../../../../../shared/components/toast/error-toast/error-toast.component";

//Constants
import AlertConst from "../../../../../core/constants/alerts.const";
import { SettingsErrorsConst } from "../../../../../core/constants/errors/alerts-app/seettings.errors.const";

//Libraries
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, DatePicker, Input, Select } from "antd";
import { useFormik } from "formik";
import dayjs from "dayjs";

// Services
import { createResolutionService, getListPricePassagesService, getResolutionByIdService, getTypeResolutionService, updateResolutionByIdService } from "../../../../../services/settings.services";

// Styles
import "./create-resolution.component.scss"

//Utils
import { createResolutionSchema } from "../../../../../utils/form-validations/settings.validators.utils";

const CreateResolutionComponent = () => {

  let history = useNavigate();
  let { state: { permit, idResolution } } = useLocation();

  const handleBack = () => {
    history(-1);
  };

  const INITIAL_STATE = {
    optionsRoute: [],
    optionsResolution: [],
    isDisabled: false,
    originalData: {},
    isUpdate: false
  }

  const [state, setState] = useState(INITIAL_STATE);
  const { 
    optionsRoute, isDisabled, 
    optionsResolution, originalData, isUpdate
  } = state;

  const  {values, errors, touched, handleBlur, setValues, setTouched} = useFormik({
    initialValues: {
      serviceType: undefined,
      prefixTNS: '',
      prefixDIAN: '',
      initialRange: 0,
      currentConsecutive: '',
      finalRange: 0,
      number: "",
      date: undefined,
      codeBank: "",
      route: undefined,
      isElectronic: false
    },
    validationSchema: createResolutionSchema,
    onSubmit: () => {},
  });

  const onChange = async (data, target) => {
    let value = data && data.target ? data.target.value || '' : data;
    if (target === "initialRange" || target === "finalRange" ) {
      value = value.replace(/,/g, "");
      value = value.replace(/[^0-9]/g, '')
      if (/^-?\d*\.?\d*$/.test(value) || value === '' || value === '-') {
        value = Number(value)
        setValues({...values, [target]: value})
      }
    }else{
      setValues({ ...values, [target]: value });
    }
  };

  useEffect(() => {
    getServices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getServices = async () => {
      try {
        let objectNewState = { ...state }
        const routes = await getRoute()
        objectNewState.optionsRoute = routes
        const resolutions = await getTypeResolution()
        objectNewState.optionsResolution = resolutions

        if (idResolution) {

          if(permit === 1){
            objectNewState.isDisabled = true
          }
  
          if(permit === 2){
              setTouched({
                ...touched,
                serviceType: true,
                prefixTNS: true,
                prefixDIAN: true,
                initialRange: true,
                currentConsecutive: true,
                finalRange: true,
                number: true,
                date: true,
                codeBank: true,
                route: true,
                isElectronic: true
              })
          }
          const valuesForm = await getData(routes,resolutions)
          if (permit === 2) {
            objectNewState.originalData = valuesForm
          }
        }
        setState(objectNewState)
      } catch (error) {
        
      }
  }

  const getData = async (routes,resolutions) => {
    try {
      const getResolutionData = await getResolutionByIdService(idResolution);
      const valuesData ={
        ...values,
        serviceType: resolutions.find((option) => option.value === getResolutionData?.prefix?.serviceType?.id),
        prefixTNS: getResolutionData?.prefix?.code,
        prefixDIAN: getResolutionData?.resolution?.DIANPrefix,
        initialRange: getResolutionData?.resolution?.initialRange,
        currentConsecutive: getResolutionData?.prefix?.currentConsecutive,
        finalRange: getResolutionData?.resolution?.finalRange,
        number: getResolutionData?.resolution?.number,
        date: dayjs(getResolutionData?.resolution?.dateOfIssuance),
        codeBank: getResolutionData?.bank?.code,
        route: routes.find((option) => option.value === getResolutionData?.resolution?.idRoute),
        isElectronic: getResolutionData?.prefix?.isElectronic
      }
      await setValues(valuesData)
      return valuesData
    } catch {
      // TODO: Implement error alert with code error.
    }
  }
  
  const getRoute = async () => {
    try {
      const routes = await getListPricePassagesService();
      return routes.map((route) => ({
        label: route?.name,
        value: route?.id
      }));
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  const getTypeResolution = async () => {
    try {
      const listResolution = await getTypeResolutionService();
      return listResolution.map((resolution) => ({
        label: resolution?.name,
        value: resolution?.id
      }));
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  const handleNext = async () => {
    const data ={
      idRouteAssociated: values?.route?.value,
      idServiceTypeAssociated: values?.serviceType?.value,
      TNSPrefix: values?.prefixTNS,
      DIANPrefix: values?.prefixDIAN,
      currentConsecutive: values?.currentConsecutive,
      number: values?.number,
      initialRange: values?.initialRange,
      finalRange: values?.finalRange,
      date: dayjs(values?.date).format('YYYY-MM-DD'),
      codeBank: values?.codeBank,
      isElectronic: values?.isElectronic
    }
    if (permit === 2) {
        try {
          const newData = {
            idRouteAssociated: originalData?.route?.value,
            idServiceTypeAssociated: originalData?.serviceType?.value,
            TNSPrefix: originalData?.prefixTNS,
            DIANPrefix: originalData?.prefixDIAN,
            currentConsecutive: originalData?.currentConsecutive,
            number: originalData?.number,
            initialRange: originalData?.initialRange,
            finalRange: originalData?.finalRange,
            date: dayjs(originalData?.date).format('YYYY-MM-DD'),
            codeBank: originalData?.codeBank,
            isElectronic: originalData?.isElectronic
          }
          const dataUpdate = compareObjects(data,newData)
          if (Object.keys(dataUpdate).length > 0) {
            await updateResolutionByIdService(idResolution,dataUpdate)
          }
          handleBack();
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }else{
        try {
          await createResolutionService(data)
          handleBack();
        } catch (error) {
          ErrorToastComponent({
            title: SettingsErrorsConst.resolutions[error.code] || SettingsErrorsConst.default,
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
          })
          // TODO: Implement error alert with code error.
        }
    }
  };

  const compareObjects = (objectOld, objectNew) => {
    const differences = {};

    const compareProperties = (key, valueOld, valueNew) => {
        if (typeof valueOld === 'object' && typeof valueNew === 'object') {
            const subDifferences = compareObjects(valueOld, valueNew);
            if (Object.keys(subDifferences).length > 0) {
                differences[key] = subDifferences;
            }
        } else if (valueOld !== valueNew) {
            differences[key] = valueNew;
        }
    };

    const missingProperties = (objOld, objNew) => {
        const missing = {};
        for (const prop in objNew) {
            if (objNew.hasOwnProperty(prop)) {
                if (!objOld.hasOwnProperty(prop)) {
                    missing[prop] = objNew[prop];
                } else if (typeof objNew[prop] === 'object' && typeof objOld[prop] === 'object') {
                    const subDifferences = compareObjects(objOld[prop], objNew[prop]);
                    if (Object.keys(subDifferences).length > 0) {
                        missing[prop] = subDifferences;
                    }
                } else if (objOld[prop] !== objNew[prop]) {
                    missing[prop] = objNew[prop];
                }
            }
        }
        return missing;
    };

    for (const key in objectOld) {
        if (objectOld.hasOwnProperty(key) && objectNew.hasOwnProperty(key)) {
            compareProperties(key, objectOld[key], objectNew[key]);
        }
    }

    const missingInObjectOld = missingProperties(objectOld, objectNew);
    const missingInObjectNew = missingProperties(objectNew, objectOld);

    Object.assign(differences, missingInObjectOld, missingInObjectNew);

    return differences;
  }

  useEffect(() => {
    if (permit === 2 && originalData) {
      const dataUpdate = compareObjects(originalData,values)
      if (Object.keys(dataUpdate).length > 0) {
        if (!isUpdate) {
          setState({
            ...state,
            isUpdate: true
          })
        }
      }else{
        if (isUpdate) {
          setState({
            ...state,
            isUpdate: false
          })
        }
      }
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const disabledSave = () => {

    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0 && permit !== 2) {
      return 3
    }
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0 && permit === 2 && isUpdate) {
      return 3
    }
    return 2
  }

  return (
    <div>
      <div className="create-resolution__content">
        <span className="create-resolution__subtitle">Resolución de facturación</span>
        <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
        <span className="create-resolution__subtitle">
          Crear Resolución de facturación
        </span>
      </div>
      <NavigationBodyComponent
        locationStepper={1}
        onBack={() => handleBack()}
        onNext={()=>handleNext()}
        textNext={"Guardar"}
        isPreview
        numStepper={disabledSave()}
        isIconNext
      >
        <div className="grid-x">
          <div className="grid-x small-12 medium-12 justify-content-between create-resolution__title">
            <label className="create-resolution__title__text">Crear una resolución</label>
            <div className="grid-x align-middle">
              <span className="create-resolution__invoice-electronics">
                Esta resolución genera facturación electrónica
              </span>
              <Checkbox
                checked={values.isElectronic}
                className="create-resolution__checkbox"
                onChange={() => onChange(!values.isElectronic, "isElectronic")}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input">
            <label className="grid-x create-resolution__label">
                Tipo de servicio
            </label>
            <Select
              disabled={isDisabled}
              value={values.serviceType}
              className="create-resolution__select"
              onChange={(e, event) => onChange(event, "serviceType")}
              onBlur={(value) => handleBlur("serviceType")(value)}
              status={
                errors.serviceType && touched.serviceType ? "error" : ""
              }
              suffixIcon={
                <img
                  src={Assets.SharedIcons.icon_down_arrow}
                  alt="icon_down_arrow"
                />
              }
              options={optionsResolution}
              placeholder={"Tipo de servicio"}
            />
            <span className="grid-x create-resolution__errors">
              {touched.serviceType ? errors.serviceType : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input create-resolution__content-input__right">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
              Prefijo TNS
            </label>
            <Input
              disabled={isDisabled}
              value={values.prefixTNS}
              onChange={(value) => onChange(value, "prefixTNS")}
              onBlur={(value) => handleBlur("prefixTNS")(value)}
              status={
                errors.prefixTNS && touched.prefixTNS ? "error" : ""
              }
              className="create-resolution__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder={""}
            />
            <span className="grid-x create-resolution__errors">
              {touched.prefixTNS ? errors.prefixTNS : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
                Prefijo DIAN
            </label>
            <Input
              disabled={isDisabled}
              value={values.prefixDIAN}
              onChange={(value) => onChange(value, "prefixDIAN")}
              onBlur={(value) => handleBlur("prefixDIAN")(value)}
              status={
                errors.prefixDIAN && touched.prefixDIAN ? "error" : ""
              }
              className="create-resolution__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder={""}
            />
            <span className="grid-x create-resolution__errors">
              {touched.prefixDIAN ? errors.prefixDIAN : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input create-resolution__content-input__right">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
                Rango inicial de la resolución
            </label>
            <Input
              disabled={isDisabled}
              value={values.initialRange?.toLocaleString()}
              onChange={(value) => onChange(value, "initialRange")}
              onBlur={(value) => handleBlur("initialRange")(value)}
              status={
                errors.initialRange && touched.initialRange ? "error" : ""
              }
              className="create-resolution__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder={""}
            />
            <span className="grid-x create-resolution__errors">
              {touched.initialRange ? errors.initialRange : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
                Consecutivo actual de la resolución
            </label>
            <Input
              disabled={isDisabled}
              value={values.currentConsecutive}
              onChange={(value) => onChange(value, "currentConsecutive")}
              onBlur={(value) => handleBlur("currentConsecutive")(value)}
              status={
                errors.currentConsecutive && touched.currentConsecutive ? "error" : ""
              }
              className="create-resolution__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder={""}
            />
            <span className="grid-x create-resolution__errors">
              {touched.currentConsecutive ? errors.currentConsecutive : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input create-resolution__content-input__right">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
                Rango final de la resolución
            </label>
            <Input
              disabled={isDisabled}
              value={values.finalRange?.toLocaleString()}
              onChange={(value) => onChange(value, "finalRange")}
              onBlur={(value) => handleBlur("finalRange")(value)}
              status={
                errors.finalRange && touched.finalRange ? "error" : ""
              }
              className="create-resolution__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder={""}
            />
            <span className="grid-x create-resolution__errors">
              {touched.finalRange ? errors.finalRange : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
                Número de resolución
            </label>
            <Input
              disabled={isDisabled}
              value={values.number}
              onChange={(value) => onChange(value, "number")}
              onBlur={(value) => handleBlur("number")(value)}
              status={
                errors.number && touched.number ? "error" : ""
              }
              className="create-resolution__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder={""}
            />
            <span className="grid-x create-resolution__errors">
              {touched.number ? errors.number : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input create-resolution__content-input__right">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
                Fecha de la resolución
            </label>
            <DatePicker
                disabled={isDisabled}
                className="create-resolution__date"
                value={values.date? dayjs(values.date, 'DD:MM:YYYY') : null}
                onChange={(date) => onChange(date, 'date')}
                status={errors.date && touched.date ? 'error' : ''}
                onBlur={(value) => handleBlur('date')(value)}
                format={"DD/MM/YYYY"}
                allowClear={{
                  clearIcon: (
                    <img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />
                  ),
                }}
                popupClassName="create-resolution__date__picker"
                placeholder="DD/MM/AAAA"
                suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
                superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
                superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
                nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
                prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
            />
            <span className="grid-x create-resolution__errors">
              {touched.date ? errors.date : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
                Código del banco
            </label>
            <Input
              disabled={isDisabled}
              value={values.codeBank}
              onChange={(value) => onChange(value, "codeBank")}
              onBlur={(value) => handleBlur("codeBank")(value)}
              status={
                errors.codeBank && touched.codeBank ? "error" : ""
              }
              className="create-resolution__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                ),
              }}
              placeholder={""}
            />
            <span className="grid-x create-resolution__errors">
              {touched.codeBank ? errors.codeBank : null}
            </span>
          </div>
          <div className="small-12 medium-6 create-resolution__content-input create-resolution__content-input__right">
            <label className=" grid-x create-resolution__label create-resolution__label--mod">
                Ruta
            </label>
            <Select
              disabled={isDisabled}
              value={values.route}
              className="create-resolution__select"
              onChange={(e, event) => onChange(event, "route")}
              onBlur={(value) => handleBlur("route")(value)}
              status={
                errors.route && touched.route ? "error" : ""
              }
              suffixIcon={
                <img
                  src={Assets.SharedIcons.icon_down_arrow}
                  alt="icon_down_arrow"
                />
              }
              options={optionsRoute}
              placeholder={"Ruta"}
            />
            <span className="grid-x create-resolution__errors">
              {touched.route ? errors.route : null}
            </span>
          </div>
        </div>
      </NavigationBodyComponent>
    </div>
  );
};

export default CreateResolutionComponent;
