
// Libraries
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

// Translation Files
import es from './es.json';
import en from './en.json'

const string = i18n;
const resources = {
    en: {
        translation: en
    },
    es: {
        translation: es
    }
};

string
    .use(initReactI18next)
    .init({
        resources,
        lng: "es",
        fallbackLng: "es",
        interpolation: {
            escapeValue: false
        }
    });

export default string;