//Actions
import { setStateSaleReducer } from '../../../storage/reducers/sales/sales.action'

// Assets
import { Assets } from '../../../assets'

//Libraries
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

//Components
import SalesTableComponent from './components/sales-table/sales-table.component'

// Core - Const
import SalesConst from '../../../core/constants/sales.const';

//Services
import { 
  getInvoicePassagesService,
  getInvoiceShippingCollected, 
  getInvoiceShippingTobeDelivered, 
  getInvoiceShippingsService, 
  getInvoiceTurnsService 
} from '../../../services/sales.services'
import { getUserService } from '../../../services/authentication.services'

// Styles
import './sales.page.scss'

const SalesPage = (props) => {
  const {
    // Actions
    setStateSaleReducer,
    // Variables
    innerWidth,
    salesHistory
  } = props;

  const INITIAL_STATE = {
    isHoverPassages: false,
    isHoverOrders: false,
    isHoverTurns: false,
    tableData: [],
    countTableData: 0
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { 
    isHoverPassages, isHoverOrders, isHoverTurns, 
    tableData, countTableData 
  } = state

  const [t] = useTranslation("translation");
  const dataUser = getUserService()

  useEffect(() => {
    switch (salesHistory) {
      case SalesConst.PASSAGES_SALES:
        async function getInvoicePassages() {
          const getListInvoicePassages = await getInvoicePassagesService({page: 0});
          setState((prevState) => ({ 
            ...prevState, 
            countTableData: getListInvoicePassages?.count ?  getListInvoicePassages?.count : 0,
            tableData: getListInvoicePassages?.rows
          }))
        }
        getInvoicePassages();
        break;
      
      case SalesConst.ASSIGNMENTS_SALES:
        async function getInvoiceShippings() {
          const getListInvoiceShippings = await getInvoiceShippingsService({
            page: 0, 
            typePaymentMethod: SalesConst.PAYMENT_METHODS.CASH
          });
          setState((prevState) => ({ 
            ...prevState, 
            countTableData: getListInvoiceShippings?.count ?  getListInvoiceShippings?.count : 0,
            tableData: getListInvoiceShippings?.shippingInvoices
          }))
        }
        getInvoiceShippings();
        break;
    
      case SalesConst.TURNS_SALES:
        async function getInvoiceTurn() {
          const getListInvoiceTurn = await getInvoiceTurnsService({
            page: 0, 
            isReportLocal:SalesConst.TURNS_TYPE.MONEY_TRANSFER_SENDS, 
            isGetClaimed:SalesConst.TURNS_TYPE.MONEY_TRANSFER_RECEIPTS
          });
          setState((prevState) => ({ 
            ...prevState, 
            countTableData: getListInvoiceTurn?.countRegisterFound ?  getListInvoiceTurn?.countRegisterFound : 0,
            tableData: getListInvoiceTurn?.invoice
          }))
        }
        getInvoiceTurn();
        break;

      default:
        setState((prevState) => ({ 
          ...prevState, 
          countTableData: 0,
          tableData: []
        }))
        break;
    }
  }, [salesHistory])

  const handlePage = async(objetFilter) => {
    setStateSaleReducer("isLoadingTable", true)
      switch (salesHistory) {
        case SalesConst.PASSAGES_SALES:
          try {
            const getListInvoicePassages = await getInvoicePassagesService(objetFilter);
            setState((prevState) => ({ 
              ...prevState, 
              countTableData: getListInvoicePassages?.count ?  getListInvoicePassages?.count : 0,
              tableData: getListInvoicePassages?.rows
            }))
            setStateSaleReducer("isLoadingTable", false)
          } catch {
            // TODO: Implement error alert with code error.
            setStateSaleReducer("isLoadingTable", false)
          };
          break;

        case SalesConst.ASSIGNMENTS_SALES:
          try {
            const getListInvoiceShippings = await getInvoiceShippingsService(objetFilter);
            setState((prevState) => ({ 
              ...prevState, 
              countTableData: getListInvoiceShippings?.count ?  getListInvoiceShippings?.count : 0,
              tableData: getListInvoiceShippings?.shippingInvoices
            }))
            setStateSaleReducer("isLoadingTable", false)
          } catch {
            // TODO: Implement error alert with code error.
            setStateSaleReducer("isLoadingTable", false)
          }
          break;
      
        case SalesConst.TURNS_SALES:
          try {
            const getListInvoiceTurn = await getInvoiceTurnsService(objetFilter);
            setState((prevState) => ({ 
              ...prevState, 
              countTableData: getListInvoiceTurn?.countRegisterFound ?  getListInvoiceTurn?.countRegisterFound : 0,
              tableData: getListInvoiceTurn?.invoice
            }))
            setStateSaleReducer("isLoadingTable", false)
          } catch {
            // TODO: Implement error alert with code error.
            setStateSaleReducer("isLoadingTable", false)
          }
          break;

        default:
          setState((prevState) => ({ 
            ...prevState, 
            countTableData: 0,
            tableData: []
          }))
          setStateSaleReducer("isLoadingTable", false)
          break;
      }
  }

  const handleTabShippingsDelivered = async(objectFilter)=>{
    setStateSaleReducer("isLoadingTable", true)
      try {
        const response = await getInvoiceShippingTobeDelivered(objectFilter);
        setState((prevState) => ({ 
          ...prevState, 
          countTableData: response?.count ?  response?.count : 0,
          tableData: response?.shippingInvoices
        }))
        setStateSaleReducer("isLoadingTable", false)
      } catch {
        // TODO: Implement error alert with code error.
        setStateSaleReducer("isLoadingTable", false)
      }
  }

  const handleTabShippingsCollected = async(objectFilter)=>{
    setStateSaleReducer("isLoadingTable", true)
    try {
      const response = await getInvoiceShippingCollected(objectFilter);
      setState((prevState) => ({
        ...prevState,
        countTableData: response?.count ? response?.count : 0,
        tableData: response?.shippingInvoices
      }))
      setStateSaleReducer("isLoadingTable", false)
    } catch (error) {
      // TODO: Implement error alert with code error.
      setStateSaleReducer("isLoadingTable", false)
    }
  };

  return (
    <>
      <div className='sales__breadcrumbs'>
        <span >{t("sales.salesHeader.sales")}</span>
      </div>
      <div className='grid-y sales__content'>
        <span className="sales__welcome">
          {`${t('sales.salesHeader.welcome')} ${dataUser.name}, ${t('sales.salesHeader.wantToday')}`}
        </span>
        <div className="grid-x sales__type-sales">
          <button
            className={`grid-y align-center-middle sales__type-sales__card ${salesHistory === SalesConst.PASSAGES_SALES ? "sales__type-sales__card__active" : "" }`}
            onClick={() =>{ 
              setStateSaleReducer("salesHistory",SalesConst.PASSAGES_SALES)
            }}
            onMouseOver={() => setState({ ...state, isHoverPassages: true })}
            onMouseOut={() => setState({ ...state, isHoverPassages: false })}
          >
            <img
              src={isHoverPassages
                ? Assets.SalesIcons.icon_passages_hover
                : Assets.SalesIcons.icon_passages
              }
              alt="icon_passages"
              className={isHoverPassages
                ? "sales__type-sales__card__icon--hover"
                : "sales__type-sales__card__icon"
              }
            />
            <span>{t("sales.salesHeader.passages")}</span>
            <Link
              to="./passages"
              className={isHoverPassages
                ? "sales__type-sales__card__add--hover"
                : 'sales__type-sales__card__add'
              }
            >
              <img
                src={isHoverPassages ? Assets.SharedIcons.icon_add_hover : Assets.SharedIcons.icon_add}
                alt="icon_add"
              />
            </Link>
          </button>
          {innerWidth > 639 &&
            <>
              <button
                className={`grid-y align-center-middle sales__type-sales__card ${salesHistory === SalesConst.ASSIGNMENTS_SALES ? "sales__type-sales__card__active" : ""}`}
                onClick={() =>{
                  setStateSaleReducer("salesHistory",SalesConst.ASSIGNMENTS_SALES)
                }}
                onMouseOver={() => setState({ ...state, isHoverOrders: true })}
                onMouseOut={() => setState({ ...state, isHoverOrders: false })}
              >
                <img
                  src={isHoverOrders
                    ? Assets.SalesIcons.icon_orders_hover
                    : Assets.SalesIcons.icon_orders
                  }
                  alt="icon_orders"
                  className={isHoverOrders
                    ? "sales__type-sales__card__icon--hover"
                    : "sales__type-sales__card__icon"
                  }
                />
                <span>{t("sales.salesHeader.orders")}</span>
                <Link
                  to="./shippings"
                  className={isHoverOrders
                    ? "sales__type-sales__card__add--hover"
                    : 'sales__type-sales__card__add'
                  }
                >
                  <img
                    src={isHoverOrders ? Assets.SharedIcons.icon_add_hover : Assets.SharedIcons.icon_add}
                    alt="icon_add"
                  />
                </Link>
              </button>
              <button
                className={`grid-y align-center-middle sales__type-sales__card ${salesHistory === SalesConst.TURNS_SALES ? "sales__type-sales__card__active" : ""}`}
                onClick={() => {
                  setStateSaleReducer("salesHistory",SalesConst.TURNS_SALES)
                }}
                onMouseOver={() => setState({ ...state, isHoverTurns: true })}
                onMouseOut={() => setState({ ...state, isHoverTurns: false })}
              >
                <img
                  src={isHoverTurns
                    ? Assets.SalesIcons.icon_turns_hover
                    : Assets.SalesIcons.icon_turns
                  }
                  alt="icon_passages"
                  className={isHoverTurns
                    ? "sales__type-sales__card__icon--hover"
                    : "sales__type-sales__card__icon"
                  }
                />
                <span>{t("sales.salesHeader.turns")}</span>
                <Link
                  to="./turn"
                  className={isHoverTurns
                    ? "sales__type-sales__card__add--hover"
                    : 'sales__type-sales__card__add'
                  }
                >
                  <img
                    src={isHoverTurns ? Assets.SharedIcons.icon_add_hover : Assets.SharedIcons.icon_add}
                    alt="icon_add"
                  />
                </Link>
              </button>
            </>
          }
        </div>
        <div className='sales__info-content'>
          <div className="sales__title">
            <span>{t("sales.salesFooter.historyTicket")}</span>
          </div>
          <SalesTableComponent
            data={tableData}
            count={countTableData}
            onPage={handlePage}
            onTabShippingsDelivered={handleTabShippingsDelivered}
            onTabShippingsCollected={handleTabShippingsCollected}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ AppReducer, SalesReducer }) => {
  const { windowSize: { innerWidth } } = AppReducer;
  const { salesHistory } = SalesReducer;

  return {
    innerWidth,
    salesHistory
  };
};

const mapStateToPropsActions = {
  setStateSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(SalesPage)