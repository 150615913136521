import * as userSettingsTypes from "./user-settings.types";

export const setDataNewUserStateReducer = (key, prop, value) => (dispatch) => {
    dispatch ({ type: userSettingsTypes.SET_DATA_NEW_USER_STATE, payload: {key, prop, value} });
};

export const setStateNewUserReducer = (prop, value) => (dispatch) => {
    dispatch ({ type: userSettingsTypes.SET_NEW_USER, payload: {prop, value} });
}

export const setStateUserSettingsReducer = (value) => (dispatch) => {
    dispatch ({ type: userSettingsTypes.SET_STATE_USER_SETTINGS, payload: value });
}

export const resetDataNewUserReducer = () => (dispatch) => {
    dispatch ({ type: userSettingsTypes.RESET_DATA_NEW_USER });
}
