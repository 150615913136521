//Assets
import { Assets } from "../../../../assets";

//Libraries
import React from "react";
import { Step, Stepper } from "react-form-stepper";
import { useTranslation } from "react-i18next";

//Styles
import "./stepper.component.scss"

const StepperComponent = (props) => {

  const {
    //Functions
    onClose = ()=>null,
    onStepper = ()=>null,
    //Variables
    dataStepper,
    locationStepper,
    title,
    icon,
    name
  } = props;

  const [t] = useTranslation("translation");

  return (
    <>
      <div className="small-12 stepper__box">
        <div className="grid-x small-12 stepper__header">
          <div className="medium-6 flex-container align-middle">
            <span className="stepper__subtitle stepper__hidden">
              {t("sales.passages.sales")}
            </span>
            <img
              src={Assets.SharedIcons.icon_next}
              alt="icon_next"
              className="stepper__hidden"
            />
            <span className="stepper__subtitle stepper__hidden">
              {name}
            </span>
            <img
              src={icon}
              alt="icon_passages"
              className="stepper__title-icon"
            />
            <span className="stepper__title">{title}</span>
          </div>
          <div className="medium-6 flex-container justify-content-end">
            <button
              className="flex-container align-middle"
              onClick={() => onClose()}
            >
              <span className="stepper__close-text">
                {t("sales.passages.cancel")}
              </span>
              <img
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            </button>
          </div>
        </div>
        <div className="grid-x small-12 stepper__stepper__content">
          <Stepper
            activeStep={locationStepper}
            connectorStateColors={locationStepper ? true : false}
            className="stepper__stepper__steppers"
          >
            {dataStepper.map((sections, index) => (
              <Step
                key={index}
                label={sections?.label}
                onClick={() => onStepper(index)}
                className={`stepper__stepper__step ${
                  index <= locationStepper
                    ? "stepper__stepper__step--active"
                    : "stepper__stepper__step--inactive"
                } ${
                  index < locationStepper
                    ? "stepper__stepper__button-active"
                    : ""
                }
                    `}
              />
            ))}
          </Stepper>
        </div>
      </div>
    </>
  );
};

export default StepperComponent;
