const VehicleEndPoints =  {
    GET_TYPE_FUEL: `/api/typeFuel`,
    GET_TYPE_BODYWORK: `/api/typeBodywork`,
    GET_TYPE_VEHICLE: `/api/typeVehicle`,
    GET_VEHICLE_FILTER: `/api/vehicle/filter/travel`,
    GET_FILTER_VEHICLE_BY_PLATE_CODE_OR_NUMBER: `/api/vehicle/filter/all/availableVehicleToTravel`,
    CREATE_VEHICLE: `/api/vehicle/`,
    UPDATE_BY_ID_VEHICLE: `/api/vehicle/:id`,
    CREATE_OWNER: `/api/owner`,
    GET_FILTER_AVAILABLE_VEHICLE_TO_TRANSFER_MANIFEST_TRAVEL: '/api/vehicle/filter/all/availableVehicleToTransferManifestTravel',
    GET_VEHICLE_BY_ID: `/api/vehicle/:id`,
    GET_LIST_OF_SEAT_TEMPLATES: `/api/templateVehicle`,
    GET_VEHICLE: `/api/vehicle/`,
    GET_FILTER_USERS:`/api/vehicle/paginator`,
    GET_VEHICLE_ALL_PAGINATION_COUNTER: `/api/vehicle/paginator/counter`,
    UPDATE_OWNER: `/api/owner`,
    GET_FILTER_DRIVER_AVAILABLE_ASSIGNED_TO_VEHICLE: `/api/driver/filter/one/driverAvailableAssignedToVehicle`,
    GET_FILTER_VEHICLE_AVAILABLE_ASSIGNED_TO_DRIVER: `/api/vehicle/filter/one/vehicleAvailableAssignedToDriver`
}

export default VehicleEndPoints