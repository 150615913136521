//Assets
import { Assets } from "../../../../assets";

//Libraries
import { Document, Image,Page, Text, View } from "@react-pdf/renderer";
import React from 'react';
import dayjs from "dayjs";

//Styles
import { PdfDetailsStyles } from "./pdf-details.component.styles";

const PdfDetailsComponent = (props) => {

    const {
        dataPreview
    } = props

    const formatHour = (hour) => {
        const hora = dayjs(hour, 'HH:mm:ss').locale('es').format('h:mm a');
        return hora
    }
    return (
        <Document
            pageLayout="singlePage"
        >
            {dataPreview?.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <Page size="A4" style={PdfDetailsStyles().page}>
                            <View fixed style={PdfDetailsStyles().content}>
                                <Image
                                    src={Assets.SharedIcons.icon_logo_pdf}
                                    style={PdfDetailsStyles().logo}
                                />
                                <Text style={PdfDetailsStyles("10px").text__bold}>Planilla No. {item?.travel?.prefixManifest + item?.travel?.manifestNumber}</Text>
                                <View style={PdfDetailsStyles().logo}>
                                    <Text style={[PdfDetailsStyles().text__bold, PdfDetailsStyles().text__right]}>
                                        Nit. 800.175.577-0
                                    </Text>
                                    <Text style={[PdfDetailsStyles().text, PdfDetailsStyles().text__right]}>
                                        Personería Jurídica 3427 - Octubre 02 / 92
                                    </Text>
                                </View>
                            </View>
                            <View fixed style={PdfDetailsStyles().separator} />
                            <View fixed style={PdfDetailsStyles().header}>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(20).header__item]}>
                                    <Text style={PdfDetailsStyles("10px").text__bold}>{"Nro. interno: "}</Text>
                                    {item?.vehicle?.internalNumber}
                                </Text>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(35).header__item, PdfDetailsStyles().text__centered]}>
                                    <Text style={PdfDetailsStyles("10px").text__bold}>{"Conductor: "}</Text>
                                    {`${item?.driver?.name} ${item?.driver?.lastName}`}
                                </Text>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(25).header__item, PdfDetailsStyles().text__centered]}>
                                    <Text style={PdfDetailsStyles("10px").text__bold}>{"Origen: "}</Text>
                                    {item?.travel?.route?.municipalityDepart}
                                </Text>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(20).header__item, PdfDetailsStyles().text__right]}>
                                    <Text style={PdfDetailsStyles("10px").text__bold}>{"Destino: "}</Text>
                                    {item?.travel?.route?.municipalityArrive}
                                </Text>
                            </View>
                            <View fixed style={PdfDetailsStyles().header}>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(20).header__item]}>
                                    <Text style={PdfDetailsStyles("10px").text__bold}>{"Placa: "}</Text>
                                    {item?.vehicle?.plate}
                                </Text>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(35).header__item, PdfDetailsStyles().text__centered]}>
                                    <Text style={PdfDetailsStyles("10px").text__bold}>{"Propietario: "}</Text>
                                    {`${item?.vehicle?.owner?.name} ${item?.vehicle?.owner?.lastName}`}
                                </Text>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(25).header__item, PdfDetailsStyles().text__centered]}>
                                    <Text style={PdfDetailsStyles("10px").text__bold}>{"Hora de salida: "}</Text>
                                    {formatHour(item?.travel?.time)}
                                </Text>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(20).header__item, PdfDetailsStyles().text__right]}>
                                    <Text style={PdfDetailsStyles("10px").text__bold}>{"Fecha: "}</Text>
                                    {dayjs(item?.travel?.date).format('DD/MM/YYYY')}
                                </Text>
                            </View>
                            <View fixed style={PdfDetailsStyles().separator__table} />
                            <View style={PdfDetailsStyles().table__header}>
                                <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().item__table, PdfDetailsStyles().text__centered]}>Nro. de Tiquete</Text>
                                <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().item__table, PdfDetailsStyles().text__centered]}>Puesto</Text>
                                <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().item__table, PdfDetailsStyles().text__centered]}>Nombre del pasajero</Text>
                                <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().item__table, PdfDetailsStyles().text__centered]}>Valor</Text>
                            </View>
                            {item?.travel?.invoices?.tickets?.data?.map((item, index) => (
                                <View key={index}>
                                    <View
                                        style={PdfDetailsStyles().item__table__content}
                                    >
                                        <Text style={[PdfDetailsStyles("8px").text, PdfDetailsStyles().item__table, PdfDetailsStyles().text__centered]}>
                                            {`${item?.prefix}${item?.number}`}
                                        </Text>
                                        <Text style={[PdfDetailsStyles("8px").text, PdfDetailsStyles().item__table, PdfDetailsStyles().text__centered]}>
                                            {item?.name}
                                        </Text>
                                        <Text style={[PdfDetailsStyles("8px").text, PdfDetailsStyles().item__table, PdfDetailsStyles().text__centered]}>
                                            {`${item?.passengerName} ${item?.passengerLastName}`}
                                        </Text>
                                        <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles().item__table, PdfDetailsStyles().text__centered]}>
                                            ${(item?.price)?.toLocaleString()}
                                        </Text>
                                    </View>
                                    <View style={PdfDetailsStyles().item__table__separator} />
                                </View>
                            ))}
                            <View wrap={false} style={PdfDetailsStyles().item__space}>
                                <View style={PdfDetailsStyles().item__table__separator} />
                                <View style={PdfDetailsStyles().total__content}>
                                    <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().total]}>Total de tiquetés</Text>
                                    <Text style={PdfDetailsStyles("10px").text}>${(item?.travel?.invoices?.tickets?.total)?.toLocaleString()}</Text>
                                </View>
                                <View style={PdfDetailsStyles().item__table__separator} />
                            </View>
                            {item?.travel?.invoices?.shippings?.data?.length !== 0 &&
                                <View style={PdfDetailsStyles("10px").table__header}>
                                    <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles(30).header__item, PdfDetailsStyles().text__centered]}>Nro. de Encomienda</Text>
                                    <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles(30).header__item, PdfDetailsStyles().text__centered]}>Nombre del destinatario</Text>
                                    <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles(30).header__item, PdfDetailsStyles().text__centered]}>Valor</Text>
                                </View>
                            }
                            {item?.travel?.invoices?.shippings?.data?.map((item, index) => (
                                <View key={index}>
                                    <View
                                        style={PdfDetailsStyles().item__table__content}
                                    >
                                        <Text style={[PdfDetailsStyles("8px").text, PdfDetailsStyles(30).header__item, PdfDetailsStyles().text__centered]}>
                                            {`${item?.prefix}${item?.number}`}
                                        </Text>
                                        <Text style={[PdfDetailsStyles("8px").text, PdfDetailsStyles(30).header__item, PdfDetailsStyles().text__centered]}>
                                            {`${item?.clientReceives?.name} ${item?.clientReceives?.lastName}`}
                                        </Text>
                                        <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles(30).header__item, PdfDetailsStyles().text__centered]}>
                                            ${(item?.price)?.toLocaleString()}
                                        </Text>
                                    </View>
                                    <View style={PdfDetailsStyles().item__table__separator} />
                                </View>
                            ))}
                            <View wrap={false} style={PdfDetailsStyles().item__space}>
                                {item?.travel?.invoices?.shippings?.data?.length !== 0 &&
                                    <>
                                        <View style={PdfDetailsStyles().item__table__separator} />
                                        <View style={PdfDetailsStyles().total__content}>
                                            <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().total]}>Total de encomiendas</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel?.invoices?.shippings?.total)?.toLocaleString()}</Text>
                                        </View>
                                        <View style={PdfDetailsStyles().item__table__separator} />
                                    </>
                                }
                                <View style={PdfDetailsStyles().item__table__content}>
                                    <View style={{ width: "30%" }}>
                                        <View style={PdfDetailsStyles().subtotal}>
                                            <Text style={PdfDetailsStyles("10px").text__bold}>Aportes extra</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel.othersDetails.extraContributions)?.toLocaleString()}</Text>
                                        </View>
                                        <View style={PdfDetailsStyles().subtotal}>
                                            <Text style={PdfDetailsStyles("10px").text__bold}>Prestaciones</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel.othersDetails.socialBenefits)?.toLocaleString()}</Text>
                                        </View>
                                        <View style={PdfDetailsStyles().subtotal}>
                                            <Text style={PdfDetailsStyles("10px").text__bold}>Abono a prest.</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel.othersDetails.loanPayment)?.toLocaleString()}</Text>
                                        </View>
                                        <View style={PdfDetailsStyles().subtotal}>
                                            <Text style={PdfDetailsStyles("10px").text__bold}>Planilla</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel.othersDetails.manifest)?.toLocaleString()}</Text>
                                        </View>
                                        <View style={PdfDetailsStyles().subtotal}>
                                            <Text style={PdfDetailsStyles("10px").text__bold}>Aportes</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel.othersDetails.contributions)?.toLocaleString()}</Text>
                                        </View>
                                        <View style={PdfDetailsStyles().subtotal}>
                                            <Text style={PdfDetailsStyles("10px").text__bold}>Reposición</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel.othersDetails.replacement)?.toLocaleString()}</Text>
                                        </View>
                                        <View style={PdfDetailsStyles().subtotal}>
                                            <Text style={PdfDetailsStyles("10px").text__bold}>I. Adicionales</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel.othersDetails.additionalInsurance)?.toLocaleString()}</Text>
                                        </View>
                                    </View>
                                    <View style={PdfDetailsStyles().details}>
                                        <View style={PdfDetailsStyles().details__content}>
                                            <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().text__right, { width: "50%" }]}>Subtotal</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>
                                                ${(item?.travel?.subTotal)?.toLocaleString()}
                                            </Text>
                                        </View>
                                        <View style={PdfDetailsStyles().details__separator} />
                                        <View style={PdfDetailsStyles().details__content}>
                                            <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().text__right, { width: "50%" }]}>Descuento</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel.discount)?.toLocaleString()}</Text>
                                        </View>
                                        <View style={PdfDetailsStyles().details__separator} />
                                        <View style={PdfDetailsStyles().details__content}>
                                            <Text style={[PdfDetailsStyles("10px").text__bold, PdfDetailsStyles().text__right, { width: "50%" }]}>Total a pagar</Text>
                                            <Text style={PdfDetailsStyles("10px").text}>${(item?.travel?.totalManifestPrice)?.toLocaleString()}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View fixed style={PdfDetailsStyles().footer}>
                                <View style={PdfDetailsStyles().signature}>
                                    <Text style={PdfDetailsStyles("10px").text}>Firma del conductor:</Text>
                                    <View style={PdfDetailsStyles().signature__line} />
                                </View>
                                <Text style={[PdfDetailsStyles("10px").text, PdfDetailsStyles().text__right]}>El agente: {`${item?.seller?.name.split(" ")[0]}`} <br /> {`${item?.seller?.lastName.split(" ")[0]}`}</Text>
                            </View>
                        </Page>
                    </React.Fragment>
                )
            })}
        </Document>
    )
}

export default PdfDetailsComponent