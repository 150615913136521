//Actions
import { setStateDriverReducer, resetDataDriverReducer, resetDataNewDriverReducer} from '../../../../../storage/reducers/drivers/drivers.action';

//Assets
import { Assets } from '../../../../../assets';

//Components
import SuccessToastComponent from '../../../../../shared/components/toast/success-toast/success-toast.component';
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';
import DriverPersonalDataComponent from '../driver-personal-data/driver-personal-data.component';
import DriverInformationFromComponent from '../driver-information-from/driver-information-from.component';
import LinkDriverVehicleComponent from '../link-driver-vehicle/link-driver-vehicle.component'

//Constants
import AlertConst from '../../../../../core/constants/alerts.const';
import { DriverErrorsConst } from '../../../../../core/constants/errors/alerts-app/driver-errors.const';

//Libraries
import { connect } from "react-redux";
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import DriverStepperComponent from '../driver-stepper/driver-stepper.component';
import dayjs from 'dayjs';

//Styles
import "./driver-form.component.scss";

//Services
import {createDriverService, updateByIdDriverService} from '../../../../../services/drivers.services'

const steps = [
    { label: "Datos personales" },
    { label: "Información conductor" },
    { label: "Vincular vehículo" },
];

const DriverFormComponent = (props) => {

    const {
        //Variables
        isError,
        driverFrom,
        originData,
        linkedVehicle,
        vehicleInformationReducer,
        //Actions
        setStateDriverReducer,
        resetDataDriverReducer,
        resetDataNewDriverReducer
    } = props;

    let history = useNavigate();
    let location = useLocation();
    let isEditing = false;
    let idDriver;

    if (location.state) {
        isEditing = location.state.isEditing || false;
        idDriver = location.state.idDriver;
      }

    const INITIAL_STATE = {
        locationStepperState: 0,
    };

    useEffect(() => {
        return () => {
            setStateDriverReducer('isDisabled', false);
            resetDataNewDriverReducer('driverFrom', {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const [state, setState] = useState(INITIAL_STATE);
    const { locationStepperState } = state;

    const handleNext = async() => {

        let data = {
            name:driverFrom?.name,
            lastName:driverFrom?.lastName,
            documentType:driverFrom?.documentType?.value,
            numberDocument:driverFrom?.documentNumber,
            dateOfBirth:dayjs(driverFrom?.date).format('YYYY-MM-DD'),
            indicativePhone:driverFrom?.telephoneCode?.value,
            numberPhone:driverFrom?.telephone,
            state:driverFrom?.state,
            email:driverFrom?.contactEmail ? driverFrom?.contactEmail : undefined,
            address:driverFrom?.contactAddress ? driverFrom?.contactAddress : undefined,
            licenseNumber:driverFrom?.driverLicenseNumber,
            dateOfLicenseIssuance:dayjs(driverFrom?.dateOfIssue).format('YYYY-MM-DD'),
            dateExpirationLicense:dayjs(driverFrom?.dateExpiration).format('YYYY-MM-DD'),
            transitAgency:driverFrom?.nameOfEntity,
            restriction:driverFrom?.drivingRestrictions ? driverFrom?.drivingRestrictions : undefined,
            municipalityOfBirth:driverFrom?.municipalityOfBirth?.value,
            municipalityOfResidence:driverFrom?.municipalityResidence?.value,
            bloodType:driverFrom?.bloodType?.value,
            licenseCategory:driverFrom?.authorizedCategory?.value
        }

        try {
            if(locationStepperState === 2){
                let newData = {
                    name:originData?.name,
                    lastName:originData?.lastName,
                    documentType:originData?.documentType?.value,
                    numberDocument:originData?.documentNumber,
                    dateOfBirth:dayjs(originData?.date).format('YYYY-MM-DD'),
                    indicativePhone:originData?.telephoneCode?.value,
                    numberPhone:originData?.telephone,
                    state:originData?.state,
                    email:originData?.contactEmail ? originData?.contactEmail : undefined,
                    address:originData?.contactAddress ? originData?.contactAddress : undefined,
                    licenseNumber:originData?.driverLicenseNumber,
                    dateOfLicenseIssuance:dayjs(originData?.dateOfIssue).format('YYYY-MM-DD'),
                    dateExpirationLicense:dayjs(originData?.dateExpiration).format('YYYY-MM-DD'),
                    transitAgency:originData?.nameOfEntity,
                    restriction:originData?.drivingRestrictions ? originData?.drivingRestrictions : undefined,
                    municipalityOfBirth:originData?.municipalityOfBirth?.value,
                    municipalityOfResidence:originData?.municipalityResidence?.value,
                    bloodType:originData?.bloodType?.value,
                    licenseCategory:originData?.authorizedCategory?.value
                }

                let dataUpdate = compareObjects(data, newData);
                let successMessage = isEditing ? "Conductor actualizado con éxito" : "Conductor creado con éxito";

                if (isEditing) {
                    if (Object.keys(dataUpdate).length > 0) {
                        await updateByIdDriverService( idDriver, dataUpdate);
                        onClose();
                    } else {
                        onClose();
                    }
                }

                if (!isEditing) {
                    let dataToSend = data;
                    if (location?.state?.typeAction === 'VIEW_INFO_DRIVER') {
                        onClose();
                    } else {
                        if (linkedVehicle !== false) {
                            dataToSend = { ...data, idVehicle: vehicleInformationReducer?.vehicle?.id };
                        }
                        await createDriverService(dataToSend);
                        onClose();
                    }
                }

                SuccessToastComponent({
                    html: 
                    `<span>${successMessage}
                        <a class="create-user__text-alert"</a>
                    </span>`,
                    position: AlertConst.TOP_END_POSITION_TEXT,
                    timer: 1500
                });
                
                resetDataDriverReducer();

            } else {
                setState({
                    ...state,
                    locationStepperState: locationStepperState + 1,
                });
            }
        } catch (err) {
            ErrorToastComponent({
                title: DriverErrorsConst.driverForm[err.code] || DriverErrorsConst.default,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
        }
    }

    const handleBack = () => {
        setState({
            ...state,
            locationStepperState: locationStepperState - 1,
        });
    };

    const onClose = () => {
        resetDataNewDriverReducer('driverFrom', {});
        history("/drivers", { replace: true });
    };

    const compareObjects = (objectOld, objectNew) => {
        const differences = {};
    
        const compareProperties = (key, valueOld, valueNew) => {
            if (typeof valueOld === 'object' && typeof valueNew === 'object') {
                const subDifferences = compareObjects(valueOld, valueNew);
                if (Object.keys(subDifferences).length > 0) {
                    differences[key] = subDifferences;
                }
            } else if (valueOld !== valueNew) {
                differences[key] = valueNew;
            }
        };
    
        const missingProperties = (objOld, objNew) => {
            const missing = {};
            for (const prop in objNew) {
                if (objNew.hasOwnProperty(prop)) {
                    if (!objOld.hasOwnProperty(prop)) {
                        missing[prop] = objNew[prop];
                    } else if (typeof objNew[prop] === 'object' && typeof objOld[prop] === 'object') {
                        const subDifferences = compareObjects(objOld[prop], objNew[prop]);
                        if (Object.keys(subDifferences).length > 0) {
                            missing[prop] = subDifferences;
                        }
                    } else if (objOld[prop] !== objNew[prop]) {
                        missing[prop] = objNew[prop];
                    }
                }
            }
            return missing;
        };
    
        for (const key in objectOld) {
            if (objectOld.hasOwnProperty(key) && objectNew.hasOwnProperty(key)) {
                compareProperties(key, objectOld[key], objectNew[key]);
            }
        }
    
        const missingInObjectOld = missingProperties(objectOld, objectNew);
        const missingInObjectNew = missingProperties(objectNew, objectOld);
    
        Object.assign(differences, missingInObjectOld, missingInObjectNew);
    
        return differences;
      }

    return (
        <>
            <div className='grid-x small-12 justify-content-end driver-form__content-close'>
                <button
                    className='driver-form__content-close__button'
                    onClick={() => onClose()}
                >
                    <span className='driver-form__content-close__close-text'>
                        Cancelar
                    </span>
                    <img
                        alt="icon_square_close"
                        src={Assets.SharedIcons.icon_square_close}
                        className='driver-form__content-close__icon'
                    />
                </button>
            </div>
            <div className='driver-form__container'>
                <DriverStepperComponent
                    dataStepper={steps}
                    locationStepper={locationStepperState}
                    onStepper={(e) => {
                        setState({
                            ...state,
                            locationStepperState: e,
                        })
                    }}
                />
                {locationStepperState === 0 && <DriverPersonalDataComponent/>}
                {locationStepperState === 1 && <DriverInformationFromComponent/>}
                {locationStepperState === 2 && <LinkDriverVehicleComponent typeAction={location?.state?.typeAction}/>}
                <div className='grid-x small-12 driver-form__content-button'>
                    <div className='grid-x small-4'>
                        {locationStepperState !== 0 &&
                            (<button
                                onClick={() => handleBack()}
                                className="driver-form__button__return"
                            >
                                <img
                                    src={Assets.SharedIcons.icon_back}
                                    alt="icon_back"
                                    className='driver-form__button__icon'
                                />
                                <span>Regresar</span>
                            </button>)
                        }
                    </div>
                    <div className='grid-x small-8 justify-content-end'>
                        <button
                            disabled ={isError}
                            onClick={() => handleNext()}
                            className="driver-form__button__next"
                        >
                            {isEditing
                                ? (locationStepperState === 0 || locationStepperState === 1 ? 'Siguiente' : 'Actualizar')
                                : (locationStepperState === 0 || locationStepperState === 1 ? 'Siguiente' : location?.state?.typeAction=== 'VIEW_INFO_DRIVER' ? 'Finalizar' : 'Crear conductor')
                            } 
                            <img
                                alt={isError ? 'icon_next_disabled' : "icon_next"}
                                src={isError ? Assets.SharedIcons.icon_next_disabled : Assets.SharedIcons.icon_next}
                                className='driver-form__button__icon'
                            />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = ({ DriversReducer }) => {
    const { 
        isDisabled, 
        isError, 
        driverFrom, 
        originData,
        linkedVehicle, 
        vehicleInformationReducer, 
    } = DriversReducer;

    return {
        isDisabled,
        isError,
        driverFrom,
        originData,
        linkedVehicle,
        vehicleInformationReducer,
    };
  };

const mapStateToPropsActions = {
    setStateDriverReducer,
    resetDataDriverReducer,
    resetDataNewDriverReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(DriverFormComponent);