import * as ExcelJS from 'exceljs';

export function boardingSheetExcelUtils(props) {
  const {
    fileTitle,columnWidths,
    dataTickets,summaryData,
    headerValues, dataOrders
  } = props;

  const itemList = [headerValues];
  const workbook = new ExcelJS.Workbook();
  const infoHeader = ["NRO. INTERNO", "CONDUCTOR", "ORIGEN", "DESTINO", "PLACA", "PROPIETARIO","HORA DE SALIDA", "FECHA"];
  const tableHeadersTicket = [
    { name: "Nro. de Tiquete" },
    { name: "Puesto" },
    { name: "Nombre del Pasajero" },
    { name: "Destino" },
    { name: "Valor", totalsRowFunction: 'sum', style: { numFmt: '#,###' } }
  ]

  // Page 1
  const worksheetResume = workbook.addWorksheet(`RESUMEN ${fileTitle}`);

  worksheetResume.mergeCells('A1:B1');
  worksheetResume.getCell('A1').value = `Planilla No. ${fileTitle}`;
  worksheetResume.getCell('A1').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '85AF26' },
  }
  worksheetResume.getCell('A1').font = {
    bold: true,
    color: { argb: 'FFFFFFFF' }
  }

  const data = [
    { concept: 'Aportes extra', price: summaryData?.extraContributions || 0  },
    { concept: 'Prestaciones', price: summaryData?.socialBenefits || 0  },
    { concept: 'Abono a prest.', price: summaryData?.loanPayment || 0  },
    { concept: 'Planilla', price: summaryData?.manifest || 0  },
    { concept: 'Aportes', price: summaryData?.contributions || 0  },
    { concept: 'Reposición', price: summaryData?.replacement || 0  },
    { concept: 'I. Adicionales', price: summaryData?.additionalInsurance || 0  },
    { concept: 'Subtotal', price: summaryData?.subTotal || 0 },
    { concept: 'Descuento', price: summaryData?.discount || 0  },
    { concept: 'Total a Pagar', price: summaryData?.totalManifestPrice || 0  },
  ];

  data.forEach((item, index) => {
    const rowIndex = index + 2;

    worksheetResume.getCell(`A${rowIndex}`).value = item.concept;
    worksheetResume.getCell(`A${rowIndex}`).font = { bold: true, color: { argb: '2C2C2C' } };

    worksheetResume.getCell(`B${rowIndex}`).value = item.price;
  });

  data.slice(-3).forEach((item,index) => {
    worksheetResume.getCell(`A${data.length - 3 + index + 2}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E4E4E4' },
    };
    worksheetResume.getCell(`B${data.length - 3 + index + 2}`).font = { bold: true, color: { argb: '2C2C2C' } };
  })

  worksheetResume.getColumn('B').numFmt = '$#,##0';

  const borderStyle = {
    top: { style: 'thin', color: { argb: 'FF000000' } },
    left: { style: 'thin', color: { argb: 'FF000000' } },
    bottom: { style: 'thin', color: { argb: 'FF000000' } },
    right: { style: 'thin', color: { argb: 'FF000000' } }
  };

  worksheetResume.eachRow((row, rowNumber) => {
    row.eachCell((cell, colNumber) => {
      cell.border = borderStyle;
    });
  });

  worksheetResume.eachRow((row) => {
    row.height = 30;
    row.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  worksheetResume.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength + 5;
  });

  // Page 2

  function applyCommonCellStyles(cell, style) {
    cell.style = style;
  }

  const headerStyle = {
    font: { bold: true },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    },
  };

  const dataCellStyle = {
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    },
  };

  const worksheet = workbook.addWorksheet(`TICKETS ${fileTitle}`, {
    properties: {
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      },
    },
  });

  worksheet.mergeCells('A1:H1');
  const cellA1 = worksheet.getCell('A1');
  cellA1.value = `Planilla No. ${fileTitle}`;
  cellA1.alignment = {
    horizontal: 'center',
    vertical: 'middle',
  };
  cellA1.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '85AF26' },
  };
  cellA1.font = {
    bold: true,
    color: { argb: 'FFFFFF' },
  };

  const headerRow = worksheet.addRow(infoHeader);
  headerRow.eachCell((cell) => applyCommonCellStyles(cell, headerStyle));

  itemList.forEach((item) => {
    const row = Object.values(item);
    const newRow = worksheet.addRow(row);
    newRow.eachCell((cell) => applyCommonCellStyles(cell, dataCellStyle));
  });

  const columns = worksheet.columns;
  for (let i = 1; i < columnWidths?.length; i++) {
    columns[i].width = columnWidths[i];
  }

  worksheet.addTable({
    name: 'MyTable',
    ref: 'A4',
    headerRow: true,
    totalsRow: true,
    columns: tableHeadersTicket,
    rows: dataTickets,
  });

  for (let index = 0; index < dataTickets.length + 2; index++) {
    worksheet.mergeCells(`E${index + 4}:H${index + 4}`);
    worksheet.getColumn('E').numFmt = '$#,##0';
  }

  const headerRowTable = worksheet.getRow(4);

  headerRowTable.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'E4E4E4' },
    };
    cell.border = {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } },
    };
    cell.font = { bold: true, color: { argb: '2C2C2C' } };
    cell.alignment = {
      wrapText: true,
      vertical: 'middle',
      horizontal: 'center',
    };
  });

  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellValue = cell.value ? cell.value.toString() : '';
      maxLength = Math.max(maxLength, cellValue.length);
    });
    column.width = maxLength + 2;
  });

  worksheet.eachRow((row) => {
    row.height = 30;
    row.alignment = { vertical: 'middle', horizontal: 'center' };
    row.eachCell((cell, colNumber) => {
      cell.border = borderStyle;
    });
  });

  if (dataOrders?.length > 0) {

    // Page 3
    const worksheetOrders = workbook.addWorksheet(`ENCOMIENDAS ${fileTitle}`, {
      properties: {
        border: {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        },
      },
    });

    worksheetOrders.mergeCells('A1:H1');
    const cellA1Orders = worksheetOrders.getCell('A1');
    cellA1Orders.value = `Anexo a planilla No. ${fileTitle}`;

    cellA1Orders.alignment = {
      horizontal: 'center',
      vertical: 'middle',
    }
    cellA1Orders.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '85AF26' },
    };
    cellA1Orders.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
    }

    const headerRowOrders = worksheetOrders.addRow(infoHeader);
    headerRowOrders.eachCell((cell) => applyCommonCellStyles(cell, headerStyle));

    itemList.forEach((item) => {
      const row = Object.values(item);
      const newRowOrders = worksheetOrders.addRow(row);
      newRowOrders.eachCell((cell) => applyCommonCellStyles(cell, dataCellStyle));
    });

    const columnsOrders = worksheetOrders.columns;
    for (let i = 1; i < columnWidths?.length; i++) {
      columnsOrders[i].width = columnWidths[i];
    }

    const headerTableAnnex = [
      { name: "Nro. de Encomienda" },
      { name: "Nombre del destinatario" },
      { name: "Destino" },
      { name: "Valor", totalsRowFunction: 'sum', style: { numFmt: '#,###' } }
    ]
    
    worksheetOrders.addTable({
      name: 'MyTable2',
      ref: 'A4',
      headerRow: true,
      totalsRow: true,
      columns: headerTableAnnex,
      rows: dataOrders,
    });

    for (let index = 0; index < dataOrders.length + 2; index++) {
      worksheetOrders.mergeCells(`D${index + 4}:H${index + 4}`);
      worksheetOrders.getColumn('D').numFmt = '$#,##0';
    }

    const headerRowTableOrders = worksheetOrders.getRow(4);

    headerRowTableOrders.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E4E4E4' },
      };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
      cell.font = { bold: true, color: { argb: '2C2C2C' } };
      cell.alignment = {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center',
      };
    })

    worksheetOrders.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2;
    })

    worksheetOrders.eachRow((row) => {
      row.height = 30;
      row.alignment = { vertical: 'middle', horizontal: 'center' };
      row.eachCell((cell, colNumber) => {
        cell.border = borderStyle;
      });
    })

  }

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Planilla_${fileTitle}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
}