//Libraries
import { Navigate, Route, Routes } from "react-router-dom";

//Views - Components
import CreateResolutionComponent from "../pages/settings/invoicing-resolution/components/create-resolution/create-resolution.component";
import CreateUserComponent from "../pages/settings/users-permissions/components/create-user/create-user.component";

//Views - Pages
import SettingsPage from "../pages/settings/settings/settings.page";

const SettingsRoutes = () => {

    return (
        <>
            <Routes>
                <Route path="/" element={<SettingsPage />} />
                <Route path="/createResolution" element={<CreateResolutionComponent />} />
                <Route path="/createUser" element={<CreateUserComponent />} />
                <Route path="*" element={<Navigate to="/settings" />} />
            </Routes>
        </>
    );

};

export default SettingsRoutes;