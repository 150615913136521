//Libraries
import { Input } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

//Services
import { createInvoiceObservationService, getObservationsByIdInvoiceService } from '../../../../../services/shared.services';

//Styles
import "./modal-observations.component.scss";

const ModalObservationsComponent = (props) => {

  const { onClose,idInvoice } = props

  const INITIAL_STATE = {
    comments: [],
    commentValue: ""
  }

  useEffect(() => {
    getObservationsByIdInvoice()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const [t] = useTranslation("translation")
  const [state, setState] = useState(INITIAL_STATE)
  const { comments, commentValue } = state

  const handleCommentAdd = async () =>{
    if (commentValue.trim().length !== 0 && idInvoice) {
      try {
        const createInvoiceObservation =  await createInvoiceObservationService({
          idInvoice: idInvoice,
          description: commentValue
        })
        if (createInvoiceObservation) {
          getObservationsByIdInvoice()
        }
      } catch (error) {
        // TODO: Implement error alert with code error.
      }
    }
  }

  const getObservationsByIdInvoice = async() => {
    try {
      const getObservations = await getObservationsByIdInvoiceService(idInvoice);
      setState((prevState) => ({
        ...prevState,
        comments: getObservations,
        commentValue: ""
      })); 
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const handleValueComment = (value) =>{
    setState({...state,commentValue: value})
  }

  return (
    <>
      <div>
        <div>
        { comments.length > 0 ?
          <div className='modal-observations__data__content'>
            {comments.map((data,index)=>{
              return(
                <div key={index} className={`grid-y modal-observations__data ${index + 1 === comments.length ? "modal-observations__data--mod" : "" }`}>
                  <div className='flex-container justify-content-between'>
                    <label className='modal-observations__data__label'>{data.user}</label>
                    <span className='modal-observations__data__date'>{dayjs(data.date).format("DD/MM/YYYY")}</span>
                  </div>
                  <p className='modal-observations__data__info'>{data.value}</p>
                </div>
              )
            })}
          </div>
          :
          <div className='modal-observations__content'>
            <p className="text-center modal-observations__no-comments">
              {t("sales.passages.billingData.noComments")}
            </p>
          </div>
        }
        </div>
      </div>
      <div className="modal-observations__line" />
      <label className="modal-observations__subtitle">
        {t("sales.passages.billingData.addComments")}
      </label>
      <Input.TextArea 
        rows={3} 
        placeholder={t("sales.passages.billingData.write")}
        className='modal-observations__text-area'
        value={commentValue}
        onChange={({target : {value}})=> handleValueComment(value)}
      />
      <div className="flex-container justify-content-between modal-observations__buttons">
        <button
          className="modal-observations__buttons__add"
          onClick={()=> (commentValue && commentValue.trim()) && handleCommentAdd()}
        >
          {t("sales.passages.billingData.addObservation")}
        </button>
        <button
          className="modal-observations__buttons__close"
          onClick={() => onClose()}
        >
          {t("sales.passages.billingData.cancel")}
        </button>
      </div>
    </>
  );
}

const mapStateToProps = ({ SalesReducer }) => {
  const { idInvoice } = SalesReducer;
  return {
    idInvoice
  };
};

export default connect(mapStateToProps, null)(ModalObservationsComponent);