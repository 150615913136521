//Assets
import { Assets } from '../../../assets'

//Components
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component'
import SuccessToastComponent from '../../../shared/components/toast/success-toast/success-toast.component';

//Constants
import AppConst from '../../../core/constants/app.const'
import AlertConst from '../../../core/constants/alerts.const'
import { SettingsErrorsConst } from '../../../core/constants/errors/alerts-app/seettings.errors.const'

//Libraries
import { debounce } from "lodash";
import { useFormik } from "formik";
import { AutoComplete, Input, Select } from 'antd'
import React, { useEffect, useState, useMemo, useRef } from 'react'

// Services
import {
    getAllDepartmentSettingService,
    getAllDocumentTypeSettingService,
    getAllIndicativeNumberSettingService,
    getAllMunicipalitySettingService,
    getFilterUsersManagementService,
    updateUserClientService,
} from '../../../services/settings.services';

//Styles
import "./client-management.page.scss";

//Utils
import { managementDataClientSchema } from "../../../utils/form-validations/settings.validators.utils";


export const ClientManagementPage = () => {

    const INITIAL_STATE = {
        textSearch: { value: "" },
        isClear: false,
        clientData: {},
        isDisabled: false,
        optionsTypeDocument: [],
        optionsNumberList: [],
        optionsDepartment: [],
        optionsMunicipality: [],
        optionsUserSearch: [],

    }
    const [state, setState] = useState(INITIAL_STATE)
    const {
        textSearch,
        isClear,
        clientData,
        isDisabled,
        optionsTypeDocument,
        optionsNumberList,
        optionsDepartment,
        optionsMunicipality,
        optionsUserSearch
    } = state

    const documentTypeRef = useRef();
    const documentNumberRef = useRef();
    const nameRef = useRef();
    const lastNameRef = useRef();
    const telephoneCodeRef = useRef();
    const telephoneRef = useRef();
    const whatsAppRef = useRef();
    const departmentResidenceRef = useRef();
    const municipalityResidenceRef = useRef();
    const defaultRef = useRef();

    const inputRefs = [
        documentTypeRef,
        documentNumberRef,
        nameRef,
        lastNameRef,
        telephoneCodeRef,
        telephoneRef,
        whatsAppRef,
        departmentResidenceRef,
        municipalityResidenceRef,
        defaultRef
    ];

    useEffect(() => {
        let touchesValues = {
            id: clientData.id ? true : false,
            documentType: clientData.documentType ? true : false,
            documentNumber: clientData.documentNumber ? true : false,
            name: clientData.name ? true : false,
            lastName: clientData.lastName ? true : false,
            telephone: clientData.telephone ? true : false,
            telephoneCode: clientData.telephoneCode ? true : false,
            whatsapp: clientData.whatsapp ? true : false,
            whatsappCode: clientData.whatsappCode ? true : false,
            departmentResidence: clientData.departmentResidence ? true : false,
            municipalityResidence: clientData.municipalityResidence ? true : false,
            contactAddress: clientData.contactAddress ? true : false,
            contactEmail: clientData.contactEmail ? true : false
        }
        setTouched({ ...touched, ...touchesValues });
        setValues(clientData);
        dataSelectorsForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { values, errors, touched, handleBlur, setValues, setTouched, setFieldTouched, handleSubmit } = useFormik({
        initialValues: {
            id: '',
            documentType: undefined,
            documentNumber: '',
            name: '',
            lastName: '',
            telephone: '',
            telephoneCode: undefined,
            whatsapp: '',
            whatsappCode: undefined,
            departmentResidence: undefined,
            municipalityResidence: undefined,
            contactAddress: '',
            contactEmail: ''
        },
        validationSchema: managementDataClientSchema,
        onSubmit: () => { },
    });

    const handleNext = async () => {
        try {
            const data = {
                id: values.originalClientData?.id,
                name: values.originalClientData?.name,
                lastName: values.originalClientData?.lastName,
                documentType: values.originalClientData?.documentType,
                documentNumber: values.originalClientData?.documentNumber,
                telephoneCode: values.originalClientData?.telephoneCode?.value,
                telephone: values.originalClientData?.telephone,
                contactEmail: values.originalClientData?.contactEmail,
                whatsapp: values.originalClientData?.whatsapp,
                whatsappCode: values.originalClientData?.whatsappCode?.value,
                departmentResidence: values.originalClientData?.departmentResidence,
                municipalityResidence: values.originalClientData?.municipalityResidence,
                contactAddress: values.originalClientData?.contactAddress,
            }
            const newData = {
                id: values?.id,
                name: values?.name,
                lastName: values?.lastName,
                documentType: values?.documentType,
                documentNumber: values?.documentNumber,
                telephoneCode: values?.telephoneCode?.value,
                telephone: values?.telephone,
                contactEmail: values?.contactEmail,
                whatsapp: values?.whatsapp,
                whatsappCode: values?.whatsappCode?.value,
                departmentResidence: values?.departmentResidence,
                municipalityResidence: values?.municipalityResidence,
                contactAddress: values?.contactAddress,
            }

            const dataUpdate = compareObjects(data, newData);
            let successMessage = "Cliente actualizado con éxito";

            await updateUserClientService({ ...dataUpdate, id: values?.id });
            SuccessToastComponent({
                html:
                    `<span>${successMessage}
                  <a class="create-user__text-alert"</a>
                </span>`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });

        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.newUsers[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
            return;
        }
    }

    const compareObjects = (objectOld, objectNew) => {
        const differences = {};
    
        for (const key in objectNew) {
            if (objectNew.hasOwnProperty(key)) {
                const valueOld = objectOld[key];
                const valueNew = objectNew[key];
    
                if (valueOld !== undefined && valueNew !== undefined) {
                    if (typeof valueNew === 'object' && valueNew !== null && !Array.isArray(valueNew)) {
                        const nestedDifferences = compareObjects(valueOld || {}, valueNew);
                        if (Object.keys(nestedDifferences).length > 0) {
                            differences[key] = nestedDifferences;
                        }
                    } else if (valueOld !== valueNew) {
                        differences[key] = valueNew;
                    }
                } else if (valueOld === undefined) {
                    differences[key] = valueNew;
                }
            }
        }
    
        return differences;
    };

    const dataSelectorsForm = async () => {
        try {
            const results = await Promise.allSettled([
                getAllDocumentTypeSettingService(),
                getAllDepartmentSettingService(),
                getAllIndicativeNumberSettingService()
            ]);

            let documentTypeList = [];
            let departmentList = [];
            let indicativeNumberList = [];
            let municipalityList = [];

            for (let index = 0; index < results.length; index++) {
                const elementResponse = results[index];

                if (elementResponse.status === AppConst.FULFILLED) {
                    switch (index) {
                        case 0:
                            documentTypeList = elementResponse?.value?.map((documentType) => ({
                                label: documentType?.name,
                                value: documentType?.id
                            }));
                            break;
                        case 1:
                            departmentList = elementResponse?.value?.map((department) => ({
                                label: department?.name,
                                value: department?.id
                            }));
                            break;
                        case 2:
                            indicativeNumberList = elementResponse?.value?.map((indicativeNumber) => ({
                                label: indicativeNumber?.number,
                                value: indicativeNumber?.id
                            }));

                            if (!clientData?.telephoneCode || !clientData?.whatsappCode) {
                                let setCodes = {};
                                setCodes = {
                                    telephoneCode: !clientData?.telephoneCode ? indicativeNumberList[0] : clientData.telephoneCode,
                                    whatsappCode: !clientData?.whatsappCode ? indicativeNumberList[0] : clientData.whatsappCode
                                };
                                setValues({
                                    ...clientData,
                                    ...setCodes
                                });
                            }

                            break;
                        default:
                            break;
                    }
                }
            }

            if (clientData?.departmentResidence?.value) {
                municipalityList = await getAllMunicipality(clientData?.departmentResidence?.value);
            }

            setState({
                ...state,
                optionsTypeDocument: documentTypeList,
                optionsDepartment: departmentList,
                optionsNumberList: indicativeNumberList,
                optionsMunicipality: municipalityList
            });
        } catch {
            // TODO: Implement error alert with code error.
        }
    };

    const getAllMunicipality = async (idDepartment) => {
        try {
            const res = await getAllMunicipalitySettingService(idDepartment);
            return res.map((municipality) => ({ label: municipality.name, value: municipality.id }));
        } catch {
            // TODO: Implement error alert with code error.
        }
    }

    const filterUser = async (data) => {
        try {
            const res = await getFilterUsersManagementService(data);
            setState((prevState) => ({
                ...prevState,
                optionsUserSearch: res,
                isClear: true
            }));
        } catch (error) {
            // TODO: Implement error alert with error code
        }
    };

    const selectFilter = async (options) => {
        setState({ ...state, optionsUserSearch: [], textSearch: { value: options.label } })
        const {
            id,
            department,
            documentType,
            indicativeNumber,
            indicativeNumberWhatsapp,
            lastName,
            municipality,
            name,
            documentNumber,
            numberPhone,
            numberPhoneWhatsapp,
            contactAddress,
            contactEmail
        } = options;

        const telephoneCode = indicativeNumber?.number && indicativeNumber?.id
            ? { ...indicativeNumber, label: indicativeNumber.number, value: indicativeNumber.id }
            : optionsNumberList[0];

        const whatsappCode = indicativeNumberWhatsapp?.number && indicativeNumberWhatsapp?.id
            ? { ...indicativeNumberWhatsapp, label: indicativeNumberWhatsapp.number, value: indicativeNumberWhatsapp.id }
            : optionsNumberList[0];

        const documentTypeCode = documentType
            ? { label: documentType.name, value: documentType.id }
            : undefined;

        const municipalityResidence = municipality
            ? { label: municipality.name, value: municipality.id }
            : undefined;

        const departmentResidence = department
            ? { label: department.name, value: department.id }
            : undefined;

        if (departmentResidence) {
            getAllMunicipality(departmentResidence?.value)
                .then((res) => {
                    setState({
                        ...state,
                        optionsMunicipality: res,
                        textSearch: { value: options.label }
                    })
                })
                .catch(() => {
                    // TODO: Implement error alert with code error.
                })
        }

        const value = {
            ...values,
            id,
            departmentResidence,
            documentType: documentTypeCode,
            telephoneCode,
            whatsappCode,
            municipalityResidence,
            lastName,
            name,
            documentNumber,
            telephone: numberPhone,
            contactAddress,
            contactEmail,
            whatsapp: numberPhoneWhatsapp
        }
        value.originalClientData = { ...value }

        try {
            await setValues(value);
            // setDataSaleReducer("passageSales", value);
            handleSubmit();
        } catch {
            // TODO: Implement error alert with code error.
        }
    }

    const onChange = async (data, target) => {
        const value = data && data.target ? data.target.value : data;
        const clientData = { ...values, [target]: value };

        if (target === 'departmentResidence') {
            clientData.departmentResidence = value;
            clientData.municipalityResidence = undefined;
            const municipalityList = await getAllMunicipality(value?.value);
            setState({
                ...state,
                optionsMunicipality: municipalityList
            });
            setFieldTouched("municipalityResidence", true);
        } else {
            setState((prevState) => ({
                ...prevState,
                [target]: value
            }))
        }

        setValues(clientData);
    };

    const handlerClearFilterParams = () => {
        setState((prevState) => ({
            ...prevState,
            textSearch: { value: "" },
            optionsUserSearch: [],
            isClear: false,
        }))
        setValues({
            id: '',
            documentType: undefined,
            documentNumber: '',
            name: '',
            lastName: '',
            telephone: '',
            telephoneCode: undefined,
            whatsapp: '',
            whatsappCode: undefined,
            departmentResidence: undefined,
            municipalityResidence: undefined,
            contactAddress: '',
            contactEmail: ''
        });
        setTouched({});
    };

    const onHandlerOnSearch = (e) => {
        const trimmedValue = e.trim();
        if (trimmedValue) {
            filterUser(trimmedValue);
        } else {
            setState((prevState) => ({
                ...prevState,
                optionsUserSearch: [],
            }));
        }
    };

    const debouncedHandleSearch = useMemo(
        () => debounce(
            onHandlerOnSearch, 390
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <div className="client-management__content">
                <AutoComplete
                    autoFocus
                    ref={inputRefs[0]}
                    options={optionsUserSearch}
                    value={textSearch}
                    disabled={isDisabled}
                    className="client-management__input-search"
                    onSelect={(value, options) => selectFilter(options)}
                    onChange={(e) => onChange({ value: e }, "textSearch")}
                    onSearch={debouncedHandleSearch}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            const inputValue = e.target.value;
                            const selectedOption = optionsUserSearch.find(option => option.documentNumber === inputValue);
                            if (selectedOption) selectFilter(selectedOption);
                            inputRefs[0].current.focus();
                        }
                    }}
                >
                    <Input
                        allowClear={{
                            clearIcon: (
                                <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                />
                            )
                        }}
                        className="client-management__input__content"
                        placeholder="Buscar por nombre ó numero de documento"
                        prefix={
                            <img
                                className="client-data__input__search"
                                src={Assets.SharedIcons.icon_search}
                                alt="icon_search"
                            />
                        }
                    />
                </AutoComplete>
                {isClear && (
                    <button
                        className='invoice-management__clear'
                        onClick={() => handlerClearFilterParams()}
                    >
                        <img
                            src={Assets.SalesIcons.icon_clear}
                            alt="icon_clear"
                        />
                    </button>
                )}
            </div>
            <div className='grid-x align-center'>
                <div className='small-7'>
                    <div className='grid-x client-management__header'>
                        <div>
                            <span className='client-management__title'>Gestión del cliente</span>
                        </div>
                    </div>
                    <div className='grid-x small-12 client-management__box'>
                        <div className="small-12 medium-6 client-management__content">
                            <label className="grid-x client-data__label">
                                Tipo de documento
                                <div className="client-management__label__icon" />
                            </label>
                            <Select
                                value={values.documentType}
                                className="client-management__select"
                                onChange={(e, event) => onChange(event, 'documentType')}
                                onBlur={(value) => handleBlur('documentType')(value)}
                                status={errors.documentType && touched.documentType ? "error" : ""}
                                suffixIcon={
                                    <img
                                        src={Assets.SharedIcons.icon_down_arrow}
                                        alt="icon_down_arrow"
                                    />
                                }
                                options={optionsTypeDocument}
                                placeholder="Selecciona el tipo de documento"
                                onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
                            />
                            <span className="grid-x client-data__errors">{touched.documentType ? errors.documentType : null}</span>
                        </div>
                        <div className="small-12 medium-6 client-management__content client-management__content__right">
                            <label className=" grid-x client-management__label client-management__label--mod">
                                Número de documento
                                <div className="client-management__label__icon" />
                            </label>
                            <Input
                                value={values.documentNumber}
                                onChange={(value) => onChange(value, 'documentNumber')}
                                onBlur={(value) => handleBlur('documentNumber')(value)}
                                status={errors.documentNumber && touched.documentNumber ? "error" : ""}
                                className="client-data__input__content"
                                allowClear={{
                                    clearIcon: (
                                        <img
                                            width={28}
                                            src={Assets.SharedIcons.icon_square_close}
                                            alt="icon_logo_vertical"
                                        />
                                    )
                                }}
                                placeholder="Número de documento sin puntos ni espacios"
                            />
                            <span className="grid-x client-data__errors">{touched.documentNumber ? errors.documentNumber : null}</span>
                        </div>
                        <div className="small-12 medium-6 client-management__content">
                            <label className=" grid-x client-management__label">
                                Nombre del cliente
                                <div className="client-management__label__icon" />
                            </label>
                            <Input
                                value={values.name}
                                onChange={(value) => onChange(value, 'name')}
                                onBlur={(value) => handleBlur('name')(value)}
                                status={errors.name && touched.name ? "error" : ""}
                                className="client-data__input__content"
                                allowClear={{
                                    clearIcon: (
                                        <img
                                            width={28}
                                            src={Assets.SharedIcons.icon_square_close}
                                            alt="icon_logo_vertical"
                                        />
                                    )
                                }}
                                placeholder="Escribe el nombre de tu cliente"
                            />
                            <span className="grid-x client-data__errors">{touched.name ? errors.name : null}</span>
                        </div>
                        <div className="small-12 medium-6 client-management__content client-management__content__right">
                            <label className=" grid-x client-management__label client-management__label--mod">
                                Apellido del cliente
                                <div className="client-management__label__icon" />
                            </label>
                            <Input
                                value={values.lastName}
                                onChange={(value) => onChange(value, 'lastName')}
                                onBlur={(value) => handleBlur('lastName')(value)}
                                status={errors.lastName && touched.lastName ? "error" : ""}
                                className="client-data__input__content"
                                allowClear={{
                                    clearIcon: (
                                        <img
                                            width={28}
                                            src={Assets.SharedIcons.icon_square_close}
                                            alt="icon_logo_vertical"
                                        />
                                    )
                                }}
                                placeholder="Escribe el apellido completo de tu cliente"
                            />
                            <span className="grid-x client-data__errors">{touched.lastName ? errors.lastName : null}</span>
                        </div>
                        <div className="small-12 medium-6 client-management__content">
                            <label className=" grid-x client-management__label">
                                Tetelefono de contacto
                                <div className="client-management__label__icon" />
                            </label>
                            <div className="grid-x client-management__label--mod justify-content-between">
                                <Select
                                    className="client-management__select indicative-number"
                                    suffixIcon={
                                        <img
                                            src={Assets.SharedIcons.icon_down_arrow}
                                            alt="icon_down_arrow"
                                        />
                                    }
                                    options={optionsNumberList}
                                    value={values.telephoneCode}
                                    onChange={(e, event) => onChange(event, 'telephoneCode')}
                                    onBlur={(value) => handleBlur('telephoneCode')(value)}
                                    status={errors.telephoneCode && touched.telephoneCode ? "error" : ""}
                                    onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
                                />
                                <Input
                                    value={values.telephone}
                                    onChange={(value) => onChange(value, 'telephone')}
                                    onBlur={(value) => handleBlur('telephone')(value)}
                                    status={errors.telephone && touched.telephone ? "error" : ""}
                                    className="client-data__input__content phone-number"
                                    allowClear={{
                                        clearIcon: (
                                            <img
                                                width={28}
                                                src={Assets.SharedIcons.icon_square_close}
                                                alt="icon_logo_vertical"
                                            />
                                        )
                                    }}
                                    placeholder="Escribe los 10 digitos de tu telefono"
                                />
                            </div>
                            <span className="grid-x client-data__errors">{touched.telephone ? errors.telephone : null}</span>
                        </div>
                        <div className="small-12 medium-6 client-management__content client-management__content__right">
                            <div className="grid-x justify-content-between client-management__label--mod">
                                <label className=" grid-x client-management__label">
                                    Wahtsapp
                                </label>
                            </div>
                            <div className="grid-x client-data__label--mod justify-content-between">
                                <Select
                                    className="client-management__select indicative-number"
                                    suffixIcon={
                                        <img
                                            src={Assets.SharedIcons.icon_down_arrow}
                                            alt="icon_down_arrow"
                                        />
                                    }
                                    options={optionsNumberList}
                                    value={values.whatsappCode}
                                    onChange={(e, event) => onChange(event, 'whatsappCode')}
                                    onBlur={(value) => handleBlur('whatsappCode')(value)}
                                    onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
                                />
                                <Input
                                    value={values.whatsapp}
                                    onChange={(value) => onChange(value, 'whatsapp')}
                                    onBlur={(value) => handleBlur('whatsapp')(value)}
                                    className="client-data__input__content phone-number"
                                    allowClear={{
                                        clearIcon: (
                                            <img
                                                width={28}
                                                src={Assets.SharedIcons.icon_square_close}
                                                alt="icon_logo_vertical"
                                            />
                                        )
                                    }}
                                    placeholder="Escribe los digitos de tu whatsapp"
                                />
                            </div>
                        </div>
                        <div className="small-12 medium-6 client-management__content">
                            <label className="client-management__label client-management__label--mod">
                                Departamento de residencia
                            </label>
                            <Select
                                value={values.departmentResidence}
                                onChange={(e, event) => onChange(event, 'departmentResidence')}
                                className="client-management__select"
                                suffixIcon={
                                    <img
                                        src={Assets.SharedIcons.icon_down_arrow}
                                        alt="icon_down_arrow"
                                    />
                                }
                                options={optionsDepartment}
                                placeholder="Seleccione el departamento"
                                onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
                            />
                        </div>
                        <div className="small-12 medium-6 client-management__content client-management__content__right">
                            <label className="client-management__label client-management__label--mod">
                                Municipio de residencia
                            </label>
                            <Select
                                value={values.municipalityResidence}
                                onChange={(e, event) => onChange(event, 'municipalityResidence')}
                                onBlur={(value) => handleBlur('municipalityResidence')(value)}
                                status={errors.municipalityResidence && touched.municipalityResidence ? "error" : ""}
                                className="client-management__select"
                                suffixIcon={
                                    <img
                                        src={Assets.SharedIcons.icon_down_arrow}
                                        alt="icon_down_arrow"
                                    />
                                }
                                disabled={!values?.departmentResidence || isDisabled}
                                options={optionsMunicipality}
                                placeholder="Seleccione municipio"
                                onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
                            />
                            <span className="grid-x client-data__errors">{touched.municipalityResidence ? errors.municipalityResidence : null}</span>
                        </div>
                        <div className="small-12 medium-6 client-management__content">
                            <label className="client-management__label">
                                Dirección de contacto (Opcional)
                            </label>
                            <Input
                                value={values.contactAddress}
                                onChange={(value) => onChange(value, 'contactAddress')}
                                onBlur={(value) => handleBlur('contactAddress')(value)}
                                status={errors.contactAddress && touched.contactAddress ? "error" : ""}
                                className="client-data__input__content"
                                allowClear={{
                                    clearIcon: (
                                        <img
                                            width={28}
                                            src={Assets.SharedIcons.icon_square_close}
                                            alt="icon_logo_vertical"
                                        />
                                    )
                                }}
                                placeholder="Escribe una dirección de contacto"
                            />
                            <span className="grid-x client-data__errors">{touched.contactAddress ? errors.contactAddress : null}</span>
                        </div>
                        <div className="small-12 medium-6 client-management__content client-management__content__right">
                            <label className="client-management__label client-management__label--mod">
                                Correo electronico
                            </label>
                            <Input
                                value={values.contactEmail}
                                onChange={(value) => onChange(value, 'contactEmail')}
                                onBlur={(value) => handleBlur('contactEmail')(value)}
                                status={errors.contactEmail && touched.contactEmail ? "error" : ""}
                                className="client-data__input__content"
                                allowClear={{
                                    clearIcon: (
                                        <img
                                            width={28}
                                            src={Assets.SharedIcons.icon_square_close}
                                            alt="icon_logo_vertical"
                                        />
                                    )
                                }}
                                placeholder="Escribe el correo electronico de tu cliente"
                            />
                            <span className="grid-x client-data__errors">{touched.contactEmail ? errors.contactEmail : null}</span>
                        </div>
                    </div>
                    <div>
                        <button className="client-management__button"
                            onClick={() => handleNext()}
                        >
                            <span className='client-management__button__text'>
                                Guardar
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}