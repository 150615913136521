//Libraries
import { Route, Routes } from "react-router-dom";

//Views - Pages
import VehiclesPage from "../pages/vehicles/vehicles/vehicles.page";
import AddVehiclesPage from "../pages/vehicles/add-vehicle/add-vehicle.page";
import VehicleOverhaulPage from "../pages/vehicles/vehicles/vehicle-overhaul/vehicle-overhaul.page";

const VehiclesRoutes = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<VehiclesPage />} />
        <Route path="/addVehicle" element={<AddVehiclesPage />} />
        <Route path="/vehicleOverhaul" element={<VehicleOverhaulPage />} />
      </Routes>
    </>
  );
};

export default VehiclesRoutes