//Libraries
import { Route, Routes } from "react-router-dom";

//Views - Pages
import DriversPage from "../pages/drivers/drivers/drivers.page";
import CreateDriversPage from "../pages/drivers/create-drivers/create-drivers.page";

const DriversRoutes = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<DriversPage />} />
        <Route path="/createDrivers" element={<CreateDriversPage />} />
      </Routes>
    </>
  );
};

export default DriversRoutes