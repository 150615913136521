import React, { useState } from "react";
import { Assets } from "../../../../assets";
import { DatePicker, Input, Select } from "antd";
import dayjs from 'dayjs';

//Styles
import "./vehicle-overhaul.page.scss";

const VehicleOverhaulPage = () => {

  const data = [
    {
      title:"LUCES",
      items:[
        "Frontales de servicio alta y baja.",
        "Direcciones delanteros (Izq. - Der.).",
        "Luz de parqueo."
      ]
    },
    {
      title:"LLANTAS",
      items:[
        "Profundas y sin abultamiento y 2 cm de huella minima.",
        "Todas las ruedas tienen sus espárragos completos.",
        "Presión de inflado."
      ]
    },
    {
      title:"OTROS",
      items:[
        "Distintivos de Cotranscat.",
        "Calcomanías de ¿Como conduzco?.",
        "Ficheros de malelas.",
        "Cinto antidoslzante en las escaleras.",
        "Botiquín.",
        "Extintor.",
        "Orden y aseo.",
        "Chapas."
      ]
    },
    {
      title:"ESTADO MECÁNICO",
      items:[
        "Radiador.",
        "Mangueras.",
        "Estado de correas.",
        "Booster y bomba.",
        "Barra de dirección.",
        "Filtros.",
        "Lubricante y engrase."
      ]
    }
  ]

  const data2 = [
    {
      title:"CABINA",
      items:[
        "Espejo central.",
        "Espejo laterales.",
        "Alarma de retroceso.",
        "Pito.",
        "Freno de servicio.",
        "Freno de emergencia.",
        "Cinturón de segundo conductor y pasajero.",
        "Estado de puertas.",
        "Panoramico delantero y trasero.",
        "Limpia brisas.",
        "Estado de tapicería.",
        "Indicadores (Hidraúlico, velocímetro, temp.).",
        "Bobinas y cables.",
        "Iluminación de cabina.",
        "Nivel de fluidos, hidráulico, refrigerante, dirección, frenos y agua de batería).",
      ]
    },
    {
      title:"DISPOSITIVO DE VELOCIDAD",
      items:[
        "Estado de dispositivo de velocidad."
      ]
    },
    {
      title:"EQUIPO CARRETERA",
      items:[
        "Cuñas de madera.",
        "Gato.",
        "Cruceta."
      ]
    },
    {
      title:"HERRAMIENTAS",
      items:[
        "Alicate.",
        "Destornilladores.",
        "Juego de llaves 3/8 hasta 7/8.",
        "Señales reflectivas.",
        "Linterna.",
        "Firmas."
      ]
    }
  ]

  const INITIAL_STATE = {
    signature : null,
    selectedFilter: 0,
    weekSelected: dayjs()
  }

  const [state, setState] = useState(INITIAL_STATE)

  const { signature, selectedFilter, weekSelected } = state

  const showDate = Array.from({ length: 7 }, (_, index) =>
    weekSelected.startOf('week').add(index, 'day')
  );

  return (
    <>
      <div className="vehicle-overhaul__content">
        <span className="vehicle-overhaul__subtitle">Vehículo</span>
        <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
        <span className="vehicle-overhaul__subtitle">Revisión de vehículo</span>
      </div>
      <div className="grid-x justify-content-center">
        <div className="medium-9 vehicle-overhaul__title__content">
          <img
            src={Assets.VehiclesIcons.icon_overhaul}
            alt="icon_overhaul"
            height={34}
            width={34}
          />
          <span 
            className="vehicle-overhaul__title"
          >
            Revisar vehículo
          </span>
        </div>
        <div className="grid-x medium-9 justify-content-between">
          <div className="grid-x small-12 medium-7 vehicle-overhaul__info">
            <div className="small-12 vehicle-overhaul__info__header">
              <img
                src={Assets.SharedIcons.icon_vehicles_card}
                alt="icon_vehicles_card"
                height={20}
                width={20}
              />
              <span className="vehicle-overhaul__text__title">Información del vehículo</span>
            </div>
            <div className="small-12 medium-5">
              <div className="grid-x vehicle-overhaul__info__content">
                <span className="medium-8 vehicle-overhaul__text__subtitle">Vehículo tipo</span>
                <span className="medium-4 vehicle-overhaul__text">Buseta</span>
              </div>
              <div className="grid-x vehicle-overhaul__info__content">
                <span className="medium-8 vehicle-overhaul__text__subtitle">Placa</span>
                <span className="medium-4 vehicle-overhaul__text">CUC144371</span>
              </div>
              <div className="grid-x vehicle-overhaul__info__content">
                <span className="medium-8 vehicle-overhaul__text__subtitle">Código del vehículo</span>
                <span className="medium-4 vehicle-overhaul__text">60</span>
              </div>
            </div>
            <div className="medium-2"></div>
            <div className="small-12 medium-5">
              <div className="grid-x vehicle-overhaul__info__content">
                <span className="medium-8 vehicle-overhaul__text__subtitle">Marca del vehículo</span>
                <span className="medium-4 vehicle-overhaul__text">Marca</span>
              </div>
              <div className="grid-x vehicle-overhaul__info__content">
                <span className="medium-8 vehicle-overhaul__text__subtitle">Número de asientos</span>
                <span className="medium-4 vehicle-overhaul__text">30 asientos</span>
              </div>
              <div className="grid-x vehicle-overhaul__info__content">
                <span className="medium-8 vehicle-overhaul__text__subtitle">Estado del vehículo</span>
                <span className="medium-4 vehicle-overhaul__text">Disponible</span>
              </div>
            </div>
          </div>
          <div className="grid-x small-12 medium-4 vehicle-overhaul__driver">
            <div className="small-12">
              <img
                src={Assets.SharedIcons.icon_drivers_card}
                alt="icon_drivers_card"
                height={20}
                width={20}
              />
              <span className="vehicle-overhaul__text__title">Información del conductor</span>
            </div>
            <div className="grid-x medium-12 small-12 vehicle-overhaul__info__content">
              <span className="medium-8 vehicle-overhaul__text__subtitle">Nombre del conductor</span>
              <span className="medium-4 vehicle-overhaul__text">José Diaz</span>
            </div>
            <div className="grid-x medium-12 small-12 vehicle-overhaul__info__content">
              <span className="medium-8 vehicle-overhaul__text__subtitle">N° de Licencia</span>
              <span className="medium-4 vehicle-overhaul__text">88222333</span>
            </div>
            <div className="grid-x medium-12 small-12 vehicle-overhaul__info__content">
              <span className="medium-8 vehicle-overhaul__text__subtitle">N° de contacto</span>
              <span className="medium-4 vehicle-overhaul__text">3003453000</span>
            </div>
          </div>
        </div>
        <div className="small-12 medium-9 vehicle-overhaul__table">
          <div className="grid-x vehicle-overhaul__header">
            <Select
              onChange={(e) => {setState({ ...state, selectedFilter: e })}}
              defaultValue={0}
              value={selectedFilter}
              className="medium-3 vehicle-overhaul__select"
              suffixIcon={
                <img
                  src={Assets.SharedIcons.icon_down_arrow}
                  alt="icon_down_arrow"
                />
              }
              options={[
                {
                  label: "Revisión por días",
                  value: 0
                },
                {
                  label: "Resultado semanal",
                  value: 1
                }
              ]}
              placeholder={'Selecciona la categoria'}
            />
            <div className='grid-x justify-content-between vehicle-overhaul__select-week'>
              <button>
                <img
                  src={Assets.SalesIcons.icon_back}
                  alt='icon_back'
                />
              </button>
                <DatePicker
                  defaultValue={dayjs()}
                  format={(value)=>{
                    return `${dayjs(value)?.format("DD/MMMM/YYYY")}`;
                  }
                  }
                  picker="date"
                  allowClear={false}
                  variant="borderless"
                  suffixIcon={null}
                  placeholder=""
                  className='vehicle-overhaul__select-week__date'
                  superNextIcon={
                    <img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />
                  }
                  superPrevIcon={
                    <img src={Assets.SalesIcons.icon_back} alt="icon_back" />
                  }
                  nextIcon={
                    <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
                  }
                  prevIcon={
                    <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
                  }
                  popupClassName="vehicle-overhaul__select-week__date__picker"
                  
                />
              <button>
                <img
                  src={Assets.SalesIcons.icon_forward}
                  alt='icon_forward'
                />
              </button>
            </div>
          </div>
          { selectedFilter === 0 && (
            <div className="grid-x vehicle-overhaul__table__content">
            <div className="small-12 medium-6 vehicle-overhaul__content__right">
              <div>
                { data.map((item, index) => {
                    return (
                      <div key={index} className="vehicle-overhaul__text__list__box">
                        <div className="grid-x align-center-middle vehicle-overhaul__text__list__content">
                          <span className="vehicle-overhaul__text__list">{item.title}</span>
                        </div>
                      { item.items.map((data, indexData) => {
                          return (
                            <div key={indexData} className="grid-x vehicle-overhaul__text__list__item">
                              <span className="small-5 medium-6 vehicle-overhaul__text grid-x align-middle">
                                {data}
                              </span>
                              <div className="grid-x small-7 medium-6 justify-content-around">
                                <button className="vehicle-overhaul__text__button-green">
                                  <span>Bueno</span>
                                  <img
                                    src={Assets.VehiclesIcons.icon_happy_face_green}
                                    alt="icon_happy_face_green"
                                  />
                                </button>
                                <button className="vehicle-overhaul__text__button-pink">
                                  <span>Malo</span>
                                  <img
                                    src={Assets.VehiclesIcons.icon_sad_face_black}
                                    alt="icon_sad_face_black"
                                  />
                                </button>
                              </div>
                            </div>
                          )
                        })
                      }
                      </div>
                    )
                  }
                )}
                <div className="vehicle-overhaul__box-input">
                  <span className="grid-x vehicle-overhaul__text__list">
                    Observaciones
                  </span>
                  <Input.TextArea 
                    rows={5} 
                    placeholder="Descripcion del campo"
                    className='modal-transfer__text-area'
                  />
                  <span className="grid-x vehicle-overhaul__text__list">
                    Inspeccionado por:
                  </span>
                  <Input
                    allowClear={{
                      clearIcon: (
                        <img
                          width={28}
                          src={Assets.SharedIcons.icon_square_close}
                          alt="icon_logo_vertical"
                        />
                      )
                    }}
                    className="sales-table__input__content"
                    placeholder="Escribe el nombre del responsable"
                    prefix={
                      <img
                        className="sales-table__input__search"
                        src={Assets.SharedIcons.icon_search}
                        alt="icon_search"
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="small-12 medium-6 vehicle-overhaul__content__left">
              <div>
                { data2.map((item, index) => {
                    return (
                      <div key={index} className="vehicle-overhaul__text__list__box">
                        <div className="grid-x align-center-middle vehicle-overhaul__text__list__content">
                          <span className="vehicle-overhaul__text__list">{item.title}</span>
                        </div>
                      { item.items.map((data, indexData) => {
                          return (
                            <div key={indexData} className="grid-x vehicle-overhaul__text__list__item">
                              <span className="small-5 medium-6 vehicle-overhaul__text grid-x align-middle">
                                {data}
                              </span>
                              <div className="grid-x small-7 medium-6 justify-content-around">
                                <button className="vehicle-overhaul__text__button-green">
                                  <span>Bueno</span>
                                  <img
                                    src={Assets.VehiclesIcons.icon_happy_face_green}
                                    alt="icon_happy_face_green"
                                  />
                                </button>
                                <button className="vehicle-overhaul__text__button-pink">
                                  <span>Malo</span>
                                  <img
                                    src={Assets.VehiclesIcons.icon_sad_face_black}
                                    alt="icon_sad_face_black"
                                  />
                                </button>
                              </div>
                            </div>
                          )
                        })
                      }
                      </div>
                    )
                  }
                )}
                <div className="vehicle-overhaul__box-small">
                  <span className="grid-x vehicle-overhaul__text__list">
                    Observaciones
                  </span>
                    <Input.TextArea 
                      rows={5} 
                      placeholder="Descripcion del campo"
                      className='modal-transfer__text-area'
                    />
                    <span className="grid-x vehicle-overhaul__text__list">
                      Inspeccionado por:
                    </span>
                    <Input
                      allowClear={{
                        clearIcon: (
                          <img
                            width={28}
                            src={Assets.SharedIcons.icon_square_close}
                            alt="icon_logo_vertical"
                          />
                        )
                      }}
                      className="sales-table__input__content"
                      placeholder="Escribe el nombre del responsable"
                      prefix={
                        <img
                          className="sales-table__input__search"
                          src={Assets.SharedIcons.icon_search}
                          alt="icon_search"
                        />
                      }
                    />
                </div>
                <div>
                  <span className="grid-x vehicle-overhaul__text__list">
                    Nombre del conductor
                  </span>
                  <Input
                    allowClear={{
                      clearIcon: (
                        <img
                          width={28}
                          src={Assets.SharedIcons.icon_square_close}
                          alt="icon_logo_vertical"
                        />
                      )
                    }}
                    className="sales-table__input__content"
                    placeholder="Escribe el nombre del conductor"
                    prefix={
                      <img
                        className="sales-table__input__search"
                        src={Assets.SharedIcons.icon_search}
                        alt="icon_search"
                      />
                    }
                  />
                  <span
                    className="grid-x vehicle-overhaul__text__list"
                    style={{ marginTop: "20px" }}
                  >
                    Firma del conductor
                  </span>
                  <input
                    type="file"
                    id="fileSignature"
                    className='vehicle-data__hidden'
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={(event) => setState({...state, signature: URL.createObjectURL(event.target.files[0])})}
                  />
                  <label htmlFor="fileSignature">
                    <div
                      style={{
                        height: "150px",
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                      }}
                    >
                      { signature ?
                          <img 
                            src={signature}
                            alt="icon_signature"
                            style={{ objectFit: "contain", height: "100%", width: "100%" }}
                          />
                        :
                          <span style={{ paddingLeft: "10px"}}>Firma del conductor</span> 
                      }
                    </div>
                  </label>
                </div>
              </div>
            </div>
            </div>
          )}
          { selectedFilter === 1 && (
            <div className="vehicle-overhaul__week">
              <div className="grid-x vehicle-overhaul__week__container">
                <div className="grid-x align-center-middle vehicle-overhaul__week__day vehicle-overhaul__week__day--mod vehicle-overhaul__week__title">ITEM</div>
                { showDate.map((item, index) => {
                  return (
                    <div key={index} className="grid-y vehicle-overhaul__week__day text-center">
                      <span className="vehicle-overhaul__week__info vehicle-overhaul__week__info--mod">{item?.format('dddd')}</span>
                      <span className="vehicle-overhaul__week__info">{item?.format('DD/MMMM')}</span>
                      <span className="vehicle-overhaul__week__result">Resultados</span>
                    </div>
                  )
                })}
              </div>
              <div className="vehicle-overhaul__week__content">
                <div className="grid-x">
                  <div className="grid-x vehicle-overhaul__week__day vehicle-overhaul__week__day--mod">
                    <div className="flex-container medium-12">
                      <div className="vehicle-overhaul__week__label">LUCES</div>
                      <div className="vehicle-overhaul__week__day__box">
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Frontales de servicio alta y baja</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Direcciones delanteros (Izq. - Der.)</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Direcciones trasero (Izq. - Der.)</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Luz de parqueo</div>
                      </div>
                    </div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Malo</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Malo</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="grid-x vehicle-overhaul__week__day vehicle-overhaul__week__day--mod">
                    <div className="flex-container medium-12">
                      <div className="vehicle-overhaul__week__label">CABINA</div>
                      <div className="vehicle-overhaul__week__day__box">
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Espejo central.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Espejo laterales.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Alarma de retroceso.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Pito.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Freno de servicio.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Freno de emergencia.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Cinturón de segundo conductor y pasajero.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Estado de puertas.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Panoramico delantero y trasero.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Limpia brisas.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Estado de tapicería.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Indicadores (Hidraúlico, velocímetro, temp.).</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Bobinas y cables.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Iluminación de cabina.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content vehicle-overhaul__week__day__content--mod">Nivel de fluidos, hidráulico, refrigerante, dirección, frenos y agua de batería.</div>
                      </div>
                    </div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="grid-x vehicle-overhaul__week__day vehicle-overhaul__week__day--mod">
                    <div className="flex-container medium-12">
                      <div className="vehicle-overhaul__week__label">LLANTAS</div>
                      <div className="vehicle-overhaul__week__day__box">
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content vehicle-overhaul__week__day__content--mod">Profundas y sin abultamiento y 2 cm de huella minima.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Todas las ruedas tienen sus espárragos completos.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Presión de inflado.</div>
                      </div>
                    </div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--mod"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="grid-x vehicle-overhaul__week__day vehicle-overhaul__week__day--mod">
                    <div className="flex-container medium-12">
                      <div className="vehicle-overhaul__week__label">OTROS</div>
                      <div className="vehicle-overhaul__week__day__box">
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Distintivos de Cotranscat.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Calcomanías de ¿Como conduzco?.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Ficheros de malelas.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Cinto antidoslzante en las escaleras.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Botiquín.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Extintor.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Orden y aseo.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Chapas.</div>
                      </div>
                    </div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="grid-x vehicle-overhaul__week__day vehicle-overhaul__week__day--mod">
                    <div className="flex-container medium-12">
                      <div className="vehicle-overhaul__week__label vehicle-overhaul__week__label--mod">
                        <span>DISP. DE</span>
                        <br/>
                        <span>VELOCIDAD</span>
                      </div>
                      <div className="vehicle-overhaul__week__day__box">
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content vehicle-overhaul__week__day__content--modification">Estado de dispositivo de velocidad.</div>
                      </div>
                    </div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--modification">Bueno</div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--modification"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--modification"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--modification"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--modification"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--modification"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info vehicle-overhaul__week__result-info--modification"></div>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="grid-x vehicle-overhaul__week__day vehicle-overhaul__week__day--mod">
                    <div className="flex-container medium-12">
                      <div className="vehicle-overhaul__week__label">ESTADO MECÁNICO</div>
                      <div className="vehicle-overhaul__week__day__box">
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Radiador.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Mangueras.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Estado de correas.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Booster y bomba.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Barra de dirección.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Filtros.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Lubricante y engrase.</div>
                      </div>
                    </div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="grid-x vehicle-overhaul__week__day vehicle-overhaul__week__day--mod">
                    <div className="flex-container medium-12">
                      <div className="vehicle-overhaul__week__label vehicle-overhaul__week__label--mod">
                        <span>EQUIPO</span> 
                        <br/>
                        <span>CARRETERA</span>
                      </div>
                      <div className="vehicle-overhaul__week__day__box">
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Cuñas de madera.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Gato.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Cruceta.</div>
                      </div>
                    </div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                </div>
                <div className="grid-x">
                  <div className="grid-x vehicle-overhaul__week__day vehicle-overhaul__week__day--mod">
                    <div className="flex-container medium-12">
                      <div className="vehicle-overhaul__week__label">HERRAMIENTAS</div>
                      <div className="vehicle-overhaul__week__day__box">
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Alicate.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Destornilladores.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Juego de llaves 3/8 hasta 7/8.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Señales reflectivas.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Linterna.</div>
                        <div className="vehicle-overhaul__week__info vehicle-overhaul__week__day__content">Firmas.</div>
                      </div>
                    </div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                    <div className="vehicle-overhaul__week__result-info">Bueno</div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                  <div className="vehicle-overhaul__week__day">
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                    <div className="vehicle-overhaul__week__result-info"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="grid-x vehicle-overhaul__buttons">
            <button className="vehicle-overhaul__button-back">
              <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
              <span>Regresar</span>
            </button>
            <button className="vehicle-overhaul__button-save" disabled>
              <span>Guardar</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleOverhaulPage;
