// Constants
import ShiftsEndPoints from "./constants/shifts.end-points"
import SalesEndPoints from "./constants/sales.end-points"

// Infrastructure
import Http from "./infrastructure/http.infrastructure"

// Services
import { getTokenService } from "./authentication.services"

export async function getAllTravelByRangeDateService( initialDate, finalDate, idRoute) {
    try {
        const { data: responseGetAllTravelByRangeDate, error } = await Http.get(ShiftsEndPoints.GET_TRAVEL_BY_RANGE_DATE, { initialDate: initialDate, finalDate: finalDate, idRoute })
        if (responseGetAllTravelByRangeDate && responseGetAllTravelByRangeDate.status) {
            return responseGetAllTravelByRangeDate.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createTravelService(data) {
    try {
        let token = getTokenService()
        const { data: responseGetAllTravelByRangeDate, error } = await Http.post(SalesEndPoints.ASSOCIATE_VEHICLE_TO_TRAVEL, data, token)
        if (responseGetAllTravelByRangeDate && responseGetAllTravelByRangeDate.status) {
            return responseGetAllTravelByRangeDate.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateTravelService(data,id) {
    try {
        const urlWithParams = ShiftsEndPoints.UPDATE_TRAVEL.replace(":id",id)
        const { data: responseGetAllTravelByRangeDate, error } = await Http.put(urlWithParams,data)
        if (responseGetAllTravelByRangeDate && responseGetAllTravelByRangeDate.status) {
            return responseGetAllTravelByRangeDate.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function deleteVehicleService(id) {

    try {
        let token = getTokenService()
        const urlWithParams = ShiftsEndPoints.REMOVE_VEHICLE.replace(":id",id)
        const { data: removeVehicleOption, error } = await Http.delete(urlWithParams, null, token)
        if (removeVehicleOption && removeVehicleOption.status) {
            return removeVehicleOption.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}