//Actions
import { setStateSidesNavReducer } from '../../../../../../storage/reducers/sides-nav/sides-nav.actions'

//Assets
import { Assets } from '../../../../../../assets'

//Libraries
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

//Styles
import "./sidebar-info.component.scss"
import { getUserRolesService, getUserService } from '../../../../../../services/authentication.services'
import AppConst from '../../../../../../core/constants/app.const'

const SidebarInfoComponent = (props) => {

  const { 
    //Variables
    isDrawer,
    innerWidth
  } = props;

  const INITIAL_STATE = {
    activeIconIndex: 0,
    listModules: [],
    userData: ""
  }
  
  const [state, setState] = useState(INITIAL_STATE);
  const { 
    activeIconIndex,
    listModules, 
    userData 
  } = state
  const role = getUserRolesService()
  const location = useLocation();

  const moduleActive = (index) => {
    setState((prevState) =>({
      ...prevState,
      activeIconIndex: index
    }))
  }

  const typeToRoute = {
    [AppConst.TYPE_ROUTES.SALES]: "/sales",
    [AppConst.TYPE_ROUTES.SPREADSHEET]: "/boardingSheet",
    [AppConst.TYPE_ROUTES.CASH]: "/cash",
    [AppConst.TYPE_ROUTES.DRIVERS]: "/drivers",
    [AppConst.TYPE_ROUTES.SHIFTS]: "/shifts",
    [AppConst.TYPE_ROUTES.VEHICLES]: "/vehicles",
    [AppConst.TYPE_ROUTES.SETTINGS]: "/settings",
  };

  const handleRedirectTo = (module) => { 
    return typeToRoute[module] || "/";
  };

  const typeToIcon = {
    [AppConst.TYPE_ROUTES.SALES]: { icon: Assets.SharedIcons.icon_sales, activeIcon: Assets.SharedIcons.icon_sales_active },
    [AppConst.TYPE_ROUTES.SPREADSHEET]: { icon: Assets.SharedIcons.icon_spreadsheet, activeIcon: Assets.SharedIcons.icon_spreadsheet_active },
    [AppConst.TYPE_ROUTES.CASH]: { icon: Assets.SharedIcons.icon_box, activeIcon: Assets.SharedIcons.icon_box_active },
    [AppConst.TYPE_ROUTES.DRIVERS]: { icon: Assets.SharedIcons.icon_drivers, activeIcon: Assets.SharedIcons.icon_drivers_active },
    [AppConst.TYPE_ROUTES.SHIFTS]: { icon: Assets.SharedIcons.icon_shifts, activeIcon: Assets.SharedIcons.icon_shifts_active },
    [AppConst.TYPE_ROUTES.VEHICLES]: { icon: Assets.SharedIcons.icon_vehicles, activeIcon: Assets.SharedIcons.icon_vehicles_active },
    [AppConst.TYPE_ROUTES.SETTINGS]: { icon: Assets.SharedIcons.icon_settings, activeIcon: Assets.SharedIcons.icon_settings_hover }
  };

  useEffect(() => {
    const userDataFromService = getUserService();
    const pathname = (location.pathname).split("/")[1];

      setState(prevState => ({ 
        ...prevState, 
        userData: userDataFromService, 
        listModules: userDataFromService.modules,
        activeIconIndex: userDataFromService.modules.findIndex(item => item.path === pathname)
      }));

    if(innerWidth < 639) {
      if (state.listModules.includes("Ventas")) {
        setState((prevState) => ({
          ...prevState,
          listModules: prevState.listModules.filter(item => item === "Ventas")
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerWidth]);

  return (
    <>
      <div
        className={
          "grid-y align-center-middle " +
          (isDrawer
            ? "sidebar-main__img-profile__content"
            : "sidebar-main__img-profile__container")
        }
      >
        <img
          src={Assets.SharedIcons.icon_user_profile}
          alt='icon_user_profile'
          className={'sidebar-info__img-profile ' + (
            !isDrawer
            ? "sidebar-info__img-profile--mod"
            : ""
          )}
        />
        <span 
          className={isDrawer ? "sidebar-info__title" : "sidebar-info__hide"}
        >
            {`${userData?.name}`} <br />  {`${userData?.lastName}`}
        </span>
        <span className={isDrawer ? "sidebar-info__subtitle" : "sidebar-info__hide"}>
          {`${role.name}`}
        </span>
      </div>
      <List className='sidebar-info__list'>
        <Divider className='sidebar-info__line'/>
        {listModules.map(
          (module, index) => {
            const iconData = typeToIcon[module.type];
              return (
                <Link key={module.id} to={handleRedirectTo(module.type)} onClick={() => moduleActive(index)}>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: isDrawer ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isDrawer ? 3 : "auto",
                          justifyContent: "center",
                        }}
                        className="sidebar-info__selection"
                      >
                        <img src={index === activeIconIndex ? iconData.activeIcon : iconData.icon} alt={`icon_${module.module}`} />
                      </ListItemIcon>
                      <ListItemText
                        primary={module.module}
                        sx={{ opacity: isDrawer ? 1 : 0 }}
                        className={index === activeIconIndex ? `sidebar-info__text--active` : `sidebar-info__text`}
                      />
                    </ListItemButton>
                    <Divider className='sidebar-info__line'/>
                  </ListItem>
                </Link>
              )
          }
        )}
      </List>
    </>
  );
}

const mapStateToProps = ({ SidesNavReducer, AppReducer }) => {
  const { isDrawer } = SidesNavReducer;
  const {
    windowSize: { innerWidth },
  } = AppReducer;

  return {
    isDrawer,
    innerWidth
  };
};

const mapStateToPropsActions = {
  setStateSidesNavReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(SidebarInfoComponent);