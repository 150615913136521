// Actions
import { setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from "../../../../../storage/reducers/sales/sales.action";

//Assets
import { Assets } from "../../../../../assets";

// Constants
import AppConst from '../../../../../core/constants/app.const'

//Libraries
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AutoComplete, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { debounce } from "lodash";

// Services
import {
  getAllDocumentTypeService,
  getAllDepartmentService,
  getAllMunicipalityService,
  getAllIndicativeNumberService,
  getFilterUsersService
} from "../../../../../services/shared.services";

//Styles
import "./client-data.components.scss";

//Utils
import { passagesDataClientSchema } from "../../../../../utils/form-validations/sales.validators.utils";

const ClientDataComponents = (prop) => {
  const {
    // Actions
    setDataSalesStateReducer,
    setStateSaleReducer,
    setDataSaleReducer,
    // Variables
    isDisabled,
    isElectronic,
    nextButtonRef,
    ...clientData
  } = prop

  const [t] = useTranslation("translation");

  const INITIAL_STATE = {
    optionsTypeDocument: [],
    optionsDepartment: [],
    optionsMunicipality: [],
    optionsNumberList: [],
    optionsUserSearch: [],
    isDropdownOpen: false
  }

  const [state, setState] = useState(INITIAL_STATE)

  const {
    optionsTypeDocument,
    optionsDepartment,
    optionsMunicipality,
    optionsNumberList,
    optionsUserSearch,
    isDropdownOpen
  } = state

  const documentTypeRef = useRef();
  const documentNumberRef = useRef();
  const nameRef = useRef();
  const lastNameRef = useRef();
  const telephoneCodeRef = useRef();
  const telephoneRef = useRef();
  const whatsAppRef = useRef();
  const departmentResidenceRef = useRef();
  const municipalityResidenceRef = useRef();
  const defaultRef = useRef();

  const inputRefs = [
    documentTypeRef,
    documentNumberRef,
    nameRef,
    lastNameRef,
    telephoneCodeRef,
    telephoneRef,
    whatsAppRef,
    departmentResidenceRef,
    municipalityResidenceRef,
    defaultRef
  ];

  useEffect(() => {
    let touchesValues = {
      id: clientData.id ? true : false,
      documentType: clientData.documentType ? true : false,
      documentNumber: clientData.documentNumber ? true : false,
      name: clientData.name ? true : false,
      lastName: clientData.lastName ? true : false,
      telephone: clientData.telephone ? true : false,
      telephoneCode: clientData.telephoneCode ? true : false,
      whatsapp: clientData.whatsapp ? true : false,
      whatsappCode: clientData.whatsappCode ? true : false,
      departmentResidence: clientData.departmentResidence ? true : false,
      municipalityResidence: clientData.municipalityResidence ? true : false,
      contactAddress: clientData.contactAddress ? true : false,
      contactEmail: clientData.contactEmail ? true : false
    }
    setTouched({ ...touched, ...touchesValues });
    setValues(clientData);
    dataSelectorsForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { values, errors, touched, handleBlur, setValues, handleSubmit, setFieldTouched, setTouched } = useFormik({
    initialValues: {
      id: '',
      documentType: undefined,
      documentNumber: '',
      name: '',
      lastName: '',
      telephone: '',
      telephoneCode: undefined,
      whatsapp: '',
      whatsappCode: undefined,
      departmentResidence: undefined,
      municipalityResidence: undefined,
      contactAddress: '',
      contactEmail: ''
    },
    validationSchema: passagesDataClientSchema,
    onSubmit: () => { },
  });

  const dataSelectorsForm = async () => {
    try {
      const results = await Promise.allSettled([
        getAllDocumentTypeService(),
        getAllDepartmentService(),
        getAllIndicativeNumberService()
      ]);

      let documentTypeList = [];
      let departmentList = [];
      let indicativeNumberList = [];
      let municipalityList = [];

      for (let index = 0; index < results.length; index++) {
        const elementResponse = results[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              documentTypeList = elementResponse?.value?.map((documentType) => ({
                label: documentType?.name,
                value: documentType?.id
              }));
              break;
            case 1:
              departmentList = elementResponse?.value?.map((department) => ({
                label: department?.name,
                value: department?.id
              }));
              break;
            case 2:
              indicativeNumberList = elementResponse?.value?.map((indicativeNumber) => ({
                label: indicativeNumber?.number,
                value: indicativeNumber?.id
              }));

              if (!clientData?.telephoneCode || !clientData?.whatsappCode) {
                let setCodes = {};
                setCodes = {
                  telephoneCode: !clientData?.telephoneCode ? indicativeNumberList[0] : clientData.telephoneCode,
                  whatsappCode: !clientData?.whatsappCode ? indicativeNumberList[0] : clientData.whatsappCode
                };
                setDataSaleReducer("passageSales", setCodes)
                setValues({
                  ...clientData,
                  ...setCodes
                });
              }

              break;
            default:
              break;
          }
        }
      }

      if (clientData?.departmentResidence?.value) {
        municipalityList = await getAllMunicipality(clientData?.departmentResidence?.value);
      }

      setState({
        ...state,
        optionsTypeDocument: documentTypeList,
        optionsDepartment: departmentList,
        optionsNumberList: indicativeNumberList,
        optionsMunicipality: municipalityList
      });
    } catch {
      // TODO: Implement error alert with code error.
    }
  };

  useEffect(() => {
    setStateSaleReducer('isError', !!Object.keys(errors).length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const onChange = async (data, target) => {
    const value = data && data.target ? data.target.value || '' : data;
    const passengerData = { ...values, [target]: value };

    if (target === 'departmentResidence') {
      passengerData.departmentResidence = value;
      passengerData.municipalityResidence = undefined;
      setDataSalesStateReducer('passageSales', "municipalityResidence", undefined);
      const municipalityList = await getAllMunicipality(value?.value);
      setState({
        ...state,
        optionsMunicipality: municipalityList
      })
      setFieldTouched("municipalityResidence", true);
    }
    if (target === "contactEmail" && isElectronic) {
      setValues(passengerData);
      setDataSaleReducer("passageSales", { contactEmail: value, isElectronic: false })
    } else {
      setValues(passengerData);
      setDataSalesStateReducer('passageSales', target, value);
    }
  };

  const getAllMunicipality = async (idDepartment) => {
    try {
      const res = await getAllMunicipalityService(idDepartment);
      return res.map((municipality) => ({ label: municipality.name, value: municipality.id }));
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const setPhoneWhatsApp = (value) => {
    setValues(prevValues => ({
      ...prevValues,
      ...value
    }));
    setDataSaleReducer("passageSales", value)
  }

  const filterUser = async (data) => {
    try {
      const res = await getFilterUsersService(data);
      setState((prevState) => ({
        ...prevState,
        optionsUserSearch: res,
      }));
    } catch (error) {
      // TODO: Implement error alert with error code
    }
  };

  const selectFilter = async (options) => {
    setState({ ...state, optionsUserSearch: [] })
    const {
      id,
      department,
      documentType,
      indicativeNumber,
      indicativeNumberWhatsapp,
      lastName,
      municipality,
      name,
      documentNumber,
      numberPhone,
      numberPhoneWhatsapp,
      contactAddress,
      contactEmail
    } = options;

    const telephoneCode = indicativeNumber?.number && indicativeNumber?.id
      ? { ...indicativeNumber, label: indicativeNumber.number, value: indicativeNumber.id }
      : optionsNumberList[0];

    const whatsappCode = indicativeNumberWhatsapp?.number && indicativeNumberWhatsapp?.id
      ? { ...indicativeNumberWhatsapp, label: indicativeNumberWhatsapp.number, value: indicativeNumberWhatsapp.id }
      : optionsNumberList[0];

    const documentTypeCode = documentType
      ? { label: documentType.name, value: documentType.id }
      : undefined;

    const municipalityResidence = municipality
      ? { label: municipality.name, value: municipality.id }
      : undefined;

    const departmentResidence = department
      ? { label: department.name, value: department.id }
      : undefined;

    if (departmentResidence) {
      getAllMunicipality(departmentResidence?.value)
        .then((res) => {
          setState({
            ...state,
            optionsMunicipality: res
          })
        })
        .catch(() => {
          // TODO: Implement error alert with code error.
        })
    }

    const value = {
      ...values,
      id,
      departmentResidence,
      documentType: documentTypeCode,
      telephoneCode,
      whatsappCode,
      municipalityResidence,
      lastName,
      name,
      documentNumber,
      telephone: numberPhone,
      contactAddress,
      contactEmail,
      whatsapp: numberPhoneWhatsapp
    }
    value.originalClientData = { ...value }
    try {
      await setValues(value);
      setDataSaleReducer("passageSales", value);
      handleSubmit();
    } catch {
      // TODO: Implement error alert with code error.
    }
  }
  useEffect(() => {
    if (values.whatsapp) {
      setFieldTouched("whatsapp", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.whatsapp])

  const handleSetPhoneWhatsApp = () => {
    setPhoneWhatsApp({
      whatsapp: values.telephone,
      whatsappCode: values.telephoneCode
    });
  };

  const onHandlerOnSearch = (e) => {
    const trimmedValue = e.trim();
    if (trimmedValue) {
      filterUser(trimmedValue);
    } else {
      setState((prevState) => ({
        ...prevState,
        optionsUserSearch: [],
      }));
    }
  };

  const debouncedHandleSearch = useMemo(
    () => debounce(onHandlerOnSearch, 390),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="grid-x">
      <div className="medium-6 client-data__content flex-container align-middle">
        <label className="client-data__title">
          {t("sales.passages.clientData.enterData")}
        </label>
      </div>
      <div className="medium-6 client-data__content client-data__content__right small-12">
        <AutoComplete
          autoFocus
          options={optionsUserSearch}
          disabled={isDisabled}
          className="client-data__label--mod"
          onSelect={(value, options) => selectFilter(options)}
          onSearch={debouncedHandleSearch}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              const inputValue = e.target.value;
              const selectedOption = optionsUserSearch.find(option => option.documentNumber === inputValue);
              if (selectedOption) selectFilter(selectedOption);
              inputRefs[0].current.focus();
            }
          }}
        >
          <Input
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            className="client-data__input__content"
            placeholder={t("sales.passages.clientData.searchName")}
            prefix={
              <img
                className="client-data__input__search"
                src={Assets.SharedIcons.icon_search}
                alt="icon_search"
              />
            }
          />
        </AutoComplete>
      </div>
      <div className="small-12 medium-6 client-data__content">
        <label className="grid-x client-data__label">
          {t("sales.passages.clientData.typeDocument")}
          <div className="client-data__label__icon" />
        </label>
        <Select
          ref={inputRefs[0]}
          disabled={isDisabled}
          value={values.documentType}
          className="client-data__select"
          onChange={(e, event) => onChange(event, 'documentType')}
          onBlur={(value) => handleBlur('documentType')(value)}
          status={errors.documentType && touched.documentType ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsTypeDocument}
          placeholder={t("sales.passages.clientData.selectTypeDocument")}
          onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
          onSelect={(value) => {
            if (isDropdownOpen) {
              inputRefs[1].current.focus();
            }
          }}
        />
        <span className="grid-x client-data__errors">{touched.documentType ? errors.documentType : null}</span>
      </div>
      <div className="small-12 medium-6 client-data__content client-data__content__right">
        <label className=" grid-x client-data__label client-data__label--mod">
          {t("sales.passages.clientData.documentNumber")}
          <div className="client-data__label__icon" />
        </label>
        <Input
          ref={inputRefs[1]}
          disabled={isDisabled}
          value={values.documentNumber}
          onChange={(value) => onChange(value, 'documentNumber')}
          onBlur={(value) => handleBlur('documentNumber')(value)}
          status={errors.documentNumber && touched.documentNumber ? "error" : ""}
          className="client-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.documentNumberPlaceholder")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[2].current.focus();
            }
          }}
        />
        <span className="grid-x client-data__errors">{touched.documentNumber ? errors.documentNumber : null}</span>
      </div>
      <div className="small-12 medium-6 client-data__content">
        <label className=" grid-x client-data__label">
          {t("sales.passages.clientData.clientName")}
          <div className="client-data__label__icon" />
        </label>
        <Input
          ref={inputRefs[2]}
          disabled={isDisabled}
          value={values.name}
          onChange={(value) => onChange(value, 'name')}
          onBlur={(value) => handleBlur('name')(value)}
          status={errors.name && touched.name ? "error" : ""}
          className="client-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterName")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[3].current.focus();
            }
          }}
        />
        <span className="grid-x client-data__errors">{touched.name ? errors.name : null}</span>
      </div>
      <div className="small-12 medium-6 client-data__content client-data__content__right">
        <label className=" grid-x client-data__label client-data__label--mod">
          {t("sales.passages.clientData.clientLastName")}
          <div className="client-data__label__icon" />
        </label>
        <Input
          ref={inputRefs[3]}
          disabled={isDisabled}
          value={values.lastName}
          onChange={(value) => onChange(value, 'lastName')}
          onBlur={(value) => handleBlur('lastName')(value)}
          status={errors.lastName && touched.lastName ? "error" : ""}
          className="client-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterLastName")}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              inputRefs[4].current.focus();
            }
          }}
        />
        <span className="grid-x client-data__errors">{touched.lastName ? errors.lastName : null}</span>
      </div>
      <div className="small-12 medium-6 client-data__content">
        <label className=" grid-x client-data__label">
          {t("sales.passages.clientData.contactTelephone")}
          <div className="client-data__label__icon" />
        </label>
        <div className="grid-x client-data__label--mod justify-content-between">
          <Select
            ref={inputRefs[4]}
            disabled={isDisabled}
            className="client-data__select indicative-number"
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsNumberList}
            value={values.telephoneCode}
            onChange={(e, event) => onChange(event, 'telephoneCode')}
            onBlur={(value) => handleBlur('telephoneCode')(value)}
            status={errors.telephoneCode && touched.telephoneCode ? "error" : ""}
            onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
            onSelect={(value) => {
              if (isDropdownOpen) {
                inputRefs[5].current.focus();
              }
            }}
          />
          <Input
            ref={inputRefs[5]}
            disabled={isDisabled}
            value={values.telephone}
            onChange={(value) => onChange(value, 'telephone')}
            onBlur={(value) => handleBlur('telephone')(value)}
            status={errors.telephone && touched.telephone ? "error" : ""}
            className="client-data__input__content phone-number"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={t("sales.passages.clientData.enterDigits")}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSetPhoneWhatsApp();
                inputRefs[6].current.focus();
              }
            }}
          />
        </div>
        <span className="grid-x client-data__errors">{touched.telephone ? errors.telephone : null}</span>
      </div>
      <div className="small-12 medium-6 client-data__content client-data__content__right">
        <div className="grid-x justify-content-between client-data__label--mod">
          <label className=" grid-x client-data__label">
            {t("sales.passages.clientData.whatsapp")}
          </label>
        </div>
        <div className="grid-x client-data__label--mod justify-content-between">
          <Select
            disabled={isDisabled}
            className="client-data__select indicative-number"
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsNumberList}
            value={values.whatsappCode}
            onChange={(e, event) => onChange(event, 'whatsappCode')}
            onBlur={(value) => handleBlur('whatsappCode')(value)}
            onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
          />
          <Input
            ref={inputRefs[6]}
            disabled={isDisabled}
            value={values.whatsapp}
            onChange={(value) => onChange(value, 'whatsapp')}
            onBlur={(value) => handleBlur('whatsapp')(value)}
            className="client-data__input__content phone-number"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
            placeholder={t("sales.passages.clientData.digitsWhatsApp")}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                inputRefs[7].current.focus();
              }
            }}
          />
        </div>
      </div>
      <div className="small-12 medium-6 client-data__content">
        <label className="client-data__label client-data__label--mod">
          {t("sales.passages.clientData.residenceDepartment")}
        </label>
        <Select
          ref={inputRefs[7]}
          disabled={isDisabled}
          value={values.departmentResidence}
          onChange={(e, event) => onChange(event, 'departmentResidence')}
          className="client-data__select"
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsDepartment}
          placeholder={t("sales.passages.clientData.selectDepartment")}
          onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
          onSelect={(value) => {
            if (isDropdownOpen) {
              setTimeout(() => {
                inputRefs[8].current.focus();
              }, 100);
            }
          }}
        />
      </div>
      <div className="small-12 medium-6 client-data__content client-data__content__right">
        <label className="client-data__label client-data__label--mod">
          {t("sales.passages.clientData.municipalityResidence")}
        </label>
        <Select
          ref={inputRefs[8]}
          value={values.municipalityResidence}
          onBlur={(value) => handleBlur('municipalityResidence')(value)}
          status={errors.municipalityResidence && touched.municipalityResidence ? "error" : ""}
          onChange={(e, event) => onChange(event, 'municipalityResidence')}
          className="client-data__select"
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          disabled={!values?.departmentResidence || isDisabled}
          options={optionsMunicipality}
          placeholder={t("sales.passages.clientData.selectMunicipality")}
          onDropdownVisibleChange={(open) => setState({ ...state, isDropdownOpen: open })}
          onSelect={(value) => {
            setTimeout(() => {
              if (isDropdownOpen) {
                nextButtonRef.current.focus();
              }
            }, 100);
          }}
        />
        <span className="grid-x client-data__errors">{touched.municipalityResidence ? errors.municipalityResidence : null}</span>
      </div>
      <div className="small-12 medium-6 client-data__content">
        <label className=" client-data__label">
          {t("sales.passages.clientData.contactAddress")}
        </label>
        <Input
          disabled={isDisabled}
          value={values.contactAddress}
          onChange={(value) => onChange(value, 'contactAddress')}
          onBlur={(value) => handleBlur('contactAddress')(value)}
          status={errors.contactAddress && touched.contactAddress ? "error" : ""}
          className="client-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.writeContact")}
        />
        <span className="grid-x client-data__errors">{touched.contactAddress ? errors.contactAddress : null}</span>
      </div>
      <div className="small-12 medium-6 client-data__content client-data__content__right">
        <label className="client-data__label client-data__label--mod">
          {t("sales.passages.clientData.contactEmail")}
        </label>
        <Input
          disabled={isDisabled}
          value={values.contactEmail}
          onChange={(value) => onChange(value, 'contactEmail')}
          onBlur={(value) => handleBlur('contactEmail')(value)}
          status={errors.contactEmail && touched.contactEmail ? "error" : ""}
          className="client-data__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
          placeholder={t("sales.passages.clientData.enterEmail")}
        />
        <span className="grid-x client-data__errors">{touched.contactEmail ? errors.contactEmail : null}</span>
      </div>
    </div>
  );
};


const mapStateToProps = ({ SalesReducer }) => {
  const {
    passageSales: {
      id,
      documentType,
      documentNumber,
      name,
      lastName,
      telephone,
      telephoneCode,
      whatsapp,
      whatsappCode,
      departmentResidence,
      municipalityResidence,
      contactAddress,
      contactEmail,
      isElectronic
    },
    isDisabled
  } = SalesReducer;
  return {
    id,
    documentType,
    documentNumber,
    name,
    lastName,
    telephone,
    telephoneCode,
    whatsapp,
    whatsappCode,
    departmentResidence,
    municipalityResidence,
    contactAddress,
    contactEmail,
    isDisabled,
    isElectronic
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setStateSaleReducer,
  setDataSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ClientDataComponents);
