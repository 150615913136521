//Assets
import { Assets } from "../../../../../assets";

//Components
import ModalAssociateDriverComponent from "../modal-associate-driver/modal-associate-driver.component";
import PdfVehiclesComponent from "../pdf-vehicles/pdf-vehicles.component";

//Components-Shared
import ModalMainComponent from "../../../../../shared/components/modal/modal-main/modal-main.component";
import ErrorToastComponent from "../../../../../shared/components/toast/error-toast/error-toast.component";

//Constants
import AlertConst from "../../../../../core/constants/alerts.const";
import { VehicleErrorsConst } from "../../../../../core/constants/errors/alerts-app/vehicles.errors.const";


//Libraries
import React, { useEffect, useState } from "react";
import { Input, Select } from "antd";
import { BlobProvider } from "@react-pdf/renderer";
import { Pagination, PaginationItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

//Services

import { getAllListOfVehiclesInformationCounterService, getAllListOfVehiclesInformationService } from "../../../../../services/vehicle.services";
import { assignDriverAvailableService, unlinkedDriverService } from "../../../../../services/drivers.services";

//Styles
import "./vehicles-table.component.scss";

//Utils
import { excelFileGeneratorUtils } from "../../../../../utils/excel-file-generator.utils";

const filterOptions = [
  {
      label: "Vehículos disponibles",
      value: "available",
  },
  {
      label: "Vehículos inactivos",
      value: "notAvailable",
  },
  {
      label: "Conductores vinculados",
      value: "withDriversAssociated",
  },
  {
      label: "Sin conductores vinculados",
      value: "withoutDriversAssociated",
  }
];

const headerTableFilter = [
  "Placa del vehículo",
  "Código del vehículo",
  "Nombre del conductor",
  "Nro. del vehículo",
  "Estado del vehículo",
  "Acciones",
];

const VehiclesTableComponent = () => {

  let history = useNavigate();

  const INITIAL_STATE = {
    pageState: 1,
    itemByPage: 0,
    vehicleCount: 0,
    itemTotalList: 7,
    listVehicles: [],
    isOpenModal: false,
    searchTextInput: "",
    selectFilterTypes: undefined,
    valueFilterType: undefined,
    driverSelected: null,
    vehicleSelected: null,
    isOpenModalUnlink: { 
      isOpen:false, 
      itemSelected: null 
    }
  };
  const [state, setState] = useState(INITIAL_STATE);

  const {
    pageState,
    itemByPage,
    isOpenModal,
    listVehicles,
    vehicleCount,
    itemTotalList,
    searchTextInput,
    selectFilterTypes,
    valueFilterType,
    driverSelected,
    vehicleSelected,
    isOpenModalUnlink
  } = state;

  useEffect(() => {
    getInfoVehicles(searchTextInput, undefined, valueFilterType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  const getInfoVehicles = async (valueFilter, filterType, valueFilterType) => {
    try {
      let itemCalculated = 0;
      const [ listVehicles, count ] = await Promise.all([
        getAllListOfVehiclesInformationService(valueFilter, valueFilterType || filterType?.value, pageState),
        getAllListOfVehiclesInformationCounterService(valueFilter, valueFilterType || filterType?.value )
      ]);
      
      if(itemTotalList * pageState <= count) {
          itemCalculated = itemTotalList * pageState;
      }else{
          itemCalculated = count;
      }
        

      setState({
        ...state,
        listVehicles,
        vehicleCount: count,
        itemByPage: itemCalculated,
        searchTextInput: valueFilter || "",
        isOpenModal: false,
        valueFilterType,
        isOpenModalUnlink: {isOpen:false, itemSelected: null}
      });
    } catch (error) {

    }
  };

  const handleData = (page) => {
    setState(prevState => ({
        ...prevState,
        pageState: page
    }))
 }

  const handlerChangeFilter = (value, target) => {
    if (target === "searchTextInput") {
      if (value === "") {
        getInfoVehicles(value, undefined, valueFilterType);
      } else {
        setState({ ...state, searchTextInput: value.trim() })
      }
    } else {
      getInfoVehicles(searchTextInput, value, value?.value || value)
    }
  };

  const getWidthByIndex = (index) => {
    const widthConfig = ["13%", "13%", "13%", "13%", "13%", "35%"];
    return widthConfig[index];
  };

  const generateExcel = (infoHeader, itemFile, isMultipleListed) => {
    let renderItemList = [];
    if (isMultipleListed) {
      renderItemList = itemFile.map(item => ({
        vehicleLicensePlate: item.plate,
        vehicleCode: item.code,
        driverName: item.driver?.name || "Conductor no vinculado",
        vehicleNumber: item.internalNumber,
        state: item.isMaintenance === true ? "No disponible" : "Disponible"
      }));
    } else {
      const renderItem = {
        vehicleLicensePlate: itemFile.plate,
        vehicleCode: itemFile.code,
        driverName: itemFile.driver?.name || "Conductor no vinculado",
        vehicleNumber: itemFile.internalNumber,
        state: itemFile.isMaintenance === true ? "No disponible" : "Disponible",
      };
      renderItemList.push(renderItem);
    };
    const columnWidths = [18, 20, 20, 16, 18]; // Predetermined widths.
    excelFileGeneratorUtils(
      infoHeader.slice(0, -1),
      renderItemList,
      (isMultipleListed) ? 'Vehiculos' : itemFile.plate,
      columnWidths
    );
  };

  const printPdf = async (url) => {
    window.open(url)
  };

  const handleViewClick = (id) => {
    history("./addVehicle", { replace: true, state: { typeAction: "VIEW_INFO_VEHICLE", id:id }});
  };


  const handleEditClick = (id) => {
    history( "./addVehicle", {replace: true, state: { typeAction: "EDIT_INFO_VEHICLE", id:id }});
  };

  // TODO: It is hidden because the main endpoint will take care of everything.
  // const searchByPlateCodeOrNumber = async () => {
  //   try {
  //     await getVehicleForByPlateCodeOrNumberService({ valueFilter: searchTextInput })
  //   } catch (error) {
  //     // TODO: Implement error alert with code error.
  //   }
  // };

  const modalLinkDriver = async () => {
    try {
      await assignDriverAvailableService({
        driver: driverSelected.id,
        vehicle: vehicleSelected.id
      })
      getInfoVehicles();
    } catch (err) {
      ErrorToastComponent({
        title: VehicleErrorsConst.modal[err.code] || `${VehicleErrorsConst.default} (${err.code || 'UDFND'})`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }


  const handleUnlink = async() => {
    try {
      await unlinkedDriverService(isOpenModalUnlink.itemSelected?.driver?.id)
      await getInfoVehicles()
    } catch (err) {
      ErrorToastComponent({
        title: VehicleErrorsConst.modal[err.code] || VehicleErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
      setState({...state, isOpenModalUnlink: {isOpen:false, itemSelected: null}})
    }
  }

  return (
    <>
      <div className="grid-x vehicles-table__filters">
        <div className="grid-x medium-2 small-12 vehicles-table__select__container">
          <Select
            options={filterOptions}
            value={selectFilterTypes}
            placeholder={"Filtrar por"}
            className="vehicles-table__select"
            onChange={(e, event) => handlerChangeFilter(event, 'selectFilterTypes')}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
          />
          <div className="vehicles-table__hidden vehicles-table__clear">
            <button className="vehicles-table__clear__button">
              <img src={Assets.SalesIcons.icon_clear} alt="icon_clear" />
            </button>
          </div>
        </div>
        <div className="grid-x medium-6 small-12 align-middle">
          <span className="vehicles-table__input__info">Buscar</span>
          <Input
            value={searchTextInput}
            className="vehicles-table__input__content"
            placeholder={"Buscar vehículo por placa, código o número"}
            onChange={(text) => handlerChangeFilter(text.target.value.trim(), 'searchTextInput')}
            prefix={
              <img
              alt="icon_search"
              src={Assets.SharedIcons.icon_search}
              className="vehicles-table__input__search"
              />
            }
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  alt="icon_square_close"
                  src={Assets.SharedIcons.icon_square_close}
                />
              ),
            }}
          />
        </div>
        <div className="grid-x medium-2 small-12 justify-content-center">
          <button
            disabled={!searchTextInput}
            className="medium-10 vehicles-table__button-search"
            onClick={() => getInfoVehicles(searchTextInput, undefined, valueFilterType)}
          >
            <span>Buscar</span>
          </button>
        </div>
        <div className="grid-x medium-2 small-12">
          <button
            className="medium-12 vehicles-table__download-list"
            onClick={() => generateExcel(headerTableFilter, listVehicles, true)}
          >
            <span>Descargar Listado</span>
            <img
              alt="icon_download_color_citron"
              className="vehicles-table__download-list__icon"
              src={Assets.SharedIcons.icon_download_color_citron}
            />
          </button>
        </div>
      </div>
      <div className="grid-x vehicles-table__box">
        {headerTableFilter.map((item, index) => {
          return (
            <div
              key={index}
              className="vehicles-table__subtitle__content"
              style={{ width: getWidthByIndex(index) }}
            >
              <span className="vehicles-table__subtitle">{item}</span>
            </div>
          );
        })}
        {listVehicles.map((item, index) => {
          return (
            <div
              key={index}
              className="grid-x small-12 vehicles-table__content-info"
            >
              <div className="grid-x vehicles-table__subtitle__content__text vehicles-table__subtitle__box">
                <span className="boarding-sheet__text vehicles-table__hidden vehicles-table__subtitle__box__text">
                  {headerTableFilter[0]}
                </span>
                <span className="boarding-sheet__text">{item?.plate}</span>
              </div>
              <div className="grid-x vehicles-table__subtitle__content__text vehicles-table__subtitle__box">
                <span className="boarding-sheet__text vehicles-table__hidden vehicles-table__subtitle__box__text">
                  {headerTableFilter[1]}
                </span>
                <span className="boarding-sheet__text">{item?.code}</span>
              </div>
              <div className="grid-x vehicles-table__subtitle__content__text vehicles-table__subtitle__box">
                <span className="boarding-sheet__text vehicles-table__hidden vehicles-table__subtitle__box__text">
                  {headerTableFilter[2]}
                </span>
                {item?.driver?.name ? (
                  <button onClick={()=>setState({...state, isOpenModalUnlink: { isOpen:true, itemSelected: item } })}>
                    <span className="boarding-sheet__text">
                      {item?.driver?.name}
                      <img
                        className="vehicles-table__icon-associate"
                        src={Assets.SharedIcons.icon_associate_driver}
                        alt="icon_associate_driver"
                      />
                    </span>
                  </button>
                ) : (
                  <button
                    className="vehicles-table__button--border"
                    onClick={() =>
                      setState({
                        ...state,
                        isOpenModal: true,
                        vehicleSelected: item
                      })
                    }
                  >
                    <span>Vincular conductor</span>
                    <img
                      src={Assets.SharedIcons.icon_associate_driver}
                      alt="icon_associate_driver"
                    />
                  </button>
                )}
              </div>
              <div className="grid-x vehicles-table__subtitle__content__text vehicles-table__subtitle__box">
                <span className="boarding-sheet__text vehicles-table__hidden vehicles-table__subtitle__box__text">
                  {headerTableFilter[3]}
                </span>
                <span className="boarding-sheet__text">{item?.internalNumber}</span>
              </div>
              <div className="grid-x vehicles-table__subtitle__container vehicles-table__subtitle__box">
                <span className="boarding-sheet__text vehicles-table__hidden vehicles-table__subtitle__box__text">
                  {headerTableFilter[4]}
                </span>
                <div
                  className={`vehicles-table__state ${item?.isMaintenance === true ? "vehicles-table__state--inactive" : "vehicles-table__state--available"
                  }`}
                >
                  <span>{item?.isMaintenance === true ? "No disponible" : "Disponible"} </span>
                </div>
              </div>
              <div className="vehicles-table__actions vehicles-table__subtitle__box vehicles-table__subtitle__box--mod">
                <div className="grid-x justify-content-evenly vehicles-table__actions__button">
                  <button onClick={() => handleViewClick(item.id)} >
                    <img
                      src={Assets.SharedIcons.icon_view_details}
                      alt="icon_view_detail"
                    />
                  </button>
                  <BlobProvider document={<PdfVehiclesComponent info={item} />}>
                    {({ url, loading, error }) => {
                      return (
                        <button
                          onClick={() => printPdf(url)}
                          disabled={loading || error}
                        >
                          <img
                            src={Assets.SharedIcons.icon_print_ticket}
                            alt="icon_print_ticket"
                          />
                        </button>
                      );
                    }}
                  </BlobProvider>
                  <button onClick={() => generateExcel(headerTableFilter, item, false)}>
                    <img
                      src={Assets.SharedIcons.icon_download}
                      alt="icon_download"
                    />
                  </button>
                  <button
                    className="vehicles-table__button--solid"
                    onClick={() => handleEditClick(item.id)}
                  >
                    <div>
                      <span>Editar vehículo</span>
                    </div>
                    <img
                      src={Assets.SharedIcons.icon_pencil}
                      alt="icon_pencil"
                    />
                  </button>
                </div>
                {/*
                  //TODO: Hidden due to new requirement id: 291668
                  <div className="flex-container justify-content-evenly vehicles-table__actions__button__edit">
                    <div className="align-center-middle">
                      <button
                        className="vehicles-table__button--solid"
                        onClick={() =>
                          history("./addVehicle", {
                            replace: true,
                            state: { typeAction: "EDIT_INFO_VEHICLE" },
                          })
                        }
                      >
                        <div>
                          <span>Editar vehículo</span>
                        </div>
                        <img
                          src={Assets.SharedIcons.icon_pencil}
                          alt="icon_pencil"
                        />
                      </button>
                    </div>
                    <div className="align-center-middle">
                      <button className="vehicles-table__button--border"
                          onClick={() => history("./vehicleOverhaul", { replace: true })}
                      >
                        <div>
                          <span>Revisar vehículo</span>
                        </div>
                        <img
                          src={Assets.VehiclesIcons.icon_review}
                          alt="icon_review"
                        />
                      </button>
                    </div>
                  </div>
                */}
              </div>
            </div>
          );
        })}
      </div>
      <div className="grid-x justify-content-between">
        <span className="vehicles-table__info">
          {`${itemByPage || 0} de ${vehicleCount} resultados`}
        </span>
        <div className="grid-x vehicles-table__pagination__content">
          <Pagination
            disabled={vehicleCount === 0 ? true : false}
            onChange={(e, event) => handleData(event)}
            count={Math.ceil(vehicleCount / itemTotalList) || 1}
            siblingCount={0}
            boundaryCount={0}
            showFirstButton
            showLastButton
            shape="rounded"
            className="vehicles-table__pagination"
            renderItem={(item) => (
              <PaginationItem
                className="vehicles-table__pagination__item"
                slots={{
                  previous: () => {
                    return (
                      <img src={Assets.SharedIcons.icon_back} alt="icon_back" />
                    );
                  },
                  next: () => {
                    return (
                      <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
                    );
                  },
                  first: () => {
                    return (
                      <img src={Assets.SalesIcons.icon_back} alt="icon_back" />
                    );
                  },
                  last: () => {
                    return (
                      <img
                        src={Assets.SalesIcons.icon_forward}
                        alt="icon_forward"
                      />
                    );
                  },
                }}
                {...item}
              />
            )}
          />
        </div>
      </div>
      {isOpenModal && (
        <ModalMainComponent
          close
          width={380}
          open={isOpenModal}
          onClose={() => setState({ ...state, isOpenModal: false })}
          titleIcon={
            <img
              src={Assets.SharedIcons.icon_logo_and_name}
              alt="icon_logo_and_name"
            />
          }
          footer={[
            <div key="accept" className="grid-x align-center">
              <button
                disabled={!driverSelected}
                onClick={() => modalLinkDriver()}
                className="boarding-sheet__modal__button"
              >
                <span>{"Vincular conductor"}</span>
              </button>
            </div>,
          ]}
        >
          <ModalAssociateDriverComponent
            setDriverSelected={(driver) => setState({...state, driverSelected: driver})}
            setVehicleSelected={vehicleSelected}
          />
        </ModalMainComponent>
      )}
      {isOpenModalUnlink.isOpen &&
        <ModalMainComponent
          close
          width={380}
          open={isOpenModalUnlink.isOpen}
          onClose={() => setState({ ...state, isOpenModalUnlink: {isOpen:false, itemSelected: null} })}
          titleIcon={
            <img
              src={Assets.SharedIcons.icon_logo_and_name}
              alt="icon_logo_and_name"
            />
          }
          footer={[
            <div key="accept" className="grid-x align-center">
              <button
                onClick={() => handleUnlink()}
                className="boarding-sheet__modal__button"
              >
                <span>Desvincular conductor</span>
              </button>
            </div>,
            <div key="cancel" className="grid-x align-center">
              <button
                onClick={() => setState({ ...state, isOpenModalUnlink: {isOpen:false, itemSelected: null} })}
                className="vehicles-table__modal__cancel"
              >
                <span>Cancelar</span>
              </button>
            </div>,
          ]}
        >
        <div className="grid-x small-12 align-center-middle">
          <span className="text-center vehicles-table__modal__text small-10">
            Vas a desvincular el conductor: {isOpenModalUnlink.itemSelected.driver.name}
          </span>
        </div>
        </ModalMainComponent>
      }
    </>
  );
};

export default VehiclesTableComponent;
