// Assets
import { Assets } from "../../../assets";

// Components - shared
import ErrorToastComponent from "../../../shared/components/toast/error-toast/error-toast.component";
import SuccessToastComponent from "../../../shared/components/toast/success-toast/success-toast.component";

// Constants
import AlertConst from "../../../core/constants/alerts.const";

// libraries
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Input } from "antd";

//Styles
import "./new-password.page.scss";

//Services
import { changePasswordService } from "../../../services/authentication.services";

// Utils
import { validateFormatPassword } from "../../../utils/form-validations/authentications.validators.utils";

const NewPasswordPage = () => {

  const [t] = useTranslation("translation");
  const INITIAL_STATE = {
    formatErrors: {
      eightCharacters: true,
      number: true,
      specialCharacter: true,
      uppercaseLetter: true,
      lowercaseLetter: true
    }
  }

  const [state, setState] = useState(INITIAL_STATE);
  const { formatErrors } = state;

  const areEqual = (values) => {
    if (values.password !== values.passwordConfirmation) return {
      passwordConfirmation: t("authentication.validations.invalidPasswordConfirmation")
    }
  };

  let history = useNavigate();
  let { state: routeState } = useLocation();

  const { handleSubmit, handleChange, values, errors, touched, setTouched } = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validate: areEqual,
    onSubmit: () => {
      // TODO: connect new password creation process flow
      processChangePassword();
    },
  });

  const processChangePassword = async() => {
    await changePasswordService(
      values?.password,
      values?.passwordConfirmation,
      routeState?.token
    )
      .then(() => {
        history("/login", { replace: true });
        SuccessToastComponent({
          html:
            `<span>
              ${t("authentication.newPassword.messageSuccessNewPassword")}
            </span>`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 1500
        })
      })
      .catch((err) => {
        ErrorToastComponent({
          title: `${err.code} ${err.message}`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 1500
        })
      })
  }

  const onChangePassword = (value) => {
    handleChange('password')(value)
    const errors = validateFormatPassword(value.target.value);
    setState((prevState) => ({ ...prevState, formatErrors: errors }))
  };

  return (
    <form className="grid-y new-password-page__container" onSubmit={handleSubmit}>
      <div className="grid-x align-center-middle small-4">
        <img
          src={Assets.SharedIcons.icon_logo_vertical}
          alt="icon_logo_vertical"
        />
      </div>
      <div className="grid-y small-7 new-password-page__form-content">
        <span className="new-password-page__form-content__title">
          {t("authentication.newPassword.mainTitle")}
        </span>
        <div className="grid-y small-7 new-password-page__form-content__field">
          <div className=" grid-y">
            <label className="grid-x new-password-page__form-content__field__title">
              {t("authentication.newPassword.newPassword")} <div className="new-password-page__form-content__field__title__icon" />
            </label>
            <Input.Password
              value={values.password}
              allowClear={{
                clearIcon: <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              }}
              iconRender={() => null}
              type="none"
              status={errors.password ? "error" : ""}
              onFocus={() => setTouched({ ...touched, password: true })}
              onChange={(value) => onChangePassword(value)}
              placeholder={t("authentication.newPassword.newPasswordPlaceHolder")}
              className={`new-password-page__form-content__field__input ${!!values.password.length ? 'new-password-page__form-content__field__input--mod' : ''}`}
            />
          </div>
          <div className="grid-y small-5 new-password-page__form-content__field__description">
            {touched.password
              ? <>
                <span className={formatErrors.eightCharacters
                  ? "new-password-page__form-content__field__description__text"
                  : "new-password-page__form-content__field__description__text--mod"
                }>{t("authentication.newPassword.eightCharacters")}</span>
                <span className={formatErrors.number
                  ? "new-password-page__form-content__field__description__text"
                  : "new-password-page__form-content__field__description__text--mod"}>
                  {t("authentication.newPassword.number")}</span>
                <span className={formatErrors.uppercaseLetter
                  ? "new-password-page__form-content__field__description__text"
                  : "new-password-page__form-content__field__description__text--mod"
                }>{t("authentication.newPassword.uppercaseLetter")}</span>
                <span className={formatErrors.lowercaseLetter
                  ? "new-password-page__form-content__field__description__text"
                  : "new-password-page__form-content__field__description__text--mod"
                }>{t("authentication.newPassword.lowercaseLetter")}</span>
                <span className={formatErrors.specialCharacter
                  ? "new-password-page__form-content__field__description__text"
                  : "new-password-page__form-content__field__description__text--mod"
                }>{t("authentication.newPassword.specialCharacter")}</span>
              </>
              : null
            }
          </div>
          <div className=" grid-y">
            <label className="grid-x new-password-page__form-content__field__title">
              {t("authentication.newPassword.confirmPassword")}<div className="new-password-page__form-content__field__title__icon" />
            </label>
            <Input.Password
              value={values.passwordConfirmation}
              allowClear={{
                clearIcon: <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              }}
              iconRender={() => null}
              type="none"
              status={errors.passwordConfirmation && touched.passwordConfirmation ? "error" : ""}
              onChange={(value) => handleChange('passwordConfirmation')(value)}
              onFocus={() => setTouched({ ...touched, passwordConfirmation: true })}
              placeholder={t("authentication.newPassword.newPasswordPlaceHolder")}
              className={`new-password-page__form-content__field__input ${!!values.passwordConfirmation.length ? 'new-password-page__form-content__field__input--mod' : ''}`}
            />
            {errors.passwordConfirmation && touched.passwordConfirmation
              ? <span className="new-password-page__form-content__field__input__error"> {errors.passwordConfirmation}</span>
              : values.passwordConfirmation
                ? <span className="new-password-page__form-content__field__input__success">{t("authentication.newPassword.messageSuccessNewPassword")}</span>
                : null
            }
          </div>
        </div>
        <div className="grid-y small-3 align-middle">
          <button
            className="new-password-page__form-content__button"
            type="submit"
            disabled={Object.keys(errors).length !== 0 || Object.keys(formatErrors).length !== 0}
          >
            {t("authentication.newPassword.save")}
          </button>
          <Link
            className="new-password-page__form-content__return"
            to="/passwordRecovery"
          >
            {t("authentication.newPassword.return")}
          </Link>

        </div>
      </div>
    </form>
  );
};


const mapStateToProps = ({ AuthenticationReducer }) => {
  const { uidUserRecoveryPassword } = AuthenticationReducer;
  return {
    uidUserRecoveryPassword
  };
};

export default connect(
  mapStateToProps
)(NewPasswordPage);