//Actions
import {setStateDriverReducer} from '../../../../../storage/reducers/drivers/drivers.action';

//Assets
import { Assets } from '../../../../../assets';

//Components
import ModalMainComponent from '../../../../../shared/components/modal/modal-main/modal-main.component';
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';

//Constants
import { BoardingSheetErrorsConst } from '../../../../../core/constants/errors/alerts-app/boarding-sheet.errors.const';
import AlertConst from '../../../../../core/constants/alerts.const';

//Libraries
import { Input } from 'antd';
import { connect } from "react-redux";
import React, { useEffect, useState } from 'react';

//Services
import { assignDriverAvailableService, getDriverFilterService, getFilterVehicleAvailableService, unlinkedDriverService } from '../../../../../services/drivers.services';

//Styles
import './link-driver-vehicle.component.scss'
import { VehicleErrorsConst } from '../../../../../core/constants/errors/alerts-app/vehicles.errors.const';

const LinkDriverVehicleComponent = (props) => {

  const {
    //props
    typeAction,
    //Variables
    isDisabled,
    driverFrom,
    driverUserRedux,
    linkedVehicle,
    vehicleInformationReducer,
    isUnlinkVehicle,
    idDriverVehicleSelected,
    //Actions
    setStateDriverReducer
  } = props;

  const INITIAL_STATE = {
    isViewModalPhoto: false,
    urlPhoto: '',
    isSearchResult: false,
    isVehicleLinkage: false,
    isEditInfoDriver: false,
    valueFilter: '',
    vehicleInformation: {
      vehicleType: '',
      plate: '',
      internalNumber: '',
      mileage: '',
      vehicleCode: '',
      vehicleMake: '',
      numberOfSeats: '',
      vehicleStatus: '',
      fuelType: '',
      bodywork: ''
    }
  };

  const [state, setState] = useState(INITIAL_STATE);

  const {
    isSearchResult, isVehicleLinkage, isViewModalPhoto,
    vehicleInformation, isEditInfoDriver, urlPhoto, valueFilter
  } = state;

  useEffect(() => {
    setState({
      ...state,
      isEditInfoDriver: typeAction === 'EDIT_INFO_DRIVER',
      vehicleInformation: {
        id: driverFrom?.vehicle?.id,
        vehicleType: driverFrom?.vehicle?.vehicleType,
        plate: driverFrom?.vehicle?.plate,
        internalNumber: driverFrom?.vehicle?.internalNumber,
        mileage: driverFrom?.vehicle?.mileage,
        vehicleCode: driverFrom?.vehicle?.vehicleCode,
        vehicleMake: driverFrom?.vehicle?.vehicleMake,
        numberOfSeats: driverFrom?.vehicle?.numberOfSeats,
        vehicleStatus: driverFrom?.vehicle?.vehicleStatus,
        fuelType: driverFrom?.vehicle?.fuelType,
        bodywork: driverFrom?.vehicle?.bodywork
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverFrom]);

  const filterVehicle = async (filter) => {
    try {
        const selectedVehicle = await getFilterVehicleAvailableService({
            valueFilter:filter
        });

        if (selectedVehicle.length === 0){
            ErrorToastComponent({
                title: BoardingSheetErrorsConst.localError.resultEmpty || BoardingSheetErrorsConst.default,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }

        setState((prevState) => ({
            ...prevState,
            vehicleInformation: {
              id: selectedVehicle?.vehicle?.id,
              vehicleType: selectedVehicle?.vehicle?.typeVehicle,
              plate: selectedVehicle?.vehicle?.plate,
              internalNumber: selectedVehicle?.vehicle?.internalNumber,
              mileage: selectedVehicle?.vehicle?.mileage,
              vehicleCode: selectedVehicle?.vehicle?.code,
              vehicleMake: selectedVehicle?.vehicle?.mark,
              numberOfSeats: selectedVehicle?.vehicle?.numberSeats,
              vehicleStatus: selectedVehicle?.vehicle?.isMaintenance,
              fuelType: selectedVehicle?.vehicle?.typeFuel,
              bodywork: selectedVehicle?.vehicle?.typeBodywork
            },
            isSearchResult: true
        }))
        setStateDriverReducer('vehicleInformationReducer', selectedVehicle)
    } catch (error) {
        ErrorToastComponent({
            title: BoardingSheetErrorsConst.localError.resultEmpty || BoardingSheetErrorsConst.default,
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
        })
    }
};


  const getDriverFilter = async (valueFilter, sortOrderType) => {
    try {
      await getDriverFilterService(valueFilter, sortOrderType);

    } catch (error) {
      // Implement error alert with code error.
    }
  }

  const handleInputChange = async (event) => {
    try {
      const valueFilter = event && event.target ? event.target.value || '' : event;
      setState(prevState => ({
        ...prevState,
        valueFilter: valueFilter,

      }));
    } catch (err) {
      // TODO: Implement error alert with code error.
    }
  }

  const handleSearchClick = async () => {
    try {
      await getDriverFilter(valueFilter);
      filterVehicle(valueFilter);
    } catch (error) {
      // Implement error alert with code error.
    }
  };

  const addVehicleToDriver = async() =>{
    try {
      if(isEditInfoDriver){
       let data = await assignDriverAvailableService({
          driver: driverUserRedux.id,
          vehicle: vehicleInformationReducer.vehicle.id
        });
        setStateDriverReducer("idDriverVehicleSelected", data.driverVehicleId)
      }
      setState((prevState) => ({
          ...prevState,
          isVehicleLinkage: true,
      }))
      setStateDriverReducer("linkedVehicle", true);
    } catch (err) {
      ErrorToastComponent({
        title: VehicleErrorsConst.modal[err.code] || `${VehicleErrorsConst.default} (${err.code || 'UDFND'})`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const unlinkVehicle = async() =>{
    try {
      if (isEditInfoDriver) {
        await unlinkedDriverService(idDriverVehicleSelected)
      }
      setState((prevState) => ({
          ...prevState,
          isVehicleLinkage: false,
          isSearchResult: false,
          valueFilter: ''
      }))
      setStateDriverReducer("linkedVehicle", false);
      if (isEditInfoDriver && !!driverUserRedux?.vehicle?.id) {
        setStateDriverReducer("isUnlinkVehicle", true)
      }
    } catch (err) {
      ErrorToastComponent({
        title: VehicleErrorsConst.modal[err.code] || `${VehicleErrorsConst.default} (${err.code || 'UDFND'})`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const handleClearFilterParams = () => {
    setState(prevState => ({
        ...prevState,
        valueFilter: "",
        isSearchResult: false
    }))
}

  // TODO: 283580 Implement operation card
  // const listPhotos = [
  //   {
  //     name: 'redBull.png',
  //     photo: 'https://sportsbase.io/images/gpfans/copy_1200x800/606b91b75626c27a73fdf6bc47c7827e7d89aa3c.jpg'
  //   },
  //   {
  //     name: 'porsche.png',
  //     photo: 'https://pictures.porsche.com/rtt/iris?COSY-EU-100-1711coMvsi60AAt5FwcmBEgA4qP8iBUDxPE3Cb9pNXABuN9dMGF4tl3U0%25z8rMHIspMtwZaiEt5yPew03zhRc2wubqA7fQf4aOJUPY0gZTBsN5xlA2dioC1ivQD9AzXCKuAIKyDUZT6Ot5FTmnEBc6XdGAX'
  //   },
  //   {
  //     name: 'mercedes.png',
  //     photo: 'https://cdn.motor1.com/images/mgl/ozYE4/s1/bulletproof-mercedes-amg-g63-by-inkas.webp'
  //   },
  //   {
  //     name: 'ferrari.png',
  //     photo: 'https://cloudfront-us-east-1.images.arcpublishing.com/infobae/L3UYLCT34NA5LFLKBVXQRA6NHM.jpg'
  //   }
  // ]

  return (
    <div className='grid-x'>
      <div className='grid-x small-12 link-driver-vehicle__content-search'>
        {(linkedVehicle || isDisabled || (isEditInfoDriver && !!driverUserRedux?.vehicle?.id && !isUnlinkVehicle))
          ?
            (<div className={`grid-x small-12 align-center-middle ${!isDisabled ? "link-driver-vehicle__content-link-vehicle-header" : ""}`}>
              <img
                alt="icon_vehicles_card"
                src={Assets.SharedIcons.icon_vehicles_card}
              />
              <span className='link-driver-vehicle__title'>Vehículo vinculado</span>
              {!isDisabled
                ?
                  (<button
                    className='link-driver-vehicle__unlink-vehicle-button'
                    onClick={() => unlinkVehicle()}
                      
                  >
                    <span className='link-driver-vehicle__unlink-vehicle-button__text'>Desvincular vehiculo</span>
                  </button>)
                :
                  null
              }
            </div>)
          : 
            (<>
              <div className="grid-x small-10">
                <span className='grid-x link-driver-vehicle__label'>
                  Busca vehículos Disponible
                </span>
                <Input
                  disabled={false}
                  value={valueFilter}
                  onChange={(e) => {
                    handleInputChange(e);
                    if (e.target.value === '') {
                      handleClearFilterParams();
                    }
                  }}
                  className='link-driver-vehicle__input__content'
                  placeholder={'Escribe el código, placa o Nro. del Vehículo'}
                  allowClear={{ clearIcon: (<img width={28} src={Assets.SharedIcons.icon_square_close} alt="icon_square_close" />)}}
                  prefix={<img alt='icon_search' src={Assets.SharedIcons.icon_search} className='link-driver-vehicle__input__search'/>}
                />
              </div>
              <div className='grid-x small-2 align-content-end'>
                <button onClick={handleSearchClick}
                  className='link-driver-vehicle__button-search'>
                  <span className='link-driver-vehicle__button-search__text'>Buscar</span>
                </button>
              </div>
            </>)
        }
      </div>
      <div className='grid-x small-12 align-center-middle link-driver-vehicle__vehicle-link-box'>
        <div className='link-driver-vehicle__vehicle-link-box__content-info'>
          {(isSearchResult || isDisabled || (isEditInfoDriver && !!driverUserRedux?.vehicle?.id && !isUnlinkVehicle))
            ?
              (<>
                <div className='link-driver-vehicle__vehicle-link-box__information-descriptive-vehicle'>
                  <div className='link-driver-vehicle__vehicle-link-box__content-box'>
                    <div className='link-driver-vehicle__vehicle-link-box__content-box__first'>
                      <div className='grid-y link-driver-vehicle__vehicle-link-box__content-box__content-titles'>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Vehículo tipo</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Placa</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Número interno</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Kilometraje</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Código del vehículo</span>
                      </div>
                      <div className='grid-y link-driver-vehicle__vehicle-link-box__content-box__content-texts'>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.vehicleType || 'Información no disponible'}</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.plate || 'Información no disponible'}</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.internalNumber || 'Información no disponible'}</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.mileage ? `${vehicleInformation?.mileage}/km` : 'Información no disponible'}</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.vehicleCode || 'Información no disponible'}</span>
                      </div>
                    </div>
                    <div className='link-driver-vehicle__vehicle-link-box__content-box__second'>
                      <div className='grid-y link-driver-vehicle__vehicle-link-box__content-box__content-titles'>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Marca del vehículo</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Número de asientos</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Estado del vehículo</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Tipo de combustible</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-titles__title'>Carrocería</span>
                      </div>
                      <div className='grid-y link-driver-vehicle__vehicle-link-box__content-box__content-texts'>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.vehicleMake || 'Información no disponible'}</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.numberOfSeats ? `${vehicleInformation?.numberOfSeats} asientos` : 'Información no disponible'}</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.isMaintenance ? "No disponible" : "Disponible" || 'Información no disponible'}</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.fuelType || 'Información no disponible'}</span>
                        <span className='link-driver-vehicle__vehicle-link-box__content-box__content-texts__text'>{vehicleInformation?.bodywork ? `${vehicleInformation?.bodywork} Tipo` : 'Información no disponible'}</span>
                      </div>
                    </div>
                  </div>
                  {/* TODO: 283580 Implement link see details  */}
                  
                  {/* <div className='link-driver-vehicle__vehicle-link-box__content-href'>
                    <a href="https://sportsbase.io/images/gpfans/copy_1200x800/606b91b75626c27a73fdf6bc47c7827e7d89aa3c.jpg" 
                      target="_blank" rel="noreferrer">
                      <span className='link-driver-vehicle__vehicle-link-box__details-link'>Ver detalles del vehículo</span>
                      <img
                        alt='icon_open_link'
                        src={Assets.SharedIcons.icon_open_link}
                      />
                    </a>
                  </div> */}
                </div>

                {/* TODO: 283580 Implement operation card  */}

                {/* <div className='link-driver-vehicle__vehicle-link-box__vehicle-files'>
                  <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__content-box">
                    <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__content-title">
                      <span className="grid-x link-driver-vehicle__vehicle-link-box__vehicle-files__title">
                        Tarjeta de propiedad del vehículo
                      </span>
                      <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__title__icon"/>
                    </div>
                    <div className='link-driver-vehicle__vehicle-link-box__vehicle-files__content-card'>
                      {listPhotos.map((card, index) => {
                        return (
                          <div
                            key={index}
                            className="link-driver-vehicle__vehicle-link-box__vehicle-files__card"
                          >
                            <img
                              alt='profile'
                              src={card.photo}
                              className="link-driver-vehicle__vehicle-link-box__vehicle-files__photo"
                            />
                            <span className="link-driver-vehicle__vehicle-link-box__vehicle-files__text">
                              {card.name}
                            </span>
                            <button onClick={() => setState({...state, isViewModalPhoto: true, urlPhoto: card.photo})}>
                              <img
                                alt='icon_view_details'
                                src={Assets.SharedIcons.icon_view_details}
                              />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__content-box">
                    <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__content-title">
                      <span className="grid-x link-driver-vehicle__vehicle-link-box__vehicle-files__title">
                        Tarjeta de operación vigente
                      </span>
                      <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__title__icon"/>
                    </div>
                    <div className='link-driver-vehicle__vehicle-link-box__vehicle-files__content-card'>
                      {listPhotos.map((card, index) => {
                        return (
                          <div
                            key={index}
                            className="link-driver-vehicle__vehicle-link-box__vehicle-files__card"
                          >
                            <img
                              alt='profile'
                              src={card.photo}
                              className="link-driver-vehicle__vehicle-link-box__vehicle-files__photo"
                            />
                            <span className="link-driver-vehicle__vehicle-link-box__vehicle-files__text">
                              {card.name}
                            </span>
                            <button onClick={() => setState({...state, isViewModalPhoto: true, urlPhoto: card.photo})}>
                              <img
                                alt='icon_view_details'
                                src={Assets.SharedIcons.icon_view_details}
                              />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__content-box">
                    <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__content-title">
                      <span className="grid-x link-driver-vehicle__vehicle-link-box__vehicle-files__title--mod">
                        Fotografías del vehículo
                      </span>
                      <div className="link-driver-vehicle__vehicle-link-box__vehicle-files__title__icon"/>
                    </div>
                    <div className='link-driver-vehicle__vehicle-link-box__vehicle-files__content-card'>
                      {listPhotos.map((card, index) => {
                        return (
                          <div
                            key={index}
                            className="link-driver-vehicle__vehicle-link-box__vehicle-files__card"
                          >
                            <img
                              alt='profile'
                              src={card.photo}
                              className="link-driver-vehicle__vehicle-link-box__vehicle-files__photo"
                            />
                            <span className="link-driver-vehicle__vehicle-link-box__vehicle-files__text">
                              {card.name}
                            </span>
                            <button onClick={() => setState({...state, isViewModalPhoto: true, urlPhoto: card.photo})}>
                              <img
                                alt='icon_view_details'
                                src={Assets.SharedIcons.icon_view_details}
                              />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div> */}
              </>)
            :
              (<div className='link-driver-vehicle__vehicle-link-box__search-results-content'>
                <img
                  alt='icon_cotranscat_disabled'
                  src={Assets.SharedIcons.icon_cotranscat_disabled}
                />
                <div className='grid-y link-driver-vehicle__vehicle-link-box__box-info'>
                  <span className='link-driver-vehicle__vehicle-link-box__description'>En la parte superior busca el vehículo disponible por código, placa o Número</span>
                </div>
              </div>)
          }
        </div>
        {((isDisabled || isVehicleLinkage) || (isEditInfoDriver && !!driverUserRedux?.vehicle?.id && !isUnlinkVehicle))
          ?
            null
          :
            (<div className='link-driver-vehicle__vehicle-link-box__content-button'>
              <button
                disabled={!isSearchResult}
                onClick={() => addVehicleToDriver()}
                className='link-driver-vehicle__vehicle-link-box__content-button__button'
              >
                <span>Vincular vehículo</span>
              </button>
            </div>)
        }
      </div> 
      {isViewModalPhoto &&
        (<ModalMainComponent
          footer={null}
          open={isViewModalPhoto}
          onClose={() => setState({ ...state, isViewModalPhoto: false, urlPhoto: '' })}
        >
          <div className='grid-x align-center-middle link-driver-vehicle__content-modal'>
            <img
              alt='profile'
              src={urlPhoto}
              className="link-driver-vehicle__content-modal__photo"
            />
          </div>
        </ModalMainComponent>)
      }
    </div>
  )
};

const mapStateToProps = ({ DriversReducer }) => {
  const { 
    driverFrom, 
    isDisabled, 
    linkedVehicle,
    driverUserRedux,
    vehicleInformationReducer,
    isUnlinkVehicle,
    idDriverVehicleSelected
  } = DriversReducer;

  return {
    driverFrom,
    isDisabled,
    linkedVehicle,
    driverUserRedux,
    vehicleInformationReducer,
    isUnlinkVehicle,
    idDriverVehicleSelected
  };
};

const mapStateToPropsActions = {
  setStateDriverReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(LinkDriverVehicleComponent);