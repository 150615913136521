import { StyleSheet } from "@react-pdf/renderer";

export const PdfCashStyles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
      width:"100%",
      padding: "20px 30px"
    },
    section: {
      width: "100%",
      height: "450px",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px"
    },
    containerLogo: {
        width: "50%",
        marginBottom: "30px"
    },
    containerSeparatorLeft: {
        width: "50%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingRight: "10px",
        marginBottom: "15px"
    },
    containerSeparatorRight: {
        width: "50%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingLeft: "10px",
        marginBottom: "15px"
    },
    containerSeparatorTotal: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingRight: "10px",
        marginBottom: "15px",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderTop: "1px solid #2C2C2C",
        borderBottom: "1px solid #2C2C2C",
    },
    total: {
        width: "50%"
    },
    totalMod:{
        width: "100%",
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    logo:{
        width: "150px",
        height: "32px"
    },
    subTitle:{
        fontSize: "10px",
        fontWeight: "normal",
        color: "#2C2C2C"
    },
    content:{
        width: "100%",
        height: "100%"
    },
    data:{
        fontSize: "12px",
        color: "#2C2C2C"
    },
    text:{
        width:"50%",
    },
    textMod: {
        width: "50%",
        alignSelf: "flex-end"
    },
    line:{
        width:"50%",
        borderBottom: "1px solid #C7C7C7"
    },
    label: {
        fontWeight: 'bold'
    },
    containerRight:{
        width: "50%",
        textAlign: "center",
        marginTop: "30px",
        display: "flex",
        alignItems: "center"
    },
    containerLeft:{
        width: "50%",
        textAlign: "center",
        marginTop: "30px",
        display: "flex",
        alignItems: "center"
    },
    containerValue:{
        width: "60%",
        textAlign: "center",
        height: "30px",
        border: "2px solid #BFBFBF",
        borderRadius: "5px"
    }
});