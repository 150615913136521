import dayjs from "dayjs"

// Constants - Endpoints
import SharedEndPoints from "./constants/shared.end-points"
import SettingsEndPoints from "./constants/settings.end-points"

// Infrastructure
import Http from "./infrastructure/http.infrastructure"

// Services
import { getTokenService } from "./authentication.services"

// Models - DTO
import UserDTO from "../models/user/dto/user.dto"
import DepartmentDTO from "../models/department/dto/department.dto"
import MunicipalityDTO from "../models/municipality/dto/municipality.dto"
import DocumentTypeDTO from "../models/document-type/dto/document-type.dto"
import IndicativeNumberDTO from "../models/indicative-number/dto/indicative-number.dto"
import { mapperToDepartmentModel } from "../models/department/dto/department.dto.dao"
import { mapperToMunicipalityModel } from "../models/municipality/dto/municipality.dto.dao"
import { mapperToDocumentTypeModel } from "../models/document-type/dto/document-type.dto.dao"
import { mapperToIndicativeNumberModel } from "../models/indicative-number/dto/indicative-number.dto.dao"
import { mapperToUserModel } from "../models/user/dto/user.dto.dao"
import { extractToClientUserDTO } from "../models/user/dto/user.dto.dao"

export async function createResolutionService(data) {
    try {
        let token = getTokenService()
        const { data: responseCreateResolution, error } = await Http.post(SettingsEndPoints.CREATE_RESOLUTION,data,token)
        if (responseCreateResolution && responseCreateResolution.status) {
            return responseCreateResolution?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getResolutionService() {
    try {
        let token = getTokenService()
        const { data: responseGetResolution, error } = await Http.get(SettingsEndPoints.GET_ALL_RESOLUTION,null,token)
        if (responseGetResolution && responseGetResolution.status) {
            return responseGetResolution?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getResolutionByIdService(id) {
    try {
        const urlWithParams = SettingsEndPoints.GET_RESOLUTION_BY_ID.replace(":id",id)
        const { data: responseGetResolution, error } = await Http.get(urlWithParams)
        if (responseGetResolution && responseGetResolution.status) {
            return responseGetResolution?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateResolutionByIdService(id,data) {
    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.UPDATE_RESOLUTION.replace(":id",id)
        const { data: responseUpdateResolution, error } = await Http.patch(urlWithParams,data,token)
        if (responseUpdateResolution && responseUpdateResolution.status) {
            return responseUpdateResolution?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getTypeResolutionService() {
    try {
        const { data: responseUpdateResolution, error } = await Http.get(SettingsEndPoints.GET_ALL_SERVICE)
        if (responseUpdateResolution && responseUpdateResolution.status) {
            return responseUpdateResolution?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getHeadquarterService() {
    try {
        let token = getTokenService()
        const { data: responseGetHeadquarter, error } = await Http.get(SettingsEndPoints.GET_HEADQUARTERS,null,token)
        if (responseGetHeadquarter && responseGetHeadquarter.status) {
            return responseGetHeadquarter?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getRoleService() {
    try {
        let token = getTokenService()
        const { data: responseGetRole, error } = await Http.get(SettingsEndPoints.GET_ROLE_ALL,null,token)
        if (responseGetRole && responseGetRole.status) {
            return responseGetRole?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getModulesService() {
    try {
        let token = getTokenService()
        const { data: responseGetModules, error } = await Http.get(SettingsEndPoints.GET_MODULES,null,token)
        if (responseGetModules && responseGetModules.status) {
            return responseGetModules?.data.flatMap( module => 
                module.subModule.map(subModule => ({
                    checked: false,
                    description: subModule.type,
                    id: subModule.id,
                    label: subModule.name,
                }))
            )
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

/* BEGIN BLOCK CONSTANTS */

export async function getAllNumberConstantService() {
    try {
        let token = getTokenService()
        const { data: responseGetNumberConstant, error } = await Http.get(SettingsEndPoints.GET_ALL_NUMBER_CONSTANT,null,token)
        if (responseGetNumberConstant && responseGetNumberConstant.status) {
            const parsedData = responseGetNumberConstant.data.map(item => ({
                ...item,
                value: parseFloat(item.value),
                activeButton: true
            }));
            
            return parsedData
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllStringConstantService() {
    try {
        let token = getTokenService()
        const { data: responseGetStringConstant, error } = await Http.get(SettingsEndPoints.GET_ALL_STRING_CONSTANT,null,token)
        if (responseGetStringConstant && responseGetStringConstant.status) {
            return responseGetStringConstant?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateNumberConstantService(code,value) {
    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.UPDATE_NUMBER_CONSTANT.replace(":code",code)
        const { data: responseUpdateNumber, error } = await Http.patch(urlWithParams,{value:value},token)
        if (responseUpdateNumber && responseUpdateNumber.status) {
            return responseUpdateNumber?.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateStringConstantService(code,value) {
    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.UPDATE_STRING_CONSTANT.replace(":code",code)
        const { data: responseUpdateString, error } = await Http.patch(urlWithParams,{value:value},token)
        if (responseUpdateString && responseUpdateString.status) {
            return responseUpdateString?.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createNewUserService(data) {
    try {
        let token = getTokenService()
        const { data: responseCreateUser, error } = await Http.post(SettingsEndPoints.CREATE_NEW_USER,data,token)
        if (responseCreateUser && responseCreateUser.status) {
            return responseCreateUser?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllUsersService(valueFilter = "", sortOrderType, page) {
    try {
        let token = getTokenService()
        const { data: responseGetAllUsers, error } = await Http.get(SettingsEndPoints.GET_FILTER_USERS, {valueFilter: valueFilter, sortOrderType, page}, token)
        if (responseGetAllUsers && responseGetAllUsers.status) {
            return responseGetAllUsers?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getUserAllPaginationCounterService(valueFilter = "", sortOrderType) {
    try {
        let token = getTokenService()
        const { data: responseGetUserAllPaginationCounter, error } = await Http.get(SettingsEndPoints.GET_USER_ALL_PAGINATION_COUNTER, {valueFilter: valueFilter, sortOrderType}, token)
        if (responseGetUserAllPaginationCounter && responseGetUserAllPaginationCounter.status) {
            return responseGetUserAllPaginationCounter?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getUserByIdService(id){
    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.GET_USER_BY_ID.replace(":id", id)
        const { data: responseGetUserById, error } = await Http.get(urlWithParams,null,token)
        if (responseGetUserById && responseGetUserById.status) {
            const response = responseGetUserById?.data
            return {
                newUserSettings: {
                    id: response.id,
                    nameCreateUser:  response.name,
                    lastNameCreateUser: response.lastName,
                    typeDocumentCreateUser: {
                        label: response.documentType.name,
                        value: response.documentType.id,
                    },
                    numberDocumentCreateUser: response.numberDocument,
                    dateOfBirthCreateUser: dayjs(response.dateBirth).format('DD-MM-YYYY'),
                    indicativeCreateUser: {
                        label: response.indicativeNumber.number,
                        value: response.indicativeNumber.id,
                    },
                    telephoneCreateUser: response.numberPhone,
                    emailCreateUser: response.email,
                    campusCreateUser: {
                        label: response.headquarter.name,
                        value: response.headquarter.id,
                    },
                    state: response.state
                },
                permitsNewUser:{
                    typeUser: {
                        label: response.role.name,
                        value: response.role.id,
                    },
                    typeUserOrigin:{
                        label: response.role.name,
                        value: response.role.id,
                    },
                    optionsPermitsNewUser: response.modules.flatMap( module => 
                        module.subModule.map(subModule => ({
                            checked: true,
                            description: subModule.type,
                            id: subModule.id,
                            label: subModule.module,
                        }))
                    ),
                    checkingYes: response?.hasPostPayroll === 0 ? false : true,
                    originCheckedManifest: response?.hasPostPayroll === 0 ? false : true,
                }
            }
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateUserByIdService(id, data) {
    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.UPDATE_USER_BY_ID.replace(":id", id)
        const { data: responseUpdateUserById, error } = await Http.patch(urlWithParams,data,token)
        if (responseUpdateUserById && responseUpdateUserById.status) {
            return responseUpdateUserById?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }

}

export async function getAllMoneyTransferRangeService() {
    try {
        let token = getTokenService()
        const { data: responseGetAllMoneyTransferRange, error } = await Http.get(SettingsEndPoints.GET_ALL_MONEY_TRANSFER_RANGE,null,token)
        if (responseGetAllMoneyTransferRange && responseGetAllMoneyTransferRange.status) {
            let response = responseGetAllMoneyTransferRange?.data
            response = response.map(obj => {
                const {isOpenRange, ...otherData} = obj
                return {...otherData, isInfinite : isOpenRange === 1 ? true : false}
            })
            return response
            
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createMoneyTransferRangeService(data) {
    try {
        let token = getTokenService()
        const { data: responseCreateMoneyTransferRange, error } = await Http.post(SettingsEndPoints.CREATE_MONEY_TRANSFER_RANGE,data,token)
        if (responseCreateMoneyTransferRange && responseCreateMoneyTransferRange.status) {
            return responseCreateMoneyTransferRange?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateByIdMoneyTransferRangeService(id, data) {
    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.UPDATE_BY_ID_MONEY_TRANSFER_RANGE.replace(":id", id)
        const { data: responseUpdateByIdMoneyTransfer, error} = await Http.patch(urlWithParams, data, token)
        if (responseUpdateByIdMoneyTransfer && responseUpdateByIdMoneyTransfer.status) {
            return responseUpdateByIdMoneyTransfer?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function deleteByIdMoneyTransferRangeService(id) {
    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.DELETE_BY_ID_MONEY_TRANSFER_RANGE.replace(":id", id)
        const { data: responseDeleteByIdMoneyTransfer, error} = await Http.delete(urlWithParams, null, token)
        if (responseDeleteByIdMoneyTransfer && responseDeleteByIdMoneyTransfer.status) {
            return responseDeleteByIdMoneyTransfer?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getListOfMunicipalitiesService() {
    try {
        let token = getTokenService()
        const { data: responseGetMunicipality, error } = await Http.get(SettingsEndPoints.GET_LIST_OF_MUNICIPALITIES, null, token)
        if (responseGetMunicipality && responseGetMunicipality.status) {
            const response = responseGetMunicipality?.data.map((item) => ({
                label: item?.name,
                value: item?.id
            }))

            return response

        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getListPricePassagesService() {
    try {
        let token = getTokenService()
        const { data:responseGetListPricePassages, error} = await Http.get(SettingsEndPoints.GET_LIST_PRICE_PASSAGES, null, token)
        if (responseGetListPricePassages && responseGetListPricePassages.status) {
            return responseGetListPricePassages?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateByIdPricePassagesService(id, data) {
    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.UPDATE_BY_ID_PRICE_PASSAGES.replace(":id", id)
        const { data: responseUpdateByIdPricePassages, error} = await Http.patch(urlWithParams, data, token)
        if (responseUpdateByIdPricePassages && responseUpdateByIdPricePassages.status) {
            return responseUpdateByIdPricePassages?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

/* END BLOCK CONSTANTS */

/* BEGIN BLOCK INVOICE MANAGEMENT */

export async function getTextSearchService(filter) {

    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.GET_FILTER_INVOICES.replace(":filter", filter)
        const { data: responseUpdateByIdPricePassages, error} = await Http.get(urlWithParams, null, token)
        if (responseUpdateByIdPricePassages && responseUpdateByIdPricePassages.status) {
            return responseUpdateByIdPricePassages?.data[0]
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }

};

export async function getTextSearchInvoicesService(filter) {

    try {
        let token = getTokenService()
        const urlWithParams = SettingsEndPoints.GET_FILTER_INVOICES.replace(":filter", filter)
        const { data: responseUpdateByIdPricePassages, error} = await Http.get(urlWithParams, null, token)
        if (responseUpdateByIdPricePassages && responseUpdateByIdPricePassages.status) {
            return responseUpdateByIdPricePassages?.data[0]
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateTypePaymentService(data) {
    try {
        let token = getTokenService()
        const { data: responseUpdateByIdPricePassages, error} = await Http.post(SettingsEndPoints.UPDATE_INVOICES, data, token)
        if (responseUpdateByIdPricePassages && responseUpdateByIdPricePassages.status) {
            return responseUpdateByIdPricePassages?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getAllDocumentTypeSettingService() {
    try {
        const { data: responseGetDocumentType, error } = await Http.get(SharedEndPoints.GET_DOCUMENT_TYPE)
        if (responseGetDocumentType && responseGetDocumentType.status) {
            const documentTypeDTOList = responseGetDocumentType.data
            let documentTypeList = []

            documentTypeDTOList.forEach(documentTypeDTO => {
                const documentType = mapperToDocumentTypeModel(new DocumentTypeDTO(documentTypeDTO.id, documentTypeDTO.name))
                documentTypeList.push(documentType)
            })
            return documentTypeList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllDepartmentSettingService() {
    try {
        const { data: responseGetDepartment, error } = await Http.get(SharedEndPoints.GET_DEPARTMENT)
        if (responseGetDepartment && responseGetDepartment.status) {
            const departmentDTOList = responseGetDepartment.data
            let departmentList = []

            departmentDTOList.forEach(departmentDTO => {
                const department = mapperToDepartmentModel(new DepartmentDTO(departmentDTO.id, departmentDTO.name))
                departmentList.push(department)
            })
            return departmentList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllMunicipalitySettingService(idDepartment) {
    try {
        const { data: responseGetMunicipality, error } = await Http.get(SharedEndPoints.GET_MUNICIPALITY,{ department: idDepartment })  
        if (responseGetMunicipality && responseGetMunicipality.status) {
            const municipalityDTOList = responseGetMunicipality.data
            let municipalityList = []

            municipalityDTOList.forEach(municipalityDTO => {
                const municipality = mapperToMunicipalityModel(new MunicipalityDTO(municipalityDTO.id, municipalityDTO.name))
                municipalityList.push(municipality)
            })
            return municipalityList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllIndicativeNumberSettingService() {
    try {
        const { data: responseGetIndicativeNumber, error } = await Http.get(SharedEndPoints.GET_INDICATIVE_NUMBER)
        if (responseGetIndicativeNumber && responseGetIndicativeNumber.status) {
            const indicativeNumberDTOList = responseGetIndicativeNumber.data
            let indicativeNumberList = []

            indicativeNumberDTOList.forEach(indicativeNumberDTO => {
                const indicativeNumber = mapperToIndicativeNumberModel(new IndicativeNumberDTO(indicativeNumberDTO.id, indicativeNumberDTO.number))
                indicativeNumberList.push(indicativeNumber)
            })
            return indicativeNumberList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getFilterUsersManagementService(filterValue) {
    try {
        const { data: responseGetFilterUsers, error } = await Http.get(SharedEndPoints.GET_FILTER_USERS,{filterValue})
        
        if (responseGetFilterUsers && responseGetFilterUsers.status) {
            const userDTOList = responseGetFilterUsers.data
            let userList = []

            userDTOList.forEach(userDTOElement => {
                
                let userDTO = new UserDTO(
                    userDTOElement.id,userDTOElement.name,userDTOElement.lastName,
                    userDTOElement.numberDocument,userDTOElement.numberPhone,
                    userDTOElement.numberPhoneWhatsapp,userDTOElement.address,
                    userDTOElement.email
                )

                if(userDTOElement.municipality){
                    const municipality = mapperToMunicipalityModel(
                        new MunicipalityDTO(
                            userDTOElement.municipality.id,
                            userDTOElement.municipality.name
                        )
                    )
                    userDTO.municipality = municipality
                }

                if(userDTOElement.documentType){
                    const documentType = mapperToDocumentTypeModel(
                        new DocumentTypeDTO(
                            userDTOElement.documentType.id,
                            userDTOElement.documentType.name
                        )
                    )
                    userDTO.documentType = documentType
                }

                if(userDTOElement.indicativeNumber){
                    const indicativeNumber = mapperToIndicativeNumberModel(
                        new IndicativeNumberDTO(
                            userDTOElement.indicativeNumber.id,
                            userDTOElement.indicativeNumber.number
                        )
                    )
                    userDTO.indicativeNumber = indicativeNumber
                }  

                if(userDTOElement.indicativeNumberWhatsApp){
                    const indicativeNumberWhatsApp = mapperToIndicativeNumberModel(
                        new IndicativeNumberDTO(
                            userDTOElement.indicativeNumberWhatsApp.id,
                            userDTOElement.indicativeNumberWhatsApp.number
                        )
                    )
                    userDTO.indicativeNumberWhatsApp = indicativeNumberWhatsApp
                }

                if(userDTOElement.department){
                    const department = mapperToDepartmentModel(
                        new DepartmentDTO(
                            userDTOElement.department.id,
                            userDTOElement.department.name
                        )
                    )
                    userDTO.department = department
                } 
                let user = mapperToUserModel(userDTO)

                user.label = user?.name + " " + user?.documentNumber;
                user.value = user?.label
                user.key = user?.id
                userList.push(user)
            })

            return userList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateUserClientService(data) {
    let token = getTokenService()
    const client = extractToClientUserDTO(data)
    try {
        const { data: responseUpdateClient, error } = await Http.put(SharedEndPoints.UPDATE_CLIENT,client,token)
        if (responseUpdateClient && responseUpdateClient.status) {
            return responseUpdateClient
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

