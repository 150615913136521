//Assets
import { Assets } from "../../../../../assets";

//Components
import PdfDriversComponent from "../pdf-drivers/pdf-drivers.component";
import ModalAssociateVehicleComponent from "../modal-associate-vehicle/modal-associate-vehicle.component";

//Components-Shared
import ModalMainComponent from "../../../../../shared/components/modal/modal-main/modal-main.component";
import ErrorToastComponent from "../../../../../shared/components/toast/error-toast/error-toast.component";

//Constants
import { VehicleErrorsConst } from "../../../../../core/constants/errors/alerts-app/vehicles.errors.const";
import AlertConst from "../../../../../core/constants/alerts.const";

//Libraries
import React, { useEffect, useState } from 'react';
import { Input, Select } from "antd";
import { Pagination, PaginationItem } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { BlobProvider } from "@react-pdf/renderer";

//Services
import { assignDriverAvailableService, getAllDriversPaginationService, getAllDriversPaginationCounterService } from "../../../../../services/drivers.services";

//Styles
import "./drivers-table.component.scss";

//Utils
import { excelFileGeneratorUtils } from "../../../../../utils/excel-file-generator.utils";

const filterOptions = [
    {
        label: "Conductores activos",
        value: "driverActive",
    },
    {
        label: "Conductores inactivos",
        value: "driverInactive",
    },
    {
        label: "Vehiculos vinculados",
        value: "linkedVehicle",
    },
    {
        label: "Sin vehiculos vinculados",
        value: "noLinkedVehicle",
    }
];

const headerTableFilter = [
    "Foto", "Nombre del conductor", "Número de contacto",
    "Licencia de conducción", "Nro. del vehículo", "Estado", "Acciones"
];

const getWidthByIndex = (index) => {
    const widthConfig = ['8%', '14%', '14%', '14%', '14%', '8%', '21%'];
    return widthConfig[index];
};

const DriversTableComponent = () => {

    let history = useNavigate();

    const INITIAL_STATE = {
        isOpenModal: false,
        listUserTypeDrivers: [],
        fullName: '',
        vehicleSelected: null,
        pageState: 1,
        itemByPage: 0,
        driverCount: 0,
        itemTotalList: 7,
        driverSelected: null,
        selectFilterTypes: undefined,
        valueFilterType: undefined,
        searchTextInput: "",
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { 
        isOpenModal, 
        listUserTypeDrivers, 
        fullName, 
        driverCount,
        pageState,
        itemTotalList,
        itemByPage,
        vehicleSelected,
        driverSelected,
        selectFilterTypes,
        valueFilterType,
        searchTextInput
    } = state;

    useEffect(() => {
        getAllDrivers(searchTextInput, undefined, valueFilterType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState]);

    const getAllDrivers = async (valueFilter, sortOrderType, valueFilterType) => {
        try {

            let itemCalculated = 0;
            const [ resDrivers, count ] = await Promise.all([
                getAllDriversPaginationService(valueFilter,valueFilterType || sortOrderType?.value, pageState),
                getAllDriversPaginationCounterService(valueFilter,valueFilterType || sortOrderType?.value)
            ]);

            if(itemTotalList * pageState <= count?.count) {
                itemCalculated = itemTotalList * pageState;
            }else{
                itemCalculated = count?.count;
            }

            const listUserTypeDrivers = resDrivers.driverVehicle.map((driver) => ({
                idDriver: driver?.id,
                state: driver?.state,
                phone: driver?.numberPhone,
                vehicleNumber: {
                    id: driver?.vehicle?.id,
                    plate: driver?.vehicle?.plate
                },
                driversLicense: driver?.numberDocument,
                fullName:`${driver?.name} ${driver?.lastName}`,
            }));
            setState(prevState => ({
                ...prevState,
                listUserTypeDrivers: listUserTypeDrivers,
                driverCount: count?.count,
                itemByPage: itemCalculated,
                searchTextInput: valueFilter || "",
                isOpenModal: false,
                valueFilterType
            }));
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    };

    const generateExcel = (infoHeader, itemFile, isMultipleListed) => {
        let renderItemList = [];
        if (isMultipleListed) {
            renderItemList = itemFile.map(item => ({
                driverName: item.fullName,
                contactNumber: item.phone,
                driversLicense: item.driversLicense,
                vehicleNumber: item?.vehicleNumber?.plate ? item?.vehicleNumber?.plate : '',
                state: item.state ? 'Disponible' : 'No disponible'
            }));
        } else {
            const renderItem = {
                driverName: itemFile.fullName,
                contactNumber: itemFile.phone,
                driversLicense: itemFile.driversLicense,
                vehicleNumber: itemFile?.vehicleNumber?.plate ? itemFile?.vehicleNumber?.plate : '',
                state: itemFile.state ? 'Disponible' : 'No disponible'
            };
            renderItemList.push(renderItem);
        }
        const columnWidths = [20, 18, 20, 18, 13]; // Predetermined widths.
        excelFileGeneratorUtils(
            infoHeader.slice(1, -1),
            renderItemList,
            (isMultipleListed) ? 'Conductores' : itemFile.driversLicense,
            columnWidths
        );
    };

    const printPdf = async (url) => {
        window.open(url)
    };

    const addDriverToVehicle = async() => {
        try {
            await assignDriverAvailableService({
                driver: driverSelected.idDriver,
                vehicle: vehicleSelected.vehicle.id
            })
            getAllDrivers();
        } catch (err) {
            ErrorToastComponent({
                title: VehicleErrorsConst.modal[err.code] || `${VehicleErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }
    }
    const handleData = (page) => {
        setState(prevState => ({
            ...prevState,
            pageState: page
        }))
     }

     const handlerChangeFilter = (value, target) => {
        if (target === "searchTextInput") {
          if (value === "") {
            getAllDrivers(value, undefined, valueFilterType);
          } else {
            setState({ ...state, searchTextInput: value.trim() })
          }
        } else {
            getAllDrivers(searchTextInput, value, value?.value || value)
        }
      };

    return (
        <>
            <div className="grid-x drivers-table__filters">
                <div className="grid-x small-2 drivers-table__select__container">
                    <Select
                        value={selectFilterTypes}
                        onChange={(e, event) => handlerChangeFilter(event, 'selectFilterTypes')}
                        placeholder={'Filtrar por'}
                        className="drivers-table__select"
                        options={filterOptions}
                        suffixIcon={
                            <img
                                src={Assets.SharedIcons.icon_down_arrow}
                                alt="icon_down_arrow"
                            />
                        }
                    />
                </div>
                <div className="grid-x small-6 align-middle">
                    <span className="drivers-table__input__info">Buscar</span>
                    <Input
                        allowClear={{
                            clearIcon: (
                                <img
                                    width={28}
                                    src={Assets.SharedIcons.icon_square_close}
                                    alt="icon_logo_vertical"
                                />
                            )
                        }}
                        className="drivers-table__input__content"
                        placeholder={'Buscar planilla por nombre o número de documento'}
                        prefix={
                            <img
                                className="drivers-table__input__search"
                                src={Assets.SharedIcons.icon_search}
                                alt="icon_search"
                            />
                        }
                        value={searchTextInput}
                        onChange={(text) => handlerChangeFilter(text.target.value.trim(), 'searchTextInput')}
                    />
                </div>
                <div className="grid-x small-2 justify-content-center">
                    <button 
                        disabled={!searchTextInput}
                        className="small-10 drivers-table__button-search"
                        onClick={() => getAllDrivers(searchTextInput, undefined, valueFilterType)}
                    >
                        <span>Buscar</span>
                    </button>
                </div>
                <div className="grid-x small-2 justify-content-end">
                    <button
                        className="small-12 drivers-table__download-list"
                        onClick={() => generateExcel(headerTableFilter, listUserTypeDrivers, true)}
                    >
                        <span>Descargar Listado</span>
                        <img
                            alt="icon_download_color_citron"
                            className="drivers-table__download-list__icon"
                            src={Assets.SharedIcons.icon_download_color_citron}
                        />
                    </button>
                </div>
            </div>
            <div className='grid-x drivers-table__box'>
                {headerTableFilter.map((item, index) => {
                    const lastElement = headerTableFilter[headerTableFilter.length - 1]
                    return (
                        <div key={index} className="grid-x drivers-table__subtitle__content"
                            style={{ width: lastElement === item? '28%' : getWidthByIndex(index)}}>
                            <span className='drivers-table__subtitle'>{item}</span>
                        </div>
                    )
                })}
                {listUserTypeDrivers.map((item, index) => {
                    return (
                        <div key={index} className='grid-x small-12 drivers-table__content-info'>
                            <div className="grid-x align-center-middle drivers-table__subtitle__content__text"
                                style={{ width: getWidthByIndex(0) }}>
                                <img
                                    className="drivers-table__photo-profile"
                                    src={Assets.SharedIcons.icon_user_profile}
                                    alt="icon_user_profile"
                                />
                            </div>
                            <div className="grid-x align-center-middle drivers-table__subtitle__content__text"
                                style={{ width: getWidthByIndex(1) }}>
                                <span className="boarding-sheet__text">
                                    {item?.fullName}
                                </span>
                            </div>
                            <div className="grid-x align-center-middle drivers-table__subtitle__content__text"
                                style={{ width: getWidthByIndex(2) }}>
                                <span className="boarding-sheet__text">
                                    {item?.phone}
                                </span>
                            </div>
                            <div className="grid-x align-center-middle drivers-table__subtitle__content__text"
                                style={{ width: getWidthByIndex(3) }}>
                                <span className="boarding-sheet__text">
                                    {item?.driversLicense}
                                </span>
                            </div>
                            <div className="grid-x align-center-middle drivers-table__subtitle__content__text"
                                style={{ width: getWidthByIndex(4) }}>
                                {(item?.vehicleNumber?.plate)
                                    ?
                                        <span className="boarding-sheet__text">
                                            {item?.vehicleNumber?.plate}
                                            <img className="drivers-table__icon-associate"
                                                src={Assets.SharedIcons.icon_associate_driver}
                                                alt="icon_associate_driver"
                                            />
                                        </span>
                                    :
                                        <button
                                            className="drivers-table__button__associate"
                                            onClick={() => setState({ ...state, isOpenModal: true, fullName: item?.fullName, idDriver: item?.idDriver, driverSelected: item })}
                                        >
                                            <span>Vincular vehículo</span>
                                            <img
                                                src={Assets.SharedIcons.icon_associate_driver}
                                                alt="icon_associate_driver"
                                            />
                                        </button>
                                }
                            </div>
                            <div className="grid-x align-center-middle"
                                style={{ width: getWidthByIndex(5) }}>
                                <div className={`drivers-table__state  ${item.state ? 'drivers-table__state--active' : 'drivers-table__state--inactive'}`}>
                                    <span>{item?.state ? 'Activo' : 'Inactivo'}</span>
                                </div>
                            </div>
                            <div className="grid-x drivers-table__subtitle__content__text"
                                style={{ width: getWidthByIndex(6) }}>
                                <div className="grid-x justify-content-between small-12">
                                    <div className="grid-x justify-content-between small-5">
                                        <button
                                            className="drivers-table__content-actions-button"
                                            onClick={() => history("./createDrivers", { replace: true, state: { typeAction: 'VIEW_INFO_DRIVER', idDriver: item.idDriver } })}
                                        >
                                            <img
                                                src={Assets.SharedIcons.icon_view_details}
                                                alt="icon_view_detail"
                                            />
                                        </button>
                                        <BlobProvider document={<PdfDriversComponent info={item} />}>
                                            {({ url, loading, error }) => {
                                                return (
                                                    <button
                                                        className="drivers-table__content-actions-button"
                                                        onClick={() => printPdf(url)}
                                                        disabled={loading || error}
                                                    >
                                                        <img
                                                            src={Assets.SharedIcons.icon_print_ticket}
                                                            alt="icon_print_ticket"
                                                        />
                                                    </button>
                                                );
                                            }}
                                        </BlobProvider>
                                        <button
                                            className="drivers-table__content-actions-button"
                                            onClick={() => generateExcel(headerTableFilter, item, false)}
                                        >
                                            <img
                                                src={Assets.SharedIcons.icon_download}
                                                alt="icon_download"
                                            />
                                        </button>
                                    </div>
                                    <div className="grid-x small-7 justify-content-evenly">
                                        <div className="grid-x align-center-middle">
                                            <button
                                                className="grid-x drivers-table__button__solid"
                                                onClick={() => history("./createDrivers", { replace: true, state: { typeAction: 'EDIT_INFO_DRIVER', idDriver: item.idDriver, isEditing: true} })}
                                            >
                                                <div className="grid-x small-7">
                                                    <span>Editar perfil</span>
                                                </div>
                                                <img
                                                    className="drivers-table__button__icon"
                                                    src={Assets.SharedIcons.icon_pencil}
                                                    alt="icon_pencil"
                                                />
                                            </button>
                                        </div>
                                        {/* TODO: This action has no use case for the first phase. */}

                                        {/* <div className="grid-x align-center-middle">
                                            <button className="grid-x drivers-table__button__border">
                                                <div className="grid-x small-8">
                                                    <span>Solicitud de permisos</span>
                                                </div>
                                                <img
                                                    className="drivers-table__button__icon"
                                                    src={Assets.SharedIcons.icon_format}
                                                    alt="icon_user_profile"
                                                />
                                            </button>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="grid-x justify-content-between">
                <span className="drivers-table__info">
                    {`${ itemByPage || 0} de ${driverCount} resultados`}
                </span>
                <div className="grid-x drivers-table__pagination__content">
                    <Pagination
                        disabled={driverCount === 0 ? true : false}
                        onChange={(e, event) => handleData(event)}
                        count={Math.ceil(driverCount / itemTotalList) || 1}
                        siblingCount={0}
                        boundaryCount={0}
                        showFirstButton
                        showLastButton
                        shape="rounded"
                        className="drivers-table__pagination"
                        renderItem={(item) => (
                            <PaginationItem
                                className="drivers-table__pagination__item"
                                slots={{
                                    previous: () => {
                                        return (
                                            <img
                                                src={Assets.SharedIcons.icon_back}
                                                alt="icon_back"
                                            />
                                        )
                                    },
                                    next: () => {
                                        return (
                                            <img
                                                src={Assets.SharedIcons.icon_next}
                                                alt="icon_forward"
                                            />
                                        )
                                    },
                                    first: () => {
                                        return (
                                            <img
                                                src={Assets.SalesIcons.icon_back}
                                                alt="icon_back"
                                            />
                                        )
                                    },
                                    last: () => {
                                        return (
                                            <img
                                                src={Assets.SalesIcons.icon_forward}
                                                alt="icon_back"
                                            />
                                        )
                                    },
                                }}
                                {...item}
                            />
                        )}
                    />
                </div>
            </div>
            {isOpenModal &&
                <ModalMainComponent
                    close
                    width={380}
                    open={isOpenModal}
                    onClose={() => setState({ ...state, isOpenModal: false, fullName:'', idDriver:''})}
                    titleIcon={<img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name' />}
                    footer={[
                        <div key="accept" className='grid-x align-center'>
                            <button
                                disabled={!vehicleSelected}
                                onClick={() => addDriverToVehicle()}
                                className='boarding-sheet__modal__button'
                            >
                                <span>{"Vincular vehículo"}</span>
                            </button>
                        </div>
                    ]}
                >
                    <ModalAssociateVehicleComponent 
                        fullName={fullName}
                        setVehicleSelected={(vehicle) => setState({...state, vehicleSelected: vehicle})}
                        setDriverSelected={driverSelected}
                    />
                </ModalMainComponent>
            }
        </>
    )
}

export default DriversTableComponent