const BoardingSheetEndPoints =  {
    GET_MANIFEST: `/api/travel/all/manifest/byDate`,
    GET_ALL_MANIFEST: `/api/travel/all/manifest`,
    GET_ALL_MANIFEST_COUNT: `/api/travel/all/manifest/count`,
    UPDATE_ASSIGN_TRAVEL_ANOTHER_VEHICLE: `/api/travel/assignTravelAnotherVehicle/:id`,
    CREATE_MANIFEST_NUMBER: `/api/travel/createManifestNumber/:id`,
    GET_MANIFEST_BY_ID: `/api/travel/manifest/:id`,
    GET_HEADQUARTER_ASSOCIATED: `/api/seller/headquarterAssociated`,
    GET_ORDER_WITH_GUIDE: `/api/shipping/assignTravelToVariousShipping`,
    GET_SHIPPING_ASSOCIATED_BY_TRAVEL: `/api/invoice/shipping/all/associatedByTravel`,
    GET_DATA_ALL_PDF: `/api/travel/all/manifest/details`,
    POST_MANIFEST_TRAVEL_BY_RANGE_DATE: `/api/travel/postManifest/byRangeDate`,
    GET_PASSAGES: `/api/travel/ticketTemporal/:id`,
    GET_SHIPPINGS: `/api/travel/shippingTemporal/:id`,
    GET_TEMPORARY: `/api/travel/manifestTemporal/:id`,
    PATCH_PASSAGES: `/api/travel/ticketBindingTravel`,
    PATCH_SHIPPING: `/api/travel/shippingBindingTravel`,
    UPDATE_TEMPORARY: `/api/travel/updateTravelTemporal/:id`,
    GET_DETAILS_TEMPORARY: `/api/travel/getTemplateTemporalTravel/:id`,
    PATCH_DELETE: `/api/travel/manifestNumber/:id`,
    GET_PAYROLL_PROCESS_TO_ZERO: `/api/travel/getPayrollProcessToZero`,
    GET_PASSAGES_LIST: `/api/invoice/ticket/all/associatedByTravel`,
    PATCH_TRANSFER_PASSAGES: `/api/travel/assignTravelToVariousTicket`,
}

export default BoardingSheetEndPoints