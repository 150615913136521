//Assets
import { Assets } from "../../../../../assets";

//Libraries
import React from "react";
import { useTranslation } from "react-i18next";

//Styles
import "./table-no-sales.component.scss"

const TableNoSalesComponent = ({ headers }) => {
  const [t] = useTranslation("translation");
  return (
    <>
      <div className="grid-x small-12 table-no-sales__content-data">
        {headers? Object.values(headers)?.map((header, index) => (
          <div key={index} className=" table-no-sales__text__content">
            <span className="table-no-sales__text table-no-sales__title">
              {header}
            </span>
          </div>
        )): null}
        <div className="medium-1 small-12 table-no-sales__text__content table-no-sales__text__content--mod" />
      </div>
      <div className="table-no-sales__content grid-y align-center-middle">
        <img
          src={Assets.SalesIcons.icon_broken_link}
          alt="icon_broken_link"
        />
        <span className="table-no-sales__sorry text-center">{t("sales.salesFooter.tableNoSales.sorry")}</span>
      </div>
    </>
  );
};

export default TableNoSalesComponent;
