// Model
import MunicipalityModel from "../model/municipality.model";
import MunicipalityDTO from "./municipality.dto";

export function mapperToMunicipalityModel(municipalityDTO) {
    return new MunicipalityModel(municipalityDTO.id, municipalityDTO.name)
}

export function extractToMunicipalityDTO({label,value}){
    return new MunicipalityDTO(value,label) 
}