export const SettingsErrorsConst = {
    resolutions: {
        RSLT001: "La ruta es obligatoria",
        RSLT002: "Se requiere el prefijo TNS",
        RSLT003: "El prefijo TNS no es válido",
        RSLT004: "El prefijo DIAN es obligatorio",
        RSLT005: "Se requiere el consecutivo actual",
        RSLT006: "Se requiere el número de resolución",
        RSLT007: "El número de resolución no es válido",
        RSLT008: "Se requiere el rango inicial de resolución",
        RSLT009: "Se requiere el rango final de resolución",
        RSLT010: "Los rangos de resolución no son válidos",
        RSLT011: "Se requiere la fecha de la resolución",
        RSLT012: "La fecha de la resolución no es válida",
        RSLT013: "Se requiere el código del banco",
        RSLT014: "El código del banco no es válido",
        RSLT015: "La ruta no es válida",
        RSLT016: "El tipo de servicio es requerido",
        RSLT020: "El tipo de servicio no es válido",
        RSLT021: "El prefijo TNS ya existe",
        RSLT022: "El prefijo DIAN ya existe",
        RSLT023: "El banco no existe",
        RSLT024: "El banco no es válido",
    },

    moneyTraRanges: {
        MTRG001: "El porcentaje del costo es requerido",
        MTRG002: "El porcentaje del iva es requerido",
        MTRG003: "El costo del giro es requerido",
        MTRG006: "El porcentaje del costo no es válido",
        MTRG007: "El porcentaje del iva no es válido",
        MTRG020: "El identificador de rango es requerido",
        MTRG021: "El rango de giro no encontrado"
    },

    routesPassages: {
        ROUT007: "La ruta es invalida",
        ROUT008: "La ruta es requerida",
        ROUT009: "La ruta no existe",
        ROUT0010: "El precio de la ruta es requerido"
    },

    newUsers: {
        //Creación de usuarios
        BNNK001: "La caja asociada no encontrada",
        DOCT003: "El tipo de documento no existe",
        HDQT001: "La sede no existe",
        INDN005: "El indicativo del teléfono no existe",
        ROLE003: "El tipo de usuario no existe",
        SBMD001: "El submódulo es requerido",
        SBMD002: "El submódulo no existe",
        USRL001: "El nombre es requerido",
        USRL006: "El usuario no existe",
        USRL007: "El apellido es requerido",
        USRL010: "La contraseña no es segura",
        USRL018: "El número de documento es requerido",
        USRL020: "El indicativo del teléfono es requerido",
        USRL029: "El número de documento ya está en uso",
        USRL028: "EL correo electrónico ya está en uso",
        USRL032: "El tamaño del nombre debe tener un mínimo de 3 caracteres y un máximo de 100",
        USRL033: "El tamaño del apellido debe tener un mínimo de 3 caracteres y un máximo de 100",
        USRL034: "La fecha de nacimiento es requerida",
        USRL035: "El número de teléfono es requerido",
        USRL036: "El número de teléfono debe tener un mínimo de 5 caracteres y un máximo de 10",
        USRL037: "El número del documento debe tener un mínimo de 5 caracteres y un máximo de 10",
        USRL038: "El correo electrónico no es válido",
        USRL039: "El correo electrónico es requerido",
        USRL040: "El tamaño del correo electrónico debe tener un mínimo de 5 caracteres y un máximo de 100",
        USRL041: "La contraseña es requerida",
        USRL042: "La contraseña debe tener un mínimo de 6 caracteres y un máximo de 10",
        USRL043: "Es necesario confirmar la contraseña",
        USRL044: "La contraseña confirmada debe tener un mínimo de 6 caracteres y un máximo de 10",
        USRL045: "La contraseña no coincide",
        USRL046: "El tipo de documento es obligatorio",
        USRL047: "El rol del usuario es requerido",
        USRL048: "La sede del usuario es requerida",
        USRL049: "El submódulo es requerido",
        USRL050: "Tipo de rol no válido para la creación de usuarios",
        USRL051: "Usuario ya está registrado en el sistema con el mismo número de documento y rol",

        //Actualización de usuarios
        USRL004: "El ID es requerido",
        USRL030: "El estado del usuario es requerido",
        USRL031: "El estado del usuario no es válido"
    },

    constants: {
        //String system constants
        USRL006: "El usuario no existe",
        SSCC001: "Se requiere la constante del sistema de cálculo del código",
        SSCC002: "No se ha encontrado la constante del sistema de cálculo del código",
        SSCC005: "Se requiere la constante del sistema de cálculo de valores",
        SSCC006: "La constante del sistema de cálculo del valor no es válido",

        //Calculation system constants
        CSCC001: "Se requiere la constante del sistema de cálculo del código",
        CSCC002: "No se ha encontrado la constante del sistema de cálculo del código",
        CSCC005: "Se requiere la constante del sistema de cálculo de valores",
        CSCC006: "La constante del sistema de cálculo del valor no es válida"
    },

    invoice:{
        TRVL058: "No se puede actualizar, hace parte de un viaje ya planillado.",
        INVO030: "No se puede actualizar, ya está sincronizado con TNS."
    },
    default: "Ocurrió un error",
  };
  