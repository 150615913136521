//Assets
import { Assets } from '../../../../assets'

//Libraries
import React from 'react'
import { Modal } from 'antd'
import { connect } from 'react-redux'

const ModalMainComponent = (props) => {
    const {
      open,
      width,
      children,
      footer,
      onClose,
      title,
      titleClass,
      close,
      titleIcon
    } = props

  return (
    <>
        <Modal
            open={open}
            title={titleIcon ? titleIcon : <span className={titleClass}>{title}</span>}
            centered
            width={width}
            onCancel={()=>{
              onClose()
            }}
            closeIcon={
              close ? 
                <img src={Assets.SharedIcons.icon_square_close} alt="icon_square_close"/>
              :
                <img src={Assets.SharedIcons.icon_close} alt="icon_close"/>
            }
            footer={footer}
            destroyOnClose
        >
            {children}
        </Modal>
    </>
  )
}

const mapStateToProps = ({ AppReducer }) => {
  const { isModalMain } = AppReducer;
  return {
    isModalMain
  };
};
  
export default connect(mapStateToProps, null)(ModalMainComponent);