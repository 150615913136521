//Assets
import { Assets } from "../../../../assets";

//Libraries
import { Checkbox } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

//Styles
import "./navigation-body.component.scss"

const NavigationBodyComponent = (props) => {

    const {
        //Functions
        onBack = ()=> null,
        onInvoice= ()=> null,
        onSave= ()=> null,
        onNext= ()=> null,
        //Variables
        locationStepper,
        assignedSeats,
        isError,
        isDisabled,
        children,
        check = false,
        textSave,
        textNext,
        textInvoice,
        numStepper,
        isPreview = false,
        isSave = false,
        isIconNext = false,
        nextButtonRef,
        invoiceButtonRef
    } = props

    const [t] = useTranslation("translation")
  return (
    <>
      <div
        className={`small-12 navigation-body__box ${
          locationStepper === 1
            ? "navigation-body__footer--mod"
            : "navigation-body__footer"
        } ${
          locationStepper === 2 ? "navigation-body__footer__check__content" : ""
        }
            `}
      >
        <div
          className={`${
            locationStepper === 1 && !!assignedSeats?.length
              ? "navigation-body__footer__content--mod"
              : "navigation-body__footer__content"
          } ${check ? "navigation-body__footer__box" : ""}
            `}
        >
            {children}
        </div>
        {locationStepper === 1 && !!assignedSeats?.length && (
          <div className="navigation-body__footer__total">
            <label className="navigation-body__footer__total__text">
              {t("sales.passages.totalPurchase")} ${" "}
              {
                Math.floor(
                  assignedSeats
                  .map((seat) => parseFloat(seat.price))
                  .reduce((total, price) => total + price, 0)
                )
                .toLocaleString()
              }
            </label>
          </div>
        )}
        {/* TODO: Implement check */}
        {check && (
          <div className="navigation-body__footer__check">
            <Checkbox>{t("sales.passages.reminder")}</Checkbox>
          </div>
        )}
        <div
          className={`grid-x ${
            locationStepper === 1
              ? "navigation-body__footer__container--mod"
              : "navigation-body__footer__container"
          } ${
            locationStepper > 2 && !isPreview
              ? "navigation-body__footer__container--invoice"
              : ""
          }`}
        >
          <button
            style={{ visibility: locationStepper === 0 ? "hidden" : "visible" }}
            onClick={()=> onBack()}
            className="grid-x align-middle navigation-body__footer__button"
          >
            <img
              src={Assets.SharedIcons.icon_back}
              alt="icon_back"
              width={30}
            />
            <span>{t("sales.passages.return")}</span>
          </button>
          <div className="navigation-body__footer__button__box">
            {locationStepper >= (numStepper - 1) && !isPreview && (
              <button
                ref={invoiceButtonRef}
                disabled={isError}
                className="navigation-body__footer__button navigation-body__footer__button--mod"
                onClick={() => onInvoice()}
              >
                <span>
                  {textInvoice}
                </span>
              </button>
            )}
            { (!isPreview && isSave) &&
              <button
                onClick={() => onSave()}
                className="navigation-body__footer__button__save"
              >
                <span>
                  {textSave}
                </span>
              </button>
            }
          </div>
          <button
            ref={nextButtonRef}
            className="grid-x navigation-body__footer__button align-middle justify-content-end"
            disabled={isError || (locationStepper >= (numStepper - 1) && !isDisabled)}
            onClick={() => onNext()}
          >
            <span style={isIconNext ? { width: "100%" } : {}}>
              {textNext}
            </span>
            { !isIconNext &&
              <img
                src={Assets.SharedIcons.icon_next}
                alt="icon_next"
                width={30}
              />
            }
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ SalesReducer }) => {
    const {
      passageSales: {
        assignedSeats
      },
      isError,
      isDisabled
    } = SalesReducer;
    return {
      assignedSeats,
      isError,
      isDisabled
    };
};
  
export default connect(mapStateToProps, null)(NavigationBodyComponent);
