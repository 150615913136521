// Constants
import SalesConst from '../../core/constants/sales.const';

// Libraries
import * as Yup from 'yup';

// Services
import { getConstantSystemService } from '../../services/sales.services';

/*
* Startup of the passages sales module validators
*/
export const passagesDataClientSchema = Yup.object().shape({
    documentType: Yup.mixed()
        .required('Requerido'),
    documentNumber: Yup.string().when('documentType', {
        is: (documentType) => documentType && documentType.label === 'NIT',
        then: Yup.string()
            .matches(/^\d+-?\d*$/, 'Solo se permite números y un guion entre el número')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
        otherwise: Yup.string()
            .matches(/^[0-9]+$/, 'Solo se permiten números')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
    }),
    name: Yup.string()
        .required('Requerido'),
    lastName: Yup.string()
        .required('Requerido'),
    telephone: Yup.string()
        .min(10, 'Mínimo 10 dígitos')
        .max(12, 'Máximo 12 dígitos')
        .required('Requerido'),
    contactAddress: Yup.string(),
    contactEmail: Yup.string()
        .email('Email no valido'),
    municipalityResidence: Yup.mixed()
        .when('departmentResidence', {
            is: value => typeof value === 'object' && value !== null && Object.keys(value).length > 0,
            then: Yup.mixed().required('Requerido')
        })
})

export const passagesTripDataSchema = Yup.object().shape({
    date: Yup.string()
        .required('Requerido'),
    hours: Yup.string()
        .required('Requerido'),
    destination: Yup.mixed()
        .required('Requerido'),
    numberOfSeats: Yup.number()
        .moreThan(0, 'Seleccionar cantidad de asientos')
        .integer(),
    vehicle: Yup.mixed()
        .required('Requerido'),
    assignedSeats: Yup.array()
        .when('numberOfSeats', 
            (numberOfSeats, schema) => schema.length(numberOfSeats, 'Seleccione los asientos'),
        )
})

export const passagesPassengerDataSchema = Yup.object().shape({
    name: Yup.string()
        .required('Requerido'),
    lastName: Yup.string()
        .required('Requerido'),
    telephoneCode: Yup.mixed()
        .required('Requerido'),
    telephone: Yup.string()
        .min(10, 'Mínimo 10 dígitos')
        .max(12, 'Máximo 12 dígitos')
        .required('Requerido'),
})

export const passagesBillingDataSchema = Yup.object().shape({
    documentType: Yup.mixed()
        .required('Requerido'),
    documentNumber: Yup.string()
        .min(6, 'Mínimo 6 dígitos')
        .max(20, 'Máximo 20 dígitos')
        .required('Requerido'),
    name: Yup.string()
        .required('Requerido'),
    lastName: Yup.string()
        .required('Requerido'),
    telephone: Yup.string()
        .min(10, 'Mínimo 10 dígitos')
        .max(12, 'Máximo 12 dígitos')
        .required('Requerido'),
    contactAddress: Yup.string(),
    municipalityResidence: Yup.mixed(),
    typePayment: Yup.mixed()
        .required('Requerido'),
    extraValue: Yup.mixed().when(['typePayment', 'typePayment.label'], {
        is: (typePayment, label) => label === 'Crédito',
        then: Yup.number().min(0).required('Requerido'),
        otherwise: Yup.number().notRequired(),
    }),
})

/*
* End of the passages sales module validators
*/

/*
* Startup of the turn module validators
*/

export const turnCustomerDataSchema = Yup.object().shape({
    documentTypeCustomer: Yup.mixed()
        .required('Requerido'),
    documentNumberCustomer: Yup.string().when('documentTypeCustomer', {
        is: (documentTypeCustomer) => documentTypeCustomer && documentTypeCustomer.label === 'NIT',
        then: Yup.string()
            .matches(/^\d+-?\d*$/, 'Solo se permite números y un guion entre el número')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
        otherwise: Yup.string()
            .matches(/^[0-9]+$/, 'Solo se permiten números')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
    }),
    nameCustomer: Yup.string()
        .required('Requerido'),
    lastNameCustomer: Yup.string()
        .required('Requerido'),
    telephoneCustomer: Yup.string()
        .min(10, 'Mínimo 10 dígitos')
        .max(12, 'Máximo 12 dígitos')
        .required('Requerido'),
    contactAddressCustomer: Yup.string(),
    contactEmailCustomer: Yup.string()
        .email('Email no valido'),
    municipalityResidenceCustomer: Yup.mixed()
    .when('departmentResidenceCustomer', {
        is: value => typeof value === 'object' && value !== null && Object.keys(value).length > 0,
        then: Yup.mixed().required('Requerido')
    })
})

export const turnRecipientsDataSchema = Yup.object().shape({
    documentTypeRecipients: Yup.mixed()
        .required('Requerido'),
    documentNumberRecipients: Yup.string().when('documentTypeRecipients', {
        is: (documentTypeRecipients) => documentTypeRecipients && documentTypeRecipients.label === 'NIT',
        then: Yup.string()
            .matches(/^\d+-?\d*$/, 'Solo se permite números y un guion entre el número')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
        otherwise: Yup.string()
            .matches(/^[0-9]+$/, 'Solo se permiten números')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
    }),
    nameRecipients: Yup.string()
        .required('Requerido'),
    lastNameRecipients: Yup.string()
        .required('Requerido'),
    telephoneRecipients: Yup.string()
        .min(10, 'Mínimo 10 dígitos')
        .max(12, 'Máximo 12 dígitos')
        .required('Requerido'),
    contactAddressRecipients: Yup.string(),
    contactEmailRecipients: Yup.string()
        .email('Email no valido'),
    departmentResidenceRecipients: Yup.mixed()
        .required('Requerido'),
    municipalityResidenceRecipients: Yup.mixed()
        .required('Requerido'),
})

export const turnCostAndInvoicingSchema = Yup.object().shape({
    documentTypeRecipients: Yup.mixed()
        .required('Requerido'),
    documentNumberRecipients: Yup.string().when('documentTypeRecipients', {
        is: (documentTypeRecipients) => documentTypeRecipients && documentTypeRecipients.label === 'NIT',
        then: Yup.string()
            .matches(/^\d+-?\d*$/, 'Solo se permite números y un guion entre el número')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
        otherwise: Yup.string()
            .matches(/^[0-9]+$/, 'Solo se permiten números')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
    }),
    nameRecipients: Yup.string()
        .required('Requerido'),
    lastNameRecipients: Yup.string()
        .required('Requerido'),
    isCalculate: Yup.boolean().oneOf([true], 'Debe ser verdadero')
})

/*
* End of the turn module validators
*/

/*
* Startup of the shippings module validators
*/

export const shippingsOrderDataSchema = Yup.object().shape({
    paymentMethod: Yup.mixed()
        .required('Requerido'),
    typePackage: Yup.object()
        .test('validate-object', 'Requerido', async (value) => {
            if (value && value.value) {
                return true
            }else{
                return false
            }
        })
        .required('Requerido'),
    domicile: Yup.mixed()
        .required('Requerido'),
    serviceType: Yup.mixed()
        .required('Requerido'),
    declaredValue: Yup.mixed().when('serviceType', {
        is: (serviceTypeValue) => typeof serviceTypeValue === 'object',
        then: Yup.number()
            .test('async-validation', 'El valor no cumple con el mínimo requerido', async (value, { parent }) => {
                const serviceTypeValue = parent?.serviceType;
                if(serviceTypeValue?.label === 'Sobre') return true;
                try {
                    const constData = await getConstantSystemService(SalesConst.MINIUM_DECLARED_VALUE)
                    if (value >= constData?.value) {
                        return true
                    }else{
                        return false
                    }
                } catch (error) {
                    // TODO: Implement error alert with code error.
                }
            }),
        otherwise: Yup.number().required('Requerido'),
    }),
    weightAmount: Yup.mixed().when(['serviceType', 'serviceType.label'], {
        is: (serviceType, label) => label === 'Peso',
        then: Yup.number().positive("El número debe ser mayor a cero").required('Requerido'),
        otherwise: Yup.number().notRequired(),
    }),
    insuranceCost: Yup.number()
        .test('async-validation-cost-order', 'El valor no cumple con el mínimo requerido', async (value, { parent }) => {
            try {
                const declaredValue = parent?.declaredValue;
                const constData = await getConstantSystemService(SalesConst.DECLARED_VALUE)
                if (value >= Math.ceil(declaredValue * constData?.value)) {
                    return true
                }else{
                    return false
                }
            } catch (error) {
                // TODO: Implement error alert with code error.
            }
        })
        .required('Requerido'),
    totalOrder: Yup.number()
    .required('Requerido')
        .test('async-validation-cost-order', 'El valor de la encomienda debe de ser mayor o igual a $6,000', async (value) => {
            if (value < 6000) {
                return false
            }
            try {
                const constData = await getConstantSystemService(SalesConst.MINIUM_VALUE)
                if (value >= constData?.value) {
                    return true
                } else {
                    return false
                }
            } catch (error) {
                // TODO: Implement error alert with code error.
            }
        }),
    isCalculate: Yup.boolean()
        .oneOf([true], 'Debe ser verdadero'),
    domicileCost: Yup.mixed()
        .when(['domicile', 'domicile.value'], {
            is: (domicile, value) => value ? true : false,
            then: Yup.number().positive("El número debe ser mayor a cero").required('Requerido')
        }),
})

export const shippingsCustomerDataSchema = Yup.object().shape({
    documentTypeCustomer: Yup.mixed()
        .required('Requerido'),
    documentNumberCustomer: Yup.string().when('documentTypeCustomer', {
        is: (documentTypeCustomer) => documentTypeCustomer && documentTypeCustomer.label === 'NIT',
        then: Yup.string()
            .matches(/^\d+-?\d*$/, 'Solo se permite números y un guion entre el número')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
        otherwise: Yup.string()
            .matches(/^[0-9]+$/, 'Solo se permiten números')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
    }),
    nameCustomer: Yup.string()
        .required('Requerido'),
    lastNameCustomer: Yup.string()
        .required('Requerido'),
    telephoneCustomer: Yup.string()
        .min(10, 'Mínimo 10 dígitos')
        .max(12, 'Máximo 12 dígitos')
        .required('Requerido'),
    contactAddressCustomer: Yup.string(),
    contactEmailCustomer: Yup.string()
        .email('Email no valido'),
    municipalityResidenceCustomer: Yup.mixed()
    .when('departmentResidenceCustomer', {
        is: value => typeof value === 'object' && value !== null && Object.keys(value).length > 0,
        then: Yup.mixed().required('Requerido')
    })
})

export const shippingsRecipientsDataSchema = Yup.object().shape({
    documentTypeRecipients: Yup.mixed()
        .required('Requerido'),
    documentNumberRecipients: Yup.string().when('documentTypeRecipients', {
        is: (documentTypeRecipients) => documentTypeRecipients && documentTypeRecipients.label === 'NIT',
        then: Yup.string()
            .matches(/^\d+-?\d*$/, 'Solo se permite números y un guion entre el número')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
        otherwise: Yup.string()
            .matches(/^[0-9]+$/, 'Solo se permiten números')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
    }),
    nameRecipients: Yup.string()
        .required('Requerido'),
    lastNameRecipients: Yup.string()
        .required('Requerido'),
    telephoneRecipients: Yup.string()
        .min(10, 'Mínimo 10 dígitos')
        .max(12, 'Máximo 12 dígitos')
        .required('Requerido'),
    contactAddressRecipients: Yup.string(),
    contactEmailRecipients: Yup.string()
        .email('Email no valido'),
    departmentResidenceRecipients: Yup.mixed()
        .required('Requerido'),
    municipalityResidenceRecipients: Yup.mixed()
        .required('Requerido'),
})

export const shippingsBillingDataSchema = Yup.object().shape({
    documentTypeCustomer: Yup.mixed()
        .required('Requerido'),
        documentNumberCustomer: Yup.string().when('documentTypeCustomer', {
        is: (documentTypeCustomer) => documentTypeCustomer && documentTypeCustomer.label === 'NIT',
        then: Yup.string()
            .matches(/^\d+-?\d*$/, 'Solo se permite números y un guion entre el número')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
        otherwise: Yup.string()
            .matches(/^[0-9]+$/, 'Solo se permiten números')
            .min(6, 'Mínimo 6 caracteres')
            .max(20, 'Máximo 20 caracteres')
            .required('Requerido'),
    }),
    nameCustomer: Yup.string()
        .required('Requerido'),
    lastNameCustomer: Yup.string()
        .required('Requerido'),
    nameRecipients: Yup.string()
        .required('Requerido'),
    lastNameRecipients: Yup.string()
        .required('Requerido'),
    domicileDescription: Yup.string().when('domicile', {
        is: (domicile) => domicile.value === 1,
        then: Yup.string().required('Requerido'),
        otherwise: Yup.string(),
    }),
    departmentResidenceRecipients: Yup.mixed()
        .required('Requerido'),
    municipalityResidenceRecipients: Yup.mixed()
        .required('Requerido'),
    costOrder: Yup.string()
        .required('Requerido'),
    insuranceCost: Yup.string()
        .required('Requerido'),
})
/*
* End of the shippings module validators
*/

export const comparePassenger = (seatsData, seatsReducer) => {
    const modifiedArray = seatsReducer.map((objectReducer) => {
        const objectOrigin = seatsData?.find((seatData) => objectReducer?.id === seatData?.id);

        if (!objectOrigin || (objectOrigin && !objectOrigin.hasOwnProperty('passenger'))) {
            return { ...objectReducer, passenger: undefined };
        }
        if (
            objectReducer.passenger && objectOrigin.passenger &&
            objectReducer.passenger.name === objectOrigin.passenger.name &&
            objectReducer.passenger.lastName === objectOrigin.passenger.lastName &&
            objectReducer.passenger.telephone === objectOrigin.passenger.telephone &&
            objectReducer.passenger.telephoneCode === objectOrigin.passenger.telephoneCode
        ) {
            return { ...objectReducer };
        }
        return { ...objectReducer, passenger: objectOrigin.passenger };
    });

    return modifiedArray;
};

export const passagesModalPreviewSchema = Yup.object().shape({

    optionsShipping: Yup.mixed().test('validateArray', 'Requerido', (value) => {
        if (value.length) {
            return true
        } else {
            return false
        }
    })
        .required('Requerido'),
    optionsVehicleSearch: Yup.object().test('validate-object', 'Requerido', (value) => {
        if (value && value.travel) {
            return true
        } else {
            return false
        }
    })
    .required('Requerido')
})

export const typeOfOrderSchema = Yup.object().shape({
    typeOfOrder: Yup.string()
    .required('Requerido'),
})

export const saleTableSchema = Yup.object().shape({

    numberDocumentDelivered: Yup.string()
        .matches(/^[0-9]+$/, 'No es un numero')
        .min(6, 'Minimo 6 digitos')
        .max(10, 'Maximo 10 digitos')
        .required('Requerido'),
    nameDelivered: Yup.string()
        .required('Requerido'),
    lastNameDelivered: Yup.string()
        .required('Requerido'),
})