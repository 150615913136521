//Assets
import { Assets } from '../../../../../assets';

//Components
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';

//Constants
import { BoardingSheetErrorsConst } from '../../../../../core/constants/errors/alerts-app/boarding-sheet.errors.const';
import AlertConst from '../../../../../core/constants/alerts.const';

//Libraries
import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';

//services
import { getFilterVehicleAvailableToVehicleService } from '../../../../../services/vehicle.services';

//Styles
import "./modal-associate-vehicle.component.scss";

const ModalAssociateVehicleComponent = (props) => {

    const {
        //Actions
        setVehicleSelected = () => {},
        fullName='',
    } = props;

    const INITIAL_STATE = {
        optionsVehicleSearch: []
    }

    const [state, setState] = useState(INITIAL_STATE)

    const {
        optionsVehicleSearch
    } = state
    const filterVehicle = async (filter) => {
        try {
            const listVehicles = await getFilterVehicleAvailableToVehicleService({
                valueFilter:filter
            })

            if (listVehicles.length === 0){
                ErrorToastComponent({
                    title: BoardingSheetErrorsConst.localError.resultEmpty || BoardingSheetErrorsConst.default,
                    position: AlertConst.TOP_END_POSITION_TEXT,
                    timer: 1500
                })
            }

            setState((prevState) => ({
                ...prevState,
                optionsVehicleSearch: listVehicles
            }))
        } catch (error) {
            ErrorToastComponent({
                title: BoardingSheetErrorsConst.localError.resultEmpty || BoardingSheetErrorsConst.default,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }
    };

  return (
    <>
        <span className='grid-x text-center modal-associate-vehicle__title'>
            Vas a vincular un vehículo con el conductor
        </span>
        <span className='modal-associate-vehicle__subtitle'>Nombre del conductor</span>
        <Input
            value={fullName}
            className="modal-associate-vehicle__input__content--mod"
            prefix={
                <img
                    className="modal-associate-vehicle__input__search"
                    src={Assets.SharedIcons.icon_search_inactive}
                    alt="icon_search"
                />
            }
            disabled
        />
        <span className='modal-associate-vehicle__subtitle'>Buscar vehículo Disponible</span>
        <AutoComplete
            className="modal-associate-vehicle__input"
            onSearch={(e) => {
                const trimmedValue = e.trim();
                if (trimmedValue) {
                    filterVehicle(trimmedValue);
                } else {
                    setState({ ...state, optionsVehicleSearch: [] });
                }
            }}
            options={optionsVehicleSearch}
            onSelect={(value,options)=> setVehicleSelected(options)}
            onChange={(e) => !e.trim() && setVehicleSelected(null)}
        >
            <Input
                allowClear={{
                    clearIcon: (
                    <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                    />
                    )
                }}
                className="modal-associate-vehicle__input__content"
                placeholder="Escribe el código, placa o Nro. del Vehículo"
                prefix={
                    <img
                    className="modal-associate-vehicle__input__search"
                    src={Assets.SharedIcons.icon_search}
                    alt="icon_search"
                    />
                }
            />
        </AutoComplete>
        <span className='modal-associate-vehicle__comments'>¿Deseas agregar una observación?</span>
        <Input.TextArea 
            rows={3} 
            placeholder="Escribe aquí tu comentario u observación"
            className='modal-associate-vehicle__text-area'
        />
    </>
  )
}

export default ModalAssociateVehicleComponent