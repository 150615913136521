//Assets
import { Assets } from "../../../../../assets";

//Libraries
import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';

//Styles
import { PdfVehiclesStyles } from "./pdf-vehicles.component.styles";

const PdfVehiclesComponent = ({ info }) => {

    return (
        <Document>
            <Page size="A4" style={PdfVehiclesStyles.page}>
                <View style={PdfVehiclesStyles.section}>
                    <View style={PdfVehiclesStyles.content}>
                        <Image
                            src={Assets.SharedIcons.icon_logo_pdf}
                            style={PdfVehiclesStyles.logo}
                        />
                        <View style={PdfVehiclesStyles.content_info}>
                            <Text style={PdfVehiclesStyles.title}>Información del Vehículo</Text>
                            <Text style={[PdfVehiclesStyles.subtitle, { marginTop: '40px' }]}>Placa del vehículo</Text>
                            <Text style={PdfVehiclesStyles.description}>
                                {info?.plate}
                            </Text>
                            <Text style={PdfVehiclesStyles.subtitle}>Código del vehículo</Text>
                            <Text style={PdfVehiclesStyles.description}>
                                {info?.code}
                            </Text>
                            <Text style={PdfVehiclesStyles.subtitle}>Nombre del conductor</Text>
                            <Text style={PdfVehiclesStyles.description}>
                                {info?.driver?.name ? info.driver?.name : 'Conductor no vinculado'}
                            </Text>
                            <Text style={PdfVehiclesStyles.subtitle}>Nro. del vehículo</Text>
                            <Text style={PdfVehiclesStyles.description}>
                                {info?.internalNumber}
                            </Text>
                            <Text style={PdfVehiclesStyles.subtitle}>Estado del vehículo</Text>
                            <Text style={PdfVehiclesStyles.description}>
                                {info?.isMaintenance === true ? "No disponible" : "Disponible" }
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default PdfVehiclesComponent