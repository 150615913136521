//Libraries
import React from 'react';
import { Step, Stepper } from 'react-form-stepper';

//Styles
import './driver-stepper.component.scss'

const DriverStepperComponent = (props) => {

    const {
        //Functions
        onStepper = () => null,
        //Variables
        dataStepper,
        locationStepper,
    } = props;

    return (
        <div className='grid-x small-12 driver-stepper__content'>
            <Stepper
                activeStep={locationStepper}
                connectorStateColors={locationStepper ? true : false}
                className="grid-x driver-stepper__steppers"
            >
                {dataStepper.map((sections, index) => (
                    <Step
                        key={index}
                        label={sections?.label}
                        onClick={() => onStepper(index)}
                        className={`driver-stepper__step ${index <= locationStepper
                            ? "driver-stepper__step--active"
                            : "driver-stepper__step--inactive"
                            } ${index < locationStepper
                                ? "driver-stepper__button-active"
                                : ""
                            }
                `}
                    />
                ))}
            </Stepper>
        </div>
    )
}

export default DriverStepperComponent