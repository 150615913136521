// Assets
import { Assets } from '../../../assets'

//Libraries
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Components
import VehiclesTableComponent from './components/vehicles-table/vehicles-table.component';

// Services
import { getUserService } from '../../../services/authentication.services';

// Styles
import './vehicles.page.scss';

const VehiclesPage = () => {

    const INITIAL_STATE = {
        isHoverVehicles: false,
    };

    const dataUser = getUserService();

    const [state, setState] = useState(INITIAL_STATE);

    const { isHoverVehicles } = state;

    return (
        <>
            <div className='vehicles__breadcrumbs'>
                <span >Vehículo</span>
            </div>
            <div className='grid-y vehicles__content'>
                <span className="vehicles__welcome">
                    {`Bienvenido ${(dataUser?.name || '') + " " + (dataUser?.lastName || '')} al modulo de vehículos.`}
                </span>
                <div className="grid-x vehicles__create-vehicles">
                    <button
                        className={`grid-y align-center-middle vehicles__create-vehicles__card ${isHoverVehicles ? 'vehicles__create-vehicles__card__active' : ''}`}
                        onClick={() => null}
                        onMouseOver={() => setState({ ...state, isHoverVehicles: true })}
                        onMouseOut={() => setState({ ...state, isHoverVehicles: false })}
                    >
                        <img
                            src={isHoverVehicles
                                ? Assets.SharedIcons.icon_vehicles_card_hover
                                : Assets.SharedIcons.icon_vehicles_card
                            }
                            alt="icon_vehicles_card"
                            className={isHoverVehicles
                                ? "vehicles__create-vehicles__card__icon--hover"
                                : "vehicles__create-vehicles__card__icon"
                            }
                        />
                        <span>Agregar un vehículo</span>
                        <Link
                        to="./addVehicle"
                            className={isHoverVehicles
                                ? "vehicles__create-vehicles__card__add--hover"
                                : 'vehicles__create-vehicles__card__add'
                            }
                        >
                            <img
                                src={isHoverVehicles ? Assets.SharedIcons.icon_add_hover : Assets.SharedIcons.icon_add}
                                alt="icon_add"
                            />
                        </Link>
                    </button>
                </div>
                <div className='vehicles__info-content'>
                    <VehiclesTableComponent />
                </div>
            </div>
        </>
    )
}

export default VehiclesPage