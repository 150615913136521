// Actions
import { setDataSalesStateReducer } from '../../../../../../storage/reducers/sales/sales.action';

// Assets
import { Assets } from '../../../../../../assets';

// Libraries
import { Input, Select, Tooltip } from 'antd';
import { useFormik } from 'formik';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';

//Services
import { getAllIndicativeNumberService } from '../../../../../../services/shared.services';

// Styles
import './passenger-seat.component.scss'

// Utils
import { passagesPassengerDataSchema } from '../../../../../../utils/form-validations/sales.validators.utils';


const PassengerSeat = (props) => {
    const {
        // Actions
        setDataSalesStateReducer,
        // Functions
        anErrorExists,
        // Variables
        seat,
        isError,
        assignedSeats,
        innerWidth,
        isDisabled,
        key,
        ...clientData
    } = props;

    const INITIAL_STATE = {
        isDropdown: false,
        optionsIndicativeNumber: [],
        isTooltip: null
    }

    const [state, setState] = useState(INITIAL_STATE)
    const { isDropdown, optionsIndicativeNumber, isTooltip } = state;

    const handleMouseEnter = (index) => {
      setState({...state, isTooltip: index });
    };
    
    const handleMouseLeave = () => {
      setState({...state, isTooltip: null});
    };

    useEffect(() => {
        let touchesValues = { 
            name: seat?.passenger?.name ? true : false ,
            lastName: seat?.passenger?.lastName ? true : false ,
            telephoneCode: seat?.passenger?.telephoneCode ? true : false ,
            telephone: seat?.passenger?.telephone ? true : false ,
        }
        setTouched({...touched,...touchesValues});
        setValues(seat.passenger || values)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seat])

    useEffect(() => {
        getAllIndicativeNumberService().then((res) => {
            const indicativeNumberList = res.map((indicativeNumber) => ({ label: indicativeNumber.number, value: indicativeNumber.id }))
            setState((prevState)=>({
              ...prevState,
              optionsIndicativeNumber: indicativeNumberList
            }))
            if (!seat?.passenger) {
                setFieldValue("telephoneCode",indicativeNumberList[0])
            }
          })
          .catch(() => {
            // TODO: Implement error alert with code error.
          })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const assignedToClient = () => {
        setValues({
            ...clientData
        })
        setDataSalesStateReducer(
            "passageSales",
            'assignedSeats',
            assignedSeats.map(assignedSeat => (assignedSeat.id === seat.id)
                ? {
                    ...seat, passenger: {
                        ...clientData
                    }
                }
                : assignedSeat
            ))
    };

    const onChange = (data, target) => {
        const value = data && data.target ? data.target.value || '' : data;
        const passengerData = { ...values, [target]: value }
        setValues(passengerData)

        setDataSalesStateReducer(
            "passageSales",
            'assignedSeats',
            assignedSeats.map(assignedSeat => (assignedSeat.id === seat.id)
                ? { ...seat, passenger: passengerData }
                : assignedSeat
            ))
    };

    const { values, errors, touched, handleBlur, setValues, setFieldValue, setTouched } = useFormik({
        initialValues: {
            name: '',
            lastName: '',
            telephoneCode: undefined,
            telephone: ''
        },
        validationSchema: passagesPassengerDataSchema,
    });

    useEffect(() => {
        anErrorExists(errors)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, isError])

    return (
        <div className="grid-x medium-12 passenger-seat">
            <div className="grid-y passenger-seat__field--mod">
                <label className="passenger-seat__label">{t("sales.passages.passengerData.seat")}</label>
                <div className='grid-x'>
                    <Input
                        className="small-10 medium-12 passenger-seat__input__disabled"
                        disabled
                        value={seat?.name}
                    />
                    <div className="small-2 passenger-seat__dropdown">
                        <button
                            onClick={() => {
                                setState({
                                    ...state,
                                    isDropdown: !isDropdown
                                })
                            }}
                            className="passenger-seat__dropdown__icon"
                        >
                            <img
                                src={Assets.SharedIcons.icon_up_arrow}
                                alt="icon_up_arrow"
                                className="passenger-seat__dropdown__img"
                                style={{ transform: isDropdown ? 'rotate(180deg)' : 'rotate(0deg)' }}
                            />
                        </button>
                    </div>

                </div>
            </div>
            <div className={`grid-y passenger-seat__field ${!isDropdown ? "passenger-seat__dropdown__hidden" : ""}`}>
                <label className="passenger-seat__label">{t("sales.passages.passengerData.passengerName")}</label>
                <Input
                    disabled={isDisabled}
                    value={values.name}
                    onChange={(value) => onChange(value, 'name')}
                    status={errors.name && touched.name ? 'error' : ''}
                    onBlur={(value) => handleBlur('name')(value)}
                    className="passenger-seat__input"
                    allowClear={{
                        clearIcon:
                            <img
                                src={Assets.SharedIcons.icon_square_close}
                                alt="icon_square_close"
                            />
                    }}
                    placeholder={t("sales.passages.passengerData.passengerName")}
                />
                <span className="grid-x passenger-seat__errors">{touched.name ? errors.name : null}</span>
            </div>
            <div className={`grid-y passenger-seat__field ${!isDropdown ? "passenger-seat__dropdown__hidden" : ""}`}>
                <label className="passenger-seat__label">
                    {t("sales.passages.passengerData.passengerLastName")}
                </label>
                <Input
                    disabled={isDisabled}
                    value={values.lastName}
                    onChange={(value) => onChange(value, 'lastName')}
                    status={errors.lastName && touched.lastName ? 'error' : ''}
                    onBlur={(value) => handleBlur('lastName')(value)}
                    className="passenger-seat__input"
                    allowClear={{
                        clearIcon:
                            <img
                                src={Assets.SharedIcons.icon_square_close}
                                alt="icon_square_close"
                            />
                    }}
                    placeholder={t("sales.passages.passengerData.passengerLastName")}
                />
                <span className="grid-x passenger-seat__errors">{touched.lastName ? errors.lastName : null}</span>
            </div>
            <div className={`grid-y passenger-seat__field--phone ${!isDropdown ? "passenger-seat__dropdown__hidden" : ""}`}>
                <label className="passenger-seat__label">
                    {t("sales.passages.passengerData.contactPhone")}
                </label>
                <div className='grid-x justify-content-between'>
                    <div>
                        <Select
                            disabled={isDisabled}
                            value={values.telephoneCode}
                            onChange={(i, value) => onChange(value, 'telephoneCode')}
                            status={errors.telephoneCode && touched.telephoneCode ? 'error' : ''}
                            onBlur={(value) => handleBlur('telephoneCode')(value)}
                            options={optionsIndicativeNumber}
                        />
                        <span className="grid-x passenger-seat__errors">{touched.telephoneCode ? errors.telephoneCode : null}</span>
                    </div>
                    <div className='grid-y passenger-seat__input__phone'>
                        <Input
                            disabled={isDisabled}
                            value={values.telephone}
                            onChange={(value) => onChange(value, 'telephone')}
                            status={errors.telephone && touched.telephone ? 'error' : ''}
                            onBlur={(value) => handleBlur('telephone')(value)}
                            allowClear={{
                                clearIcon:
                                    <img
                                        src={Assets.SharedIcons.icon_square_close}
                                        alt="icon_square_close"
                                    />
                            }}
                            className="passenger-seat__input passenger-seat__input__phone"
                            placeholder={t("sales.passages.passengerData.contactPhone")}
                        />
                        <span className="grid-x passenger-seat__errors">{touched.telephone ? errors.telephone : null}</span>
                    </div>
                </div>
            </div>
            <div className={`passenger-seat__copy ${!isDropdown ? "passenger-seat__dropdown__hidden" : ""}`}>
                <Tooltip
                    open={isTooltip === key}
                    placement={innerWidth < 639 ? "right" : "topLeft"}
                    title={() => (
                        <div className='passenger-seat__tooltip'>
                            <div className='passenger-seat__tooltip__header grid-x'>
                                {innerWidth < 639
                                    ? <img
                                        className='passenger-seat__tooltip__icon'
                                        src={Assets.SalesIcons.icon_copy_disabled}
                                        alt='icon_copy_disabled'
                                    />
                                    : null}
                                <span className='passenger-seat__tooltip__title'>
                                    {innerWidth < 639
                                        ? t("sales.passages.passengerData.tooltip.titleMobile")
                                        : t("sales.passages.passengerData.tooltip.title")
                                    }
                                </span>
                            </div>
                            <div className='passenger-seat__tooltip__body'>
                                <span className='passenger-seat__tooltip__text'>
                                    {t("sales.passages.passengerData.tooltip.description")}
                                </span>
                                <button
                                    disabled={isDisabled}
                                    className='passenger-seat__tooltip__button'
                                    onClick={() => assignedToClient()}
                                >
                                    <span>{t("sales.passages.passengerData.tooltip.button")}</span>
                                </button>
                            </div>
                        </div>)}
                    color='white'
                    trigger={innerWidth < 639 ? 'click' : 'hover'}
                    zIndex={1}
                >
                    <button 
                        disabled={isDisabled} 
                        onClick={() => innerWidth > 639 && assignedToClient()}
                        onMouseEnter={() => handleMouseEnter(key)}
                        onMouseLeave={() => handleMouseLeave()}
                    >
                        <img src={isDisabled ? Assets.SalesIcons.icon_copy_disabled : Assets.SalesIcons.icon_copy} alt="icon_copy" />
                    </button>
                </Tooltip>
            </div>
        </div>
    )
}
const mapStateToProps = ({ SalesReducer, AppReducer }) => {
    const {
        passageSales: {
            name,
            lastName,
            telephone,
            telephoneCode,
            assignedSeats,
        },
        isError,
        isDisabled
    } = SalesReducer;
    const {
        windowSize: { innerWidth },
    } = AppReducer;
    return {
        innerWidth,
        name,
        lastName,
        telephone,
        telephoneCode,
        assignedSeats,
        isError,
        isDisabled
    };
};

const mapStateToPropsActions = {
    setDataSalesStateReducer
};


export default connect(mapStateToProps, mapStateToPropsActions)(PassengerSeat);