//Actions
import { resetDataSaleReducer, setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from '../../../storage/reducers/sales/sales.action';

//Assets
import { Assets } from '../../../assets'

//Const
import AlertConst from '../../../core/constants/alerts.const';
import { SalesErrorsConst } from '../../../core/constants/errors/alerts-app/sales.errors.const';

//Components
import NavigationBodyComponent from '../components/navigation-body/navigation-body.component';
import StepperComponent from '../components/stepper/stepper.component';
import BillingComponent from './components/billing/billing.component';
import CustomerInformationComponent from './components/customer-information/customer-information.component';
import OrderingDataComponent from './components/ordering-data/ordering-data.component';
import RecipientsInformationComponent from './components/recipients-information/recipients-information.component';
import ModalSaveComponent from '../components/modal-save/modal-save.component';
import ModalInvoiceComponent from '../components/modal-invoice/modal-invoice.component';
import DrawerComponent from "../components/drawer/drawer.component"
import OrderGuideComponent from './components/order-guide/order-guide.component';
import PreviewPdfComponent from '../components/preview-pdf/preview-pdf.component';
import PdfShippingsComponent from './components/pdf-shippings/pdf-shippings.component';
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';

//Components - Shared
import ModalMainComponent from '../../../shared/components/modal/modal-main/modal-main.component';
import SuccessToastComponent from '../../../shared/components/toast/success-toast/success-toast.component';

// Connect WebSocket
import { PrintConnectionSocket } from '../../../socket';

//Libraries
import React, { useRef, useState } from 'react'
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
// import printJS from 'print-js';

//Services
import { createClientService, updateClientService } from '../../../services/shared.services';
import { createInvoiceShippingService, getAllRouteService, getInvoiceShippingService } from '../../../services/sales.services';

const ShippingsPage = (props) => {

  const { 
    // Actions
    resetDataSaleReducer,
    setStateSaleReducer,
    setDataSalesStateReducer,
    setDataSaleReducer,
    // Variables
    isShowDrawer,
    innerWidth,
    shippingsSales
  } = props;

  const [t] = useTranslation("translation")

  const steps = [
    { label: "Datos de la encomienda" },
    { label: "Datos del Cliente" },
    { label: "Datos del Destinatario"},
    { label: "Facturación"}
  ];

  const INITIAL_STATE = {
    locationStepperState: 0,
    isModalInvoice: false,
    isModalSave: false,
    isOpenModal: false,
    isPrint: false,
    invoiceData: null,
    typePrint: 0,
    isLoadingModalSpin: false
  };

  const [state, setState] = useState(INITIAL_STATE);
  const { 
    locationStepperState, isModalInvoice, isModalSave, 
    isOpenModal, isPrint, invoiceData, typePrint,
    isLoadingModalSpin

  } = state;

  let history = useNavigate();
  const nextButtonRef = useRef();
  const invoiceButtonRef = useRef();

  const directSale = () => {
    resetDataSaleReducer();
    history("/sales", { replace: true });
  };

  const handleInvoice = () =>{
    if (isModalInvoice) {
      setState((prevState)=>({
        ...prevState,
        isPrint: true
      }))
      setStateSaleReducer('isShowDrawer', true)
    } else {
      setState({
        ...state,
        isModalSave: false,
        isOpenModal:true
      })
    }
    
  }

  const handleSave = () =>{
    if (isModalInvoice) {
      setState((prevState)=>({
        ...prevState,
        isPrint: false
      }))
      setStateSaleReducer('isShowDrawer', true)
    } else {
      setState({
        ...state,
        isModalSave: true,
        isOpenModal:true
      })
    }
  }

  const handleAcceptInvoice = async () => {
    try {
      if (isModalSave) {
        SuccessToastComponent({
          html: 
          `<span>
            ${t("sales.salesFooter.congratulations")}
          </span>`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 5000
        });
  
        setState((prevState) => ({
          ...prevState,
          isOpenModal: false,
          isLoadingModalSpin: false
        }));
      } else {
        await createInvoice(shippingsSales);
        await updateClient();

        setState((prevState) => ({
          ...prevState,
          isLoadingModalSpin: false
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoadingModalSpin: false
      }));
    }
  };

  const createInvoice = async({
    paymentMethod,weightAmount,serviceType,
    declaredValue,insuranceCost,costOrder, quantity,
    cargoManifest, totalOrder,contentPackage,idCustomer,
    idRecipients,typePackage,domicile,
    isElectronic,vehicleSelected,domicileCost,
    domicileDescription,municipalityResidenceRecipients
  }) =>{
    try {
      let dataInvoice = {}
      paymentMethod && (dataInvoice.paymentMethod = paymentMethod)
      weightAmount && (dataInvoice.weight = weightAmount)
      dataInvoice.declaredValue = declaredValue
      dataInvoice.insuranceCost = insuranceCost
      dataInvoice.costShipping = costOrder
      dataInvoice.porteService = quantity
      dataInvoice.manifestOfFreight = cargoManifest
      totalOrder && (dataInvoice.price = totalOrder)
      dataInvoice.content = contentPackage
      idCustomer && (dataInvoice.idClientSends = idCustomer)
      idRecipients && (dataInvoice.idClientReceives = idRecipients)
      typePackage && (dataInvoice.idShippingType = typePackage.value)
      serviceType && (dataInvoice.idCostOfProductType = serviceType.value)
      dataInvoice.isHomeDelivery = domicile === false ? 0 : 1
      typePackage?.type && (dataInvoice.otherShippingType = typePackage?.label)
      dataInvoice.isElectronic = isElectronic
      dataInvoice.costDelivery = domicileCost
      domicileDescription && (dataInvoice.descriptionDelivery = domicileDescription)
      municipalityResidenceRecipients && (dataInvoice.idSite = municipalityResidenceRecipients?.value)

      let vehicleAssignedData = {
        idVehicle: null
      }
      if (vehicleSelected) {

        if (!vehicleSelected?.idVehicle) {
          vehicleAssignedData.idVehicle = vehicleSelected?.idVehicle
        }

        if (vehicleSelected?.idVehicle && vehicleSelected?.idTravel) {
          vehicleAssignedData.idVehicle = vehicleSelected?.idVehicle
          vehicleAssignedData.idTravel = vehicleSelected?.idTravel
        }

        if (vehicleSelected?.idVehicle && !vehicleSelected?.idTravel) {
          vehicleAssignedData.idVehicle = vehicleSelected?.idVehicle
          vehicleAssignedData.time = dayjs().format('HH:mm:ss')
          vehicleAssignedData.date = dayjs().format('YYYY-MM-DD')
          const route = await getAllRouteService();
          vehicleAssignedData.idRoute = route[0]?.id
        }
      }

      dataInvoice.vehicleAssigned = vehicleAssignedData

      const idInvoice = await createInvoiceShippingService(dataInvoice);
      await setStateSaleReducer("idInvoice", idInvoice)
      SuccessToastComponent({
        html: 
        `<span>${t("sales.passages.successfully")}
          <a class="turn__text-alert" href="/sales">${t("sales.passages.salesHistory")}</a>
        </span>`,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 5000
      })
      setStateSaleReducer('isDisabled', true)
      setState({
        ...state,
        locationStepperState: locationStepperState + 1,
        isModalInvoice: true,
        isOpenModal:false
      })
      getInvoice(idInvoice);
    } catch (err){
      ErrorToastComponent({
        title: SalesErrorsConst.shippings[err.code] || SalesErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const getInvoice = async (idInvoice) => {
    try {
      const { connectSocket, printTicketSocket } = PrintConnectionSocket();
      connectSocket();
      const dataInvoice = await getInvoiceShippingService(idInvoice);
      
      setState((prevState) => ({
        ...prevState,
        invoiceData: dataInvoice,
        isPrint: true
      }));

      setStateSaleReducer('isShowDrawer', true);

      printTicketSocket({ typeTicket: 'printAssignment', data: dataInvoice });
    } catch {
      // TODO: Implement error alert with code error.
    }
  };
  const updateClient = async () => {
    const compareAndUpdate = async (keysToCompare, typeClient) => {
      const compareData = keysToCompare.every(key => {
        const shippingsSalesValue = shippingsSales[`${key}${typeClient}`];
        const originalClientDataValue = shippingsSales[`originalClientData${typeClient}`][`${key}${typeClient}`];
        return deepEqual(shippingsSalesValue, originalClientDataValue);
      });
  
      if (compareData === false) {
        try {
          const dataClient = Object.keys(shippingsSales).reduce((object, key) => {
            const newKey = key.replace(`${typeClient}`, '');
            object[newKey] = shippingsSales[key];
            return object;
          }, {});
  
          await updateClientService(dataClient);
          setDataSalesStateReducer('shippingsSales', `originalClientData${typeClient}`, shippingsSales);
        } catch {
          // TODO: Implement error alert with code error.
        }
      }
    };
  
    const customerKeys = [
      "documentType",
      "documentNumber",
      "name",
      "lastName"
    ];
    await compareAndUpdate(customerKeys, "Customer");
  
    const recipientKeys = [
      "name",
      "lastName",
      "telephone",
      "telephoneCode",
      "contactAddress",
      "municipalityResidence"
    ];
    await compareAndUpdate(recipientKeys, "Recipients");
  };
  
  const handleNext = () =>{
    setStateSaleReducer('isShowDrawer', false)
    if (steps.length > locationStepperState) {
      (locationStepperState === 1 || locationStepperState === 2 )&& validateClient()
      setState({
        ...state,
        locationStepperState: locationStepperState + 1,
      });
    }
    if (steps.length === locationStepperState) {
      setState({
        ...state,
        isOpenModal:false
      })
      resetDataSaleReducer()
      history("/sales", { replace: true })
    }
  }

  const validateClient = async () =>{
    let typeClient= ""
    locationStepperState === 1 && (typeClient = "Customer")
    locationStepperState === 2 && (typeClient = "Recipients")
    if ((locationStepperState === 1 && shippingsSales?.idCustomer) || (locationStepperState === 2 && shippingsSales?.idRecipients)) {
      const keysToCompare = [
        "id",
        "documentType",
        "documentNumber",
        "name",
        "lastName",
        "telephone",
        "telephoneCode",
        "whatsapp",
        "whatsappCode",
        "departmentResidence",
        "municipalityResidence",
        "contactAddress",
        "contactEmail" 
      ];

      const compareData = keysToCompare.every(key => {
        const shippingsSalesValue = shippingsSales[`${key}${typeClient}`];
        const originalClientDataValue = shippingsSales[`originalClientData${typeClient}`][`${key}${typeClient}`];
        return deepEqual(shippingsSalesValue, originalClientDataValue);
      });

      if (compareData === false) {
        try {
          const dataClient = Object.keys(shippingsSales).reduce((object, key) => {
            const newKey = key.replace(`${typeClient}`, '');
            object[newKey] = shippingsSales[key];
            return object;
          }, {});
          await updateClientService(dataClient);
          setDataSalesStateReducer('shippingsSales', `originalClientData${typeClient}` , shippingsSales)
        } catch {
          // TODO: Implement error alert with code error.
        }
      }
    }else{
      try {
        const newClient = Object.keys(shippingsSales).reduce((object, key) => {
          const newKey = key.replace(`${typeClient}`, '');
          object[newKey] = shippingsSales[key];
          return object;
        }, {});
        const idClient = await createClientService(newClient);
        let dataClient = shippingsSales
        dataClient[`id${typeClient}`] = idClient
        dataClient[`originalClientData${typeClient}`] = {...dataClient}
        setDataSaleReducer("shippingsSales",dataClient)
      } catch {
        // TODO: Implement error alert with code error.
      }
      
    }
  }

  const deepEqual = (value1, value2) => {
    if (typeof value1 === "object" && typeof value2 === "object") {
      const keys1 = Object.keys(value1);
      const keys2 = Object.keys(value2);
  
      if (keys1.length !== keys2.length) {
        return false;
      }
  
      for (const key of keys1) {
        if (!deepEqual(value1[key], value2[key])) {
          return false;
        }
      }
  
      return true;
    }
  
    return value1 === value2;
  }

  const handleCloseModal = () =>{
    setState({
      ...state,
      isOpenModal: false
    })
  }

  const handleBack = () =>{
    setStateSaleReducer("isShowDrawer", false);
    if (locationStepperState > 0) {
      setState({
        ...state,
        locationStepperState: locationStepperState - 1,
      });
    }
  }

  const closeDrawer = () => {
    setState({
      ...state,
      isPrint: false
    })
    setStateSaleReducer("isShowDrawer", false);
  }

  const handlePrint = () =>{
    const pdfSize = innerWidth < 639 ? undefined : typePrint
    pdfDocumentPrint(pdfSize)
  }

  // const printPdf = async(url) => {
  //   if (innerWidth > 639) {
  //     printJS({
  //       printable: url,
  //       type: "pdf",
  //       showModal: true,
  //     })
  //   } else {
  //     window.open(url)
  //   }
  // };

  const pdfDocumentPrint = (size) =>{
    const { connectSocket, printTicketSocket } = PrintConnectionSocket();
    connectSocket();
    printTicketSocket({typeTicket: 'printAssignment', data: invoiceData});

    // const pdfBlob = size ? pdf(<PdfShippingsComponent dataInvoice={invoiceData} size />) : pdf(<PdfShippingsComponent dataInvoice={invoiceData} />);
    // pdfBlob.toBlob().then((blob) => {
    //   printPdf(URL.createObjectURL(blob))
    // });
  }

  return (
    <>
    <div className="turn__content">
      <span className="turn__subtitle">{t("sales.passages.sales")}</span>
      <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
      <span className="turn__subtitle">
        {t("sales.shippings.title")}
      </span>
    </div>
    <div className={`grid-x ${isShowDrawer ? "turn__container--mod" : "turn__container"}`}>
      <div className={`${isShowDrawer?"turn__container__steps--mod":"turn__container__steps"} grid-x`}>
      <StepperComponent
        title={t("sales.shippings.orderDispatching")}
        name={t("sales.shippings.title")}
        icon={Assets.SalesIcons.icon_orders}
        dataStepper={steps}
        locationStepper={locationStepperState}
        onClose={()=>directSale()}
        onStepper={(e)=>{
          setState({
            ...state,
            locationStepperState: e,
          })
        }}
      />
      <NavigationBodyComponent
        nextButtonRef={nextButtonRef}
        invoiceButtonRef={invoiceButtonRef}
        locationStepper={locationStepperState}
        onBack={()=>handleBack()}
        onInvoice={()=>handleInvoice()}
        onSave={()=>handleSave()}
        onNext={()=>handleNext()}
        textSave={isModalInvoice? t("sales.shippings.seeGuide") : t("sales.passages.save")}
        textNext={locationStepperState >= 3 ? t("sales.passages.end") : t("sales.passages.next")}
        textInvoice={isModalInvoice ? t("sales.passages.seeInvoice") : t("sales.passages.invoice")}
        numStepper={steps.length}
      >
        {locationStepperState === 0 && <OrderingDataComponent nextButtonRef={nextButtonRef}/>}
        {locationStepperState === 1 && <CustomerInformationComponent nextButtonRef={nextButtonRef}/>}
        {locationStepperState === 2 && <RecipientsInformationComponent nextButtonRef={nextButtonRef}/>}
        {locationStepperState >= 3 && <BillingComponent invoiceButtonRef={invoiceButtonRef}/>}
      </NavigationBodyComponent>
    </div>
      <div className={isShowDrawer ? "turn__drawer-modal--active" : "turn__drawer-modal"}>
        <DrawerComponent
          pdfDocument={ isPrint && <PdfShippingsComponent dataInvoice={invoiceData}/>}
          accept = {()=>handlePrint()}
          onClose = {()=> closeDrawer()}
          title={t("sales.turnPage.salesInvoice")}
          buttonPrimaryText={t("sales.shippings.printGuide")}
          // TODO: Send by WhatsApp button
          // buttonSecondaryText={t("sales.shippings.send")}
          onModalPrint={()=>handlePrint()}
          typePrint={typePrint}
          // onTypePrint={(type)=> setState({ ...state, typePrint: type })}
        >
        {isPrint?
          <PreviewPdfComponent>
            <PdfShippingsComponent dataInvoice={invoiceData}/>
          </PreviewPdfComponent>
          :
          <OrderGuideComponent dataInvoice={invoiceData}/>
        }
        </DrawerComponent>
      </div>
    </div>
      <>
        <ModalMainComponent
          width={300}
          open={isOpenModal}
          onClose={()=>handleCloseModal()}
          footer={[
            <button key="accept" className="turn__modal__button"
              onClick={()=> {
                setState((prevState) => ({ ...prevState, isLoadingModalSpin: true }));
                handleAcceptInvoice();
              }}
              disabled={isLoadingModalSpin}
            >
              <span className="turn__modal__button__label">
                
              {isLoadingModalSpin ? <Spin size='default' className='turn__modal__spin' /> : (isModalSave ? t("sales.passages.accept") : t("sales.passages.invoiceNow"))}
              </span>
            </button>,
            <div className={`turn__modal__close ${isModalSave ? "turn__hidden" : ""}`} key="cancel">
              <button
                className="turn__modal__close__label"
                onClick={()=>
                  handleCloseModal()
                }
              >
                {t("sales.passages.cancel")}
              </button>
            </div>
          ]}
        >
          {isModalSave ? 
            <ModalSaveComponent/>
           :
           <ModalInvoiceComponent/>
          }
        </ModalMainComponent>
      </>
  </>
  )
}

const mapStateToProps = ({ SalesReducer, AppReducer }) => {
    const { isShowDrawer, shippingsSales } = SalesReducer;
    const { windowSize: { innerWidth } } = AppReducer;
    return {
      isShowDrawer,
      shippingsSales,
      innerWidth
    };
};
  
  
  const mapStateToPropsActions = {
    resetDataSaleReducer,
    setStateSaleReducer,
    setDataSalesStateReducer,
    setDataSaleReducer
  };
  
  export default connect(mapStateToProps, mapStateToPropsActions)(ShippingsPage);