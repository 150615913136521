
//Assets
import { Assets } from "../../../../../assets";

//Components
import PdfDriversComponent from "../../../drivers/components/pdf-drivers/pdf-drivers.component";

//Actions
import { setDataDriverReducer } from "../../../../../storage/reducers/drivers/drivers.action";

//Libraries
import React, { useEffect, useState } from 'react';
import { Switch } from "antd";
import { connect } from "react-redux";
import { BlobProvider } from "@react-pdf/renderer";
import { useLocation } from "react-router-dom";

//Styles
import './driver-information.component.scss';

//Utils
import { excelFileGeneratorUtils } from "../../../../../utils/excel-file-generator.utils";

const DriverInformationComponent = (props) => {

  let params = useLocation();

  const {
    //Variables
    driverFrom,
    // Actions
    setDataDriverReducer
  } = props;

  const INITIAL_STATE = {
    vehicleInformation: {
      name: '',
      lastName: '',
      state: 1,
      telephone: '',
      documentNumber: '',
      licenseNumber: '',
    }
  };

  const [state, setState] = useState(INITIAL_STATE);

  const { vehicleInformation} = state;

  useEffect(() => {
    setState({
      ...state,
      vehicleInformation: {
        name: driverFrom?.name,
        lastName: driverFrom?.lastName,
        state: driverFrom?.state,
        telephone: driverFrom?.telephone,
        documentNumber: driverFrom?.documentNumber,
        licenseNumber: driverFrom?.driverLicenseNumber,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverFrom]);

  const handleSwitchChange = (checked) => {
    setDataDriverReducer("driverFrom", "state", checked ? 1 : 0 );
  }

  const generateExcel = (itemFile) => {
    let renderItemList = [];
    const infoHeader = [
      'Nombre del conductor',
      'Número de contacto',
      'Licencia de conducción',
      'Nro. del vehículo',
      'Estado'
    ];
    const renderItem = {
      driverName: `${itemFile.name} ${itemFile.lastName}`,
      contactNumber: itemFile.telephone,
      driversLicense: itemFile.licenseNumber,
      vehicleNumber: driverFrom?.vehicle?.plate ? driverFrom.vehicle.plate : '',
      state: driverFrom?.state ? 'Disponible' : 'No disponible'
    };
    renderItemList.push(renderItem);

    const columnWidths = [20, 18, 20, 18, 12];
    excelFileGeneratorUtils(infoHeader, renderItemList, itemFile.licenseNumber, columnWidths);
  };

  const printPdf = async (url) => {
    window.open(url)
  };

  return (
    <div className="grid-y driver-information__container">
      <div>
        <img
          className="driver-information__icon__driver"
          src={Assets.SharedIcons.icon_drivers_card}
          alt="icon_drivers_card"
        />
        <span className="driver-information__title-text">Datos del conductor</span>
      </div>
      <div className='grid-y align-center-middle driver-information__content'>
        <img
          className="driver-information__photo-profile"
          src={Assets.SharedIcons.icon_user_profile}
          alt="icon_user_profile"
        />
        <div className='grid-y driver-information__box'>
          <span className="driver-information__title-text">
            {(vehicleInformation.name && vehicleInformation.lastName)
              ? `${vehicleInformation.name} ${vehicleInformation.lastName}`
              : 'Nombre del conductor'
            }
          </span>

          {/* TODO: 283580 Implement photo attachment button */}

          {/* <button
            disabled={isDisabled}
            className='driver-information__button-change-photo'
          >
            <span className="driver-information__button-change-photo__text">Subir Fotos</span>
            <img
              alt="icon_up_arrow_white"
              className="driver-information__icon"
              src={isDisabled ? Assets.SharedIcons.icon_up_arrow_gray : Assets.SharedIcons.icon_up_arrow_white}
            />
          </button> */}
        </div>
        <div className='grid-y align-center-middle driver-information__box-info--start'>
          <span className="driver-information__title-text">Estado</span>
          <Switch
            checkedChildren="Activo"
            unCheckedChildren="Inactivo"
            checked={vehicleInformation.state}
            onChange={handleSwitchChange}
            className='create-user__content-left__switch'
            disabled={params?.state?.typeAction === 'VIEW_INFO_DRIVER'}
          />
        </div>
        <div className='grid-y align-center-middle driver-information__box-info'>
          <span className="driver-information__title-text">Número de contacto</span>
          <span className='driver-information__text-info'>
            {vehicleInformation.telephone}
          </span>
        </div>
        <div className='grid-y align-center-middle driver-information__box-info'>
          <span className="driver-information__title-text">Número de documento</span>
          <span className='driver-information__text-info'>
            {vehicleInformation.documentNumber}
          </span>
        </div>
        <div className='grid-y align-center-middle driver-information__box-info'>
          <span className="driver-information__title-text">Licencia de conducción</span>
          <span className='driver-information__text-info'>
            {vehicleInformation.licenseNumber}
          </span>
        </div>
        <div className='grid-x align-center-middle driver-information__box-info'>
          <BlobProvider
            document={
              <PdfDriversComponent
                info={{
                  fullName: `${vehicleInformation?.name} ${vehicleInformation?.lastName}`,
                  phone: vehicleInformation?.telephone,
                  driversLicense: vehicleInformation?.licenseNumber,
                  vehicleNumber: driverFrom?.vehicle,
                  state: vehicleInformation?.state,
                }}
              />
            }
          >
            {({ url, loading, error }) => {
              return (
                <button
                  disabled={(loading || error) || !(params?.state?.typeAction)}
                  onClick={() => printPdf(url)}
                >
                  <img
                    className="driver-information__icons-options"
                    src={!(params?.state?.typeAction) ? Assets.SharedIcons.icon_print_ticket_inactive : Assets.SharedIcons.icon_print_ticket}
                    alt="icon_print_ticket_inactive"
                  />
                </button>
              );
            }}
          </BlobProvider>
          <button
            disabled={!(params?.state?.typeAction)}
            onClick={() => generateExcel(vehicleInformation)}
          >
            <img
              className="driver-information__icons-options"
              src={!(params?.state?.typeAction) ? Assets.SharedIcons.icon_download_inactive : Assets.SharedIcons.icon_download}
              alt="icon_download_inactive"
            />
          </button>
        </div>
        <div className='driver-information__box-info--finish'>
          {/* TODO: This action has no use case for the first phase. */}
          {/* <button className="grid-x driver-information__button__permit-application">
            <span>Solicitud de permisos</span>
            <img
              className="driver-information__icon__permit-application"
              src={Assets.SharedIcons.icon_format_white}
              alt="icon_user_profile"
            />
          </button> */}
          {/* {params?.state?.typeAction === 'VIEW_INFO_DRIVER' &&
            (<button
              className="driver-information__edit-profile"
              onClick={() => setStateDriverReducer('isDisabled', false)}
            >
              <span className="driver-information__edit-profile__text">Editar Perfil</span>
              <img
                alt="icon_pencil"
                src={Assets.SharedIcons.icon_pencil}
                className="driver-information__icon__permit-application"
              />
            </button>)
          }     */}
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = ({ DriversReducer }) => {
  const { driverFrom, isDisabled } = DriversReducer;
  return {
    driverFrom,
    isDisabled
  };
};

const mapStateToPropsActions = {
  setDataDriverReducer
};

export default connect(mapStateToProps, mapStateToPropsActions )(DriverInformationComponent);