//Assets
import { Assets } from '../../../../assets'

//Libraries
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'

//Styles
import { PdfCashStyles } from './pdf-cash.component.styles'

const PdfCashComponent = (props) => {
  const { data } = props

  const sumValues = () => {
    const sumTotal = data?.moneyTransferTotal + data?.shippingTotal + data?.noManifestTravelAssociatedTotal + data?.manifestTravelAssociatedTotal + data?.ticketsTotal + data?.shippingCreditDeliveredTotal
    const restTotal = sumTotal - data?.ticketsDiscountTotal - data?.shippingDiscountTotal
    return restTotal?.toLocaleString()
  }

  return (
    <Document style={PdfCashStyles.content}>
      <Page size="A4" style={PdfCashStyles.page}>
        <View style={PdfCashStyles.section}>
          <View style={PdfCashStyles.containerLogo}>
            <Image
                src={Assets.SharedIcons.icon_logo_pdf}
                style={PdfCashStyles.logo}
              />
          </View>
          <View>
            <View>
              <Text style={PdfCashStyles.subTitle}>
                Fecha expedición: 
                <Text>
                  {data.date}
                </Text>
              </Text>
            </View>
            <View>
              <Text style={PdfCashStyles.subTitle}>
                Hora:   
                <Text>
                  {data.time}
                </Text>
              </Text>
            </View>
            <View>
              <Text style={PdfCashStyles.subTitle}>
                Vendedor:   
                <Text>
                 {data.userSelected}
                </Text>
              </Text>
            </View>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Valor en caja - Giros
            </Text> 
            <Text style={PdfCashStyles.data}>
              ${data?.moneyTransferTotal?.toLocaleString()}
            </Text>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Descuentos
            </Text>
            <Text style={PdfCashStyles.line}/>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Valor en caja - Remesas
            </Text> 
            <Text style={PdfCashStyles.data}>
              ${data?.shippingTotal?.toLocaleString()}
            </Text>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Total - Producido
            </Text>
            <Text style={PdfCashStyles.line}/>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Remesas no planilladas
            </Text> 
            <Text style={PdfCashStyles.data}>
              ${data?.noManifestTravelAssociatedTotal?.toLocaleString()}
            </Text>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Menos pagos autorizados
            </Text>
            <Text style={PdfCashStyles.line}/>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Valor en caja - Planillas
            </Text> 
            <Text style={PdfCashStyles.data}>
              ${data?.manifestTravelAssociatedTotal?.toLocaleString()}
            </Text>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Total de consignaciones
            </Text>
            <Text style={PdfCashStyles.line}/>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Valor en caja - Tiquetes
            </Text> 
            <Text style={PdfCashStyles.data}>
              ${data?.ticketsTotal?.toLocaleString()}
            </Text>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Total base más caja menor
            </Text>
            <Text style={PdfCashStyles.line}/>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Descuento - Tiquetes
            </Text> 
            <Text style={PdfCashStyles.data}>
              -${data?.ticketsDiscountTotal?.toLocaleString()}
            </Text>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Giros por pagar
            </Text>
            <Text style={PdfCashStyles.line}/>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Descuento - Remesas crédito
            </Text> 
            <Text style={PdfCashStyles.data}>
              -${data?.shippingDiscountTotal?.toLocaleString()}
            </Text>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Giros enviados
            </Text>
            <Text style={PdfCashStyles.data}>
            ${data?.moneyTransferSends?.toLocaleString()}
            </Text>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Encomiendas entregadas contra entrega
            </Text> 
            <Text style={PdfCashStyles.data}>
              ${data?.shippingCreditDeliveredTotal?.toLocaleString() || 0}
            </Text>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Giros pagados
            </Text>
            <Text style={PdfCashStyles.data}>
            ${data?.moneyTransferDelivered?.toLocaleString()}
            </Text>
          </View>
          <View style={PdfCashStyles.total}>
            <View style={PdfCashStyles.containerSeparatorTotal}>
              <Text style={PdfCashStyles.label}>
                TOTAL EN CAJA
              </Text> 
              <Text style={PdfCashStyles.label}>
                ${sumValues()}
              </Text>        
            </View>
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.textMod}>
              Menos debe Tibú
            </Text>
            <Text style={PdfCashStyles.line}/>
          </View>
          <View style={PdfCashStyles.containerSeparatorLeft}>
            <Text style={PdfCashStyles.label}>
              Descuentos - domicilios
            </Text> 
            <Text style={PdfCashStyles.line}/>        
          </View>
          <View style={PdfCashStyles.containerSeparatorRight}>
            <Text style={PdfCashStyles.text}>
              Consignación pendiente
            </Text>
            <Text style={PdfCashStyles.line}/>
          </View>
          <View style={PdfCashStyles.totalMod}>
            <View style={PdfCashStyles.containerSeparatorRight}>
              <Text style={PdfCashStyles.text}>
                Más debo Tibú
              </Text>
              <Text style={PdfCashStyles.line}/>
            </View>
          </View>
          <View style={PdfCashStyles.containerLeft}>
            <Text style={PdfCashStyles.label}>
              Entrego
            </Text> 
            <Text style={PdfCashStyles.containerValue}/>        
          </View>
          <View style={PdfCashStyles.containerRight}>
            <Text style={PdfCashStyles.text}>
              Recibo
            </Text>
            <Text style={PdfCashStyles.containerValue}/>
          </View>
          
        </View>
      </Page>
    </Document>
  )
}

export default PdfCashComponent