// Constants - Endpoints
import SalesEndPoints from "./constants/sales.end-points"

// Constants - Core
import LocalStorageConst from "../core/constants/local-storage.const";

// Infrastructure
import Http from "./infrastructure/http.infrastructure"
import LocalStorage from "./infrastructure/local-storage.infrastructure";

// Models - DTO
import RouteDTO from "../models/route/dto/route.dto";
import SeatDTO from "../models/seat/dto/seat.dto";
import VehicleDTO from "../models/vehicle/dto/vehicle.dto";
import UserDTO from "../models/user/dto/user.dto";
import { mapperToRouteModel } from "../models/route/dto/route.dto.dao";
import { mapperToSeatModel } from "../models/seat/dto/seat.dto.dao";
import { mapperToVehicleModel } from "../models/vehicle/dto/vehicle.dto.dao";
import { mapperToUserModel } from "../models/user/dto/user.dto.dao"

//Libraries
import dayjs from "dayjs";

//Services
import { getTokenService } from "./authentication.services";

/** 
* Init Block API Services 
**/

export async function cancelledInvoiceService(data) {
  let token = getTokenService()
  try {
      const { data: responseCancelledInvoice, error } = await Http.post(SalesEndPoints.CANCELLED_INVOICE,data,token)
      if (responseCancelledInvoice && responseCancelledInvoice.status) {
          return responseCancelledInvoice?.data
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function postDeliveryShippingInvoice (data){
  let token = getTokenService()
  try {
    const {data: responsePostDeliveryShippingInvoice, error} = await Http.post(SalesEndPoints.POST_DELIVERY_SHIPPING_INVOICE, data, token) 
    if(responsePostDeliveryShippingInvoice && responsePostDeliveryShippingInvoice.status){
      let responsePostDeliveryShipping = responsePostDeliveryShippingInvoice.data
      responsePostDeliveryShipping.title = responsePostDeliveryShipping.name
      responsePostDeliveryShipping.description = `${dayjs(responsePostDeliveryShipping.date).format('DD/MM/YYYY')} ${responsePostDeliveryShipping.time}`
      responsePostDeliveryShipping.delivery = responsePostDeliveryShipping.chronologicalPosition
      return responsePostDeliveryShipping
    } else {
      throw error
    }
  } catch (err) {
    throw err
  }
}

export async function postDeliveryTurnInvoice (data){
  let token = getTokenService()
  try {
    const {data: postDeliveryTurnInvoice, error} = await Http.post(SalesEndPoints.POST_DELIVERY_TURN_INVOICE, data, token)
    if(postDeliveryTurnInvoice && postDeliveryTurnInvoice.status){
      let responsePostDeliveryTurn = postDeliveryTurnInvoice.data
      responsePostDeliveryTurn.title = responsePostDeliveryTurn.trackingStatus.name
      responsePostDeliveryTurn.description = `${dayjs(responsePostDeliveryTurn.date).format('DD/MM/YYYY')} ${responsePostDeliveryTurn.time}`
      responsePostDeliveryTurn.delivery = responsePostDeliveryTurn.trackingStatus.chronologicalPosition
      return responsePostDeliveryTurn;
    }else {
      throw error
    }
  } catch (err) {
    throw err
  }
}

export async function createElectronicInvoiceService(idInvoice) {
  let token = getTokenService()

  const data = {
    idInvoice
  }
  try {
      const { data: responseCancelledInvoice, error } = await Http.post(SalesEndPoints.CREATE_ELECTRONIC_INVOICE,data,token)
      if (responseCancelledInvoice && responseCancelledInvoice.status) {
          return responseCancelledInvoice?.data
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

/** 
 * Init Block Passages Services 
**/

export async function getInvoicePassagesService(objectFilter) {
  let token = getTokenService()
  try {
    const { data: responseGetInvoicePassages, error } = await Http.get(SalesEndPoints.GET_INVOICE_PASSAGES, objectFilter, token)

      if (responseGetInvoicePassages && responseGetInvoicePassages.status) {

        let listInvoice = responseGetInvoicePassages.data

        if (listInvoice?.rows && listInvoice?.rows?.length > 0) {
          const listInvoiceMap = listInvoice?.rows?.map(({ id, isCancelled, isElectronic, number, codeSale, codePrefix, client, price, date, tickets, municipalityArrive, municipalityDepart, seller }) => {
            return { 
              id,
              isCancelled,
              isElectronic,
              factura: `${codeSale}-${codePrefix}-${number}`,
              name: `${client?.name} ${client?.lastName}`,
              documento: client?.numberDocument,
              costo: price,
              fecha: dayjs(date).format('DD/MM/YYYY'),
              pasajes: tickets,
              destino: `${municipalityDepart} ${municipalityArrive}`,
              vendedor: `${seller?.name} ${seller?.lastName}`,
              email: client?.email,
              idClient: client?.id
            };
          })
          listInvoice.rows = listInvoiceMap
        }
        
        return listInvoice

      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getAllRouteService() {
  try {
      let token = getTokenService()
      const { data: responseGetRoute, error } = await Http.get(SalesEndPoints.GET_ROUTE,null,token)
      if (responseGetRoute && responseGetRoute.status) {
        const routeDTOList = responseGetRoute.data
        let routeList = []

        routeDTOList.forEach(routeDTO => {
            const route = mapperToRouteModel(new RouteDTO(routeDTO.id, routeDTO.name))
            routeList.push(route)
        })
        return routeList
    } else {
        throw error
    }
 
  } catch (err) {
      throw err
  }
}

export async function getAllRouteByHeadquarterDepartService() {
  try {
      let token = getTokenService()
      const { data: responseGetRoute, error } = await Http.get(SalesEndPoints.GET_ROUTE_BY_HEADQUARTER_DEPART,null,token)
      if (responseGetRoute && responseGetRoute.status) {
        const routeDTOList = responseGetRoute.data
        let routeList = []

        routeDTOList.forEach(routeDTO => {
            const route = mapperToRouteModel(new RouteDTO(routeDTO.id, routeDTO.name))
            routeList.push(route)
        })
        return routeList
    } else {
        throw error
    }

  } catch (err) {
      throw err
  }
}

export async function getVehiclesAvailableToTravelService(data) {

  try {
      const { data: responseGetVehiclesAvailableToTravel, error } = await Http.get(SalesEndPoints.GET_VEHICLES_AVAILABLE_TO_TRAVEL,data)
      if (responseGetVehiclesAvailableToTravel && responseGetVehiclesAvailableToTravel.status) {
        const vehiclesAvailableToTravelDTOList = responseGetVehiclesAvailableToTravel.data

        let vehiclesAvailableToTravelList = []
        vehiclesAvailableToTravelDTOList.forEach(vehiclesAvailableToTravelDTO => {

          let vehiclesAvailable = {}
          const vehicles = mapperToVehicleModel(new VehicleDTO(
            vehiclesAvailableToTravelDTO.vehicle.id,
            vehiclesAvailableToTravelDTO.vehicle.height,
            vehiclesAvailableToTravelDTO.vehicle.mark,
            vehiclesAvailableToTravelDTO.vehicle.model,
            vehiclesAvailableToTravelDTO.vehicle.plate,
            vehiclesAvailableToTravelDTO.vehicle.width,
            vehiclesAvailableToTravelDTO.vehicle.internalNumber
          ))
          vehiclesAvailable.vehicle = vehicles
          vehiclesAvailable.totalSeatsAvailable = vehiclesAvailableToTravelDTO.totalSeatsAvailable
          vehiclesAvailable.travel = vehiclesAvailableToTravelDTO.idTravel
          vehiclesAvailable.isAssociatedToTravel = vehiclesAvailableToTravelDTO.isAssociatedToTravel
         

          vehiclesAvailableToTravelList.push(vehiclesAvailable)
        })

        return vehiclesAvailableToTravelList

      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getSeatService(travel) {
  try {
      const { data: responseGetSeat, error } = await Http.get(SalesEndPoints.GET_SEAT,{travel})
      if (responseGetSeat && responseGetSeat.status) {
        const seatDTOList = responseGetSeat.data

        let seatList = []
        seatDTOList.forEach(seatDTO => {

          const seat = mapperToSeatModel(new SeatDTO(
            seatDTO.id, 
            seatDTO.row,
            seatDTO.column,
            seatDTO.price,
            seatDTO.state,
            seatDTO.name,
          ))
          seatList.push(seat)
        })
        return seatList

      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getDriverVehicleTravelService(travel) {
  try {
      const { data: responseGetDriverVehicle, error } = await Http.get(SalesEndPoints.GET_DRIVER_VEHICLE_TRAVEL,{travel})
      if (responseGetDriverVehicle && responseGetDriverVehicle.status) {

        const driverVehicleDTO = responseGetDriverVehicle.data
        let driverVehicle = {}

        let vehicleDTO = new VehicleDTO()
        vehicleDTO.plate = driverVehicleDTO.vehicle.plate
        driverVehicle.vehicle = mapperToVehicleModel(vehicleDTO)

        let driverDTO = new UserDTO()
        driverDTO.name = driverVehicleDTO.driver.name
        driverDTO.lastName = driverVehicleDTO.driver.lastName
        driverVehicle.driver = mapperToUserModel(driverDTO)

        return driverVehicle
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function changeStateToStandByService(seat) {
  try {
      const { data: responseGetSeat, error } = await Http.put(SalesEndPoints.CHANGE_STATE_TO_STAND_BY,{seats : seat})
      if (responseGetSeat && responseGetSeat.status) {

        return responseGetSeat.status

      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function changeStateToAvailableService(seat) {
  try {
      const { data: responseGetSeat, error } = await Http.put(SalesEndPoints.CHANGE_STATE_TO_AVAILABLE,{seats : seat})
      if (responseGetSeat && responseGetSeat.status) {

        return responseGetSeat.status

      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function associateVehicleService(data) {
  try {
      let token = getTokenService()
      const { data: responseAssociateVehicle, error } = await Http.post(SalesEndPoints.ASSOCIATE_VEHICLE_TO_TRAVEL,data,token)
      if (responseAssociateVehicle && responseAssociateVehicle.status) {
          return responseAssociateVehicle?.data
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getPassagesService(id) {
  try {
    let token = getTokenService()
    const urlWithParams = SalesEndPoints.GET_INVOICE_PASSAGES_BY_ID.replace(":id", id)
    const { data: responseGetPassages, error } = await Http.get(urlWithParams,null,token)
    if (responseGetPassages && responseGetPassages.status) {
      const passagesResponseData = responseGetPassages.data;
    
      const counter = passagesResponseData?.seats.filter(seat => seat.state === 0).length;
    
      const seatList = passagesResponseData?.seats.map(seatDTO => {
        const seat = mapperToSeatModel(new SeatDTO(
          seatDTO.id,
          seatDTO.row,
          seatDTO.column,
          seatDTO.price,
          seatDTO.state,
          seatDTO.name
        ));
        seat.label = seat.name;
        seat.value = seat.id;
        seat.checked = false;
        return seat;
      });
    
      const seatArray = Array.from({ length: passagesResponseData?.vehicle?.height }, () =>
        Array(passagesResponseData?.vehicle?.width).fill(undefined)
      );
    
      seatList.forEach(seatVehicle => {
        seatArray[seatVehicle.row][seatVehicle.column] = seatVehicle;
      });
    
      const assignedSeatData = passagesResponseData?.tickets?.map(object => ({
        checked: false,
        id: object?.seat?.id,
        label: object?.seat?.name,
        name: object?.seat?.name,
        price: passagesResponseData?.extraValue ? ((passagesResponseData?.price - passagesResponseData?.extraValue)/ passagesResponseData?.tickets?.length)  : (passagesResponseData?.price / passagesResponseData?.tickets?.length),
        value: object?.id,
        passenger: {
          name: object?.passengerName,
          lastName: object?.passengerLastName || object?.passengerName,
          telephone: object?.numberPhone,
          telephoneCode: {
            id: object?.indicativePhone?.id,
            label: object?.indicativePhone?.number,
            number: object?.indicativePhone?.number,
            value: object?.indicativePhone?.id
          }
        }
      }));
    
      const passagesResponse = {
        documentType: { value: passagesResponseData?.invoiceClient?.UserDocumentType?.id },
        documentNumber: passagesResponseData?.invoiceClient?.numberDocument,
        name: passagesResponseData?.invoiceClient?.name,
        lastName: passagesResponseData?.invoiceClient?.lastName,
        telephone: passagesResponseData?.invoiceClient?.numberPhone,
        telephoneCode: { value: passagesResponseData?.invoiceClient?.indicativeNumber?.id },
        whatsapp: passagesResponseData?.invoiceClient?.numberPhoneWhatsapp,
        whatsappCode: { value: passagesResponseData?.invoiceClient?.indicativeNumberWhatsApp?.id },
        departmentResidence: { value: passagesResponseData?.invoiceClient?.department?.id },
        municipalityResidence: { value: passagesResponseData?.invoiceClient?.municipality?.id },
        contactAddress: passagesResponseData?.invoiceClient?.address || '',
        contactEmail: passagesResponseData?.invoiceClient?.email || '',
        date: dayjs(passagesResponseData?.travelSeat?.date).format('DD/MM/YYYY'),
        hours: passagesResponseData?.travelSeat?.time.replace(/:00$/, ""),
        destination: {
          label: `${passagesResponseData?.route?.municipalityDepart} - ${passagesResponseData?.route?.municipalityArrive}`,
          value: passagesResponseData?.route?.id
        },
        location: {
          label: passagesResponseData?.siteArrive?.name,
          value: passagesResponseData?.siteArrive?.id
        },
        vehicle: {
          isAssociatedToTravel: false,
          label: `${passagesResponseData?.vehicle?.internalNumber} (${counter})`,
          totalSeatsAvailable: 9,
          travel: passagesResponseData?.travelSeat?.id,
          value: passagesResponseData?.vehicle?.id,
          vehicle: {
            height: passagesResponseData?.vehicle?.height,
            id: passagesResponseData?.vehicle?.id,
            internalNumber: passagesResponseData?.vehicle?.internalNumber,
            mark: passagesResponseData?.vehicle?.mark,
            model: passagesResponseData?.vehicle?.model,
            plate: passagesResponseData?.vehicle?.plate,
            width: passagesResponseData?.vehicle?.width,
            seatMap: seatArray
          }
        },
        numberOfSeats: passagesResponseData?.tickets?.length || 1,
        assignedSeats: passagesResponseData?.tickets?.length ? assignedSeatData : [],
        typePayment: {
          label: passagesResponseData?.paymentMethod?.name,
          value: passagesResponseData?.paymentMethod?.id
        },
        isElectronic: passagesResponseData?.isElectronic,
        extraValue: passagesResponseData?.extraValue
      };
      return { passagesResponse, idInvoice: passagesResponseData?.id };
    }else {
      throw error
    }
  } catch (err) {
      throw err
  }
}

/**
* End Block Passages Services 
**/

/** 
* Init Block Shippings Services 
**/

export async function createInvoiceShippingService(data) {
  let token = getTokenService()
  try {
      const { data: responseCreateInvoice, error } = await Http.post(SalesEndPoints.CREATE_INVOICE_SHIPPING,data,token)
      if (responseCreateInvoice && responseCreateInvoice.status) {
          return responseCreateInvoice?.data
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getInvoiceShippingService(data) {
  let token = getTokenService()
  try {
      const { data: responseGetInvoice, error } = await Http.get(SalesEndPoints.CREATE_INVOICE_SHIPPING,{filterValue : data},token)
      if (responseGetInvoice && responseGetInvoice.status) {
          const invoiceData = {...responseGetInvoice?.data,
            dataElectronic: {
                provider: {
                    name: responseGetInvoice?.data?.dataElectronic?.provider?.name || "",
                    nit: responseGetInvoice?.data?.dataElectronic?.provider?.nit || "",
                    url: responseGetInvoice?.data?.dataElectronic?.provider?.url || "",
                },
                cufe: responseGetInvoice?.data?.dataElectronic?.cufe || "",
                dateValidation: responseGetInvoice?.data?.dataElectronic?.dateValidation || "",
            }
          }
          return invoiceData
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getInvoiceShippingsService(objectFilter) {
  let token = getTokenService()
  try {
      const { data: responseGetInvoiceShippings, error } = await Http.get(SalesEndPoints.GET_INVOICE_SHIPPING,objectFilter,token)
      
      if (responseGetInvoiceShippings && responseGetInvoiceShippings.status) {
        let listInvoice = responseGetInvoiceShippings.data
        if (listInvoice?.rows && listInvoice?.rows?.length > 0) {
          const listInvoiceMap = listInvoice?.rows?.map(({
            id, isCancelled, isElectronic, 
            number, codeSale, codePrefix, 
            clientSends, clientReceives, price, 
            date, internalNumber
          }) => {
            return { 
              id,
              isCancelled,
              isElectronic,
              guide: `${codeSale}-${codePrefix}-${number}`,
              clientName: `${clientSends?.name} ${clientSends?.lastName}`,
              document: clientSends?.numberDocument,
              addressee: `${clientReceives?.name} ${clientReceives?.lastName}`,
              documentAddressee: clientReceives?.numberDocument,
              costSales: price,
              dateSale: dayjs(date).format('DD/MM/YYYY'),
              destination: clientReceives?.siteArrive?.name,
              idClient: clientSends?.id,
              email: clientSends?.email,
              vehicle: internalNumber
            };
          })
          listInvoice.shippingInvoices = listInvoiceMap
        }

        return listInvoice

      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getInvoiceShippingTobeDelivered(objectFilter){
  let token = getTokenService()
  try{
    const {data: responseGetInvoiceTobeDelivered, error}= await Http.get(SalesEndPoints.GET_INVOICE_SHIPPING_TOBEDELIVERED,objectFilter,token)

    if (responseGetInvoiceTobeDelivered && responseGetInvoiceTobeDelivered.status) {
      let listInvoice = responseGetInvoiceTobeDelivered.data
      if (listInvoice?.rows && listInvoice?.rows?.length > 0) {
        const listInvoiceMap = listInvoice?.rows?.map(({
          id, isCancelled, isElectronic, 
          number, codeSale, codePrefix, 
          clientSends, clientReceives, price, 
          date, internalNumber,paymentMethod
        }) => {

          return { 
            id,
            isCancelled,
            isElectronic,
            guide: `${codeSale}-${codePrefix}-${number}`,
            clientName: `${clientSends?.name} ${clientSends?.lastName}`,
            document: clientSends?.numberDocument,
            addressee: `${clientReceives?.name} ${clientReceives?.lastName}`,
            documentAddressee: clientReceives?.numberDocument,
            costSales: price,
            dateSale: dayjs(date).format('DD/MM/YYYY'),
            destination: clientReceives?.siteArrive?.name,
            idClient: clientSends?.id,
            email: clientSends?.email,
            vehicle: internalNumber,
            paymentMethod:paymentMethod?.name,
            dataClientReceivesr: {
              name:clientReceives?.name,
              lastName:clientReceives?.lastName,
            },
            documentClientReceives:{numberDocument:clientReceives?.numberDocument},
            typeDocument: {
              label:clientReceives?.documentType?.name,
              value:clientReceives?.documentType?.id,
            }
          };
        })
        listInvoice.shippingInvoices = listInvoiceMap
      }

      return listInvoice

    } else {
        throw error
    }
  }catch(err) {
    throw err
  }
}

export async function getInvoiceShippingCollected(objectFilter){
  try {
    let token = getTokenService()
    const {data: responseGetInvoiceCollected, error}= await Http.get(SalesEndPoints.GET_INVOICE_SHIPPING_COLLECTED,objectFilter,token)

    if (responseGetInvoiceCollected && responseGetInvoiceCollected.status) {
      let listInvoice = responseGetInvoiceCollected.data
      if (listInvoice?.rows && listInvoice?.rows?.length > 0) {
        const listInvoiceMap = listInvoice?.rows?.map(({
          id, isCancelled, isElectronic, 
          number, codeSale, codePrefix, 
          clientSends, clientReceives, price, 
          date, internalNumber,paymentMethod
        }) => {

          return { 
            id,
            isCancelled,
            isElectronic,
            guide: `${codeSale}-${codePrefix}-${number}`,
            clientName: `${clientSends?.name} ${clientSends?.lastName}`,
            document: clientSends?.numberDocument,
            addressee: `${clientReceives?.name} ${clientReceives?.lastName}`,
            documentAddressee: clientReceives?.numberDocument,
            costSales: price,
            dateSale: dayjs(date).format('DD/MM/YYYY'),
            destination: clientReceives?.siteArrive?.name,
            idClient: clientSends?.id,
            email: clientSends?.email,
            vehicle: internalNumber,
            paymentMethod:paymentMethod?.name,
            dataClientReceivesr: {
              name:clientReceives?.name,
              lastName:clientReceives?.lastName,
            },
            documentClientReceives:{numberDocument:clientReceives?.numberDocument},
            typeDocument: {
              label:clientReceives?.documentType?.name,
              value:clientReceives?.documentType?.id,
            }
          };
        })
        listInvoice.shippingInvoices = listInvoiceMap
      }

      return listInvoice
    } else {
        throw error
    }
  } catch (err) {
    throw err
  }
};

export async function getAllVehiclesAvailableTravelService(filter) {
  try {
      let token = getTokenService()
      const { data: responseGetInvoice, error } = await Http.get(SalesEndPoints.GET_ALL_VEHICLES_AVAILABLE_TRAVEL,filter, token)
      if (responseGetInvoice && responseGetInvoice.status) {
        const response = responseGetInvoice.data.map((invoice,index) => ({
          ...invoice,
          label: `${invoice?.internalNumber}${invoice?.time ? "-" + invoice.time : ""}`,
          value: `${index}`
        }))
        return response
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function updateToAssignedTravelService(idInvoice,idTravel) {
  try {
    const { data: responseGetInvoice, error } = await Http.put(SalesEndPoints.UPDATE_SHIPPING,{idTravel,idInvoice})
    if (responseGetInvoice && responseGetInvoice.status) {
      return responseGetInvoice.data
    } else {
      throw error
    }
  } catch (err) {
      throw err
  }
}

export async function getConstantSystemService(code) {
  try {
    let token = getTokenService()
    const urlWithParams = SalesEndPoints.GET_CONSTANT.replace(":code",code)
    const { data: responseGetInvoice, error } = await Http.get(urlWithParams,null,token)
    if (responseGetInvoice && responseGetInvoice.status) {
      return responseGetInvoice.data
    } else {
      throw error
    }
  } catch (err) {
      throw err
  }
}

/**
* End Block Shippings Services 
**/

/** 
* Init Block Turn Services 
**/

export async function createInvoiceTurnService(data) {
  let token = getTokenService()
  try {
      const { data: responseCreateInvoice, error } = await Http.post(SalesEndPoints.CREATE_INVOICE_TURN,data,token)
      if (responseCreateInvoice && responseCreateInvoice.status) {
          return responseCreateInvoice?.data
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getInvoiceTurnService(idInvoice) {
  let token = getTokenService()
  try {
    const { data: responseGetInvoice, error } = await Http.get(SalesEndPoints.GET_INVOICE_TURN, { filterValue: idInvoice }, token)
      if (responseGetInvoice && responseGetInvoice.status) {
          const invoiceData = {...responseGetInvoice?.data,
            dataElectronic: {
                provider: {
                    name: responseGetInvoice?.data?.dataElectronic?.provider?.name || "",
                    nit: responseGetInvoice?.data?.dataElectronic?.provider?.nit || "",
                    url: responseGetInvoice?.data?.dataElectronic?.provider?.url || "",
                },
                cufe: responseGetInvoice?.data?.dataElectronic?.cufe || "",
                dateValidation: responseGetInvoice?.data?.dataElectronic?.dateValidation || "",
            }
          }
          return invoiceData
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getInvoiceTurnsService(objectFilter) {
  let token = getTokenService()
  try {
      const { data: responseGetInvoiceTurns, error } = await Http.get(SalesEndPoints.GET_INVOICE_TURNS,objectFilter,token)

      if (responseGetInvoiceTurns && responseGetInvoiceTurns.status) {

        let listInvoice = responseGetInvoiceTurns.data

        if (listInvoice?.moneyTransferInvoices && listInvoice?.moneyTransferInvoices?.length > 0) {        
          const listInvoiceMap = listInvoice?.moneyTransferInvoices?.map(({ id, isCancelled, isElectronic, number, codeSale, codePrefix , client, clientReceives, price, date, seller, invoiceDetails, siteArrive}) => {
            return { 
              id,
              isCancelled,
              isElectronic,
              guide: `${codeSale}-${codePrefix}-${number}`,
              clientName: `${client?.name} ${client?.lastName}`,
              document: client?.numberDocument,
              addressee: `${clientReceives?.name} ${clientReceives?.lastName}`,
              documentAddressee: clientReceives?.numberDocument,
              amountDraft: price,
              amountMoney: invoiceDetails.amountMoney,
              dateSale: dayjs(date).format('DD/MM/YYYY'),
              destination: siteArrive?.name,
              seller: `${seller?.name} ${seller?.lastName}`,
              idClient: client?.id,
              email: client?.email,
              documentClientReceives:{numberDocument:clientReceives?.numberDocument},
              dataClientReceivesr: {
                name:clientReceives?.name,
                lastName:clientReceives?.lastName,
              },
              typeDocument: {
                label:clientReceives?.documentType?.name,
                value:clientReceives?.documentType?.id,
              }
            };
          })
          listInvoice.invoice = listInvoiceMap
        }
        
        return listInvoice

      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

export async function getInvoiceTurnPreviewService(idInvoice) {
  let token = getTokenService()
  try {
    const { data: responseGetInvoice, error } = await Http.get(SalesEndPoints.GET_INVOICE_TURN, { filterValue: idInvoice }, token)
      if (responseGetInvoice && responseGetInvoice.status) {
        const invoiceData = responseGetInvoice.data
        const getInvoiceTurn = {
          dataSale:{
            num: `${invoiceData?.codeSale}-${invoiceData?.codePrefix}-${invoiceData?.number}`,
            amountDrawing: invoiceData?.invoiceMoneyTransfer?.amountMoney,
            costDraft: invoiceData?.invoiceMoneyTransfer?.cost,
            totalCost: invoiceData?.invoiceMoneyTransfer?.totalCost,
            iva: invoiceData?.invoiceMoneyTransfer?.iva,
            amountReceived: invoiceData?.price
          },
          dataClient: {
            clientName: `${invoiceData?.invoiceClient?.name} ${invoiceData?.invoiceClient?.lastName}`,
            documentType: invoiceData?.invoiceClient?.userDocumentType,
            identificationNumber: invoiceData?.invoiceClient?.numberDocument,
            phone: invoiceData?.invoiceClient?.numberPhone,
            phoneWhatsapp: invoiceData?.invoiceClient?.numberPhoneWhatsapp,
            address: invoiceData?.invoiceClient?.address || "-",
            residentMunicipality: invoiceData?.invoiceClient?.municipality || "-",
          },
          dataRecipient: {
            clientName: `${invoiceData?.invoiceMoneyTransfer?.moneyTransferClient?.name} ${invoiceData?.invoiceMoneyTransfer?.moneyTransferClient?.lastName}`,
            documentType: invoiceData?.invoiceMoneyTransfer?.moneyTransferClient?.userDocumentType,
            identificationNumber: invoiceData?.invoiceMoneyTransfer?.moneyTransferClient?.numberDocument,
            phone: invoiceData?.invoiceMoneyTransfer?.moneyTransferClient?.numberPhone,
            phoneWhatsapp: invoiceData?.invoiceMoneyTransfer?.moneyTransferClient?.numberPhoneWhatsapp,
            address: invoiceData?.invoiceMoneyTransfer?.moneyTransferClient?.address || "-",
            residentMunicipality: invoiceData?.invoiceMoneyTransfer?.moneyTransferClient?.municipality || "-",
          },
          dataStatus: invoiceData.moneyTransferTracker.map((elementTracking) => ({
            title: elementTracking.trackingStatus.name,
            description: `${dayjs(elementTracking.date).format('DD/MM/YYYY')} ${elementTracking.time}`
          }))
        }
          
        return getInvoiceTurn
      } else {
          throw error
      }
  } catch (err) {
      throw err
  }
}

/**
* End Block Turn Services 
**/

/**
* End Block API Services 
**/

/** 
* Begin Block LocalStorage Services 
**/
export function setPassageSalesService(salesPassage) {
  LocalStorage.set(LocalStorageConst.PASSAGE_SALES, salesPassage)
}

export function clearPassageSalesService() {
  LocalStorage.remove(LocalStorageConst.PASSAGE_SALES)
}

export function getPassageSalesService() {
  return LocalStorage.get(LocalStorageConst.PASSAGE_SALES, true)
}

/** 
* End Block LocalStorage Services 
**/
  