//Actions
import { setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from "../../../../../storage/reducers/sales/sales.action";

//Assets
import { Assets } from "../../../../../assets";

//Components
import ModalElectronicInvoiceComponent from "../../../sales/components/modal-electronic-invoice/modal-electronic-invoice.component";

//Components - Shared
import ModalMainComponent from "../../../../../shared/components/modal/modal-main/modal-main.component";
import ErrorToastComponent from "../../../../../shared/components/toast/error-toast/error-toast.component";

//Constants
import AlertConst from "../../../../../core/constants/alerts.const";

//Libraries
import { Checkbox, Input, Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

//Styles
import "./cost-and-invoicing.component.scss"

//Services
import { getAllDocumentTypeService, updateClientService } from "../../../../../services/shared.services";
import { getAllMoneyTransferRangeService } from "../../../../../services/settings.services";

//Utils
import { turnCostAndInvoicingSchema } from "../../../../../utils/form-validations/sales.validators.utils";
import AppConst from "../../../../../core/constants/app.const";

const CostAndInvoicingComponent = (props) => {

  const {
    setDataSalesStateReducer,
    setDataSaleReducer,
    setStateSaleReducer,
    isDisabled,
    isElectronic,
    idCustomer,
    invoiceButtonRef,
    ...clientData
  } = props

  const [t] = useTranslation("translation");
  const buttonCalculateRef = useRef();
  const dinnerCostRef = useRef();
  const dinnerIvaRef = useRef();
  const defaultRef = useRef();

  const inputRefs = [
    buttonCalculateRef, 
    dinnerCostRef, 
    dinnerIvaRef, 
    defaultRef
  ];
  
  const {values, errors, touched, handleBlur, setValues} = useFormik({
    initialValues: {
      documentTypeRecipients: undefined,
      documentNumberRecipients: '',
      nameRecipients: '',
      lastNameRecipients: '',
      dinner: 0,
      dinnerIva: 0,
      dinnerCost: 0,
      dinnerTotal: 0,
      isCalculate: false,
      contactEmailCustomer: ''
    },
    validationSchema: turnCostAndInvoicingSchema,
  });

  const INITIAL_STATE = {
    optionsTypeDocument: [],
    modalInvoiceElectronic: {
      isElectronic: true,
      isState: false
    },
    dataTurns: []
  }

  const [state, setState] = useState(INITIAL_STATE)

  const { 
    optionsTypeDocument,
    modalInvoiceElectronic,
    dataTurns
  } = state
  
  useEffect(() => {
    setValues(clientData);
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStateSaleReducer('isError', !!Object.keys(errors).length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])


  const fetchData = async () => {
    try {
      const results = await Promise.allSettled([
        getAllDocumentTypeService(),
        getAllMoneyTransferRangeService(),
      ]);

      let documentTypeList = [];
      let rangesTurns = [];
      
      for (let index = 0; index < results.length; index++) {
        const elementResponse = results[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              documentTypeList = elementResponse.value.map((documentType) => ({
                label: documentType.name,
                value: documentType.id
              }));
              break;
            case 1:
              rangesTurns = elementResponse.value;
              break;
            default:
              break;
          }
        }
      }

      setState({
        ...state,
        optionsTypeDocument: documentTypeList,
        dataTurns: rangesTurns
      })
      
    } catch (error) {
      // TODO: Implement error alert with code error
    }
  }

  const onChange = ( data, target) =>{
    let value = data && data.target ? data.target.value || '' : data;
    if (target === "dinner") {
      value = value.replace(/,/g, "");
      value = value.replace(/\./g, "");
      if (/^-?\d*\.?\d*$/.test(value) || value === '' || value === '-') {
          const valueNum = Number(value)
          setValues({...values, [target]: valueNum, isCalculate: false})
        }
    }else{
      setValues({...values, [target]: value})
      if (value !== "" || target === "isElectronic") {
        setDataSalesStateReducer("turnSales",target, value)
      }
    }
  }


  const handleCalculate = () => {
    
    if (!values.isCalculate) {
      setDataSalesStateReducer("turnSales","isCalculate", true)
    }

    let rangeFound = undefined

    for (const range of dataTurns) {
      const { initialRange, finalRange, isInfinite } = range;
      if (
        (isInfinite && values.dinner >= initialRange) ||
        (values.dinner >= initialRange && values.dinner <= finalRange)
      ) {
        rangeFound = range;
      }
    }
    
    if (rangeFound) {
      const costMoneyTransfer = Math.ceil(values.dinner * rangeFound.costPercentage) + rangeFound.costMoneyTransfer
      const ivaMoneyTransfer = Math.ceil((costMoneyTransfer) * rangeFound.ivaPercentage)
      const total = costMoneyTransfer + ivaMoneyTransfer + values.dinner
      const costTotal = Math.ceil(total / 100) * 100
  
      let newData = {
        ...values,
        dinnerIva: ivaMoneyTransfer,
        dinnerCost: costMoneyTransfer,
        dinnerTotal: costTotal,
        isCalculate: true,
        costTransferRange: rangeFound.costMoneyTransfer,
        ivaPercentageRange: rangeFound.ivaPercentage,
      }
      setValues(newData);
      setDataSaleReducer("turnSales", newData)
    } else {
      ErrorToastComponent({
        title: "El monto de giro no esta dentro  de los rangos de giro establecidos",
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 2500
      })
    }
  }

  const checkInvoice = () => {
    if (values.contactEmailCustomer) {
      onChange(!isElectronic, "isElectronic")
    }else{
      if (!isElectronic) {
        setState({
          ...state,
          modalInvoiceElectronic: {
            ...modalInvoiceElectronic,
            isState: true
          }
        })
      }
    }
  }

  const closeModalInvoiceElectronic = () => {
    onChange("","contactEmailCustomer")
    setState({
      ...state,
      modalInvoiceElectronic: {
        isElectronic: true,
        isState: false
      }
    })
  }

  const changeEmail = (email) => {
    const validation = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
    onChange(email,"contactEmailCustomer")
    if (validation.test(email)) {
      setState({
        ...state,
        modalInvoiceElectronic: {
          ...modalInvoiceElectronic,
          isElectronic: false
        }
      })
    }
  }

  const acceptModalInvoiceElectronic = async() => {
    try {
      await updateClientService({contactEmail: values.contactEmailCustomer, id: idCustomer})
      setState({
        ...state,
        modalInvoiceElectronic: {
          isElectronic: true,
          isState: false
        }
      })
      setDataSalesStateReducer("turnSales","isElectronic", true)
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  return (
    <>
      <div className="medium-12 cost-and-invoicing__content flex-container align-middle justify-content-between">
        <label className="cost-and-invoicing__title">
          {t("sales.turnPage.turnoverData.title")}
        </label>
        <div className="grid-x align-middle">
          <span className="billing-data__invoice-electronics">
            Crear esta factura como electrónica
          </span>
          <Checkbox
            checked={isElectronic}
            className="billing-data__checkbox"
            onChange={() => checkInvoice()}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="grid-x">
        <div className="medium-6 cost-and-invoicing__content">
          <label className="grid-x cost-and-invoicing__label">
            {t("sales.turnPage.turnoverData.typeDocument")}
          </label>
          <Select
            disabled={isDisabled}
            value={values.documentTypeRecipients}
            onChange={(e, event) => onChange(event, 'documentTypeRecipients')}
            onBlur={(value) => handleBlur('documentTypeRecipients')(value)}
            status={errors.documentTypeRecipients && touched.documentTypeRecipients ?"error":""}
            className="cost-and-invoicing__select"
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsTypeDocument}
          />
          <span className="grid-x cost-and-invoicing__errors">{touched.documentTypeRecipients? errors.documentTypeRecipients : null}</span>
        </div>
        <div className="medium-6 cost-and-invoicing__content cost-and-invoicing__content__right">
          <label className=" grid-x cost-and-invoicing__label cost-and-invoicing__label--mod">
            {t("sales.turnPage.turnoverData.documentNumber")}
          </label>
          <Input
            disabled={isDisabled}
            value={values.documentNumberRecipients}
            onChange={(value) => onChange(value, 'documentNumberRecipients')}
            onBlur={(value) => handleBlur('documentNumberRecipients')(value)}
            status={errors.documentNumberRecipients && touched.documentNumberRecipients ?"error" : ""}
            className="cost-and-invoicing__input__content"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              ),
            }}
          />
          <span className="grid-x cost-and-invoicing__errors">{touched.documentNumberRecipients? errors.documentNumberRecipients : null}</span>
        </div>
        <div className="medium-6 cost-and-invoicing__content ">
          <label className="grid-x cost-and-invoicing__label cost-and-invoicing__label--mod">
            {t("sales.turnPage.turnoverData.nameRecipient")}
          </label>
          <Input
            disabled={isDisabled}
            value={values.nameRecipients}
            onChange={(value) => onChange(value, 'nameRecipients')}
            onBlur={(value) => handleBlur('nameRecipients')(value)}
            status={errors.nameRecipients && touched.nameRecipients ? "error" : ""}
            className="cost-and-invoicing__input__content"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
          />
          <span className="grid-x cost-and-invoicing__errors">{touched.nameRecipients? errors.nameRecipients : null}</span>
        </div>
        <div className="medium-6 cost-and-invoicing__content cost-and-invoicing__content__right">
          <label className=" grid-x cost-and-invoicing__label cost-and-invoicing__label--mod">
            {t("sales.turnPage.turnoverData.lastNameRecipient")}
          </label>
          <Input
            disabled={isDisabled}
            value={values.lastNameRecipients}
            onChange={(value) => onChange(value, 'lastNameRecipients')}
            onBlur={(value) => handleBlur('lastNameRecipients')(value)}
            status={errors.lastNameRecipients && touched.lastNameRecipients ? "error" : ""}
            className="cost-and-invoicing__input__content"
            allowClear={{
              clearIcon: (
                <img
                  width={28}
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_logo_vertical"
                />
              )
            }}
          />
          <span className="grid-x cost-and-invoicing__errors">{touched.lastNameRecipients? errors.lastNameRecipients : null}</span>
        </div>
      </div>
      <div className="cost-and-invoicing__line"/>
      <div className="grid-x cost-and-invoicing__box">
        <div className="medium-6 cost-and-invoicing__content ">
            <label className="grid-x cost-and-invoicing__label cost-and-invoicing__label--mod">
              {t("sales.turnPage.turnoverData.amountMoney")}
            </label>
            <div className="grid-x">
              <Input
                autoFocus
                disabled={isDisabled}
                type="none"
                onChange={(value) => onChange(value,"dinner")}
                onBlur={(value) => handleBlur('dinner')(value)}
                status={errors.dinner && touched.dinner ?"error" : ""}
                className="cost-and-invoicing__input__content cost-and-invoicing__input__content--mod"
                prefix="$"
                value={values.dinner?.toLocaleString()}
                allowClear={{
                  clearIcon: (
                    <img
                      width={28}
                      src={Assets.SharedIcons.icon_square_close}
                      alt="icon_logo_vertical"
                    />
                  )
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setTimeout(() => {
                      inputRefs[0].current.focus();
                    }, 100);
                  }
                }}
              />
              <label className="cost-and-invoicing__text cost-and-invoicing__text--mod">COP</label>
            </div>
            <div>
              <button 
                ref={inputRefs[0]}
                disabled={isDisabled || !values.dinner}
                className="cost-and-invoicing__calculate"
                onClick={handleCalculate}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleCalculate();
                    setTimeout(() => {
                      inputRefs[1].current.focus();
                    }, 100);
                  }
                }}
              >
                <span>{t("sales.turnPage.turnoverData.calculate")}</span>
              </button>
            </div>
        </div>
        { values.isCalculate &&
          <div className="grid-x medium-6 cost-and-invoicing__cost">
            <div className="grid-x justify-content-between align-middle">
            <label className="grid-x cost-and-invoicing__label cost-and-invoicing__label--subtitle">
              {t("sales.turnPage.turnoverData.costDraft")}
            </label>
            <Input
              ref={inputRefs[1]}
              disabled={isDisabled}
              type="none"
              status={errors.dinnerCost && touched.dinnerCost ?"error" : ""}
              className="small-5 cost-and-invoicing__input__content"
              prefix="$"
              value={values.dinnerCost?.toLocaleString()}
              allowClear={false}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  inputRefs[2].current.focus();
                }
              }}
            />
            <label className="cost-and-invoicing__text">COP</label>
            </div>
            <div className="grid-x justify-content-between align-middle">
            <label className="grid-x cost-and-invoicing__label cost-and-invoicing__label--subtitle">IVA 19%</label>
            <Input
              ref={inputRefs[2]}
              disabled={isDisabled}
              type="none"
              className="small-5 cost-and-invoicing__input__content"
              prefix="$"
              value={values.dinnerIva?.toLocaleString()}
              allowClear={false}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setTimeout(() => {
                    invoiceButtonRef.current.focus();
                  }, 100);
                }
              }}
            />
            <label className="cost-and-invoicing__text">COP</label>
            </div>
          </div>
        }
      </div>
      <div className="cost-and-invoicing__line"/>
      { values.isCalculate &&
        <div className="grid-x cost-and-invoicing__container justify-content-end">
          <div className="grid-x medium-6 align-middle justify-content-end">
            <label className="grid-x cost-and-invoicing__label cost-and-invoicing__label--total">
              Total
            </label>
            <Input
                disabled={isDisabled}
                type="none"
                className="cost-and-invoicing__input__content small-6"
                prefix="$"
                value={values.dinnerTotal?.toLocaleString()}
                allowClear={false}
              />
              <label className="cost-and-invoicing__text cost-and-invoicing__text--mod">COP</label>
          </div>
        </div>
      }
      { modalInvoiceElectronic?.isState &&
        <ModalMainComponent
          width={400}
          open={modalInvoiceElectronic?.isState}
          onClose={()=> closeModalInvoiceElectronic()}
          titleIcon={
            <div className="grid-x align-center-middle">
              <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
            </div>
          }
          footer={[
            <button 
              key="accept" 
              className="sales-table__modal-electronic__button"
              onClick={()=> acceptModalInvoiceElectronic()}
              disabled={modalInvoiceElectronic?.isElectronic}
            >
              <span className="sales-table__modal-electronic__button__label">
                Crear factura electrónica
              </span>
            </button>,
            <div className="sales-table__modal-electronic__close" key="cancel">
              <button
                className="sales-table__modal-electronic__close__label"
                onClick={()=> closeModalInvoiceElectronic()}
              >
                Cancelar
              </button>
            </div>
          ]}
        >
          <ModalElectronicInvoiceComponent
            email={values.contactEmailCustomer}
            changeEmail={(email)=>changeEmail(email)}
            isCreateInvoiceElectronic={modalInvoiceElectronic?.isElectronic}
          />
        </ModalMainComponent>
      }
    </>
  );
};

const mapStateToProps = ({ SalesReducer }) => {
  const {
    turnSales: {
      documentTypeRecipients,
      documentNumberRecipients,
      nameRecipients,
      lastNameRecipients,
      dinner,
      dinnerIva,
      dinnerCost,
      dinnerTotal,
      isCalculate,
      isElectronic,
      contactEmailCustomer,
      idCustomer
    },
    isDisabled
  } = SalesReducer;
  return {
    documentTypeRecipients,
    documentNumberRecipients,
    nameRecipients,
    lastNameRecipients,
    isDisabled,
    dinner,
    dinnerIva,
    dinnerCost,
    dinnerTotal,
    isCalculate,
    isElectronic,
    contactEmailCustomer,
    idCustomer
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setDataSaleReducer,
  setStateSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(CostAndInvoicingComponent);
