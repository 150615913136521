class VehicleDTO {

    constructor(id, height, mark, model, plate, width, internalNumber) {
        this.id = id;
        this.height = height;
        this.mark = mark;
        this.model = model;
        this.plate = plate;
        this.width = width;
        this.internalNumber = internalNumber;
    }
}

export default VehicleDTO