// Assets
import { Assets } from "../../../../assets";

// Libraries
import React from "react";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import { Radio } from "antd";
import { connect } from "react-redux";

// Styles
import "./drawer.component.scss";

const DrawerComponent = (props) => {

  const {
    //Functions
    accept = ()=> null,
    onClose = ()=> null,
    onModalPrint = ()=> null,
    // onTypePrint = ()=> null,
    //Variables
    pdfDocument,
    children,
    title,
    buttonPrimaryText,
    buttonSecondaryText,
    disabledPrint = false,
    // innerWidth,
    // typePrint
  } = props;

  const [t] = useTranslation("translation")

  return (
    <div className="drawer grid-y">
        <div className="drawer__header grid-x">
          <button className="small-2" onClick={() => onClose()}>
            <img
              src={Assets.SharedIcons.icon_square_close}
              alt="icon_square_close"
            />
          </button>
          <div className="small-9 grid-x align-center-middle">
            <span className="drawer__header__title">
              {title}
            </span>
          </div>
        </div>
        {pdfDocument ? (
          <>
            {children}
            <div className="drawer__footer grid-y align-middle">
            {/* { innerWidth > 639 &&
              <Radio.Group className="drawer__footer__radio__group" onChange={(e) => onTypePrint(e?.target?.value)}  value={typePrint}>
                <Radio className="drawer__footer__radio" value={0}>80mm</Radio>
                <Radio className="drawer__footer__radio" value={1}>Carta</Radio>
              </Radio.Group>
            } */}
              <button
                className="drawer__footer__button"
                onClick={() => onModalPrint()}
              >
                <span>
                  {t("sales.passages.pdfPassages.salesInvoice.print")}
                </span>
              </button>
              <PDFDownloadLink document={pdfDocument} fileName={`document${Date.now()}`} >
                <button className="drawer__footer__link">
                  <span>
                    {t(
                      "sales.passages.pdfPassages.salesInvoice.downloadInvoice"
                    )}
                  </span>
                </button>
              </PDFDownloadLink>
            </div>
          </>
        ) : (
          <>
            {children}
            <div className="drawer__footer grid-y align-center-middle">
              <button
                disabled={disabledPrint}
                className="drawer__footer__button"
                onClick={() => accept()}
              >
                <span>{buttonPrimaryText}</span>
              </button>
              <button className="drawer__footer__link">
                <span>{buttonSecondaryText}</span>
              </button>
            </div>
          </>
        )}
    </div>
  );
};

const mapStateToProps = ({ AppReducer }) => {
  const { windowSize: { innerWidth } } = AppReducer;
  return {
    innerWidth
  };
};

export default connect(mapStateToProps)(DrawerComponent);
