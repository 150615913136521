import * as driversTypes from './drivers.types';

const INITIAL_STATE = {
	isDisabled: false,
	isError: false,
	linkedVehicle: false,
	driverUserRedux: {},
	vehicleInformationReducer: {},
	isUnlinkVehicle: false,
	driverFromCopy: '',
	idDriverVehicleSelected: null,
	
	originData: {
		//PersonalData
		idDriver: '',
		name: '',
		lastName: '',
		documentType: undefined,
		documentNumber: '',
		date: '',
		countryOfBirth: undefined,
		departmentOfBirth: undefined,
		municipalityOfBirth: undefined,
		bloodType: undefined,
		telephoneCode: undefined,
		telephone: '',
		contactEmail: '',
		departmentResidence: undefined,
		municipalityResidence: undefined,
		contactAddress: '',
		state: 1,
		//DriverInformation
		driverLicenseNumber: '',
		dateOfIssue: '',
		dateExpiration: '',
		nameOfEntity: '',
		authorizedCategory: undefined,
		//vehicle information
		vehicle: {
			id: '',
			vehicleType: '',
			plate: '',
			internalNumber: '',
			mileage: '',
			vehicleCode: '',
			vehicleMake: '',
			numberOfSeats: undefined,
			vehicleStatus: undefined,
			fuelType: '',
			bodywork: ''
		},
	},

	driverFrom: {
		//PersonalData
		idDriver: '',
		name: '',
		lastName: '',
		documentType: undefined,
		documentNumber: '',
		date: '',
		countryOfBirth: undefined,
		departmentOfBirth: undefined,
		municipalityOfBirth: undefined,
		bloodType: undefined,
		telephoneCode: undefined,
		telephone: '',
		contactEmail: '',
		departmentResidence: undefined,
		municipalityResidence: undefined,
		contactAddress: '',
		state: 1,
		//DriverInformation
		driverLicenseNumber: '',
		dateOfIssue: '',
		dateExpiration: '',
		nameOfEntity: '',
		authorizedCategory: undefined,
		//vehicle information
		vehicle: {
			id: '',
			vehicleType: '',
			plate: '',
			internalNumber: '',
			mileage: '',
			vehicleCode: '',
			vehicleMake: '',
			numberOfSeats: undefined,
			vehicleStatus: undefined,
			fuelType: '',
			bodywork: ''
		},
	},
};

const DriversReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case driversTypes.SET_DRIVERS:
			return {
				...state,
				[action.payload.prop]: action.payload.value
			}
		case driversTypes.RESET_DATA_DRIVERS:
			return {
				...INITIAL_STATE
			};
			
		case driversTypes.SET_DATA_DRIVER:
			return {
				...state,
				[action.payload.key]: {
					...state[action.payload.key],
					[action.payload.prop]: action.payload.value
				}
			};
		
		case driversTypes.RESET_DATA_NEW_USER:
			return {
				...state,
				driverFrom: INITIAL_STATE.driverFrom,
				driverFromCopy: INITIAL_STATE.driverFromCopy,
			};
	
		
		case driversTypes.SET_DATA_DRIVER_KEY_STATE:
			return {
				...state,
				...action.payload.value
			};
        default:
			return state;
	}

};

export default DriversReducer;