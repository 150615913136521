//Libraries
import React from 'react'
import { Input } from 'antd'

//Styles
import "./modal-electronic-invoice.scss"

const ModalElectronicInvoiceComponent = (props) => {
    const { 
        email,
        changeEmail = ()=> null,
        isCreateInvoiceElectronic
    } = props
  return (
    <div className='grid-x align-center-middle'>
        <p className='modal-electronic-invoice__title'>
            Crear esta factura como electrónica
        </p>
        <p className='modal-electronic-invoice__info'>
            Estas a punto de crear una factura electrónica y el cliente recibirá en su correo electrónico la factura de compra, podrás acceder, consultarla y descargarla.
        </p>
        <p className='modal-electronic-invoice__info'>
            Para continuar con este proceso es necesario confirmar el correo electrónico del cliente
        </p>
        <label className='modal-electronic-invoice__title'>
            Correo de Contacto
        </label>
        <Input
            value={email}
            className='modal-electronic-invoice__input'
            placeholder='Escribe el correo electrónico de tu cliente'
            onChange={(e)=>{
                changeEmail(e.target.value)
            }}
            status={isCreateInvoiceElectronic ? "error" : ""}
        />
        {isCreateInvoiceElectronic && 
            <span className='modal-electronic-invoice__info__error'>
                El correo electrónico debe ser válido
            </span>
        }
        <label className='modal-electronic-invoice__text'>
            ¿Estas seguro que deseas facturar?
        </label>
    </div>
  )
}

export default ModalElectronicInvoiceComponent