//Assets
import { Assets } from '../../../../../assets'

//Constants
import SalesConst from '../../../../../core/constants/sales.const';

//Libraries
import { Page, Text, View, Document, Image } from '@react-pdf/renderer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from "dayjs";

//Styles
import { PdfPassagesStyles } from "./pdf-passages.component.styles"

const PdfPassagesComponent = (props) => {

    const { 
      dataInvoice,
      size,
    } = props

    const [t]= useTranslation("translation")

    const splitCufe = (cufe, size) => {
      const parts = [];
      for (let i = 0; i < cufe.length; i += size) {
        parts.push(cufe.substring(i, i + size));
      }
      return parts.join('\n');
    };

    return(
        <Document style={PdfPassagesStyles.content}>
        <Page size={size ? "A4" : { width: 297 }} style={PdfPassagesStyles.page}>
          <View style={PdfPassagesStyles.section}>
            <Image
              src={Assets.SharedIcons.icon_logo_pdf}
              style={PdfPassagesStyles.logo}
            />
            <Text
              style={PdfPassagesStyles.titleNit}
            >
              {"Cooperativa de Transportadores del Catatumbo\nNit: 800.175.577-0\nRegimen común"}
            </Text>
            <Text
              style={PdfPassagesStyles.titleNit}
            >
              {"Factura electrónica de venta"}
            </Text>
            <Text
              style={PdfPassagesStyles.subTitle}
            >
              {t("sales.passages.pdfPassages.salesInvoice.header")} No. {dataInvoice?.resolution?.number} DE {dayjs(dataInvoice?.resolution?.date).format('DD/MM/YYYY')} DEL {dataInvoice?.codePrefix} {dataInvoice?.resolution?.initialRange} AL {dataInvoice?.codePrefix} {dataInvoice?.resolution?.finalRange}
            </Text>
            <Text
              style={PdfPassagesStyles.titleMunicipality}
            >
              TIQUETE DE TRANSPORTE INTERMUNICIPAL BÁSICO DE PASAJEROS
            </Text>
            <View style={PdfPassagesStyles.box}>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title} >{t("sales.passages.pdfPassages.salesInvoice.invoice")}</Text>
                  <Text style={PdfPassagesStyles.data}>{`${dataInvoice?.codeSale}-${dataInvoice?.codePrefix}-${dataInvoice?.number}`}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title}>{t("sales.passages.pdfPassages.salesInvoice.dateIssued")}</Text>
                  <Text style={PdfPassagesStyles.data}>{dayjs(dataInvoice?.date).format('DD/MM/YYYY')} {dataInvoice?.hour}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={[PdfPassagesStyles.title, PdfPassagesStyles.boxUnit]}>{t("sales.passages.pdfPassages.salesInvoice.client")}</Text>
                  <Text style={[PdfPassagesStyles.data, PdfPassagesStyles.boxUnit]}>{`${dataInvoice?.invoiceClient?.name} ${dataInvoice?.invoiceClient?.lastName}`}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title} >{t("sales.passages.pdfPassages.salesInvoice.document")}</Text>
                  <Text style={PdfPassagesStyles.data}>{`${dataInvoice?.invoiceClient?.UserDocumentType?.name} ${dataInvoice?.invoiceClient?.numberDocument}`}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={[PdfPassagesStyles.title, PdfPassagesStyles.boxUnit]}>{t("sales.passages.pdfPassages.salesInvoice.seller")}</Text>
                  <Text style={[PdfPassagesStyles.data, PdfPassagesStyles.boxUnit]}>{`${dataInvoice?.seller?.name} ${dataInvoice?.seller?.lastName}`}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={[PdfPassagesStyles.title, PdfPassagesStyles.boxUnit]}>{""}</Text>
                  <Text style={[PdfPassagesStyles.data, PdfPassagesStyles.boxUnit]}>{""}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title}>{t("sales.passages.pdfPassages.salesInvoice.route")}</Text>
                  <Text style={PdfPassagesStyles.data}>{`${dataInvoice?.route?.municipalityDepart}-${dataInvoice?.route?.municipalityArrive}`}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title}>Destino</Text>
                  <Text style={PdfPassagesStyles.data}>{`${dataInvoice?.location?.name}`}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title} >{t("sales.passages.pdfPassages.salesInvoice.departure")}</Text>
                  <Text style={PdfPassagesStyles.data}>{dayjs(dataInvoice?.travelSeat?.date).format('DD/MM/YYYY')}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title}>{t("sales.passages.pdfPassages.salesInvoice.departureTime")}</Text>
                  <Text style={PdfPassagesStyles.data}>{dataInvoice?.travelSeat?.time}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title} >{t("sales.passages.pdfPassages.salesInvoice.vehicle")}</Text>
                  <Text style={PdfPassagesStyles.data}>{dataInvoice?.vehicle?.model}</Text>
              </View>
              <View style={PdfPassagesStyles.boxSection}>
                  <Text style={PdfPassagesStyles.title}>{t("sales.passages.pdfPassages.salesInvoice.plate")}</Text>
                  <Text style={PdfPassagesStyles.data}>{`${dataInvoice?.vehicle?.plate}`}</Text>
              </View>
            </View>
            <View style={[PdfPassagesStyles.line, PdfPassagesStyles.lineSeparator]}/>
            <View style={PdfPassagesStyles.box}>
              <Text style={[PdfPassagesStyles.title, PdfPassagesStyles.titleSecondary, PdfPassagesStyles.boxSection]}>{t("sales.passages.pdfPassages.salesInvoice.salesConcept")}</Text>
              <Text style={[PdfPassagesStyles.titleSecondary, PdfPassagesStyles.boxSection, PdfPassagesStyles.dataRight]}>{t("sales.passages.pdfPassages.salesInvoice.passages")}</Text>
              <Text style={[PdfPassagesStyles.boxSection, PdfPassagesStyles.titleSecondary, PdfPassagesStyles.dataLeft]}>{t("sales.passages.pdfPassages.salesInvoice.quantity")}</Text>
              <Text style={[PdfPassagesStyles.titleSecondary, PdfPassagesStyles.boxSection, PdfPassagesStyles.dataRight]}>{t("sales.passages.pdfPassages.salesInvoice.unitCost")}</Text>
              <Text style={[PdfPassagesStyles.boxSection, PdfPassagesStyles.titleSecondary, PdfPassagesStyles.dataLeft]}>{dataInvoice?.tickets?.length}</Text>
              <Text style={[PdfPassagesStyles.titleSecondary, PdfPassagesStyles.boxSection, PdfPassagesStyles.dataRight]}>${(dataInvoice?.unitCost).toLocaleString()}</Text>
              <View style={[PdfPassagesStyles.line, PdfPassagesStyles.lineSeparator]}/>
              {dataInvoice?.extraValue >= 0 && dataInvoice?.paymentMethod?.type === SalesConst.PAYMENT_METHODS.CREDIT_CARD ?
                <>
                  <Text style={[PdfPassagesStyles.boxSection, PdfPassagesStyles.titleSecondary, PdfPassagesStyles.dataLeft]}>Valor extra</Text>
                  <Text style={[PdfPassagesStyles.titleSecondary, PdfPassagesStyles.boxSection, PdfPassagesStyles.dataRight]}>${(dataInvoice?.extraValue).toLocaleString()}</Text>
                  <View style={[PdfPassagesStyles.line, PdfPassagesStyles.lineSeparator]}/>
                </>
                : 
                <></>
              }
              <Text style={[PdfPassagesStyles.titleSecondary, PdfPassagesStyles.boxSection, PdfPassagesStyles.dataRight, PdfPassagesStyles.dataSeparator]}>{t("sales.passages.pdfPassages.salesInvoice.totalCost")}</Text>
              <Text style={[PdfPassagesStyles.title, PdfPassagesStyles.titleSecondary, PdfPassagesStyles.boxSection]}>${dataInvoice?.price?.toLocaleString()}</Text>
              <View style={[PdfPassagesStyles.line, PdfPassagesStyles.dataLeft, PdfPassagesStyles.lineBottom]}/>
              {dataInvoice?.tickets?.map((ticket,index)=>{
                  return(
                      <View key={index} style={[PdfPassagesStyles.dataUser, PdfPassagesStyles.dataContent]}>
                          <View style={[PdfPassagesStyles.boxSection, PdfPassagesStyles.dataUser, PdfPassagesStyles.passenger]}>
                              <Text style={PdfPassagesStyles.passengerLabel}>{t("sales.passages.pdfPassages.salesInvoice.passenger")}</Text>
                              <Text style={[PdfPassagesStyles.passengerText, PdfPassagesStyles.dataText]}>{ticket?.passengerName}</Text>
                          </View>
                          <View style={[PdfPassagesStyles.boxSection, PdfPassagesStyles.dataUser, PdfPassagesStyles.booth]}>
                              <Text style={PdfPassagesStyles.passengerLabel}>{t("sales.passages.pdfPassages.salesInvoice.booth")}</Text>
                              <Text style={PdfPassagesStyles.dataText}>{ticket?.seat?.name}</Text>
                          </View>
                      </View>
                  )
              })}
              <View style={[PdfPassagesStyles.containerCenterText]}>
                <Text style={[PdfPassagesStyles.centerText]}>
                  {dataInvoice?.nameInsurer} No. {dataInvoice?.policyNumber}
                </Text>
              </View>
              <View style={[PdfPassagesStyles.box,PdfPassagesStyles.footer]}>
                  <Text wrap={false}>
                    {t("sales.passages.pdfPassages.salesInvoice.footer")}
                  </Text>
              </View>
              <View style={[PdfPassagesStyles.line, PdfPassagesStyles.lineSeparator]} />
              <Text style={[PdfPassagesStyles.title, PdfPassagesStyles.boxUnit]}>
                {t("sales.passages.pdfPassages.salesInvoice.cufe")}
              </Text>
              <Text style={[PdfPassagesStyles.data, PdfPassagesStyles.boxUnit]}>
                {splitCufe(dataInvoice?.dataElectronic?.cufe, 37)}
              </Text>
              <Text style={[PdfPassagesStyles.title, PdfPassagesStyles.boxUnit]}>
                {t("sales.passages.pdfPassages.salesInvoice.date")}
              </Text>
              <Text style={[PdfPassagesStyles.data, PdfPassagesStyles.boxUnit]}>
                {dataInvoice?.dataElectronic?.dateValidation}
              </Text>
              <Text style={[PdfPassagesStyles.title, PdfPassagesStyles.boxUnit]}>
                {t("sales.passages.pdfPassages.salesInvoice.provider")}
              </Text>
              <Text style={[PdfPassagesStyles.data, PdfPassagesStyles.boxUnit]}>
                {dataInvoice?.dataElectronic?.provider?.name} {dataInvoice?.dataElectronic?.provider?.nit}
              </Text>
              <View style={[PdfPassagesStyles.bottom]} />
            </View>
          </View>
        </Page>
      </Document>
    )
}

export default PdfPassagesComponent