
export const shiftsData = [
    {
      id: '4:00 AM',
      nestedDroppables: [
        {
          id: "Domingo 4:00 AM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 4:00 AM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 4:00 AM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 4:00 AM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 4:00 AM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 4:00 AM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 4:00 AM",
          day: "sábado",
          items: []
        }
      ]
    },
    {
      id: '5:00 AM',
      nestedDroppables: [
        {
          id: "Domingo 5:00 AM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 5:00 AM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 5:00 AM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 5:00 AM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 5:00 AM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 5:00 AM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 5:00 AM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '6:00 AM',
      nestedDroppables: [
        {
          id: "Domingo 6:00 AM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 6:00 AM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 6:00 AM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 6:00 AM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 6:00 AM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 6:00 AM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 6:00 AM",
          day: "sábado",
          items: []
        }
      ]
    },
    {
      id: '7:00 AM',
      nestedDroppables: [
        {
          id: "Domingo 7:00 AM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 7:00 AM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 7:00 AM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 7:00 AM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 7:00 AM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 7:00 AM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 7:00 AM",
          day: "sábado",
          items: [],
        }
      ]
    },
    // Other shifts data...
    {
      id: '8:00 AM',
      nestedDroppables: [
        {
          id: 'Domingo 8:00 AM',
          day: "domingo",
          items: []
        },
        {
          id: 'Lunes 8:00 AM',
          day: "lunes",
          items: []
        },
        {
          id: 'Martes 8:00 AM',
          day: "martes",
          items: []
        },
        {
          id: 'Miércoles 8:00 AM',
          day: "miércoles",
          items: []
        },
        {
          id: 'Jueves 8:00 AM',
          day: "jueves",
          items: []
        },
        {
          id: 'Viernes 8:00 AM',
          day: "viernes",
          items: []
        },
        {
          id: 'Sábado 8:00 AM',
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '9:00 AM',
      nestedDroppables: [
        {
          id: "Domingo 9:00 AM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 9:00 AM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 9:00 AM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 9:00 AM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 9:00 AM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 9:00 AM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 9:00 AM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '10:00 AM',
      nestedDroppables: [
        {
          id: "Domingo 10:00 AM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 10:00 AM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 10:00 AM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 10:00 AM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 10:00 AM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 10:00 AM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 10:00 AM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '11:00 AM',
      nestedDroppables: [
        {
          id: "Domingo 11:00 AM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 11:00 AM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 11:00 AM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 11:00 AM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 11:00 AM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 11:00 AM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 11:00 AM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '12:00 PM',
      nestedDroppables: [
        {
          id: "Domingo 12:00 PM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 12:00 PM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 12:00 PM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 12:00 PM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 12:00 PM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 12:00 PM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 12:00 PM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '1:00 PM',
      nestedDroppables: [
        {
          id: "Domingo 1:00 PM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 1:00 PM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 1:00 PM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 1:00 PM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 1:00 PM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 1:00 PM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 1:00 PM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '2:00 PM',
      nestedDroppables: [
        {
          id: "Domingo 2:00 PM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 2:00 PM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 2:00 PM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 2:00 PM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 2:00 PM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 2:00 PM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 2:00 PM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '3:00 PM',
      nestedDroppables: [
        {
          id: "Domingo 3:00 PM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 3:00 PM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 3:00 PM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 3:00 PM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 3:00 PM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 3:00 PM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 3:00 PM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '4:00 PM',
      nestedDroppables: [
        {
          id: "Domingo 4:00 PM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 4:00 PM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 4:00 PM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 4:00 PM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 4:00 PM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 4:00 PM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 4:00 PM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '5:00 PM',
      nestedDroppables: [
        {
          id: "Domingo 5:00 PM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 5:00 PM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 5:00 PM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 5:00 PM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 5:00 PM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 5:00 PM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 5:00 PM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '6:00 PM',
      nestedDroppables: [
        {
          id: "Domingo 6:00 PM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 6:00 PM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 6:00 PM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 6:00 PM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 6:00 PM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 6:00 PM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 6:00 PM",
          day: "sábado",
          items: []
        }
      ]
    },
    // Other shifts data...
    {
      id: '7:00 PM',
      nestedDroppables: [
        {
          id: "Domingo 7:00 PM",
          day: "domingo",
          items: []
        },
        {
          id: "Lunes 7:00 PM",
          day: "lunes",
          items: []
        },
        {
          id: "Martes 7:00 PM",
          day: "martes",
          items: []
        },
        {
          id: "Miércoles 7:00 PM",
          day: "miércoles",
          items: []
        },
        {
          id: "Jueves 7:00 PM",
          day: "jueves",
          items: []
        },
        {
          id: "Viernes 7:00 PM",
          day: "viernes",
          items: []
        },
        {
          id: "Sábado 7:00 PM",
          day: "sábado",
          items: []
        }
      ]
    }
];
  