//Actions
import { resetDataNewUserReducer } from './../../../../../storage/reducers/user-settings/user-settings.action';

//Assets
import { Assets } from '../../../../../assets';

//Components
import DataUserComponent from '../data-user/data-user.component';
import PermitsUserComponent from '../permits-user/permits-user.component';
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';
import SuccessToastComponent from '../../../../../shared/components/toast/success-toast/success-toast.component';

//Constants
import { SettingsErrorsConst } from '../../../../../core/constants/errors/alerts-app/seettings.errors.const';
import AlertConst from '../../../../../core/constants/alerts.const';

//Libraries
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Step, Stepper } from 'react-form-stepper';
import { Switch } from 'antd';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

//Services
import { createNewUserService, updateUserByIdService } from '../../../../../services/settings.services';

//Styles
import "./create-user.component.scss";

const CreateUserComponent = (props) => {

  const {
    isError,
    newUserSettings,
    permitsNewUser,
    originData,
    //Actions
    resetDataNewUserReducer,
  } = props;

    let history = useNavigate();
    let {state: isEditing} = useLocation();

    const INITIAL_STATE = {
      locationStepper: 0,
      isActive: newUserSettings.state,
    }

    const [state, setState] = useState(INITIAL_STATE)
    const { locationStepper, isActive } = state
  
    const handleBack = () => {
      !locationStepper && history(-1);
      locationStepper && setState({
        ...state,
        locationStepper: locationStepper - 1
      });
    };

    const dataStepper = [
      { label: "Datos personales" },
      { label: "Permisos" },
    ]

    const onStepperChange = (value) => {
      setState({
        ...state,
        locationStepper: value
      })
    }

    const handleSwitchChange = (checked) => {
      setState({
        ...state,
        isActive: checked ? 1 : 0
      });
    }


    const handleNext = async () => {
      try {
        let data = {
          id: originData?.id,
          name: originData?.nameCreateUser,
          lastName: originData?.lastNameCreateUser,
          email: originData?.emailCreateUser,
          numberPhone: originData?.telephoneCreateUser,
          dateBirth: dayjs(originData?.dateOfBirthCreateUser).format('YYYY-MM-DD'),
          state: originData?.state,
          numberDocument: originData?.numberDocumentCreateUser,
          idDocumentType: originData?.typeDocumentCreateUser?.value,
          idIndicativePhone: originData?.indicativeCreateUser?.value,
          idHeadquarter: originData?.campusCreateUser?.value,
          idUserType: permitsNewUser?.typeUserOrigin?.value,
          sudModules: permitsNewUser?.optionsPermitsNewUser.filter(item => item.checked === true).map(item => item.id),
          hasPostPayroll: permitsNewUser?.originCheckedManifest ? 1 : 0
        }
        if (locationStepper === 1) {
          let newData = {
            id: newUserSettings?.id,
            name: newUserSettings?.nameCreateUser,
            lastName: newUserSettings?.lastNameCreateUser,
            password: newUserSettings?.newPasswordCreateUser,
            email: newUserSettings?.emailCreateUser,
            numberPhone: newUserSettings?.telephoneCreateUser,
            dateBirth: dayjs(newUserSettings?.dateOfBirthCreateUser,'DD/MM/YYYY').format('YYYY-MM-DD'),
            state: isActive,
            numberDocument: newUserSettings?.numberDocumentCreateUser,
            passwordConfirm: newUserSettings?.confirmNewPasswordCreateUser,
            idDocumentType: newUserSettings?.typeDocumentCreateUser?.value,
            idIndicativePhone: newUserSettings?.indicativeCreateUser?.value,
            idHeadquarter: newUserSettings?.campusCreateUser?.value,
            idUserType: permitsNewUser?.typeUser?.value,
            sudModules: permitsNewUser?.optionsPermitsNewUser.filter(item => item.checked === true).map(item => item.id),
            hasPostPayroll: permitsNewUser?.checkingYes ? 1 : 0
          }

          let dataUpdate = compareObjects(newData, data);

          let successMessage = isEditing.isEditing ? "Usuario actualizado con éxito" : "Usuario creado con éxito";
          
          if (isEditing.isEditing && Object.keys(dataUpdate).length > 0) {
            dataUpdate.sudModules = permitsNewUser?.optionsPermitsNewUser.filter(item => item.checked === true).map(item => item.id)
            await updateUserByIdService(newUserSettings.id, dataUpdate);
          } 
          if(!isEditing.isEditing) {
            await createNewUserService(newData);
          }

          SuccessToastComponent({
            html: 
            `<span>${successMessage}
              <a class="create-user__text-alert"</a>
            </span>`,
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
        });

          resetDataNewUserReducer();
          history(-1);
        } else {
          setState({
            ...state,
            locationStepper: locationStepper + 1
          });
        }
      } catch (err) {
        ErrorToastComponent({
          title: SettingsErrorsConst.newUsers[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
          position: AlertConst.TOP_END_POSITION_TEXT,
          timer: 1500
      });
      return;
      }
    }

    const compareObjects = (objectOld, objectNew) => {
      const differences = {};
  
      const compareProperties = (key, valueOld, valueNew) => {
          if (typeof valueOld === 'object' && typeof valueNew === 'object') {
              const subDifferences = compareObjects(valueOld, valueNew);
              if (Object.keys(subDifferences).length > 0) {
                  differences[key] = subDifferences;
              }
          } else if (valueOld !== valueNew) {
              differences[key] = valueNew;
          }
      };
  
      const missingProperties = (objOld, objNew) => {
          const missing = {};
          for (const prop in objNew) {
              if (objNew.hasOwnProperty(prop)) {
                  if (!objOld.hasOwnProperty(prop)) {
                      missing[prop] = objNew[prop];
                  } else if (typeof objNew[prop] === 'object' && typeof objOld[prop] === 'object') {
                      const subDifferences = compareObjects(objOld[prop], objNew[prop]);
                      if (Object.keys(subDifferences).length > 0) {
                          missing[prop] = subDifferences;
                      }
                  } else if (objOld[prop] !== objNew[prop]) {
                      missing[prop] = objNew[prop];
                  }
              }
          }
          return missing;
      };
  
      for (const key in objectOld) {
          if (objectOld.hasOwnProperty(key) && objectNew.hasOwnProperty(key)) {
              compareProperties(key, objectOld[key], objectNew[key]);
          }
      }
  
      const missingInObjectOld = missingProperties(objectOld, objectNew);
      const missingInObjectNew = missingProperties(objectNew, objectOld);
  
      Object.assign(differences, missingInObjectOld, missingInObjectNew);
  
      return differences;
    }
    
    return (
      <div style={{ height: "100%" }}>
        <div className="create-user__content">
          <span className="create-user__subtitle">
            Usuarios y permisos
          </span>
          <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
          <span className="create-user__subtitle">Crear usuario</span>
        </div>
        <div className="small-9 grid-x align-center" style={{ height: "90%" }}>
          <div className="small-2" style={{ height: "100%" }}>
            <div
              className="create-user__content-left"
              style={{ height: "100%"}}
            >
              <div className="grid-x align-center create-user__content-left__box">
                  <img
                    src={Assets.SharedIcons.icon_user_profile}
                    alt="icon_user_profile"
                    className="create-user__content-left__image create-user__content-left__image--mod"
                  />
                <label className="small-12 text-center create-user__label create-user__label--mod">
                  {newUserSettings?.nameCreateUser} {newUserSettings?.lastNameCreateUser}
                </label>
                <div className='create-user__content-left__padding-top'>
                  <button className="create-user__content-left__button">
                    <span>Subir fotos</span>
                  </button>
                </div>
              </div>
              <div className="create-user__content-left__line"></div>
              <div className="grid-x align-center">
                <label className="small-12 text-center create-user__label">Estado</label>
                <div>
                  <Switch
                    checkedChildren="Activo" 
                    unCheckedChildren="Inactivo"
                    checked={isActive}
                    onChange={handleSwitchChange}
                    className='create-user__content-left__switch'
                  />
                </div>
              </div>
              <div className="create-user__content-left__line"></div>
              <div className="grid-x align-center">
                <label className="small-12 text-center create-user__label">
                  Número de contacto
                </label>
                <span className='create-user__label--mod-text'>{newUserSettings?.telephoneCreateUser}</span>
              </div>
              <div className="create-user__content-left__line"></div>
              <div className="grid-x align-center">
                <label className="small-12 text-center create-user__label">
                  Número de documento
                </label>
                <span className='create-user__label--mod-text'>{newUserSettings?.numberDocumentCreateUser}</span>
              </div>
              <div className="create-user__content-left__line"></div>
              <div className="grid-x align-center">
                <label className="small-12 text-center create-user__label">Tipo de usuario</label>
                <span className='create-user__label--mod-text'>{permitsNewUser?.typeUser?.label}</span>
              </div>
              <div className="create-user__content-left__line"></div>
            </div>
          </div>
          <div className="small-7" style={{ height: "100%" }}>
            <div className="create-user__content-right" style={{ height: "100%" }}>
                <div style={{ height: "90%" }}>
                    <div className="grid-x small-12 create-user__content-step">
                        <Stepper
                        activeStep={locationStepper}
                        connectorStateColors={locationStepper ? true : false}
                        className="grid-x create-user__steppers"
                        >
                        {dataStepper.map((sections, index) => (
                            <Step
                            key={index}
                            label={sections?.label}
                            onClick={() => onStepperChange(index)}
                            className={
                              `create-user__step ${
                                index <= locationStepper
                                ? "create-user__step--active"
                                : "create-user__step--inactive"
                              } ${
                                index < locationStepper
                                ? "create-user__button-active"
                                : ""
                              }`
                            }
                            />
                        ))}
                        </Stepper>
                    </div>
                    {locationStepper === 0 && <DataUserComponent/>}
                    {locationStepper === 1 && <PermitsUserComponent/>}
                </div>
                <div className='grid-x justify-content-between align-middle' style={{ height: "10%", paddingLeft: "30px", paddingRight: "30px" }}>
                    <button 
                      onClick={() => handleBack()} 
                      className='grid-x align-center-middle create-user__content-right__button'>
                        Regresar
                    </button>
                    <button 
                      onClick={() => handleNext()} 
                      className='grid-x align-center-middle create-user__content-right__button' 
                      disabled={isError}>
                        {isEditing.isEditing 
                          ? (locationStepper === 0 ? 'Siguiente' : 'Actualizar') 
                          : (locationStepper === 0 ? 'Siguiente' : 'Crear usuario')
                        }
                    </button>
                </div> 
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = ({UserSettingsReducer}) => {
  const { 
    isError, 
    newUserSettings, 
    permitsNewUser,
    originData
  } = UserSettingsReducer;

  return{
    isError,
    newUserSettings,
    permitsNewUser,
    originData
  };
};

const mapStateToPropsActions = {
  resetDataNewUserReducer
};

export default connect (mapStateToProps, mapStateToPropsActions)(CreateUserComponent);