// Constants - Endpoints
import ShippingsEndPoints from "./constants/shippings.end-points"

// Infrastructure
import Http from "./infrastructure/http.infrastructure"

// Libraries
import dayjs from 'dayjs';

// Models - DTO
import ShippingTypeDTO from "../models/shipping-type/dto/shipping-type.dto"
import { mapperToShippingTypeModel } from "../models/shipping-type/dto/shipping-type.dto.dao"

// Services
import { getTokenService } from "./authentication.services"

/** 
* Init Block API Services 
**/
export async function getAllShippingTypeService(valueFilter="") {
    try {
        const { data: responseGetShippingType, error } = await Http.get(ShippingsEndPoints.GET_SHIPPING_TYPE, { valueFilter: valueFilter, state: 1})
        if (responseGetShippingType && responseGetShippingType.status) {
            const shippingTypeDTOList = responseGetShippingType.data
            let shippingTypeList = []
            let shippingIdOthers = undefined
            shippingTypeDTOList.forEach(shippingTypeDTO => {
                const shippingType = mapperToShippingTypeModel(new ShippingTypeDTO(shippingTypeDTO.id, shippingTypeDTO.name))
                if (shippingType?.name === "Otros") {
                    shippingIdOthers = shippingType?.id
                }else{
                    shippingTypeList.push(shippingType)
                }
            })
            return {shippingTypeList, shippingIdOthers}
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getServiceTypeService() {
    try {
        const { data: responseGetServiceType, error } = await Http.get(ShippingsEndPoints.GET_SERVICE_TYPE)
        if (responseGetServiceType && responseGetServiceType.status) {
            const serviceType = responseGetServiceType.data
            return serviceType
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getShippingDetailsByFilterParamService(filterValue) {
    try {
        const token = getTokenService()
        const { data: responseGetShippingFound, error } = await Http.get(ShippingsEndPoints.GET_SHIPPING_DETAILS_BY_FILTER_PARAM, { filterValue }, token)
        if (responseGetShippingFound && responseGetShippingFound.status) {
            const { clientReceives, clientSends, invoice, invoiceDetails, shipmentTracking } = responseGetShippingFound.data
            const shippingFound = {
                dataClient: {
                    address: clientSends?.address || "",
                    clientName: `${clientSends?.name || ""} ${clientSends?.lastName || ""}`,
                    documentType: clientSends?.documentType?.name || "",
                    identificationNumber: clientSends?.numberDocument || "",
                    phone: clientSends?.numberPhone || "",
                    residentMunicipality: clientSends?.municipality?.name || "",
                    phoneWhatsapp: clientSends?.numberPhoneWhatsapp || ""
                },
                dataRecipient: {
                    address: clientReceives?.address || "",
                    clientName: `${clientReceives?.name || ""} ${clientReceives?.lastName || ""}`,
                    documentType: clientReceives?.documentType?.name || "",
                    identificationNumber: clientReceives.numberDocument || "",
                    phone: clientReceives?.numberPhone || "",
                    residentSiteArrive: clientReceives?.siteArrive?.name || "",
                    residentMunicipality: clientReceives?.municipality?.name || "",
                    phoneWhatsapp: clientReceives?.numberPhoneWhatsapp || ""
                },
                dataSale: {
                    category: invoiceDetails?.nameTypeShipping || "",
                    content: invoiceDetails?.content || "",
                    costInsurance: invoiceDetails?.insuranceCost || 0,
                    costOrder: invoiceDetails?.costShipping || 0,
                    porteService: invoiceDetails?.porteService || 0,
                    manifestOfFreight: invoiceDetails?.manifestOfFreight || 0,
                    costTotal: invoice?.price || 0,
                    declaredValue: invoiceDetails?.declaredValue || 0,
                    delivery: invoiceDetails?.isHomeDelivery ? "Domicilio" : "En oficina",
                    description: invoiceDetails?.descriptionDelivery || "",
                    costDelivery: invoiceDetails?.costDelivery || 0,
                    num: `${invoice?.codeSale || ""}-${invoice?.codePrefix || ""}-${invoice?.number || ""}`,
                },
                dataStatus: shipmentTracking.map((elementTracking) => ({
                    title: elementTracking.name || "",
                    description: `${dayjs(elementTracking?.date).format('DD/MM/YYYY')} ${elementTracking?.time}`
                }))
            }

            return shippingFound
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getOrderWithGuideService() {
    try {
        let token = getTokenService();
        const { data: getOrderWithGuide, error } = await Http.get(ShippingsEndPoints.GET_ORDER_WITH_GUIDE, null, token)
        if (getOrderWithGuide && getOrderWithGuide.status) {
            const getDriverAvailable = getOrderWithGuide.data.map((item) => ({
                ...item,
                label: `${item?.invoice.codeSale}-${item?.invoice.prefix}-${item?.invoice.number}`,
                value: item?.id,
                checked: false
            }))
            return getDriverAvailable
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }

}

export async function getAllShippingTypeSettingService() {
    try {
        let token = getTokenService();
        const { data: getShippingResolution, error } = await Http.get(ShippingsEndPoints.GET_SHIPPING_TYPE, null, token)
        if (getShippingResolution && getShippingResolution.status) {
            return getShippingResolution.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createOrderTypeService(data, state) {
    try {
        let token = getTokenService()
        const { data: createOrderType, error } = await Http.post(ShippingsEndPoints.CREATE_ORDER_TYPE,{name:data, state:state},token)
        if (createOrderType && createOrderType.status) {
            return createOrderType?.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateOrderTypeService(id, name,state) {
    try {
        let token = getTokenService()
        const orderParameters = ShippingsEndPoints.UPDATE_ORDER_TYPE.replace(":id", id)
        const { data: updateOrderType, error } = await Http.patch(orderParameters, {name:name,state:state}, token)
        if (updateOrderType && updateOrderType.status) {
            return updateOrderType.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};


/**
* End Block API Services 
**/