class UserDTO {
    constructor(
      id,name,lastName,numberDocument,numberPhone,numberPhoneWhatsapp,
      address,email,municipality,department,documentType,indicativePhone,
      indicativeNumberWhatsapp
    ) {
      this.id = id;
      this.name = name;
      this.lastName = lastName;
      this.documentType = documentType;
      this.indicativePhone = indicativePhone;
      this.indicativeNumberWhatsapp = indicativeNumberWhatsapp;
      this.numberDocument = numberDocument;
      this.numberPhone = numberPhone;
      this.numberPhoneWhatsapp = numberPhoneWhatsapp;
      this.municipality = municipality;
      this.department = department;
      this.address = address;
      this.email = email;
    }
  }
  
  export default UserDTO;