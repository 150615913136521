// Assets
import { Assets } from "../../../assets";

// Components - shared
import ErrorToastComponent from "../../../shared/components/toast/error-toast/error-toast.component";

// Constants
import AlertConst from "../../../core/constants/alerts.const";

// Libraries
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Input } from "antd";

// Styles
import "./recovery-password.page.scss";

//Services
import { generateCodeService, getValidateEmailService } from "../../../services/authentication.services";

// Utils
import { validateEmailSchema } from "../../../utils/form-validations/authentications.validators.utils";

const RecoveryPasswordPage = () => {

  const [t] = useTranslation("translation");
  const  history = useNavigate();

  const { handleSubmit, handleChange, values, errors, touched, setTouched } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validateEmailSchema,
    onSubmit: async () => {
      try {
        const token = await getValidateEmailService({email:values?.email});
        await generateCodeService(token);
        history("/validateCode", { replace: true, state: { token } });

      } catch (error){
        ErrorToastComponent({
          title: `${error.code} ${error.message}`,
          position: AlertConst?.TOP_END_POSITION_TEXT,
          timer: 1500
        })
      }
    }
  });

  return (
    <form className="grid-y recovery-password__container" onSubmit={handleSubmit}>
      <div className="grid-x align-center-middle small-4">
        <img
          src={Assets.SharedIcons.icon_logo_vertical}
          alt="icon_logo_vertical"
        />
      </div>
      <div className="grid-y small-8 recovery-password__form-content">
        <span className="recovery-password__form-content__title">
          {t("authentication.recoveryPassword.mainTitle")}
        </span>

        <div className="grid-y recovery-password__form-content__field ">
          <label className="grid-x recovery-password__form-content__field__title">
            {t("authentication.recoveryPassword.email")} <div className="recovery-password__form-content__field__title__icon" />
          </label>
          <Input
            value={values.email}
            allowClear={{
              clearIcon: <img
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            }}
            type="email"
            status={touched.email && errors.email ? "error" : ""}
            onFocus={() => setTouched({ ...touched, email: true })}
            onChange={(value) => handleChange('email')(value)}
            placeholder={t("authentication.recoveryPassword.email")}
            className={`recovery-password__form-content__field__input ${!!values.email.length ? 'recovery-password__form-content__field__input--mod' : ''}`}
          />
        </div>
        <span className="recovery-password__form-content__description">
          {t("authentication.recoveryPassword.description")}
        </span>
        <div className="grid-x">
          <button
            className="recovery-password__button"
            type="submit"
            disabled={Object.keys(errors).length || !values.email.length}
          >
            {t("authentication.recoveryPassword.sendMail")}
          </button>
        </div>
        <div className="grid-x align-center-middle">
          <Link className="recovery-password__link" to="/login">
            {t("authentication.recoveryPassword.return")}
          </Link>
        </div>

      </div>
    </form>
  );
};

export default RecoveryPasswordPage;
