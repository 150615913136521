//  Actions
import { setProcessValidateSessionAuthenticationReducer } from "../storage/reducers/authentication/authentication.actions"

// Components - shared
// import ErrorToastComponent from "../shared/components/toast/error-toast/error-toast.component";

// Constants
// import AlertConst from "../core/constants/alerts.const";

// Libraries
import { Navigate, Outlet } from "react-router-dom"
import { connect } from "react-redux"
import { useEffect } from 'react'

// Services
import { 
	// clearKeysAuthenticationService, 
	// validateSessionService,
	getTokenService, getUserRolesService
} from "../services/authentication.services"

// Utils
import { isContainedAnyRolesRequired } from "../utils/authentication.utils"
// import LoadingHomeComponent from "../shared/components/loadings/loading-home/loading-home.component"

const authProcess = () => {
	let token = getTokenService()
	let role = getUserRolesService()

	if (token) {
		return {
			isAuth: true,
			role: role.roleId
		}
	} else {
		return {
			isAuth: false,
			role: ""
		}
	}
}

const ProtectedRoutes = (props) => {

	// const {
	// 	// Actions
	// 	setProcessValidateSessionAuthenticationReducer,
	// 	// Variables
	// 	isCheckingSession,
	// 	isValidSession,
	// 	tokenReducer
	// } = props

	useEffect(() => {
		// processValidationToken()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	// TODO: Verify case use, implement renew data session on cotranscat project
	// async function processValidationToken() {
		// try {
		// 	let token = getTokenService()
		// 	if (!token) {
		// 		token = tokenReducer
		// 	}
		// 	const isValidSession = await validateSessionService(token)
		// 	setProcessValidateSessionAuthenticationReducer(true, isValidSession)
		// } catch (err) {
		// 	ErrorToastComponent({
		// 		title:`${err.code} ${err.message}`,
		// 	  position: AlertConst.TOP_END_POSITION_TEXT,
		// 	  timer: 1500
		// 	})
		// 	setProcessValidateSessionAuthenticationReducer(true, false)
		// }
	// }

	/* if (!isCheckingSession && !isValidSession) {
		return <LoadingHomeComponent />
	} else if (isCheckingSession && !isValidSession) {
		clearKeysAuthenticationService()
		return <Navigate replace to="/login" />
	} else {
	
	} */
	const { isAuth, role } = authProcess()
	if (props.roleRequired) {
		const isValidRole = isContainedAnyRolesRequired(props.roleRequired, role)
		return isAuth ? (
			isValidRole ? (
				<Outlet />
			) : (
				<Navigate replace to="/denied" />
			)
		) : (
			<Navigate replace to="/login" />
		)
	} else {
		return isAuth ? <Outlet /> : <Navigate replace to="/login" />
	}
}

const mapStateToProps = ({ AuthenticationReducer }) => {
	const { isCheckingSession, isValidSession, userLogged } = AuthenticationReducer

	let tokenReducer

	if (userLogged) {
		const { authentication: { token } } = userLogged
		tokenReducer = token
	}

	return {
		isCheckingSession,
		isValidSession,
		tokenReducer
	}
}

const mapStateToPropsActions = {
	setProcessValidateSessionAuthenticationReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(ProtectedRoutes)