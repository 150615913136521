//Assets
import { Assets } from '../../../assets';

//Libraries
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

//Services
import { getResolutionService, getTypeResolutionService } from '../../../services/settings.services';

//Styles
import './invoicing-resolution.page.scss'

const InvoicingResolutionPage = () => {
    let history = useNavigate();
    const titles = [
        "Estado","Tipo de servicio","Prefijo TNS",
        "Prefijo de la DIAN","Fecha de la resolución",
        "Consecutivo actual","Rango final","Acciones"
    ]

    useEffect(() => {
        getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const INITIAL_STATE = {
        listResolution: [],
        listTypeResolution: []
    }

    const [state, setState] = useState(INITIAL_STATE)
    const { listResolution, listTypeResolution } = state

    const getServices = async() => {
        const objectState = { ...state }
        const resolutions = await getResolution()
        objectState.listResolution = resolutions
        const typeResolution = await getTypeResolution()
        objectState.listTypeResolution = typeResolution
        setState(objectState)
    }

    const getResolution = async() => {
        try {
            const resolutions = await getResolutionService();
            return resolutions
        } catch (error) {
            // TODO: Implement error alert with code error.
        }
    }

    const redirect = (num,id) => {
        history('createResolution',{ state : { permit: num, idResolution: id } })
    }

    const getTypeResolution = async () => {
        try {
          const listType = await getTypeResolutionService();
          return listType.map((resolution) => ({
            ...resolution,
            label: resolution?.name,
            value: resolution?.id
          }));
        } catch {
          // TODO: Implement error alert with code error.
        }
    }

  return (
    <div>
        <div className='grid-x align-center-middle'>
            <button 
                className='invoicing-resolution__button'
                onClick={() => {
                    redirect(0)
                }}
            >
                <span className='invoicing-resolution__button__text'>
                    Crear Resolución de facturación
                </span>
                <img
                    src={Assets.SharedIcons.icon_add}
                    alt="icon_add"
                    className='invoicing-resolution__button__icon'
                />
            </button>
        </div>
        <div className='invoicing-resolution__table'>
            <div className='grid-x'>
                {titles.map((title, index) => {
                    return (
                        <div
                            key={index}
                            style={{ width: `calc(100% / ${titles.length})` }}
                            className='text-center invoicing-resolution__table__line'
                        >
                            <span className='invoicing-resolution__table__title'>{title}</span>
                        </div>
                    )
                })}
            </div>
            <div>
                {listResolution.map((item, index) => {
                    const status = {
                        1: "Vigente",
                        0: "Caducado",
                    }
                    const serviceTypeItem = listTypeResolution?.find((type) => type?.id === item?.prefix?.serviceType)

                    return(
                        <div
                            key={index}
                            className={`${index === listResolution?.length - 1 ? 'grid-x' : 'grid-x invoicing-resolution__table__line'}`}
                        >
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span 
                                    className={`invoicing-resolution__table__text ${item?.resolution?.stateType ? "invoicing-resolution__table__text__green" : "invoicing-resolution__table__text__red"}`}
                                >
                                    {status[item?.resolution?.stateType]}
                                </span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='invoicing-resolution__table__text'>{serviceTypeItem?.name}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='invoicing-resolution__table__text'>{item?.prefix?.code}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='invoicing-resolution__table__text'>{item?.resolution?.DIANPrefix}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='invoicing-resolution__table__text'>{dayjs(item?.resolution?.dateOfIssuance).format('DD/MM/YYYY')}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='invoicing-resolution__table__text'>{item?.prefix?.currentConsecutive}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <span className='invoicing-resolution__table__text'>{item?.resolution?.finalRange}</span>
                            </div>
                            <div 
                                style={{ width: `calc(100% / ${titles.length})` }}
                                className='text-center'
                            >
                                <button
                                    onClick={() => {
                                        redirect(1, item?.resolution?.id)
                                    }}
                                >
                                    <img
                                        src={Assets.SharedIcons.icon_view_details}
                                        alt="icon_view_details"
                                        width={25}
                                        height={25}
                                    />
                                </button>
                                <button
                                    onClick={() => {
                                        redirect(2, item?.resolution?.id)
                                    }}
                                >
                                    <img
                                        src={Assets.SharedIcons.icon_edit_pencil}
                                        alt="icon_edit_pencil"
                                        width={25}
                                        height={25}
                                    />
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default InvoicingResolutionPage