//Passages
import icon_copy_disabled from "./passages/icon_copy_disabled.svg"
import icon_send_ticket from "./passages/icon_send_ticket.svg"
import icon_send_ticket_active from "./passages/icon_send_ticket_active.svg"
import icon_print_ticket from "./passages/icon_print_ticket.svg"
import icon_print_ticket_active from "./passages/icon_print_ticket_active.svg"

//Passages - passenger-data
import icon_copy from "./passages/passenger-data/icon_copy.svg"

//Passages - trip-data
import icon_clear_calendar from "./passages/trip-data/icon_clear_calendar.svg"
import icon_calendar from "./passages/trip-data/icon_calendar.svg"
import icon_hour from "./passages/trip-data/icon_hour.svg"
import icon_calendar_disabled from "./passages/trip-data/icon_calendar_disabled.svg"
import icon_hour_disabled from "./passages/trip-data/icon_hour_disabled.svg"
import icon_comments from "./passages/trip-data/icon_comments.svg"
import icon_available_seat from "./passages/trip-data/icon_available_seat.svg"
import icon_driver_seat from "./passages/trip-data/icon_driver_seat.svg"
import icon_preselected_seat from "./passages/trip-data/icon_preselected_seat.svg"
import icon_reserved_seat from "./passages/trip-data/icon_reserved_seat.svg"
import icon_seat_not_available from "./passages/trip-data/icon_seat_not_available.svg"
import icon_comments_disabled from "./passages/trip-data/icon_comments_disabled.svg"

//Sales-footer
import icon_clear from "./sales-footer/icon_clear.svg"
import icon_right_arrow from "./sales-footer/icon_right_arrow.svg"

//Sales-header
import icon_passages from "./sales-header/icon_passages.svg"
import icon_turns from "./sales-header/icon_turns.svg"
import icon_orders from "./sales-header/icon_orders.svg"
import icon_passages_hover from "./sales-header/icon_passages_hover.svg"
import icon_turns_hover from "./sales-header/icon_turns_hover.svg"
import icon_orders_hover from "./sales-header/icon_orders_hover.svg"

//Sales-table
import icon_back from "./sales-table/icon_back.svg"
import icon_forward from "./sales-table/icon_forward.svg"
import icon_invoice from "./sales-table/icon_invoice.svg"
import icon_shared_disabled from "./sales-table/icon_shared_disabled.svg"
import icon_shared from "./sales-table/icon_shared.svg"
import icon_print from "./sales-table/icon_print.svg"

//Table-no-sales
import icon_broken_link from "./table-no-sales/icon_broken_link.svg"

export const SalesIcons = {
    icon_passages,
    icon_turns,
    icon_orders,
    icon_back,
    icon_forward,
    icon_clear,
    icon_broken_link,
    icon_passages_hover,
    icon_turns_hover,
    icon_orders_hover,
    icon_copy,
    icon_copy_disabled,
    icon_clear_calendar,
    icon_calendar,
    icon_hour,
    icon_calendar_disabled,
    icon_hour_disabled,
    icon_comments,
    icon_available_seat,
    icon_driver_seat,
    icon_preselected_seat,
    icon_reserved_seat,
    icon_seat_not_available,
    icon_send_ticket,
    icon_send_ticket_active,
    icon_print_ticket,
    icon_print_ticket_active,
    icon_right_arrow,
    icon_comments_disabled,
    icon_invoice,
    icon_shared_disabled,
    icon_shared,
    icon_print
}