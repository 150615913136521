//Actions
import { setDataSalesStateReducer, setStateSaleReducer } from '../../../../../storage/reducers/sales/sales.action';

//Assets
import { Assets } from '../../../../../assets';

//Components
import ModalObservationsComponent from '../../../passages/components/modal-observations/modal-observations.component';
import ModalElectronicInvoiceComponent from '../../../sales/components/modal-electronic-invoice/modal-electronic-invoice.component';

//Components - Shared
import ModalMainComponent from '../../../../../shared/components/modal/modal-main/modal-main.component';

//Constants
import AppConst from '../../../../../core/constants/app.const';

//Libraries
import { Checkbox, Input, Select } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

//Services
import { getAllDocumentTypeService, getMunicipalityBySiteService, updateClientService } from '../../../../../services/shared.services';

//Styles
import "./billing.component.scss"

//Utils
import { shippingsBillingDataSchema } from '../../../../../utils/form-validations/sales.validators.utils';

const BillingComponent = (props) => {

  const [t] = useTranslation("translation");

  const {
    // Action
    setDataSalesStateReducer,
    setStateSaleReducer,
    // Variables
    isDisabled,
    declaredValue,
    isElectronic,
    idCustomer,
    invoiceButtonRef,
    ...billingData
  } = props;

  const INITIAL_STATE = {
    numberOfSeats: 4,
    isEditBilling: true,
    isModalObservation: false,
    optionsMunicipality: [],
    optionsTypeDocument: [],
    modalInvoiceElectronic: {
      isElectronic: true,
      isState: false
    }
  };

  const [state, setState] = useState(INITIAL_STATE);
  const { 
    isModalObservation, optionsMunicipality, optionsTypeDocument,
    modalInvoiceElectronic
  } = state;

  useEffect(() => {
    setValues(billingData)
    dataSelectorsForm(billingData?.departmentResidenceRecipients?.value);
    if (invoiceButtonRef.current) {
      invoiceButtonRef.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dataSelectorsForm = async (idDepartment) => {
    await Promise.allSettled([
      getAllDocumentTypeService(),
      getMunicipalityBySiteService(idDepartment)
    ])
    .then((res)=>{
      let documentTypeList = [];
      let municipalityList = [];

      for (let index = 0; index < res.length; index++) {
        const elementResponse = res[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              documentTypeList = elementResponse?.value.map((documentType) => ({ label: documentType.name, value: documentType.id }))
              break;
            case 1:
              municipalityList = idDepartment ? elementResponse?.value.map((municipality) => ({ label: municipality.name, value: municipality.id })) : []
              break;;
            default:
              break;
          }
        }
      }
      setState({
        ...state,
        optionsTypeDocument: documentTypeList,
        optionsMunicipality: municipalityList
      })
    })
    .catch(() => {
      // TODO: Implement error alert with code error.
    })
  }

  const  {values, errors, touched, handleBlur, setValues} = useFormik({
    initialValues: {
      documentTypeCustomer: undefined,
      documentNumberCustomer: '',
      nameCustomer: '',
      lastNameCustomer: '',
      nameRecipients: '',
		  lastNameRecipients: '',
      domicileDescription: "",
      domicile: false,
      departmentResidenceRecipients: undefined,
      municipalityResidenceRecipients: undefined,
      costOrder: 0,
		  insuranceCost: 0,
      domicileCost: 0,
      contactEmailCustomer: ''
    },
    validationSchema: shippingsBillingDataSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  
  const onChange = (data, target) => {
    const value = data && data.target ? data.target.value || '' : data;
    const passengerData = { ...values, [target]: value }

    setValues(passengerData)
    setDataSalesStateReducer("shippingsSales",target, value)
  };

  const handledModal = () =>{
    setState({
      ...state,
      isModalObservation : true
    })
  }
  const handleCloseModal = () =>{
    setState({
      ...state,
      isModalObservation : false
    })
  }

  useEffect(() => {
    setStateSaleReducer('isError', !!Object.keys(errors).length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  const checkInvoice = () => {
    if (values.contactEmailCustomer) {
      onChange(!isElectronic, "isElectronic")
    }else{
      if (!isElectronic) {
        setState({
          ...state,
          modalInvoiceElectronic: {
            ...modalInvoiceElectronic,
            isState: true
          }
        })
      }
    }
  }

  const closeModalInvoiceElectronic = () => {
    onChange("","contactEmailCustomer")
    setState({
      ...state,
      modalInvoiceElectronic: {
        isElectronic: true,
        isState: false
      }
    })
  }

  const changeEmail = (email) => {
    const validation = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/
    onChange(email,"contactEmailCustomer")
    if (validation.test(email)) {
      setState({
        ...state,
        modalInvoiceElectronic: {
          ...modalInvoiceElectronic,
          isElectronic: false
        }
      })
    }
  }

  const acceptModalInvoiceElectronic = async() => {
    try {
      await updateClientService({contactEmail: values.contactEmailCustomer, id: idCustomer})
      setState({
        ...state,
        modalInvoiceElectronic: {
          isElectronic: true,
          isState: false
        }
      })
      setDataSalesStateReducer("shippingsSales","isElectronic", true)
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  return (
    <>
      <div className="grid-x">
        <div className="grid-x small-12 medium-12 justify-content-between">
          <label className="billing__title">
            {t("sales.shippings.billing.orderSummary")}
          </label>
          <div className="grid-x align-middle">
            <span className="billing-data__invoice-electronics">
              Crear esta factura como electrónica
            </span>
            <Checkbox
              checked={isElectronic}
              className="billing-data__checkbox"
              onChange={() => checkInvoice()}
              disabled={isDisabled}
            />
          </div>
        </div>
        <div className="small-12 medium-3">
          <label className="billing__label">{t("sales.passages.billingData.typeDocument")}</label>
          <Select
            disabled={isDisabled}
            value={values.documentTypeCustomer}
            className="billing__select"
            onChange={(e, event) => onChange(event, 'documentTypeCustomer')}
            onBlur={(value) => handleBlur('documentTypeCustomer')(value)}
            status={errors.documentTypeCustomer && touched.documentTypeCustomer ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsTypeDocument}
          />
        <span className="grid-x billing__errors">{touched.documentTypeCustomer? errors.documentTypeCustomer : null}</span>
        </div>
        <div className="small-12 medium-3">
          <label className="billing__label">{t("sales.passages.billingData.documentNumber")}</label>
          <Input
            disabled={isDisabled}
            value={values.documentNumberCustomer}
            onChange={(value) => onChange(value, 'documentNumberCustomer')}
            onBlur={(value) => handleBlur('documentNumberCustomer')(value)}
            status={errors.documentNumberCustomer && touched.documentNumberCustomer ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={t("sales.passages.billingData.documentNumber")}
            className="billing__input"
          />
          <span className="grid-x billing__errors">{touched.documentNumberCustomer? errors.documentNumberCustomer : null}</span>
        </div>
        <div className="small-12 medium-3">
          <label className="billing__label">{t("sales.passages.billingData.clientName")}</label>
          <Input
            disabled={isDisabled}
            value={values.nameCustomer}
            onChange={(value) => onChange(value, 'nameCustomer')}
            onBlur={(value) => handleBlur('nameCustomer')(value)}
            status={errors.nameCustomer && touched.nameCustomer ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={t("sales.passages.billingData.clientName")}
            className="billing__input"
          />
        <span className="grid-x billing__errors">{touched.nameCustomer? errors.nameCustomer : null}</span>
        </div>
        <div className="small-12 medium-3">
          <label className="billing__label">{t("sales.passages.billingData.clientLastName")}</label>
          <Input
            placeholder={t("sales.passages.billingData.clientLastName")}
            disabled={isDisabled}
            value={values.lastNameCustomer}
            onChange={(value) => onChange(value, 'lastNameCustomer')}
            onBlur={(value) => handleBlur('lastNameCustomer')(value)}
            status={errors.lastNameCustomer && touched.lastNameCustomer ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            className="billing__input"
          />
          <span className="grid-x billing__errors">{touched.lastNameCustomer? errors.lastNameCustomer : null}</span>
        </div>
      </div>
      <div className="billing__line" />
      <div className="grid-x">
        <label className="small-7 medium-12 billing__title billing__title--mod">
          {t("sales.shippings.billing.orderingData")}
        </label>
        <div className="small-6 medium-6">
          <label className="billing__label">{t("sales.shippings.billing.nameRecipient")}</label>
          <Input
            disabled={isDisabled}
            value={values.nameRecipients}
            onChange={(value) => onChange(value, 'nameRecipients')}
            onBlur={(value) => handleBlur('nameRecipients')(value)}
            status={errors.nameRecipients && touched.nameRecipients ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={t("sales.shippings.billing.nameRecipient")}
            className="billing__input"
          />
        <span className="grid-x billing__errors">{touched.nameRecipients? errors.nameRecipients : null}</span>
        </div>
        <div className="small-6 medium-6 grid-x justify-content-end">
          <label className="billing__label billing__box">{t("sales.shippings.billing.lastNameRecipient")}</label>
          <Input
            disabled={isDisabled}
            value={values.lastNameRecipients}
            onChange={(value) => onChange(value, 'lastNameRecipients')}
            onBlur={(value) => handleBlur('lastNameRecipients')(value)}
            status={errors.lastNameRecipients && touched.lastNameRecipients ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={t("sales.shippings.billing.lastNameRecipient")}
            className="billing__input"
          />
        <span className="grid-x billing__errors">{touched.lastNameRecipients? errors.lastNameRecipients : null}</span>
        </div>
        <div className="small-6 medium-6">
          <label className="billing__label">{t("sales.shippings.billing.destinationAddress")}</label>
          <Input
            disabled={isDisabled || values?.domicile === false ? true : false}
            value={values.domicileDescription }
            onChange={(value) => onChange(value, 'domicileDescription')}
            onBlur={(value) => handleBlur('domicileDescription')(value)}
            status={errors.domicileDescription && touched.domicileDescription ? "error" : ""}
            allowClear={{
              clearIcon: (
                <img
                  src={Assets.SharedIcons.icon_square_close}
                  alt="icon_square_close"
                />
              ),
            }}
            placeholder={values?.domicile === false ? "Se recoge encomienda en la oficina" : t("sales.shippings.billing.destinationAddress")}
            className="billing__input"
          />
        <span className="grid-x billing__errors">{touched.domicileDescription? errors.domicileDescription : null}</span>
        </div>
        <div className="small-6 medium-6 grid-x justify-content-end">
          <label className="billing__label billing__box">{t("sales.shippings.billing.destinationMunicipality")}</label>
          <Select
            disabled={isDisabled}
            value={values.municipalityResidenceRecipients}
            className="billing__select"
            onChange={(e, event) => onChange(event, 'municipalityResidenceRecipients')}
            onBlur={(value) => handleBlur('municipalityResidenceRecipients')(value)}
            status={errors.municipalityResidenceRecipients && touched.municipalityResidenceRecipients ? "error" : ""}
            suffixIcon={
              <img
                src={Assets.SharedIcons.icon_down_arrow}
                alt="icon_down_arrow"
              />
            }
            options={optionsMunicipality}
          />
        <span className="grid-x billing__errors">{touched.municipalityResidenceRecipients? errors.municipalityResidenceRecipients : null}</span>
        </div>
      </div>
      <div className="billing__line" />
      <div className="grid-x">
        <label className="small-6 billing__title billing__title--mod">
          {t("sales.passages.billingData.purchaseData")}
        </label>
        <div className="small-6 grid-x billing__title--mod justify-content-end">
          <button
            className="grid-x"
            onClick={()=> handledModal()}
            disabled={!isDisabled}
          >
              <label className="billing__comments__label">{t("sales.passages.billingData.remarks")}</label>
              <img
                src={isDisabled ? Assets.SalesIcons.icon_comments : Assets.SalesIcons.icon_comments_disabled}
                alt="icon_comments"
                className="billing__comments"
              />
          </button>
        </div>
        <div className="small-4 medium-4">
          <label className="billing__label">{t("sales.shippings.billing.costOrder")}</label>
          <Input
            className="small-10 medium-11 billing__input__disabled"
            disabled
            value={`$${values.costOrder?.toLocaleString()}`}
          />
        </div>
        <div className="small-4 medium-4">
          <label className="billing__label">Porte</label>
          <Input
            className="small-10 medium-11 billing__input__disabled"
            disabled
            value={`$${values.quantity?.toLocaleString()}`}
          />
        </div>
        <div className="small-4 medium-4 grid-x ">
          <label className="small-11 billing__label">{t("sales.shippings.billing.costInsurance")}</label>
          <Input
            className="billing__input__disabled"
            disabled
            value={`$${values.insuranceCost?.toLocaleString()}`}
          />
        </div>
        {values.domicile === true &&
          <div className="small-4 medium-4">
            <label className="billing__label">Domicilio</label>
            <Input
              className="small-10 medium-11 billing__input__disabled"
              disabled
              value={`$${values.domicileCost?.toLocaleString()}`}
            />
          </div>
        }
        <div className="small-4 medium-4">
          <label className="billing__label">Manifiesto de carga</label>
          <Input
            className="small-10 medium-11 billing__input__disabled"
            disabled
            value={`$${values.cargoManifest?.toLocaleString()}`}
          />
        </div>
        <div className="small-4 medium-4 grid-x ">
          <label className="small-11 billing__label">Total de la encomienda</label>
          <Input
            className="billing__input__disabled"
            disabled
            value={`$${(values.insuranceCost + values.costOrder + values.domicileCost + values.quantity + values.cargoManifest)?.toLocaleString()}`}
          />
        </div>
      </div>
      <>
          <ModalMainComponent
            width={478}
            title={t("sales.shippings.billing.remarks")}
            open={isModalObservation}
            onClose={()=> handleCloseModal()}
            footer={false}
            titleClass="billing__title-modal"
          >
            <ModalObservationsComponent onClose={()=> handleCloseModal()}/>
          </ModalMainComponent>
          { modalInvoiceElectronic?.isState &&
            <ModalMainComponent
              width={400}
              open={modalInvoiceElectronic?.isState}
              onClose={()=> closeModalInvoiceElectronic()}
              titleIcon={
                <div className="grid-x align-center-middle">
                  <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name'/>
                </div>
              }
              footer={[
                <button 
                  key="accept" 
                  className="sales-table__modal-electronic__button"
                  onClick={()=> acceptModalInvoiceElectronic()}
                  disabled={modalInvoiceElectronic?.isElectronic}
                >
                  <span className="sales-table__modal-electronic__button__label">
                    Crear factura electrónica
                  </span>
                </button>,
                <div className="sales-table__modal-electronic__close" key="cancel">
                  <button
                    className="sales-table__modal-electronic__close__label"
                    onClick={()=> closeModalInvoiceElectronic()}
                  >
                    Cancelar
                  </button>
                </div>
              ]}
            >
              <ModalElectronicInvoiceComponent
                email={values.contactEmailCustomer}
                changeEmail={(email)=>changeEmail(email)}
                isCreateInvoiceElectronic={modalInvoiceElectronic?.isElectronic}
              />
            </ModalMainComponent>
          }
      </>
    </>
  );
}

const mapStateToProps = ({ SalesReducer }) => {
  const {
    shippingsSales: {
      documentTypeCustomer,
      documentNumberCustomer,
      nameCustomer,
      lastNameCustomer,
      nameRecipients,
		  lastNameRecipients,
      domicileDescription,
      domicile,
      departmentResidenceRecipients,
      municipalityResidenceRecipients,
      costOrder,
      quantity,
      cargoManifest,
		  insuranceCost,
      declaredValue,
      isElectronic,
      contactEmailCustomer,
      idCustomer,
      domicileCost
    },
    isDisabled
  } = SalesReducer;
  return {
    documentTypeCustomer,
    documentNumberCustomer,
    nameCustomer,
    lastNameCustomer,
    nameRecipients,
		lastNameRecipients,
    domicileDescription,
    domicile,
    departmentResidenceRecipients,
    municipalityResidenceRecipients,
    costOrder,
    quantity,
    cargoManifest,
		insuranceCost,
    isDisabled,
    declaredValue,
    isElectronic,
    contactEmailCustomer,
    idCustomer,
    domicileCost
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setStateSaleReducer
}

export default connect(mapStateToProps, mapStateToPropsActions)(BillingComponent);