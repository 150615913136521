// Constants - Endpoints
import SharedEndPoints from "./constants/shared.end-points"

// Infrastructure
import Http from "./infrastructure/http.infrastructure"

// Models - DTO
import DocumentTypeDTO from "../models/document-type/dto/document-type.dto"
import DepartmentDTO from "../models/department/dto/department.dto"
import MunicipalityDTO from "../models/municipality/dto/municipality.dto"
import IndicativeNumberDTO from "../models/indicative-number/dto/indicative-number.dto"
import PaymentMethodDTO from "../models/payment-method/dto/payment-method.dto"
import UserDTO from "../models/user/dto/user.dto"
import { mapperToDocumentTypeModel } from "../models/document-type/dto/document-type.dto.dao"
import { mapperToDepartmentModel } from "../models/department/dto/department.dto.dao"
import { mapperToMunicipalityModel } from "../models/municipality/dto/municipality.dto.dao"
import { mapperToIndicativeNumberModel } from "../models/indicative-number/dto/indicative-number.dto.dao"
import { mapperToPaymentMethodModel } from "../models/payment-method/dto/payment-method.dto.dao"
import { extractToClientUserDTO, mapperToUserModel } from "../models/user/dto/user.dto.dao"

// Services
import { getTokenService } from "./authentication.services"

/** 
* Init Block API Services 
**/
export async function getAllDocumentTypeService() {
    try {
        const { data: responseGetDocumentType, error } = await Http.get(SharedEndPoints.GET_DOCUMENT_TYPE)
        if (responseGetDocumentType && responseGetDocumentType.status) {
            const documentTypeDTOList = responseGetDocumentType.data
            let documentTypeList = []

            documentTypeDTOList.forEach(documentTypeDTO => {
                const documentType = mapperToDocumentTypeModel(new DocumentTypeDTO(documentTypeDTO.id, documentTypeDTO.name))
                documentTypeList.push(documentType)
            })
            return documentTypeList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllDepartmentService() {
    try {
        const { data: responseGetDepartment, error } = await Http.get(SharedEndPoints.GET_DEPARTMENT)
        if (responseGetDepartment && responseGetDepartment.status) {
            const departmentDTOList = responseGetDepartment.data
            let departmentList = []

            departmentDTOList.forEach(departmentDTO => {
                const department = mapperToDepartmentModel(new DepartmentDTO(departmentDTO.id, departmentDTO.name))
                departmentList.push(department)
            })
            return departmentList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getDepartmentByNameService() {
    try {
        const { data: responseGetDepartmentByName, error } = await Http.get(SharedEndPoints.GET_DEPARTMENT)
        if (responseGetDepartmentByName && responseGetDepartmentByName.status) {
            const departmentDTOList = responseGetDepartmentByName.data
            let departments = []

            departmentDTOList.forEach(departmentDTO => {
                const department = mapperToDepartmentModel(new DepartmentDTO(departmentDTO.id, departmentDTO.name))
                departments.push(department)
            })
            

            const departmentName = "Norte de Santander"
            const department = departments.find(dept => dept.name.toLowerCase() === departmentName.toLowerCase())
            return department
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllMunicipalityService(idDepartment) {
    try {
        const { data: responseGetMunicipality, error } = await Http.get(SharedEndPoints.GET_MUNICIPALITY,{ department: idDepartment })  
        if (responseGetMunicipality && responseGetMunicipality.status) {
            const municipalityDTOList = responseGetMunicipality.data
            let municipalityList = []

            municipalityDTOList.forEach(municipalityDTO => {
                const municipality = mapperToMunicipalityModel(new MunicipalityDTO(municipalityDTO.id, municipalityDTO.name))
                municipalityList.push(municipality)
            })
            return municipalityList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getMunicipalityBySiteService(idDepartment) {
    let token = getTokenService()
    try{
        const { data: responseGetMunicipalityBySite, error } = await Http.get(SharedEndPoints.GET_SITE,{idDepartment: idDepartment}, token)
        if(responseGetMunicipalityBySite && responseGetMunicipalityBySite.status){
            const municipalityDTOList = responseGetMunicipalityBySite.data
            let municipalityList = []

            municipalityDTOList.forEach(municipalityDTO => {
                const municipality = mapperToMunicipalityModel(new MunicipalityDTO(municipalityDTO.id, municipalityDTO.name))
                municipalityList.push(municipality)
            })
            return municipalityList
        }else{
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getMunicipalityTurnBySiteService(idDepartment) {
    let token = getTokenService()
    try{
        const { data: responseGetMunicipalityTurnBySite, error } = await Http.get(SharedEndPoints.GET_SITE,{idDepartment: idDepartment}, token)
       
        if(responseGetMunicipalityTurnBySite && responseGetMunicipalityTurnBySite.status){
            const municipalityDTOList = responseGetMunicipalityTurnBySite.data
            let municipalityList = []

            municipalityDTOList.forEach(municipalityDTO => {
                const municipality = mapperToMunicipalityModel(new MunicipalityDTO(municipalityDTO.id, municipalityDTO.name))
                municipalityList.push(municipality)
            })
            return municipalityList.length>0 ?municipalityList[0] : null
        }else{
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllIndicativeNumberService() {
    try {
        const { data: responseGetIndicativeNumber, error } = await Http.get(SharedEndPoints.GET_INDICATIVE_NUMBER)
        if (responseGetIndicativeNumber && responseGetIndicativeNumber.status) {
            const indicativeNumberDTOList = responseGetIndicativeNumber.data
            let indicativeNumberList = []

            indicativeNumberDTOList.forEach(indicativeNumberDTO => {
                const indicativeNumber = mapperToIndicativeNumberModel(new IndicativeNumberDTO(indicativeNumberDTO.id, indicativeNumberDTO.number))
                indicativeNumberList.push(indicativeNumber)
            })
            return indicativeNumberList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getAllPaymentMethodService() {
    try {
        const { data: responseGetPaymentMethod, error } = await Http.get(SharedEndPoints.GET_PAYMENT_METHOD)
        if (responseGetPaymentMethod && responseGetPaymentMethod.status) {
            const paymentMethodDTOList = responseGetPaymentMethod.data
            let paymentMethodList = []

            paymentMethodDTOList.forEach(paymentMethodDTO => {
                const paymentMethod = mapperToPaymentMethodModel(new PaymentMethodDTO(paymentMethodDTO.id, paymentMethodDTO.name))
                paymentMethodList.push(paymentMethod)
            })
            return paymentMethodList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getFilterUsersService(filterValue) {
    try {
        const { data: responseGetFilterUsers, error } = await Http.get(SharedEndPoints.GET_FILTER_USERS,{filterValue})
        
        if (responseGetFilterUsers && responseGetFilterUsers.status) {
            const userDTOList = responseGetFilterUsers.data
            let userList = []

            userDTOList.forEach(userDTOElement => {
                
                let userDTO = new UserDTO(
                    userDTOElement.id,userDTOElement.name,userDTOElement.lastName,
                    userDTOElement.numberDocument,userDTOElement.numberPhone,
                    userDTOElement.numberPhoneWhatsapp,userDTOElement.address,
                    userDTOElement.email
                )

                if(userDTOElement.municipality){
                    const municipality = mapperToMunicipalityModel(
                        new MunicipalityDTO(
                            userDTOElement.municipality.id,
                            userDTOElement.municipality.name
                        )
                    )
                    userDTO.municipality = municipality
                }

                if(userDTOElement.documentType){
                    const documentType = mapperToDocumentTypeModel(
                        new DocumentTypeDTO(
                            userDTOElement.documentType.id,
                            userDTOElement.documentType.name
                        )
                    )
                    userDTO.documentType = documentType
                }

                if(userDTOElement.indicativeNumber){
                    const indicativeNumber = mapperToIndicativeNumberModel(
                        new IndicativeNumberDTO(
                            userDTOElement.indicativeNumber.id,
                            userDTOElement.indicativeNumber.number
                        )
                    )
                    userDTO.indicativeNumber = indicativeNumber
                }  

                if(userDTOElement.indicativeNumberWhatsApp){
                    const indicativeNumberWhatsApp = mapperToIndicativeNumberModel(
                        new IndicativeNumberDTO(
                            userDTOElement.indicativeNumberWhatsApp.id,
                            userDTOElement.indicativeNumberWhatsApp.number
                        )
                    )
                    userDTO.indicativeNumberWhatsApp = indicativeNumberWhatsApp
                }

                if(userDTOElement.department){
                    const department = mapperToDepartmentModel(
                        new DepartmentDTO(
                            userDTOElement.department.id,
                            userDTOElement.department.name
                        )
                    )
                    userDTO.department = department
                } 
                let user = mapperToUserModel(userDTO)

                user.label = user?.name + " " + user?.documentNumber;
                user.value = user?.label
                user.key = user?.id
                userList.push(user)
            })

            return userList
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createClientService(data) {
    const client = extractToClientUserDTO(data)
    try {
        const { data: responseCreateClient, error } = await Http.post(SharedEndPoints.CREATE_CLIENT,client)
        if (responseCreateClient && responseCreateClient.status) {
            return responseCreateClient.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function updateClientService(data) {
    let token = getTokenService()
    const client = extractToClientUserDTO(data)
    try {
        const { data: responseUpdateClient, error } = await Http.put(SharedEndPoints.UPDATE_CLIENT,client,token)
        if (responseUpdateClient && responseUpdateClient.status) {
            return responseUpdateClient
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createInvoicePassengerService(data) {
    let token = getTokenService()
    try {
        const { data: responseCreateInvoice, error } = await Http.post(SharedEndPoints.CREATE_INVOICE,data,token)
        if (responseCreateInvoice && responseCreateInvoice.status) {
            return responseCreateInvoice?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function createInvoiceObservationService(data) {
    let token = getTokenService()
    try {
        const { data: responseObservationCreated, error } = await Http.post(SharedEndPoints.CREATE_INVOICE_OBSERVATION,data,token)
        if (responseObservationCreated && responseObservationCreated.status) {
            return responseObservationCreated.status
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getObservationsByIdInvoiceService(idInvoice) {
    let token = getTokenService()
    const urlWithParams = SharedEndPoints.GET_OBSERVATIONS_BY_INVOICE.replace(":id",idInvoice)
    try {
        const { data: responseObservationCreated, error } = await Http.get(urlWithParams,null,token)
        if (responseObservationCreated && responseObservationCreated.status) {
            const commentsResponse = responseObservationCreated.data

            let comments = []
            commentsResponse.forEach((element) => { 
                comments.push({
                    id: element.id,
                    value: element.description,
                    date: element.date,
                    user: `${element.name} ${element.lastName}`
                })
            })
            
            return comments.reverse()
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}

export async function getInvoiceService(idInvoice) {
    let token = getTokenService()
    const urlWithParams = SharedEndPoints.GET_INVOICE.replace(":idInvoice",idInvoice)
    try {
        const { data: responseGetInvoice, error } = await Http.get(urlWithParams,null,token)
        if (responseGetInvoice && responseGetInvoice.status) {
            const invoiceData = {...responseGetInvoice?.data, 
                unitCost: (responseGetInvoice?.data?.extraValue ? ((responseGetInvoice?.data?.price - responseGetInvoice?.data?.extraValue)/ responseGetInvoice?.data?.tickets?.length)  : (responseGetInvoice?.data?.price / responseGetInvoice?.data?.tickets?.length)),
                location:{
                    name: responseGetInvoice?.data?.siteArrive?.name,
                    id: responseGetInvoice?.data?.siteArrive?.id
                  },
                dataElectronic: {
                    provider: {
                        name: responseGetInvoice?.data?.dataElectronic?.provider?.name || "",
                        nit: responseGetInvoice?.data?.dataElectronic?.provider?.nit || "",
                        url: responseGetInvoice?.data?.dataElectronic?.provider?.url || "",
                    },
                    cufe: responseGetInvoice?.data?.dataElectronic?.cufe || "",
                    dateValidation: responseGetInvoice?.data?.dataElectronic?.dateValidation || "",
                }
            }
            return invoiceData
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
}
/**
* End Block API Services 
**/