import * as userSettingsTypes from "./user-settings.types";

const INITIAL_STATE = {
    isError: false,
    isDisabled: false,
    originData: {
        id: '',
        nameCreateUser: '',
        lastNameCreateUser: '',
        typeDocumentCreateUser: undefined,
        numberDocumentCreateUser: '',
        dateOfBirthCreateUser: undefined,
        indicativeCreateUser: undefined,
        telephoneCreateUser: '',
        emailCreateUser: '',
        campusCreateUser: undefined,
        newPasswordCreateUser: '',
        confirmNewPasswordCreateUser: '',
        state: 1,
    },
    newUserSettings: {
        id: '',
        nameCreateUser: '',
        lastNameCreateUser: '',
        typeDocumentCreateUser: undefined,
        numberDocumentCreateUser: '',
        dateOfBirthCreateUser: undefined,
        indicativeCreateUser: undefined,
        telephoneCreateUser: '',
        emailCreateUser: '',
        campusCreateUser: undefined,
        newPasswordCreateUser: '',
        confirmNewPasswordCreateUser: '',
        state: 1,
    },
    permitsNewUser: {
        checkingYes: false,
        originCheckedManifest: false,
        typeUser: undefined,
        typeUserOrigin: undefined,
        optionsPermitsNewUser: []
    },
    
};

const UserSettingsReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        
        case userSettingsTypes.SET_DATA_NEW_USER_STATE:
            return {
                ...state,
                [action.payload.key]:{
                    ...state[action.payload.key],
                    [action.payload.prop]: action.payload.value
                }
            };
        
        case userSettingsTypes.SET_NEW_USER:
            return{
                ...state,
                [action.payload.prop]: action.payload.value
            };
        
        case userSettingsTypes.RESET_DATA_NEW_USER:
            return {
                ...state,
                newUserSettings: INITIAL_STATE.newUserSettings,
                permitsNewUser: INITIAL_STATE.permitsNewUser
            };

        case userSettingsTypes.SET_STATE_USER_SETTINGS:
            const { newUserSettings, permitsNewUser } = action.payload; 
            return {
                ...state,
                originData: newUserSettings,
                newUserSettings: newUserSettings,
                permitsNewUser: permitsNewUser
            };
        default:
            return state;
    }
};

export default UserSettingsReducer;