import { StyleSheet } from "@react-pdf/renderer";

export const PdfShippingsStyles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFFFFF',
      width:"100%"
    },
    section: {
      width: "100%",
      display: "flex",
      alignItems: "center"
    },
    logo:{
        width: "152px",
        height: "32px",
        marginTop: "8px"
    },
    subTitle:{
        fontSize: "9px",
        fontWeight: "normal",
        width:"152px",
        paddingTop: "8px",
        textAlign:"center",
        color: "#2C2C2C"
    },
    titleNit:{
        fontSize: "10px",
        width:"220px",
        marginTop: "5px",
        textAlign: "center",
        fontFamily: "Helvetica-Bold",
    },
    content:{
        width: "100%",
        height: "100%"
    },
    box:{
        width: "100%",
        paddingHorizontal: "25px",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    boxSection:{
        width: "50%"
    },
    title:{
        fontSize: "12px",
        marginTop: "3px",
        fontFamily: "Helvetica-Bold"
    },
    data:{
        fontSize: "12px",
        color: "#2C2C2C"
    },
    boxUnit:{
        width: "100%",
    },
    line:{
        height: "1px",
        width:"100%",
        backgroundColor: "#C7C7C7"
    },
    lineSignature:{
        height: "1px",
        width:"100%",
        backgroundColor: "#000000"
    },
    lineSeparator:{
        marginTop: "8px"
    },
    titleSecondary:{
        color: "#2C2C2C",
        fontSize: "12px"
    },
    dataRight:{
        textAlign: "right",
        marginTop: "2px"
    },
    dataLeft:{
        marginTop: "2px"
    },
    dataSeparator:{
        paddingRight: "10px"
    },
    dataUser:{
        flexWrap: "wrap",
        flexDirection: "row",
    },
    passenger:{
        width: "70%"
    },
    passengerText:{
        paddingLeft: "15px"
    },
    booth:{
        width: "30%",
        display: "flex",
        justifyContent: "space-between"
    },
    passengerLabel:{
        fontSize: "12px",
        color: "#000000"
    },
    dataText:{
        fontSize: "12px",
        color: "#707070"
    },
    dataContent:{
        marginTop: "5px"
    },
    lineBottom:{
        marginBottom: "5px"
    },
    footer:{
        marginTop: "25px",
        fontSize: "10px",
        textAlign: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%"
    },
    separatorRight:{
        paddingRight: "10px"
    },
    separatorLeft:{
        paddingLeft:"10px"
    }
});