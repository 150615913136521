//Libraries
import React from 'react'
import { Route, Routes } from 'react-router-dom'

// Views - Pages
import MySpreadsheetsPage from '../pages/my-spreadsheets/my-spreadsheets/my-spreadsheets.page'
import MyOrdersPage from '../pages/my-spreadsheets/my-orders/my-orders.page'

const MySpreadsheetsRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MySpreadsheetsPage />} />
        <Route path="/myOrders" element={<MyOrdersPage />} />
      </Routes>
    </>
  )
}

export default MySpreadsheetsRoutes