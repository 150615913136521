//Assets
import { Assets } from '../../../../../assets';

//Libraries
import React from 'react';

//Styles
import './structures-of-vehicles.component.scss';

const StructuresOfVehiclesComponent = ({ seatMap }) => {

    return (
        <div className='grid-x structures-of-vehicles__container'>
            {seatMap.map((row, indexRow) => 
                <div
                    key={indexRow}
                    className={`grid-y ${indexRow === 0 ? 'structures-of-vehicles__row--driver' : 'structures-of-vehicles__row'} flex-column-reverse`}
                >
                    {row.map((seat, indexSeat) => (
                        (seat)
                            ?
                                (<div
                                    key={`${indexRow}${indexSeat}`}
                                    className={indexRow === 0 ? 'structures-of-vehicles__row__driver-seat' : `structures-of-vehicles__row__seat`}
                                    style={{ display: "flex" }}
                                >
                                    <img
                                        alt="icon_available_seat"
                                        src={Assets.SalesIcons.icon_available_seat}
                                    />
                                </div>)
                            :
                                (<div
                                    key={`${indexRow}${indexSeat}`}
                                    className={indexRow === 0 ? 'structures-of-vehicles__row__driver-seat' : `structures-of-vehicles__row__seat`}
                                >
                                    {(indexRow === 0 && indexSeat === 0)
                                        ? (<img src={Assets.SalesIcons.icon_driver_seat} alt="icon_available_seat" />)
                                        : null
                                    }
                                </div>)
                    ))}
                </div>
            )}
        </div>
    )
}

export default StructuresOfVehiclesComponent;