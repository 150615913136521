import { StyleSheet } from "@react-pdf/renderer";

export const PdfDetailsStyles =(props) => StyleSheet.create({

    text__bold:{
        fontSize: props || "8px",
        fontWeight: 'bold',
        fontFamily: "Helvetica-Bold"
    },

    text__right:{
        textAlign: "right"
    },

    text:{
        fontSize: props || "8px",
        fontFamily: "Helvetica"
    },

    table__header:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: "10px",
        backgroundColor: "#E4E4E4",
        height: "25px",
        alignItems: "center",
        marginHorizontal: "10px",
        marginTop: props || "0px",
    },

    page:{
        backgroundColor: "white",
        padding: "20px",
        paddingBottom: "60px"
    },

    content:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "10px",
        alignItems: "center"
    },

    logo:{
        width: "150px"
    },

    separator:{
        height: "1px",
        backgroundColor: "#D6D6D6",
        width: "100%"
    },

    header:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "10px",
        paddingHorizontal: "15px"
    },

    header__item:{
        width: `${props || 0}%`
    },

    text__centered:{
        textAlign: "center",
    },

    separator__table:{
        width: "100%",
        height: "1px",
        backgroundColor: "#D6D6D6",
        marginTop: "10px",
        marginBottom: "15px"
    },

    item__table:{
        width: "20%"
    },

    item__table__content:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "1px",
        paddingHorizontal: "15px",
        marginHorizontal: "15px",
        paddingVertical: "5px"
    },

    item__table__separator:{
        height: "1px",
        backgroundColor: "#D6D6D6",
        marginHorizontal: "15px",
    },

    item__space:{
        marginTop: "5px",
    },

    total__content:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        paddingHorizontal: "15px",
        marginHorizontal: "15px",
        marginVertical: "5px",
    },

    total:{
        marginRight: "30px",
    },

    footer:{
        position: "absolute",
        bottom: "0px",
        height: "50px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "10px",
        paddingRight: "10px",
        marginHorizontal: "15px",
        width: "95%",
        paddingTop: "10px",
    },

    signature:{
        display: "flex",
        flexDirection: "row"
    },

    signature__line:{
        width: "60%",
        height: "1px",
        backgroundColor: "#D6D6D6",
        marginHorizontal: "15px",
        marginTop: "10px",
    },

    subtotal:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "5px",
    },

    details:{
        width: "40%",
        border: "1px solid #D6D6D6",
        borderRadius: "20px",
        height: "105px",
        marginTop: "10px",
    },

    details__content:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "10px",
        paddingHorizontal: "15px"
    },

    details__separator:{
        height: "1px",
        backgroundColor: "#D6D6D6",
        marginTop: "10px"
    }

});