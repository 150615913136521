
export const statusOrders = [
    {
      title:"Recibida para despacho",
      description:"28/12/2022 - 13:30:45",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    },
    {
      title:"En camino al destino",
      description:"28/12/2022 - 13:30:45",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    },
    {
      title:"En la ciudad de destino.",
      description:"28/12/2022 - 13:30:45",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    },
    {
      title:"En ruta de entrega",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    },
    {
      title:"Entregado al destinatario",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    }
  ]

export const statusTurns = [
    {
      title:"Giro recibido para despacho",
      description:"28/12/2022 - 13:30:45",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    },
    {
      title:"Giro despachado",
      description:"28/12/2022 - 15:30:07",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    },
    {
      title:"Giro en ventanilla disponible para entrega",
      description:"28/12/2022 - 13:30:45",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    },
    {
      title:"Giro reclamado por el Cliente",
      icon: 
          <div
            className="grid-x align-center-middle order-guide__steps__circle__content"
          >
              <div className='order-guide__steps__circle'></div>
          </div>
    }
  ]