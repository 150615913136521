//Component
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component';
import SuccessToastComponent from '../../../shared/components/toast/success-toast/success-toast.component';

// Constants
import AppConst from '../../../core/constants/app.const';
import { SettingsErrorsConst } from '../../../core/constants/errors/alerts-app/seettings.errors.const';
import AlertConst from '../../../core/constants/alerts.const';

//Libraries
import React, { useState } from 'react';
import { Input } from 'antd';
import { useEffect } from 'react';

//Styles
import "./constants-file.page.scss";

//Services
import { getAllNumberConstantService, getAllStringConstantService, updateNumberConstantService, updateStringConstantService } from '../../../services/settings.services';


const ConstantsFilePage = () => {

    const INITIAL_STATE = {
        listNumConstants: [],
        listStrConstants:[],
        listNumConstantsCopy: []  
    }

    const [state, setState] = useState(INITIAL_STATE)
    const {
        listNumConstants, listStrConstants,listNumConstantsCopy
    } = state;

    useEffect(() => {
        servicesConstants()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    const servicesConstants = async () => {
        try {
            const results = await Promise.allSettled([
                getAllNumberConstantService(),
                getAllStringConstantService()
            ]);
        
            let NumbersDataList = [];
            let StringDataList = [];
      
            for (let index = 0; index < results.length; index++) {
              const elementResponse = results[index];
        
              if (elementResponse.status === AppConst.FULFILLED) {
                switch (index) {
                  case 0:
                    NumbersDataList = elementResponse?.value
                    break;
                  case 1:
                    StringDataList = elementResponse?.value
                    break;
                  default:
                    break;
                }
              }
            }
            setState({
              ...state,
              listNumConstants: NumbersDataList,
              listStrConstants: StringDataList,
              listNumConstantsCopy: NumbersDataList
            });
        } catch (err){
            ErrorToastComponent({
                title: SettingsErrorsConst.constants[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
        }    
      }

    const dataInputChange = (value, index) => {
        let numericValue = Number(value.replace(/,/g, ""));
        if (!isNaN (numericValue)) {
        let newData = JSON.parse(JSON.stringify(listNumConstants))
        if (numericValue !== listNumConstantsCopy[index].value) {
            newData[index].activeButton = false;
        } else { 
            newData[index].activeButton = true; 
        }
        newData[index].value = numericValue
        setState((prevState) => ({
            ...prevState,
            listNumConstants: newData
        }));
    }}

    const stringDataChange = (value, index) => {
        let newData = JSON.parse(JSON.stringify(listStrConstants))
        newData[index].activeButton = true;

        newData[index].value = value
        setState((prevState) => ({
            ...prevState,
            listStrConstants: newData
        }));
    }
    
    const updateNumberConstant = async (index ) => {
        try {
            const code = listNumConstants[index].code;
            const value = listNumConstants[index].value;
            await updateNumberConstantService(code, value);
            servicesConstants()

            SuccessToastComponent({
                html: 
                `<span>${"Se ha actualizado satisfactoriamente"}
                  <a class="create-user__text-alert"</a>
                </span>`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.constants[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
        }
    };

    const updateStringConstant = async (index ) => {
        try {
            const code = listStrConstants[index].code;
            const value = listStrConstants[index].value;
            await updateStringConstantService(code, value);
            servicesConstants()

            SuccessToastComponent({
                html: 
                `<span>${"Se ha actualizado satisfactoriamente"}
                  <a class="create-user__text-alert"</a>
                </span>`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
        } catch (err) {
            ErrorToastComponent({
                title: SettingsErrorsConst.constants[err.code] || `${SettingsErrorsConst.default} (${err.code || 'UDFND'})`,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            });
        }
    };

    const uniqueNameGroups = new Set();
    const StringNameGroups = new Set();
    return (
        <>
            <div className='grid-x align-center'>
                <div className='small-9 constants-file__general'>

                    <div className='constants-file__box constants-file__box--mod grid-x'>
                        <div className='grid-x small-12'>
                            <span className='constants-file__subtitle small-4'>Descripción</span>
                            <span className='constants-file__subtitle small-4'>Valor</span>
                        </div>

                        <div className='grid-x constants-file__content' >
 
                            {listNumConstants.map((item, index) => {
                                const isUniquegroupName = !uniqueNameGroups.has(item?.groupType);
                            
                                if (isUniquegroupName) {
                                    uniqueNameGroups.add(item?.groupType);
                                }
                                return (
                                    <div key={index} className='grid-x small-12 constants-file__container constants-file__line'>
                                        {isUniquegroupName && (
                                            <div className='small-12 constants-file__text'>
                                                <span>{item?.groupName}</span>
                                            </div>
                                        )}
                                        <div className='grid-x small-4'>
                                            {item?.description}
                                        </div>
                                        <div className='grid-x small-1 justify-content-end constants-file__content-base-unit'>
                                            <span className='constants-file__content-base-unit__text'>
                                                {item?.baseUnit}
                                            </span>
                                        </div>
                                        <div className='small-5 constants-file__input'>
                                            <Input
                                                value={item?.value?.toLocaleString()}

                                                allowClear={{
                                                    clearIcon: (
                                                        <button
                                                            onClick={() => null}
                                                        >
                                                        </button>
                                                    )
                                                }}
                                                className="constants-file__input__content"
                                                placeholder="Escribe el valor"
                                                onChange={(e) => {
                                                    dataInputChange(e.target.value, index)}}
                                            />
                                        </div>
                                        {!item.activeButton &&
                                        <div className='small-2 grid-x align-center-middle'>
                                            <button className="constants-file__button__actions"
                                                onClick={() => updateNumberConstant(index)}
                                            >
                                                <span>Actualizar</span>
                                            </button>
                                        </div>
                                        }
                                    </div>
                                );
                            })}

                            {listStrConstants.map((item, index) => {
                                const isUniquegroupName = !StringNameGroups.has(item?.groupType);

                                if (isUniquegroupName) {
                                    StringNameGroups.add(item?.groupType);
                                }

                                return (
                                    <div key={index} className='grid-x small-12 constants-file__container constants-file__line'>
                                        {isUniquegroupName && (
                                            <div className='small-12 constants-file__text'>
                                                <span>{item?.groupName}</span>
                                            </div>
                                        )}
                                        <div className='small-4'>
                                            {item?.description}
                                        </div>
                                        <div className='grid-x small-1 justify-content-end constants-file__content-base-unit'>
                                            <span className='constants-file__content-base-unit__text'>
                                                {item?.baseUnit}
                                            </span>
                                        </div>
                                        <div className='small-5 constants-file__input'>
                                            <Input
                                                value={item?.value}
                                                allowClear={{
                                                    clearIcon: (
                                                        <button
                                                            onClick={() => null}
                                                        >
                                                        </button>
                                                    )
                                                }}
                                                className="constants-file__input__content"
                                                placeholder="Escribe el valor"
                                                onChange={(e) => {
                                                    stringDataChange(e.target.value, index)
                                                }}
                                            />
                                        </div>
                                       {item.activeButton &&
                                        <div className='small-2 grid-x align-center-middle'>
                                            <button className="constants-file__button__actions"
                                                onClick={() => updateStringConstant(index)}
                                            >
                                                <span>Actualizar</span>
                                            </button>
                                        </div>
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConstantsFilePage