// Model
import IndicativeNumberModel from "../model/indicative-number.model";
import IndicativeNumberDTO from "./indicative-number.dto";

export function mapperToIndicativeNumberModel(indicativeNumberDTO) {
    return new IndicativeNumberModel(indicativeNumberDTO.id, indicativeNumberDTO.number)
}

export function extractToIndicativeNumberDTO({label,value}){
    return new IndicativeNumberDTO(value,label) 
}