//Assets
import { Assets } from '../../../../../assets';

//Libraries
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from "dayjs";

//Styles
import "./boarding-tickets.component.scss"

const BoardingTicketsComponent = (props) => {

  const { 
    dataInvoice,
    ticketsSelected = () => {},
    ticketsChecked = () => {},
    selectedTickets
  } = props

  const [t] = useTranslation("translation")

  const selectTicket = (ticket) => {
    const selectedTicketsData = ticket
      ? selectedTickets?.some((t) => t?.id === ticket?.id)
        ? selectedTickets?.filter((t) => t?.id !== ticket?.id)
        : [...selectedTickets, ticket]
      : selectedTickets?.length === dataInvoice?.tickets?.length
      ? []
      : dataInvoice?.tickets;
    
    ticketsChecked(selectedTicketsData)
  };

  const printTickets = (ticket) => {
    ticketsSelected(ticket)
  }

  return (
    <>
        <div className="boarding-tickets__content grid-x">
            <div className="boarding-tickets__content__field grid-y small-6">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.invoiceNumber")}
              </span>
              <span>{`${dataInvoice?.codeSale}-${dataInvoice?.codePrefix}-${dataInvoice?.number}`}</span>
            </div>
            <div className="boarding-tickets__content__field grid-y small-6">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.dateIssued")}
              </span>
              <span>{dayjs(dataInvoice?.date).format('DD/MM/YYYY')}</span>
            </div>
            <div className="boarding-tickets__content__field grid-y small-12">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.client")}
              </span>
              <span>{`${dataInvoice?.invoiceClient?.name} ${dataInvoice?.invoiceClient?.lastName}`}</span>
            </div>
            <div className="boarding-tickets__content__field grid-y small-6">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.document")}
              </span>
              <span>{`${dataInvoice?.invoiceClient?.UserDocumentType?.name} ${dataInvoice?.invoiceClient?.numberDocument}`}</span>
            </div>
            <div className="boarding-tickets__content__field grid-y small-6">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.route")}
              </span>
              <span>{`${dataInvoice?.route?.municipalityDepart}-${dataInvoice?.route?.municipalityArrive}`}</span>
            </div>
            <div className="boarding-tickets__content__field grid-y small-6">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.exitDate")}
              </span>
              <span>{dayjs(dataInvoice?.travelSeat?.date).format('DD/MM/YYYY')}</span>
            </div>
            <div className="boarding-tickets__content__field grid-y small-6">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.departureTime")}
              </span>
              <span>{dataInvoice?.travelSeat?.time}</span>
            </div>
            <div className="boarding-tickets__content__field grid-y small-6">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.vehicle")}
              </span>
              <span>{dataInvoice?.vehicle?.model}</span>
            </div>
            <div className="boarding-tickets__content__field grid-y small-6">
              <span className="boarding-tickets__content__field__label">
                {t("sales.passages.pdfPassages.plate")}
              </span>
              <span>{`${dataInvoice?.vehicle?.plate} ${dataInvoice?.vehicle?.vehicleMunicipality?.name}`}</span>
            </div>
          </div>
          <div className="boarding-tickets__passages">
            <button
              onClick={() => selectTicket()}
              className="boarding-tickets__passages__select grid-x align-center-middle"
            >
              <div
                className={
                  selectedTickets?.length === dataInvoice?.tickets?.length
                    ? "boarding-tickets__passages__select__status--active"
                    : "boarding-tickets__passages__select__status"
                }
              />
              <span className="boarding-tickets__passages__select__text">
                {t("sales.passages.pdfPassages.tickets.selectAll")}
              </span>
            </button>
            {dataInvoice?.tickets?.map((ticket, index) => (
              <div
                key={index}
                className={
                  selectedTickets.some(
                    (t) => t?.id === ticket?.id
                  )
                    ? "boarding-tickets__passages__ticket--active"
                    : "boarding-tickets__passages__ticket"
                }
              >
                <div className="boarding-tickets__passages__ticket__header grid-x align-middle">
                  <button
                    onClick={() => selectTicket(ticket)}
                    className="grid-x align-center-middle"
                  >
                    <div
                      className={
                        selectedTickets.some(
                          (t) => t?.id === ticket?.id
                        )
                          ? "boarding-tickets__passages__select__status--active"
                          : "boarding-tickets__passages__select__status"
                      }
                    />
                    <span className="boarding-tickets__passages__select__text">
                      {`${t("sales.passages.pdfPassages.tickets.number")} ${ticket?.code} ${ticket?.number}`}
                    </span>
                  </button>
                  <div>
                    {/* TODO: Implement send */}
                    {/* <button>
                      <img
                        width={30}
                        src={
                          selectedTickets.some(
                            (t) => t?.id === ticket?.id
                          )
                            ? Assets.SalesIcons.icon_send_ticket_active
                            : Assets.SalesIcons.icon_send_ticket
                        }
                        alt="icon_send_ticket"
                      />
                    </button> */}
                    <button
                      onClick={() => printTickets([ticket])}
                    >
                      <img
                        width={30}
                        src={
                          selectedTickets.some(
                            (t) => t?.id === ticket?.id
                          )
                            ? Assets.SalesIcons.icon_print_ticket_active
                            : Assets.SalesIcons.icon_print_ticket
                        }
                        alt="icon_print_ticket"
                      />
                    </button>
                  </div>
                </div>
                <div className="boarding-tickets__passages__ticket__body grid-x">
                  <div className="boarding-tickets__passages__ticket__labels grid-y">
                    <span>
                      {t("sales.passages.pdfPassages.tickets.passenger")}
                    </span>
                    <span>
                      {t("sales.passages.pdfPassages.tickets.output")}
                    </span>
                    <span>
                      {t("sales.passages.pdfPassages.tickets.position")}
                    </span>
                  </div>
                  <div className="boarding-tickets__passages__ticket__data grid-y">
                    <span>{ticket?.passengerName}</span>
                    <span>{`${dayjs(dataInvoice?.travelSeat?.date).format('DD/MM/YYYY')} ${dataInvoice?.travelSeat?.time}`}</span>
                    <span>{ticket?.seat?.name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
    </>
  )
}

export default BoardingTicketsComponent