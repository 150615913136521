import { getTokenService } from "./authentication.services";
import CashClosingEndPoints from "./constants/cash-closing.end-points"
import Http from "./infrastructure/http.infrastructure"


export async function getUserDataService() {

    try {
        let token = getTokenService();
        const { data: responseGetUser, error } = await Http.get(CashClosingEndPoints.GET_USER, null, token)
        if (responseGetUser && responseGetUser.status) {
            const listGetUser = responseGetUser.data.map((user) => ({
                label: `${user?.name} ${user?.lastName}`,
                value: user?.id
            }));
            return listGetUser
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};

export async function getCashClosingService(data) {
    try {
        const { data: responseGetCashClosing, error } = await Http.get(CashClosingEndPoints.GET_CASH_CLOSING,data)
        if (responseGetCashClosing && responseGetCashClosing.status) {
            return responseGetCashClosing.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};