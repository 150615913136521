//Assets
import { Assets } from '../../../../../assets';

//Components
import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';

//Constants
import { BoardingSheetErrorsConst } from '../../../../../core/constants/errors/alerts-app/boarding-sheet.errors.const';
import AlertConst from '../../../../../core/constants/alerts.const';

//Libraries
import React, { useState } from 'react'
import { AutoComplete, Input } from 'antd';

//Services
import { getFilterDriverAvailableToVehicleService } from '../../../../../services/vehicle.services';

//Styles
import "./modal-associate-driver.component.scss"

const ModalAssociateDriverComponent = (props) => {

    const {
        setDriverSelected = () => {},
        setVehicleSelected
    } = props;

    const INITIAL_STATE = {
        optionsDriverSearch: []
    }

    const [state, setState] = useState(INITIAL_STATE)

    const { optionsDriverSearch } = state

    const filterDriver = async(filter) => {
        try {
            const listDrivers = await getFilterDriverAvailableToVehicleService({
                valueFilter:filter
            })

            if (listDrivers.length === 0) {
                ErrorToastComponent({
                    title: BoardingSheetErrorsConst.localError.resultEmpty || BoardingSheetErrorsConst.default,
                    position: AlertConst.TOP_END_POSITION_TEXT,
                    timer: 1500
                })
            }

            setState((prevState) => ({
                ...prevState,
                optionsDriverSearch: listDrivers
            }))
        } catch {
            ErrorToastComponent({
                title: BoardingSheetErrorsConst.localError.resultEmpty || BoardingSheetErrorsConst.default,
                position: AlertConst.TOP_END_POSITION_TEXT,
                timer: 1500
            })
        }
    }
    
  return (
    <>
        <span className='grid-x text-center modal-associate-driver__title'>
            Vas a vincular un conductor al vehículo con el código {setVehicleSelected.plate}
        </span>
        <span className='modal-associate-driver__subtitle'>Vehículo Disponible</span>
        <Input
            className="modal-associate-driver__input__content--mod"
            value={setVehicleSelected.plate}
            prefix={
                <img
                    className="modal-associate-driver__input__search"
                    src={Assets.SharedIcons.icon_search_inactive}
                    alt="icon_search"
                />
            }
            disabled
        />
        <span className='modal-associate-driver__subtitle'>Buscar el Conductor Disponible</span>
        <AutoComplete
          className="modal-associate-driver__input"
          onSearch={(e) => {
                const trimmedValue = e.trim();
                if (trimmedValue) {
                    filterDriver(trimmedValue);
                } else { 
                    setState({ ...state, optionsDriverSearch: [] });
                }
            }}
            options={optionsDriverSearch}
            onSelect={(value,options)=> setDriverSelected(options)}
            onChange={(e) => !e.trim() && setDriverSelected(null)}
        >
            <Input
                allowClear={{
                    clearIcon: (
                    <img
                        width={28}
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_logo_vertical"
                    />
                    )
                }}
                className="modal-associate-driver__input__content"
                placeholder="Escribe el conductor por nombre o Nro. de documento"
                prefix={
                    <img
                    className="modal-associate-driver__input__search"
                    src={Assets.SharedIcons.icon_search}
                    alt="icon_search"
                    />
                }
            />
        </AutoComplete>
        <span className='modal-associate-driver__comments'>¿Deseas agregar una observación?</span>
        <Input.TextArea 
            rows={3} 
            placeholder="Escribe aquí tu comentario u observación"
            className='modal-associate-driver__text-area'
        />
    </>
  )
}

export default ModalAssociateDriverComponent