//Actions
import { setDataVehiclesStateReducer, setStateVehicleReducer } from '../../../../../storage/reducers/vehicles/vehicles.action';

//Assets
import { Assets } from '../../../../../assets';

//Components
//TODO: Commented on new requirement not to attach photo documents
// import ErrorToastComponent from '../../../../../shared/components/toast/error-toast/error-toast.component';
import ModalMainComponent from '../../../../../shared/components/modal/modal-main/modal-main.component';
import StructuresOfVehiclesComponent from '../structures-of-vehicles/structures-of-vehicles.component';

//Constants
//TODO: Commented on new requirement not to attach photo documents
// import AlertConst from '../../../../../core/constants/alerts.const';
import AppConst from '../../../../../core/constants/app.const';

//Libraries
import dayjs from 'dayjs';
import { useFormik } from "formik";
import { connect } from 'react-redux';
import { Checkbox, DatePicker, Input, Radio, Select } from "antd";
import React, { useEffect, useState } from 'react';

//Services
import { getListOfSeatTemplatesService } from '../../../../../services/template-vehicle-services';
import { getTypeBodyworkService, getTypeFuelService, getTypeVehicleService } from '../../../../../services/vehicle.services';

//Styles
import './vehicle-data.component.scss';

//Utils
import { vehicleDataSchema } from '../../../../../utils/form-validations/vehicles.validators.utils';

const VehicleDataComponent = (props) => {

  const {
    //Actions
    setStateVehicleReducer,
    setDataVehiclesStateReducer,
    //Variables
    isDisabled,
    addVehicle
  } = props;

  const { values, errors, touched, handleBlur, setValues, setFieldValue } = useFormik({
    initialValues: {
      typeVehicle: undefined,
      mark: '',
      model: '',
      plate: '',
      typeFuel: undefined,
      internalNumber: '',
      templateVehicle: undefined,
      codeBodyWork: '',
      typeBodywork: undefined,
      code: '',
      number: '',
      isMaintenance: {
        isTrue: true,
        isFalse: false
      },
      operatingCardExpiration: '',
      civilInsuranceExpiration: '',
      //TODO: Commented on new requirement not to attach photo documents
      // photoOwnershipCar: null,
      // photoOperatingCard: null,
      // photoPhotosVehicle: null,
    },
    validationSchema: vehicleDataSchema,
    onSubmit: () => { },
  });

  //TODO: Commented on new requirement not to attach photo documents
  // const stateProperties = {
  //   photoOwnershipCar: 'selectImgOwnershipCard',
  //   photoOperatingCard: 'selectImgOperatingCard',
  //   photoPhotosVehicle: 'selectImgPhotosVehicle',
  // };

  const INITIAL_STATE = {
    isViewModalStructure: false,
    //TODO: Commented on new requirement not to attach photo documents
    // selectImgOwnershipCard: "",
    // selectImgOperatingCard: "",
    // selectImgPhotosVehicle: "",
    optionsTypeFuel: [],
    optionsTypeBodywork: [],
    optionsTypeVehicle: [],
    optionsSeatTemplate: [],
  };

  const [state, setState] = useState(INITIAL_STATE);
  const { 
    //TODO: Commented on new requirement not to attach photo documents
    // selectImgOwnershipCard, selectImgOperatingCard, selectImgPhotosVehicle,
    optionsTypeFuel,
    optionsTypeBodywork,
    optionsTypeVehicle,
    isViewModalStructure,
    optionsSeatTemplate,
  } = state;

  useEffect(() => {
    setFieldsByReducer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStateVehicleReducer('isError', Object.keys(errors).length !== 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const onChange = async (data, target) => {
    //TODO: Commented on new requirement not to attach photo documents
    // if (target === 'photoOwnershipCar' || target === 'photoOperatingCard' || target === 'photoPhotosVehicle') {
    //   const { target: { value: fileImg, files } } = data;
    //   const fileValue = files[0];
    //   const newStateProperty = stateProperties[target];
    //   if (fileValue?.type === "image/jpg" || fileValue?.type === "image/jpeg" || fileValue?.type === "image/png") {
    //     if (fileValue?.size <= 4194304) {
    //       setFieldValue(target, fileValue);
    //       setState((prevState) => ({
    //         ...prevState,
    //         [newStateProperty]: fileImg,
    //       }));
    //     } else {
    //       setFieldValue(target, "")
    //       setState((prevState) => ({
    //         ...prevState,
    //         [newStateProperty]: "",
    //       }));
    //       ErrorToastComponent({
    //         title: 'Lo siento, has superado el límite de tamaño de la imagen. Por favor, selecciona una imagen más pequeña.',
    //         position: AlertConst.TOP_END_POSITION_TEXT,
    //         timer: 1500
    //       })
    //     }
    //   } else {
    //     setState((prevState) => ({
    //       ...prevState,
    //       [newStateProperty]: "",
    //     }));
    //     if (fileValue) {
    //       ErrorToastComponent({
    //         title: 'Lo siento, el tipo de archivo no es compatible',
    //         position: AlertConst.TOP_END_POSITION_TEXT,
    //         timer: 1500
    //       })
    //     }
    //   }
    // } else {
      const value = data && data.target ? data.target.value || '' : data;
      const vehicleData = { ...values, [target]: value };
      setValues(vehicleData);
      setDataVehiclesStateReducer('addVehicle', target, value)
    // }
  };

  const setFieldsByReducer = async () => {
    const {
      typeVehicle, mark, model,
      plate, mileage, typeFuel,
      internalNumber, templateVehicle, codeBodyWork,
      typeBodywork, code, number,
      isMaintenance, operatingCardExpiration, civilInsuranceExpiration
    } = addVehicle;

    setValues({
      typeVehicle, mark, model,
      plate, mileage, typeFuel,
      internalNumber, templateVehicle, codeBodyWork,
      typeBodywork, code, number,
      isMaintenance, operatingCardExpiration, civilInsuranceExpiration
    });

    await servicesVehicle();
  }

  const servicesVehicle = async () => {

    try {
        const results = await Promise.allSettled([
          getTypeFuelService(),
          getTypeBodyworkService(),
          getTypeVehicleService(),
          getListOfSeatTemplatesService()
        ]);
    
        let typeFuelList = [];
        let typeBodyworkList = [];
        let typeVehicleList = [];
        let listOfSeatTemplateList = [];
  
        for (let index = 0; index < results.length; index++) {
          const elementResponse = results[index];
    
          if (elementResponse.status === AppConst.FULFILLED) {
            switch (index) {
              case 0:
                typeFuelList = elementResponse?.value
                break;
              case 1:
                typeBodyworkList = elementResponse?.value
                break;
              case 2:
                typeVehicleList = elementResponse?.value
                break;
              case 3:
                elementResponse?.value.forEach(element => {
                  if (element && element.seats.length > 0) {
                    const seatArray = Array.from({ length: element?.row }, () => Array(element?.column).fill(undefined));
                    element.seats.forEach(({ ...seatVehicle }) => {
                      seatArray[seatVehicle.row][seatVehicle.column] = seatVehicle;
                    });
                    element.seats = seatArray
                  }
                });
                listOfSeatTemplateList = elementResponse?.value
                break;
              default:
                break;
            }
          }
        }
  
        setState({
          ...state,
          optionsTypeFuel: typeFuelList,
          optionsTypeBodywork: typeBodyworkList,
          optionsTypeVehicle: typeVehicleList,
          optionsSeatTemplate: listOfSeatTemplateList
        });
    } catch {
      // TODO: Implement error alert with code error.
    }    
  }

  const confirmVehicleTemplateCheck = (itemSeat) => {
    setFieldValue('templateVehicle', itemSeat);
    setDataVehiclesStateReducer('addVehicle', 'templateVehicle', itemSeat);
    setState({ ...state, isViewModalStructure: false });
  };

  const handlerOnChangeCost = (value, target) => {
    if (value === '') {
      setFieldValue(target, '');
    } else {
      let numericValue = Number(value.replace(/,/g, ""))
      if (!isNaN(numericValue)) {
        setFieldValue(target, numericValue)
      };
      setDataVehiclesStateReducer('addVehicle', target, value);
    }
  };

  const onChangeCheckbox = (value, target, option) => {
    setValues((prevValues) => ({
      ...prevValues,
      [option]: {
        ...prevValues[option],
        [target]: value,
        [(target === 'isTrue') ? 'isFalse' : 'isTrue']: !value,
      },
    }));
    setDataVehiclesStateReducer(
      'addVehicle',
      option,
      {
        [target]: value,
        [(target === 'isTrue') ? 'isFalse' : 'isTrue']: !value,
      }
    )
  };

  return (
    <div className='grid-x'>
      <div className='small-12 medium-4 vehicle-data__content'>
        <label className=" grid-x vehicle-data__label">
          Tipo de vehículo
          <div className="vehicle-data__label__icon" />
        </label>
        <Select
          disabled={isDisabled}
          value={values.typeVehicle}
          options={optionsTypeVehicle}
          className="vehicle-data__select"
          placeholder={'Seleccione el tipo de vehículo'}
          onBlur={(value) => handleBlur('typeVehicle')(value)}
          onChange={(e, event) => onChange(event, 'typeVehicle')}
          status={errors.typeVehicle && touched.typeVehicle ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
        />
        <span className="grid-x vehicle-data__errors">{touched.typeVehicle ? errors.typeVehicle : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle vehicle-data__content'>
        <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
          Marca del vehículo
          <div className="vehicle-data__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          value={values.mark}
          className="vehicle-data__input__content"
          onChange={(value) => onChange(value, 'mark')}
          onBlur={(value) => handleBlur('mark')(value)}
          placeholder={'Escribe la marca del vehículo'}
          status={errors.mark && touched.mark ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.mark ? errors.mark : null}</span>
      </div>
      <div className='small-12 medium-4 vehicle-data__content vehicle-data__content__right'>
        <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
          Línea de fabricación
          <div className="vehicle-data__label__icon" />
        </label>
        <Input
          value={values.model}
          disabled={isDisabled}
          className="vehicle-data__input__content"
          placeholder={'Escribe el módulo del vehículo'}
          onChange={(value) => onChange(value, 'model')}
          onBlur={(value) => handleBlur('model')(value)}
          status={errors.model && touched.model ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.model ? errors.model : null}</span>
      </div>
      <div className='small-12 medium-4 vehicle-data__content'>
        <label className=" grid-x vehicle-data__label">
          Placa del vehículo
          <div className="vehicle-data__label__icon" />
        </label>
        <Input
          value={values.plate}
          disabled={isDisabled}
          placeholder={'Escribe la placa'}
          className="vehicle-data__input__content"
          onChange={(value) => onChange(value, 'plate')}
          onBlur={(value) => handleBlur('plate')(value)}
          status={errors.plate && touched.plate ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.plate ? errors.plate : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle vehicle-data__content'>
        <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
          Kilometraje del vehículo
        </label>
        <Input
          disabled={isDisabled}
          value={values.mileage}
          className="vehicle-data__input__content"
          placeholder={'Escribe kilometraje del vehículo'}
          onBlur={(value) => handleBlur('mileage')(value)}
          onChange={(e) => handlerOnChangeCost(e.target.value, "mileage")}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.mileage ? errors.mileage : null}</span>
      </div>
      <div className='small-12 medium-4 vehicle-data__content vehicle-data__content__right'>
        <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
          Tipo de combustible
          <div className="vehicle-data__label__icon" />
        </label>
        <Select
          disabled={isDisabled}
          value={values.typeFuel}
          options={optionsTypeFuel}
          className="vehicle-data__select"
          placeholder={'Tipo de combustible'}
          onBlur={(value) => handleBlur('typeFuel')(value)}
          onChange={(e, event) => onChange(event, 'typeFuel')}
          status={errors.typeFuel && touched.typeFuel ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
        />
        <span className="grid-x vehicle-data__errors">{touched.typeFuel ? errors.typeFuel : null}</span>
      </div>
      <div className='small-12 medium-4 vehicle-data__content'>
        <label className=" grid-x vehicle-data__label">
          Número interno
          <div className="vehicle-data__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          value={values.internalNumber}
          className="vehicle-data__input__content"
          placeholder={'Escribe el número interno'}
          onChange={(value) => onChange(value, 'internalNumber')}
          onBlur={(value) => handleBlur('internalNumber')(value)}
          status={errors.internalNumber && touched.internalNumber ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                alt="icon_square_close"
                src={Assets.SharedIcons.icon_square_close}
              />
            )
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.internalNumber ? errors.internalNumber : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle vehicle-data__content'>
        <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
          Número de asientos
          <div className="vehicle-data__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          allowClear={{ clearIcon: ( <></> ) }}
          placeholder={'Selecciona el número de asientos'}
          value={values?.templateVehicle?.totalSeats || 0}
          className="vehicle-data__input__content__numberSeats"
          onBlur={(value) => handleBlur('templateVehicle')(value)}
          status={errors.templateVehicle && touched.templateVehicle ? "error" : ""}
        />
        <div className='vehicle-data__content-icon-vehicle'>
          <button
            disabled={isDisabled}
            onClick={() => setState({ ...state, isViewModalStructure: true })}
          >
            <img
              alt="icon_vehicle_button"
              src={Assets.VehiclesIcons.icon_vehicle_button}
              className='vehicle-data__content-icon-vehicle__icon'
            />
          </button>
        </div>
        <span className="grid-x vehicle-data__errors">{touched.templateVehicle ? errors.templateVehicle : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 justify-content-end vehicle-data__content vehicle-data__content__right'>
        <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
          Carrocería
          <div className="vehicle-data__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          placeholder={'Código'}
          value={values.codeBodyWork}
          className="vehicle-data__input__content--mod"
          onChange={(value) => onChange(value, 'codeBodyWork')}
          onBlur={(value) => handleBlur('codeBodyWork')(value)}
          status={(errors.codeBodyWork && touched.codeBodyWork) ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <Select
          disabled={isDisabled}
          value={values.typeBodywork}
          onBlur={(value) => handleBlur('typeBodywork')(value)}
          onChange={(e, event) => onChange(event, 'typeBodywork')}
          className="vehicle-data__select vehicle-data__select--mod"
          status={(errors.typeBodywork && touched.typeBodywork) ? "error" : ""}
          suffixIcon={
            <img
              src={Assets.SharedIcons.icon_down_arrow}
              alt="icon_down_arrow"
            />
          }
          options={optionsTypeBodywork}
          placeholder={'Tipo de carrocería'}
        />
        <span className="grid-x vehicle-data__errors">
          {(touched.typeBodywork || touched.codeBodyWork) ? errors.typeBodywork ? errors.typeBodywork : errors.codeBodyWork : null}
        </span>
      </div>
      <div className='small-12 medium-4 vehicle-data__content'>
        <label className=" grid-x vehicle-data__label">
          Código del vehículo
          <div className="vehicle-data__label__icon" />
        </label>
        <Input
          value={values.code}
          disabled={isDisabled}
          className="vehicle-data__input__content"
          placeholder={'Escribe el número interno'}
          onChange={(value) => onChange(value, 'code')}
          onBlur={(value) => handleBlur('code')(value)}
          status={errors.code && touched.code ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
            )
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.code ? errors.code : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle vehicle-data__content'>
        <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
          Número del vehículo
          <div className="vehicle-data__label__icon" />
        </label>
        <Input
          disabled={isDisabled}
          value={values.number}
          className="vehicle-data__input__content"
          placeholder={'Escribe el número interno'}
          onChange={(value) => onChange(value, 'number')}
          onBlur={(value) => handleBlur('number')(value)}
          status={errors.number && touched.number ? "error" : ""}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                alt="icon_square_close"
                src={Assets.SharedIcons.icon_square_close}
              />
            )
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.number ? errors.number : null}</span>
      </div>
      <div className='small-12 medium-4 vehicle-data__content vehicle-data__content__right'>
        <label className="grid-x vehicle-data__label vehicle-data__label--mod">
          Está disponible el vehículo
        </label>
        <div className='grid-x vehicle-data__content-check'>
          <div className="vehicle-data__content-check__check">
            Sí
            <Checkbox
              disabled={isDisabled}
              checked={values?.isMaintenance?.isTrue}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'isTrue', 'isMaintenance')}
            />
          </div>
          <div className="vehicle-data__content-check__check">
            No
            <Checkbox
              disabled={isDisabled}
              checked={values?.isMaintenance?.isFalse}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'isFalse', 'isMaintenance')}
            />
          </div>
        </div>
      </div>
      <div className='small-12 medium-4 vehicle-data__content'>
        <label className=" grid-x vehicle-data__label">
          Fecha de tarjeta de operación
          <div className="vehicle-data__label__icon" />
        </label>
        <DatePicker
          disabled={isDisabled}
          format={"DD/MM/YYYY"}
          placeholder="DD/MM/AAAA"
          className="vehicle-data__date"
          onBlur={() => handleBlur('operatingCardExpiration')}
          popupClassName="vehicle-data__date__picker"
          value={ dayjs(values.operatingCardExpiration).isValid()? dayjs(values.operatingCardExpiration) : null}
          onChange={(date) => onChange(date, 'operatingCardExpiration')}
          nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
          prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
          superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
          superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
          status={errors.operatingCardExpiration && touched.operatingCardExpiration ? "error" : null}
          suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
          allowClear={{
            clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.operatingCardExpiration ? errors.operatingCardExpiration : null}</span>
      </div>
      <div className='grid-x small-12 medium-4 align-center-middle vehicle-data__content'>
        <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
          Fecha de seguros civiles
          <div className="vehicle-data__label__icon" />
        </label>
        <DatePicker
          disabled={isDisabled}
          format={"DD/MM/YYYY"}
          placeholder="DD/MM/AAAA"
          className="vehicle-data__date"
          onBlur={() => handleBlur('civilInsuranceExpiration')}
          popupClassName="vehicle-data__date__picker"
          value={ dayjs(values.civilInsuranceExpiration).isValid()? dayjs(values.civilInsuranceExpiration) : null}
          onChange={(date) => onChange(date, 'civilInsuranceExpiration')}
          nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
          prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
          superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
          superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
          status={errors.civilInsuranceExpiration && touched.civilInsuranceExpiration ? "error" : null}
          suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
          allowClear={{
            clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
          }}
        />
        <span className="grid-x vehicle-data__errors">{touched.civilInsuranceExpiration ? errors.civilInsuranceExpiration : null}</span>
      </div>
      <>
        {/* TODO: Commented on new requirement not to attach photo documents */}
        {/* <div className='small-12 medium-4 vehicle-data__content'>
          <label className=" grid-x vehicle-data__label">
            Tarjeta de propiedad del vehículo
            <div className="vehicle-data__label__icon" />
          </label>
          <div className='vehicle-data__upload-photo'>
            <img
              alt="icon_id_card"
              className={values.photoOwnershipCar ? 'vehicle-data__upload-photo__photo-uploaded' : ''}
              src={values.photoOwnershipCar ? ((typeof values.photoOwnershipCar === "string") ? values.photoOwnershipCar : URL.createObjectURL(values.photoOwnershipCar)) : Assets.SharedIcons.icon_id_card}
            />
            <div className='grid-y align-center-middle'>
              <input
                type="file"
                disabled={isDisabled}
                id="filePhotoOwnershipCard"
                value={selectImgOwnershipCard}
                className='vehicle-data__hidden'
                accept="image/jpg, image/jpeg, image/png"
                onChange={(event) => onChange(event, 'photoOwnershipCar')}
              />
              <label className={`vehicle-data__upload-photo__charge-photo-label--mod ${isDisabled  ? 'vehicle-data__upload-photo__disabled' : ''}`} htmlFor="filePhotoOwnershipCard">
                <span className="vehicle-data__upload-photo__charge-photo-label__text">Adjuntar documento</span>
                <img
                  alt="icon_down_arrow"
                  src={Assets.SharedIcons.icon_up_arrow_white}
                />
              </label>
              <span className='vehicle-data__upload-photo__text'>Adjunta una fotografía de la tarjeta de propiedad del vehículo por ambos lados. Los tipos de formatos permisos son: JPG , JPEG o PNG</span>
            </div>
          </div>
        </div>
        <div className='grid-x small-12 medium-4 align-center-middle vehicle-data__content'>
          <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
            Tarjeta de operación vigente
            <div className="vehicle-data__label__icon" />
          </label>
          <div className='vehicle-data__upload-photo'>
            <img
              alt="icon_id_card"
              className={values.photoOperatingCard ? 'vehicle-data__upload-photo__photo-uploaded' : ''}
              src={values.photoOperatingCard ? ((typeof values.photoOperatingCard === "string") ? values.photoOperatingCard : URL.createObjectURL(values.photoOperatingCard)) : Assets.SharedIcons.icon_id_card}
            />
            <div className='grid-y align-center-middle'>
              <input
                type="file"
                disabled={isDisabled}
                id="filePhotoOperatingCard"
                value={selectImgOperatingCard}
                className='vehicle-data__hidden'
                accept="image/jpg, image/jpeg, image/png"
                onChange={(event) => onChange(event, 'photoOperatingCard')}
              />
              <label className={`vehicle-data__upload-photo__charge-photo-label--mod ${isDisabled  ? 'vehicle-data__upload-photo__disabled' : ''}`} htmlFor="filePhotoOperatingCard">
                <span className="vehicle-data__upload-photo__charge-photo-label__text">Adjuntar documento</span>
                <img
                  src={Assets.SharedIcons.icon_up_arrow_white}
                  alt="icon_down_arrow"
                />
              </label>
              <span className='vehicle-data__upload-photo__text'>Adjunta una fotografía de la tarjeta de operación del vehículo por ambos lados. Los tipos de formatos permisos son: JPG , JPEG o PNG</span>
            </div>
          </div>
        </div>
        <div className='small-12 medium-4 vehicle-data__content vehicle-data__content__right'>
          <label className=" grid-x vehicle-data__label vehicle-data__label--mod">
            Fotografías del vehículo
            <div className="vehicle-data__label__icon" />
          </label>
          <div className='vehicle-data__upload-photo'>
            <img
              alt="icon_id_card"
              className={values.photoPhotosVehicle ? 'vehicle-data__upload-photo__photo-uploaded' : ''}
              src={values.photoPhotosVehicle ? ((typeof values.photoPhotosVehicle === "string") ? values.photoPhotosVehicle : URL.createObjectURL(values.photoPhotosVehicle)) : Assets.SharedIcons.icon_id_card}
            />
            <div className='grid-y align-center-middle'>
              <input
                type="file"
                disabled={isDisabled}
                id="filePhotosVehicle"
                value={selectImgPhotosVehicle}
                className='vehicle-data__hidden'
                accept="image/jpg, image/jpeg, image/png"
                onChange={(event) => onChange(event, 'photoPhotosVehicle')}
              />
              <label className={`vehicle-data__upload-photo__charge-photo-label ${isDisabled  ? 'vehicle-data__upload-photo__disabled' : ''}`} htmlFor="filePhotosVehicle">
                <span className="vehicle-data__upload-photo__charge-photo-label__text">Subir fotos</span>
                <img
                  src={Assets.SharedIcons.icon_up_arrow_white}
                  alt="icon_down_arrow"
                />
              </label>
              <span className='vehicle-data__upload-photo__text'>Anexa fotografía del vehículo en donde se observe la placa. Los tipos de formatos permisos son: JPG , JPEG o PNG.</span>
            </div>
          </div>
        </div> */}
      </>
      {isViewModalStructure &&
        (<ModalMainComponent
          footer={null}
          open={isViewModalStructure}
          onClose={() => setState({ ...state, isViewModalStructure: false })}
        >
          <div className='vehicle-data__content-modal'>
            <div className='vehicle-data__content-modal__content-title'>
              <img
                alt="icon_vehicle_button"
                src={Assets.SharedIcons.icon_logo_and_name}
                className='vehicle-data__content-modal__icon'
              />
              <span className='vehicle-data__content-modal__title'>
                Por favor selecciona la estructura del vehículo
              </span>
            </div>
            <div className='vehicle-data__content-modal__content-structure'>
              {optionsSeatTemplate.map((structure, index) => {
                return (
                  <div
                    key={index}
                    className="grid-x align-middle vehicle-data__content-modal__container"
                  >
                    <div className='vehicle-data__content-modal__container__check-radio-content'>
                      <Radio
                        checked={values.templateVehicle?.id === structure?.id}
                        className='vehicle-data__content-modal__container__check-radio'
                        onChange={() => confirmVehicleTemplateCheck({ id: structure?.id, totalSeats: structure?.totalSeats })}
                      />
                    </div>
                    <button
                      className="vehicle-data__content-modal__container__structures"
                      onClick={() => confirmVehicleTemplateCheck({ id: structure?.id, totalSeats: structure?.totalSeats })}
                    >
                      <StructuresOfVehiclesComponent
                        seatMap={structure?.seats}
                      />
                    </button>
                    <div className='grid-x vehicle-data__content-modal__container__number-seats__content'>
                      <span className='vehicle-data__content-modal__container__number-seats'>Nombre:</span>
                      <span className='vehicle-data__content-modal__container__number-seats__number'>{structure?.name}</span>
                      <span className='vehicle-data__content-modal__container__number-seats'>Número de asientos:</span>
                      <span className='vehicle-data__content-modal__container__number-seats__number'>{structure?.totalSeats}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </ModalMainComponent>)
      }
    </div>
  )
};

const mapStateToProps = ({ VehiclesReducer }) => {
  const { addVehicle, isDisabled } = VehiclesReducer;
  return {
    addVehicle,
    isDisabled  
  };
};

const mapStateToPropsActions = {
  setStateVehicleReducer,
  setDataVehiclesStateReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(VehicleDataComponent);