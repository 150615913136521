// Constants - Endpoints
import TemplateVehiclesEndPoints from "./constants/template-vehicle.end-points";

// Infrastructure
import Http from "./infrastructure/http.infrastructure";

// Services
import { getTokenService } from "./authentication.services";

export async function getListOfSeatTemplatesService() {
    try {
        let token = getTokenService()
        const { data: getListOfSeatTemplateResponse, error } = await Http.get(TemplateVehiclesEndPoints.GET_LIST_OF_SEAT_TEMPLATES, null, token)
        if (getListOfSeatTemplateResponse && getListOfSeatTemplateResponse.status) {
            getListOfSeatTemplateResponse.data = getListOfSeatTemplateResponse.data.filter(item => item.seats.length > 0);
            return getListOfSeatTemplateResponse?.data
        } else {
            throw error
        }
    } catch (err) {
        throw err
    }
};