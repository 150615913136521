//Assets
import { Assets } from '../../../../../assets'

//Libraries
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from "dayjs";

//Styles
import { PdfShippingsStyles } from './pdf-shippings.component.styles'

//Utils
import { domicileOptions } from '../../../../../utils/data/shippings.utils';

const PdfShippingsComponent = (props) => {

  const { dataInvoice, size, isDelivery, tabClaimedShippings } = props
  const [t] = useTranslation("translation");

  const splitCufe = (cufe, size) => {
    const parts = [];
    for (let i = 0; i < cufe.length; i += size) {
      parts.push(cufe.substring(i, i + size));
    }
    return parts.join('\n');
  };

  return (
    <Document style={PdfShippingsStyles.content}>
      <Page size={size ? "A4" : { width: 297 }} style={PdfShippingsStyles.page}>
        <View style={PdfShippingsStyles.section}>
          <Image
            src={Assets.SharedIcons.icon_logo_pdf}
            style={PdfShippingsStyles.logo}
          />
          <Text
            style={PdfShippingsStyles.titleNit}
          >
            {"Cooperativa de Transportadores del Catatumbo\nNit: 800.175.577-0\nRegimen común"}
          </Text>
          <Text
            style={PdfShippingsStyles.titleNit}
          >
            {"Factura electrónica de venta"}
          </Text>
          <Text style={PdfShippingsStyles.subTitle}>
            {t("sales.turnPage.pdfTurn.header")} {dataInvoice?.invoice?.resolution?.number} DE {dayjs(dataInvoice?.invoice?.resolution?.date).format('DD/MM/YYYY')} DEL {dataInvoice?.invoice?.codePrefix}
            {dataInvoice?.invoice?.resolution?.initialRange} AL {dataInvoice?.invoice?.codePrefix} {dataInvoice?.invoice?.resolution?.finalRange}
          </Text>
          <View style={PdfShippingsStyles.box}>
            <View style={PdfShippingsStyles.boxSection}>
              <Text style={PdfShippingsStyles.title}>
                {t("sales.turnPage.pdfTurn.guide")}
              </Text>
              <Text style={PdfShippingsStyles.data}>{`${dataInvoice?.invoice?.codeSale}-${dataInvoice?.invoice?.codePrefix}-${dataInvoice?.invoice?.number}`}</Text>
            </View>
            <View style={PdfShippingsStyles.boxSection}>
              <Text style={PdfShippingsStyles.title}>
                {t("sales.turnPage.pdfTurn.dateIssued")}
              </Text>
              <Text style={PdfShippingsStyles.data}>{dayjs(dataInvoice?.invoiceDetails?.dateOfEntry).format('DD/MM/YYYY')} {dataInvoice?.hour}</Text>
            </View>
            <View style={PdfShippingsStyles.boxSection}>
              <Text style={PdfShippingsStyles.title}>
                {t("sales.turnPage.pdfTurn.client")}
              </Text>
              <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
                {`${dataInvoice?.clientSends?.name} ${dataInvoice?.clientSends?.lastName}`}
              </Text>
            </View>
            <View style={PdfShippingsStyles.boxSection}>
              <Text style={PdfShippingsStyles.title}>
                Tipo de encomienda
              </Text>
              <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
                {dataInvoice?.invoice?.paymentMethod?.name}
              </Text>
            </View>
            <View style={PdfShippingsStyles.boxSection}>
              <Text style={PdfShippingsStyles.title}>
                {t("sales.turnPage.pdfTurn.document")}
              </Text>
              <Text style={PdfShippingsStyles.data}>{`${dataInvoice?.clientSends?.documentType?.name} ${dataInvoice?.clientSends?.numberDocument}`}</Text>
            </View>
            <View style={PdfShippingsStyles.boxSection}>
              <Text style={PdfShippingsStyles.title}>
                {t("sales.turnPage.pdfTurn.departure")}
              </Text>
              <Text style={PdfShippingsStyles.data}>{dayjs(dataInvoice?.invoiceDetails?.dateOfEntry).format('DD/MM/YYYY')}</Text>
            </View>
            <Text style={[PdfShippingsStyles.title, PdfShippingsStyles.boxUnit]}>
              {t("sales.turnPage.pdfTurn.addressee")}
            </Text>
            <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
              {`${dataInvoice?.clientReceives?.name} ${dataInvoice?.clientReceives?.lastName}`}
            </Text>
            <Text style={[PdfShippingsStyles.title, PdfShippingsStyles.boxUnit]}>
              {t("sales.turnPage.pdfTurn.recipientAddress")}
            </Text>
            <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
              {dataInvoice?.clientReceives?.address}
            </Text>
            <Text style={[PdfShippingsStyles.title, PdfShippingsStyles.boxUnit]}>
              {t("sales.turnPage.pdfTurn.recipientPhoneNumber")}
            </Text>
            <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
              {dataInvoice?.clientReceives?.numberPhone}
            </Text>
          </View>
          <View style={[PdfShippingsStyles.line, PdfShippingsStyles.lineSeparator]} />
          <View style={[PdfShippingsStyles.box, { marginBottom: "10px", paddingBottom: "10px" }]}>
            <Text
              style={[
                PdfShippingsStyles.title,
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
              ]}
            >
              {t("sales.turnPage.pdfTurn.salesConcept")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight,
              ]}
            >
              {t("sales.shippings.pdf.order")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              {t("sales.shippings.pdf.costShipment")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight,
              ]}
            >
              ${(dataInvoice?.invoiceDetails?.costShipping).toLocaleString()}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              Porte
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight,
              ]}
            >
              ${(dataInvoice?.invoiceDetails?.porteService).toLocaleString()}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              {t("sales.shippings.pdf.costInsurance")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight,
              ]}
            >
              ${(dataInvoice?.invoiceDetails?.insuranceCost).toLocaleString()}
            </Text>
            {dataInvoice?.invoiceDetails?.isHomeDelivery === 1 &&
              <>
                <Text
                  style={[
                    PdfShippingsStyles.boxSection,
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.dataLeft,
                  ]}
                >
                  Costo del domicilio
                </Text>
                <Text
                  style={[
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.boxSection,
                    PdfShippingsStyles.dataRight,
                  ]}
                >
                  ${(dataInvoice?.invoiceDetails?.costDelivery).toLocaleString()}
                </Text>
              </>
            }
            <Text
              style={[
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              Manifiesto de carga
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight,
              ]}
            >
              ${(dataInvoice?.invoiceDetails?.manifestOfFreight).toLocaleString()}
            </Text>
            <View style={[PdfShippingsStyles.line, PdfShippingsStyles.lineSeparator]} />
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight,
                PdfShippingsStyles.separatorRight
              ]}
            >
              {t("sales.turnPage.pdfTurn.totalCost")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.title,
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataLeft,
                PdfShippingsStyles.separatorLeft
              ]}
            >
              ${(dataInvoice?.invoice?.price).toLocaleString()}
            </Text>
            <View
              style={[
                PdfShippingsStyles.line,
                PdfShippingsStyles.dataLeft,
                PdfShippingsStyles.lineBottom,
              ]}
            />
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              {t("sales.shippings.pdf.typeOrder")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight
              ]}
            >
              {dataInvoice?.invoiceDetails?.nameTypeShipping}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              {t("sales.shippings.pdf.content")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight
              ]}
            >
              {dataInvoice?.invoiceDetails?.content}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              {t("sales.shippings.pdf.declaredValue")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight
              ]}
            >
              ${(dataInvoice?.invoiceDetails?.declaredValue).toLocaleString()}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              {t("sales.shippings.pdf.destinationPlace")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight
              ]}
            >
              {dataInvoice?.clientReceives?.siteArrive?.name}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataLeft,
              ]}
            >
              {t("sales.shippings.pdf.address")}
            </Text>
            <Text
              style={[
                PdfShippingsStyles.titleSecondary,
                PdfShippingsStyles.boxSection,
                PdfShippingsStyles.dataRight
              ]}
            >
              {domicileOptions[dataInvoice?.invoiceDetails?.isHomeDelivery].label}
            </Text>
            {dataInvoice?.invoiceDetails?.isHomeDelivery === 1 &&
              <>
                <Text
                  style={[
                    PdfShippingsStyles.title,
                    PdfShippingsStyles.boxUnit,
                    PdfShippingsStyles.dataLeft,
                  ]}
                >
                  Observaciones
                </Text>
                <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
                  {dataInvoice?.invoiceDetails?.descriptionDelivery}
                </Text>
              </>
            }
            {(isDelivery || tabClaimedShippings) &&
              <>
                <View style={[PdfShippingsStyles.line, PdfShippingsStyles.lineSeparator]} />
                <Text
                  style={[
                    PdfShippingsStyles.title,
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.boxSection,
                    { width: "100%" }
                  ]}
                >
                  {isDelivery ? "Cliente que recibe:" : "Cliente que reclama:"}
                </Text>
                <Text
                  style={[
                    PdfShippingsStyles.boxSection,
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.dataLeft,
                  ]}
                >
                  Nombre
                </Text>
                <Text
                  style={[
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.boxSection,
                    PdfShippingsStyles.dataRight,
                  ]}
                >
                  {isDelivery ? dataInvoice?.clientReceives?.name : dataInvoice?.delivered?.nameUser}
                </Text>
                <Text
                  style={[
                    PdfShippingsStyles.boxSection,
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.dataLeft,
                  ]}
                >
                  Apellido
                </Text>
                <Text
                  style={[
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.boxSection,
                    PdfShippingsStyles.dataRight,
                  ]}
                >
                  {isDelivery ? dataInvoice?.clientReceives?.lastName : dataInvoice?.delivered?.lastNameUser}
                </Text>
                <Text
                  style={[
                    PdfShippingsStyles.boxSection,
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.dataLeft,
                  ]}
                >
                  C.C.
                </Text>
                <Text
                  style={[
                    PdfShippingsStyles.titleSecondary,
                    PdfShippingsStyles.boxSection,
                    PdfShippingsStyles.dataRight,
                  ]}
                >
                  {isDelivery ? dataInvoice?.clientReceives?.numberDocument : dataInvoice?.delivered?.numberDocumentUser}
                </Text>
                {!tabClaimedShippings && (
                  <>
                    <Text
                      style={[
                        PdfShippingsStyles.title,
                        PdfShippingsStyles.titleSecondary,
                        PdfShippingsStyles.dataLeft,
                        { paddingBottom: "20px" }
                      ]}
                    >
                      Firma
                    </Text>
                    <View style={[PdfShippingsStyles.lineSignature, PdfShippingsStyles.lineSeparator]} />
                  </>
                )}
              </>
            }
            <View
              style={[
                PdfShippingsStyles.line,
                PdfShippingsStyles.dataLeft,
                PdfShippingsStyles.lineBottom,
              ]}
            />
            <Text style={[PdfShippingsStyles.title, PdfShippingsStyles.boxUnit]}>
              {t("sales.shippings.pdf.cufe")}
            </Text>
            <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
              {splitCufe(dataInvoice?.dataElectronic?.cufe, 37)}
            </Text>
            <Text style={[PdfShippingsStyles.title, PdfShippingsStyles.boxUnit]}>
              {t("sales.shippings.pdf.date")}
            </Text>
            <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
              {dataInvoice?.dataElectronic?.dateValidation}
            </Text>
            <Text style={[PdfShippingsStyles.title, PdfShippingsStyles.boxUnit]}>
              {t("sales.shippings.pdf.provider")}
            </Text>
            <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
              {dataInvoice?.dataElectronic?.provider?.name} {dataInvoice?.dataElectronic?.provider?.nit}
            </Text>
            <View
              style={[
                PdfShippingsStyles.line,
                PdfShippingsStyles.dataLeft,
                PdfShippingsStyles.lineBottom,
              ]}
            />
            <View style={PdfShippingsStyles.boxUnit}>
              <Text style={PdfShippingsStyles.title}>
                {t("sales.turnPage.pdfTurn.seller")}
              </Text>
              <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
                {`${dataInvoice?.seller?.name} ${dataInvoice?.seller?.lastName}`}
              </Text>
            </View>
            { dataInvoice?.delivered &&
              <View>
                <Text style={PdfShippingsStyles.title}>
                  Cobrador
                </Text>
                <Text style={[PdfShippingsStyles.data, PdfShippingsStyles.boxUnit]}>
                  {`${dataInvoice?.delivered?.seller}`}
                </Text>
              </View>
            }
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default PdfShippingsComponent