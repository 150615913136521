//Libraries
import { Route, Routes, useLocation, useNavigate} from "react-router-dom";

// Pages
import LoginPage from "../../pages/authentication/login/login.page";
import NewPasswordPage from "../../pages/authentication/new-password/new-password.page";
import RecoveryPasswordPage from "../../pages/authentication/recovery-password/recovery-password.page";
import ValidateCodePage from "../../pages/authentication/validate-code/validate-code.page";

//Styles
import './authentication.router.scss'
import { useEffect } from "react";

const AuthenticationRouter = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // TODO: The process of linking routes needs to be improved. 
        // if (location.pathname === '/newPassword' && location.state?.from !== '/validateCode') {
        //     navigate('/login');
        // }
    }, [location, navigate]);

    return (
        <div className="authentication-router">
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/passwordRecovery" element={<RecoveryPasswordPage />} />
                <Route path="/validateCode" element={<ValidateCodePage />} />
                <Route path="/newPassword" element={<NewPasswordPage />} />
            </Routes>
        </div>
    )
}

export default AuthenticationRouter;