//Assets
import { Assets } from '../../../assets'

//Components
import ErrorToastComponent from '../../../shared/components/toast/error-toast/error-toast.component'
import ModalMainComponent from '../../../shared/components/modal/modal-main/modal-main.component'

//Constants
import AlertConst from '../../../core/constants/alerts.const'

//Libraries
import React, { useState } from 'react'

//Styles
import "./my-orders.page.scss"

const MyOrdersPage = () => {

  const ordersData = [
    {
      id: 1,
      number: "TI36141",
      recipientName: "Jose Santos",
      destination: 123456789,
      price: 2897,
      img: "",
      image: undefined
    },
    {
      id: 2,
      number: "TI36141",
      recipientName: "Jose Santos",
      destination: 123456789,
      price: 2897,
      img: "",
      image: undefined
    }
  ]

  const INITIAL_STATE = {
    orders: ordersData,
    isPreviewImg: {
      state:false,
      index: undefined
    },

  }

  const [state, setState] = useState(INITIAL_STATE)

  const { orders, isPreviewImg } = state

  const onChange = (data, index) => {
    const { target: { value: fileImg, files } } = data;
    const fileValue = files[0];
    if (fileValue?.type === "image/jpg" || fileValue?.type === "image/jpeg" || fileValue?.type === "image/png") {
        if (fileValue?.size <= 4194304) {
          const orderItem = [...orders];
          orderItem[index].img = fileImg;
          orderItem[index].image = fileValue;
          setState((prevState) => ({
            ...prevState,
            order: orderItem
          }));
        } else {
          const orderItem = [...orders];
          orderItem[index].img = "";
          orderItem[index].image = undefined
          setState((prevState) => ({
            ...prevState,
            order: orderItem
          }));
          ErrorToastComponent({
            title: 'Lo siento, has superado el límite de tamaño de la imagen. Por favor, selecciona una imagen más pequeña.',
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
          })
        }
    } else {
        const orderItem = [...orders];
        orderItem[index].img = "";
        orderItem[index].image = undefined
        setState((prevState) => ({
          ...prevState,
          order: orderItem
        }));
        if (fileValue) {
          ErrorToastComponent({
            title: 'Lo siento, el tipo de archivo no es compatible',
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 1500
          })
        }
    }
  }

  const deleteImg = (index) => {
    
    const orderItem = [...orders];
    orderItem[index].img = "";
    orderItem[index].image = undefined
    setState((prevState) => ({
      ...prevState,
      order: orderItem
    }));

  }

  return (
    <>
      <div className="my-orders__content">
        <span className="my-orders__subtitle">Mis encomiendas</span>
        <img src={Assets.SharedIcons.icon_next} alt="icon_next" />
        <span className="my-orders__subtitle">
          Planilla No. TI{Math.floor(Math.random() * 1000)}
        </span>
      </div>
      <div className='grid-x align-center-middle my-orders__header'>
        <img src={Assets.SalesIcons.icon_orders} alt="icon_orders" />
        <span className="my-orders__title">Encomiendas</span>
      </div>
      <div style={{ paddingBottom: "20px"}}>
        { orders.map((order, index) => {
          return(
            <div key={index} className='my-orders__target'>
              <div className='grid-x justify-content-between my-orders__text__content'>
                <span className='my-orders__subtitle'>Nro. de Encomienda</span>
                <span className='my-orders__text'>{order.number}</span>
              </div>
              <div className='grid-x justify-content-between my-orders__text__content'>
                <span className='my-orders__subtitle'>Nombre del destinatario</span>
                <span className='my-orders__text'>{order.recipientName}</span>
              </div>
              <div className='grid-x justify-content-between my-orders__text__content'>
                <span className='my-orders__subtitle'>Destino</span>
                <span className='my-orders__text'>{order.destination}</span>
              </div>
              <div className='grid-x justify-content-between my-orders__text__content'>
                <span className='my-orders__subtitle'>Valor</span>
                <span className='my-orders__text'>${order.price}</span>
              </div>
              <div className='my-orders__button__content'>
              { order?.img?.length > 0 ?
                  <div className='grid-x justify-content-between'>
                    <div>
                      <button 
                        className='grid-x align-center-middle my-orders__button__secondary'
                        onClick={() => 
                          setState({
                            ...state,
                            isPreviewImg: {
                              state: true,
                              index
                            }
                          })
                        }
                      >
                        <img
                          src={Assets.SharedIcons.icon_camera}
                          alt="icon_camera"
                        />
                        <span className='my-orders__button__text'>Ver foto del comprobante</span>
                      </button>
                    </div>
                    <div>
                      <button 
                        className='grid-x align-center-middle' 
                        style={{ height: "34px"}}
                        onClick={() => { deleteImg(index) }}
                      >
                        <img
                          src={Assets.SharedIcons.icon_delete}
                          alt="icon_delete"
                        />
                      </button>
                    </div>
                  </div>
                :
                <>
                  <input
                    type="file"
                    id={order.id}
                    value={order?.img}
                    className='my-orders__hidden'
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={(event) => onChange(event, index)}
                  />
                  <label htmlFor={order.id} className='grid-x align-center-middle my-orders__button'>
                    <img
                      src={Assets.SharedIcons.icon_camera}
                      alt="icon_camera"
                    />
                    <span className='my-orders__button__text'>Agregar foto del comprobante</span>
                  </label>
                </>
              }
              </div>
            </div>
          )
        })}
      </div>
      { isPreviewImg?.state &&
        <ModalMainComponent
          open={isPreviewImg.state}
          onClose={() => setState({ ...state, isPreviewImg: { state: false, index: undefined } })}
          close
          footer={null}
          title={"Comprobante de entrega de la encomienda"}
        >
          <img
            src={orders[isPreviewImg.index]?.image ? URL.createObjectURL(orders[isPreviewImg.index]?.image) : orders[isPreviewImg.index]?.img}
            alt="img" 
            className='my-orders__preview-img'  
          />
        </ModalMainComponent>
      }
    </>
  )
}

export default MyOrdersPage