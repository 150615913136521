//Assets
import { Assets } from '../../../../assets'

//Libraries
import React from 'react'
import { useTranslation } from 'react-i18next'

//Styles
import "./modal-save.component.scss"

const ModalSaveComponent = () => {

    const [t] = useTranslation("translation")

  return (
    <>
        <div className="grid-x align-center-middle">
        <img
          src={Assets.SharedIcons.icon_logo_and_name}
          alt="icon_logo_and_name"
          className="modal-save__icon"
        />
        <label className="modal-save__title">
            {t("sales.passages.saveChange")}
        </label>
        <p className='text-center modal-save__text'>
            {t("sales.passages.infoSave")}
        </p>
      </div>
    </>
  )
}

export default ModalSaveComponent