// Assets
import { Assets } from "../../../assets";

//Libraries
import React, { useRef, useState } from 'react'
import PinInput from 'react-pin-input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";

//Styles
import './validate-code.page.scss'

//Services
import { generateCodeService, validateCodeService } from "../../../services/authentication.services";

const ValidateCodePage = () => {

  const INITIAL_STATE = {
    time: Date.now() + 300000,
    timeOut: false,
    code: "",
    error: undefined
  }

  const pinInputRef = useRef(null);
  const [state, setState] = useState(INITIAL_STATE);
  const { time, timeOut, code, error } = state;

  const [t] = useTranslation("translation");
  const history = useNavigate();
  let { state: routeState } = useLocation();

  const handleSubmit = async () => {
    try {
      await validateCode()
      history("/newPassword", { replace: true, state: { token:routeState?.token }});
    } catch (error) {
      setState((prevState) => ({ ...prevState, error: error.message }))
    }
  }

  const resendCode = async() => {
    try {
      if(pinInputRef.current) pinInputRef.current.clear();
      await generateCode()
      setState((prevState) =>
      ({
        ...prevState,
        error: undefined,
        time: Date.now() + 300000,
        timeOut: false,
        code: ""
      }))
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  };

  const generateCode = async () => {
    try {
      await generateCodeService(routeState?.token);
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  const validateCode = async () => {
    try {
      await validateCodeService(code, routeState?.token);
    } catch (error) {
      // TODO: Implement error alert with code error.
    }
  }

  return (
    <div className="grid-y validate-code-page__container">
      <div className="grid-x align-center-middle small-4">
        <img
          src={Assets.SharedIcons.icon_logo_vertical}
          alt="icon_logo_vertical"
        />
      </div>
      <div className="grid-y small-7 validate-code-page__form-content">
        <span className="validate-code-page__form-content__title">{t("authentication.validateCode.mainTitle")}</span>
        <span className="validate-code-page__form-content__description">{t("authentication.validateCode.description")}</span>
        <div className='grid-y align-center-middle'>
          <PinInput
            ref={pinInputRef}
            length={6}
            onChange={(value) => setState((prevState) => ({ ...prevState, code: value, error: undefined }))}
            type="numeric"
            inputMode="number"
            style={{
              display: "flex",
              padding: 10
            }}
            inputStyle={{
              width: 30,
              height: 35,
              borderRadius: 5,
              marginInline: 5,
              color: "#2C2C2C",
              fontSize: 18,
              fontWeight: 'bold',
              borderWidth: code.length === 6 ? 2 : 1,
              borderColor: error
                ? "#C40062"
                : code.length === 6
                  ? "#85AF26"
                  : "#B7B7B7"
            }}
            inputFocusStyle={{
              borderColor: "#85AF26",
              borderWidth: 2
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </div>
        <div className='grid-y align-center-middle small-1 mb-3'>
          {!error
            ? <Countdown
              key={time}
              date={time}
              autoStart={true}
              onComplete={() => setState((prevState) => ({ ...prevState, timeOut: true }))}
              renderer={({ minutes, seconds }) => (
                <span className="validate-code-page__form-content__counter">
                  {`${minutes < 10
                    ? "0" + minutes
                    : minutes
                    }:${seconds < 10
                      ? "0" + seconds
                      : seconds
                    }`}
                </span>
              )}
            />
            : <span className="validate-code-page__form-content__error">
              {error}
            </span>
          }
        </div>
        <div>
          <button
            className="validate-code-page__button"
            type="button"
            onClick={handleSubmit}
            disabled={!!error || code.length !== 6 || timeOut}
          >
            {t("authentication.validateCode.validateCode")}
          </button>
        </div>
        <div className="grid-y small-3 align-middle">
          <div className="grid-x align-center-middle validate-code-page__resend-email">
            <span className="validate-code-page__resend-email__text">
              {t("authentication.validateCode.noEmail")}
            </span>
            <button
              type="button"
              className="validate-code-page__resend-email__link"
              onClick={resendCode}
            >
              {t("authentication.validateCode.resend")}
            </button>
          </div>
          <Link
            className="validate-code-page__resend-email__link"
            to="/passwordRecovery"
          >
            {t("authentication.validateCode.cancel")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ValidateCodePage;