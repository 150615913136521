// Assets
import { Assets } from '../../../assets'

//Libraries
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Components
import DriversTableComponent from './components/drivers-table/drivers-table.component';

// Styles
import './drivers.page.scss';
import { getUserService } from '../../../services/authentication.services';

const DriversPage = () => {

    const INITIAL_STATE = {
        isHoverDrivers: false,
    };

    const [state, setState] = useState(INITIAL_STATE);

    const { isHoverDrivers } = state;
    const dataUser = getUserService()

    return (
        <>
            <div className='drivers__breadcrumbs'>
                <span >Conductores</span>
            </div>
            <div className='grid-y drivers__content'>
                <span className="drivers__welcome">
                    {`Bienvenido ${dataUser.name} al modulo de conductores.`}
                </span>
                <div className="grid-x drivers__create-driver">
                    <button
                        className={`grid-y align-center-middle drivers__create-driver__card ${isHoverDrivers ? 'drivers__create-driver__card__active' : ''}`}
                        onClick={() => null}
                        onMouseOver={() => setState({ ...state, isHoverDrivers: true })}
                        onMouseOut={() => setState({ ...state, isHoverDrivers: false })}
                    >
                        <img
                            src={isHoverDrivers
                                ? Assets.SharedIcons.icon_drivers_card_hover
                                : Assets.SharedIcons.icon_drivers_card
                            }
                            alt="icon_drivers_card"
                            className={isHoverDrivers
                                ? "drivers__create-driver__card__icon--hover"
                                : "drivers__create-driver__card__icon"
                            }
                        />
                        <span>Crear conductor</span>
                        <Link
                            to="./createDrivers"
                            className={isHoverDrivers
                                ? "drivers__create-driver__card__add--hover"
                                : 'drivers__create-driver__card__add'
                            }
                        >
                            <img
                                src={isHoverDrivers ? Assets.SharedIcons.icon_add_hover : Assets.SharedIcons.icon_add}
                                alt="icon_add"
                            />
                        </Link>
                    </button>
                </div>
                <div className='drivers__info-content'>
                    <DriversTableComponent />
                </div>
            </div>
        </>
    )
}

export default DriversPage