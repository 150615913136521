// Libraries
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import { useEffect, useState } from "react";

// Routes
import InnerContent from "./inner-content.router";
import PublicRoutes from "./public-routes.router";
import ProtectedRoutes from "./protected-routes.router";
import SalesRoutes from "./sales/sales.router"
import AuthenticationRouter from "./authentication/authentication.router";
import DriversRoutes from "./drivers.router";
import MySpreadsheetsRoutes from "./my-spreadsheets-routes.router";
import SettingsRoutes from "./settings.router";

// Services
import { getUserModulesPermissionsService } from "../services/authentication.services";

// Views - Pages
import DeniedPage from "../pages/authentication/denied/denied.page";
import NotFoundPage from "../pages/authentication/not-found/not-found.page";
import TrackingPage from "../pages/tracking/tracking.page";
import BoardingSheetPage from "../pages/boarding-sheet/boarding-sheet.page";
import CashClosingPage from "../pages/cash-closing/cash-closing.page";
import ShiftsPage from "../pages/shifts/shifts.page";
import VehiclesRoutes from "./vehicles.router";

const AppRouter = () => {

    const { innerWidth } = window;

    const INITIAL_STATE = {
        route: ''
    }
    const [state, setState] = useState(INITIAL_STATE);
    const { route } = state;
    useEffect(() => {
        const modulesAssociateByUser = getUserModulesPermissionsService()
        if (modulesAssociateByUser.length > 0) {
            const [firstModule] = modulesAssociateByUser;
            if (firstModule) {
                setState((prevState) => ({
                    ...prevState,
                    route: firstModule.path,
                }))
            }
        }
    }, [])

    return (
        <Routes>
            {/* Non-authenticated special routes */}
            <Route path="tracking" element={<TrackingPage/>} />

            {/** Protected Routes */}
            {/** Wrap all Route under ProtectedRoutes element */}
            <Route path="/" element={<ProtectedRoutes />}>
                <Route path="/" element={<InnerContent />}>
                    <Route path="/" element={<Navigate replace to={`/${route}`} />} />
                    <Route path="/sales/*" element={<SalesRoutes/>} />
                    {innerWidth >= 639 && (
                        <>
                            <Route path="/boardingSheet" element={<BoardingSheetPage/>} />
                            <Route path="/cash" element={<CashClosingPage />} />
                            <Route path="/shifts" element={<ShiftsPage/>} />
                            <Route path="/drivers/*" element={<DriversRoutes />} />
                            <Route path="/vehicles/*" element={<VehiclesRoutes />} />
                            <Route path="/settings/*" element={<SettingsRoutes />} />
                            <Route path="/mySpreadsheets/*" element={<MySpreadsheetsRoutes />} />
                            
                            {/* Examples routes on app-root.router */}
                            {/* <Route path="tabs" element={<Tabs props={{userName: "Lions Team"}} />}> */}
                            {/* <Route path="/tabs" element={<Navigate replace to="tab1" />} /> */}
                            {/* This router implement Role = admin - role get localStorage on ProtectedRoutes File*/}
                            {/* <Route path="tab1" element={<Tab1 />} /> */}
                            {/* This router required role = USER */}
                            {/* <Route path="tab2" element={<ProtectedRoutes roles=[{`${AppConst.ROLE_USER}`}]/>}>
                                <Route path="/tabs/tab2" element={<Tab2 />} /> </Route> */}
                            {/* <Route path="tab3" element={<Tab3 />} /> */}
                        </>
                    )}
                </Route>
            </Route>

            {/** Public Routes */}
            {/** Wrap all Route under PublicRoutes element */}
            <Route path="/*" element={<PublicRoutes />}>
                {/* <Route path="/*" element={<Navigate replace to="/login" />} /> */}
                <Route path="*" element={<AuthenticationRouter/>} />
            </Route>

            {/** Permission denied route */}
            <Route path="/denied" element={<DeniedPage />} />

            {/** Not found page */}
            <Route path="/NotFoundPage" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate replace to="/NotFoundPage" />} />

        </Routes>
    )
}

export default AppRouter