//Assets
import { Assets } from '../../../assets'

//Components-Shared
import ModalMainComponent from '../../../shared/components/modal/modal-main/modal-main.component'

//Libraries
import React, { useEffect, useState } from 'react';
import { Input, Switch } from 'antd';
import { useFormik } from "formik";

//Styles
import "./creation-shipments.page.scss";

//Services
import { typeOfOrderSchema } from '../../../utils/form-validations/sales.validators.utils';
import { createOrderTypeService, getAllShippingTypeSettingService, updateOrderTypeService } from '../../../services/shippings.services';

const CreationShipmentsPage = (props) => {

  const {
    //Variables
    isDisabled
  } = props;

  const INITIAL_STATE = {
    isAssignmentOfOrderModal: false,
    optionShipping: [],
    optionName: '',
    flag: true,
    type: 2,
    idSelected: undefined 
  }

  const [state, setState] = useState(INITIAL_STATE)
  const {
    isAssignmentOfOrderModal,optionShipping,flag,
    type, idSelected
  } = state

useEffect(() => {
  getOptionTypeShipping()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  const AssignmentOfOrderModal = (item) => {
    item && setFieldValue("typeOfOrder", item.name);
    setState({
      ...state,
      isAssignmentOfOrderModal: true,
      flag: item?.state === 0 ? false : true,
      type: item ? 1 : 2,
      idSelected: item ? item.id : undefined
    })
    setFieldTouched("typeOfOrder", false)
  }

  const getOptionTypeShipping= async() => {
    try {
      const optionsGuide = await getAllShippingTypeSettingService();
      setState((prevState) => ({
        ...prevState,
        optionShipping: optionsGuide
      }))
    } catch (error) {
    }
  }

  const createOrderType= async(name, state) => {
    try {
      await createOrderTypeService(name, state)
      await getOptionTypeShipping()
      setState((prevState) => ({
        ...prevState,
        isAssignmentOfOrderModal: false
      }))
    } catch (error) {
    }
  }

  const updateOrderType= async(id, name, state) => {
    try {
      await updateOrderTypeService(id, name, state)
      await getOptionTypeShipping()
      setState((prevState) => ({
        ...prevState,
        isAssignmentOfOrderModal: false
      }))
    } catch (error) {
    }
  }

  const handleButton= (name,state) => {
        if(type===1){updateOrderType(idSelected,name,state)}
        if(type===2){createOrderType(name, state)}

  }

  const modalClose = () => {
    setFieldValue("typeOfOrder", '')
    setState((prevState) => ({
      ...prevState,
      isAssignmentOfOrderModal: false
    }))
  }

  const { values, errors, handleBlur, touched, setFieldValue, setFieldTouched } = useFormik({
    initialValues: {
      typeOfOrder: ""
    },
    initialErrors: {
      typeOfOrder: ""
    },
    validationSchema: typeOfOrderSchema,
  });


  return (
    <>
      <div className='grid-x align-center'>
        <div className='small-7'>
          <div className='grid-x creation-shipments__container__header'>
            <div>
              <img
                src={Assets.SharedIcons.icon_shipping}
                alt="icon_generate"
              />
              <span className='creation-shipments__title'>Encomiendas</span>
            </div>
          </div>
          <div className='grid-x small-12 creation-shipments__box creation-shipments__box--mod'>
            <div className='grid-x small-12'>
              <span className='creation-shipments__subtitle small-5'>Tipo de Encomienda</span>
              <span className='creation-shipments__subtitle small-3'>Estado</span>
              <span className='creation-shipments__subtitle small-4'>Acciones</span>
            </div>
          
            {optionShipping.map((item, index) => {
              const status = {
                1: "Activo",
                0: "Inactivo",
              }
              return (
                <div key={index} className='grid-x small-12 creation-shipments__table__line'
                >
                  <div className='small-5 grid-x'>
                    {item?.name}
                  </div>
                  <div className='small-3 grid-x align-center-middle'>
                    <span
                      className={`creation-shipments__table__text ${item?.state ? "creation-shipments__table__text__green" : "creation-shipments__table__text__red"}`}
                    >
                      {status[item?.state]}
                    </span>
                  </div>
                  <div className='small-4 grid-x align-center-middle'>
                    <button className="creation-shipments__button__actions"
                      onClick={() => AssignmentOfOrderModal(item)}
                      disabled={item?.name === "Otros"}
                    >
                      <span>Ajustar Encomienda</span>
                      <img
                        src={Assets.SharedIcons.icon_edit_pencil}
                        alt="icon_generate"
                      />
                    </button>
                  </div>
                </div>)
            })}
          </div>
          <div>
            <button className="creation-shipments__button"
              onClick={() => AssignmentOfOrderModal()}
            >
              <span className='creation-shipments__button__text'>
                Agregar Categoria</span>
              <img
                src={Assets.SharedIcons.icon_add_cross}
                alt="icon_generate"
              />
            </button>
          </div>
        </div>
      </div>

      {isAssignmentOfOrderModal &&
        <ModalMainComponent
          close
          width={300}
          open={isAssignmentOfOrderModal}
          onClose={() => modalClose()}
          titleIcon={
            <div className='grid-x align-center'>
              <img src={Assets.SharedIcons.icon_logo_and_name} alt='icon_logo_and_name' />
            </div>
          }
          footer={[
            <div key="accept" className='grid-x align-center'>
              <button
                className='creation-shipments__modal__button'
                onClick={() => handleButton(values?.typeOfOrder, flag ? 1 : 0)}
                disabled={(isDisabled || !values?.typeOfOrder)}
              >
                <span>{"Actualizar Costo"}</span>
              </button>
            </div>,

            <div key="cancel" className='grid-x align-center'>
              <button
                className='creation-shipments__modal__cancel'
                onClick={() => modalClose()}
              >
                <span >{"Salir"}</span>
              </button>
            </div>
          ]}
        >
          <div className='grid-x align-center-middle'>
            <span className='creation-shipments__modal__title'>Crear encomienda</span>
          </div>
          <div className='grid-x align-center-middle'>
            <span className='text-center creation-shipments__modal__text'>Aqui puedes crear un tipo de encomienda nueva y estandarizar su costo </span>

          </div>

          <div className='small-12 grid-x align-center-middle creation-shipments__modal__titleInput'>
            <span className="creation-shipments__info">Titulo del tipo de encomienda</span>
            <Input
              value={values?.typeOfOrder}
              status={errors?.typeOfOrder && touched?.typeOfOrder ? "error" : ""}
              allowClear={{
                clearIcon: (
                  <button
                    onClick={() => null}
                  >
                  </button>
                )
              }}
              className="creation-shipments__input__content"
              placeholder="Escribe aqui el tipo de encomienda"
              onChange={(e) => {
                setFieldValue("typeOfOrder", e.target.value)
              }}
              onBlur={(value) => handleBlur("typeOfOrder")(value)}
            />
          </div>

          <div className='grid-x align-center-middle'>
            <Switch
              checked={flag}
              checkedChildren="Activo" unCheckedChildren="Inactivo"
              defaultChecked
              className='creation-shipments__switch ant-switch'
              onChange={() => {
                setState((prevState) => ({
                  ...prevState,
                  flag: !flag
                }))
              }
              }
            />
          </div>
        </ModalMainComponent>
      }
    </>
  ) 
}

export default CreationShipmentsPage
