import * as settingsTypes from './settings.types';

const INITIAL_STATE = {
	selectedTab: undefined,
}

const SettingsReducer = (state = INITIAL_STATE, action) => {

	switch (action.type) {

		case settingsTypes.SET_SETTINGS:
			return{
				...state,
				[action.payload.prop]: action.payload.value
			}
        default:
            return state;
    }
}

export default SettingsReducer;