// Model
import VehicleModel from "../model/vehicle.model";

export function mapperToVehicleModel(VehicleDTO) {
    return new VehicleModel(
        VehicleDTO.id, 
        VehicleDTO.height,
        VehicleDTO.mark, 
        VehicleDTO.model, 
        VehicleDTO.plate, 
        VehicleDTO.width,
        VehicleDTO.internalNumber,
    )
}