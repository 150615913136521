// Model
import UserModel from "../model/user.model"
import { extractToDocumentTypeDTO } from "../../document-type/dto/document-type.dto.dao";
import { extractToIndicativeNumberDTO } from "../../indicative-number/dto/indicative-number.dto.dao";
import { extractToMunicipalityDTO } from "../../municipality/dto/municipality.dto.dao";
import UserDTO from "./user.dto";

export function mapperToUserModel(userDTO) {
    return new UserModel(
        userDTO.id,userDTO.name,userDTO.lastName,
        userDTO.documentType,userDTO.indicativeNumber,
        userDTO.indicativeNumberWhatsApp,userDTO.numberDocument,
        userDTO.numberPhone,userDTO.numberPhoneWhatsapp,
        userDTO.municipality,userDTO.department,
        userDTO.email,userDTO.address
    )
}

export function extractToClientUserDTO({
    id,name,lastName,documentNumber,telephone,whatsapp,
    contactAddress,contactEmail,municipalityResidence,documentType,
    telephoneCode,whatsappCode
}){
    let newEmail = contactEmail ? contactEmail : undefined
    let newContactAddress = contactAddress ? contactAddress : undefined
    let documentTypeExtract = documentType ? extractToDocumentTypeDTO(documentType) : undefined
    let indicativeNumberExtract = telephoneCode ? extractToIndicativeNumberDTO(telephoneCode) : undefined
    let indicativeNumberWhatsappExtract = whatsappCode ? extractToIndicativeNumberDTO(whatsappCode) : undefined
    let municipalityExtract = municipalityResidence ? extractToMunicipalityDTO(municipalityResidence) : undefined

    return new UserDTO( 
        id,name,lastName,documentNumber,telephone,
        whatsapp,newContactAddress,newEmail,
        municipalityExtract?.id,undefined,documentTypeExtract?.id,
        indicativeNumberExtract?.id,indicativeNumberWhatsappExtract?.id
    )
    
}