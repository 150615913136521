import Swal from "sweetalert2";
import { Assets } from "../../../../assets";
import AlertConst from "../../../../core/constants/alerts.const";
import { createErrorInternalApp } from "../../../../models/aggregates/build-error-internal-app/build-error-internal-app.dao";
import AlertErrorsConst from "../../../../core/constants/errors/alerts-app/alerts-error.const";

const InfoToastComponent = (props) => {
  if (!props) {
    throw createErrorInternalApp(AlertErrorsConst.paramsRequiredBuildToasts)
  }

  let {
    html = "",
    timer = 1500,
    position = AlertConst.CENTER_POSITION_TEXT,
    timerProgressBar = true
  } = props

  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: false,
    timer,
    timerProgressBar,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  return Toast.fire({
    iconHtml: `<img src=${Assets.SharedIcons.icon_info} />`,
    iconColor: "transparent",
    html,
    width: "500px",
    customClass:{
      htmlContainer: "info-toast__text",
      timerProgressBar: "info-toast__progress"
    },
  })
}

export default InfoToastComponent