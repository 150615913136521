//Assets
import { Assets } from "../../../../assets";

//Components
import PdfDetailsComponent from "../pdf-details/pdf-details.component";

//Libraries
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import dayjs from "dayjs";

//Services
import { getUserService } from "../../../../services/authentication.services";
import { getDetailsTemporaryPayrollService, getManifestByIdService } from "../../../../services/boarding-sheet.services";

//Styles
import "./modal-details.component.scss";

//Utils
import { boardingSheetExcelUtils } from "../../../../utils/boarding-sheet.utils";


const ModalDetailsComponent = (props) => {
  const { 
    open, 
    onClose = () => null,
    itemPreview,
    isTemporaryPayroll
  } = props;

  useEffect(() => {
    getDataPreview()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const INITIAL_STATE = {
    dataPreview: null
  }

  const [state, setState] = useState(INITIAL_STATE)

  const { dataPreview } = state

  const getDataPreview = async () => {
    try {
      
      let dataPreview = null
      if (isTemporaryPayroll) {

        const userDataFromService = getUserService();
        const { name, lastName } = userDataFromService;

        dataPreview = await getDetailsTemporaryPayrollService(itemPreview?.travel?.id);
        dataPreview.seller  = {
          name: name,
          lastName: lastName
        }
      } else {
        dataPreview = await getManifestByIdService(itemPreview?.travel?.id);
      }

      setState({
        ...state,
        dataPreview
      })
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const printPdf = async (url) => {
    window.open(url)
  };

  const generateExcel = async() => {
    try {
      const dataExcel = await getManifestByIdService(itemPreview?.travel?.id)

      const dataTickets = dataExcel?.travel?.invoices?.tickets?.data?.map((ticket) => {
        return [
          `${ticket?.prefix}${ticket?.number}`,
          ticket?.name,
          `${ticket?.passengerName} ${ticket?.passengerLastName}`,
          dataExcel?.travel?.route?.municipalityArrive,
          ticket?.price
        ]
      })

      const dataOrders = dataExcel?.travel?.invoices?.shippings?.data?.map((order) => {
        return [
          `${order?.prefix}${order?.number}`,
          `${order?.clientReceives?.name} ${order?.clientReceives?.lastName}`,
          dataExcel?.travel?.route?.municipalityArrive,
          order?.price
        ]
      })

      boardingSheetExcelUtils({
        summaryData:{
          extraContributions: dataExcel?.travel.othersDetails.extraContributions,
          socialBenefits: dataExcel?.travel.othersDetails.socialBenefits,
          loanPayment: dataExcel?.travel.othersDetails.loanPayment,
          manifest: dataExcel?.travel.othersDetails.manifest,
          contributions: dataExcel?.travel.othersDetails.contributions,
          replacement: dataExcel?.travel.othersDetails.replacement,
          additionalInsurance: dataExcel?.travel.othersDetails.additionalInsurance,
          subTotal: dataExcel?.travel?.subTotal,
          discount: dataExcel?.travel.discount,
          totalManifestPrice: dataExcel?.travel.totalManifestPrice
        },
        headerValues: [
          dataExcel?.vehicle?.internalNumber,
          `${dataExcel?.driver?.name} ${dataExcel?.driver?.lastName}`,
          dataExcel?.travel?.route?.municipalityDepart,
          dataExcel?.travel?.route?.municipalityArrive,
          dataExcel?.vehicle?.plate,
          `${dataExcel?.vehicle?.owner?.name} ${dataExcel?.vehicle?.owner?.lastName}`,
          dayjs(dataExcel?.travel?.time, 'HH:mm:ss').locale('es').format('h:mm a'),
          dayjs(dataExcel?.travel?.date).format('DD/MM/YYYY')
        ],
        fileTitle: itemPreview?.travel?.manifestNumber,
        dataTickets: dataTickets,
        dataOrders: dataOrders
      })
      
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  return (
    <Modal
      open={open}
      onCancel={() => {
        onClose();
      }}
      destroyOnClose
      footer={null}
      closeIcon={null}
      width={"85%"}
      style={{ height: "calc(100% - 40px)"}}
      className="modal-details__content modal-details__content--mod"
      wrapClassName="modal-details__container"
    >
      <div className="modal-details__content">
        <div className="grid-x justify-content-between modal-details__content__box">
          <div>
            <button onClick={() => onClose()}>
              <img
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_square_close"
              />
              <span>Cerrar</span>
            </button>
          </div>
          <div 
            className="grid-x justify-content-between"
            style={{ width: "80px" }}
          >
            <BlobProvider document={<PdfDetailsComponent dataPreview={[dataPreview]} />}>
              {({ url, loading, error }) => {
                return (
                    <button
                        onClick={() => printPdf(url)}
                        disabled={loading || error}
                    >
                      <img
                        src={Assets.SalesIcons.icon_print_ticket_active}
                        alt="icon_print_ticket_active"
                      />
                    </button>
                );
              }}
            </BlobProvider>
            <button
              onClick={() => generateExcel()}
            >
                <img 
                    src={Assets.SharedIcons.icon_download} 
                    alt="icon_download" 
                    style={{ height: "30px", width: "30px" }}
                    />
            </button>
          </div>
        </div>
          <PDFViewer showToolbar={false} style={{ width: "100%", height: "92%", overflowY: "scroll", paddingTop: "30px", backgroundColor: "#525659"  }}>
            <PdfDetailsComponent dataPreview={[dataPreview]} />
          </PDFViewer>
        </div>
    </Modal>
  );
};

export default ModalDetailsComponent;
