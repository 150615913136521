class UserModel {
  constructor(
    id,name,lastName,documentType,
    indicativeNumber,indicativeNumberWhatsapp,numberDocument,
    numberPhone,numberPhoneWhatsapp,municipality,department,
    contactEmail,contactAddress,modules
  ) {
    this.id = id;
    this.name = name;
    this.lastName = lastName;
    this.documentType = documentType;
    this.indicativeNumber = indicativeNumber;
    this.indicativeNumberWhatsapp = indicativeNumberWhatsapp;
    this.documentNumber = numberDocument;
    this.numberPhone = numberPhone;
    this.numberPhoneWhatsapp = numberPhoneWhatsapp;
    this.municipality = municipality;
    this.department = department;
    this.contactEmail = contactEmail;
    this.contactAddress = contactAddress;
    this.modules = modules;
  }
}

export default UserModel;
