// Actions
import { setDataSaleReducer, setDataSalesStateReducer, setStateSaleReducer } from "../../../../../storage/reducers/sales/sales.action";

//Assets
import { Assets } from "../../../../../assets";

// Components
import SeatMapRender from "./components/seat-map-render.component";
import ModalMainComponent from "../../../../../shared/components/modal/modal-main/modal-main.component";
import ErrorToastComponent from "../../../../../shared/components/toast/error-toast/error-toast.component";

//Constants
import AlertConst from "../../../../../core/constants/alerts.const";
import { SalesErrorsConst } from "../../../../../core/constants/errors/alerts-app/sales.errors.const";
import AppConst from "../../../../../core/constants/app.const";

//Libraries
import { Checkbox, DatePicker, InputNumber, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

// Services
import { 
  associateVehicleService,
  changeStateToAvailableService,changeStateToStandByService,getAllRouteService, 
  getDriverVehicleTravelService, getPassageSalesService, getSeatService, getVehiclesAvailableToTravelService, 
  setPassageSalesService
} from "../../../../../services/sales.services";
import { getDepartmentByNameService, getMunicipalityBySiteService } from "../../../../../services/shared.services";

//Styles
import "./trip-data.component.scss";

//Utils
import { comparePassenger, passagesTripDataSchema } from "../../../../../utils/form-validations/sales.validators.utils";


const TripDataComponent = (props) => {
  const {
    // Actions
    setDataSalesStateReducer,
    setDataSaleReducer,
    setStateSaleReducer,
    // Variables
    seatSelected,
    isDisabled,
    passageSales,
    isPreview,
    nextButtonRef,
    ...tripData
  } = props

  const INITIAL_STATE = {
    vehicle: undefined,
    optionsRoute: [],
    seatState: [],
    optionsVehicles : [],
    optionsMunicipality: [],
    driver: undefined,
    isOpenModal: false,
    isDropdownOpen: false,
  }
  
  const [state, setState] = useState(INITIAL_STATE)
  const { 
    vehicle,
    optionsRoute,
    seatState,
    optionsVehicles,
    optionsMunicipality,
    driver,
    isOpenModal,
    isDropdownOpen,
  } = state;

  const hoursRef = useRef();
  const destinationRef = useRef();
  const locationRef = useRef();
  const vehicleRef = useRef();
  const numberOfSeatsRef = useRef();
  const chooseOfSeatRef = useRef();
  const defaultRef = useRef();

  const inputRefs = [
    hoursRef, 
    destinationRef, 
    locationRef, 
    vehicleRef, 
    numberOfSeatsRef, 
    chooseOfSeatRef, 
    defaultRef
  ];

  const [t] = useTranslation("translation");

  useEffect(() => {
    if (optionsRoute.length === 1) {
      setFieldValue("destination", optionsRoute[0]);
    }
    if (optionsMunicipality.length === 1) {
      setFieldValue("location", optionsMunicipality[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsRoute, optionsMunicipality]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await Promise.allSettled([
          getAllRouteService(),
          getDepartmentByNameService()
        ]);
  
        let routeList = [];
        let departmentList = [];
        let departmentId = null;
  
        for (let index = 0; index < results.length; index++) {
          const elementResponse = results[index];
  
          if (elementResponse.status === AppConst.FULFILLED) {
            switch (index) {
              case 0:
                routeList = elementResponse.value.map((route) => ({
                  label: route.name,
                  value: route.id
                }));
                break;
              case 1:
                departmentList = [{
                  label: elementResponse.value.name,
                  value: elementResponse.value.id
                }];
                departmentId = departmentList[0].value;
                break;
              default:
                break;
            }
          }
        }
  
        const resMunicipality = await getMunicipalityBySiteService(departmentId);
        const municipalityList = resMunicipality.map((municipality) => ({
          label: municipality?.name,
          value: municipality?.id
        }));

        setState((prevState) => ({
          ...prevState,
          optionsRoute: routeList,
          optionsDepartment: departmentList,
          optionsMunicipality: municipalityList
        }));
  
        setValues({
          ...tripData,
        });
      } catch (error) {
        // TODO: Implement error alert with code error.
      }
    };
    fetchData();
    if (tripData?.vehicle?.travel) {
      const fetchDriver = async () => {
        try {
          const res = await getDriverVehicleTravelService(tripData?.vehicle?.travel);
          setState((prevState) => ({
            ...prevState,
            driver: res
          }));
        } catch (error) {
          // TODO: Implement error alert with code error.
        }
      };
      fetchDriver();
    }

    if (!tripData.date) {
      tripData.date = dayjs().format('DD/MM/YYYY')
    }
    if (!tripData.hours) {
      let approximateTime= (Math.ceil(dayjs().minute() / 30) * 30)
      let timeDefault= dayjs().set("minute", approximateTime).format('HH:mm')
      tripData.hours = timeDefault
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let touchesValues = { 
      date: tripData.date ? true : false,
      hours: tripData.hours ? true : false,
      destination: tripData.destination ? true : false,
      location: tripData.location ? true : false,
      numberOfSeats: tripData.numberOfSeats ? true : false,
      vehicle: tripData.vehicle ? true : false,
      assignedSeats: tripData.assignedSeats?.length > 0 ? true : false
    }
    setTouched({...touched,...touchesValues});
    setValues(tripData)

    let seatMap = [];

    const assignCheckedProperty = (object) => {
      const coincide = tripData?.assignedSeats?.some(assignedSeat => assignedSeat?.id === object?.id);
      return { ...object, checked: coincide };
    };
  
    if (seatState.length === 0) {
      seatMap = tripData?.vehicle?.vehicle?.seatMap
        ?.flatMap(seat => seat?.filter(element => element ))
        .map(assignCheckedProperty);
    } else {
      seatMap = seatState.map(assignCheckedProperty);
    }

    if(tripData.vehicle) setState((prevState) => ({ ...prevState, vehicle: tripData.vehicle, seatState: seatMap}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripData.assignedSeats])

  const onChange = async (data, target) => {
    let updateValues = {}
    let assignedSeats = []

    switch (target) {
      case 'date':
        updateValues = {
          date: data,
          hours: '',
          numberOfSeats: 1,
          vehicle: undefined
        }
        seatState?.length > 0 && resetStateSeat(seatState)
        break;
      case 'hours':
        updateValues = {
          hours: data,
          numberOfSeats: 1,
          vehicle: undefined
        }
        seatState?.length > 0 && resetStateSeat(seatState)
        break;
      case 'destination':
        updateValues = {
          destination: data,
          numberOfSeats: 1,
          vehicle: undefined
        }
        seatState?.length > 0 && resetStateSeat(seatState)
        break
      case 'location':
        updateValues = {
          location: data,
          numberOfSeats: 1,
          vehicle: undefined
        }
        seatState?.length > 0 && resetStateSeat(seatState)
        break
      case 'numberOfSeats':
        updateValues = {
          numberOfSeats: data && data <= vehicle?.totalSeatsAvailable ? data : 1,
        }
        assignedSeats = values.assignedSeats.slice(0, data)
        const seatReset = getIdDifferences(assignedSeats, values.assignedSeats);
        seatReset.length && changeStateSeat(seatReset)
        break;
      case 'vehicle':
        try {
          const res = await getSeatService(data?.travel);
          res.forEach(seat => {
            seat.label = seat.name
            seat.value = seat.id
            seat.checked = false
          });

          const seatArray = Array.from({ length: data.vehicle.height }, () => Array(data.vehicle.width).fill(undefined));

          res.forEach(({...seatVehicle }) => {
            seatArray[seatVehicle.row][seatVehicle.column] = seatVehicle;
          });

          data.vehicle.seatMap = seatArray

          updateValues = {
            vehicle: data,
            numberOfSeats: 1
          };

          const driverData = await getDriverVehicleTravelService(data?.travel)
          seatState.length > 0 && resetStateSeat(seatState)
          setState((prevState) => ({ ...prevState, vehicle: updateValues?.vehicle, seatState: res, driver: driverData }))

        } catch (error) {
          // TODO: Implement error alert with code error.
        }
        break;
      default:
        break
    }

    const newValues = { ...values, ...updateValues, assignedSeats };
    setValues(newValues)
    setDataSaleReducer("passageSales",{ ...newValues, seatSelected: undefined })
    setStateSaleReducer("isShowDrawer", false)
  };

  const { values, errors, touched, handleBlur, setFieldTouched, setValues, setTouched, setFieldValue } = useFormik({
    initialValues: {
      date: null,
      hours: null,
      destination: undefined,
      location: undefined,
      numberOfSeats: 1,
      vehicle: undefined,
      assignedSeats: []
    },
    validationSchema: passagesTripDataSchema,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    if (!isDisabled) {
      const passagesData = getPassageSalesService()
        const {
          id,documentType,documentNumber,name,
          lastName,telephone,telephoneCode,whatsapp,
          whatsappCode,departmentResidence,municipalityResidence,
          contactAddress,contactEmail,date,hours,
          destination,location,numberOfSeats,vehicle,seatSelected,
          assignedSeats,originalClientData
        } = passageSales
        const newDataPassages = {
          id,documentType,documentNumber,name,
          lastName,telephone,telephoneCode,whatsapp,
          whatsappCode,departmentResidence,municipalityResidence,
          contactAddress,contactEmail,date,hours,
          destination,location,numberOfSeats,vehicle,seatSelected,
          assignedSeats,originalClientData
        }
        newDataPassages.assignedSeats = comparePassenger(passagesData?.assignedSeats,assignedSeats)
        
        const passagesSalesData = {...passagesData, ...newDataPassages}
        setPassageSalesService(passagesSalesData);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values,passageSales])
  

  useEffect(() => {
    if (values.destination && values.date && values.hours) {
      getVehiclesAvailableToTravelService({
        route : values.destination?.value ,
        date : dayjs(values.date, 'DD/MM/YYYY').format('YYYY/MM/DD').replace(/\//g, '-'),
        time : `${values.hours}:00`
      })
      .then((res)=>{
        let filterAvailableSeats = res.filter(vehiclesAvailable => vehiclesAvailable.totalSeatsAvailable > 0);
        if (filterAvailableSeats.length === 0 && !isDisabled) {
          ErrorToastComponent({
            title: "No se encontraron vehiculos disponibles en la hora seleccionada",
            position: AlertConst.TOP_END_POSITION_TEXT,
            timer: 2500
          })
        }
        res.forEach(vehiclesAvailable => {
          vehiclesAvailable.label = `${vehiclesAvailable.vehicle.internalNumber} (${vehiclesAvailable.totalSeatsAvailable})`
          vehiclesAvailable.value = vehiclesAvailable.vehicle.id
        });
        setState((prevState) => ({
          ...prevState,
          optionsVehicles: filterAvailableSeats
        }));
      })
      .catch(() => {
        // TODO: Implement error alert with code error.
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.date, values.destination, values.hours])

  useEffect(() => {
    setStateSaleReducer('isError', !!Object.keys(errors).length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])


  const selectSeat = async(selected) => {

    const getDifferences = (previous, newData) => {
      const newDataIds = newData.map(obj => obj.id);
      return previous.filter(objectPrevious => !newDataIds.includes(objectPrevious.id)).map(objectPrevious => objectPrevious.id)
    };

    if(selected > tripData?.assignedSeats){
      try {
          await changeStateToStandByService(getDifferences(selected, tripData?.assignedSeats));
      } catch {
        // TODO: Implement error alert with code error.
      }
    }else{
      try {
          await changeStateToAvailableService(getDifferences(tripData?.assignedSeats,selected));
      } catch {
        // TODO: Implement error alert with code error.
      }
    }

    const newArray = [...seatState];
    let checks = []

    newArray.forEach((option) => {
      if (selected.some((select) => select.id === option.id)) {
        option.checked = true;
        checks.push(option)
      } else {
        option.checked = false;
      }
    });

    setState({
      ...state,
      seatState: newArray
    });

    const seatPassenger = checks.map(obj1 => {
      const matchingObj2 = tripData?.assignedSeats.find(obj2 => obj2.id === obj1.id);
      if (matchingObj2 && matchingObj2.passenger) {
        return { ...obj1, passenger: matchingObj2.passenger };
      }
      return obj1;
    });
    setDataSalesStateReducer("passageSales",'assignedSeats', seatPassenger)

  };

  const resetStateSeat = (seats) =>{
    const seatsChecked = seats.filter(object => object.checked).map(object => object.id);
    if (seatsChecked?.length) {
      changeStateSeat(seatsChecked)
    }
  }

  const changeStateSeat = async(seats) =>{
    try {
      await changeStateToAvailableService(seats)
    } catch {
      // TODO: Implement error alert with code error.
    }
  }

  const getIdDifferences = (seat, seatNew) => {
    const arrSeat = seat.map(object => object.id);
    const arrNewSeat = seatNew.map(object => object.id);
    return [...arrSeat, ...arrNewSeat].filter(id => !(arrSeat.includes(id) && arrNewSeat.includes(id)));
  };

  const removeSeat = (seat) => {
    setDataSalesStateReducer("passageSales",'assignedSeats', values.assignedSeats.filter(s => s.id !== seat.id))
    changeStateSeat([seat?.id])
  }

  const verifyAssociatedVehicle = (data, target) => {
    if(data?.isAssociatedToTravel) {
      onChange(data, target)
    } else {
      setState((prevState) => ({ 
        ...prevState, 
        isOpenModal: true,
        vehicle: data
      }))
    }
  }

  const associateVehicle = async () => {   
    try {
      const data = {
        route : values.destination?.value ,
        date : dayjs(values.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        time : `${values.hours}:00`,
        idVehicle: vehicle?.vehicle?.id,
        isShiftManual: false
      }
      const res = await associateVehicleService(data);
      setState((prevState) => ({ 
        ...prevState, 
        isOpenModal: false
      }))
      const travelData = { ...vehicle, travel: res }

      onChange(travelData, 'vehicle')
    } catch (err) {
      ErrorToastComponent({
        title: SalesErrorsConst.passages[err.code] || SalesErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  return (
    <div className="grid-x trip-data__content">
      <label className="small-12 trip-data__title">{t("sales.passages.tripData.tripData")}</label>
      <div className="small-12 medium-5">
        <div>
          <label className="trip-data__label">{t("sales.passages.tripData.selectDate")}</label>
          <DatePicker
            disabled={isDisabled}
            className="trip-data__date"
            value={values.date? dayjs(values.date, 'DD:MM:YYYY') : null}
            onChange={(e, date) => onChange(date, 'date')}
            status={errors.date && touched.date ? 'error' : ''}
            format={"DD/MM/YYYY"}
            allowClear={{
              clearIcon:(<img src={Assets.SalesIcons.icon_clear_calendar} alt="icon_clear_calendar" style={{ height: "25px", width: "25px" }} />)
            }}
            popupClassName="trip-data__date__picker"
            placeholder="DD/MM/AAAA"
            suffixIcon={<img src={Assets.SalesIcons.icon_calendar} alt="icon_calendar" style={{ height: "25px", width: "25px" }} />}
            superNextIcon={<img src={Assets.SalesIcons.icon_forward} alt="icon_forward" />}
            superPrevIcon={<img src={Assets.SalesIcons.icon_back} alt="icon_back" />}
            nextIcon={<img src={Assets.SharedIcons.icon_next} alt="icon_next" />}
            prevIcon={<img src={Assets.SharedIcons.icon_back} alt="icon_back" />}
            onFocus={() => {
              setState(prevState => ({ ...prevState, isDropdownOpenDatePicker: true }));
            }}
            onBlur={(value) => {
              handleBlur('date')(value);
              setState(prevState => ({ ...prevState, isDropdownOpenDatePicker: false }));
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setState(prevState => ({ ...prevState, isDropdownOpenDatePicker: false }));
                if (inputRefs[0].current) {
                  inputRefs[0].current.focus();
                }
              }
            }}
          />
          <span className="grid-x trip-data__errors">{touched.date ? errors.date : null}</span>
        </div>
        {values.date && <div>
          <label className="trip-data__label">{t("sales.passages.tripData.selectTime")}</label>
          <TimePicker
            autoFocus
            ref={inputRefs[0]}
            format={"HH:mm"} 
            minuteStep={30}
            hourStep={1}
            disabled={isDisabled}
            value={values.hours? dayjs(values.hours, 'HH:mm') : null}
            onChange={(e, hours) => {
              onChange(hours,"hours");
              setTimeout(() => {
                inputRefs[1].current.focus();
              }, 100);
            }}
            status={errors.hours && touched.hours ? 'error' : ''}
            onBlur={() => handleBlur('hours')}
            className="trip-data__hour"
            suffixIcon={<img src={Assets.SalesIcons.icon_hour} alt="icon_hour" className="trip-data__hour__icon" />}
            popupClassName="trip-data__hour__select"
            allowClear={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                  e.preventDefault();
                  setTimeout(() => {
                    inputRefs[1].current.focus();
                  }, 100);
              }
          }}
          />
          <span className="grid-x trip-data__errors">{touched.hours ? errors.hours : null}</span>
        </div>}
        {values?.hours && <div>
          <label className="trip-data__label">{t("sales.passages.tripData.selectRoute")}</label>
          <Select
            ref={inputRefs[1]}
            optionLabelProp="label"
            disabled={isDisabled}
            value={values.destination}
            onChange={(i, value) => onChange(value, 'destination')}
            status={errors.destination && touched.destination ? 'error' : ''}
            onBlur={(value) => handleBlur('destination')(value)}
            className="trip-data__select"
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
            options={optionsRoute}
            placeholder="Seleccione una ruta"
            onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
            onSelect={(value) => {
              if (isDropdownOpen) {
                setTimeout(() => {
                  inputRefs[2].current.focus();
                }, 100);
              }
            }}
          />
          <span className="grid-x trip-data__errors">{touched.destination ? errors.destination : null}</span>
        </div>}
        {values?.destination && <div>
          <label className="trip-data__label">Selecciona Destino de Viaje</label>
          <Select
            disabled={isDisabled}
            ref={inputRefs[2]}
            value={values.location}
            onChange={(i, value) => onChange(value, 'location')}
            status={errors.location && touched.location ? 'error' : ''}
            onBlur={(value) => handleBlur('location')(value)}
            className="trip-data__select"
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
            options={optionsMunicipality}
            placeholder="Seleccione un destino"
            onDropdownVisibleChange={(open) => setState({...state, isDropdownOpen: open})}
            onSelect={(value) => {
              if (isDropdownOpen) {
                setTimeout(() => {
                  inputRefs[3].current.focus();
                }, 100);
              }
            }}
          />
          <span className="grid-x trip-data__errors">{touched.location ? errors.location : null}</span>
        </div>}
        {values?.location && values?.destination && values?.hours && values?.date && <div>
          <label className="trip-data__label">{t("sales.passages.tripData.selectVehicle")}</label>
          <Select
            showSearch
            ref={inputRefs[3]}
            optionLabelProp="label"
            disabled={isDisabled}
            className="trip-data__select"
            value={values.vehicle}
            onSelect={(e, value) => {
              verifyAssociatedVehicle(value, 'vehicle');
              setTimeout(() => {
                if (inputRefs[4] && inputRefs[4].current) {
                  inputRefs[4].current.focus();
                }
              }, 200);
            }}
            status={errors.vehicle && touched.vehicle ? 'error' : ''}
            onBlur={(value) => handleBlur('vehicle')(value)}
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
            options={optionsVehicles}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            placeholder="Seleccione un vehículo"
          />
          <span className="grid-x trip-data__errors">{touched.vehicle ? errors.vehicle : null}</span>
        </div>}
      </div>
      <div className="small-12 medium-5">
        {seatState?.length !== 0 && values?.vehicle &&<div>
          <label className="trip-data__label">{t("sales.passages.tripData.selectNumberSeats")}</label>
          <div className={`grid-x ${errors.numberOfSeats && touched.numberOfSeats ? "trip-data__input-number__content--error" : "trip-data__input-number__content"}`}>
            <InputNumber
              ref={inputRefs[4]}
              disabled={isDisabled}
              onChange={(e) => onChange(e, 'numberOfSeats')}
              value={values.numberOfSeats}
              onBlur={(value) => handleBlur('numberOfSeats')(value)}
              className="trip-data__input-number"
              controls={false}
              variant="borderless"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  inputRefs[5].current.focus();
                }
              }}
            />
            <div className={`grid-y trip-data__input-number__buttons`}>
              <button className="small-6 trip-data__input-number__buttons__state"
                disabled={(values?.numberOfSeats === vehicle?.totalSeatsAvailable) || isDisabled}
                onClick={() => {
                  setFieldTouched('documentType', true)
                  onChange(values.numberOfSeats + 1, 'numberOfSeats')
                }}
              >
                <img
                  src={Assets.SharedIcons.icon_up_arrow}
                  alt="icon_up_arrow"
                  className="trip-data__input-number__buttons__icon"
                />
              </button>
              <button className="small-6 trip-data__input-number__buttons__state"
                onClick={() => {
                  onChange(values.numberOfSeats - 1, 'numberOfSeats')
                  setFieldTouched('numberOfSeats', true)
                }}
                disabled={(values.numberOfSeats <= 1) || isDisabled}
              >
                <img
                  src={Assets.SharedIcons.icon_down_arrow}
                  alt="icon_down_arrow"
                  className="trip-data__input-number__buttons__icon"
                />
              </button>
            </div>
          </div>
          <span className="grid-x trip-data__errors">{touched.numberOfSeats ? errors.numberOfSeats : null}</span>
        </div>}
        { values.vehicle && seatState && values.numberOfSeats ?
          <>
            <div>
              <label className="trip-data__label">{t("sales.passages.tripData.selectSeats.title")}</label>
              <Select
                ref={inputRefs[5]}
                mode="multiple"
                optionLabelProp="label"
                disabled={isDisabled}
                value={seatState?.filter(object => object?.checked === true)?.map(object => object?.id)}
                className="trip-data__select"
                onChange={(e, value) => {
                  selectSeat(value);
                  setTimeout(() => {
                    if (seatState.filter(seat => seat.checked).length === values.numberOfSeats) {
                      nextButtonRef.current.focus();
                    }
                  }, 200);
                }}
                suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
                menuItemSelectedIcon={() =><></>}
                showSearch={false}
                removeIcon={()=>{
                  return(
                    <img
                        src={Assets.SharedIcons.icon_close}
                        alt="icon_square_close"
                    />
                  )
                }}
              >
              {seatState?.map((item,index) => {
                return (
                  <Select.Option 
                    key={index} 
                    id={item?.id}
                    label={item?.label} 
                    value={item?.id}
                    disabled={item?.state !== 0 || (values?.assignedSeats?.length === values?.numberOfSeats && !item?.checked)}
                  >
                    <div className="grid-x justify-content-between">
                      <div>
                        <Checkbox 
                          checked={item?.checked} 
                          className="trip-data__check"
                          disabled={item?.state !== 0 || (values?.assignedSeats?.length === values?.numberOfSeats && !item?.checked)}
                        />
                        {item?.label}
                      </div>
                      <div>{item?.state !== 0 ? "No disponible": "Disponible"}</div>
                    </div>
                  </Select.Option>
                );
              })}
              </Select>
              <span className="grid-x trip-data__errors">{touched.vehicle ? errors.vehicle : null}</span>
            </div>
            <div>
            <div className="grid-y">
              <div className="grid-x justify-content-between">
                <label className="trip-data__label">{t("sales.passages.tripData.selectSeats.title")}</label>
                <label className="trip-data__label">({values.assignedSeats.length}/{values.numberOfSeats})</label>
              </div>
              <div className="grid-x align-center trip-data__vehicle">
                <div className="grid-x small-12 large-8 align-center-middle mb-2">
                  {vehicle
                    ? <SeatMapRender
                      disabled={!(values.assignedSeats.length < values.numberOfSeats)}
                      seatMap={vehicle?.vehicle?.seatMap}
                      assignedSeats={values.assignedSeats}
                      removeSeat={(seat) => removeSeat(seat)}
                      isPreview={isPreview}
                    />
                    : null
                  }
                </div>
                <div className="grid-y small-7 large-4 trip-data__vehicle__description">
                  <div className="grid-x justify-content-between mb-2">
                    <span className="small-8">{t("sales.passages.tripData.selectSeats.availableSeats")}</span>
                    <span>{vehicle?.totalSeatsAvailable}</span>
                  </div>
                  <div className="grid-x justify-content-between mb-2">
                    <span className="small-8">{t("sales.passages.tripData.selectSeats.vehicle")}</span>
                    <span>{driver?.vehicle?.plate}</span>
                  </div>
                  <div className="grid-x justify-content-between mb-2">
                    <div className="grid-y small-8">
                      <span>{t("sales.passages.tripData.selectSeats.driver")}</span>
                      <span>{`${driver?.driver?.name} ${driver?.driver?.lastName}`}</span>
                    </div>
                    <img
                      className="small-1 medium-3"
                      src={Assets.SharedIcons.icon_user_profile}
                      alt="icon_user_profile"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-y">
              <label className="trip-data__label">{t("sales.passages.tripData.selectedSeats")}</label>
              <div className="trip-data__assigned-seats grid-x align-middle">
                {values?.assignedSeats.map((seat, index) => (
                  <div key={index} className="grid-x trip-data__assigned-seats__seat">
                    <span>
                      {seat?.name}
                    </span>
                    { !isDisabled &&
                      <button disabled={isDisabled} className="small-3" onClick={() => removeSeat(seat)}>
                        <img
                          src={Assets.SharedIcons.icon_close}
                          alt="icon_square_close"
                        />
                      </button>
                    }
                  </div>
                )
                )}
              </div>
            </div>
            </div>
          </>
          : null
        }
      </div>
      {isOpenModal && (
        <ModalMainComponent
          close
          width={380}
          open={isOpenModal}
          onClose={() => setState((prevState) => ({ ...prevState, isOpenModal: false }))}
          titleIcon={
            <img
              src={Assets.SharedIcons.icon_logo_and_name}
              alt="icon_logo_and_name"
            />
          }
          footer={[
            <div key="accept" className="grid-x align-center">
              <button
                onClick={() => associateVehicle()}
                className="trip-data__modal__button"
              >
                <span>{"Vincular vehículo"}</span>
              </button>
            </div>,
          ]}
        >
          <span className='grid-x text-center trip-data__modal__title'>
            {`El vehículo con el código ${vehicle?.vehicle?.plate} no se encuentra de turno, Deseas Vincularlo?`}
          </span>
        </ModalMainComponent>
      )}
    </div>
  );
};

const mapStateToProps = ({ SalesReducer }) => {
  const {
    passageSales: {
      date,
      hours,
      destination,
      location,
      numberOfSeats,
      vehicle,
      seatSelected,
      assignedSeats,
    },
    passageSales,
    isDisabled
  } = SalesReducer;
  return {
    date,
    hours,
    destination,
    location,
    numberOfSeats,
    vehicle,
    seatSelected,
    assignedSeats,
    isDisabled,
    passageSales
  };
};

const mapStateToPropsActions = {
  setDataSalesStateReducer,
  setDataSaleReducer,
  setStateSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(TripDataComponent);
