// Actions
import { setDataSalesStateReducer, setStateSaleReducer } from '../../../../../storage/reducers/sales/sales.action'

// Assets
import { Assets } from '../../../../../assets'

// Libraries
import { t } from 'i18next'
import React from 'react'
import { connect } from 'react-redux'

// Services
import { changeStateToStandByService } from '../../../../../services/sales.services'

// Styles
import './seat-assignment.component.scss'

const SeatAssignmentComponent = (props) => {
    const {
        // Actions
        setDataSalesStateReducer,
        setStateSaleReducer,
        // Variables
        seatSelected,
        assignedSeats,
        date,
        hours,
        destination,
        vehicle
    } = props;
    
    const selectedSeat = async(seat) => {
        try {
            await changeStateToStandByService([seat?.id]);
        } catch {
          // TODO: Implement error alert with code error.
        }
    }

    return (
        <div className="seat-assignment__content grid-y">
            <div className="seat-assignment__header grid-x">
                <button className="small-2" onClick={() => {
                    setDataSalesStateReducer("passageSales",'seatSelected', undefined)
                    setStateSaleReducer('isShowDrawer', false)
                }}
                >
                    <img
                        src={Assets.SharedIcons.icon_square_close}
                        alt="icon_square_close"
                    />
                </button>
                <div className="seat-assignment__header__title small-9 grid-x">
                    <span className="">{t("sales.passages.assignedSeats.title")}</span>
                </div>
            </div>
            <div className="seat-assignment__body">
                <div className="grid-y seat-assignment__body__filed">
                    <span className="grid-y seat-assignment__body__filed__title">{t("sales.passages.assignedSeats.date")}</span>
                    <span className="grid-y seat-assignment__body__filed__description">{date}</span>
                </div>
                <div className="grid-y seat-assignment__body__filed">
                    <span className="grid-y seat-assignment__body__filed__title">{t("sales.passages.assignedSeats.hour")}</span>
                    <span className="grid-y seat-assignment__body__filed__description">{hours}</span>
                </div>
                <div className="grid-y seat-assignment__body__filed">
                    <span className="grid-y seat-assignment__body__filed__title">{t("sales.passages.assignedSeats.destination")}</span>
                    <span className="grid-y seat-assignment__body__filed__description">{destination?.label}</span>
                </div>
                <div className="grid-y seat-assignment__body__filed">
                    <span className="grid-y seat-assignment__body__filed__title">{t("sales.passages.assignedSeats.vehicle")}</span>
                    <span className="grid-y seat-assignment__body__filed__description">{vehicle?.label}</span>
                </div>
                <div className="grid-y seat-assignment__body__filed">
                    <span className="grid-y seat-assignment__body__filed__title">{t("sales.passages.assignedSeats.seat")}</span>
                    <span className="grid-y seat-assignment__body__filed__description">{seatSelected?.name}</span>
                </div>
                <div className="grid-y seat-assignment__body__filed">
                    <span className="grid-y seat-assignment__body__filed__title">{t("sales.passages.assignedSeats.state")}</span>
                    <span className="grid-y seat-assignment__body__filed__description">{t("sales.passages.assignedSeats.available")}</span>
                </div>

            </div>
            <div className="seat-assignment__footer grid-y align-middle">
                <div className="">
                    <span className="seat-assignment__footer__price">{t("sales.passages.assignedSeats.cost")} $ {seatSelected?.price?.toLocaleString('es-ES', { style: 'decimal' })}</span>
                </div>
                <button
                    className="seat-assignment__footer__button"
                    onClick={() => {
                        selectedSeat(seatSelected)
                        setDataSalesStateReducer("passageSales",'assignedSeats', [...assignedSeats, seatSelected])
                        setDataSalesStateReducer("passageSales",'seatSelected', undefined)
                        setStateSaleReducer('isShowDrawer', false)
                    }}
                >
                    <span>{t("sales.passages.assignedSeats.reserve")}</span>
                </button>
                <button
                    className="seat-assignment__footer__cancel"
                    onClick={() => {
                        setDataSalesStateReducer("passageSales",'seatSelected', undefined)
                        setStateSaleReducer('isShowDrawer', false)
                    }}
                >
                    <span>{t("sales.passages.assignedSeats.cancel")}</span>
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = ({ SalesReducer }) => {
    const { 
        passageSales: { 
            seatSelected,
            assignedSeats,
            date,
            hours,
            destination,
            vehicle
        }
    } = SalesReducer;
    return {
        seatSelected,
        assignedSeats,
        date,
        hours,
        destination,
        vehicle
    };
};

const mapStateToPropsActions = {
    setDataSalesStateReducer,
    setStateSaleReducer
};

export default connect(mapStateToProps, mapStateToPropsActions)(SeatAssignmentComponent);