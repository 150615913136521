//Assets
import { Assets } from '../../../../assets';

//Constants
import AppConst from '../../../../core/constants/app.const';
import BoardingSheetConst from '../../../../core/constants/boarding-sheet.const';
import { BoardingSheetErrorsConst } from '../../../../core/constants/errors/alerts-app/boarding-sheet.errors.const';
import AlertConst from '../../../../core/constants/alerts.const';

//Components
import ErrorToastComponent from '../../../../shared/components/toast/error-toast/error-toast.component';

//Libraries
import React, { useEffect, useState } from 'react'
import { Select, Input, Checkbox } from 'antd';

//Services
import { getDriverAvailableWithVehicleService } from '../../../../services/drivers.services';
import { getHeadquarterAssociatedService, getPassagesListService, getShippingsListService, patchPassagesService, patchShippingService } from '../../../../services/boarding-sheet.services';

//Styles
import "./modal-temporary-payroll.component.scss"

const ModalTemporaryPayrollComponent = (props) => {

  const { 
    itemSelected, 
    driverSelected,
    setDataGeneratePayroll = () => {},
    selectedShipments,
    selectedPassages,
    setStateModal = () => {}
  } = props;

  const INITIAL_STATE = {
    optionsDriver: [],
    headquarterAssociated: "",
    optionsShipping: [],
    optionsPassages: []
  }

  const [state, setState] = useState(INITIAL_STATE)
  const { 
    optionsDriver, 
    headquarterAssociated, 
    optionsShipping,
    optionsPassages
  } = state;

  useEffect(() => {
    getAllServices()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAllServices = async() => {
    try {
      const results = await Promise.allSettled([
        getDriverAvailableWithVehicleService(),
        getShippingsListService(itemSelected?.id),
        getPassagesListService(itemSelected?.id),
        getHeadquarterAssociatedService()
      ])

      let dataServices = {};
      let stateModal = {};

      for (let index = 0; index < results.length; index++) {
        const elementResponse = results[index];

        if (elementResponse.status === AppConst.FULFILLED) {
          switch (index) {
            case 0:
              dataServices.optionsDriver = elementResponse?.value
              if (elementResponse?.value.filter((object)=> object?.idDriverVehicle === itemSelected?.idDriverVehicleTemp)) {
                stateModal.driverSelected = elementResponse?.value.filter((object)=> object?.idDriverVehicle === itemSelected?.idDriverVehicleTemp)
              }
              break;

            case 1:
              dataServices.optionsShipping = elementResponse?.value
              stateModal.selectedShipments = elementResponse?.value?.filter(object => object?.checked === true) || []
              break;

            case 2:
              dataServices.optionsPassages = elementResponse?.value
              stateModal.selectedPassages = elementResponse?.value.filter(object => object?.checked === true) || []
              break;

            case 3:
              dataServices.headquarterAssociated = elementResponse?.value?.name
              break;
              
            default:
              break;
          }
        }
      }
      setStateModal(stateModal)
      setState((prevState) => ({
        ...prevState,
        ...dataServices
      }))

    } catch (err) {
      ErrorToastComponent({
        title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  }

  const handleChange = async(value) => {
    try {
      if (value.length > selectedShipments.length) {
        await patchShippingService(itemSelected?.id,value[value.length - 1]?.id)
      } else {
        let itemDelete = null
        selectedShipments.forEach((item) => {
          if (!value.some((select) => select.id === item.id)) {
            itemDelete = item
          }
        })
        if (itemDelete) {
          await patchShippingService(itemSelected?.id,itemDelete.id)
        }
      }

      let options = [...optionsShipping]
      let checks = []
  
      options.forEach((option) => {
        if (value.some((select) => select.id === option.id)) {
          option.checked = true;
          checks.push(option)
        } else {
          option.checked = false;
        }
      });

      setStateModal({
        selectedShipments: checks
      })
    } catch (err) {
      ErrorToastComponent({
        title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  };

  const handlePassages = async(value) => {
    try {

      let options = [...optionsPassages];
      let checks = [];
  
      options.forEach((option) => {
        if (value.some((select) => select.id === option.id)) {
          option.checked = true;
          checks.push(option);
        } else {
          option.checked = false;
        }
      });

      if (value.length > selectedPassages.length) {
        await patchPassagesService(itemSelected?.id,value[value.length - 1]?.id)
      } else {
        let itemDelete = null
        selectedPassages?.forEach((item) => {
          if (!value.some((select) => select.id === item.id)) {
            itemDelete = item 
          }
        })
        if (itemDelete) {
          await patchPassagesService(itemSelected?.id,itemDelete.id)
        }
      }

      setStateModal({
        selectedPassages: checks
      })

    } catch (err) {
      ErrorToastComponent({
        title: BoardingSheetErrorsConst.passages[err.code] || BoardingSheetErrorsConst.default,
        position: AlertConst.TOP_END_POSITION_TEXT,
        timer: 1500
      })
    }
  };

  const totalAmount = (array) =>{
    return array.reduce((add, object) => {
      return add + object.amount;
    }, 0);
  } 

  return (
    <>
      <span className='grid-x text-center modal-temporary-payroll__title'>
        Vas a generar una planilla para el vehículo con la siguiente información:
      </span>
      <div className='grid-x'>
        <span className='small-6 modal-temporary-payroll__subtitle'>Código del vehículo</span>
        <span className='small-6 text-right modal-temporary-payroll__text'>{itemSelected?.vehicle?.plate}</span>
        <span className='small-6 modal-temporary-payroll__subtitle'>Nro. del vehículo</span>
        <span className='small-6 text-right modal-temporary-payroll__text'>{itemSelected?.vehicle?.internalNumber}</span>
        <span className='small-6 modal-temporary-payroll__subtitle'>Pasajes vendidos</span>
        <span className='small-6 text-right modal-temporary-payroll__text'>{totalAmount(selectedPassages)}/{itemSelected?.amountSeat}</span>
        <span className='small-6 modal-temporary-payroll__subtitle'>Encomiendas</span>
        <span className='small-6 text-right modal-temporary-payroll__text'>{totalAmount(selectedShipments)}</span>
        <div className='small-12 grid-x '>
          <div className='small-6'>
            <label className='modal-temporary-payroll__subtitle'>Vincular encomiendas</label>
          </div>
          <Select
            mode="multiple"
            optionLabelProp="label"
            placeholder="Vincular encomiendas"
            value={selectedShipments}
            onChange={(e,select) => handleChange(select)}
            className="modal-temporary-payroll__select"
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
            menuItemSelectedIcon={() => <></>}
            showSearch={true}
            removeIcon={() => {
              return (
                <img
                  src={Assets.SharedIcons.icon_close} alt="icon_square_close" />
              )
            }}

            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {optionsShipping?.map((item, index) => {

              return (
                <Select.Option
                  key={index}
                  id={item?.id}
                  label={item?.label}
                  value={item?.id}
                >
                  <div className="grid-x justify-content-between">
                    <div>
                      <Checkbox
                        checked={item?.checked}
                        className="trip-data__check"
                      />
                      {item?.label}
                    </div>
                  </div>
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className='small-12 grid-x '>
          <div className='small-6'>
            <label className='modal-temporary-payroll__subtitle'>Vincular pasajes</label>
          </div>
          <Select
            mode="multiple"
            optionLabelProp="label"
            placeholder="Facturas de pasajes"
            value={selectedPassages}
            onChange={(e,select) => handlePassages(select)}
            className="modal-temporary-payroll__select"
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
            menuItemSelectedIcon={() => <></>}
            showSearch={true}
            removeIcon={() => {
              return (
                <img
                  src={Assets.SharedIcons.icon_close} alt="icon_square_close" />
              )
            }}

            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {optionsPassages?.map((item, index) => {
              return (
                <Select.Option
                  key={index}
                  id={item?.id}
                  label={item?.label}
                  value={item?.id}
                  disabled={(item.amount + totalAmount(selectedPassages)) > itemSelected?.amountSeat && !item?.checked }
                >
                  <div className="grid-x justify-content-between">
                    <div>
                      <Checkbox
                        checked={item?.checked}
                        className="trip-data__check"
                        disabled={(item.amount + totalAmount(selectedPassages)) > itemSelected?.amountSeat && !item?.checked }
                      />
                      {item?.label}
                    </div>
                  </div>
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className='small-12'>
          <p className='modal-temporary-payroll__subtitle'>Conductor</p>
          <Select
            showSearch
            optionLabelProp="label"
            value={driverSelected}
            onChange={(i, value) => setStateModal({ driverSelected:value })}
            className="modal-temporary-payroll__select"
            suffixIcon={<img src={Assets.SharedIcons.icon_down_arrow} alt="icon_down_arrow" />}
            options={optionsDriver}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            placeholder="Seleccione un conductor"
          />
        </div>
        <span className='small-6 modal-temporary-payroll__subtitle'>Abonos Prest</span>
        <Input
          value={itemSelected?.loanPayment?.toLocaleString()}
          onChange={(e) => setDataGeneratePayroll({...itemSelected,loanPayment: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
          className="small-6 modal-temporary-payroll__input__content"
          onBlur={() => !itemSelected?.loanPayment && setDataGeneratePayroll({...itemSelected,loanPayment: "0"})}
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
        />
        { BoardingSheetConst.TIBU === headquarterAssociated &&
          <>
            <span className='small-6 modal-temporary-payroll__subtitle'>Aportes</span>
            <Input
              value={itemSelected?.contributions?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected,contributions: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.contributions && setDataGeneratePayroll({...itemSelected,contributions: "0"})}
              className="small-6 modal-temporary-payroll__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
          </>
        }
        { BoardingSheetConst.CUCUTA === headquarterAssociated &&
          <>
            <span className='small-6 modal-temporary-payroll__subtitle'>Seg Social</span>
            <Input
              value={itemSelected?.socialSecurity?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected,socialSecurity: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.socialSecurity && setDataGeneratePayroll({...itemSelected,socialSecurity: "0"})}
              className="small-6 modal-temporary-payroll__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
          </>
        }
        { BoardingSheetConst.TIBU === headquarterAssociated &&
          <>
            <span className='small-6 modal-temporary-payroll__subtitle'>Fondo Seg</span>
            <Input
              value={itemSelected?.insuranceFund?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected,insuranceFund: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.insuranceFund && setDataGeneratePayroll({...itemSelected,insuranceFund: "0"})}
              className="small-6 modal-temporary-payroll__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
          </>
        }
        { BoardingSheetConst.CUCUTA === headquarterAssociated && 
          <>
            <span className='small-6 modal-temporary-payroll__subtitle'>Otros Ingre</span>
            <Input
              value={itemSelected?.otherIncome?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected,otherIncome: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.otherIncome && setDataGeneratePayroll({...itemSelected,otherIncome: "0"})}
              className="small-6 modal-temporary-payroll__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
            <span className='small-6 modal-temporary-payroll__subtitle'>Aportes C</span>
            <Input
              value={itemSelected?.cucutaContributions?.toLocaleString()}
              onChange={(e) => setDataGeneratePayroll({...itemSelected,cucutaContributions: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
              onBlur={() => !itemSelected?.cucutaContributions && setDataGeneratePayroll({...itemSelected,cucutaContributions: "0"})}
              className="small-6 modal-temporary-payroll__input__content"
              allowClear={{
                clearIcon: (
                  <img
                    width={28}
                    src={Assets.SharedIcons.icon_square_close}
                    alt="icon_logo_vertical"
                  />
                )
              }}
            />
          </>
        }
        
        <span className='small-6 modal-temporary-payroll__subtitle'>Aportes Ex</span>
        <Input
          value={itemSelected?.extraContributions?.toLocaleString()}
          onChange={(e) => setDataGeneratePayroll({...itemSelected,extraContributions: Number(e?.target?.value?.replace(/[^0-9]/g, ''))})}
          onBlur={() => !itemSelected?.extraContributions && setDataGeneratePayroll({...itemSelected,extraContributions: "0"})}
          className="small-6 modal-temporary-payroll__input__content"
          allowClear={{
            clearIcon: (
              <img
                width={28}
                src={Assets.SharedIcons.icon_square_close}
                alt="icon_logo_vertical"
              />
            )
          }}
        />
      </div>
    </>
  )
}

export default ModalTemporaryPayrollComponent