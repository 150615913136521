export const domicileOptions = [

    {
        label: 'No, se recoge en la oficina',
        value: 0
    },
    {
        label: 'Sí, requiere domicilio',
        value: 1
    }
]